import styled from 'styled-components';
import { ReactNode } from 'react';
import { useQuery } from 'src/modules/Router';
import { capitalize, pluralize } from 'src/modules/String';
import { ImmutableList } from 'src/modules/Immutable';
import { useStore } from 'src/Store';
import { colors, borderRadii } from 'src/styles';
import { Spacer, Copyable, Flex, Text } from 'src/components';
import { appUrl } from 'src/config';

import cleverLogin from 'src/assets/clever-login.png';
import googleLogin from 'src/assets/google-login.png';

const loginUrl = `${appUrl}/login`;

const NumberContainer = styled.div`
  display: grid;
  align-items: center;
  justify-items: center;
  background: ${colors.primaryBlue.hex};
  color: ${colors.primaryBlue.text};
  border-radius: ${borderRadii.rounded};
  min-width: 25px;
  height: 25px;
`;

const Step = ({ number, children }: { number: number; children: ReactNode }) => (
  <Flex>
    <NumberContainer>{number}</NumberContainer>
    <Spacer horizontal size={2} />
    {children}
  </Flex>
);

export const LmsConfirmation = ({ lms }: { lms: 'google' | 'clever' }) => {
  const query = useQuery();
  const studentCounts = useStore((s) => s.TeacherCourseListData.studentCounts, []);
  const courseIds = ImmutableList((query.get('editIds') || '').split(',')).filter(Boolean);
  const studentTotal = courseIds.reduce((acc, courseId) => acc + studentCounts.get(courseId, 0), 0);

  return (
    <>
      <Flex direction="column" align="center">
        <Text variant="h3" weightOverride="medium">
          Congratulations!
        </Text>
        <Spacer />
        <Text>
          You&apos;ve imported {pluralize('class', courseIds.size, { pluralVersion: 'classes' })}{' '}
          and {pluralize('student', studentTotal)}.
        </Text>
      </Flex>
      <Spacer size={7} />
      <Flex align="center">
        <img
          data-test-tag={lms}
          src={lms === 'google' ? googleLogin : cleverLogin}
          style={{ width: '50%', height: 'auto' }}
        />
        <Spacer horizontal size={4} />
        <Flex direction="column" height="100%" justify="center">
          <Step number={1}>
            <Text>
              Invite students to visit the
              <br />
              Readlee login page
            </Text>
          </Step>
          <Spacer size={3} />
          <Step number={2}>
            <Text>
              Ask your students to use the
              <br />
              <b>Login with {capitalize(lms)}</b> button
            </Text>
          </Step>
          <Spacer size={6} />

          <Copyable
            copyContent={loginUrl}
            displayType="link"
            kind="buttonOnly"
            color="primaryBlue"
            copyText="Copy Login Link"
            width="175px"
          />
        </Flex>
      </Flex>
    </>
  );
};
