import { Fragment } from 'react';
import {
  TagButton,
  Button,
  FlexGrow,
  Text,
  Spacer,
  Flex,
  NumberInput,
  ErrorText,
  TaskButton,
  SelectInput,
  StandardModal,
  WordDivider,
  FormGroup,
  LinkButton,
} from 'src/components';
import { ReadingTaskDetails } from 'src/models';
import { ComprehensionResponseType } from 'src/models/ComprehensionTask';
import { ErrorMap } from 'src/modules/Api';
import { ImmutableList, ImmutableMap } from 'src/modules/Immutable';
import { useHistory, useQuery } from 'src/modules/Router';
import { useStore } from 'src/Store';
import { paddings } from 'src/styles';
import { useCurrentTaskDetails, useReadingTaskDetails } from './AssignmentBuilderHelpers';

const textTypes = {
  TextStoredFile: ['whole_text', 'start_and_end_index'],
  EpubStoredFile: ['whole_text', 'chapters'],
  PdfStoredFile: ['whole_text', 'pages'],
  PdfByPageStoredFile: ['whole_text', 'pages'],
} as const;

const prettyReadingPartTypes = {
  whole_text: 'Full Text',
  start_and_end_index: 'Select Text',
  pages: 'Select Pages',
  chapters: 'Select Chapters',
} as const;

const SelectTextHelpText = ({
  currentTaskDetails,
  taskErrors,
}: {
  currentTaskDetails: ReadingTaskDetails;
  taskErrors: ImmutableMap<string, ErrorMap>;
}) =>
  currentTaskDetails.task.readingPartType !== 'start_and_end_index' ? null : (
    <>
      <Spacer size={4} />
      <Text>Click a word to start selecting, then click another word to finish selecting.</Text>
      {taskErrors.get(currentTaskDetails.task.id)?.has('endPoint') && (
        <ErrorText message="Please click on your end word to continue" />
      )}
    </>
  );

const PageSelector = ({
  currentTaskDetails,
  setStartPage,
  setEndPage,
  badPages,
}: {
  currentTaskDetails: ReadingTaskDetails;
  setStartPage: (taskId: string, value: number) => void;
  setEndPage: (taskId: string, value: number) => void;
  badPages: boolean;
}) => {
  const { task, contentFile } = currentTaskDetails;

  if (task.readingPartType !== 'pages') return null;
  if (contentFile?.type !== 'PdfStoredFile' && contentFile?.type !== 'PdfByPageStoredFile')
    return null;

  const startValue = task.startPoint.pageNumber + 1;
  const endValue = task.endPoint.pageNumber + 1;
  return (
    <>
      <Spacer size={2} />
      <Flex align="center">
        <NumberInput
          name="startPoint"
          value={startValue}
          onChange={(value) => {
            if (typeof value === 'number') {
              setStartPage(task.id, value - 1);
            }
          }}
          max={contentFile.pageCount}
          min={1}
          testTag="start-point-page"
          integerOnly
        />{' '}
        &mdash;{' '}
        <NumberInput
          name="endPoint"
          value={endValue}
          onChange={(value) => {
            if (typeof value === 'number') {
              setEndPage(task.id, value - 1);
            }
          }}
          max={contentFile.pageCount}
          min={1}
          testTag="end-point-page"
          integerOnly
        />
      </Flex>
      {badPages && <ErrorText message="Invalid range, end page must be higher than start page" />}
    </>
  );
};

const responseTypeOptions = ImmutableList<{ value: ComprehensionResponseType; label: string }>([
  { value: 'student_choice', label: 'Student Choice' },
  { value: 'written', label: 'Written' },
  { value: 'recorded', label: 'Recorded' },
]);

export const TaskBuilderSidebar = () => {
  const query = useQuery();
  const history = useHistory();
  const {
    saving,
    assessment,
    setReadingPartType,
    cancelTask,
    deleteTask,
    setField,
    deletingTaskId,
    taskDetailsMap,
    setStartPage,
    setEndPage,
    setQuestionType,
    setResponseType,
    saveTask,
    vocabTaskStep,
    vocabWord,
    saveWord,
    setCustomWord,
    setWordContent,
    uploading,
    taskErrors,
    isRecording,
  } = useStore(
    ({ AssignmentBuilderData: s, AppData: a }) => ({
      saving: s.saving,
      assessment: s.assessment,
      setReadingPartType: s.setReadingPartType,
      taskDetailsMap: s.taskDetailsMap,
      setField: s.setField,
      deletingTaskId: s.deletingTaskId,
      cancelTask: s.cancelTask,
      deleteTask: s.deleteTask,
      setStartPage: s.setStartPage,
      setEndPage: s.setEndPage,
      setQuestionType: s.setQuestionType,
      setResponseType: s.setResponseType,
      saveTask: s.saveTask,
      vocabTaskStep: s.vocabTaskStep,
      vocabWord: s.vocabWord,
      saveWord: s.saveWord,
      setCustomWord: s.setCustomWord,
      setWordContent: s.setWordContent,
      uploading: s.uploading,
      taskErrors: s.taskErrors,
      isRecording: a.isRecording,
    }),
    [],
  );
  const currentTaskDetails = useCurrentTaskDetails();
  const readingTaskDetails = useReadingTaskDetails();

  if (!currentTaskDetails) return null;
  if (!assessment) return null;

  const badPages =
    currentTaskDetails.type === 'ReadingTask' && currentTaskDetails.task.readingPartType === 'pages'
      ? currentTaskDetails.task.endPoint.pageNumber < currentTaskDetails.task.startPoint.pageNumber
      : false;

  const deletingTask = deletingTaskId ? taskDetailsMap.get(deletingTaskId) : null;
  const deleteButton = (currentTaskDetails.type === 'ComprehensionTask' ||
    currentTaskDetails.type === 'ModelTask' ||
    currentTaskDetails.type === 'VocabTask') &&
    currentTaskDetails.task.status !== 'empty' && (
      <>
        <Button
          onClick={() => setField('deletingTaskId')(currentTaskDetails.task.id)}
          loading={saving}
          color="danger"
          displayType="outline"
          disabled={isRecording}
        >
          Delete
        </Button>
        <Spacer size={2} />
      </>
    );

  const deleteModal = deletingTask && (
    <StandardModal
      openType="state"
      opened={Boolean(deletingTask)}
      close={() => setField('deletingTaskId')(undefined)}
      header="Delete Task?"
      testTag="delete-task-modal"
      footer={
        <>
          <Button
            displayType="link"
            color="primaryBlue"
            onClick={() => setField('deletingTaskId')(undefined)}
          >
            Cancel
          </Button>
          <Spacer horizontal />
          <Button
            color="primaryBlue"
            onClick={() => deleteTask(deletingTask.task.id, query, history)}
          >
            Delete
          </Button>
        </>
      }
    >
      Are you sure you would like to delete {deletingTask.task.name}?
    </StandardModal>
  );

  if (currentTaskDetails.type === 'ReadingTask') {
    return (
      <>
        <FlexGrow>
          <Text variant="h5">How much of the text do you want to assign?</Text>
          <Spacer size={2} />
          <Flex wrap="wrap">
            {textTypes[currentTaskDetails.contentFile?.type ?? 'TextStoredFile'].map((textType) => {
              return (
                <Fragment key={textType}>
                  <TagButton
                    onClick={() => setReadingPartType(currentTaskDetails.task.id, textType)}
                    active={currentTaskDetails.task.readingPartType === textType}
                  >
                    {prettyReadingPartTypes[textType]}
                  </TagButton>
                  <Spacer horizontal size={2} />
                </Fragment>
              );
            })}
          </Flex>
          <SelectTextHelpText currentTaskDetails={currentTaskDetails} taskErrors={taskErrors} />
          <PageSelector
            currentTaskDetails={currentTaskDetails}
            setStartPage={setStartPage}
            setEndPage={setEndPage}
            badPages={badPages}
          />
        </FlexGrow>
        <Spacer size={2} />
        <Button
          onClick={() => cancelTask(currentTaskDetails.task.id)}
          loading={saving}
          displayType="outline"
          color="white"
        >
          Cancel
        </Button>
        <Spacer size={2} />
        <Button type="submit" loading={saving} disabled={badPages}>
          Save Task
        </Button>
      </>
    );
  } else if (currentTaskDetails.type === 'ComprehensionTask') {
    return (
      <>
        <Flex grow={1} direction="column" align="stretch">
          <Text variant="h6" textTransform="uppercase">
            Task Format
          </Text>
          <Text variant="h5">Comprehension Question</Text>
          <Spacer size={6} />
          <Text variant="h6" textTransform="uppercase">
            Question Format
          </Text>
          <TaskButton
            active={currentTaskDetails.task.questionType === 'recorded'}
            icon="microphone"
            onClick={() => setQuestionType(currentTaskDetails.task.id, 'recorded')}
            disabled={isRecording}
          >
            Record Question
          </TaskButton>
          <Spacer size={1} />
          <TaskButton
            active={currentTaskDetails.task.questionType === 'written'}
            icon="edit"
            onClick={() => setQuestionType(currentTaskDetails.task.id, 'written')}
            disabled={isRecording}
          >
            Written Question
          </TaskButton>
          <Spacer size={6} />
          <Text variant="h6" textTransform="uppercase">
            Response Settings
          </Text>
          <SelectInput
            name="responseType"
            value={currentTaskDetails.task.responseType}
            options={responseTypeOptions}
            onChange={(value) => setResponseType(currentTaskDetails.task.id, value)}
            disabled={isRecording}
          />
        </Flex>
        <Spacer size={2} />
        {deleteButton}
        <Button
          onClick={() => cancelTask(currentTaskDetails.task.id)}
          loading={saving}
          displayType="outline"
          color="white"
          disabled={isRecording}
        >
          Cancel
        </Button>
        <Spacer size={2} />
        <Button
          onClick={() => saveTask({ taskDetails: currentTaskDetails, query, history })}
          loading={saving}
          disabled={
            isRecording ||
            currentTaskDetails.task.questionType === 'undecided' ||
            (currentTaskDetails.task.questionType === 'recorded' &&
              currentTaskDetails.questionRecordingSequence.processingStatus === 'waiting' &&
              !currentTaskDetails.questionAudioFile)
          }
        >
          Save Question
        </Button>

        {deleteModal}
      </>
    );
  } else if (currentTaskDetails.type === 'ModelTask') {
    return (
      <>
        <Flex grow={1} direction="column" align="stretch">
          <Text variant="h6" textTransform="uppercase">
            Task Format
          </Text>
          <Text variant="h5">Model Reading</Text>
          <Spacer size={6} />
          <Text>
            Modeling a reading is scientifically proven to help your students become better readers.
            Try recording yourself reading some or all of the text to give your students a
            reference.
          </Text>
        </Flex>
        <Spacer size={2} />
        {deleteButton}
        <Button
          onClick={() => cancelTask(currentTaskDetails.task.id)}
          loading={saving}
          displayType="outline"
          color="white"
          disabled={isRecording}
        >
          Cancel
        </Button>
        <Spacer size={2} />
        <Button
          onClick={() => saveTask({ taskDetails: currentTaskDetails, query, history })}
          loading={saving}
          disabled={
            isRecording ||
            (currentTaskDetails.recordingSequence.processingStatus === 'waiting' &&
              !currentTaskDetails.audioFile)
          }
        >
          Save Model Reading
        </Button>
        {deleteModal}
      </>
    );
  } else if (currentTaskDetails.type === 'VocabTask') {
    return (
      <>
        <Flex grow={1} direction="column" align="stretch">
          <Text variant="h6" textTransform="uppercase">
            Task Format
          </Text>
          <Text variant="h5">Vocabulary Task</Text>
          <Spacer size={8} />
          {vocabTaskStep === 'word' ? (
            <>
              {readingTaskDetails?.reading.contentType === 'independent' ||
              readingTaskDetails?.reading.contentType === 'pdf' ||
              readingTaskDetails?.reading.contentType === 'pdf_by_page' ? (
                <>
                  <Text variant="h5">Enter the word you wish to scaffold</Text>
                  <Spacer />
                </>
              ) : (
                <>
                  <Text variant="h5">
                    Click on the word in the reading that you wish to scaffold
                  </Text>
                  <Spacer size={2} />

                  <WordDivider color="gray2">OR</WordDivider>
                  <Spacer size={2} />
                  <Text variant="h5">Enter word manually</Text>
                  <Spacer />
                </>
              )}
              <FormGroup
                name="vocabWord"
                label="Vocabulary Word"
                type="text"
                value={currentTaskDetails.word?.content || ''}
                onChange={(word) => {
                  setField('vocabWord')(word);
                  setWordContent(currentTaskDetails.task.id, word);
                  setCustomWord(currentTaskDetails.task.id, word);
                }}
              />
            </>
          ) : (
            <Text>
              According to the science of reading, previewing vocabulary is one of the most
              effective ways to augment student vocabulary acquisition.{' '}
              <LinkButton
                paddingOverride={paddings.none}
                href="https://readleesupport.zendesk.com/hc/en-us/articles/14127734413325"
                newTab
              >
                See blog post.
              </LinkButton>
            </Text>
          )}
        </Flex>
        <Spacer size={2} />
        {deleteButton}
        {vocabTaskStep === 'details' && (
          <>
            <Button
              displayType="outline"
              color="white"
              onClick={() => {
                setField('vocabTaskStep')('word');
                setField('vocabWord')(currentTaskDetails.word?.content || '');
              }}
              loading={saving}
            >
              Change Word
            </Button>
            <Spacer size={2} />
          </>
        )}
        <Button
          onClick={() => cancelTask(currentTaskDetails.task.id)}
          loading={saving}
          displayType="outline"
          color="white"
        >
          Cancel
        </Button>
        <Spacer size={2} />
        <Button
          onClick={() =>
            vocabTaskStep === 'word'
              ? saveWord(currentTaskDetails.task.id)
              : saveTask({ taskDetails: currentTaskDetails, query, history })
          }
          loading={saving}
          disabled={(vocabTaskStep === 'word' && !vocabWord) || uploading}
        >
          {vocabTaskStep === 'word' ? 'Continue' : 'Save Vocabulary Task'}
        </Button>
        {deleteModal}
      </>
    );
  } else {
    throw new Error('Unrecognized task type');
  }
};
