import { useCallback, useEffect } from 'react';
import { useHistory, useLocation } from 'src/modules/Router';
import {
  Button,
  FormGroup,
  Spacer,
  StandardModal,
  WordDivider,
  Text,
  Flex,
  GoogleButton,
  CleverButton,
  LinkButton,
} from 'src/components';
import { useStore } from 'src/Store';
import { emptyErrorMap } from 'src/modules/Api';
import { ENTERPRISE_PLAN_LABEL } from 'src/models/Plan';

export const CreateCourseModal = ({
  openedPath,
  closedPath,
}: {
  openedPath: string;
  closedPath: string;
}) => {
  const {
    courseName,
    errors,
    submit,
    setField,
    load,
    loadCourses,
    isCoTeachersEnabled = false,
  } = useStore(
    (s) => ({
      courseName: s.CreateCourseData.courseName,
      errors: s.CreateCourseData.errors,
      submit: s.CreateCourseData.submit,
      setField: s.CreateCourseData.setField,
      load: s.CreateCourseData.load,
      loadCourses: s.TeacherCourseListData.load,
      isCoTeachersEnabled: s.AppData.currentUser?.flags?.isCoTeachersEnabled,
    }),
    [],
  );
  const history = useHistory();
  const pathname = useLocation().pathname;
  const closeModal = useCallback(() => {
    setField('courseName')('');
    setField('errors')(emptyErrorMap());
    history.replace(closedPath);
  }, [history, closedPath, setField]);

  useEffect(() => {
    load();
  }, [load]);

  return (
    <StandardModal
      openType="path"
      header="Create Class"
      footer={
        <Flex justify="end">
          <Button onClick={closeModal} inheritBgColor displayType="link" color="primaryBlue">
            Close
          </Button>
          <Spacer horizontal size={3} />
          <Button
            type="submit"
            onClick={() =>
              submit((course) => {
                loadCourses({ history, pathname });
                history.push(`/t/classes/create?editIds=${course.id}`);
              })
            }
          >
            Create Class
          </Button>
        </Flex>
      }
      openedPath={openedPath}
      close={closeModal}
    >
      <Text>We recommend importing your classes for easy student sign-in.</Text>

      <Spacer size={4} />
      <GoogleButton scopeKey="import_class" successUrl="/t/classes/google_import">
        Import from Google Classroom
      </GoogleButton>
      <Spacer size={2} />
      <CleverButton successUrl="/t/classes/clever_import">Import from Clever</CleverButton>
      <Spacer size={2} />
      <WordDivider>OR</WordDivider>
      <Spacer size={2} />
      <Text variant="h4">Manually create a class</Text>
      <Spacer size={2} />
      <FormGroup
        type="text"
        name="name"
        label="Class Name"
        errors={errors}
        value={courseName}
        onChange={setField('courseName')}
      />
      {!isCoTeachersEnabled && (
        <>
          <Spacer size={2} />
          <Flex>
            <Text>Upgrade to {ENTERPRISE_PLAN_LABEL} to sync co-teachers!</Text>
            <Spacer horizontal />
            <LinkButton href="https://www.readlee.com/pricing" newTab>
              View Plans and Pricing
            </LinkButton>
          </Flex>
        </>
      )}
    </StandardModal>
  );
};
