import React, { RefCallback, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Flex, Icon, Arrow, ArrowDirection, oppositeDirection, IconSize } from 'src/components';
import {
  appFontSize,
  borderRadii,
  boxShadows,
  Color,
  colors,
  fontWeights,
  RequiredDollarPrefix,
  useBreakpoints,
  Width,
  zIndices,
} from 'src/styles';
import { throttle } from 'lodash';
import {
  addEventListenerToAllAncestors,
  removeEventListenerFromAllAncestors,
} from 'src/modules/Dom';

type StyleProps = {
  /** Container display */
  display?: 'block' | 'inline-block' | 'inline' | 'flex';
  lineHeight?: 1 | '120%' | '130%' | '140%' | '145%' | '150%';
  containerWidthOverride?: Width;
};

export type TooltipProps = StyleProps & {
  /** Content to render for tooltip; defaults to an icon */
  children?: React.ReactNode;
  /** Content to render on hover */
  content: React.ReactNode;
  /** Control when tooltip is disabled */
  disabled?: boolean;
  /** Changes position calculations to better work on mobile */
  isMobile?: boolean;
  /** Changes info icon color */
  iconColor?: Color;
  iconSize?: IconSize;
  /** Overrides max width of the tooltip content */
  maxWidthOverride?: number;
  containerWidthOverride?: Width;
  hideOnClick?: boolean;
};

const ARROW_SIZE = 8;
const BUFFER_SIZE = ARROW_SIZE + 2;

const assumedMaxHeight = 150;
const minWidth = 200;
const maxWidth = 500;
const mobileMinWidth = 150;
const mobileMaxWidth = 250;

const TooltipContent = styled.div<{
  $position: ArrowDirection;
  $hovered: boolean;
  $showMobile: boolean;
  $maxWidthOverride?: number;
}>`
  display: ${({ $hovered }) => ($hovered ? 'block' : 'none')};
  padding: 8px;
  position: fixed;
  background: white;
  color: ${colors.black.hex};
  font-size: ${appFontSize}px;
  font-weight: ${fontWeights.default};
  border-radius: ${borderRadii[2]};
  z-index: ${zIndices.tooltip};
  box-shadow: ${boxShadows.standard};
  width: auto;
  min-width: ${({ $showMobile }) => ($showMobile ? mobileMinWidth : minWidth)}px;
  max-width: ${({ $showMobile, $maxWidthOverride }) =>
    $maxWidthOverride || ($showMobile ? mobileMaxWidth : maxWidth)}px;
  transform: ${({ $position }) =>
    $position === 'up' || $position === 'down' ? 'translateX(-50%)' : 'translateY(-50%)'};
  text-align: center;
  white-space: pre-wrap;
  word-break: break-word;
`;

export const TooltipContainer = styled.div<RequiredDollarPrefix<StyleProps>>`
  position: relative;
  display: ${(props) => props.$display};
  line-height: ${({ $lineHeight }) => $lineHeight};
  ${(props) => (props.$display.includes('inline') ? '' : 'vertical-align: middle;')}
  ${({ $display }) => ($display === 'block' ? 'width: 100%;' : '')}
  ${({ $containerWidthOverride }) => `width: ${$containerWidthOverride}`};
`;

type Coordinates =
  | {
      position: 'up';
      left: number;
      bottom: number;
    }
  | {
      position: 'down';
      left: number;
      top: number;
    }
  | {
      position: 'left';
      right: number;
      top: number;
    }
  | {
      position: 'right';
      left: number;
      top: number;
    };

const isValidTooltipcoordinates = (coords: Coordinates, isMobile: boolean) => {
  const widthToUse = isMobile ? mobileMaxWidth : maxWidth;
  const widthAdjustment = widthToUse / 2;
  const heightAdjustment = assumedMaxHeight / 2;
  if (coords.position === 'up' || coords.position === 'down') {
    if (coords.left - widthAdjustment < 0 || coords.left + widthAdjustment > window.innerWidth) {
      return false;
    }
  }
  if (coords.position === 'up') {
    if (coords.bottom + heightAdjustment > window.innerHeight) {
      return false;
    }
  }
  if (coords.position === 'down') {
    if (coords.top + heightAdjustment > window.innerHeight) {
      return false;
    }
  }
  if (coords.position === 'right') {
    if (coords.left + widthToUse > window.innerWidth) {
      return false;
    }
  }
  if (coords.position === 'left') {
    if (coords.right + widthToUse > window.innerWidth) {
      return false;
    }
  }

  return true;
};

const UnmemoizedTooltip = ({
  iconColor = 'black',
  iconSize,
  children = (
    <Flex height="100%">
      <Icon icon="info" strokeWidth={2} color={iconColor} size={iconSize} />
    </Flex>
  ),
  content,
  display = 'inline-block',
  lineHeight = 1,
  disabled = false,
  hideOnClick = false,
  maxWidthOverride,
  containerWidthOverride = 'auto',
}: TooltipProps) => {
  const showMobile = useBreakpoints({ smallerThanOrEqualTo: 'mobileLarge' });

  const [hovered, setHovered] = useState(false);
  const active = !disabled && hovered;
  const [tooltipcoordinates, setTooltipcoordinates] = useState<Coordinates>({
    left: -1000,
    bottom: window.innerHeight + 1000,
    position: 'up',
  });
  const [tooltipContainer, setTooltipContainer] = useState<HTMLDivElement | null>(null);
  const tooltipContainerRef: RefCallback<HTMLDivElement> = (elem) => {
    if (elem && elem !== tooltipContainer) {
      setTooltipContainer(elem);
    }
  };

  const onMouseEnter = useCallback(() => {
    setHovered(true);
  }, []);

  const onMouseLeave = useCallback(() => {
    setHovered(false);
  }, []);

  const onContainerClicked = useCallback(() => {
    if (hideOnClick) {
      setHovered(false);
    }
  }, [hideOnClick]);

  useEffect(() => {
    if (!tooltipContainer || !active) return;

    const reset = throttle(() => {
      const rect = tooltipContainer.getBoundingClientRect();
      const upTooltipcoordinates: Coordinates = {
        left: rect.left + rect.width / 2,
        bottom: window.innerHeight - (rect.top - BUFFER_SIZE),
        position: 'up',
      };
      const downTooltipcoordinates: Coordinates = {
        left: rect.left + rect.width / 2,
        top: rect.bottom + BUFFER_SIZE,
        position: 'down',
      };
      const leftTooltipcoordinates: Coordinates = {
        right: window.innerWidth - rect.left + BUFFER_SIZE,
        top: rect.top + rect.height / 2,
        position: 'left',
      };
      const rightTooltipcoordinates: Coordinates = {
        left: rect.right + BUFFER_SIZE,
        top: rect.top + rect.height / 2,
        position: 'right',
      };
      const tooltipCoordinateOptions: Array<Coordinates> = [
        upTooltipcoordinates,
        downTooltipcoordinates,
        leftTooltipcoordinates,
        rightTooltipcoordinates,
      ];
      const newTooltipcoordinates =
        tooltipCoordinateOptions.find((coords) => isValidTooltipcoordinates(coords, showMobile)) ??
        upTooltipcoordinates;
      setTooltipcoordinates(newTooltipcoordinates);
    }, 10);

    reset();
    window.addEventListener('resize', reset);
    document.addEventListener('scroll', reset);
    addEventListenerToAllAncestors(tooltipContainer, 'scroll', reset);

    return () => {
      window.removeEventListener('resize', reset);
      document.removeEventListener('scroll', reset);
      removeEventListenerFromAllAncestors(tooltipContainer, 'scroll', reset);
    };
  }, [tooltipContainer, active, showMobile]);

  const { position, ...coordinates } = tooltipcoordinates;

  return (
    <TooltipContainer
      $display={display}
      $lineHeight={lineHeight}
      $containerWidthOverride={containerWidthOverride}
      ref={tooltipContainerRef}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={onContainerClicked}
    >
      {children}
      {!disabled && (
        <TooltipContent
          // Styled-components generates a new class every time you change something
          // so we use inline styles here to clean it up
          style={coordinates}
          $position={position}
          $hovered={hovered}
          $showMobile={showMobile}
          $maxWidthOverride={maxWidthOverride}
        >
          {content}
          <Arrow
            direction={oppositeDirection(tooltipcoordinates.position)}
            size={ARROW_SIZE}
            color="white"
            position={tooltipcoordinates.position}
          />
        </TooltipContent>
      )}
    </TooltipContainer>
  );
};

export const Tooltip = React.memo(UnmemoizedTooltip);
