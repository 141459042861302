import styled from 'styled-components';
import { LogoButton, Spacer, Loading, Flex } from 'src/components';
import { paddings, colors, borderRadii, margins, useBreakpoints } from 'src/styles';
import { commonLayoutStyles } from 'src/modules/Layout';
import { useStore } from 'src/Store';

const SIMPLE_LAYOUT_MAX_WIDTH = '500px';
const SIMPLE_LAYOUT_MIN_WIDTH = '300px';

const BaseSimpleLayout = styled.div`
  ${commonLayoutStyles}
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${colors.backgroundLight.hex};
`;

const SimpleLayoutPanel = styled.div`
  padding: ${paddings[6]} ${paddings[8]};
  background: ${colors.white.hex};
  border-radius: ${borderRadii[4]};

  width: 100%;
  max-width: ${SIMPLE_LAYOUT_MAX_WIDTH};
  min-width: ${SIMPLE_LAYOUT_MIN_WIDTH};
  border: 1px solid ${colors.gray3.hex};
`;

export interface SimpleLayoutProps {
  children?: React.ReactNode;
  loading?: boolean;
  footer?: React.ReactNode;
}

export const SimpleLayout = ({ children, loading, footer }: SimpleLayoutProps) => {
  const isMobileLayout = useBreakpoints({ smallerThan: 'mobileLarge' });
  const currentUser = useStore((s) => s.AppData.currentUser, []);

  return (
    <BaseSimpleLayout>
      {loading ? (
        <Loading flex kind="boat" />
      ) : (
        <Flex
          direction="column"
          grow={1}
          width="100%"
          justify="center"
          margin={isMobileLayout ? margins[5] : margins.none}
        >
          <Flex
            justify={isMobileLayout ? 'center' : 'space-between'}
            padding={isMobileLayout ? `${paddings.none}` : `${paddings[4]} ${paddings[2]}`}
          >
            <LogoButton
              logoType={currentUser?.paperId ? 'paper-reading' : 'full-name'}
              size={isMobileLayout || currentUser?.paperId ? 'medium' : 'small'}
            />
          </Flex>
          <Flex grow={isMobileLayout ? 0 : 1} align="center" justify="center">
            <Flex
              direction="column"
              align="center"
              justify="center"
              margin={isMobileLayout ? `${margins.none} ${margins[5]}` : margins[5]}
              maxWidth={SIMPLE_LAYOUT_MAX_WIDTH}
              width="100%"
            >
              <Spacer size={10} />
              <SimpleLayoutPanel>{children || null}</SimpleLayoutPanel>
              <Spacer size={4} />
              {footer}
            </Flex>
          </Flex>
        </Flex>
      )}
    </BaseSimpleLayout>
  );
};
