import { useEffect } from 'react';
import { Flex, FormGroup, LinkButton, Loading, Spacer, Text } from 'src/components';
import { US_STATE_ABBREVIATED_OPTIONS } from 'src/modules/Location';
import { useQuery, useHistory } from 'src/modules/Router';
import { useStore } from 'src/Store';
import { SchoolChip } from './SchoolChip';

type SearchFormProps = {
  onSetSchool: () => void;
  manualLink: string;
  loadUserSchools?: boolean;
};

export const SchoolSearch = ({
  onSetSchool,
  manualLink,
  loadUserSchools = true,
}: SearchFormProps) => {
  const {
    schoolQuery,
    schoolResults,
    schoolDomains,
    suggestedSchoolMap,
    searching,
    waitingToSearch,
    stateAbbreviation,
    districtMap,
    setStateAbbreviation,
    setSchoolQuery,
    setSchool,
    load,
  } = useStore((s) => s.SchoolSearchData, []);

  const query = useQuery();
  const history = useHistory();

  useEffect(() => {
    if (loadUserSchools) {
      load(history, query);
    }
  }, [load, history, query, loadUserSchools]);

  return (
    <>
      <Flex>
        <FormGroup
          type="text"
          name="schoolQuery"
          value={schoolQuery}
          onChange={setSchoolQuery}
          autoComplete="off"
          placeholder="Search for your school"
          label="School Name"
        />
        <Spacer horizontal size={2} />
        <FormGroup
          type="select"
          name="stateAbbreviation"
          options={US_STATE_ABBREVIATED_OPTIONS}
          value={stateAbbreviation}
          onChange={setStateAbbreviation}
          width="100px"
          label="State"
        />
      </Flex>
      <Spacer size={2} />
      <Text>
        Can&apos;t find your school? <LinkButton to={manualLink}>Add it manually</LinkButton>
      </Text>
      <Spacer size={8} />
      {schoolResults.size > 0 || schoolDomains.size > 0 || searching || waitingToSearch ? (
        <>
          <Text variant="h3">
            Matching Schools ({schoolQuery.length === 0 ? schoolDomains.size : schoolResults.size})
          </Text>
          <Spacer size={2} />
        </>
      ) : null}
      {searching || waitingToSearch ? (
        <Loading />
      ) : (
        <>
          {schoolQuery.length === 0 &&
            schoolDomains.map((sd) => {
              const school = suggestedSchoolMap.get(sd.schoolId);
              if (!school) return;
              return (
                <SchoolChip
                  key={sd.id}
                  school={school}
                  district={school.districtId ? districtMap.get(school.districtId, null) : null}
                  choose={() => setSchool(school, onSetSchool)}
                  suggested
                />
              );
            })}

          {schoolQuery.length > 0 &&
            schoolResults.map((s) => (
              <SchoolChip
                key={s.id}
                school={s}
                district={s.districtId ? districtMap.get(s.districtId, null) : null}
                choose={() => setSchool(s, onSetSchool)}
              ></SchoolChip>
            ))}
          {schoolQuery.length > 0 && schoolResults.size === 0 ? <Text>No results.</Text> : null}
        </>
      )}
    </>
  );
};
