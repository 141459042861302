import { User, School, SchoolDomain, emptySchool, District } from 'src/models';
import { ImmutableList, ImmutableMap } from 'src/modules/Immutable';
import { SetField } from 'src/State';
import { History, QueryMap } from 'src/modules/Router';
import { emptyErrorMap, ErrorMap } from 'src/modules/Api';
import { USStateAbbreviation } from 'src/modules/Location';

export type SchoolSearchState = {
  loading: boolean;
  currentUser: User | null;
  schools: ImmutableList<School>;
  districtMap: ImmutableMap<string, District>;
  schoolQuery: string;
  schoolResults: ImmutableList<School>;
  schoolDomains: ImmutableList<SchoolDomain>;
  suggestedSchoolMap: ImmutableMap<string, School>;
  usedDomain: boolean;
  searching: boolean;
  manualSchool: School;
  waitingToSearch: boolean;
  manualSchoolErrors: ErrorMap;
  saving: boolean;
  stateAbbreviation: USStateAbbreviation | undefined;
  load: (history: History, query: QueryMap) => void;
  setField: SetField<SchoolSearchState>;
  setSchool: (school: School, onSuccess: () => void) => void;
  setSchoolQuery: (value: string) => void;
  setStateAbbreviation: (stateAbbreviation: USStateAbbreviation | undefined) => void;
  submitSchool: (history: History, query: QueryMap) => void;
  setManualSchoolField: <K extends keyof School>(key: K) => (value: School[K]) => void;
  saveManualSchool: (onSuccess: () => void) => void;
};

export const defaultState = {
  loading: false,
  currentUser: null,
  schools: ImmutableList<School>(),
  schoolQuery: '',
  schoolResults: ImmutableList<School>(),
  schoolDomains: ImmutableList<SchoolDomain>(),
  suggestedSchoolMap: ImmutableMap<string, School>(),
  usedDomain: false,
  searching: false,
  waitingToSearch: false,
  manualSchoolErrors: emptyErrorMap(),
  manualSchool: emptySchool(),
  saving: false,
  stateAbbreviation: undefined,
  districtMap: ImmutableMap<string, District>(),
} as const;
