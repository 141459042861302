import { useEffect, useState, useMemo, useCallback, ReactNode } from 'react';
import { useStore } from 'src/Store';
import {
  Text,
  Flex,
  TabView,
  SuperTable,
  SuperTableColumns,
  Spacer,
  AvatarCard,
  UserAvatar,
  Loading,
  DatePicker,
  TimeLabel,
  EmptyState,
  dateOptions,
  Button,
  Tooltip,
  SearchInput,
  PremiumTooltip,
} from 'src/components';
import { CenteredLayout } from 'src/layouts';
import { colors, paddings, margins } from 'src/styles';
import { ImmutableMap, ImmutableList, searchFilter } from 'src/modules/Immutable';
import {
  queryMapToString,
  useQuery,
  useHistory,
  updateQuery,
  useLocation,
} from 'src/modules/Router';
import { statStyles, formatStat } from 'src/modules/Stat';
import { pluralize } from 'src/modules/String';
import { formatDateTime } from 'src/modules/Time';
import { StatResponse } from './ProgressMonitoringState';
import { courseTabs, ENTERPRISE_PLAN_LABEL, formatName, getFilterLength } from 'src/models';
import { useFetch } from 'src/modules/Api';
import { downloadCSV } from 'src/modules/Csv';

const Empty = ({ tooltipText }: { tooltipText?: ReactNode }) => (
  <Tooltip content={<Text>{tooltipText || 'No data for this time period or class'}</Text>}>
    <Text color="black">--</Text>
  </Tooltip>
);

const studentProgressMonitoringColumnIds = [
  'assignment',
  'completion',
  'wordsCountPerMinute',
  'words',
  'time',
  'tasks',
  'submittedAt',
];

export const ProgressMonitoring = () => {
  const {
    load,
    isLoading,
    courses,
    stats,
    teachersMap,
    search,
    dateRange,
    setField,
    currentUser,
    currentPlan,
  } = useStore(
    (s) => ({
      load: s.ProgressMonitoringData.load,
      isLoading: s.ProgressMonitoringData.isLoading,
      courses: s.ProgressMonitoringData.courses,
      stats: s.ProgressMonitoringData.stats,
      teachersMap: s.ProgressMonitoringData.teachersMap,
      search: s.ProgressMonitoringData.search,
      dateRange: s.ProgressMonitoringData.dateRange,
      setField: s.ProgressMonitoringData.setField,
      currentUser: s.AppData.currentUser,
      currentPlan: s.AppData.currentPlan,
    }),
    [],
  );
  const { fetchJson } = useFetch();
  const query = useQuery();
  const history = useHistory();
  const pathname = useLocation().pathname;
  const selectedCourseId = query.get('selectedCourseId') || 'all';
  const selectedTimeLabelParam = query.get('selectedTime') as TimeLabel;

  const sortBy = query.get('sortBy') ?? '';

  const [startTime, setStartTime] = useState<Date | null>(dateOptions[dateRange].value.start);
  const [startTimeLoaded, setStartTimeLoaded] = useState<boolean>(false);
  const [dateRangeLoaded, setDateRangeLoaded] = useState<boolean>(false);

  const columns: SuperTableColumns<StatResponse> = [
    {
      id: 'student',
      name: 'Student',
      render: ({ student, submissionCount }) => (
        <AvatarCard
          testTag="student-tag"
          primaryText={formatName(student)}
          secondaryText={`${pluralize('Submission', submissionCount)}`}
          avatar={
            <UserAvatar
              key={student.displayName}
              backgroundColor={student.avatarColor || colors.primaryBlue.hex}
              name={student.displayName}
            />
          }
        />
      ),
      sortType: 'sortBy',
      defaultSortDirection: 'desc',
      sortBy: ({ student }) => formatName(student),
    },
    {
      id: 'completion',
      name: 'Completion',
      tooltipContent: 'Total Words Correct / Total Words Assigned',
      render: ({ submissionCount, independentOnly, completion }) =>
        submissionCount === 0 || independentOnly ? (
          <Empty
            tooltipText={
              submissionCount === 0
                ? undefined
                : 'Cannot calculate completion for independent readings'
            }
          />
        ) : (
          <Text variant="h6">{formatStat(statStyles.completion, completion)}</Text>
        ),
      sortType: 'sortBy',
      defaultSortDirection: 'desc',
      sortBy: (student) => student.completion,
    },
    {
      id: 'wordsCorrectPerMinute',
      name: 'WCPM',
      tooltipContent: 'Words Correct Per Minute (Total Words Correct / Total Time Read)',
      render: ({ wordsCorrectPerMinute, submissionCount, independentOnly }) =>
        submissionCount === 0 || independentOnly ? (
          <Empty
            tooltipText={
              submissionCount === 0 ? undefined : 'Cannot calculate WCPM for independent readings'
            }
          />
        ) : (
          <Text variant="h6">{formatStat(statStyles.wcpm, wordsCorrectPerMinute)}</Text>
        ),
      sortType: 'sortBy',
      defaultSortDirection: 'desc',
      sortBy: (student) => student.wordsCorrectPerMinute,
    },
    {
      id: 'wordsPerMinute',
      name: 'WPM',
      tooltipContent: 'Words Per Minute',
      render: ({ wordsPerMinute, submissionCount }) =>
        submissionCount === 0 ? (
          <Empty />
        ) : (
          <Text variant="h6">{formatStat(statStyles.wcpm, wordsPerMinute)}</Text>
        ),
      sortType: 'sortBy',
      defaultSortDirection: 'desc',
      sortBy: (student) => student.wordsPerMinute,
    },
    {
      id: 'words',
      name: 'Words Read',
      render: ({ wordsSaidCount, submissionCount }) =>
        submissionCount === 0 ? (
          <Empty />
        ) : (
          <Text variant="h6">{formatStat(statStyles.total_words, wordsSaidCount)}</Text>
        ),
      sortType: 'sortBy',
      defaultSortDirection: 'desc',
      sortBy: (student) => student.wordsSaidCount,
    },
    {
      id: 'time',
      name: 'Time',
      render: ({ totalTime, submissionCount }) =>
        submissionCount === 0 ? (
          <Empty />
        ) : (
          <Text variant="h6">{formatStat(statStyles.time_taken, totalTime)}</Text>
        ),
      sortType: 'sortBy',
      defaultSortDirection: 'desc',
      sortBy: (student) => student.totalTime,
    },
    {
      id: 'tasks',
      name: 'Tasks',
      render: (student) =>
        student.submissionCount === 0 ? (
          <Empty />
        ) : (
          <Text variant="h6">
            {student.totalFinishedTasks} / {student.totalTasks}
          </Text>
        ),
      sortType: 'sortBy',
      defaultSortDirection: 'desc',
      sortBy: (student) => student.totalFinishedTasks,
    },
    {
      id: 'online',
      name: 'Last Online',
      render: (student) => (
        <Text variant="h6" color={student.student.status === 'pre_registered' ? 'danger' : 'black'}>
          {student.student.status === 'pre_registered'
            ? 'Never'
            : formatDateTime(new Date(student.online))}
        </Text>
      ),
      sortType: 'sortBy',
      defaultSortDirection: 'desc',
      sortBy: (student) => student.online,
    },
  ];

  const studentResults = useMemo(() => {
    return stats.toList();
  }, [stats]);

  const memoedCourseTabs = useMemo(
    () => courseTabs(courses, teachersMap, currentUser),
    [courses, currentUser, teachersMap],
  );

  useEffect(() => {
    if (!currentUser) return;

    if (selectedTimeLabelParam) {
      setField('dateRange')(selectedTimeLabelParam);
    } else {
      setField('dateRange')(getFilterLength(currentPlan));
    }
    setDateRangeLoaded(true);
  }, [currentPlan, selectedTimeLabelParam, currentUser, setField, setStartTime]);

  useEffect(() => {
    if (dateRangeLoaded) {
      setStartTime(dateOptions[dateRange].value.start);
      setStartTimeLoaded(true);
    }
  }, [dateRange, dateRangeLoaded]);

  useEffect(() => {
    if (!startTimeLoaded) return;

    load(selectedCourseId, startTime, dateRange);
  }, [load, selectedCourseId, startTime, dateRange, startTimeLoaded]);

  const updateTimeParam = useCallback(
    (value: TimeLabel) => history.push(`${pathname}${updateQuery(query, { selectedTime: value })}`),
    [history, pathname, query],
  );

  const columnNames = [
    'Last Name/Username',
    'First Name',
    'Completion',
    'WCPM',
    'WPM',
    'Words Read',
    'Time',
    'Tasks',
    'Last Online',
  ];

  const studentData = useMemo(
    () =>
      studentResults.map(({ student, ...stats }) => [
        student.familyName || student.displayName,
        student.givenName || '',
        stats.completion === 0 ? '' : formatStat(statStyles.completion, stats.completion),
        stats.wordsCorrectPerMinute === 0
          ? ''
          : formatStat(statStyles.wcpm, stats.wordsCorrectPerMinute),
        stats.wordsSaidCount === 0 ? '' : stats.wordsSaidCount.toString(),
        stats.totalTime === 0 ? '' : formatStat(statStyles.time_taken, stats.totalTime),
        !stats.totalFinishedTasks && !stats.totalTasks
          ? ''
          : `${stats.totalFinishedTasks} / ${stats.totalTasks}`,
        formatDateTime(new Date(stats.online)),
      ]),
    [studentResults],
  );

  const fileData = studentData.insert(0, columnNames);

  const getFilename = () => {
    const course = courses.find((course) => course.id === selectedCourseId);
    return `${course?.name.substring(0, 20) || 'All Students'}-progress-report`;
  };

  const hasCsvExport = Boolean(currentUser?.flags.isCsvExportEnabled);
  const TooltipElem = hasCsvExport ? Tooltip : PremiumTooltip;
  const tooltipProps = {
    content: hasCsvExport
      ? 'Export Table as CSV'
      : `Upgrade to ${ENTERPRISE_PLAN_LABEL} to seamlessly export all your data`,
    linkLocation: 'contact' as const,
  };
  return (
    <Flex
      width="100%"
      minWidth={0}
      height="100%"
      direction="column"
      backgroundColor={colors.backgroundLight.hex}
      overflowY="auto"
    >
      <CenteredLayout backgroundColor={colors.backgroundDark.hex} padding={`0 ${paddings[6]}`}>
        <Flex width="100%" padding={`${paddings[7]} 0`}>
          <Flex
            width="100%"
            justify="space-between"
            align="center"
            wrap="wrap"
            margin={`-${margins[2]} 0`}
          >
            <Flex margin={`${margins[2]} ${margins[8]} ${margins[2]} 0`}>
              <Text variant="h2" color="white">
                Progress Monitoring
              </Text>
            </Flex>
            <Flex justify="end" margin={`${margins[2]} 0`}>
              <DatePicker
                value={dateRange}
                name="assessmentDatePicker"
                onChange={(v) => {
                  setField('dateRange')(v.label);
                  setStartTime(v.label === 'All Time' ? null : v.value.start);
                  updateTimeParam(v.label);
                }}
              />
              <Spacer horizontal size={2} />
              <TooltipElem enabled {...tooltipProps} display="flex">
                <Button
                  testTag="export-csv-button"
                  displayType="iconOnly"
                  icon="download"
                  color="white"
                  width="70px"
                  borderRadiusOverride="2"
                  disabled={!hasCsvExport}
                  onClick={downloadCSV({
                    data: fileData,
                    filename: getFilename(),
                    fetchJson,
                  })}
                />
              </TooltipElem>
            </Flex>
          </Flex>
        </Flex>
      </CenteredLayout>
      <CenteredLayout backgroundColor={colors.backgroundDark.hex} minWidth={0}>
        <Flex minWidth={0} maxWidth="100%">
          <TabView skeumorphic queryParam="selectedCourseId" tabs={memoedCourseTabs} />
        </Flex>
      </CenteredLayout>
      <CenteredLayout justify="space-between">
        <Flex margin={`${margins[6]} 0 ${margins[2]} 0`} align="center">
          <TabView tabs={ImmutableList([{ id: 'all', name: 'Students' }])} queryParam="" />
          <SearchInput value={search} onChange={setField('search')} placeholder="Search Students" />
        </Flex>
      </CenteredLayout>
      <CenteredLayout minWidth={0} minHeight={0} height="100%">
        {isLoading || !startTimeLoaded ? (
          <Loading flex kind="boat" />
        ) : (
          <SuperTable
            columns={columns}
            rows={searchFilter(search, studentResults, (sr: StatResponse) =>
              formatName(sr.student),
            )}
            getRowId={({ student }) => student.id}
            queryParam="sortBy"
            rowClickable
            onRowClick={({ student }) => {
              history.push(
                `/t/progress-monitoring/${student.id}${queryMapToString(
                  ImmutableMap({
                    backPath: `/t/progress-monitoring${queryMapToString(query)}`,
                    selectedCourseId,
                    selectedTime: dateRange,
                    sortBy: studentProgressMonitoringColumnIds.includes(sortBy) ? sortBy : '',
                    sortByDirection: query.get('sortByDirection') ?? '',
                  }),
                )}`,
              );
            }}
            initialSortSettings={{
              columnId: 'student',
              sortDirection: 'asc',
            }}
            emptyStateContent={
              <EmptyState
                kind={currentUser?.paperSyncedAt ? 'progressMonitoringPaper' : 'progressMonitoring'}
              />
            }
          />
        )}
        <Spacer size={5} />
      </CenteredLayout>
    </Flex>
  );
};
