import { subDataCreator } from 'src/State';
import { ImmutableMap } from 'src/modules/Immutable';
import { defaultState } from './SignUpSchoolCodeState';
import { updateQuery } from 'src/modules/Router';
import { School } from 'src/models';
import { ErrorMapResponse } from 'src/modules/Api';

export const createDefaultSignUpSchoolCodeData = subDataCreator(
  'SignUpSchoolCodeData',
  defaultState,
  ({ set, setField, fetchJson }) => ({
    setField,
    load: () => set({ ...defaultState }),
    submit:
      ({ history, query, schoolCode, replace }) =>
      () => {
        if (!schoolCode) return;

        set({ submitting: true });
        fetchJson(`/api/school_codes/${encodeURIComponent(schoolCode)}`, {
          onSuccess: (data: { school: School; errors: ErrorMapResponse; success: boolean }) => {
            if (data.success) {
              const url = `/sign-up/teacher/register${updateQuery(query, {
                schoolCode,
              })}`;

              replace ? history.replace(url) : history.push(url);
            }

            set({ errors: ImmutableMap(data.errors), submitting: false });
          },
        });
      },
  }),
);
