export const clamp = ({ min, max, value }: { min?: number; max?: number; value: number }) => {
  const ceilinged = typeof max === 'number' ? Math.min(max, value) : value;
  const floored = typeof min === 'number' ? Math.max(min, ceilinged) : ceilinged;
  return floored;
};

export const roundTo = (num: number, decimals: number) => {
  const change = 10 ** decimals;
  return Math.round(num * change) / change;
};
