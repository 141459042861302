import { useMemo } from 'react';
import {
  StandardModal,
  Text,
  Flex,
  Button,
  Spacer,
  Thumbnail,
  SuperTable,
  SuperTableColumns,
  UserAvatar,
  AvatarCard,
  WordDivider,
  Tooltip,
  LinkButton,
  Loading,
} from 'src/components';
import { useStore } from 'src/Store';
import { queryMapToString, useHistory, useQuery } from 'src/modules/Router';
import { colors, paddings, useBreakpoints } from 'src/styles';
import { AssessmentExtended, emptyUser, formatName } from 'src/models';
import { TileStats } from './TeacherReadingTile';
import { pluralize } from 'src/modules/String';
import { ImmutableMap } from 'src/modules/Immutable';
import { moderatorOrGreater } from '../../models/User';

export const SelectionModal = () => {
  const history = useHistory();
  const {
    selectionModalOpen,
    assignmentsLoading,
    selectedSearchResult,
    gradeLevels,
    assessmentResults,
    userMap,
    setField,
    accountType,
  } = useStore(
    ({ ContentLibraryData, AppData }) => ({
      selectionModalOpen: ContentLibraryData.selectionModalOpen,
      assignmentsLoading: ContentLibraryData.assignmentsLoading,
      selectedSearchResult: ContentLibraryData.selectedSearchResult,
      gradeLevels: ContentLibraryData.gradeLevels,
      assessmentResults: ContentLibraryData.assessmentResults,
      userMap: ContentLibraryData.userMap,
      setField: ContentLibraryData.setField,
      accountType: AppData.currentUser?.accountType || 'student',
    }),
    [],
  );
  const closeModal = () => setField('selectionModalOpen')(false);

  const columns: SuperTableColumns<AssessmentExtended> = useMemo(
    () => [
      {
        id: 'name',
        name: 'Title',
        render: ({ name }) => (
          <Flex>
            <Text variant="h6">{name}</Text>
            <Spacer horizontal size={8} />
          </Flex>
        ),
        sortType: 'sortBy',
        defaultSortDirection: 'desc',
        sortBy: ({ name }) => name,
      },
      {
        id: 'total_words',
        name: 'Word Count',
        render: ({ totalWords }) => <Text variant="h6">{totalWords}</Text>,
        sortType: 'sortBy',
        defaultSortDirection: 'desc',
        sortBy: ({ totalWords }) => totalWords,
      },
      {
        id: 'tasks',
        name: 'Tasks',
        render: ({ taskOrder }) => {
          const readingTaskCount = taskOrder.count((task) => task.type === 'ReadingTask');
          const comprehensionTaskCount = taskOrder.count(
            (task) => task.type === 'ComprehensionTask',
          );
          const modelTaskCount = taskOrder.count((task) => task.type === 'ModelTask');
          const vocabTaskCount = taskOrder.count((task) => task.type === 'VocabTask');
          const TooltipContent = (
            <>
              {readingTaskCount > 0 && <Text>{pluralize('Reading Task', readingTaskCount)}</Text>}
              {comprehensionTaskCount > 0 && (
                <Text>{pluralize('Comprehension Task', comprehensionTaskCount)}</Text>
              )}
              {modelTaskCount > 0 && <Text>{pluralize('Model Reading Task', modelTaskCount)}</Text>}
              {vocabTaskCount > 0 && <Text>{pluralize('Vocab Task', vocabTaskCount)}</Text>}
            </>
          );

          return (
            <Tooltip content={TooltipContent} display="flex">
              <Text variant="h6">{taskOrder.size}</Text>
            </Tooltip>
          );
        },
        sortType: 'sortBy',
        defaultSortDirection: 'desc',
        sortBy: ({ taskOrder }) => taskOrder.size,
      },
      {
        id: 'creator',
        name: 'Creator',
        render: ({ id }) => {
          const creator = userMap.get(id);
          return (
            creator && (
              <AvatarCard
                testTag="creator-tag"
                primaryText={formatName(creator)}
                avatar={
                  <UserAvatar
                    key={creator.displayName}
                    backgroundColor={creator.avatarColor || colors.primaryBlue.hex}
                    name={creator.displayName}
                  />
                }
              />
            )
          );
        },
        sortType: 'sortBy',
        defaultSortDirection: 'desc',
        sortBy: ({ id }) => formatName(userMap.get(id) || emptyUser),
      },
    ],
    [userMap],
  );
  const showMobile = useBreakpoints({ smallerThanOrEqualTo: 'tabletSmall' });
  const query = useQuery();

  const previewPath = selectedSearchResult
    ? `/t/reading-builder/metadata${queryMapToString(
        ImmutableMap({
          backPath: `/t/library${queryMapToString(query)}`,
          readingId: selectedSearchResult.reading.id,
          viewMode: 'preview',
        }),
      )}`
    : '';

  return (
    <StandardModal
      openType="state"
      opened={selectionModalOpen}
      header="Select an Assignment"
      close={closeModal}
      hasFooter={false}
    >
      {selectedSearchResult && (
        <Flex direction="column">
          <Flex direction={showMobile ? 'column' : 'row'} align="start" justify="space-between">
            <Flex>
              <Thumbnail
                src={selectedSearchResult.imageStoredFile?.thumbnailUrl ?? undefined}
                size="medium"
                title={selectedSearchResult.reading.name}
              />
              <Spacer horizontal size={2} />
              <Flex direction="column">
                <Text variant="h3" title={selectedSearchResult.reading.name} ellipsis>
                  {selectedSearchResult.reading.name}
                </Text>
                {selectedSearchResult.author && (
                  <Text
                    variant="h6"
                    color="gray6"
                    title={selectedSearchResult.author.name}
                    ellipsis
                  >
                    {selectedSearchResult.author.name.toUpperCase()}
                  </Text>
                )}
                <Spacer />
                <Text
                  variant="h5"
                  color="gray6"
                  title={selectedSearchResult.tags.map((tag) => tag.name).join(', ')}
                  ellipsis
                >
                  {selectedSearchResult.tags.map((tag) => tag.name).join(', ')}
                </Text>
              </Flex>
            </Flex>
            {showMobile ? <Spacer size={2} /> : <Spacer horizontal size={8} />}
            <TileStats
              result={selectedSearchResult}
              gradeLevels={gradeLevels}
              reversed={!showMobile}
            />
          </Flex>
          <Spacer size={8} />
          <Button paddingOverride={paddings[3]} to={previewPath}>
            Preview Reading
          </Button>
          <Spacer size={2} />
          {moderatorOrGreater(accountType) && (
            <>
              <Button
                width="100%"
                displayType="outline"
                to={`/t/reading-builder/metadata?readingId=${selectedSearchResult.reading.id}`}
              >
                Edit Reading (Moderator)
              </Button>
              <Spacer size={2} />
            </>
          )}
          <WordDivider color="gray5">OR</WordDivider>
          <Spacer size={2} />
          <Text variant="h4" weightOverride="bold" textAlign="center">
            Select Pre-Built Assignment
          </Text>
          <Spacer size={2} />
          {assignmentsLoading ? (
            <>
              <Spacer size={8} />
              <Loading flex />
              <Spacer size={8} />
            </>
          ) : (
            <>
              {assessmentResults.isEmpty() ? (
                <>
                  <Spacer size={2} />
                  <Text textAlign="center">
                    No pre-built assignments available.{' '}
                    <LinkButton to={previewPath}>Create a new assignment</LinkButton>
                  </Text>
                  <Spacer size={4} />
                </>
              ) : (
                <SuperTable
                  columns={columns}
                  rows={assessmentResults}
                  getRowId={(assessment) => assessment.id}
                  queryParam="sortBy"
                  rowClickable
                  onRowClick={({ id }) => {
                    history.push(`/t/public-preview?assessmentId=${id}`);
                  }}
                  noPadding
                  emptyStateContent={
                    <>
                      <Spacer size={2} />
                      <Text textAlign="center">
                        No pre-built assignments available.{' '}
                        <LinkButton to={previewPath}>Create a new assignment</LinkButton>
                      </Text>
                      <Spacer size={4} />
                    </>
                  }
                />
              )}
            </>
          )}
        </Flex>
      )}
    </StandardModal>
  );
};
