import { SimpleLayout } from 'src/layouts';
import { FormGroup, Button, LinkButton, Form, Text, Spacer, Flex } from 'src/components';
import { useStore } from 'src/Store';
import { useHistory, useQuery } from 'src/modules/Router';
import { useEffect } from 'react';

export const SignUpSchoolCode = () => {
  const query = useQuery();
  const sso = query.get('sso');
  const history = useHistory();
  const schoolCodeParam = query.get('schoolCode') || '';
  const { errors, schoolCode, setField, submit, submitting, load } = useStore(
    (state) => state.SignUpSchoolCodeData,
    [],
  );

  useEffect(() => {
    load();
    setField('schoolCode')(schoolCodeParam);
    submit({ history, query, schoolCode: schoolCodeParam, replace: true })();
  }, [load, submit, schoolCodeParam, history, query, setField]);

  return (
    <SimpleLayout>
      <Form onSubmit={submit({ history, query, schoolCode, replace: false })}>
        <Text variant="h1">Enter your school code</Text>
        <Spacer size={3} />

        <FormGroup
          name="schoolCode"
          label="School Code"
          errors={errors}
          type="text"
          value={schoolCode}
          onChange={setField('schoolCode')}
        />
        <Spacer size={2} />
        <Button loading={submitting} type="submit" disabled={schoolCode.trim().length === 0}>
          Next
        </Button>
      </Form>
      <Spacer size={2} />
      <Flex>
        <Text>Don&apos;t have a school code?</Text>
        <Spacer horizontal />
        <LinkButton href="https://readleesupport.zendesk.com/hc/en-us/requests/new">
          Contact us for help!
        </LinkButton>
      </Flex>
      {!sso && (
        <Flex>
          <Text>Already a user?</Text>
          <Spacer horizontal />
          <LinkButton
            to={`/login${schoolCodeParam && `?schoolCode=${encodeURIComponent(schoolCodeParam)}`}`}
          >
            Sign In
          </LinkButton>
        </Flex>
      )}
    </SimpleLayout>
  );
};
