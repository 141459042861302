import { ReactNode, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { Flex, Label, SearchInput, Text, Spacer, Button, Icon, LinkButton } from 'src/components';
import { margins, paddings, colors, useBreakpoints, zIndices } from 'src/styles';
import { commonLayoutStyles } from 'src/modules/Layout';
import { updateQuery, useHistory, useLocation, useQuery, matchPath } from 'src/modules/Router';
import { Route } from 'react-router-dom';
import { Footer } from 'src/components/Footer';
import { useStore } from 'src/Store';

const BaseContentLayout = styled.div<{ $fixedSearchBar: boolean }>`
  ${commonLayoutStyles}

  background: ${colors.backgroundLight.hex};
  padding: ${paddings[4]};

  ${({ $fixedSearchBar }) =>
    $fixedSearchBar &&
    css`
      padding-top: 0;
    `}
`;

const FiltersArea = styled.div<{ $isMobile: boolean; $fixedSearchBar: boolean }>`
  margin-bottom: ${margins[5]};

  ${({ $isMobile, $fixedSearchBar }) =>
    $isMobile
      ? css`
          padding: ${paddings[4]};
          min-width: 250px;
        `
      : css`
          position: fixed;
          padding: ${$fixedSearchBar ? paddings[6] : paddings[2]} ${paddings[2]} ${paddings[2]}
            ${paddings[2]};
          width: 250px;
          height: 100%;
        `}
`;

const SearchContainer = styled.div<{ $fixedSearchBar: boolean }>`
  ${({ $fixedSearchBar }) =>
    $fixedSearchBar
      ? css`
          position: fixed;
          z-index: ${zIndices.raised};
          padding: ${paddings[7]} ${paddings[3]} ${paddings[4]};
          background: ${colors.backgroundLight.hex};
          width: calc(100% - 298px);
        `
      : css`
          padding: ${paddings[3]};
        `}
`;

type ContentLayoutProps = {
  filters: ReactNode;
  searchValue: string;
  searchAction: (value: string) => void;
  searchPlaceholder: string;
  resultCount: number;
  resultTotal: number;
  loading: boolean;
  children: ReactNode;
};
export const ContentLayout = ({
  filters,
  searchValue,
  searchAction,
  searchPlaceholder = 'Search',
  resultCount = 0,
  resultTotal,
  loading,
  children,
}: ContentLayoutProps) => {
  const isMobile = useBreakpoints({ smallerThan: 'tabletSmall' });
  const currentUser = useStore((s) => s.AppData.currentUser, []);
  const query = useQuery();
  const pathname = useLocation().pathname;
  const history = useHistory();
  const PAGE_SIZE = 30;
  const page = parseInt(query.get('pageNumber') ?? '0');
  const total = Math.ceil(resultTotal / PAGE_SIZE);
  const isTeacher = Boolean(matchPath(pathname, '/t/library'));

  useEffect(() => {
    if (page >= total && total > 0)
      history.push(
        `${pathname}${updateQuery(query, {
          pageNumber: '0',
        })}`,
      );
  }, [page, total, history, query, pathname]);

  const fixedSearchBar = !isMobile && isTeacher;

  const filterSection = (
    <FiltersArea $isMobile={isMobile} $fixedSearchBar={fixedSearchBar}>
      {filters}
    </FiltersArea>
  );

  return (
    <BaseContentLayout $fixedSearchBar={fixedSearchBar}>
      <Flex>
        {!isMobile && filterSection}
        <Flex
          width="100%"
          height="100%"
          direction="column"
          padding={isMobile ? '0' : '0 0 0 266px'}
        >
          <Route path="/t/library">
            <SearchContainer $fixedSearchBar={fixedSearchBar}>
              <SearchInput
                value={searchValue}
                onChange={searchAction}
                placeholder={searchPlaceholder}
                displayType="large"
              />
              {fixedSearchBar && searchValue.length > 0 && (
                <Flex
                  justify="space-between"
                  align="center"
                  padding={`${paddings[3]} 0 0 0`}
                  wrap="wrap"
                >
                  <Flex maxWidth="75%">
                    <Text variant="h3" ellipsis padding={`0 ${paddings[4]}`}>
                      Searching for &quot;{searchValue}&quot;
                    </Text>
                    <LinkButton to="/t/library">Clear</LinkButton>
                  </Flex>
                </Flex>
              )}
            </SearchContainer>
          </Route>
          {isMobile && filterSection}
          {!fixedSearchBar && (
            <Flex
              justify="space-between"
              align="center"
              padding={`0 ${paddings[7]} ${paddings[1]} 0`}
              wrap="wrap"
            >
              {searchValue.length > 0 && (
                <Flex maxWidth="75%">
                  <Text variant="h3" ellipsis padding={`0 ${paddings[4]}`}>
                    Searching for &quot;{searchValue}&quot;
                  </Text>
                  <LinkButton to="/t/library">Clear</LinkButton>
                </Flex>
              )}
            </Flex>
          )}
          {fixedSearchBar && <Spacer size={searchValue.length > 0 ? 32 : 23} />}
          {children}
          <Spacer size={2} />
          <Flex justify="center">
            {!loading && (
              <>
                <Flex justify="end" align="center">
                  <Button
                    displayType="noStyles"
                    disabled={page <= 0}
                    testTag="prev-page"
                    onClick={() => {
                      history.push(
                        `${pathname}${updateQuery(query, {
                          pageNumber: (page - 1).toString(),
                        })}`,
                      );
                    }}
                  >
                    <Icon icon="arrowLeft" size="2em" color={page <= 0 ? 'gray5' : 'black'} />
                  </Button>
                  <Spacer horizontal />
                  <Text variant="h5">{total === 0 ? '1 / 1' : `${page + 1} / ${total}`}</Text>
                  <Spacer horizontal />
                  <Button
                    displayType="noStyles"
                    disabled={page + 1 >= total}
                    testTag="next-page"
                    onClick={() => {
                      history.push(
                        `${pathname}${updateQuery(query, {
                          pageNumber: (page + 1).toString(),
                        })}`,
                      );
                    }}
                  >
                    <Icon
                      icon="arrowRight"
                      size="2em"
                      color={page + 1 >= total ? 'gray5' : 'black'}
                    />
                  </Button>
                  <Spacer horizontal size={3} />
                </Flex>
                <Flex align="center">
                  <Label $isTextInput $noMargin>
                    {resultTotal === 0
                      ? '0 Results'
                      : `${page * PAGE_SIZE + 1} - ${
                          page * PAGE_SIZE + resultCount
                        } of ${resultTotal} Results`}
                  </Label>
                </Flex>
                <Spacer horizontal size={2} />
              </>
            )}
          </Flex>
          <Flex justify="center">
            {currentUser?.paperId && <Footer accountType={currentUser.accountType} />}
          </Flex>
          <Spacer size={4} />
        </Flex>
      </Flex>
    </BaseContentLayout>
  );
};

const GridContainer = styled.div<{ $isStudent: boolean }>`
  ${({ $isStudent }) =>
    $isStudent
      ? css`
          display: grid;
          grid-template-columns: repeat(auto-fill, 208px);
          grid-gap: ${paddings[4]};
          justify-content: center;
        `
      : css`
          display: grid;
          grid-template-columns: repeat(auto-fill, minmax(min(250px, 100%), 1fr));
          grid-gap: ${paddings[4]};
        `}

  padding: ${paddings[3]} ${paddings[4]};
`;

export const SearchResultsContainer = ({
  id,
  testTag,
  children,
}: {
  id?: string;
  testTag: string;
  children: ReactNode;
}) => {
  const pathname = useLocation().pathname;
  const isStudent = Boolean(matchPath(pathname, '/s/library'));
  return (
    <GridContainer id={id} data-test-tag={testTag} $isStudent={isStudent}>
      {children}
    </GridContainer>
  );
};
