import { ReadingTask, ReadingTaskDetails } from './ReadingTask';
import { ComprehensionTask, ComprehensionTaskDetails } from './ComprehensionTask';
import { ModelTask, ModelTaskDetails } from './ModelTask';
import { VocabTask, VocabTaskDetails } from './VocabTask';

export type Task = ReadingTask | ComprehensionTask | ModelTask | VocabTask;
export type TaskType = Task['type'];
export type TaskDetails =
  | ReadingTaskDetails
  | ComprehensionTaskDetails
  | ModelTaskDetails
  | VocabTaskDetails;

export const getTaskIcon = (task: Task) => {
  switch (task.type) {
    case 'ModelTask':
      return 'modelReading';
    case 'ComprehensionTask':
      return 'task';
    case 'ReadingTask':
      return 'microphone';
    case 'VocabTask':
      return 'vocab';
  }
};
