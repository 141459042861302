import { useStore } from 'src/Store';
import { Text, PDFReader, PlainTextReader, EmptyState } from 'src/components';
import { useLanguageCodeDetails } from 'src/modules/LanguageCodes';
import { ContentContainer } from 'src/pages/ReadingBuilder/ReadingBuilderHelpers';

export const MetadataStep = () => {
  const {
    contentType,
    pdfFileUrl,
    rotatePdf,
    pdfRotation,
    basicText,
    basicTextFile,
    languageCode,
  } = useStore(
    ({ ReadingBuilderData: rbd }) => ({
      contentType: rbd.contentType,
      pdfFileUrl: rbd.pdfFileUrl,
      rotatePdf: rbd.rotatePdf,
      pdfRotation: rbd.pdfRotation,
      basicText: rbd.basicText,
      basicTextFile: rbd.basicTextFile,
      languageCode: rbd.languageCode,
    }),
    [],
  );

  const languageCodeDetails = useLanguageCodeDetails(languageCode);

  if (['text', 'web'].includes(contentType)) {
    if (basicText) {
      return (
        <PlainTextReader
          languageCodeDetails={languageCodeDetails}
          textContent={basicText}
          enableTextRangeSelection={false}
        />
      );
    } else if (basicTextFile) {
      return (
        <PlainTextReader
          languageCodeDetails={languageCodeDetails}
          textFile={basicTextFile}
          enableTextRangeSelection={false}
        />
      );
    } else {
      return <Text>Nothing to display</Text>;
    }
  } else if (contentType === 'pdf' || contentType === 'pdf_by_page') {
    if (!pdfFileUrl) return <div>could not display content</div>;
    return <PDFReader onRotatePDF={rotatePdf} rotation={pdfRotation} src={pdfFileUrl} />;
  } else if (contentType === 'independent') {
    return (
      <ContentContainer>
        <EmptyState kind="independentReading" />
      </ContentContainer>
    );
  } else {
    throw new Error('Unsupported content type');
  }
};
