import { ReactNode, useEffect, useState } from 'react';
import { TestModeWindow } from './TestModeWindow';
import { BodyPortal } from 'src/components';

type TestModeProps = {
  children: ReactNode;
};

const modifiedWindow = window as unknown as TestModeWindow;
modifiedWindow.testModeEnabled = false;
modifiedWindow.testModeEnabledSubscribers = [];
modifiedWindow.setTestModeEnabled = (value: boolean) => {
  modifiedWindow.testModeEnabled = value;
  modifiedWindow.testModeEnabledSubscribers.forEach((fn: (value: boolean) => void) => {
    fn(value);
  });
};

// A component for enabling things on the screen exclusively in integration tests
export const TestMode = ({ children }: TestModeProps) => {
  const [testModeEnabled, setTestModeEnabled] = useState(false);

  useEffect(() => {
    setTestModeEnabled(modifiedWindow.testModeEnabled);

    const subscriber = (value: boolean) => {
      setTestModeEnabled(value);
    };
    modifiedWindow.testModeEnabledSubscribers.push(subscriber);

    return () => {
      modifiedWindow.testModeEnabledSubscribers = modifiedWindow.testModeEnabledSubscribers.filter(
        (fn) => fn !== subscriber,
      );
    };
  }, []);

  if (!testModeEnabled) return null;

  return (
    <BodyPortal>
      <div className="test-mode">{children}</div>
    </BodyPortal>
  );
};
