import styled from 'styled-components';
import { ReactNode } from 'react';
import { Flex, FlexPrefixProps } from 'src/components';
import { colors, paddings } from 'src/styles';
import { TopBar } from '../TopBar';
import { commonLayoutStyles } from 'src/modules/Layout';
import { ImmutableList } from 'src/modules/Immutable';
import { TopBarLink } from '../TopBar/TopBarHelpers';

type OnboardingLayoutProps = {
  children: ReactNode;
  secondaryAction?: ReactNode;
} & FlexPrefixProps;

const BaseOnboardingLayoutStyles = styled.div`
  ${commonLayoutStyles}

  display: grid;
  grid-template-rows: auto 1fr;
  grid-template-columns: 1fr;
`;

export const OnboardingLayout = ({
  children,
  secondaryAction,
  ...flexProps
}: OnboardingLayoutProps) => {
  return (
    <BaseOnboardingLayoutStyles>
      <TopBar
        navLinksOverride={ImmutableList<TopBarLink>()}
        secondaryAction={secondaryAction}
        showProfile={false}
        showPrimaryAction={false}
        alwaysShowSecondaryAction
        logoDisabled
      />
      <Flex
        grow={1}
        width="100%"
        align="center"
        direction="column"
        backgroundColor={colors.backgroundLight.hex}
        overflowY="auto"
        {...flexProps}
        padding={`${paddings[8]}`}
      >
        <Flex direction="column" maxWidth="500px" width="100%" minWidth={flexProps.minWidth}>
          {children}
        </Flex>
      </Flex>
    </BaseOnboardingLayoutStyles>
  );
};
