import { ErrorMap, emptyErrorMap } from 'src/modules/Api';

export type SignUpConfirmationSentState = {
  resending: boolean;
  resent: boolean;
  errors: ErrorMap;
  resend: (email: string | undefined) => () => void;
  load: () => void;
};

export const defaultState = {
  resending: false,
  resent: false,
  errors: emptyErrorMap(),
};
