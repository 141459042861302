import { useEffect } from 'react';
import { Prompt } from 'react-router-dom';

type PromptBeforeLeaveProps = {
  dirty: boolean;
  children?: React.ReactNode;
  message?: string;
};

export const PromptBeforeLeave = ({
  dirty,
  children,
  message = 'Leave without saving your changes?',
}: PromptBeforeLeaveProps) => {
  // When dirty changes, we want to set window.onbeforeunload to true
  // so that our prompt shows if the user tries to leave the website
  useEffect(() => {
    const onBeforeUnload = (event: BeforeUnloadEvent) => {
      // Cancel the event as stated by the standard.
      event.preventDefault();
      // Chrome requires returnValue to be set.
      event.returnValue = '';
    };

    if (dirty) {
      window.addEventListener('beforeunload', onBeforeUnload);
    } else {
      window.removeEventListener('beforeunload', onBeforeUnload);
    }

    return () => {
      window.removeEventListener('beforeunload', onBeforeUnload);
    };
  }, [dirty]);

  return (
    <>
      <Prompt message={message} when={dirty} />
      {children}
    </>
  );
};
