import { Button, Flex, FormGroup, LinkButton, Spacer, StandardModal, Text } from 'src/components';
import { useHistory, useQuery } from 'src/modules/Router';
import { useStore } from 'src/Store';

export const AssignmentTitle = () => {
  const query = useQuery();
  const history = useHistory();
  const { assessment, editingTitle, setField, setAssessmentField, errors, saving, cancel, submit } =
    useStore(
      ({ AssignmentBuilderData: s }) => ({
        assessment: s.assessment,
        editingTitle: s.editingTitle,
        setField: s.setField,
        setAssessmentField: s.setAssessmentField,
        errors: s.errors,
        saving: s.saving,
        cancel: s.cancel,
        submit: s.submit,
      }),
      [],
    );

  if (!assessment) return null;

  return (
    <>
      <Flex align="center" justify="space-between">
        <Text variant="h6" textTransform="uppercase">
          Title
        </Text>
        <LinkButton
          onClick={() => setField('editingTitle')(true)}
          color="primaryBlue"
          testTag="startEditTitle"
        >
          Edit
        </LinkButton>
      </Flex>
      <Text variant="h4" testTag="assessmentName">
        {assessment.name}
      </Text>

      {editingTitle && (
        <StandardModal
          openType="state"
          opened={editingTitle}
          header="Assignment Title"
          close={cancel}
          disableFadeClose
          footer={
            <>
              <Button onClick={cancel} displayType="link" color="primaryBlue" disabled={saving}>
                Cancel
              </Button>
              <Spacer horizontal size={3} />
              <Button loading={saving} onClick={() => submit({ query, history })}>
                Save
              </Button>
            </>
          }
        >
          <FormGroup
            type="text"
            name="name"
            label="Assignment Title"
            value={assessment.name}
            onChange={setAssessmentField('name')}
            errors={errors}
          />
        </StandardModal>
      )}
    </>
  );
};
