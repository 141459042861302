import React from 'react';

import { Spacer, Text } from 'src/components';
import styled from 'styled-components';
import { Color, colors } from 'src/styles';

const Container = styled.div`
  display: flex;
  align-items: center;
  min-height: 40px;
`;

const HalfDivider = styled.div<{ color: Color }>`
  width: 100%;
  border-width: 1px;
  border-style: solid;
  border-color: ${({ color }) => colors[color].hex};
`;

export const WordDivider = ({
  children,
  color = 'primaryBlue',
}: {
  children: React.ReactNode;
  color?: Color;
}) => (
  <Container>
    <HalfDivider color={color} />
    <Spacer horizontal size={4} />
    <Text color={color} variant="h6">
      {children}
    </Text>
    <Spacer horizontal size={4} />
    <HalfDivider color={color} />
  </Container>
);
