export type School = {
  id: string;
  name: string;
  city: string;
  state: string;
  country: string;
  stateAbbreviation: string | null;
  street: string | null;
  zipCode: number | null;
  districtId: string | null;
  county: string | null;
  isPrivate: boolean;
  isUserCreated: boolean;
  schoolCode: string;
};

export const emptySchool = () => ({
  id: '',
  name: '',
  city: '',
  state: '',
  country: '',
  stateAbbreviation: null,
  street: null,
  zipCode: null,
  districtId: null,
  county: null,
  isPrivate: false,
  isUserCreated: false,
  schoolCode: '',
});
