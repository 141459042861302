import { useStore } from 'src/Store';
import { CreateComment } from './CreateComment';
import { useBreakpoints } from '../../../styles/Breakpoints';
import { Flex, Spacer, EmptyState, StandardModal } from 'src/components';
import { Comment } from './Comment';
import { usageCapFeatureEnabled } from 'src/models';

export const CommentModal = () => {
  const {
    setField,
    commentModalOpened,
    accountType = 'student',
    comments,
    currentUser,
    assessment,
    isRecording,
  } = useStore(
    ({ AppData, SubmissionOverviewData }) => ({
      setField: SubmissionOverviewData.setField,
      assessment: SubmissionOverviewData.assessment,
      commentModalOpened: SubmissionOverviewData.commentModalOpened,
      comments: SubmissionOverviewData.comments,
      currentUser: AppData.currentUser,
      accountType: AppData.currentUser?.accountType,
      isRecording: AppData.isRecording,
    }),
    [],
  );

  const canOpenFeedback = usageCapFeatureEnabled(
    'isTeacherFeedbackEnabled',
    currentUser,
    assessment,
    true,
  );
  const width = useBreakpoints({ smallerThan: 'tabletSmall' }) ? '90%' : '550px';
  const height = '600px';
  if (!canOpenFeedback || !commentModalOpened) return null;

  return (
    <StandardModal
      opened
      openType="state"
      maxWidthOverride={width}
      minWidthOverride={width}
      minHeightOverride={height}
      maxHeightOverride={height}
      header={accountType === 'student' ? 'Teacher Feedback' : 'Leave Comments'}
      close={() => setField('commentModalOpened')(false)}
      disableFadeClose
      footer={
        accountType === 'student' ? null : (
          <Flex width="100%" testTag="create-comment-footer">
            <CreateComment />
          </Flex>
        )
      }
      disableClose={isRecording}
    >
      <Flex direction="column">
        {comments.isEmpty() ? (
          <Flex align="center" justify="center">
            <EmptyState kind="emptyComments" displayType="minimal" />
          </Flex>
        ) : (
          <>
            <Spacer size={2} />
            {comments
              .sortBy((c) => c.comment.createdAt)
              .map((comment, i) => (
                <Comment
                  key={comment.comment.id}
                  commentDetails={comment}
                  isLast={i === comments.size - 1}
                />
              ))}
            <Spacer size={4} />
          </>
        )}
      </Flex>
    </StandardModal>
  );
};
