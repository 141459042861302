import { useCallback, useState } from 'react';

import { useStore } from 'src/Store';
import { Loading, Flex, Button, Spacer, MicrophoneSettings, TextSettings } from 'src/components';
import { ReadingStudioLayoutTopBar } from 'src/layouts';
import {
  useReadingTaskSubmissionDetails,
  useIsPublicPreview,
  useBackPath,
} from './ReadingStudioHelpers';
import { useCompactLayout } from 'src/layouts/ReadingStudioLayout/ReadingStudioLayoutHelpers';
import { TaskList } from './TaskList';
import { paddings } from 'src/styles';
import { useHistory } from 'src/modules/Router';
import { secondaryTitleText } from 'src/models';

export const ReadingStudioTopBar = () => {
  const [micSettingsOpened, setMicSettingsOpened] = useState<boolean>(false);
  const [textSettingsOpened, setTextSettingsOpened] = useState<boolean>(false);
  const compactLayout = useCompactLayout();
  const openMicSettings = useCallback(() => setMicSettingsOpened(true), []);
  const closeMicSettings = useCallback(() => setMicSettingsOpened(false), []);
  const openTextSettings = useCallback(() => setTextSettingsOpened(true), []);
  const closeTextSettings = useCallback(() => setTextSettingsOpened(false), []);

  const {
    loading,
    assessment,
    mobileTaskListOpened,
    isRecording,
    toggleMobileTaskList,
    setField,
    leave,
  } = useStore(
    ({ ReadingStudioData: s, AppData: a }) => ({
      loading: s.loading,
      assessment: s.assessment,
      mobileTaskListOpened: s.mobileTaskListOpened,
      isRecording: a.isRecording,
      toggleMobileTaskList: s.toggleMobileTaskList,
      setField: s.setField,
      leave: s.leave,
    }),
    [],
  );

  const history = useHistory();

  const readingTaskSubmissionDetails = useReadingTaskSubmissionDetails();
  const isPublicPreview = useIsPublicPreview();
  const { reading, coverImageFile, author } = readingTaskSubmissionDetails || {};
  const backPath = useBackPath();
  const iconSharedProps = {
    paddingOverride: paddings.none,
    displayType: 'iconOnly',
    width: '36px',
    height: '36px',
    color: 'gray2',
    iconColor: 'black',
    iconSize: '24px',
  } as const;

  if (loading)
    return (
      <>
        <Spacer size={2} />
        <Loading flex flexHeight="auto" />
        <Spacer size={2} />
      </>
    );

  return (
    <ReadingStudioLayoutTopBar
      leftButton={
        isPublicPreview ? null : (
          <Button
            color="gray2"
            nav
            onClick={leave(backPath, history)}
            icon="backArrow"
            disabled={isRecording}
          >
            Leave
          </Button>
        )
      }
      rightButtons={
        <Flex>
          {compactLayout && (
            <>
              <Button
                icon="menu"
                onClick={toggleMobileTaskList}
                testTag="mobile-task-list"
                disabled={isRecording}
                {...iconSharedProps}
              />
              <Spacer horizontal size={3} />
            </>
          )}
          {!['PdfStoredFile', 'PdfByPageStoredFile'].includes(
            readingTaskSubmissionDetails?.contentFile?.type || '',
          ) && (
            <>
              <Button
                icon="text"
                onClick={openTextSettings}
                testTag="text-settings"
                {...iconSharedProps}
              />
              <Spacer horizontal size={3} />
            </>
          )}
          <Button
            icon="settings"
            onClick={openMicSettings}
            {...iconSharedProps}
            disabled={isRecording}
            testTag="top-bar-recording-settings"
          />
          <MicrophoneSettings opened={micSettingsOpened} close={closeMicSettings} />
          <TextSettings opened={textSettingsOpened} close={closeTextSettings} />
        </Flex>
      }
      thumbnailUrl={coverImageFile?.thumbnailUrl}
      readingTitle={reading?.name}
      authorName={secondaryTitleText(assessment, author)}
      webSourceUrl={reading?.webSourceUrl}
      drawer={compactLayout && mobileTaskListOpened ? <TaskList showClose /> : null}
      closeDrawer={() => setField('mobileTaskListOpened')(false)}
    />
  );
};
