import { useMemo, memo } from 'react';
import { useStore } from 'src/Store';
import { AssessmentExtended } from 'src/models';
import {
  Icon,
  SuperTable,
  SuperTableColumns,
  Flex,
  Spacer,
  Thumbnail,
  Text,
  Tooltip,
  EmptyState,
} from 'src/components';
import { useHistory, useParams, useQuery, queryMapToString } from 'src/modules/Router';
import { ImmutableList, ImmutableMap } from 'src/modules/Immutable';
import { formatDate, formatDateTime } from 'src/modules/Time';
import { formatStat, statStyles } from 'src/modules/Stat';
import { SubmissionResponse } from './StudentProgressMonitoringState';

const getAssessmentSubtext = (
  courseNames: string | undefined,
  assessment: AssessmentExtended,
  canView: boolean,
) => {
  if (!canView) return 'Private Submission';

  if (courseNames) return courseNames;

  if (assessment.isFreeRead)
    return formatDate(new Date(assessment.createdAt), { includeYear: true });

  return '';
};

const Empty = ({ tooltipText }: { tooltipText: string }) => (
  <Tooltip content={<Text>{tooltipText}</Text>}>
    <Text color="black">--</Text>
  </Tooltip>
);

export const UnmemoizedStudentSubmissionTable = ({
  rows,
}: {
  rows: ImmutableList<SubmissionResponse>;
}) => {
  const { assessmentMap, courseMap, assessmentToCourseMap } = useStore(
    ({ StudentProgressMonitoringData: s }) => ({
      assessmentMap: s.assessmentMap,
      courseMap: s.courseMap,
      assessmentToCourseMap: s.assessmentToCourseMap,
    }),
    [],
  );
  const history = useHistory();
  const studentId = useParams<{ userId: string }>().userId;
  const query = useQuery();

  const columns: SuperTableColumns<SubmissionResponse> = useMemo(
    () => [
      {
        id: 'assignment',
        name: 'Assignment',
        render: ({ assessmentId, canView, readingName }) => {
          const assessment = assessmentMap.get(assessmentId);
          if (!assessment) return null;

          const assessmentCourses = assessmentToCourseMap.get(assessment.id);

          const courseNames = assessmentCourses
            ?.map((ac) => courseMap.get(ac)?.name)
            .filter(Boolean)
            .join(', ');

          const subtext = getAssessmentSubtext(courseNames, assessment, canView);

          return (
            <Flex>
              <Spacer horizontal />
              <Thumbnail src={assessment.coverImageUrl} title={readingName} />
              <Spacer size={4} horizontal />
              <Flex direction="column" maxWidth="250px" justify="center">
                <Flex align="center">
                  {assessment.isUltimate && (
                    <Flex height="100%" padding="0 5px 0 0">
                      <Tooltip content="Upgraded to Ultimate Assignment">
                        <Icon
                          icon="ultimateAssignmentCentered"
                          size="1.25em"
                          testTag="ultimate-assessment"
                        />
                      </Tooltip>
                    </Flex>
                  )}
                  <Text variant="h6" ellipsis title={assessment.name} testTag="assessment-name">
                    {assessment.name}
                  </Text>
                </Flex>
                <Tooltip
                  disabled={canView}
                  content="You cannot view this submission because it belongs to another teacher"
                >
                  <Text variant="h7" color="gray5" title={subtext} ellipsis>
                    {subtext}
                  </Text>
                </Tooltip>
              </Flex>
            </Flex>
          );
        },
        sortType: 'sortBy',
        defaultSortDirection: 'desc',
        sortBy: ({ assessmentId }) => assessmentMap.get(assessmentId, { name: '' }).name,
      },
      {
        id: 'completion',
        name: 'Completion',
        render: ({ submission, contentType }) =>
          contentType === 'independent' ? (
            <Empty tooltipText="Cannot calculate completion for independent readings." />
          ) : (
            <Text variant="h6">{formatStat(statStyles.completion, submission.percentRead)}</Text>
          ),
        sortType: 'sortBy',
        defaultSortDirection: 'desc',
        sortBy: ({ submission }) => submission.percentRead ?? 0,
      },
      {
        id: 'wordsCorrectPerMinute',
        name: 'WCPM',
        tooltipContent: 'Words Correct Per Minute (Total Words Correct / Total Time Read)',
        render: ({ submission, contentType }) =>
          contentType === 'independent' ? (
            <Empty tooltipText="Cannot calculate WCPM for independent readings" />
          ) : (
            <Text variant="h6">{formatStat(statStyles.wcpm, submission.wcpm)}</Text>
          ),
        sortType: 'sortBy',
        defaultSortDirection: 'desc',
        sortBy: ({ submission, contentType }) =>
          contentType === 'independent' ? submission.wcpm ?? 0 : 0,
      },
      {
        id: 'wordsPerMinute',
        name: 'WPM',
        tooltipContent: 'Words Per Minute (Total Words / Total Time Read)',
        render: ({ submission }) => (
          <Text variant="h6">{formatStat(statStyles.wcpm, submission.wpm)}</Text>
        ),
        sortType: 'sortBy',
        defaultSortDirection: 'desc',
        sortBy: ({ submission }) => submission.wpm ?? 0,
      },
      {
        id: 'words',
        name: 'Words Read',
        render: ({ submission }) => (
          <Text variant="h6">{formatStat(statStyles.total_words, submission.totalWords) ?? 0}</Text>
        ),
        sortType: 'sortBy',
        defaultSortDirection: 'desc',
        sortBy: ({ submission }) => submission.totalWords ?? 0,
      },
      {
        id: 'time',
        name: 'Time',
        render: ({ submission }) => (
          <Text variant="h6">{formatStat(statStyles.time_taken, submission.timeRead)}</Text>
        ),
        sortType: 'sortBy',
        defaultSortDirection: 'desc',
        sortBy: ({ submission }) => submission.timeRead ?? 0,
      },
      {
        id: 'tasks',
        name: 'Tasks',
        render: (result) => (
          <Text variant="h6">
            {result.completedTasksCount ?? 0} / {result.totalTasksCount ?? 0}
          </Text>
        ),
        sortType: 'sortBy',
        defaultSortDirection: 'desc',
        sortBy: ({ completedTasksCount }) => completedTasksCount ?? 0,
      },
      {
        id: 'updatedAt',
        name: 'Student Edited',
        render: ({ submission }) =>
          submission.updatedAt ? (
            <Text variant="h6" testTag="student-edited">
              {formatDateTime(new Date(submission.updatedAt))}
            </Text>
          ) : (
            <Empty tooltipText="Student has not submitted their assignment." />
          ),
        sortType: 'sortBy',
        defaultSortDirection: 'desc',
        sortBy: ({ submission }) => new Date(submission.updatedAt).valueOf(),
      },
      {
        id: 'submittedAt',
        name: 'Turned In',
        textAlign: 'left',
        render: ({ submission }) => (
          <Flex width="100%" justify="center">
            {submission.finishedAt ? (
              <Tooltip
                content={
                  <Text>
                    Student turned in their assignment on{' '}
                    <b data-test-tag="submitted-at">
                      {formatDateTime(new Date(submission.finishedAt))}
                    </b>
                  </Text>
                }
              >
                <Icon icon="checkmark" color="success" fill testTag="submitted" />
              </Tooltip>
            ) : (
              <Empty tooltipText="Student has not submitted their assignment." />
            )}
          </Flex>
        ),

        sortType: 'sortBy',
        defaultSortDirection: 'desc',
        sortBy: ({ submission }) => new Date(submission.finishedAt).valueOf(),
      },
    ],
    [assessmentMap, assessmentToCourseMap, courseMap],
  );

  return (
    <SuperTable
      columns={columns}
      rows={rows}
      getRowId={({ submission }) => submission.id}
      queryParam="sortBy"
      rowClickable={({ canView }) => canView}
      onRowClick={({ submission }) =>
        history.push(
          `/submissions/${submission.id}${queryMapToString(
            ImmutableMap({
              backPath: `/t/progress-monitoring/${studentId}${queryMapToString(query)}`,
            }),
          )}`,
        )
      }
      initialSortSettings={{
        columnId: 'submittedAt',
        sortDirection: 'desc',
      }}
      emptyStateContent={<EmptyState kind="emptyStudentProgress" />}
    />
  );
};

export const StudentSubmissionTable = memo(UnmemoizedStudentSubmissionTable);
