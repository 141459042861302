import { useCallback } from 'react';
import styled from 'styled-components';

import { Button, Subscribe, Flex, Text, LinkButton, Spacer, TestMode } from 'src/components';
import { useToaster } from 'src/components/Toast';
import { colors, paddings } from 'src/styles';
import { useStore } from 'src/Store';
import { matchPath, useHistory, useLocation, useQuery } from 'src/modules/Router';
import { DateString } from 'src/modules/Time';
import { startZendeskTicket } from 'src/modules/Analytics/Analytics';

const BaseBanner = styled.div`
  width: 100%;
`;

type UserChannelMessage = {
  action: 'roster_sync';
  payload: {
    status: 'finished' | 'errored';
    lastSyncedAt: DateString | null;
    kind: 'manual' | 'auto';
  };
};

export const PaperSyncBanner = () => {
  const {
    currentUser,
    notificationKind,
    closeBanner,
    loadClasses,
    reloadUser,
    reloadAssignModal,
    sync,
    setField,
    getStatus,
    loadStudents,
  } = useStore(
    (s) => ({
      notificationKind: s.PaperSyncData.notificationKind,
      closeBanner: s.PaperSyncData.closeBanner,
      sync: s.PaperSyncData.sync,
      setField: s.PaperSyncData.setField,
      getStatus: s.PaperSyncData.getStatus,
      currentUser: s.AppData.currentUser,
      reloadUser: s.AppData.load,
      loadStudents: s.TeacherStudentListData.load,
      loadClasses: s.TeacherCourseListData.load,
      reloadAssignModal: s.AssignToData.load,
    }),
    [],
  );
  const status = getStatus();
  const { createToast } = useToaster();
  const query = useQuery();
  const assessmentId = query.get('assessmentId') || '';
  const courseId = query.get('selectedCourseId') || '';
  const history = useHistory();
  const pathname = useLocation().pathname;
  const onClassesPage = Boolean(matchPath(pathname, '/t/classes'));
  const onStudentsPage = Boolean(matchPath(pathname, '/t/students'));
  const showToast = Boolean(
    matchPath(pathname, ['/t/reading-builder', '/t/assignment-builder', '/reading-studio']),
  );
  const onAssignModal = Boolean(
    matchPath(pathname, ['/t/assignments/:id/assign', '/t/assignments/assign']),
  );

  const syncCount = currentUser
    ? currentUser.paperRosterAutoSyncCount + currentUser.paperRosterManualSyncCount
    : 0;
  const onUserMessage = useCallback(
    ({ action, payload: { status, lastSyncedAt, kind } }: UserChannelMessage) => {
      if (action === 'roster_sync') {
        setField('localStatus')(status);
        setField('localLastSyncedAt')(lastSyncedAt);
        if (status === 'finished') {
          reloadUser({ skipStateReset: true });

          if (onAssignModal) {
            reloadAssignModal({ assessmentId, courseId });
            setField('notificationKind')('hidden');
            return;
          }
          if (onClassesPage) {
            loadClasses({ history, pathname });
          }
          if (onStudentsPage) {
            loadStudents();
          }
          if (kind !== 'auto' || syncCount === 0) {
            if (showToast) {
              setField('notificationKind')('toast');
              createToast({ children: 'Your classes have synced!', color: 'success' });
            } else {
              setField('notificationKind')('banner');
            }
          }
        } else {
          setField('notificationKind')('banner');
        }
      }
    },
    [
      setField,
      onAssignModal,
      showToast,
      onClassesPage,
      onStudentsPage,
      syncCount,
      reloadAssignModal,
      assessmentId,
      courseId,
      createToast,
      loadClasses,
      history,
      pathname,
      reloadUser,
      loadStudents,
    ],
  );

  if (!currentUser) return null;

  return (
    <>
      <Subscribe channel="UserChannel" id={currentUser.id} onMessage={onUserMessage} />
      {notificationKind === 'banner' && (
        <BaseBanner>
          <Flex
            width="100%"
            backgroundColor={status === 'finished' ? colors.primaryBlue.hex : colors.warning.hex}
            padding={paddings[2]}
            align="center"
          >
            {status === 'finished' ? (
              <Flex grow={1} justify="center" align="center" testTag="syncing-banner">
                {onClassesPage ? (
                  <Text variant="largeText" color="white">
                    Your classes have been synced!
                  </Text>
                ) : (
                  <>
                    <Text variant="largeText" color="white">
                      Your classes have been synced! Click
                    </Text>
                    <Spacer horizontal />
                    <LinkButton
                      color="white"
                      onClick={() => setField('notificationKind')('hidden')}
                      to="/t/classes"
                    >
                      <Text variant="largeText" color="white" weightOverride="bold">
                        here
                      </Text>
                    </LinkButton>
                    <Spacer horizontal />
                    <Text variant="largeText" color="white">
                      to see your classes
                    </Text>
                  </>
                )}
              </Flex>
            ) : (
              <Flex grow={1} justify="center" align="center" testTag="syncing-error-banner">
                <Text variant="largeText" color="white">
                  There was an error syncing your classes. Click
                </Text>
                <Spacer horizontal />
                <LinkButton color="white" onClick={() => sync('manual')}>
                  <Text variant="largeText" color="white" weightOverride="bold">
                    here
                  </Text>
                </LinkButton>
                <Spacer horizontal />
                <Text variant="largeText" color="white">
                  to try again or
                </Text>
                <Spacer horizontal />
                <LinkButton color="white" onClick={startZendeskTicket}>
                  <Text variant="largeText" color="white" weightOverride="bold">
                    contact support
                  </Text>
                </LinkButton>
              </Flex>
            )}
            <Flex justify="end">
              <Button
                displayType="iconOnly"
                icon="close"
                iconColor="white"
                iconSize="1em"
                width="32px"
                height="32px"
                color={status === 'finished' ? 'primaryBlue' : 'warning'}
                testTag="close-syncing-banner"
                onClick={closeBanner}
              />
            </Flex>
          </Flex>
        </BaseBanner>
      )}
      <TestMode>
        <Button
          testTag="simulate-failed-sync"
          onClick={() =>
            onUserMessage({
              action: 'roster_sync',
              payload: { status: 'errored', lastSyncedAt: null, kind: 'manual' },
            })
          }
        >
          Simulate Failed Sync
        </Button>
        <Button
          testTag="simulate-successful-sync"
          onClick={() =>
            onUserMessage({
              action: 'roster_sync',
              payload: { status: 'finished', lastSyncedAt: null, kind: 'manual' },
            })
          }
        >
          Simulate Successful Sync
        </Button>
      </TestMode>
    </>
  );
};
