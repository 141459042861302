import { ChangeEvent, ReactNode, useCallback } from 'react';
import styled from 'styled-components';
import { useUniqueId } from 'src/modules/UniqueId';
import { Spacer } from '../Spacer';
import { Icon } from '../Icon';
import { Color } from 'src/styles';
import { Text } from '../Text';

const StyledCheckbox = styled.input`
  display: none;
`;

export type CheckboxInputOnChange = (newValue: boolean, evt: ChangeEvent<HTMLInputElement>) => void;

const Label = styled.label<{ disabled: boolean }>`
  cursor: ${(props) => (props.disabled ? 'unset' : 'pointer')};
  display: flex;
  align-items: center;
`;

const LabelContent = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

type CheckboxInputProps = {
  /** True/false value of the input */
  value: boolean;
  /** id of the checkbox */
  id?: string;
  /** Name used in ancestor form */
  name: string;
  /** Event handler */
  onChange?: CheckboxInputOnChange;
  /** Disabled */
  disabled?: boolean;
  /** Label content */
  label?: ReactNode;
  /** Fill color of the checkbox */
  fillColor?: Color;
  /** Color of the text */
  textColor?: Color;
  testTag?: string;
};

export const CheckboxInput = ({
  onChange,
  value,
  id: propId,
  label,
  disabled = false,
  fillColor = 'primaryBlue',
  textColor = 'black',
  testTag,
  ...basicProps
}: CheckboxInputProps) => {
  const id = useUniqueId(propId);
  const realOnChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      onChange?.(event.target.checked, event);
    },
    [onChange],
  );
  return (
    <Label htmlFor={id} disabled={disabled}>
      <StyledCheckbox
        {...basicProps}
        id={id}
        disabled={disabled}
        checked={value}
        type="checkbox"
        onChange={realOnChange}
        data-test-tag={testTag}
      />
      <Icon
        icon={value ? 'checkboxChecked' : 'checkboxUnchecked'}
        color={value ? 'white' : 'gray5'}
        size="1.25em"
        flexShrink={0}
        fill
        fillColor={value ? fillColor : 'white'}
      />
      <Spacer size={2} horizontal />
      <LabelContent>
        <Text color={textColor}>{label}</Text>
      </LabelContent>
    </Label>
  );
};
