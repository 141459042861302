import { useEffect } from 'react';
import { useQuery, useHistory } from 'src/modules/Router';
import { useStore } from 'src/Store';
import { SimpleLayout } from 'src/layouts';

export const JoinCourseRedirect = () => {
  const history = useHistory();
  const query = useQuery();
  const courseCode = query.get('courseCode') || '';
  const { loading, currentUser } = useStore((s) => s.AppData, []);

  useEffect(() => {
    const doIt = () => {
      if (loading) return;

      if (!currentUser) {
        return history.replace(
          `/sign-up/student/class-code?courseCode=${encodeURIComponent(courseCode)}`,
        );
      }

      if (currentUser.accountType === 'teacher') {
        return history.replace('/t/');
      }

      history.replace(`/s/assignments?joinCode=${encodeURIComponent(courseCode)}`);
    };

    doIt();
  }, [courseCode, currentUser, history, loading]);

  return <SimpleLayout loading={loading} />;
};
