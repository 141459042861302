import { RecordingSequence } from './RecordingSequence';
import { TranscriptableAudioStoredFile } from './TranscriptableAudioStoredFile';

export type ModelTask = {
  id: string;
  name: string;
  type: 'ModelTask';
  status: 'active' | 'empty' | 'deleted';
};

export type ModelTaskDetails = {
  type: 'ModelTask';
  task: ModelTask;
  recordingSequence: RecordingSequence;
  audioFile: TranscriptableAudioStoredFile | null;
};
