import { subDataCreator } from 'src/State';
import { defaultState } from './TeacherAssignmentListState';
import { makeQueryString } from 'src/modules/Api';
import { ImmutableMap, setToggle } from 'src/modules/Immutable';
import { Course, User, AssessmentDetails } from 'src/models';

type LoadResponse = {
  assessmentMap: ImmutableMap<string, AssessmentDetails>;
  courseMap: ImmutableMap<string, Course>;
  studentMap: ImmutableMap<string, User>;
  currentUser: User;
};

export const createDefaultTeacherAssignmentListData = subDataCreator(
  'TeacherAssignmentListData',
  defaultState,
  ({ set, setField, get, fetchJson }) => ({
    setField,
    toggleAssignmentCardOpen: (value: string) => {
      set(({ openAssignmentCardSet }) => ({
        openAssignmentCardSet: setToggle(openAssignmentCardSet, value),
      }));
    },
    load: (history, pathname) => {
      set(defaultState);
      fetchJson(`/api/assessments/teacher_index`, {
        onSuccess: (data: LoadResponse) => {
          if (data.currentUser.onboardingStatus === 'needed') {
            history.replace(`/t/onboarding${makeQueryString({ redirectPath: pathname })}`);
          } else {
            set({
              assessmentMap: data.assessmentMap,
              courseMap: data.courseMap,
              studentMap: data.studentMap,
              loading: false,
            });
          }
        },
      });
    },
    updateAssessments: (assessment) => {
      const stale = get().assessmentMap.get(assessment.id);
      if (stale) {
        set(({ assessmentMap }) => ({
          assessmentMap: assessmentMap.set(assessment.id, {
            ...stale,
            assessment: {
              ...stale.assessment,
              ...assessment,
            },
          }),
        }));
      }
    },
  }),
);
