import { colors, paddings, useBreakpoints } from 'src/styles';
import { Bold } from '../Bold';
import { LinkButton, Button } from '../Button';
import { Flex } from '../Flex';
import { Spacer } from '../Spacer';
import { Text } from '../Text';
import { AccountType } from 'src/models';
import { useState } from 'react';
import { StandardModal } from '../Modal';

export const Footer = ({ accountType = 'student' }: { accountType: AccountType }) => {
  const showMobile = useBreakpoints({ smallerThanOrEqualTo: 'tabletSmall' });
  const [showDisclaimer, setShowDisclaimer] = useState(false);
  return (
    <Flex
      align="center"
      direction={showMobile ? 'column' : 'row'}
      backgroundColor={colors.backgroundLight.hex}
      padding={`${paddings[4]} ${paddings[8]}`}
    >
      <Text>
        © <Bold>Paper 2023 All rights reserved</Bold>
      </Text>
      {showMobile ? (
        <Spacer size={2} />
      ) : (
        <Text padding={`${paddings.none} ${paddings[3]}`}>•</Text>
      )}
      {accountType === 'student' ? (
        <LinkButton href="https://pages.paper.co/privacy-statement" newTab>
          Privacy Policy
        </LinkButton>
      ) : (
        <>
          <Button onClick={() => setShowDisclaimer(!showDisclaimer)} displayType="link">
            Platform Disclaimer
          </Button>
          <StandardModal
            openType="state"
            opened={showDisclaimer}
            close={() => setShowDisclaimer(false)}
            header="Platform Disclaimer"
            maxWidthOverride="700px"
          >
            Links to content shared by end users or tutors anywhere in the Platform are not endorsed
            by Paper Education Company Inc. and/or its affiliates (“Paper”). Paper does not
            represent or warrant it has any rights in or to such content or materials, and disclaims
            any liability in regards to the use or reliance on such materials or content. Reliance
            on and use of such materials is at the sole discretion of the end user, and at their own
            risk. Any user that uploads information or content represents and warrants that they
            have full legal authority to share such content to the platform and more broadly as
            applicable, including but not limited to the Paper Reading teacher community, and takes
            full legal responsibility for and shall indemnify Paper and hold it harmless in the
            event of any third party claims due to copyright or other intellectual property
            infringement claims arising as a result of such material and content uploads, or the
            reliance thereon.
          </StandardModal>
        </>
      )}
    </Flex>
  );
};
