import styled from 'styled-components';
import { colors, paddings, borderRadii, RequiredDollarPrefix } from 'src/styles';
import { ImmutableList } from 'src/modules/Immutable';
import { NoStylesButton } from 'src/components';
import { ellipsify } from 'src/modules/String';

interface WordToggleInputProps {
  id?: string;
  value: string;
  onChange: (value: string) => void;
  options: ImmutableList<{
    label: string;
    value: string;
  }>;
  showMobile?: boolean;
}

type InternalProps = {
  selected?: boolean;
  showMobile?: boolean;
};

type WordToggleInputInternalProps = RequiredDollarPrefix<InternalProps>;

const WordToggleInputContainer = styled.div`
  background-color: ${colors.backgroundDark.disabled};
  padding: ${paddings[1]};
  border-radius: ${borderRadii[1]};
  width: fit-content;
`;

const ButtonContentStyles = styled.div<WordToggleInputInternalProps>`
  display: inline-block;
  color: ${({ $selected }) => ($selected ? colors.white.hex : colors.white.disabled)};
  background-color: ${({ $selected }) =>
    $selected ? colors.backgroundDark.hex : colors.backgroundDark.disabled};
  padding: ${({ $showMobile }) =>
    $showMobile
      ? `${paddings[1]} ${paddings[8]}`
      : `${paddings.none} ${paddings[8]} ${paddings.none} ${paddings[8]}`};
  border-radius: ${borderRadii[1]};
  width: fit-content;
  transition: all 0.4s ease;
`;

export const WordToggleInput = ({
  id,
  value,
  onChange,
  options,
  showMobile = false,
}: WordToggleInputProps) => {
  return (
    <WordToggleInputContainer id={id}>
      {options.map((option) => (
        <NoStylesButton
          key={option.value}
          paddingOverride={paddings.none}
          onClick={option.value !== value ? () => onChange(option.value) : undefined}
        >
          <ButtonContentStyles
            title={option.label}
            $selected={option.value === value}
            $showMobile={showMobile}
          >
            {ellipsify(option.label, 20)}
          </ButtonContentStyles>
        </NoStylesButton>
      ))}
    </WordToggleInputContainer>
  );
};
