import { subDataCreator } from 'src/State';
import { defaultState, AssignToStateResponse } from './AssignToState';
import { trackAssessmentAssigned } from 'src/modules/Analytics/AnalyticsEvents';

export const createDefaultAssignToData = subDataCreator(
  'AssignToData',
  defaultState,
  ({ set, get, setField, fetchJson }) => ({
    setField,
    submit: (createToast) => {
      const {
        currRegistrationAssessments,
        courseAssessmentsAssigned,
        assessmentId,
        selectedCourse,
        load,
      } = get();

      if (!assessmentId || !selectedCourse?.id) return;

      set({ saving: true, saved: false, error: false });
      fetchJson(`/api/registration_assessments/${assessmentId}`, {
        method: 'PATCH',
        data: {
          registrationAssessmentList: currRegistrationAssessments,
          courseAssessment: courseAssessmentsAssigned.find(
            (ca) => ca.courseId === selectedCourse.id,
          ),
          courseId: selectedCourse.id,
        },
        onSuccess: ({
          success,
          currentUserStatus,
        }: {
          success: boolean;
          currentUserStatus: string;
        }) => {
          load({ assessmentId, setDefault: false });
          if (currentUserStatus === 'needs_subscription') {
            createToast({
              children:
                'You are now eligible for a free trial of Readlee Pro! Check your email for details',
              color: 'success',
            });
          }
          trackAssessmentAssigned({ assessmentId });
          setTimeout(() => set({ saving: false, saved: true, error: !success }), 500);
        },
      });
    },
    setSelectedCourse: (course) => {
      const { courseRegistrationAssessments } = get();
      const registrationAssessmentData = courseRegistrationAssessments.get(course.id);

      set({
        selectedCourse: course,
        currRegistrationAssessments: registrationAssessmentData || null,
        saving: false,
        saved: false,
      });
    },
    load: ({ assessmentId, courseId, setDefault = true }) => {
      if (setDefault) set({ ...defaultState, loading: true });
      fetchJson(`/api/registration_assessments/${assessmentId}`, {
        onSuccess: (data: AssignToStateResponse) => {
          set({
            loading: false,
            courses: data.courses,
            courseRegistrationAssessments: data.courseRegistrationAssessments,
            courseAssessmentsAssigned: data.courseAssessmentsAssigned,
            assessmentId: assessmentId,
            title: data.assessmentTitle,
          });
          const course = data.courses.find((c) => c.id === courseId) || null;
          if (course) get().setSelectedCourse(course);
        },
      });
    },
    updateCheckbox: (registrationAssessment, createToast) => () => {
      const { submit } = get();
      set((state) => {
        const { currRegistrationAssessments } = state;
        if (!currRegistrationAssessments) return state;

        const index = currRegistrationAssessments.findIndex((a) => a === registrationAssessment);
        const newAssignments = currRegistrationAssessments.setIn(
          [index, 'assigned'],
          !registrationAssessment.assigned,
        );
        return { ...state, currRegistrationAssessments: newAssignments };
      });
      submit(createToast);
    },
    updateVisibilitySettings: (registrationAssessment, value, createToast) => () => {
      const { submit } = get();
      set((state) => {
        const { currRegistrationAssessments } = state;
        if (!currRegistrationAssessments) return state;

        const index = currRegistrationAssessments.findIndex((a) => a === registrationAssessment);
        const newAssignments = currRegistrationAssessments.setIn(
          [index, 'submissionVisibility'],
          value,
        );
        return { ...state, currRegistrationAssessments: newAssignments };
      });
      submit(createToast);
    },
    updateCourseAssessment: (createToast) => () => {
      const { submit, optimisticUpdateCourseAssessment } = get();
      optimisticUpdateCourseAssessment();
      submit(createToast);
    },
    optimisticUpdateCourseAssessment: () => {
      const { courseAssessmentsAssigned, assessmentId, selectedCourse } = get();
      if (!assessmentId || !selectedCourse?.id) return;

      const courseAssessment = courseAssessmentsAssigned.find(
        (ca) => ca.courseId === selectedCourse.id,
      );
      if (!courseAssessment) return;

      set({
        courseAssessmentsAssigned: courseAssessmentsAssigned
          .filter((ca) => ca.courseId !== selectedCourse?.id)
          .push({ ...courseAssessment, assigned: !courseAssessment.assigned }),
      });
    },
    toggleAll: (selected, createToast) => () => {
      const {
        submit,
        optimisticUpdateCourseAssessment,
        courseAssessmentsAssigned,
        assessmentId,
        selectedCourse,
      } = get();
      if (!assessmentId || !selectedCourse?.id) return;

      set((state) => {
        if (!state.currRegistrationAssessments) return state;
        return {
          ...state,
          currRegistrationAssessments: state.currRegistrationAssessments?.map((assignment) => ({
            ...assignment,
            assigned: !selected,
          })),
        };
      });

      const courseAssessment = courseAssessmentsAssigned.find(
        (ca) => ca.courseId === selectedCourse.id,
      );

      if (selected === courseAssessment?.assigned) {
        optimisticUpdateCourseAssessment();
      }

      submit(createToast);
    },
  }),
);
