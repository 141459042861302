import { subDataCreator } from 'src/State';
import { defaultState, CourseResponse, StatResponse } from './ProgressMonitoringState';
import { ImmutableList, ImmutableMap } from 'src/modules/Immutable';
import { TimeLabel } from 'src/components';
import { Course } from 'src/models';

export const createDefaultProgressMonitoringData = subDataCreator(
  'ProgressMonitoringData',
  defaultState,
  ({ set, get, setField, fetchJson }) => ({
    setField,
    load: (id, startTime, dateRange) => {
      set({ isLoading: true });
      const { cache } = get();
      const courseCache = cache.stats.get(id);
      const queryCache = courseCache?.get(dateRange);

      if (queryCache && cache.courses) {
        set({
          isLoading: false,
          courses: cache.courses,
          stats: queryCache,
        });
      } else {
        fetchJson(`/api/courses/course_summary`, {
          data: { id, startTime },
          onSuccess: ({ stats, courses, teachersMap }: CourseResponse) => {
            // Caching has been messing people up more and more, let's incur the cost to see if people complain
            // const courseMap =
            //   courseCache || ImmutableMap<TimeLabel, ImmutableMap<string, StatResponse>>();
            // const updatedCourseMap = courseMap.set(dateRange, ImmutableMap(stats));
            // const updatedStatsCache = cache.stats.set(id, updatedCourseMap);

            set({
              isLoading: false,
              courses: courses,
              stats: ImmutableMap(stats),
              teachersMap: ImmutableMap(teachersMap),
              // cache: {
              //   courses: courses,
              //   stats: updatedStatsCache,
              // },
            });
          },
        });
      }
    },
    clearCache: () =>
      set({
        cache: {
          courses: ImmutableList<Course>(),
          stats: ImmutableMap<
            string,
            ImmutableMap<TimeLabel, ImmutableMap<string, StatResponse>>
          >(),
        },
      }),
  }),
);
