import { ModelTaskDetails } from './ModelTask';

export type ModelTaskSubmission = {
  id: string;
  completed: boolean;
};

export type ModelTaskSubmissionDetails = ModelTaskDetails & {
  taskSubmission: ModelTaskSubmission;
};
