import { default as LogoFullName } from 'src/icons/logo_full_name.svg';
import { default as LogoFullNamePro } from 'src/icons/logo_full_name_pro.svg';
import { default as LogoFullNameUltimate } from 'src/icons/logo_full_name_ultimate.svg';
import { default as LogoPaperReading } from 'src/icons/paper_reading_logo.svg';
import { default as LogoFirstLetter } from 'src/icons/logo_first_letter.svg';
import { colors, CssColor } from 'src/styles';
import { ExtendedUser, Plan } from 'src/models';

export type LogoSize = 'small' | 'medium' | 'large';

const getPixelSize = (strSize: LogoSize, type: LogoType) => {
  if (strSize === 'small') {
    if (type === 'paper-reading') return '25px';
    return '16px';
  } else if (strSize === 'medium') {
    return '40px';
  } else {
    return '80px';
  }
};

const logoTypes = {
  'first-letter': LogoFirstLetter,
  'full-name': LogoFullName,
  'full-name-pro': LogoFullNamePro,
  'full-name-ultimate': LogoFullNameUltimate,
  'paper-reading': LogoPaperReading,
} as const;

export const getLogoType = (user: ExtendedUser | null, plan: Plan) => {
  if (!user) return;
  if (user.paperSyncedAt) return 'paper-reading';
  if (plan.isBasicPlan) return 'full-name';
  return user.flags.isTeacherFeedbackEnabled ? 'full-name-ultimate' : 'full-name-pro';
};

export type LogoType = keyof typeof logoTypes;

type LogoProps = {
  size: LogoSize;
  type?: LogoType;
  color?: CssColor;
};

export const Logo = ({
  size,
  color = colors.primaryBlue.hex,
  type = 'first-letter',
}: LogoProps) => {
  const Elem = logoTypes[type];
  return (
    <Elem
      fill={color}
      style={{ height: getPixelSize(size, type), width: 'auto' }}
      width="100%"
      height="100%"
      stroke={color}
      strokeWidth={0}
      data-test-tag={`logo-${type}`}
    />
  );
};
