import { Text, Flex, Button, Spacer, Icon } from 'src/components';
import { paddings } from 'src/styles';

type DrawerExpandButtonProps = {
  expanded: boolean;
  toggleOpen: () => void;
};

export const DrawerExpandButton = (props: DrawerExpandButtonProps) => {
  return (
    <Button
      displayType="link"
      height="fit-content"
      paddingOverride={paddings.none}
      color="primaryBlue"
      onClick={props.toggleOpen}
      width="fit-content"
    >
      <Flex align="center">
        <Text variant="h7" lineHeightOverride="100%">
          {props.expanded ? 'Exit Full Screen' : 'Open in Full Screen'}
        </Text>
        <Spacer horizontal size={2} />
        <Icon icon={props.expanded ? 'collapse' : 'expand'} />
      </Flex>
    </Button>
  );
};
