import { MouseEventHandler, ReactNode, useEffect, useMemo } from 'react';
import styled, { css } from 'styled-components';
import { BodyPortal, Form } from 'src/components';
import {
  colors,
  rgba,
  borderRadii,
  Width,
  breakpoints,
  Height,
  cssIfTruthy,
  useBreakpoints,
  useWindowSize,
  zIndices,
} from 'src/styles';
import { useUniqueId } from 'src/modules/UniqueId';
import { Route } from 'react-router-dom';
import { ConfettiDrop, ConfettiEmitter } from 'src/modules/Confetti';
import { useStore } from 'src/Store';

type ModalFadeProps = {
  $opened: boolean;
};
const ModalFade = styled.div<ModalFadeProps>`
  display: ${({ $opened }) => ($opened ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
  background: ${rgba(colors.gray6.hex, 0.5)};
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: ${zIndices.modal};
`;

export const modalBreakpoint = 'mobileLarge';

type BaseModalProps = {
  $opened: boolean;
  $forceFullscreenOverride: boolean;
  $isStudent: boolean;
  $hasFooter: boolean;
  $minWidthOverride?: Width;
  $maxWidthOverride?: Width;
  $minHeightOverride?: Height;
  $maxHeightOverride?: Height;
  $paddingOverride?: string;
};

const BaseModal = styled.div<BaseModalProps>`
  display: ${({ $opened }) => ($opened ? 'flex' : 'none')};
  flex-direction: column;
  background: ${colors.white.hex};
  ${({
    $forceFullscreenOverride,
    $minWidthOverride,
    $maxWidthOverride,
    $minHeightOverride,
    $maxHeightOverride,
    $isStudent,
    $hasFooter,
  }) => {
    const mobileStyles = css`
      width: 100%;
      // default to 100vh, gets overridden by safari fix below
      height: 100vh;
      padding-bottom: ${$isStudent || !$hasFooter
        ? '0'
        : '72px'}; // This gives space for our help controls to not overlap important elements on the screen
      overflow-y: auto;
    `;
    if ($forceFullscreenOverride) {
      return mobileStyles;
    } else {
      return css`
        /* Mobile Styles */
        @media (max-width: ${breakpoints[modalBreakpoint]}px) {
          ${mobileStyles}
        }

        /* Desktop/Tablet Styles */
        @media (min-width: ${breakpoints[modalBreakpoint] + 1}px) {
          ${cssIfTruthy('min-width', $minWidthOverride, `${breakpoints[modalBreakpoint] * 0.9}px`)}
          ${cssIfTruthy('max-width', $maxWidthOverride, '90vw')}
          ${cssIfTruthy('min-height', $minHeightOverride, '0')}
          ${cssIfTruthy('max-height', $maxHeightOverride, '90vh')}
          border-radius: ${borderRadii[5]};
        }
      `;
    }
  }}
  ${({ $paddingOverride }) => cssIfTruthy('padding', $paddingOverride)}
`;

type CommonProps = {
  children: ReactNode;
  close?: () => void;
  testTag?: string;
  onSubmit?: () => void;
  minWidthOverride?: Width;
  maxWidthOverride?: Width;
  minHeightOverride?: Height;
  maxHeightOverride?: Height;
  showConfetti?: boolean;
  forceFullscreen?: boolean;
  paddingOverride?: string;
  disableFadeClose?: boolean;
  hasFooter?: boolean;
  confettiLoop?: boolean;
};

type StateOpenedProps = {
  opened: boolean;
  openType: 'state';
};

type PathOpenedProps = {
  openedPath: string;
  openType: 'path';
};

type OpenedProps = StateOpenedProps | PathOpenedProps;

export type ModalProps = CommonProps & OpenedProps;

export const Modal = (props: ModalProps) => {
  const {
    children,
    openType,
    close,
    showConfetti = false,
    forceFullscreen = false,
    paddingOverride,
    disableFadeClose = false,
    hasFooter = true,
    confettiLoop = true,
  } = props;
  const modalFadeId = useUniqueId();
  const modalId = useUniqueId();

  const fadeClose: MouseEventHandler<HTMLDivElement> = (event) => {
    if (!disableFadeClose && (event.target as HTMLElement).id === modalFadeId) {
      props.close?.();
    }
  };

  const isMobile = useBreakpoints({ smallerThanOrEqualTo: modalBreakpoint });

  useEffect(() => {
    const onKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Esc' || event.key === 'Escape') {
        close?.();
      }
    };
    document.addEventListener('keydown', onKeyDown);

    return () => {
      document.removeEventListener('keydown', onKeyDown);
    };
  }, [close]);

  const opened = openType === 'state' ? props.opened : true;

  const leftConfettiStyles = useMemo(
    () => (isMobile ? { position: 'absolute', top: '5%', left: '35%' } : {}),
    [isMobile],
  );
  const rightConfettiStyles = useMemo(
    () => (isMobile ? { position: 'absolute', top: '5%', right: '35%' } : {}),
    [isMobile],
  );

  const windowSize = useWindowSize();
  const isStudent = useStore(
    ({ AppData: { currentUser } }) => !currentUser || currentUser.accountType === 'student',
    [],
  );
  const content = (
    <BodyPortal>
      <ConfettiDrop enabled={isMobile && showConfetti} />
      <ModalFade id={modalFadeId} $opened={opened} onClick={fadeClose}>
        <ConfettiEmitter
          enabled={!isMobile && showConfetti}
          angle={225}
          style={leftConfettiStyles}
          loop={confettiLoop}
        />
        <BaseModal
          id={modalId}
          $opened={opened}
          data-test-tag={props.testTag}
          $minWidthOverride={props.minWidthOverride}
          $maxWidthOverride={props.maxWidthOverride}
          $minHeightOverride={props.minHeightOverride}
          $maxHeightOverride={props.maxHeightOverride}
          $forceFullscreenOverride={forceFullscreen}
          $paddingOverride={paddingOverride}
          $isStudent={isStudent}
          $hasFooter={hasFooter}
          // Deal with safari bug where 100vh takes up too much space
          style={forceFullscreen || isMobile ? { height: `${windowSize.height}px` } : {}}
        >
          {props.onSubmit ? (
            <Form
              onSubmit={props.onSubmit}
              flexDirection="column"
              display="flex"
              height={forceFullscreen || isMobile ? `${windowSize.height}px` : 'auto'}
            >
              {children}
            </Form>
          ) : (
            children
          )}
        </BaseModal>
        <ConfettiEmitter
          enabled={!isMobile && showConfetti}
          angle={315}
          style={rightConfettiStyles}
          loop={confettiLoop}
        />
      </ModalFade>
    </BodyPortal>
  );

  return openType === 'state' ? content : <Route path={props.openedPath}>{content}</Route>;
};
