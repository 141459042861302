import { useStore } from 'src/Store';
import { useQuery, useHistory, useParams, queryMapToString } from 'src/modules/Router';
import { Button, Spacer, Text, StandardModal, Bold } from 'src/components';
import { Route } from 'react-router-dom';

const InternalDeleteAccountModal = () => {
  const history = useHistory();
  const query = useQuery();
  const params = useParams<{ id: string }>();
  const { results, deleteUser } = useStore((s) => s.UserSearchData, []);

  const { user = null, hasCoTeachers = null } =
    results.find(({ user }) => user.id === params.id) || {};
  if (!user) return null;

  const close = () => history.push(`/m/user-search${queryMapToString(query)}`);
  return (
    <StandardModal
      header={`Delete Confirmation for ${user.displayName}`}
      close={close}
      openType="state"
      opened
      footer={
        <>
          <Button onClick={close} displayType="link" color="primaryBlue">
            Cancel
          </Button>
          <Spacer horizontal size={3} />
          <Button color="danger" onClick={deleteUser(user, history, query)}>
            Confirm Delete
          </Button>
        </>
      }
    >
      <Text variant="h4">
        You are about to delete <Bold>{user.displayName}&apos;s</Bold> account!
      </Text>
      <Spacer />
      <ul>
        <li>
          Deleting this account will also delete all associated registrations, disassociate any SSO
          IDs, and hide all comments created by the teacher!
        </li>
        <li>This DOES NOT delete their data from our systems</li>
        <li>This will not fulfil GDPR obligations</li>
        {hasCoTeachers && (
          <li>
            <b>
              This teacher has co-teachers! Contact engineering to verify if we want to delete this
              user!
            </b>
          </li>
        )}
      </ul>
    </StandardModal>
  );
};

export const DeleteAccountModal = () => (
  <Route path="/m/user-search/:id/delete">
    <InternalDeleteAccountModal />
  </Route>
);
