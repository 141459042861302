import {
  AvatarEyes,
  AvatarHat,
  AvatarMouth,
  AvatarSkin,
  ExtendedUser,
  StoreItem,
  Transaction,
} from 'src/models';
import { ErrorMapResponse } from 'src/modules/Api';
import { choice, ImmutableList, ImmutableMap } from 'src/modules/Immutable';
import { subDataCreator } from 'src/State';
import { defaultState } from './AvatarBuilderState';
import { emptyErrorMap } from '../../modules/Api';

type StoreData = {
  purchasedItems: ImmutableList<StoreItem>;
  itemsToBuy: ImmutableList<StoreItem>;
};

type PurchaseData = {
  success: boolean;
  errors: ErrorMapResponse;
  currentUser: ExtendedUser;
  purchasedItems: ImmutableList<StoreItem>;
  itemsToBuy: ImmutableList<StoreItem>;
  transaction: Transaction;
};

export const createDefaultAvatarBuilderData = subDataCreator(
  'AvatarBuilderData',
  defaultState,
  ({ setField, set, setFull, get, getFull, fetchJson }) => ({
    setField,
    load: () => {
      getFull().AppData.load({
        skipStateReset: true,
        onSuccess: (data) => set({ localUser: data.currentUser, dirty: false, itemToBuy: null }),
      });
      fetchJson(`/api/store_items`, {
        onSuccess: ({ itemsToBuy, purchasedItems }: StoreData) =>
          set({ itemsToBuy, purchasedItems }),
      });
    },
    save: (createToast) => {
      const { localUser } = get();
      if (!localUser) return;

      set({ saving: true });
      getFull().AppData.setUserFields(
        ImmutableMap({
          avatarSkin: localUser.avatarSkin,
          avatarMouth: localUser.avatarMouth,
          avatarEyes: localUser.avatarEyes,
          avatarHat: localUser.avatarHat,
        }),
        createToast,
        false,
        () => set({ saving: false, dirty: false }),
      );
    },
    undo: () => {
      set({ localUser: getFull().AppData.currentUser, dirty: false });
    },
    rollDice: () => {
      const { localUser, purchasedItems } = get();

      const skins: ImmutableList<AvatarSkin> = purchasedItems
        .filter((item) => item.itemType === 'skin')
        .map((skin) => skin.name as AvatarSkin);
      const eyes: ImmutableList<AvatarEyes> = purchasedItems
        .filter((item) => item.itemType === 'eyes')
        .map((skin) => skin.name as AvatarEyes);
      const mouths: ImmutableList<AvatarMouth> = purchasedItems
        .filter((item) => item.itemType === 'mouth')
        .map((skin) => skin.name as AvatarMouth);
      const hats: ImmutableList<AvatarHat> = purchasedItems
        .filter((item) => item.itemType === 'hat')
        .map((skin) => skin.name as AvatarHat);

      if (localUser) {
        set({
          dirty: true,
          itemToBuy: null,
          localUser: {
            ...localUser,
            avatarSkin: choice(skins) || 'blue',
            avatarEyes: choice(eyes) || 'regular',
            avatarMouth: choice(mouths) || 'smile',
            avatarHat: choice(hats) || 'nothing',
          },
        });
      }
    },
    setAttribute: (key) => (value) => {
      const { localUser } = get();
      if (!localUser) return;

      set({
        dirty: true,
        localUser: { ...localUser, [key]: value },
      });
    },
    setItem: (item, fieldName) => () => {
      const { localUser, purchasedItems, itemToBuy, setAttribute } = get();
      const purchased = purchasedItems.contains(item);

      if (purchased) {
        set({ itemToBuy: null });
      } else if (itemToBuy) {
        set({ itemToBuy: item });
      } else {
        set({ itemToBuy: item, previousUserState: localUser });
      }

      setAttribute(fieldName)(item.name);
    },
    cancelPurchase: () => {
      const { previousUserState } = get();
      set({ localUser: previousUserState, itemToBuy: null, previousUserState: null });
    },
    purchaseItem: (createToast) => {
      const { itemToBuy, save } = get();
      set({ purchasing: true });

      if (!itemToBuy) return;

      fetchJson(`/api/transactions`, {
        method: 'POST',
        data: { itemId: itemToBuy.id },
        onSuccess: ({ success, errors, currentUser, transaction }: PurchaseData) => {
          if (success) {
            setFull((state) => ({
              ...state,
              AppData: { ...state.AppData, currentUser },
            }));
            save(createToast);
            set((state) => ({
              errors: emptyErrorMap(),
              itemToBuy: null,
              previousUserState: null,
              purchasing: false,
              confirmModalOpen: false,
              itemsToBuy: state.itemsToBuy.filter((item) => item.id !== transaction.storeItemId),
              purchasedItems: state.purchasedItems.push(itemToBuy),
            }));
            createToast({ children: 'Purchase Successful!', color: 'success' });
          } else {
            set({ errors: ImmutableMap(errors) });
          }
        },
      });
    },
  }),
);
