import { subDataCreator } from 'src/State';
import { defaultState, ReadingBuilderState } from './ReadingBuilderState';
import { ErrorMapResponse, makeQueryString, QueryObject, emptyErrorMap } from 'src/modules/Api';
import {
  Assessment,
  Author,
  ImageStoredFile,
  PdfStoredFile,
  Reading,
  TextStoredFile,
  ReadingTask,
  GradeLevel,
  Tag,
  LicenseType,
  PdfByPageStoredFile,
  readingIsShared,
} from 'src/models';
import { queryMapToString } from '../../modules/Router';
import { ImmutableMap, ImmutableList } from 'src/modules/Immutable';
import { trackAssessmentCreated } from 'src/modules/Analytics/AnalyticsEvents';
import { LanguageCode } from 'src/modules/LanguageCodes';
import { Playlist } from 'src/models/Playlist';

type CreateReadingData = {
  reading: Reading;
  tags: ImmutableList<Tag>;
  success: boolean;
  errors: ErrorMapResponse;
};

type CreatePdfResponse = {
  storedFile: PdfStoredFile | PdfByPageStoredFile;
  success: boolean;
};

type CreateAssessmentData = {
  assessment: Assessment;
  readingTask: ReadingTask;
};

type LoadResponse = {
  reading: Reading;
  contentFile: PdfStoredFile | PdfByPageStoredFile | TextStoredFile;
  coverImageFile: ImageStoredFile;
  author: Author;
  basicText?: string;
  webSourceUrl: string;
  gradeLevels: ImmutableList<GradeLevel>;
  tags: ImmutableList<Tag>;
  gradeLevel?: GradeLevel;
  languageCode: LanguageCode;
  selectedPlaylistIds: ImmutableList<string>;
  playlists: ImmutableList<Playlist>;
  primaryLicenseType: LicenseType;
};

type ScrapeResponse = {
  title: string | null;
  coverImageFile: ImageStoredFile | null;
  author: string | null;
  success: boolean;
  errors: ErrorMapResponse;
};

const INVALID_URL_ERROR_MAP = {
  scrapeErrors: ImmutableMap({
    webSourceUrl: ['The provided URL is not valid'],
  }),
};

const setErrorState = (s: ReadingBuilderState) => ({
  errors: s.errors.set('contentFile', ['Could not upload file, please try again']),
  uploadingPdf: false,
  uploadedPdfFile: null,
});

export const createDefaultReadingBuilderData = subDataCreator(
  'ReadingBuilderData',
  defaultState,
  ({ set, get, setField, fetchJson }) => {
    const createOrUpdateReading = (
      url: string,
      method: 'PATCH' | 'POST',
      onSuccess: (data: CreateReadingData) => void,
    ) => {
      const {
        coverImage,
        basicText,
        name,
        authorName,
        contentType,
        pdfFile,
        pdfRotation,
        webSourceUrl,
        coverImageFile,
        selectedLicenseType,
        gradeLevelId,
        languageCode,
        tagNameList,
        selectedPlaylistIds,
        isEll,
        isSpecialEducation,
      } = get();

      const isPdfFile = contentType === 'pdf' || contentType === 'pdf_by_page';
      fetchJson(url, {
        method,
        data: {
          contentType,
          name,
          authorName,
          basicText,
          pdfFileId: isPdfFile ? pdfFile?.id : null,
          pdfRotation,
          webSourceUrl: contentType === 'web' ? webSourceUrl : null,
          coverImageFileId: coverImageFile?.id,
          licenseType: selectedLicenseType,
          gradeLevelId,
          languageCode,
          tagNameList: tagNameList,
          selectedPlaylistIds,
          isEll,
          isSpecialEducation,
        },
        files: { ...(coverImage ? { coverImage } : {}) },
        onSuccess,
      });
    };

    return {
      setField,
      load: ({ assessmentId, readingId, isPaperUser }) => {
        set({ ...defaultState, assessmentId, readingId, loading: true });
        // Fetch stuff
        if (readingId) {
          fetchJson(`/api/readings/${readingId}`, {
            onSuccess: (data: LoadResponse) => {
              const {
                reading,
                contentFile,
                coverImageFile,
                author,
                basicText,
                gradeLevels,
                gradeLevel,
                tags,
                languageCode,
                selectedPlaylistIds,
                playlists,
                primaryLicenseType,
              } = data;

              const isPdfFile =
                contentFile.type === 'PdfStoredFile' || contentFile.type === 'PdfByPageStoredFile';
              set({
                reading,
                contentType: reading.contentType,
                webSourceUrl: reading.webSourceUrl || '',
                pdfFile: isPdfFile
                  ? {
                      ...contentFile,
                      ...(contentFile.type === 'PdfByPageStoredFile'
                        ? ({ jsonText: ImmutableMap(contentFile.jsonText) } as const)
                        : {}),
                    }
                  : null,
                pdfFileUrl: isPdfFile ? contentFile.url : null,
                basicTextFile: !isPdfFile ? contentFile : null,
                basicText: basicText || '',
                name: reading.name,
                authorName: author?.name || '',
                coverImageFile: coverImageFile || null,
                coverImageFileUrl: coverImageFile?.thumbnailUrl,
                gradeLevels,
                gradeLevelId: gradeLevel?.id,
                tags: tags,
                tagNameList: tags.map((t) => t.name),
                selectedLicenseType: reading.license,
                languageCode,
                selectedPlaylistIds,
                playlists,
                primaryLicenseType,
                isEll: reading.isEll,
                isSpecialEducation: reading.isSpecialEducation,
              });

              if (reading.contentType === 'pdf') {
                if (!contentFile || contentFile.type !== 'PdfStoredFile') return;

                fetchJson(
                  `/api/pdf_by_page_stored_files/${contentFile.id}/convert_to_pdf_by_page`,
                  {
                    method: 'POST',
                    onSuccess: ({ storedFile }: CreatePdfResponse) => {
                      set({
                        contentType: 'pdf_by_page',
                        pdfFile: storedFile,
                        pdfFileUrl: storedFile.url,
                        loading: false,
                      });
                    },
                  },
                );
              } else {
                set({ loading: false });
              }
            },
          });
        } else {
          fetchJson(`/api/readings/new`, {
            onSuccess: ({
              gradeLevels,
              playlists,
            }: {
              gradeLevels: ImmutableList<GradeLevel>;
              playlists: ImmutableList<Playlist>;
            }) => {
              set({
                gradeLevels,
                playlists,
                loading: false,
                selectedLicenseType: isPaperUser ? 'district' : 'open_source_needs_review',
              });
            },
          });
        }
      },
      toggleSharing: (isPaperUser) => () => {
        const defaultLicense = isPaperUser ? 'district' : 'open_source_needs_review';

        set(({ selectedLicenseType }) => ({
          selectedLicenseType: readingIsShared(selectedLicenseType)
            ? 'private_user_content'
            : defaultLicense,
        }));
      },
      rotatePdf: () => set((s) => ({ pdfRotation: (s.pdfRotation + 90) % 360 })),
      uploadPdfFile: (files) => {
        const file = files.first();
        if (!file) {
          set({
            uploadedPdfFile: null,
            pdfFile: null,
            pdfFileUrl: null,
            pdfRotation: 0,
            contentType: 'pdf_by_page',
          });
          return;
        }
        set({ uploadingPdf: true });
        fetchJson(
          `/api/${get().contentType === 'pdf' ? 'pdf_stored_files' : 'pdf_by_page_stored_files'}`,
          {
            method: 'POST',
            files: { file },
            onSuccess: ({ success, storedFile }: CreatePdfResponse) => {
              if (success) {
                set({
                  pdfFile: storedFile,
                  uploadedPdfFile: file,
                  pdfFileUrl: storedFile.url,
                  uploadingPdf: false,
                });
              } else {
                set(setErrorState);
              }
            },
          },
        );
      },
      submitForProcessing: (history, query) => () => {
        const onSuccess = () =>
          history.push(`/t/reading-builder/processing${queryMapToString(query)}`);

        const { pdfRotation, createRotatedPdf, contentType } = get();
        if (['pdf', 'pdf_by_page'].includes(contentType) && pdfRotation !== 0) {
          createRotatedPdf(onSuccess);
        } else {
          onSuccess();
        }
      },
      createRotatedPdf: (onSuccess) => {
        const { pdfFile } = get();
        if (!pdfFile) return;

        set({ submitting: true });
        fetchJson(
          `/api/${get().contentType === 'pdf' ? 'pdf_stored_files' : 'pdf_by_page_stored_files'}/${
            pdfFile.id
          }/rotate`,
          {
            method: 'POST',
            data: { rotation: get().pdfRotation },
            onSuccess: ({ success, storedFile }: CreatePdfResponse) => {
              set({ submitting: false });

              if (success) {
                set({
                  pdfFile: storedFile,
                  pdfFileUrl: storedFile.url,
                  pdfRotation: 0,
                });

                onSuccess?.();
              } else {
                set(setErrorState);
              }
            },
          },
        );
      },
      submit:
        ({ history, query }) =>
        () => {
          const { reading } = get();
          const readingUrl = `/api/readings/${reading ? reading.id : ''}`;
          const method = reading ? 'PATCH' : 'POST';
          set({ submitting: true });
          createOrUpdateReading(
            readingUrl,
            method,
            ({ success, errors, reading, tags }: CreateReadingData) => {
              const errorMap = ImmutableMap(errors);
              set({ submitting: false, errors: errorMap, reading, tags });
              if (!success) {
                if (errorMap.has('contentFile') || errorMap.has('webSourceUrl')) {
                  history.push(`/t/reading-builder/content${queryMapToString(query)}`);
                  if (errorMap.has('webSourceUrl')) {
                    get().openWebSourceUrlModal();
                  }
                } else {
                  history.push(`/t/reading-builder/metadata${queryMapToString(query)}`);
                }
              } else {
                get().goToAssignmentBuilder({ history });
              }
            },
          );
        },
      goToAssignmentBuilder: ({ history }) => {
        const { reading, assessmentId } = get();
        if (!reading) return;

        const assessmentUrl = `/api/assessments${
          assessmentId ? `/${assessmentId}/update_reading` : ''
        }`;
        const method = assessmentId ? 'PATCH' : 'POST';

        fetchJson(assessmentUrl, {
          method: method,
          data: { readingId: reading.id },
          onSuccess: (data: CreateAssessmentData) => {
            const query = queryMapToString(
              ImmutableMap({
                readingId: reading.id,
                assessmentId: data.assessment.id,
              }),
            );

            if (!assessmentId) {
              trackAssessmentCreated({ assessment: data.assessment });
            }

            history.replace(`/t/reading-builder/metadata${query}`);
            const assignmentBuilderQuery: QueryObject =
              reading.contentType === 'independent'
                ? {}
                : {
                    currentTaskId: data.readingTask.id,
                  };
            history.push(
              `/t/assignment-builder/${data.assessment.id}${makeQueryString(
                assignmentBuilderQuery,
              )}`,
            );
          },
        });
      },
      onPdfProcessingStatus: (data) => {
        const pdfFile = {
          ...data.storedFile,
          ...(data.storedFile.type === 'PdfByPageStoredFile'
            ? ({ jsonText: ImmutableMap(data.storedFile.jsonText) } as const)
            : {}),
        };
        set({ pdfFile });
      },
      fetchArticle: () => {
        const editingWebSourceUrl = get().editingWebSourceUrl;
        if (editingWebSourceUrl.length > 0) {
          try {
            new window.URL(editingWebSourceUrl || '');
          } catch (e) {
            set(INVALID_URL_ERROR_MAP);
            return;
          }
        }
        set({ scrapingWebSourceUrl: true, scrapeErrors: emptyErrorMap() });
        fetchJson(`/api/website_metadata`, {
          method: 'POST',
          data: { url: editingWebSourceUrl },
          onSuccess: (data: ScrapeResponse) => {
            set((s) => ({
              webModalOpen: !data.success,
              scrapeErrors: ImmutableMap(data.errors),
              name: data.title || 'Unknown Title',
              authorName: data.author || 'Unknown Author',
              coverImageFile: data.coverImageFile,
              coverImageFileUrl: data.coverImageFile?.thumbnailUrl || '',
              scrapingWebSourceUrl: false,
              webSourceUrl: data.success ? editingWebSourceUrl : s.webSourceUrl,
            }));
          },
        });
      },
      openWebSourceUrlModal: () =>
        set((s) => ({
          contentType: 'web',
          webModalOpen: true,
          editingWebSourceUrl: s.webSourceUrl,
          scrapeErrors: emptyErrorMap(),
        })),
      adminUpdate: (history, query, createToast) => () => {
        const { reading } = get();
        if (!reading) return;
        set({ submitting: true });
        createOrUpdateReading(
          `/api/readings/${reading.id}/moderator_update`,
          'PATCH',
          ({ success, errors, reading, tags }: CreateReadingData) => {
            const errorMap = ImmutableMap(errors);
            set({ submitting: false, errors: errorMap, reading, tags });
            if (success) {
              createToast({ children: 'Content Updated!', color: 'success', duration: 1000 });
              return;
            }

            if (errorMap.has('contentFile') || errorMap.has('webSourceUrl')) {
              history.push(`/t/reading-builder/content${queryMapToString(query)}`);
              if (errorMap.has('webSourceUrl')) {
                get().openWebSourceUrlModal();
              }
            } else {
              history.push(`/t/reading-builder/metadata${queryMapToString(query)}`);
            }
            createToast({ children: 'Updated failed!', color: 'danger', duration: 5000 });
          },
        );
      },
      removeTag: (tagName) => () => {
        set((state) => ({ tagNameList: state.tagNameList.filter((t) => t !== tagName) }));
      },

      addTag: () => {
        const { newTagName, tagNameList } = get();

        const cleanTagContent = (str: string) => {
          return str.replace(/[,;]/g, ' ').trim();
        };

        if (newTagName) {
          set({
            tagNameList: tagNameList.push(cleanTagContent(newTagName)),
            newTagName: '',
          });
        }
      },
      setPdfPageText: (page, pageText) => {
        set((state) => {
          if (!state.pdfFile || state.pdfFile?.type === 'PdfStoredFile') return state;

          return {
            pdfTextDirty: true,
            pdfFile: {
              ...state.pdfFile,
              jsonText: state.pdfFile.jsonText.set(String(page), pageText),
            },
          };
        });
      },
      overridePdfJsonText:
        ({ history, query }) =>
        () => {
          const { pdfFile, pdfTextDirty } = get();
          if (!pdfFile || pdfFile.type === 'PdfStoredFile') return;
          const onSuccess = get().submit({ history, query });

          if (!pdfTextDirty) {
            onSuccess();
            return;
          }

          set({ submitting: true });
          const jsonText = JSON.stringify(pdfFile.jsonText.toJSON());
          fetchJson(`/api/pdf_by_page_stored_files/${pdfFile.id}/override_json_text`, {
            method: 'PATCH',
            data: { jsonText },
            onSuccess: ({ success }: { success: boolean }) => {
              if (success) {
                onSuccess();
              } else {
                set(setErrorState);
              }
            },
          });
        },
    };
  },
);
