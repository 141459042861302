import { Flex, Text, Spacer, Tooltip } from 'src/components';
import { ReactNode } from 'react';
import { Color } from 'src/styles';

type AvatarCardProps = {
  avatar: ReactNode;
  primaryText: ReactNode;
  secondaryText?: ReactNode;
  secondaryTextColor?: Color;
  textColor?: Color;
  testTag?: string;
  tooltip?: string;
};

export const AvatarCard = ({
  avatar,
  primaryText,
  secondaryText,
  textColor = 'black',
  secondaryTextColor = 'gray5',
  testTag,
  tooltip,
}: AvatarCardProps) => (
  <Flex align="center">
    {avatar}
    <Spacer horizontal size={2} />
    <Flex direction="column" justify="center">
      <Tooltip content={tooltip} display="block" disabled={!tooltip}>
        <Text color={textColor} variant="h6" testTag={testTag}>
          {primaryText}
        </Text>
      </Tooltip>
      {secondaryText && (
        <Text variant="h7" color={secondaryTextColor}>
          {secondaryText}
        </Text>
      )}
    </Flex>
  </Flex>
);
