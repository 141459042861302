import { ReactNode } from 'react';
import styled from 'styled-components';
import { borderRadii, colors, paddings } from 'src/styles';
import { Spacer, Button, Flex, Modal, ModalProps, Text } from 'src/components';

type StandardModalProps = ModalProps & {
  header: string;
  middleHeader?: ReactNode | null;
  rightHeader?: ReactNode | null;
  footer?: ReactNode;
  disableClose?: boolean;
};

const ModalHeader = styled.div`
  padding: ${paddings[4]} ${paddings[6]};
  border-bottom: 1px solid ${colors.gray2.hex};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ModalContent = styled.div`
  flex-grow: 1;
  padding: ${paddings[4]} ${paddings[6]};
  overflow-y: auto;
  min-height: 0;
`;

const ModalFooter = styled.div`
  padding: ${paddings[4]} ${paddings[6]};
  background: ${colors.backgroundLight.hex};
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-radius: 0 0 ${borderRadii[5]} ${borderRadii[5]};
`;

const CLOSE_BUTTON_SIZE = '36px';

export const StandardModal = ({
  header,
  middleHeader,
  rightHeader,
  children,
  footer,
  disableFadeClose,
  disableClose = false,
  ...modalProps
}: StandardModalProps) => {
  const textHeader = (
    <Text title={header} variant="h3" ellipsis ellipsifiedTitle={header} maxWidth="90%">
      {header}
    </Text>
  );
  return (
    <Modal {...modalProps} disableFadeClose={disableFadeClose || disableClose}>
      <ModalHeader>
        {middleHeader ? (
          <Flex align="center" grow={1} maxWidth="90%">
            {textHeader}
            {middleHeader}
          </Flex>
        ) : (
          textHeader
        )}
        <Flex align="center">
          {rightHeader}
          {modalProps.close && (
            <>
              <Spacer size={2} horizontal />
              <Button
                color="gray3"
                icon="close"
                iconSize="0.75em"
                width={CLOSE_BUTTON_SIZE}
                height={CLOSE_BUTTON_SIZE}
                displayType="iconOnly"
                onClick={modalProps.close}
                testTag="close"
                paddingOverride={paddings.none}
                disabled={disableClose}
              />
            </>
          )}
        </Flex>
      </ModalHeader>
      <ModalContent>{children}</ModalContent>
      {footer && <ModalFooter>{footer}</ModalFooter>}
    </Modal>
  );
};
