import { useCallback } from 'react';
import createStore from 'zustand';
import createContext from 'zustand/context';
import { shallow } from 'zustand/shallow';
import type { StateCreator, StoreApi } from 'zustand';
import { State } from 'src/State';

type RestrictedState = Record<string, unknown>;
export type RestrictedStateCreator<S extends RestrictedState> = StateCreator<S>;

export type SubStateCreatorParams<S extends Record<string, unknown> = State> = {
  getFull: StoreApi<S>['getState'];
  setFull: StoreApi<S>['setState'];
};

export const createStrictStore = <S extends RestrictedState>(
  stateCreator: RestrictedStateCreator<S>,
) => {
  const useBaseStore = createStore<S>(stateCreator);

  const useStore = <T>(selector: (s: S) => T, deps: Array<unknown>) =>
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useBaseStore(useCallback(selector, deps), shallow);
  return { useStore, useBaseStore };
};

export const createStrictStoreContext = <S extends RestrictedState>() => {
  const { Provider, useStore: useBaseStore } = createContext<StoreApi<S>>();

  const useStore = <T>(selector: (s: S) => T, deps: Array<unknown>) =>
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useBaseStore(useCallback(selector, deps), shallow);
  return { useStore, Provider };
};
