import { ReadingSearchResult } from 'src/models';
import { StudentReadingTile } from './StudentReadingTile';
import { TeacherReadingTile } from './TeacherReadingTile';
import { matchPath, useLocation } from 'react-router-dom';

export type ReadingTileProps = {
  id: string;
  result: ReadingSearchResult;
  testTag?: string;
  existingAssessmentId?: string;
};

export const ReadingTile = (props: ReadingTileProps) => {
  const pathname = useLocation().pathname;
  const isStudent = Boolean(matchPath(pathname, '/s/library'));

  return isStudent ? <StudentReadingTile {...props} /> : <TeacherReadingTile {...props} />;
};
