import Google from 'src/assets/google.svg';
import Clever from 'src/assets/clever.svg';
import styled from 'styled-components';
import { margins } from 'src/styles/Margin';

const LmsIconStyles = styled.img`
  width: 18px;
  height: 18px;
  margin-right: ${margins[2]};
`;

export const LmsIcon = ({ lms }: { lms: 'Google' | 'Clever' | '-' }) =>
  lms === '-' ? null : <LmsIconStyles src={lms === 'Google' ? Google : Clever} alt={lms} />;
