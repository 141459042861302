import { ReactNode } from 'react';
import { RequiredDollarPrefix, Color, colors, borderRadii, paddings, margins } from 'src/styles';
import { Button } from 'src/components';
import styled from 'styled-components';

type CommonProps = {
  children: ReactNode;
};

type PropsToPrefix = {
  color?: Color;
};

type PrefixedProps = RequiredDollarPrefix<PropsToPrefix>;

type ToastProps = CommonProps &
  PropsToPrefix & {
    closeToast: () => void;
    closeAnywhere?: boolean;
  };

type ToastInternalProps = Required<ToastProps>;

type ToastStyleProps = CommonProps & PrefixedProps;

const BaseToast = styled.div<ToastStyleProps>`
  border-radius: ${borderRadii[1]};
  padding: ${paddings[2]};
  background: ${({ $color }) => colors[$color].hex};
  color: ${({ $color }) => colors[$color].text};
  display: flex;
  align-items: center;
  margin-bottom: ${margins[2]};
`;

const Close = styled.div`
  margin-left: ${margins[1]};
`;

export const Toast = (props: ToastInternalProps) => {
  const { children, color, closeToast, closeAnywhere } = props;
  return (
    <>
      {closeAnywhere ? (
        <Button display="flex" displayType="noStyles" onClick={closeToast} testTag="close-toast">
          <BaseToast $color={color}>
            {children} <Close>✕</Close>
          </BaseToast>
        </Button>
      ) : (
        <BaseToast $color={color}>
          {children}
          <Close>
            <Button displayType="noStyles" onClick={closeToast} testTag="close-toast">
              ✕
            </Button>
          </Close>
        </BaseToast>
      )}
    </>
  );
};
