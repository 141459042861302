import { SetField } from 'src/State';
import { AvatarFeature } from 'src/models/Avatar';
import { ExtendedUser, AvatarProps, StoreItem } from 'src/models';
import { CreateToast } from 'src/components';
import { ImmutableList } from 'src/modules/Immutable';
import { emptyErrorMap, ErrorMap } from 'src/modules/Api';

export type AvatarBuilderState = {
  customizingSelection: AvatarFeature;
  dirty: boolean;
  saving: boolean;
  errors: ErrorMap;
  localUser: ExtendedUser | null;
  purchasedItems: ImmutableList<StoreItem>;
  itemsToBuy: ImmutableList<StoreItem>;
  itemToBuy: StoreItem | null;
  previousUserState: ExtendedUser | null;
  purchasing: boolean;
  confirmModalOpen: boolean;
  setField: SetField<AvatarBuilderState>;
  load: () => void;
  save: (createToast: CreateToast) => void;
  undo: () => void;
  rollDice: () => void;
  setAttribute: <K extends keyof AvatarProps>(name: K) => (value: AvatarProps[K]) => void;
  setItem: (item: StoreItem, fieldName: keyof AvatarProps) => () => void;
  cancelPurchase: () => void;
  purchaseItem: (createToast: CreateToast) => void;
};

export const defaultState = {
  customizingSelection: 'skin' as AvatarFeature,
  dirty: false,
  saving: false,
  errors: emptyErrorMap(),
  localUser: null,
  purchasedItems: ImmutableList<StoreItem>(),
  itemsToBuy: ImmutableList<StoreItem>(),
  itemToBuy: null,
  previousUserState: null,
  purchasing: false,
  confirmModalOpen: false,
};
