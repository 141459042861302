import { useEffect } from 'react';
import { useQuery, useLocation, useHistory } from 'src/modules/Router';
import { useStore } from 'src/Store';
import { SimpleLayout } from 'src/layouts';
import { makeQueryString, useFetch } from 'src/modules/Api';

export const AutoAssignRedirect = () => {
  const { fetchJson } = useFetch();
  const history = useHistory();
  const query = useQuery();
  const { pathname, search } = useLocation();
  const courseId = query.get('courseId') || '';
  const assessmentId = query.get('assessmentId') || '';
  const { loading, currentUser } = useStore((s) => s.AppData, []);

  useEffect(() => {
    const doIt = () => {
      if (loading) return;

      if (!currentUser) {
        return history.replace(
          `/login${makeQueryString({
            courseCode: query.get('courseCode') || '',
            redirectPath: pathname + search,
          })}`,
        );
      }

      const successUrl = `/reading-studio${makeQueryString({
        assessmentId: assessmentId,
        showInstructions: true,
      })}`;

      if (currentUser.accountType !== 'student') {
        return history.replace(successUrl);
      } else {
        fetchJson(`/api/registration_assessments/create_from_link`, {
          method: 'POST',
          data: { assessmentId, courseId },
          onSuccess: (data: { success: boolean }) => {
            if (data.success) {
              history.replace(successUrl);
            }
          },
        });
      }
    };

    doIt();
  }, [assessmentId, courseId, currentUser, fetchJson, history, loading, pathname, search, query]);

  return <SimpleLayout loading={loading} />;
};
