import { useCallback, useEffect } from 'react';
import { useStore } from 'src/Store';
import { useTeacherStudentListHelpers } from '../../TeacherStudentListHelpers';
import {
  StandardModal,
  Button,
  Spacer,
  FormGroup,
  Form,
  FormRow,
  Flex,
  LinkButton,
} from 'src/components';

export const NewStudentModal = () => {
  const {
    createStudent,
    setUserField,
    newUser,
    newStudentErrors,
    creatingNewStudent,
    passwordHidden,
    generatePassword,
    loadNewStudentModal,
    setField,
  } = useStore(
    ({ TeacherStudentListData: s }) => ({
      createStudent: s.createStudent,
      setUserField: s.setUserField,
      newUser: s.newUser,
      newStudentErrors: s.newStudentErrors,
      creatingNewStudent: s.creatingNewStudent,
      passwordHidden: s.passwordHidden,
      generatePassword: s.generatePassword,
      loadNewStudentModal: s.loadNewStudentModal,
      setField: s.setField,
    }),
    [],
  );

  const helpers = useTeacherStudentListHelpers();
  const submit = useCallback(() => createStudent(helpers), [createStudent, helpers]);

  useEffect(() => {
    loadNewStudentModal();
  }, [loadNewStudentModal]);

  return (
    <StandardModal
      testTag="new-student-modal"
      openType="state"
      opened
      header="Create Student"
      close={helpers.closeModal}
      disableFadeClose
      footer={
        <Button width="100%" loading={creatingNewStudent} onClick={submit}>
          Next
        </Button>
      }
    >
      <Form onSubmit={submit}>
        <FormRow>
          <FormGroup
            name="name"
            label="First Name"
            errors={newStudentErrors}
            type="text"
            value={newUser.firstName}
            onChange={setUserField('firstName')}
          />
          <Spacer horizontal size={2} />
          <FormGroup
            name="lastName"
            label="Last Name"
            errors={newStudentErrors}
            type="text"
            value={newUser.lastName}
            onChange={setUserField('lastName')}
          />
        </FormRow>
        <Spacer size={2} />
        <FormGroup
          type="text"
          name={newUser.username.includes('@') ? 'email' : 'username'}
          label="Username or Email"
          errors={newStudentErrors}
          value={newUser.username}
          onChange={setUserField('username')}
        />
        <Spacer size={2} />
        <FormGroup
          name="newPassword"
          label="Password"
          errors={newStudentErrors}
          type={passwordHidden ? 'password' : 'text'}
          value={newUser.password}
          onChange={setUserField('password')}
        />
        <Spacer />
        <Flex width="100%" justify="space-between">
          <LinkButton onClick={() => setField('passwordHidden')(!passwordHidden)}>
            {passwordHidden ? 'Show' : 'Hide'} password
          </LinkButton>
          <LinkButton onClick={generatePassword({ newUser: true })}>Generate Password</LinkButton>
        </Flex>
      </Form>
    </StandardModal>
  );
};
