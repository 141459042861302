import { useEffect } from 'react';
import { useStore } from 'src/Store';
import { useQuery, useHistory } from 'src/modules/Router';
import { colors, paddings } from 'src/styles';
import { Text, Loading, Flex, Title } from 'src/components';
import { ReadingStudioLayout } from 'src/layouts/ReadingStudioLayout';
import { ReadingStudioTopBar } from './ReadingStudioTopBar';
import { ReadingStudioSideBar } from './ReadingStudioSideBar';
import { ReadingStudioBottomBar } from './ReadingStudioBottomBar';
import {
  useCurrentTaskSubmissionDetails,
  useIsPublicPreview,
  useReadingTaskSubmissionDetails,
} from './ReadingStudioHelpers';
import { teacherOrGreater } from 'src/models';
import { MainContent } from './MainContent';
import { DrawerContent } from './DrawerContent';
import { useHideZendesk } from 'src/modules/Analytics/AnalyticsIdentity';
import { useCompactLayout } from 'src/layouts/ReadingStudioLayout/ReadingStudioLayoutHelpers';
import { TurnedInModal } from './TurnedInModal';
import { UnfinishedTasksModal } from './UnfinishedTasksModal';
import { InstructionsModal } from './InstructionsModal';

export const ReadingStudio = () => {
  const history = useHistory();
  const query = useQuery();
  const assessmentId = query.get('assessmentId');
  const currentQueryTaskId = query.get('currentTaskId');

  const {
    loading,
    load,
    assessment,
    setCurrentTask,
    dirty,
    submit,
    drawerExpanded,
    onCompleteTask,
    audioRecorderStatus,
    userType,
  } = useStore(
    (state) => ({
      loading: state.ReadingStudioData.loading,
      load: state.ReadingStudioData.load,
      assessment: state.ReadingStudioData.assessment,
      setCurrentTask: state.ReadingStudioData.setCurrentTask,
      dirty: state.ReadingStudioData.dirty,
      submit: state.ReadingStudioData.submit,
      drawerExpanded: state.ReadingStudioData.drawerExpanded,
      onCompleteTask: state.ReadingStudioData.onCompleteTask,
      audioRecorderStatus: state.ReadingStudioData.audioRecorderStatus,
      userType: state.AppData.currentUser?.accountType || 'student',
    }),
    [],
  );

  const currentTaskSubmissionDetails = useCurrentTaskSubmissionDetails();
  const readingTaskSubmissionDetails = useReadingTaskSubmissionDetails();
  const isPublicPreview = useIsPublicPreview();

  useEffect(() => {
    load({ assessmentId, isPublicPreview });
  }, [assessmentId, load, isPublicPreview]);

  useEffect(() => {
    const firstTaskId =
      assessment?.taskOrder?.first()?.type !== 'VocabTask'
        ? assessment?.taskOrder?.first()?.taskId
        : null;
    setCurrentTask(currentQueryTaskId ?? firstTaskId ?? null);
  }, [setCurrentTask, currentQueryTaskId, assessment]);

  useHideZendesk({ smallerThan: 'desktop' });
  const compactLayout = useCompactLayout();

  useEffect(() => {
    if (currentTaskSubmissionDetails?.type === 'VocabTask')
      onCompleteTask(currentTaskSubmissionDetails);
  }, [onCompleteTask, currentTaskSubmissionDetails]);

  // This prevents a glitchtip we get where, for a single moment, the page is rendered using the
  // previous assessmentId, which can cause errors with fetching files from S3 with now expired
  // URLs.
  if (loading || !assessmentId || assessmentId !== assessment?.id)
    return <Loading flex kind="boat" />;

  return (
    <>
      <Title title={assessment?.name ?? 'Reading Studio'} />
      <ReadingStudioLayout
        loading={loading}
        sideBar={<ReadingStudioSideBar />}
        topBar={<ReadingStudioTopBar />}
        bottomBar={
          compactLayout &&
          audioRecorderStatus !== 'recording' &&
          audioRecorderStatus !== 'loading' ? (
            <ReadingStudioBottomBar />
          ) : null
        }
        showTopBarDivider={
          !['PdfStoredFile', 'PdfByPageStoredFile'].includes(
            readingTaskSubmissionDetails?.contentFile?.type || '',
          )
        }
        bannerContent={
          teacherOrGreater(userType) && (
            <Flex justify="center" backgroundColor={colors.primaryBlue.hex} padding={paddings[2]}>
              <Text variant="largeText" color="white">
                {isPublicPreview ? 'Previewing Assignment' : 'Previewing as a Student'}
              </Text>
            </Flex>
          )
        }
        onSubmit={() => submit({ finished: true, clearDirty: true, history })}
        dirty={dirty}
        mainContent={<MainContent />}
        drawerContent={!compactLayout ? <DrawerContent /> : null}
        drawerExpanded={drawerExpanded}
      />
      <UnfinishedTasksModal />
      <TurnedInModal />
      <InstructionsModal />
    </>
  );
};
