import {
  User,
  TaskSubmissionDetails,
  Course,
  TaskDetails,
  formatName,
  SubmissionExtended,
  ComprehensionTextDiffItem,
} from 'src/models';
import {
  Flex,
  Text,
  Spacer,
  LinkButton,
  Tooltip,
  Button,
  UserAvatar,
  TaskSubmissionViewer,
} from 'src/components';
import { borderRadii, colors, margins, paddings, userColors } from 'src/styles';
import styled from 'styled-components';
import { ImmutableList, ImmutableMap } from 'src/modules/Immutable';
import { queryMapToString, useQuery } from 'src/modules/Router';

export const TaskSubmissionCardStyles = styled.div`
  background: ${colors.white.hex};
  padding: ${paddings[5]};
  margin-bottom: ${margins[3]};
  border-radius: ${borderRadii[4]};
  border: 1px solid ${colors.gray2.hex};
`;

export const TaskSubmissionCard = ({
  usageCapsEnabled,
  availableUltimateAssessments,
  toolTipOnClick,
  user,
  taskDetails,
  taskSubmissionDetails,
  assignedCourses,
  submissionMap,
  comprehensionTextDiff,
  canSeeTranscription,
}: {
  toolTipOnClick: () => void;
  usageCapsEnabled: boolean;
  availableUltimateAssessments: number;
  user: User;
  canSeeTranscription: boolean;
  taskDetails: TaskDetails;
  taskSubmissionDetails: TaskSubmissionDetails | null;
  assignedCourses: ImmutableList<Course>;
  submissionMap: ImmutableMap<string, SubmissionExtended>;
  comprehensionTextDiff?: ImmutableList<ComprehensionTextDiffItem>;
}) => {
  const query = useQuery();
  const hasSubmission = submissionMap.has(user.id);
  const submission = submissionMap.get(user.id);
  const backPath = `/t/assignments/task/${taskDetails.type}/${
    taskDetails.task.id
  }${queryMapToString(query)}`;

  const submissionLink = !submission
    ? ''
    : `/submissions/${submission.id}${queryMapToString(ImmutableMap({ backPath }))}&currentTaskId=${
        taskDetails.task.id
      }`;

  return (
    <TaskSubmissionCardStyles data-test-tag={`task-submission-card-${user.id}`}>
      <Flex justify="space-between" align="start">
        <UserAvatar
          name={user.displayName}
          backgroundColor={user.avatarColor || userColors[0]}
          size="large"
        />
        <Spacer horizontal size={4} />
        <Flex direction="column" width="100%">
          <Flex align="center">
            <Button
              displayType="noStyles"
              disabled={!hasSubmission}
              to={submissionLink}
              disabledOpacityOverride="1"
            >
              <Text variant="h3" testTag="task-submission-card-title">
                {formatName(user)}
              </Text>
            </Button>
            <Spacer horizontal size={3} />
            {!submission ? null : (
              <Tooltip disabled={hasSubmission} content="Assignment not started">
                <LinkButton paddingOverride={paddings.none} to={submissionLink}>
                  View Submission
                </LinkButton>
              </Tooltip>
            )}
          </Flex>
          <Text color="gray7">{assignedCourses.map((c) => c.name).join(', ')}</Text>

          <Spacer size={2} />
          <TaskSubmissionViewer
            submission={submission}
            taskDetails={taskDetails}
            taskSubmissionDetails={taskSubmissionDetails}
            comprehensionTextDiff={comprehensionTextDiff}
            canSeeTranscription={canSeeTranscription}
            usageCapsEnabled={usageCapsEnabled}
            availableUltimateAssessments={availableUltimateAssessments}
            toolTipOnClick={toolTipOnClick}
          />
        </Flex>
      </Flex>
    </TaskSubmissionCardStyles>
  );
};
