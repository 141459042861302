import { SelectOption, TabViewTab } from 'src/components';
import { ErrorMapResponse, FetchJson } from 'src/modules/Api';
import { ImmutableList, ImmutableMap, partition } from 'src/modules/Immutable';
import { QueryMap, updateQuery, queryMapToString } from 'src/modules/Router';
import { ClassColor } from 'src/styles';
import { User } from 'src/models/User';
import { appUrl } from 'src/config';

type CourseStatus = 'active' | 'deleted' | 'archived';

export type Course = {
  id: string;
  name: string;
  disableEmail: boolean;
  disableName: boolean;
  courseCode: string;
  status: CourseStatus;
  googleSyncedAt: string;
  cleverSyncedAt: string;
  avatarColor: ClassColor;
  gradeLevelId: string | undefined;
  isLms: boolean;
  createdAt: string;
  creatorId: string;
};

export type CourseWithGoogleId = Course & { googleId: string | null };

export type SignUpClassCodeResponse = {
  course: Course;
  errors: ErrorMapResponse;
  success: boolean;
};

export const courseCodeLink = (courseCode: string) =>
  `${appUrl}/j/?courseCode=${encodeURIComponent(courseCode)}`;

export const checkCourseCode = ({
  courseCode,
  onSuccess,
  fetchJson,
}: {
  courseCode: string;
  onSuccess?: (data: SignUpClassCodeResponse) => void;
  fetchJson: FetchJson;
}) =>
  fetchJson(`/api/course_codes/${encodeURIComponent(courseCode)}`, {
    onSuccess: (data: SignUpClassCodeResponse) => {
      onSuccess?.(data);
    },
  });

export type CourseUpdateResponse = {
  course: Course;
  success: boolean;
  errors: ErrorMapResponse;
};

export const updateCourse = ({
  course,
  onSuccess,
  fetchJson,
  editingInModal,
  newSubjectIds = ImmutableList<string>(),
  oldSubjectIds = ImmutableList<string>(),
}: {
  course: Course;
  onSuccess?: (data: CourseUpdateResponse) => void;
  fetchJson: FetchJson;
  editingInModal: boolean;
  newSubjectIds?: ImmutableList<string>;
  oldSubjectIds?: ImmutableList<string>;
}) =>
  fetchJson(`/api/courses/${course.id}`, {
    method: 'PATCH',
    data: { course: { ...course, editingInModal }, newSubjectIds, oldSubjectIds },
    onSuccess,
  });

export const editCoursePath = (
  kind: 'create' | 'edit',
  query: QueryMap,
  courseIds: ImmutableList<string>,
) => {
  const status = query.get('status') || '';
  return `/t/classes/${kind}${queryMapToString(
    ImmutableMap({ status, editIds: courseIds.join(',') }).filter(Boolean),
  )}`;
};

export const editStudentsPath = (query: QueryMap, courseId: string) =>
  `/t/classes/roster${updateQuery(query, { editIds: courseId })}`;

type OptionOrTag = TabViewTab & SelectOption<string>;

const courseFilterOptions = (
  courses: ImmutableList<Course>,
  teachersMap: ImmutableMap<string, User>,
  currentUser: User | null,
): ImmutableList<OptionOrTag> => {
  const [myCourses, othersCourses] = partition(
    courses,
    (course) => course.creatorId === currentUser?.id,
  );

  const mine = myCourses.map((c) => ({ ...c, value: c.id, label: c.name, name: c.name }));

  const others = othersCourses.map((c) => {
    const ogLastName = teachersMap
      .get(c.creatorId, { displayName: '' })
      .displayName.split(' ')
      .pop();
    const displayName = `${ogLastName ? `${ogLastName} - ` : ''}${c.name}`;

    return { ...c, value: c.id, label: displayName, name: displayName };
  });

  const allDisplayName = `All Classes (${mine.size + others.size})`;
  const allEntry = { id: 'all', value: 'all', name: allDisplayName, label: allDisplayName };

  const mineDisplayName = `My Classes (${mine.size})`;
  const mineEntry = { id: 'mine', value: 'mine', name: mineDisplayName, label: mineDisplayName };

  return ImmutableList([allEntry, mineEntry])
    .concat(mine.sortBy((c) => c.name))
    .concat(others.sortBy((c) => c.name));
};

export const courseSelectOptions = (
  courses: ImmutableList<Course>,
  teachersMap: ImmutableMap<string, User>,
  currentUser: User | null,
): ImmutableList<SelectOption<string>> => courseFilterOptions(courses, teachersMap, currentUser);

export const courseTabs = (
  courses: ImmutableList<Course>,
  teachersMap: ImmutableMap<string, User>,
  currentUser: User | null,
): ImmutableList<TabViewTab> => {
  const courseTabs: ImmutableList<TabViewTab> = courseFilterOptions(
    courses,
    teachersMap,
    currentUser,
  );
  const isPaperUser = Boolean(currentUser?.paperSyncedAt);

  return isPaperUser
    ? courseTabs
    : courseTabs.push({
        id: 'add-new',
        name: 'Add Class +',
        to: '/t/classes/new',
        onlyClick: true,
      });
};
