import { throttle } from 'lodash';
import { useEffect, useRef, useState } from 'react';

export const breakpoints = {
  mobileSmall: 360,
  mobileMed: 380,
  // Iphone 6/7/8+
  mobileLarge: 415,
  tabletSmall: 760,
  tablet: 930,
  desktopSmall: 1100,
  desktop: 1280,
} as const;

export type Breakpoints = keyof typeof breakpoints;

const RESIZE_THROTTLE_TIMEOUT = 50;

export const useWindowSize = ({
  throttleTimeout = RESIZE_THROTTLE_TIMEOUT,
}: {
  throttleTimeout?: number;
} = {}) => {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const windowSizeRef = useRef(windowSize);
  useEffect(() => {
    const handleResize = throttle(() => {
      if (
        window.innerWidth !== windowSizeRef.current.width ||
        window.innerHeight !== windowSizeRef.current.height
      ) {
        const newWindowSize = {
          width: window.innerWidth,
          height: window.innerHeight,
        };
        windowSizeRef.current = newWindowSize;
        setWindowSize(newWindowSize);
      }
    }, throttleTimeout);
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, [throttleTimeout]);
  return windowSize;
};

export type BreakpointProps = {
  largerThan?: Breakpoints;
  largerThanOrEqualTo?: Breakpoints;
  smallerThan?: Breakpoints;
  smallerThanOrEqualTo?: Breakpoints;
};

export const useBreakpoints = ({
  largerThan,
  largerThanOrEqualTo,
  smallerThan,
  smallerThanOrEqualTo,
}: BreakpointProps = {}) => {
  const windowWidth = useWindowSize().width;
  let result = true;
  if (largerThan) {
    result = result && windowWidth > breakpoints[largerThan];
  }
  if (largerThanOrEqualTo) {
    result = result && windowWidth >= breakpoints[largerThanOrEqualTo];
  }
  if (smallerThan) {
    result = result && windowWidth < breakpoints[smallerThan];
  }
  if (smallerThanOrEqualTo) {
    result = result && windowWidth <= breakpoints[smallerThanOrEqualTo];
  }

  return result;
};
