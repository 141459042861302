import { useEffect, useMemo, useCallback } from 'react';
import { useStore } from 'src/Store';
import { CenteredLayout } from 'src/layouts';
import { Playlist } from 'src/models/Playlist';
import { DeletePlaylistModal, EditPlaylistModal, NewPlaylistModal } from './modals';
import {
  DropdownMenu,
  Flex,
  Loading,
  Spacer,
  SuperTable,
  SuperTableColumn,
  Text,
  Button,
} from 'src/components';

export const PlaylistManager = () => {
  const { load, loading, playlists, updatePlaylistRank, setField, startCreate } = useStore(
    ({ PlaylistManagerData }) => PlaylistManagerData,
    [],
  );

  useEffect(() => {
    load();
  }, [load]);

  const getRowId = useCallback((p: Playlist) => p.id, []);
  const columns: Array<SuperTableColumn<Playlist>> = useMemo(
    () => [
      {
        id: 'name',
        name: 'Name',
        render: (playlist) => playlist.name,
        sortType: 'none',
      },
      {
        id: 'rank',
        name: 'Rank (Lower Means Higher on Page)',
        render: (playlist) => (
          <Flex>
            <Button
              icon="selectArrowUp"
              onClick={() => updatePlaylistRank(playlist, (playlist.ranking || 0) - 1)}
              displayType="iconOnly"
              color="white"
              iconColor="gray7"
              iconSize="1em"
              testTag="rank-up"
            />
            <Button
              onClick={() => updatePlaylistRank(playlist, (playlist.ranking || 0) + 1)}
              icon="selectArrowDown"
              displayType="iconOnly"
              color="white"
              iconColor="gray7"
              iconSize="1em"
              testTag="rank-down"
            />
            <Spacer horizontal size={2} />
            {playlist.ranking}
          </Flex>
        ),
        sortBy: (playlist) => playlist.ranking || 0,
        sortType: 'sortBy',
        defaultSortDirection: 'asc',
      },
      {
        id: 'status',
        name: 'Status',
        render: ({ enabled }) => (enabled ? 'Visible' : 'Hidden'),
        sortType: 'none',
      },
      {
        id: 'actions',
        name: '',
        sortType: 'none',
        render: (playlist) => (
          <DropdownMenu
            displayType="outline"
            options={[
              { children: 'Edit', onClick: () => setField('editingPlaylist')(playlist) },
              {
                children: 'Delete',
                color: 'danger',
                onClick: () => setField('deletingPlaylist')(playlist),
              },
            ]}
          >
            Actions
          </DropdownMenu>
        ),
      },
    ],
    [setField, updatePlaylistRank],
  );

  if (loading) return <Loading />;

  return (
    <CenteredLayout>
      <Spacer size={6} />
      <Flex align="center" justify="space-between">
        <Text variant="h1">Playlists</Text>
        <Button onClick={startCreate}>Add Playlist</Button>
      </Flex>
      <Spacer size={6} />
      <SuperTable
        columns={columns}
        rows={playlists.filter((p) => p.status === 'active')}
        getRowId={getRowId}
        queryParam="playlists"
        initialSortSettings={{ columnId: 'rank', sortDirection: 'asc' }}
      />
      <NewPlaylistModal />
      <EditPlaylistModal />
      <DeletePlaylistModal />
    </CenteredLayout>
  );
};
