import { Fragment } from 'react';
import { useStore } from 'src/Store';
import {
  AudioRecorder,
  Flex,
  Button,
  Spacer,
  TextAreaInput,
  ErrorText,
  SelectInput,
  IconType,
  ComprehensionDisplayType,
  LinkButton,
} from 'src/components';

import {
  ComprehensionTaskSubmissionDetails,
  ComprehensionTaskSubmissionResponseType,
} from 'src/models/ComprehensionTaskSubmission';
import { ImmutableList, ImmutableMap } from 'src/modules/Immutable';
import { useIsPublicPreview } from './ReadingStudioHelpers';
import { useCompactLayout } from 'src/layouts/ReadingStudioLayout/ReadingStudioLayoutHelpers';

const responseTypeSelectOptions = ImmutableList<{
  value: ComprehensionTaskSubmissionResponseType;
  label: string;
  icon: IconType;
}>([
  { value: 'recorded', label: 'Record Response', icon: 'microphone' },
  { value: 'written', label: 'Write Response', icon: 'pencil' },
]);

export const ComprehensionContent = ({
  currentTaskSubmissionDetails,
  displayType,
  textSize = 16,
  openContentModal,
}: {
  currentTaskSubmissionDetails: ComprehensionTaskSubmissionDetails;
  displayType: ComprehensionDisplayType;
  textSize?: number;
  openContentModal?: () => void;
}) => {
  const {
    taskErrors,
    dirty,
    assessment,
    isRecording,
    setResponseType,
    onResponseRecordingSequenceStatus,
    setResponseContent,
    onAudioRecorderStatus,
    submit,
    emitPaperEvent,
  } = useStore(
    ({ ReadingStudioData: s, AppData: a }) => ({
      taskErrors: s.taskErrors,
      dirty: s.dirty,
      assessment: s.assessment,
      isRecording: a.isRecording,
      setResponseType: s.setResponseType,
      onResponseRecordingSequenceStatus: s.onResponseRecordingSequenceStatus,
      setResponseContent: s.setResponseContent,
      onAudioRecorderStatus: s.onAudioRecorderStatus,
      submit: s.submit,
      emitPaperEvent: a.emitPaperEvent,
    }),
    [],
  );

  const isPublicPreview = useIsPublicPreview();

  const compactLayout = useCompactLayout();

  const { task, taskSubmission, responseRecordingSequence, responseAudioFile } =
    currentTaskSubmissionDetails;

  const responseTypeSelect =
    task.responseType === 'student_choice' ? (
      <SelectInput
        displayType="flat"
        value={taskSubmission.responseType}
        name="responseType"
        onChange={setResponseType}
        options={responseTypeSelectOptions}
        disabled={isRecording}
      />
    ) : null;

  const contentWidth = displayType === 'drawerClosed' ? '500px' : '100%';

  const contentModalContent = openContentModal && (
    <>
      <Flex width="100%">
        <LinkButton onClick={openContentModal}>View Reading</LinkButton>
      </Flex>
      <Spacer size={2} />
    </>
  );
  if (
    task.responseType === 'student_choice' &&
    (taskSubmission.responseType === 'undecided' || isPublicPreview)
  ) {
    return (
      <>
        {responseTypeSelectOptions.map((option) => (
          <Fragment key={option.value}>
            <Button
              onClick={() => setResponseType(option.value)}
              icon={option.icon}
              iconFill={option.icon === 'pencil'}
              iconStrokeWidth={option.icon === 'pencil' ? 0 : 1}
              disabled={isPublicPreview}
            >
              {option.label}
            </Button>
            <Spacer square size={2} />
          </Fragment>
        ))}
      </>
    );
  } else if (task.responseType === 'recorded' || taskSubmission.responseType === 'recorded') {
    return (
      <Flex width={contentWidth} direction="column" justify="space-between" height="100%">
        <Flex direction="column">
          {contentModalContent}
          {responseTypeSelect}
        </Flex>
        <Flex align="center" justify="center">
          <AudioRecorder
            recordingSequence={responseRecordingSequence}
            audioFile={isPublicPreview ? null : responseAudioFile}
            onRecordingSequenceStatus={onResponseRecordingSequenceStatus}
            displayType={compactLayout ? 'mobile' : 'desktopSmall'}
            disabled={isPublicPreview}
            dirty={dirty}
            onAudioRecorderStatus={onAudioRecorderStatus}
            onSave={() => submit({ clearDirty: true })}
            onRecordingStart={(recordingId) => {
              if (!assessment) return;

              emitPaperEvent({
                type: 'literacy_recording_started_v3',
                taskId: task.id,
                assessmentId: assessment.id,
                recordingSequenceId: responseRecordingSequence.id,
                recordingId,
              });
            }}
            onRecordingStop={(recordingId) => {
              if (!assessment) return;

              emitPaperEvent({
                type: 'literacy_recording_ended_v3',
                taskId: task.id,
                assessmentId: assessment.id,
                recordingId: recordingId,
                recordingSequenceId: responseRecordingSequence.id,
              });
            }}
          />
        </Flex>
      </Flex>
    );
  } else if (task.responseType === 'written' || taskSubmission.responseType === 'written') {
    return (
      <Flex width={contentWidth} direction="column" height="100%" justify="center">
        {contentModalContent}
        {responseTypeSelect}
        <TextAreaInput
          disabled={isPublicPreview}
          name="writtenContent"
          value={isPublicPreview ? '' : taskSubmission.responseContent}
          onChange={setResponseContent}
          placeholder="Write your answer here"
          height="100%"
          width="100%"
          textSize={textSize}
        />
        <ErrorText errors={taskErrors.get(task.id, ImmutableMap())} errorKey="writtenContent" />
      </Flex>
    );
  } else {
    return null;
  }
};
