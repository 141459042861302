import { Fragment } from 'react';
import { Text, Spacer, Flex, Divider, Tag, ClassAvatar, Reaction } from 'src/components';
import { paddings, useBreakpoints } from 'src/styles';
import { Course } from 'src/models/Course';
import { AssignmentCardProps, AssignmentCard } from './AssignmentCard';
import { ImmutableList, ImmutableMap } from 'src/modules/Immutable';
import styled from 'styled-components';
import { AssessmentExtended, CommentReaction, SubmissionStatusDetails } from 'src/models';

const TimeEstimateStyles = styled(Flex)`
  overflow: none;
  visibility: ${(props: { visible: boolean }) => (props.visible ? 'visible' : 'hidden')};
`;

type StudentAssignmentCardProps = Pick<
  AssignmentCardProps,
  'to' | 'open' | 'onToggleCardOpen' | 'metrics'
> & {
  assessment: AssessmentExtended;
  status: SubmissionStatusDetails;
  reaction: CommentReaction;
  courseIds: ImmutableList<string>;
  courseMap: ImmutableMap<string, Course>;
  readingName?: string;
};

export const StudentAssignmentCard = ({
  assessment,
  status,
  courseIds,
  courseMap,
  reaction,
  ...baseAssignmentCardProps
}: StudentAssignmentCardProps) => {
  const showMobile = useBreakpoints({ smallerThanOrEqualTo: 'tabletSmall' });

  const statusTag = (
    <Tag color={status.color} align="center">
      <Text variant="h6">{status.label}</Text>
      {reaction !== 'undecided' && (
        <>
          <Spacer horizontal size={2} />
          <Reaction kind={reaction} />
        </>
      )}
    </Tag>
  );

  return (
    <AssignmentCard
      {...baseAssignmentCardProps}
      testTag={assessment.id}
      coverImg={assessment.coverImageUrl}
      title={assessment.name}
      isStudent
      subTitle={
        showMobile ? (
          <Flex width="100%" align="center">
            <div>{statusTag}</div>
            {!assessment.independentOnly && (
              <>
                <Divider vertical size={1} />
                <TimeEstimateStyles
                  visible={!assessment.independentOnly}
                  direction="column"
                  align="start"
                  width="80px"
                  padding={`0 0 0 ${paddings[1]}`}
                >
                  <Text color="primaryBlue" variant="h5" whiteSpace="nowrap">
                    {assessment.estimatedReadingTime} min
                  </Text>
                  <Text color="gray5" variant="h7">
                    Est. Read
                  </Text>
                </TimeEstimateStyles>
              </>
            )}
          </Flex>
        ) : (
          <Flex align="stretch">{statusTag}</Flex>
        )
      }
      showMobile={showMobile}
    >
      {!showMobile && (
        <Flex width="100%" justify="end" align="center" grow={1} padding={`0 ${paddings[4]}`}>
          {courseIds.map((courseId) => {
            const course = courseMap.get(courseId);
            return !course ? null : (
              <Fragment key={courseId}>
                <ClassAvatar backgroundColor={course.avatarColor} name={course.name} />
                <Spacer horizontal />
              </Fragment>
            );
          })}
          <Spacer horizontal size={5} />
          <Divider
            vertical
            size={1}
            noMargin
            color={assessment.independentOnly ? 'transparent' : undefined}
          />
          <Spacer horizontal size={5} />
          <TimeEstimateStyles
            visible={!assessment.independentOnly}
            direction="column"
            align="start"
            width="80px"
          >
            <Text color="primaryBlue" variant="h4" whiteSpace="nowrap">
              {assessment.estimatedReadingTime} min
            </Text>
            <Text color="gray5" variant="h7">
              Est. Read
            </Text>
          </TimeEstimateStyles>
        </Flex>
      )}
    </AssignmentCard>
  );
};
