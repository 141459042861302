import { useQuery } from 'src/modules/Router';
import { Text, Button } from 'src/components';
import { BASIC_ERROR } from 'src/modules/Api';
import { SimpleLayout } from 'src/layouts';

export const ErrorPage = () => {
  const query = useQuery();
  const originalPath = query.get('originalPath');
  return (
    <SimpleLayout loading={false}>
      <Text>Error! {query.get('message') || BASIC_ERROR}</Text>
      {originalPath && <Button to={originalPath}>Try Again!</Button>} <Button to="/">Home</Button>
    </SimpleLayout>
  );
};
