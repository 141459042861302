import { subDataCreator } from 'src/State';
import { defaultState } from './DuplicateModalState';
import { ImmutableMap } from 'src/modules/Immutable';
import { DuplicateAssessmentData } from 'src/models';

export const createDefaultDuplicateModalData = subDataCreator(
  'DuplicateModalData',
  defaultState,
  ({ set, setField, get, fetchJson }) => ({
    setField,
    openDuplicateModal: (assessmentId) => set({ assessmentId, opened: true }),
    submitDuplicate: (createToast, history) => () => {
      const { assessmentId, duplicatingName } = get();
      if (!assessmentId) return;

      set({ submitting: true });
      fetchJson(`/api/assessments/${assessmentId}/duplicate`, {
        method: 'POST',
        data: { name: duplicatingName },
        onSuccess: (data: DuplicateAssessmentData) => {
          set({ submitting: false, duplicatingErrors: ImmutableMap(data.errors) });
          if (data.success) {
            set({ duplicatingName: '', opened: false });
            history.push(`/t/assignments/${data.assessment.id}`);
            createToast({ children: 'Assignment has been duplicated!' });
          }
        },
      });
    },
  }),
);
