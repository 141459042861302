import { ReadingTaskDetails, VocabTask, VocabTaskDetails } from 'src/models';
import {
  PDFReader,
  Text,
  PlainTextReader,
  Flex,
  EmptyStateBase,
  ReaderDisplayType,
} from 'src/components';
import { emptyTextRange, TextRange } from 'src/modules/TextRange';
import React from 'react';
import { paddings } from 'src/styles';
import Bookworm from 'src/assets/bookworm.svg';
import { ImmutableList } from 'src/modules/Immutable';
import { DEFAULT_LANGUAGE_CODE, useLanguageCodeDetails } from 'src/modules/LanguageCodes';

type ReaderProps = {
  instructions: string;
  readingTaskDetails: ReadingTaskDetails | null;
  vocabTask?: VocabTask | null;
  onTextRangeSelected?: (textRange: TextRange, text: string) => void;
  drawerOpen?: boolean;
  selectableText?: boolean;
  highlightedWord?: string;
  setVocabWord?: (text: string) => void;
  enableTextRangeSelection: boolean;
  vocabTaskDetailsList?: ImmutableList<VocabTaskDetails>;
  displayTypeOverride?: ReaderDisplayType;
  compactLayout: boolean;
};

export const NoContent = ({ children }: { children?: React.ReactNode }) => (
  <Flex height="100%" width="100%" align="center" justify="center">
    <Text variant="h1">{children || 'No content'}</Text>
  </Flex>
);

export const Reader = ({
  readingTaskDetails,
  vocabTask,
  onTextRangeSelected,
  drawerOpen = false,
  instructions,
  highlightedWord,
  setVocabWord,
  enableTextRangeSelection: baseEnableTextRangeSelection,
  vocabTaskDetailsList,
  displayTypeOverride,
  compactLayout,
}: ReaderProps) => {
  const languageCodeDetails = useLanguageCodeDetails(
    readingTaskDetails?.reading?.languageCode || DEFAULT_LANGUAGE_CODE,
  );
  if (!readingTaskDetails) return <NoContent />;
  const { contentFile, basicTextFile, task, reading } = readingTaskDetails;

  const enableTextRangeSelection = Boolean(
    baseEnableTextRangeSelection &&
      onTextRangeSelected &&
      task.readingPartType === 'start_and_end_index',
  );

  const textRange = task.readingPartType === 'start_and_end_index' ? task : emptyTextRange;

  const displayType = (() => {
    if (displayTypeOverride) {
      return displayTypeOverride;
    } else if (vocabTask) {
      return 'setVocabWord';
    } else if (enableTextRangeSelection) {
      return 'setTextRange';
    } else if (onTextRangeSelected) {
      return 'textOnly';
    } else {
      return 'dictionary';
    }
  })();

  if (reading.contentType === 'independent') {
    return (
      <Flex padding={paddings[compactLayout ? 2 : 10]} width="100%" justify="center" grow={1}>
        <Flex
          maxWidth="700px"
          width="100%"
          direction="column"
          align="center"
          justify="center"
          height="100%"
        >
          <EmptyStateBase
            testTag="empty-state-independentReading"
            illustration={Bookworm}
            primaryText={reading.name}
            secondaryText={
              <Text textAlign="center" whiteSpace="break-spaces">
                {instructions}
              </Text>
            }
            displayType={compactLayout ? 'minimal' : 'normal'}
          />
        </Flex>
      </Flex>
    );
  } else if (['PdfStoredFile', 'PdfByPageStoredFile'].includes(contentFile?.type || '')) {
    return (
      <PDFReader
        readingTaskDetails={readingTaskDetails}
        drawerOpen={drawerOpen}
        kind={compactLayout ? 'compact' : 'full'}
      />
    );
  } else if (vocabTask) {
    return (
      <PlainTextReader
        languageCodeDetails={languageCodeDetails}
        textFile={contentFile?.type === 'TextStoredFile' ? contentFile : basicTextFile}
        displayType={displayType}
        textRange={textRange}
        enableTextRangeSelection={enableTextRangeSelection}
        drawerOpen={drawerOpen}
        highlightedWord={highlightedWord}
        setVocabWord={setVocabWord}
        vocabTaskDetailsList={vocabTaskDetailsList}
        compactLayout={compactLayout}
      />
    );
  } else if (contentFile?.type === 'TextStoredFile') {
    return (
      <PlainTextReader
        languageCodeDetails={languageCodeDetails}
        textFile={onTextRangeSelected ? contentFile : basicTextFile}
        displayType={displayType}
        onTextRangeSelected={onTextRangeSelected}
        textRange={textRange}
        enableTextRangeSelection={enableTextRangeSelection}
        drawerOpen={drawerOpen}
        vocabTaskDetailsList={vocabTaskDetailsList}
        compactLayout={compactLayout}
      />
    );
  } else if (!basicTextFile) {
    return <NoContent />;
  } else {
    return (
      <PlainTextReader
        languageCodeDetails={languageCodeDetails}
        textFile={basicTextFile}
        displayType={displayType}
        onTextRangeSelected={onTextRangeSelected}
        textRange={textRange}
        enableTextRangeSelection={enableTextRangeSelection}
        drawerOpen={drawerOpen}
        vocabTaskDetailsList={vocabTaskDetailsList}
        compactLayout={compactLayout}
      />
    );
  }
};
