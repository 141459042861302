import styled from 'styled-components';

import { Switch, Route, Redirect } from 'react-router-dom';
import { RedirectHomeOnAccountType } from 'src/modules/Router';
import { commonLayoutStyles } from 'src/modules/Layout';
import { TopBar } from 'src/layouts/TopBar';
import { UserSearch } from 'src/pages/UserSearch';
import { Title } from 'src/components';
import { PlaylistManager } from 'src/pages/PlaylistManager';

const BaseModeratorLayoutStyles = styled.div`
  ${commonLayoutStyles}

  display: grid;
  grid-template-rows: auto 1fr;
  grid-template-columns: 1fr;
`;

const routes = [
  {
    path: '/m/user-search',
    component: <UserSearch />,
    title: 'User Search',
  },
  {
    path: '/m/playlists',
    component: <PlaylistManager />,
    title: 'Playlist Manager',
  },
];

export const ModeratorLayout = () => (
  <BaseModeratorLayoutStyles>
    <RedirectHomeOnAccountType accountTypes={['student', 'teacher']} />
    <Switch>
      {routes.map(({ component, title, ...routeProps }, index) => (
        <Route key={index} {...routeProps}>
          <TopBar />
          {title && <Title title={title} />}
          {component}
        </Route>
      ))}
      <Route>
        <Redirect to="/" />
      </Route>
    </Switch>
  </BaseModeratorLayoutStyles>
);
