import { SetField } from 'src/State';
import { emptyErrorMap, ErrorMap } from 'src/modules/Api';
import { History } from 'src/modules/Router';
import { Registration } from 'src/models';

export type JoinCourseStateResponse = {
  success: boolean;
  errors: ErrorMap;
  registration: Registration;
};

export type JoinCourseState = {
  open: boolean;
  courseCode: string;
  errors: ErrorMap;
  loading: boolean;
  submit: (history: History) => void;
  checkCode: (courseCode: string) => void;
  load: (props: { joinCode: string }) => void;
  setField: SetField<JoinCourseState>;
};

export const defaultState = {
  open: false,
  loading: false,
  courseCode: '',
  errors: emptyErrorMap(),
};
