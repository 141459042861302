import { SetField } from 'src/State';
import { PaperRosterSyncStatus } from 'src/models';
import { DateString } from 'src/modules/Time';

export type PaperSyncState = {
  setField: SetField<PaperSyncState>;
  sync: (kind: 'manual' | 'auto') => void;
  notificationKind: 'hidden' | 'banner' | 'toast';
  closeBanner: () => void;
  getStatus: () => PaperRosterSyncStatus | null;
  getIsSyncing: () => boolean;
  getLastSyncedAt: () => DateString | null;
  localStatus: PaperRosterSyncStatus | null;
  localLastSyncedAt: DateString | null;
};

export const defaultState = {
  notificationKind: 'hidden' as const,
  localStatus: null,
  localLastSyncedAt: null,
};
