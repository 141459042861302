import { ReadingStudioLayoutSideBar } from 'src/layouts';
import { TaskBuilderSidebar } from './TaskBuilderSideBar';
import { GeneralSideBar } from './GeneralSideBar';
import { NewTaskSideBar } from './NewTaskSideBar';
import { useCurrentTaskDetails } from './AssignmentBuilderHelpers';
import { Route, Switch } from 'react-router-dom';

export const AssignmentBuilderSideBar = () => {
  const currentTaskDetails = useCurrentTaskDetails();
  return (
    <ReadingStudioLayoutSideBar
      content={
        <Switch>
          <Route path="/t/assignment-builder/:assessmentId/tasks/new" exact>
            <NewTaskSideBar />
          </Route>
          <Route>{currentTaskDetails ? <TaskBuilderSidebar /> : <GeneralSideBar />}</Route>
        </Switch>
      }
    />
  );
};
