import { TranscriptableAudioStoredFile } from './TranscriptableAudioStoredFile';

export type RecordingSequence = {
  id: string;
  processingStatus: 'waiting' | 'queued' | 'started' | 'finished';
  duration: number;
};

export type AddRecordingResponse = {
  recordingSequence: RecordingSequence;
  audioFile: TranscriptableAudioStoredFile | null;
  pointsEarned: number;
  pointBalance: number;
};
