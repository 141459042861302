import styled from 'styled-components';

import { Switch, Route, Redirect } from 'react-router-dom';
import { TeacherLayoutContent } from './TeacherLayoutContent';
import { TeacherCourseList } from 'src/pages/TeacherCourseList';
import { TeacherStudentList } from 'src/pages/TeacherStudentList';
import { queryMapToString, RedirectHomeOnAccountType, useQuery } from 'src/modules/Router';
import { UserProfile } from 'src/pages/UserProfile';
import { TeacherAssignmentList } from 'src/pages/TeacherAssignmentList';
import { TeacherAssignmentOverview } from 'src/pages/TeacherAssignmentOverview';
import { TaskOverview } from 'src/pages/TaskOverview';
import { commonLayoutStyles } from 'src/modules/Layout';
import { ReadingBuilder } from 'src/pages/ReadingBuilder';
import { AssignmentBuilder } from 'src/pages/AssignmentBuilder';
import { ReadingStudio } from 'src/pages/ReadingStudio';
import { TeacherOnboarding } from 'src/pages/TeacherOnboarding';
import { ProgressMonitoring } from 'src/pages/ProgressMonitoring';
import { StudentProgressMonitoring } from 'src/pages/StudentProgressMonitoring';
import { PlanList } from 'src/pages/PlanList';
import { PUBLIC_PREVIEW_PATH } from 'src/models';
import { TopBar } from 'src/layouts/TopBar';
import { Title } from 'src/components';
import { ContentLibrary } from 'src/pages/ContentLibrary';

const BaseTeacherLayoutStyles = styled.div`
  ${commonLayoutStyles}

  display: grid;
  grid-template-rows: auto 1fr;
  grid-template-columns: 1fr;
`;

const routes = [
  {
    path: ['/t/assignments', '/t/assignments/assign'],
    exact: true,
    component: <TeacherAssignmentList />,
    title: 'Assignments',
  },
  {
    path: [
      '/t/assignments/:id',
      '/t/assignments/:id/assign',
      '/t/assignments/:id/archive',
      '/t/assignments/:id/restore',
    ],
    exact: true,
    component: <TeacherAssignmentOverview />,
  },
  {
    path: ['/t/assignments/task/:type/:id'],
    exact: true,
    component: <TaskOverview />,
  },
  {
    path: '/t/progress-monitoring',
    exact: true,
    component: <ProgressMonitoring />,
    title: 'Progress Monitoring',
  },
  {
    path: '/t/progress-monitoring/:userId',
    exact: true,
    component: <StudentProgressMonitoring />,
  },
  {
    path: '/t/library',
    component: <ContentLibrary />,
  },
  {
    path: '/t/profile',
    exact: true,
    component: <UserProfile ContentElement={TeacherLayoutContent} />,
    title: 'My Profile',
  },
  {
    path: '/t/classes',
    component: <TeacherCourseList ContentElement={TeacherLayoutContent} />,
    title: 'Classes',
  },
  {
    path: '/t/students',
    component: <TeacherStudentList ContentElement={TeacherLayoutContent} />,
    title: 'Students',
  },
  { path: '/t/plans', component: <PlanList />, title: 'Plans' },
];

export const TeacherLayout = () => {
  const query = useQuery();

  return (
    <>
      <RedirectHomeOnAccountType accountTypes={['student']} />
      <Switch>
        <Route path="/t/reading-builder">
          <Title title="Reading Builder" />
          <ReadingBuilder />
        </Route>
        <Route path="/t/assignment-builder/:assessmentId">
          <Title title="Assignment Builder" />
          <AssignmentBuilder />
        </Route>
        <Route path="/t/onboarding">
          <Title title="Teacher Onboarding" />
          <TeacherOnboarding />
        </Route>
        <Route path={PUBLIC_PREVIEW_PATH} exact>
          <ReadingStudio />
        </Route>

        <Route>
          <BaseTeacherLayoutStyles>
            <Switch>
              {routes.map(({ component, title, ...routeProps }, index) => (
                <Route key={index} {...routeProps}>
                  {title && <Title title={title} />}
                  <TopBar />
                  {component}
                </Route>
              ))}
              <Route>
                <Redirect
                  to={{
                    pathname: '/t/assignments',
                    search: queryMapToString(query),
                  }}
                />
              </Route>
            </Switch>
          </BaseTeacherLayoutStyles>
        </Route>
      </Switch>
    </>
  );
};
