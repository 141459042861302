import { ReactNode } from 'react';
import { Flex, FlexPrefixProps } from 'src/components';
import { colors, paddings, useBreakpoints, Width } from 'src/styles';

type CenteredLayoutProps = {
  children: ReactNode;
  centeredMaxWidth?: Width;
} & FlexPrefixProps;

export const CenteredLayout = ({
  children,
  centeredMaxWidth = '1150px',
  ...flexProps
}: CenteredLayoutProps) => {
  const showMobile = useBreakpoints({ smallerThanOrEqualTo: 'tabletSmall' });

  return (
    <Flex
      width="100%"
      align="center"
      direction="column"
      backgroundColor={colors.backgroundLight.hex}
      padding={`0 ${paddings[showMobile ? 3 : 8]}`}
      {...flexProps}
    >
      <Flex
        direction="column"
        maxWidth={centeredMaxWidth}
        width="100%"
        height="100%"
        minWidth={flexProps.minWidth}
      >
        {children}
      </Flex>
    </Flex>
  );
};
