import { Button } from 'src/components';
import { ReadingStudioLayoutTopBar } from 'src/layouts';
import { useQuery } from 'src/modules/Router';
import { useStore } from 'src/Store';

export const ReadingBuilderTopBar = () => {
  const { name, authorName, coverImageFileUrl, coverImageFile, webSourceUrl, contentType } =
    useStore(
      ({ ReadingBuilderData: s }) => ({
        name: s.name,
        authorName: s.authorName,
        coverImageFileUrl: s.coverImageFileUrl,
        coverImageFile: s.coverImageFile,
        webSourceUrl: s.webSourceUrl,
        contentType: s.contentType,
      }),
      [],
    );

  const query = useQuery();
  const backPath = query.get('backPath');

  return (
    <ReadingStudioLayoutTopBar
      leftButton={
        <Button color="gray2" nav to={backPath || '/t'}>
          Cancel
        </Button>
      }
      thumbnailUrl={coverImageFileUrl || coverImageFile?.thumbnailUrl}
      readingTitle={name}
      authorName={authorName}
      webSourceUrl={contentType === 'web' ? webSourceUrl : null}
    />
  );
};
