import { Button, Flex, Icon, Loading, Spacer, Text } from 'src/components';
import { formatTimeAgo } from 'src/modules/Time';
import { useStore } from 'src/Store';
import { paddings } from 'src/styles';

type PaperSyncButtonProps = {
  showSyncStatus?: boolean;
};

const SyncWithText = () => (
  <>
    Sync with <Spacer horizontal />
    <Text variant="h6" fontStyle="italic">
      PAPER
    </Text>
  </>
);

export const PaperSyncButton = ({ showSyncStatus = false }: PaperSyncButtonProps) => {
  const { sync, isSyncing, lastSyncedAt } = useStore(
    ({ PaperSyncData: psd }) => ({
      sync: psd.sync,
      isSyncing: psd.getIsSyncing(),
      lastSyncedAt: psd.getLastSyncedAt(),
    }),
    [],
  );
  const lastSyncedAtText = lastSyncedAt ? formatTimeAgo(new Date(lastSyncedAt)) : 'Never';

  return (
    <Flex direction="column" width="fit-content">
      <Button
        displayType="outline"
        paddingOverride={`${paddings[3]} ${paddings[8]}`}
        onClick={() => sync('manual')}
        disabled={isSyncing}
      >
        {isSyncing ? (
          <>
            <Loading size="medium" />
            <Spacer horizontal size={2} />
            Syncing with <Spacer horizontal />
            <Text variant="h6" fontStyle="italic">
              PAPER
            </Text>
          </>
        ) : (
          <>
            <Icon icon="resync" strokeWidth={0} size="25px" />
            <Spacer horizontal size={2} />
            <SyncWithText />
          </>
        )}
      </Button>
      {showSyncStatus && (
        <>
          <Spacer size="half" />
          <Flex justify="center">
            <Text color="gray7">Last Synced: {lastSyncedAtText}</Text>
          </Flex>
        </>
      )}
    </Flex>
  );
};
