import { subDataCreator } from 'src/State';
import { defaultState } from './PaperSyncState';

export const createDefaultPaperSyncData = subDataCreator(
  'PaperSyncData',
  defaultState,
  ({ set, get, getFull, setField, fetchJson, refreshPaperAccessToken }) => ({
    setField,
    sync: async (kind) => {
      if (get().getIsSyncing()) return;

      set({ notificationKind: 'hidden', localStatus: 'started' });
      await refreshPaperAccessToken();
      fetchJson(`/api/paper_auths/roster`, { method: 'POST', data: { kind } });
    },
    closeBanner: () => {
      set({ notificationKind: 'hidden' });
    },
    getStatus() {
      return get().localStatus ?? getFull().AppData.currentUser?.paperRosterSyncStatus ?? null;
    },
    getIsSyncing() {
      return get().getStatus() === 'started';
    },
    getLastSyncedAt() {
      return get().localLastSyncedAt ?? getFull().AppData.currentUser?.paperRosterSyncedAt ?? null;
    },
  }),
);
