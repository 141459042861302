import { useEffect } from 'react';
import { useStore } from './Store';
import { Switch, Route, Redirect } from 'react-router-dom';
import { ReleaseBanner, LayoutContent, Toaster, Flex, Title, MobileGate } from 'src/components';

import { AvatarGallery } from 'src/pages/AvatarGallery';
import { Login } from 'src/pages/Login';
import { Logout } from 'src/pages/Logout';
import { SignUpClassCode } from 'src/pages/SignUpClassCode';
import { SignUpRegister } from 'src/pages/SignUpRegister';
import { SignUpConfirmationSent } from 'src/pages/SignUpConfirmationSent';
import { ConfirmEmail } from 'src/pages/ConfirmEmail';
import { ErrorPage } from 'src/pages/ErrorPage';
import { ForgotPassword } from 'src/pages/ForgotPassword';
import { ResetPassword } from 'src/pages/ResetPassword';
import { SignUpAccountType } from 'src/pages/SignUpAccountType';
import { ReadingStudio } from 'src/pages/ReadingStudio';
import { CleverOauthCallback } from 'src/pages/CleverOauthCallback';
import { GoogleOauthCallback } from 'src/pages/GoogleOauthCallback';
import { JoinCourseRedirect } from 'src/pages/JoinCourseRedirect';
import { AutoAssignRedirect } from 'src/pages/AutoAssignRedirect';
import { BackToPaper } from 'src/pages/BackToPaper';
import { SubmissionOverview } from 'src/pages/SubmissionOverview';
import { StudentLayout, TeacherLayout, ModeratorLayout } from 'src/layouts';
import * as Analytics from 'src/modules/Analytics/Analytics';
import * as AnalyticsEvents from 'src/modules/Analytics/AnalyticsEvents';
import { makeQueryString } from 'src/modules/Api';
import { PaperSyncBanner } from 'src/components/PaperSync';
import { SignUpSchoolCode } from './pages/SignUpSchoolCode';

Analytics.init();

const RootRouteRedirect = () => {
  const { currentUser, loading } = useStore(
    ({ AppData }) => ({
      currentUser: AppData.currentUser,
      loading: AppData.loading,
    }),
    [],
  );
  if (!loading && !currentUser) {
    return <Redirect to="/login" />;
  }
  return (
    <LayoutContent loading={loading}>
      {currentUser?.accountType === 'student' ? <Redirect to="/s/" /> : <Redirect to="/t/" />}
    </LayoutContent>
  );
};

export const App = (): JSX.Element => {
  const { load, loading } = useStore(
    ({ AppData }) => ({
      load: AppData.load,
      loading: AppData.loading,
    }),
    [],
  );

  useEffect(() => {
    if (!window.location.pathname.includes('/login-from-paper/')) {
      load();
    }
  }, [load]);

  return (
    <>
      <Route path="/" component={AnalyticsEvents.trackPageView({ loading })} />
      <Flex grow={1} direction="column" minHeight={0}>
        <ReleaseBanner />
        <PaperSyncBanner />
        <MobileGate />
        <Switch>
          <Route path={['/404', '/500']} exact>
            <Title title="Error" />
            <ErrorPage />
          </Route>
          <Route path="/login" exact>
            <Title title="Login" />
            <Login />
          </Route>
          <Route path="/google-oauth-callback" exact>
            <GoogleOauthCallback />
          </Route>
          <Route path="/clever-oauth-callback" exact>
            <CleverOauthCallback />
          </Route>
          <Route path="/sign-up" exact>
            <Title title="Sign Up" />
            <SignUpAccountType />
          </Route>
          <Route path="/logout" exact>
            <Logout />
          </Route>
          <Route path="/back-to-paper" exact>
            <BackToPaper />
          </Route>
          <Route path="/forgot-password" exact>
            <Title title="Forgot Password" />
            <ForgotPassword />
          </Route>
          <Route path="/sign-up/student" exact>
            <Redirect to="/sign-up/student/class-code" />
          </Route>
          <Route path="/sign-up/teacher" exact>
            <Redirect to="/sign-up/teacher/register" />
          </Route>
          <Route path="/sign-up/teacher/school-code" exact>
            <Title title="Sign Up" />
            <SignUpSchoolCode />
          </Route>
          <Route path="/sign-up/student/class-code" exact>
            <Title title="Sign Up" />
            <SignUpClassCode />
          </Route>
          <Route path="/sign-up/:accountType/register" exact>
            <Title title="Sign Up" />
            <SignUpRegister />
          </Route>
          <Route path="/confirmation-sent" exact>
            <Title title="Confirmation Sent" />
            <SignUpConfirmationSent />
          </Route>
          <Route path="/confirm-email/:id/:token" exact>
            <ConfirmEmail />
          </Route>
          <Route path="/password-resets/:id/:token" exact>
            <Title title="Reset Password" />
            <ResetPassword />
          </Route>
          <Route path="/reading-studio">
            <ReadingStudio />
          </Route>
          <Route path="/submissions/:id" exact>
            <SubmissionOverview />
          </Route>
          <Route path="/j/">
            <JoinCourseRedirect />
          </Route>
          <Route path="/a/">
            <AutoAssignRedirect />
          </Route>
          <Route
            path="/q/"
            component={() => {
              window.location.href = 'https://www.readlee.com/contact';
              return null;
            }}
          />
          <Route path="/t/">
            <TeacherLayout />
          </Route>
          <Route path="/s/">
            <StudentLayout />
          </Route>
          <Route path="/m/">
            <ModeratorLayout />
          </Route>
          <Route path="/" exact>
            <RootRouteRedirect />
          </Route>
          <Route path="/avatar-gallery" exact>
            <Title title="Avatars of Readlee" />
            <AvatarGallery />
          </Route>
          <Redirect to={`/404${makeQueryString({ message: 'Page Not Found!' })}`} />
        </Switch>
        <Toaster />
      </Flex>
    </>
  );
};
