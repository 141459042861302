import { subDataCreator } from 'src/State';
import { defaultState, JoinCourseStateResponse } from './JoinCourseState';
import { ImmutableMap } from 'src/modules/Immutable';
import { checkCourseCode } from 'src/models';

export const createDefaultJoinCourseData = subDataCreator(
  'JoinCourseData',
  defaultState,
  ({ set, get, setField, fetchJson }) => ({
    setField,
    load: ({ joinCode }) => set({ ...defaultState, courseCode: joinCode, open: Boolean(joinCode) }),
    checkCode: (courseCode: string) => {
      if (!courseCode) return;
      set({ loading: true });
      checkCourseCode({
        fetchJson,
        courseCode,
        onSuccess: ({ errors }) => {
          set({
            errors: ImmutableMap(errors),
            loading: false,
          });
        },
      });
    },
    submit: (history) => {
      set({ loading: true });
      fetchJson(`/api/registrations`, {
        method: 'POST',
        data: {
          courseCode: get().courseCode,
        },
        onSuccess: ({ success, errors }: JoinCourseStateResponse) => {
          if (success) {
            set(defaultState);
            history.push('/');
          } else {
            set({
              errors: ImmutableMap(errors),
              loading: false,
            });
          }
        },
      });
    },
  }),
);
