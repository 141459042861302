import React, { useState, useMemo, useCallback, ReactNode } from 'react';
import { ImmutableList } from 'src/modules/Immutable';
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableEntry,
  TableHeaderEntry,
  SortArrow,
  SortDirection,
  Button,
  Flex,
  Spacer,
  Text,
  Tooltip,
  EmptyStateContainer,
} from 'src/components';
import { TextAlign } from 'src/styles';
import { useHistory, useQuery, updateQuery, useLocation } from 'src/modules/Router';

type BaseColumn<R> = {
  id: string;
  name: React.ReactNode;
  render: (row: R, index: number) => React.ReactNode;
  textAlign?: TextAlign;
  tooltipContent?: string;
};

type BaseSortableColumn<R> = BaseColumn<R> & {
  defaultSortDirection?: SortDirection;
};

type SortByColumn<R> = BaseSortableColumn<R> & {
  sortBy: (row: R) => number | string | Array<number | string>;
  sortType: 'sortBy';
};

type SortColumn<R> = BaseSortableColumn<R> & {
  sort: (row1: R, row2: R) => number;
  sortType: 'sort';
};

type SortableColumn<R> = SortColumn<R> | SortByColumn<R>;

type NonSortableColumn<R> = BaseColumn<R> & {
  sortType: 'none';
};

export type SuperTableColumn<R> = SortableColumn<R> | NonSortableColumn<R>;
export type SuperTableColumns<R> = Array<SuperTableColumn<R>>;

type SortSettings = {
  columnId: string | null;
  sortDirection: SortDirection | null;
};

const getNewSortSettings: <R>(
  sortSettings: SortSettings,
  column: SortableColumn<R>,
) => SortSettings = (sortSettings, column) => {
  if (sortSettings.columnId !== column.id || !sortSettings.sortDirection) {
    return {
      columnId: column.id,
      sortDirection: column.defaultSortDirection ?? 'asc',
    };
  }

  return {
    columnId: column.id,
    sortDirection: sortSettings.sortDirection === 'asc' ? 'desc' : 'asc',
  };
};

const sortRows: <R>(
  rows: ImmutableList<R>,
  sortSettings: SortSettings,
  columns: Array<SuperTableColumn<R>>,
) => ImmutableList<R> = (rows, sortSettings, columns) => {
  if (!(sortSettings.columnId && sortSettings.sortDirection)) return rows;

  const sortColumn = columns.find(
    (column) => column.sortType !== 'none' && column.id === sortSettings.columnId,
  );
  if (!sortColumn || sortColumn.sortType === 'none') {
    throw new Error(`Attempting to sort on bad column ${sortSettings.columnId}`);
  }

  let sorted = rows;
  if (sortColumn.sortType === 'sortBy') {
    sorted = rows.sortBy(sortColumn.sortBy);
  } else if (sortColumn.sortType === 'sort') {
    sorted = rows.sort(sortColumn.sort);
  }

  if (sortSettings.sortDirection === 'asc') {
    return sorted;
  } else {
    return sorted.reverse();
  }
};

const getRows: <R>(
  rows: ImmutableList<R>,
  columns: SuperTableColumns<R>,
  getRowId: (row: R) => string | number,
  rowClickable?: boolean | ((row: R) => boolean),
  onRowClick?: (row: R) => void,
) => ImmutableList<JSX.Element> = (rows, columns, getRowId, rowClickable, onRowClick) =>
  rows.map((row) => {
    const clickable =
      typeof rowClickable === 'boolean' ? rowClickable : rowClickable?.(row) ?? false;

    return (
      <TableRow
        key={getRowId(row)}
        clickable={clickable}
        onClick={() => clickable && onRowClick?.(row)}
      >
        {columns.map((column, index) => (
          <TableEntry key={`${getRowId(row)}-${column.id}`}>{column.render(row, index)}</TableEntry>
        ))}
      </TableRow>
    );
  });

type SuperTableProps<R> = {
  columns: SuperTableColumns<R>;
  rows: ImmutableList<R>;
  getRowId: (row: R) => string | number;
  initialSortSettings?: Required<SortSettings>;
  onRowClick?: (row: R) => void;
  rowClickable?: boolean | ((row: R) => boolean);
  queryParam: string;
  fixedTopRows?: ImmutableList<R>;
  noPadding?: boolean;
  emptyStateContent?: ReactNode;
};

export const SuperTable: <R>(props: SuperTableProps<R>) => JSX.Element = ({
  columns,
  rows,
  getRowId,
  onRowClick,
  rowClickable,
  initialSortSettings,
  queryParam,
  noPadding,
  emptyStateContent,
  fixedTopRows = ImmutableList(),
}) => {
  const query = useQuery();
  const history = useHistory();
  const pathname = useLocation().pathname;
  const directionQueryParam = `${queryParam}Direction`;

  const getSortSettings = () => {
    const param = query.get(queryParam);
    const direction = query.get(directionQueryParam) as SortDirection;

    if (param) {
      return {
        columnId: param,
        sortDirection: direction ?? 'asc',
      };
    } else if (initialSortSettings) {
      return initialSortSettings;
    }

    return {
      columnId: null,
      sortDirection: null,
    };
  };

  const [sortSettings, setSortSettingsState] = useState<SortSettings>(getSortSettings());

  const setSortSettings = useCallback(
    (sortSettings: SortSettings) => () => {
      setSortSettingsState(sortSettings);
      history.replace(
        `${pathname}${updateQuery(query, {
          [queryParam]: sortSettings.columnId,
          [directionQueryParam]: sortSettings.sortDirection,
        })}`,
      );
    },
    [history, pathname, query, queryParam, directionQueryParam],
  );

  const sortedRows = useMemo(
    () => sortRows(rows, sortSettings, columns),
    [rows, sortSettings, columns],
  );

  const sortedFixedRows = useMemo(
    () => sortRows(fixedTopRows, sortSettings, columns),
    [fixedTopRows, sortSettings, columns],
  );

  const showEmptyState =
    Boolean(emptyStateContent) && sortedRows.isEmpty() && sortedFixedRows.isEmpty();

  return (
    <>
      <Table noPadding={noPadding} showEmptyState={showEmptyState}>
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableHeaderEntry key={column.id} $textAlign={column.textAlign}>
                {column.sortType !== 'none' ? (
                  <Tooltip
                    disabled={!column.tooltipContent}
                    content={<Text color="black">{column.tooltipContent}</Text>}
                  >
                    <Button
                      displayType="noStyles"
                      onClick={setSortSettings(getNewSortSettings(sortSettings, column))}
                    >
                      <Flex align="center" justify="center">
                        <Text variant="h6" color="gray7">
                          {column.name}
                        </Text>
                        <Spacer horizontal />
                        {sortSettings.columnId === column.id && sortSettings.sortDirection && (
                          <SortArrow direction={sortSettings.sortDirection} />
                        )}
                      </Flex>
                    </Button>
                  </Tooltip>
                ) : (
                  <Text variant="h6" color="gray7">
                    {column.name}
                  </Text>
                )}
              </TableHeaderEntry>
            ))}
          </TableRow>
        </TableHead>

        {!showEmptyState && (
          <TableBody>
            {getRows(sortedFixedRows, columns, getRowId, rowClickable, onRowClick)}
            {getRows(sortedRows, columns, getRowId, rowClickable, onRowClick)}
          </TableBody>
        )}
      </Table>
      {showEmptyState && <EmptyStateContainer>{emptyStateContent}</EmptyStateContainer>}
    </>
  );
};
