import { subDataCreator } from 'src/State';
import { defaultState, LoadResponse } from './TaskOverviewState';
import { TaskType } from 'src/models';

export const createDefaultTaskOverviewData = subDataCreator(
  'TaskOverviewData',
  defaultState,
  ({ set, setField, fetchJson }) => ({
    setField,
    upgradeAssessment: () =>
      set((s) => {
        if (!s.assessment) return s;
        return { assessment: { ...s.assessment, isUltimate: true } };
      }),
    load: (taskId: string, type: TaskType) => {
      set({ loading: true });
      fetchJson(`/api/tasks/${taskId}`, {
        method: 'GET',
        data: { taskType: type },
        onSuccess: ({ success: _, ...data }: LoadResponse) => {
          set({
            ...data,
            loading: false,
          });
        },
      });
    },
  }),
);
