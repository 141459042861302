import { appcues, gtm, zendeskDisplay, zendesk, getAmplitude } from './Analytics';
import { School, User } from 'src/models';

import * as ErrorReporting from 'src/modules/ErrorReporting';
import { useStore } from 'src/Store';
import { useEffect } from 'react';
import { BreakpointProps, useBreakpoints } from 'src/styles';
import { noop } from 'lodash';
import { ImmutableList } from '../Immutable';

let zendeskHiders = 0;
const shouldShowZendesk = (user: User | null | undefined) => {
  return user && (user.accountType !== 'student' || user.paperId) && zendeskHiders <= 0;
};

export const useHideZendesk = (props?: BreakpointProps) => {
  const currentUser = useStore(({ AppData }) => AppData.currentUser, []);
  const passesBreakpoint = useBreakpoints(props);

  useEffect(() => {
    if (passesBreakpoint) {
      zendeskHiders += 1;
      zendeskDisplay('webWidget', 'hide');
      return () => {
        zendeskHiders -= 1;
        if (shouldShowZendesk(currentUser)) {
          zendeskDisplay('webWidget', 'show');
        }
      };
    } else {
      return noop;
    }
  }, [currentUser, passesBreakpoint]);
};

export const identify = async ({ user }: { user: User; schools: ImmutableList<School> }) => {
  ErrorReporting.setUser(user);

  const zendeskIdentity = {
    name: user.accountType === 'student' ? `${user.givenName}-${user.paperId}` : user.displayName,
    email: user.accountType === 'student' ? '' : user.email || user.username || user.id,
  };

  if (user.accountType === 'student') {
    window.zESettings = {
      webWidget: {
        contactForm: {
          suppress: true,
        },
      },
    };
  }

  zendeskDisplay('webWidget', shouldShowZendesk(user) ? 'show' : 'hide');
  zendeskDisplay('webWidget', 'identify', { ...zendeskIdentity });
  zendeskDisplay('webWidget', 'prefill', {
    name: { value: zendeskIdentity.name },
    email: { value: zendeskIdentity.email },
  });

  zendesk((zd) => {
    zd.livechat.set({ name: zendeskIdentity.name, email: zendeskIdentity.email ?? '' });
    zd.livechat.addTags(
      `accountType:${user.accountType}`,
      `status:${user.status}`,
      `paper_id:${user.paperId || 'N/A'}`,
      `district_id:${user.paperDistrictId || 'N/A'}`,
    );
  });

  const { accountType, ...userAttrs } = user;
  appcues((ac) => {
    ac.identify(user.username, { userType: accountType, ...userAttrs });
  });

  gtm('config', process.env.GOOGLE_ANALYTICS_ID);
  gtm('set', 'user_properties', user);

  const amplitude = await getAmplitude();
  amplitude?.setUserId(user.id);
  amplitude?.setUserProperties({
    ...user,
  });
};

export const identifyAnonymous = async () => {
  appcues((ac) => {
    ac.identify('anonymous-user', { loggedIn: false });
  });
};

export const deidentify = async () => {
  ErrorReporting.setUser(null);

  zendeskDisplay('webWidget', 'hide');
  zendeskDisplay('webWidget', 'logout');
  zendeskDisplay('webWidget', 'clear');

  zendesk((zd) => zd.livechat.clearAll());
  appcues((ac) => ac.reset());

  const amplitude = await getAmplitude();
  amplitude?.setUserId(null);
  amplitude?.regenerateDeviceId();

  identifyAnonymous();
};
