import { ReactNode, useCallback, useState } from 'react';
import styled from 'styled-components';
import { copyTextToClipboard } from 'src/modules/Clipboard';
import {
  Button,
  ButtonProps,
  Flex,
  IconSize,
  useToaster,
  DisplayTypes,
  Spacer,
} from 'src/components';
import { borderRadii, colors, paddings } from 'src/styles';

type CopyableProps = ButtonProps & {
  copyContent: string;
  children?: ReactNode;
  kind: 'scrollable' | 'buttonOnly' | 'link';
  copyIconSize?: IconSize;
  copyText?: string;
  copyDisplayType?: DisplayTypes;
  onSuccess?: () => void;
};

const COPYABLE_TIMEOUT = 3000;

const LinkBox = styled.div`
  background: ${colors.white.hex};
  border: 1px solid ${colors.gray2.hex};
  border-right: none;
  border-radius: ${borderRadii[2]};
  padding: ${paddings[3]};
  display: flex;
  align-items: center;
  height: 100%;
  overflow: hidden;
  width: 100%;

  > * {
    white-space: nowrap !important;
  }
`;

const CopyStyles = styled.div<{ displayType: DisplayTypes }>`
  display: ${({ displayType }) => displayType};
`;

export const Copyable = ({
  copyContent,
  children,
  copyIconSize = '1.5em',
  copyDisplayType = 'block',
  copyText = 'Copy Link',
  kind,
  onSuccess,
  ...buttonProps
}: CopyableProps) => {
  const { createToast, closeToast } = useToaster();
  const [disabled, setDisabled] = useState(false);
  const [copied, setCopied] = useState(false);

  const cb = useCallback(async () => {
    setDisabled(true);

    const copyingId = createToast({
      children: 'Copying...',
      color: 'success',
      duration: COPYABLE_TIMEOUT,
      closeAnywhere: true,
    });

    const success = await copyTextToClipboard(copyContent);

    closeToast(copyingId);

    if (success) {
      onSuccess?.();
      createToast({
        children: 'Text copied!',
        color: 'success',
        duration: COPYABLE_TIMEOUT,
      });
      setCopied(true);
      setTimeout(() => {
        setDisabled(false);
        setCopied(false);
      }, COPYABLE_TIMEOUT);
    } else {
      createToast({
        children: 'Text failed to copy.',
        color: 'danger',
        duration: COPYABLE_TIMEOUT,
      });
      setDisabled(false);
    }
  }, [copyContent, createToast, closeToast, onSuccess]);

  return (
    <CopyStyles displayType={copyDisplayType} onClick={(e) => e.stopPropagation()}>
      {['scrollable', 'buttonOnly'].includes(kind) && (
        <Flex align="center">
          {kind === 'scrollable' && (
            <>
              <LinkBox>{children}</LinkBox>
              <Spacer size={2} horizontal />
            </>
          )}
          <Button
            displayType={copied ? 'outline' : 'button'}
            height="40px"
            icon="copy"
            width={buttonProps.width || '150px'}
            onClick={cb}
            disabled={copied}
            testTag="copyable-button"
          >
            {copied ? 'Copied!' : copyText}
          </Button>
        </Flex>
      )}
      {kind === 'link' && (
        <Button
          testTag="copyable-button"
          icon={disabled ? 'check' : 'copy'}
          iconSize={copyIconSize}
          onClick={cb}
          disabled={disabled}
          paddingOverride={paddings.none}
          iconShrink={0}
          {...buttonProps}
        >
          {children}
        </Button>
      )}
    </CopyStyles>
  );
};
