import { useEffect, useState } from 'react';
import { useStore } from 'src/Store';
import { Spacer, Text, Flex, LinkButton } from 'src/components';
import { SearchResultsContainer } from 'src/layouts';
import { matchPath, queryMapToString, useLocation } from 'src/modules/Router';
import { ImmutableList } from 'src/modules/Immutable';
import { ReadingSearchResult } from 'src/models';
import { paddings, useBreakpoints, useWindowSize } from 'src/styles';
import { useSearchData, CenteredLoading, ListHeader, isSearching } from './ContentLibraryHelpers';
import { trimEnd } from 'lodash';
import { ReadingTile } from './ReadingTile';

export const SearchView = () => {
  const { searchResults, playlists, playlistResults, loading, continueReadingMap } = useStore(
    ({
      ContentLibraryData: {
        searchResults,
        playlists,
        playlistResults,
        loading,
        continueReadingMap,
      },
    }) => ({
      searchResults,
      playlistResults,
      playlists,
      loading,
      continueReadingMap,
    }),
    [],
  );

  const pathname = useLocation().pathname;
  const isStudent = Boolean(matchPath(pathname, '/s/library'));
  const isMobile = useBreakpoints({ smallerThan: 'tabletSmall' });
  const [resultsPerRow, setResultsPerRow] = useState(10);
  const windowSize = useWindowSize();
  const searchData = useSearchData();
  const searching = isSearching(searchData);

  useEffect(() => {
    if (loading) return;

    const searchResultContainer = document.getElementById('readings-search-results');
    if (!searchResultContainer) return;

    const firstResult = document.getElementById(`search-result-0`);
    if (!firstResult) return;

    const computedStyle = getComputedStyle(searchResultContainer);
    const paddingLeft = computedStyle.paddingLeft || '0px';
    const paddingRight = computedStyle.paddingLeft || '0px';
    const paddingNumberLeft = parseInt(trimEnd(paddingLeft, 'px'), 10);
    const paddingNumberRight = parseInt(trimEnd(paddingRight, 'px'), 10);
    const totalPadding = paddingNumberLeft + paddingNumberRight;

    const extraPaddingToAvoidWrapping = isStudent ? 8 : 0;

    const containerWidth = searchResultContainer.getBoundingClientRect().width - totalPadding;
    const resultWidth = firstResult.getBoundingClientRect().width + extraPaddingToAvoidWrapping;
    const perRow = Math.floor(containerWidth / resultWidth);
    setResultsPerRow(perRow);
  }, [loading, windowSize, isStudent]);

  if (loading) return <CenteredLoading />;

  return (
    <>
      {!searching && (
        <div data-test-tag="all-playlists">
          {playlists
            .sortBy((p) => p.ranking)
            .map((playlist) => {
              const results =
                playlist.id === 'continue-reading'
                  ? continueReadingMap.toList()
                  : playlistResults.get(playlist.id, ImmutableList<ReadingSearchResult>());

              if (results.isEmpty()) return null;
              return (
                <div key={playlist.id} data-test-tag={`playlist-container-${playlist.id}`}>
                  <Flex align="center" padding={`${paddings[2]} 0 0 0`}>
                    <ListHeader>{playlist.name}</ListHeader>
                    {resultsPerRow < results.size && (
                      <LinkButton
                        icon="forwardArrow"
                        iconPosition="right"
                        iconSize="1.3em"
                        to={`/${isStudent ? 's' : 't'}/library${queryMapToString({
                          playlistId: playlist.id,
                        })}`}
                      >
                        View More
                      </LinkButton>
                    )}
                  </Flex>
                  <SearchResultsContainer testTag={`playlist-results-${playlist.id}`}>
                    {results
                      .sortBy((res) => res.reading.name.toLocaleLowerCase())
                      .take(resultsPerRow)
                      .map((res, idx) => (
                        <ReadingTile
                          id={`playlist-result-${idx}`}
                          key={res.reading.id}
                          result={res}
                          testTag={`playlist-result-${idx}`}
                          existingAssessmentId={res.assessmentId}
                        />
                      ))}
                  </SearchResultsContainer>
                  <Spacer size={4} />
                </div>
              );
            })}
        </div>
      )}
      <div>
        {searching || playlists.isEmpty() ? null : <ListHeader>More Content</ListHeader>}
        {searchResults.count() > 0 ? (
          <SearchResultsContainer id="readings-search-results" testTag="readings-search-results">
            {searchResults.map((res, idx) => (
              <ReadingTile
                id={`search-result-${idx}`}
                key={res.reading.id}
                result={res}
                testTag={`search-result-${idx}`}
              />
            ))}
          </SearchResultsContainer>
        ) : (
          <Flex align={isMobile ? 'start' : 'center'} justify="center" width="100%">
            <Text variant="h4">No readings match your search criteria</Text>
          </Flex>
        )}
      </div>
    </>
  );
};
