import {
  ComprehensionHeader,
  FlexGrow,
  Reader,
  Spacer,
  StandardModal,
  VocabTaskOverview,
} from 'src/components';
import {
  useReadingTaskSubmissionDetails,
  useCurrentTaskSubmissionDetails,
  useVocabTaskDetailsList,
  useIsInstructionsPage,
} from './ReadingStudioHelpers';
import { useStore } from 'src/Store';
import { ReadingStudioInstructions } from './ReadingStudioInstructions';
import { useCompactLayout } from 'src/layouts/ReadingStudioLayout/ReadingStudioLayoutHelpers';
import { getInstructions } from 'src/models';
import { ReadingTaskStudio } from './ReadingTaskStudio';
import styled from 'styled-components';
import { ComprehensionContent } from './ComprehensionContent';
import { paddings } from 'src/styles';

export const MobileReader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  width: 100%;
`;

const MobileComprehension = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: ${paddings[4]};
  position: relative;
`;

export const MainContent = () => {
  const { assessment, currentUser, openContentModal, contentModalOpened, closeContentModal } =
    useStore(
      (state) => ({
        assessment: state.ReadingStudioData.assessment,
        openContentModal: state.ReadingStudioData.openContentModal,
        contentModalOpened: state.ReadingStudioData.contentModalOpened,
        closeContentModal: state.ReadingStudioData.closeContentModal,
        currentUser: state.AppData.currentUser,
      }),
      [],
    );
  const readingTaskSubmissionDetails = useReadingTaskSubmissionDetails();
  const currentTaskSubmissionDetails = useCurrentTaskSubmissionDetails();
  const vocabTaskDetailsList = useVocabTaskDetailsList();
  const isInstructionsPage = useIsInstructionsPage();
  const compactLayout = useCompactLayout();

  const taskType = currentTaskSubmissionDetails ? currentTaskSubmissionDetails.type : '';
  const drawerOpen = taskType === 'ComprehensionTask';

  if (isInstructionsPage) {
    return <ReadingStudioInstructions />;
  }

  if (currentTaskSubmissionDetails?.type === 'VocabTask') {
    return (
      <VocabTaskOverview taskDetails={currentTaskSubmissionDetails} compactLayout={compactLayout} />
    );
  }

  if (!readingTaskSubmissionDetails) {
    return (
      <Reader
        instructions={getInstructions(assessment)}
        readingTaskDetails={null}
        enableTextRangeSelection={false}
        vocabTaskDetailsList={vocabTaskDetailsList}
        compactLayout={compactLayout}
      />
    );
  }

  const reader = (
    <Reader
      instructions={getInstructions(assessment)}
      readingTaskDetails={readingTaskSubmissionDetails}
      drawerOpen={drawerOpen}
      enableTextRangeSelection={false}
      vocabTaskDetailsList={vocabTaskDetailsList}
      compactLayout={compactLayout}
    />
  );

  if (compactLayout) {
    if (currentTaskSubmissionDetails?.type === 'ReadingTask') {
      return (
        <MobileReader>
          {reader}
          <ReadingTaskStudio />
        </MobileReader>
      );
    } else if (currentTaskSubmissionDetails?.type === 'ComprehensionTask') {
      return (
        <MobileComprehension>
          <ComprehensionHeader
            currentTaskSubmissionDetails={currentTaskSubmissionDetails}
            displayType="mainContent"
            textSize={currentUser?.textSize}
          />
          <Spacer size={2} />
          <FlexGrow />
          <ComprehensionContent
            currentTaskSubmissionDetails={currentTaskSubmissionDetails}
            displayType="mainContent"
            textSize={currentUser?.textSize}
            openContentModal={
              readingTaskSubmissionDetails.task.readingPartType !== 'independent'
                ? openContentModal
                : undefined
            }
          />
          <StandardModal
            openType="state"
            opened={contentModalOpened}
            header="Content"
            close={closeContentModal}
          >
            {reader}
          </StandardModal>
        </MobileComprehension>
      );
    }
  }

  return reader;
};
