import styled from 'styled-components';
import {
  fontWeights,
  borderRadii,
  Color,
  colors,
  margins,
  paddings,
  rgba,
  RequiredDollarPrefix,
  cssIfTrue,
  cssIfTruthy,
  standardMeasurements,
} from 'src/styles';
import { ReactNode } from 'react';
import { Align, Button, Spacer } from 'src/components';

type PropsToPrefix = {
  color: Color;
  paddingOverride?: string | false;
  removeable?: boolean;
  align?: Align;
};

type PrefixedProps = RequiredDollarPrefix<PropsToPrefix>;

export type TagProps = PropsToPrefix & {
  children: ReactNode;
  testTag?: string;
  title?: string;
  disabled?: boolean;
  onRemove?: () => void;
};

export const StyledTag = styled.div<PrefixedProps>`
  display: flex;
  min-width: ${standardMeasurements[8]};
  ${({ $align }) => cssIfTruthy('align-items', $align, 'baseline')}

  ${({ $paddingOverride }) =>
    cssIfTruthy('padding', $paddingOverride, `${paddings[1]} ${paddings[2]}`)};
  border-radius: ${borderRadii.rounded};
  color: ${(props) => colors[props.$color].hex};
  font-weight: ${fontWeights.semibold};
  ${({ $color, $removeable }) =>
    cssIfTrue('background', rgba(colors[$color].hex, 0.2), !$removeable)};
  margin-right: ${margins[1]};
  ${({ $removeable }) => cssIfTrue('border', '1px solid white', $removeable)}
`;

export const Tag = ({
  color,
  children,
  removeable = false,
  disabled = false,
  testTag,
  title,
  onRemove,
  paddingOverride = false,
  align = 'baseline',
}: TagProps) => (
  <StyledTag
    $color={color}
    $removeable={removeable}
    data-test-tag={testTag}
    title={title}
    $paddingOverride={paddingOverride}
    $align={align}
  >
    {children}
    {removeable && !disabled && (
      <>
        <Spacer horizontal />
        <Button
          displayType="iconOnly"
          icon="close"
          iconSize=".5em"
          iconColor="white"
          color="gray5"
          paddingOverride={paddings.half}
          inheritBgColor
          onClick={onRemove}
          testTag="remove-tag"
        />
      </>
    )}
  </StyledTag>
);
