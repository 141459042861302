import { ImmutableList } from 'src/modules/Immutable';

export type EpubChapter = {
  label: string;
  src: string;
};

export type EpubStoredFile = {
  id: string;
  type: 'EpubStoredFile';
  url: string;
  tableOfContents: ImmutableList<EpubChapter>;
};
