import * as React from 'react';
import styled, { css } from 'styled-components';
import { Link, NavLink } from 'react-router-dom';
import {
  paddings,
  borderRadii,
  colors,
  Color,
  RequiredDollarPrefix,
  fontWeights,
  FontWeight,
  CssColor,
  BorderRadius,
  rgba,
  Height,
  Width,
  zIndices,
  cssIfTruthyOrZero,
  ZIndex,
} from 'src/styles';
import {
  Flex,
  IconType,
  IconSize,
  Loading,
  Icon,
  Spacer,
  Justify,
  Align,
  FlexDisplay,
} from 'src/components';

export type DisplayType = 'button' | 'link' | 'noStyles' | 'iconOnly' | 'outline';
export type ButtonType = 'submit' | 'button';
export type ButtonEvent =
  | Parameters<React.MouseEventHandler<HTMLButtonElement>>[0]
  | Parameters<React.MouseEventHandler<HTMLAnchorElement>>[0];

type CommonProps = {
  id?: string;
  testTag?: string;
  type?: ButtonType;
  disabled?: boolean;
  to?: string;
  name?: string;
  href?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement> | React.MouseEventHandler<HTMLAnchorElement>;
  children?: React.ReactNode;
  download?: string | boolean;
  flexDisplay?: DisplayTypes;
  loadingText?: string;
  onMouseOver?:
    | React.MouseEventHandler<HTMLButtonElement>
    | React.MouseEventHandler<HTMLAnchorElement>;
  onMouseOut?:
    | React.MouseEventHandler<HTMLButtonElement>
    | React.MouseEventHandler<HTMLAnchorElement>;
};

export type DisplayTypes = 'inherit' | 'flex' | 'inline-flex' | 'block' | 'inline-block' | 'inline';

type PropsToPrefix = {
  displayType?: DisplayType;
  loading?: boolean;
  display?: DisplayTypes;
  justify?: Justify;
  align?: Align;
  activeBg?: Color | 'inherit';
  linkTextStyle?: 'linkOptional' | 'linkBasic' | 'linkNavBar';
  sizeOverride?: number | false;
  weightOverride?: FontWeight | false;
  width?: Width;
  height?: Height;
  minWidth?: Width;
  borderRadiusOverride?: BorderRadius | false;
  paddingOverride?: string | false;
  disabledOpacityOverride?: string | false;
  flexGrow?: number | 'inherit' | 'initial' | 'revert' | 'unset';
  zIndex?: ZIndex;
};

type PrefixedProps = RequiredDollarPrefix<PropsToPrefix>;

export type ButtonProps = CommonProps &
  PropsToPrefix & {
    color?: Color;
    nav?: boolean;
    exact?: boolean;
    newTab?: boolean;
    icon?: IconType;
    iconSize?: IconSize;
    iconShrink?: number;
    iconColor?: Color;
    iconPosition?: 'left' | 'right';
    iconFill?: boolean;
    iconStrokeWidth?: number;
    borderColorOverride?: Color;
    hoverColor?: Color;
    activeColor?: Color;
    inheritBgColor?: boolean;
  };

type ButtonInternalProps = CommonProps &
  PrefixedProps & {
    activeClassName?: string;
    target?: string;
    rel?: string;
    $backgroundColor: CssColor;
    $textColor: CssColor;
    $borderColor?: CssColor;
    $activeBorderColor?: CssColor;
    $activeBackgroundColor: CssColor;
    $hoverColor?: Color;
    $activeColor?: Color;
    $inheritBgColor: boolean;
    paddingOverride?: string;
    //$disabledOpacityOverride?: string;
  };

const ACTIVE_CLASS_NAME = 'button-nav-link-active';

const commonStyles = css<ButtonInternalProps>`
  display: ${(props) => props.$display};
  align-items: center;
  border: none;
  outline: none;
  text-decoration: none;
  cursor: pointer;
  position: relative;
  color: ${(props) => props.$textColor};
  background: ${(props) => (props.$inheritBgColor ? 'inherit' : props.$backgroundColor)};
  flex-grow: ${(props) => props.$flexGrow};
  flex-shrink: 0;
  z-index: ${(props) => zIndices[props.$zIndex]};

  &[disabled] {
    cursor: default;
    pointer-events: none;
    opacity: 0.4;
  }

  &:active,
  &:hover {
    &:not([disabled]) {
      background: ${(props) => props.$activeBackgroundColor};
    }
  }
`;

const overrideStyles = css<ButtonInternalProps>`
  ${({ $sizeOverride }) => $sizeOverride && `font-size: ${$sizeOverride}px;`}
  ${({ $weightOverride }) => $weightOverride && `font-weight: ${fontWeights[$weightOverride]};`}
  ${({ $borderRadiusOverride }) =>
    $borderRadiusOverride && `border-radius: ${borderRadii[$borderRadiusOverride]};`}
  ${({ $paddingOverride }) => cssIfTruthyOrZero('padding', $paddingOverride)}

  &[disabled] {
    ${({ $disabledOpacityOverride }) => cssIfTruthyOrZero('opacity', $disabledOpacityOverride)}
  }
`;

const linkStyles = css<ButtonInternalProps>`
  ${commonStyles}
  background: transparent;
  letter-spacing: '0';
  font-weight: ${fontWeights.semibold};
  line-height: 100%;
  padding: 0;
  ${(props) => (props.$width ? `width: ${props.$width};` : '')};

  ${({ $linkTextStyle }) => {
    switch ($linkTextStyle) {
      case 'linkOptional':
        return `font-size: 12px`;
      case 'linkNavBar':
        return `font-size: 16px`;
      case 'linkBasic':
      default:
        return `font-size: 14px`;
    }
  }};

  &:active,
  &.${ACTIVE_CLASS_NAME} {
    /* stylelint-enable */
    color: ${(props) => (props.$activeColor ? colors[props.$activeColor].hex : props.$textColor)};
  }

  &:hover {
    &:not([disabled]) {
      color: ${(props) => (props.$hoverColor ? colors[props.$hoverColor].hex : props.$textColor)};
      text-decoration: underline;
    }
  }
  ${overrideStyles}
`;

const buttonStyles = css<ButtonInternalProps>`
  ${commonStyles}
  border-radius: ${borderRadii.rounded};
  font-weight: 600;
  font-size: 14px;
  line-height: 145%;
  padding: ${paddings[2]} ${paddings[5]};
  text-decoration: none;
  cursor: pointer;
  height: ${(props) => props.$height};
  width: ${(props) => props.$width};
  min-width: ${(props) => props.$minWidth};
  ${overrideStyles};
`;

const outlineStyles = css<ButtonInternalProps>`
  ${commonStyles}
  ${buttonStyles}

  border: 1px solid ${(props) => props.$borderColor};

  &:active,
  &:hover {
    &:not([disabled]) {
      border: 1px solid ${(props) => props.$activeBorderColor};
    }
  }

  ${overrideStyles}
`;

const iconOnlyStyles = css<ButtonInternalProps>`
  ${commonStyles}

  border-radius: ${borderRadii.rounded};
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${(props) => props.$height};
  width: ${(props) => props.$width};

  ${overrideStyles}
`;

const unsetStyles = css<ButtonInternalProps>`
  all: unset;
  color: inherit;
  cursor: pointer;
  height: ${(props) => props.$height};
  width: ${(props) => props.$width};
  display: ${(props) => props.$display};
  flex-grow: ${(props) => props.$flexGrow};

  &[disabled] {
    cursor: default;
    opacity: 0.4;
  }
  ${overrideStyles}
`;

export const StyledButton = styled.button<ButtonInternalProps>`
  ${({ $displayType }) => {
    if ($displayType === 'link') {
      return linkStyles;
    } else if ($displayType === 'button') {
      return buttonStyles;
    } else if ($displayType === 'outline') {
      return outlineStyles;
    } else if ($displayType === 'iconOnly') {
      return iconOnlyStyles;
    } else if ($displayType === 'noStyles') {
      return unsetStyles;
    }
    return null;
  }}
`;

const StyledAnchor = StyledButton.withComponent('a');
const StyledLink = StyledButton.withComponent(Link);
const StyledNavLink = StyledButton.withComponent(NavLink);

type CommonButton = (props: ButtonInternalProps) => React.ReactElement | null;

const LoadingContainer = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: ${zIndices.raised};
`;

const getButtonElement = ({
  to,
  nav,
  href,
  buttonDisabled,
}: {
  to: string | void;
  nav: boolean | void;
  href: string | void;
  buttonDisabled: boolean;
}): CommonButton => {
  // Always use button if it's disabled, as the react-router Link just ignores the disabled attribute
  if (buttonDisabled) return StyledButton;
  if (typeof to === 'string') {
    if (nav) {
      return StyledNavLink;
    } else {
      return StyledLink;
    }
  } else if (typeof href === 'string') {
    return StyledAnchor;
  } else {
    return StyledButton;
  }
};

const getColors = ({
  baseColor,
  borderColorOverride,
  displayType,
}: {
  baseColor: Color | undefined;
  borderColorOverride: Color | undefined;
  displayType: DisplayType;
}): {
  backgroundColor: CssColor;
  textColor: CssColor;
  activeBackgroundColor: CssColor;
  borderColor: CssColor;
  activeBorderColor: CssColor;
} => {
  const color = baseColor ?? 'primaryBlue';
  const borderColor = borderColorOverride ? borderColorOverride : color;
  if (displayType === 'link') {
    return {
      backgroundColor: 'transparent',
      textColor: colors[color].hex,
      activeBackgroundColor: 'transparent',
      borderColor: 'transparent',
      activeBorderColor: 'transparent',
    };
  } else if (displayType === 'outline') {
    return {
      backgroundColor: 'transparent',
      textColor: colors[color].hex,
      activeBackgroundColor: rgba(colors[color].hex, 0.08),
      borderColor: rgba(colors[borderColor].hex, 0.5),
      activeBorderColor: colors[borderColor].hex,
    };
  } else {
    return {
      backgroundColor: colors[color].hex,
      textColor: colors[color].text,
      activeBackgroundColor: colors[color].active,
      borderColor: 'transparent',
      activeBorderColor: 'transparent',
    };
  }
};

export const Button = ({
  nav,
  exact = false,
  type = 'button',
  displayType = 'button',
  children,
  name,
  onClick,
  onMouseOver,
  onMouseOut,
  to,
  href,
  testTag,
  color: baseColor,
  borderColorOverride,
  hoverColor,
  activeColor,
  inheritBgColor = false,
  display = 'inline-flex',
  flexDisplay = display,
  justify = 'center',
  align = 'center',
  loading = false,
  loadingText,
  disabled = false,
  newTab = false,
  width = 'auto',
  height = 'auto',
  minWidth = 'auto',
  download,
  id,
  activeBg = 'inherit',
  icon,
  iconSize,
  iconColor,
  iconPosition = 'left',
  iconFill = false,
  iconStrokeWidth = 1,
  iconShrink = 1,
  linkTextStyle = 'linkBasic',
  sizeOverride = false,
  weightOverride = false,
  borderRadiusOverride = false,
  paddingOverride = false,
  flexGrow = 'unset',
  zIndex = 'normal',
  disabledOpacityOverride = false,
}: ButtonProps) => {
  const { backgroundColor, textColor, activeBackgroundColor, borderColor, activeBorderColor } =
    getColors({
      baseColor,
      borderColorOverride,
      displayType,
    });
  const buttonDisabled = disabled || loading;

  const basicProps = {
    id,
    type,
    to,
    href,
    download,
    name,
    onClick,
    onMouseOver,
    onMouseOut,
    disabled: buttonDisabled,
  };

  const iconColorProps = iconColor ? { color: iconColor } : { cssColor: textColor };

  const IconElem = icon ? (
    <>
      {iconPosition === 'right' && displayType !== 'iconOnly' && <Spacer horizontal />}
      <Icon
        {...iconColorProps}
        fill={iconFill}
        strokeWidth={iconStrokeWidth}
        icon={icon}
        size={iconSize || '1.75em'}
        flexShrink={iconShrink}
      />
      {iconPosition === 'left' && displayType !== 'iconOnly' && <Spacer horizontal />}
    </>
  ) : null;

  const ButtonElem: CommonButton = getButtonElement({ buttonDisabled, to, nav, href });
  const navProps = nav ? { activeClassName: ACTIVE_CLASS_NAME, exact } : {};
  const tabProps = newTab ? { target: '_blank', rel: 'noopener noreferrer' } : {};
  return (
    <ButtonElem
      $displayType={displayType}
      $loading={loading}
      $backgroundColor={backgroundColor}
      $textColor={textColor}
      $activeBackgroundColor={activeBackgroundColor}
      $borderColor={borderColor}
      $activeBorderColor={activeBorderColor}
      $display={display}
      $justify={justify}
      $align={align}
      $hoverColor={hoverColor}
      $activeColor={activeColor}
      $inheritBgColor={inheritBgColor}
      $activeBg={activeBg}
      $linkTextStyle={linkTextStyle}
      $sizeOverride={sizeOverride}
      $weightOverride={weightOverride}
      $width={width}
      $height={height}
      $minWidth={minWidth}
      $borderRadiusOverride={borderRadiusOverride}
      $paddingOverride={paddingOverride}
      $disabledOpacityOverride={disabledOpacityOverride}
      $flexGrow={flexGrow}
      $zIndex={zIndex}
      data-test-tag={testTag}
      {...basicProps}
      {...navProps}
      {...tabProps}
    >
      <Flex
        display={
          ['flex', 'inline-flex', 'inherit'].includes(flexDisplay)
            ? (flexDisplay as FlexDisplay)
            : 'flex'
        }
        align={align}
        justify={justify}
        width="100%"
        opacity={loading && displayType !== 'link' ? 0 : 1}
      >
        {iconPosition === 'left' && IconElem}
        {children}
        {loading && displayType === 'link' && (
          <>
            <Spacer horizontal size={2} />
            <Loading size="small" />
          </>
        )}
        {iconPosition === 'right' && IconElem}
      </Flex>
      {loading && displayType !== 'link' && (
        <LoadingContainer>
          {loadingText && (
            <>
              {loadingText}
              <Spacer horizontal size={2} />
            </>
          )}
          <Loading darkBg={displayType !== 'outline' && displayType !== 'noStyles'} size="medium" />
        </LoadingContainer>
      )}
    </ButtonElem>
  );
};

export const LinkButton = (props: ButtonProps) => {
  return <Button displayType="link" {...props} />;
};

export const NoStylesButton = (props: ButtonProps) => {
  return <Button displayType="noStyles" {...props} />;
};

export const OutlineButton = (props: ButtonProps) => {
  return <Button displayType="outline" {...props} />;
};
