export const zIndices = {
  normal: 0,
  raised: 1,
  modal: 2,
  toast: 3,
  dropdown: 4,
  tooltip: 5,
} as const;

export type ZIndex = keyof typeof zIndices;
