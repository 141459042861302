import { Modal, Text, Flex, Button, Spacer, LogoButton, useToaster } from 'src/components';
import { margins, paddings, useBreakpoints } from 'src/styles';
import { useLocation, matchPath } from 'src/modules/Router';
import { useStore } from 'src/Store';

const BLOCK_LIST = [
  '/t/assignments/:id',
  '/t/assignments/task/:type/:id',
  '/t/classes',
  '/t/students',
  '/t/progress-monitoring',
  '/t/reading-builder',
  '/t/assignment-builder',
  '/t/public-preview',
];

export const MobileGate = () => {
  const { submit, sending, currentUser } = useStore(
    (s) => ({ ...s.MobileGateData, currentUser: s.AppData.currentUser }),
    [],
  );
  const { createToast } = useToaster();
  const showMobileLayout = useBreakpoints({ smallerThanOrEqualTo: 'tabletSmall' });
  const pathname = useLocation().pathname;
  const onBlockedPath = BLOCK_LIST.some((p) => Boolean(matchPath(pathname, p)));

  return !showMobileLayout || !onBlockedPath ? null : (
    <Modal forceFullscreen openType="state" opened paddingOverride={paddings.none}>
      <Flex direction="column" padding={paddings[8]} height="100%" justify="space-between">
        <Flex justify="center" margin={margins[4]}>
          <LogoButton
            logoType={currentUser?.paperSyncedAt ? 'paper-reading' : 'full-name'}
            size="medium"
          />
        </Flex>
        <Flex direction="column" justify="center" height="100%">
          <Text variant="h3" textAlign="center">
            You need a bigger screen to access all the features on this page!
          </Text>
          <Spacer size={8} />

          {currentUser?.email && (
            <>
              <Text variant="h4">
                1. Click the button below to email yourself a link to this page
              </Text>
              <Spacer size={2} />
              <Text variant="h4">2. Open up the link on a laptop or desktop computer</Text>
              <Spacer size={2} />
              <Text variant="h4">3. Pick up where you left off</Text>
              <Spacer size={2} />
            </>
          )}
        </Flex>
        <Flex direction="column" align="center">
          {currentUser?.email && (
            <Button onClick={submit(createToast)} loading={sending}>
              Send Email
            </Button>
          )}
          <Spacer size={3} />
          <Button displayType="outline" to="/">
            Go Home
          </Button>
          <Spacer size={6} />
        </Flex>
      </Flex>
    </Modal>
  );
};
