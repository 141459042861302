import { Button, Flex, Spacer, Text } from 'src/components';
import { District, School } from 'src/models';
import { borderRadii, colors, margins, paddings } from 'src/styles';
import styled from 'styled-components';

const SchoolChipStyles = styled.div`
  background: ${colors.white.hex};
  padding: ${paddings[4]};
  border-radius: ${borderRadii[2]};
  width: 100%;
  flex: 1;
  margin-bottom: ${margins[4]};
  border: 1px solid ${colors.gray2.hex};
`;
export const SchoolChip = ({
  school,
  district,
  choose,
  suggested = false,
}: {
  school: School;
  district: District | null;
  choose?: () => void;
  suggested?: boolean;
}) => {
  return (
    <Button
      displayType="noStyles"
      onClick={choose}
      width="100%"
      disabled={!choose}
      disabledOpacityOverride="100%"
    >
      <SchoolChipStyles>
        <Text variant="h4">{school.name}</Text>
        <Spacer size={2} />
        {district && <Text variant="h5">{district.name}</Text>}
        {school.isPrivate && <Text variant="h5">Private School</Text>}
        <Spacer size={2} />
        <Text>{school.street ? `${school.street}, ` : ''}</Text>
        <Text>
          {school.city ? `${school.city}, ` : ''}
          {school.stateAbbreviation || school.state
            ? `${school.stateAbbreviation || school.state}, `
            : ''}
          {school.country} {school.zipCode}
        </Text>
        {suggested ? (
          <>
            <Spacer size={4} />
            <Flex justify="end">
              <Text color="primaryBlue">Suggested based on your email address</Text>
            </Flex>
          </>
        ) : null}
      </SchoolChipStyles>
    </Button>
  );
};
