import { useCallback, useEffect } from 'react';
import { useStore } from 'src/Store';
import { ContentLayout } from 'src/layouts';
import { useSearchData, useSetQuery, useCurrentPlaylist } from './ContentLibraryHelpers';
import { SelectionModal } from './SelectionModal';
import { Filters } from './Filters';
import { PlaylistDetails } from './PlaylistDetails';
import { SearchView } from './SearchDetails';
import { Loading } from 'src/components';

const ContentLibraryDetails = () => {
  const { loading, totalResults, searchResults, search } = useStore(
    ({ ContentLibraryData: s }) => ({
      search: s.search,
      loading: s.loading,
      totalResults: s.totalResults,
      searchResults: s.searchResults,
    }),
    [],
  );

  const searchData = useSearchData();
  useEffect(() => {
    search(searchData);
  }, [search, searchData]);

  const setQuery = useSetQuery();
  const onSearch = useCallback((text: string) => setQuery({ searchText: text }), [setQuery]);
  const playlist = useCurrentPlaylist();

  return (
    <>
      {playlist ? (
        <PlaylistDetails />
      ) : (
        <ContentLayout
          filters={<Filters />}
          searchValue={searchData.searchText}
          searchAction={onSearch}
          searchPlaceholder="Search Content"
          resultCount={searchResults.count()}
          resultTotal={totalResults}
          loading={loading}
        >
          <SearchView />
        </ContentLayout>
      )}
      <SelectionModal />
    </>
  );
};

export const ContentLibrary = () => {
  const { load, gradeLevelsLoading } = useStore(
    ({ ContentLibraryData: s }) => ({
      load: s.load,
      gradeLevelsLoading: s.gradeLevelsLoading,
    }),
    [],
  );

  useEffect(() => {
    load();
  }, [load]);

  return gradeLevelsLoading ? <Loading kind="boat" flex /> : <ContentLibraryDetails />;
};
