import SkinChocolate from 'src/assets/avatar/skins/chocolate.png';
import SkinCaramel from 'src/assets/avatar/skins/caramel.png';
import SkinBlue from 'src/assets/avatar/skins/blue.png';
import SkinPurple from 'src/assets/avatar/skins/purple.png';
import SkinRed from 'src/assets/avatar/skins/red.png';
import SkinPink from 'src/assets/avatar/skins/pink.png';
import SkinZiggy from 'src/assets/avatar/skins/ziggy.png';
import SkinGreenWarrior from 'src/assets/avatar/skins/green_warrior.png';
import SkinOddYellow from 'src/assets/avatar/skins/odd_yellow.png';
import SkinTieDye from 'src/assets/avatar/skins/tie_dye.png';
import SkinAirBender from 'src/assets/avatar/skins/air_bender.png';
import SkinFreckles from 'src/assets/avatar/skins/freckles.png';

import EyesRegular from 'src/assets/avatar/eyes/regular.png';
import EyesWide from 'src/assets/avatar/eyes/wide.png';
import EyesAlmond from 'src/assets/avatar/eyes/almond.png';
import EyesCyclops from 'src/assets/avatar/eyes/cyclops.png';
import EyesBig from 'src/assets/avatar/eyes/big.png';
import EyesSunglasses from 'src/assets/avatar/eyes/sunglasses.png';
import EyesFlaming from 'src/assets/avatar/eyes/flaming.png';
import EyesHotdog from 'src/assets/avatar/eyes/hotdog.png';
import EyesRaphaelWanabee from 'src/assets/avatar/eyes/raphael_wannabee.png';
import EyesKushiteKing from 'src/assets/avatar/eyes/kushite_king.png';
import EyesSpaceSkiing from 'src/assets/avatar/eyes/space_skiing.png';
import EyesSpeckTackle from 'src/assets/avatar/eyes/speck_tackle.png';
import EyesSharingan from 'src/assets/avatar/eyes/sharingan.png';

import MouthSmile from 'src/assets/avatar/mouths/smile.png';
import MouthScary from 'src/assets/avatar/mouths/scary.png';
import MouthOh from 'src/assets/avatar/mouths/oh.png';
import MouthClosed from 'src/assets/avatar/mouths/closed.png';
import MouthToothySmile from 'src/assets/avatar/mouths/toothy_smile.png';
import MouthBugs from 'src/assets/avatar/mouths/bugs.png';
import MouthPurpleLips from 'src/assets/avatar/mouths/purple_lips.png';
import MouthSquareTooth from 'src/assets/avatar/mouths/square_tooth.png';
import MouthStitch from 'src/assets/avatar/mouths/stitch.png';
import MouthVampire from 'src/assets/avatar/mouths/vampire.png';
import MouthTongue from 'src/assets/avatar/mouths/tongue.png';
import MouthBigSmile from 'src/assets/avatar/mouths/big_smile.png';

import HatBlueHair from 'src/assets/avatar/hats/blue_hair.png';
import HatPizza from 'src/assets/avatar/hats/pizza.png';
import HatOctopus from 'src/assets/avatar/hats/octopus.png';
import HatWinter from 'src/assets/avatar/hats/winter.png';
import HatHeadphones from 'src/assets/avatar/hats/headphones.png';
import HatUnicorn from 'src/assets/avatar/hats/unicorn.png';
import HatAntennaeEyes from 'src/assets/avatar/hats/antennae_eyes.png';
import HatAstronaut from 'src/assets/avatar/hats/astronaut.png';
import HatBanana from 'src/assets/avatar/hats/banana.png';
import HatHipPlant from 'src/assets/avatar/hats/hip_plant.png';
import HatChiaPet from 'src/assets/avatar/hats/chia_pet.png';
import HatFrog from 'src/assets/avatar/hats/frog.png';

import CroppedMouthSmile from 'src/assets/avatar/mouths/cropped/smile.png';
import CroppedMouthScary from 'src/assets/avatar/mouths/cropped/scary.png';
import CroppedMouthOh from 'src/assets/avatar/mouths/cropped/oh.png';
import CroppedMouthClosed from 'src/assets/avatar/mouths/cropped/closed.png';
import CroppedMouthToothySmile from 'src/assets/avatar/mouths/cropped/toothy_smile.png';
import CroppedMouthBugs from 'src/assets/avatar/mouths/cropped/bugs.png';
import CroppedMouthPurpleLips from 'src/assets/avatar/mouths/cropped/purple_lips.png';
import CroppedMouthSquareTooth from 'src/assets/avatar/mouths/cropped/square_tooth.png';
import CroppedMouthStitch from 'src/assets/avatar/mouths/cropped/stitch.png';
import CroppedMouthVampire from 'src/assets/avatar/mouths/cropped/vampire.png';
import CroppedMouthTongue from 'src/assets/avatar/mouths/cropped/tongue.png';
import CroppedMouthBigSmile from 'src/assets/avatar/mouths/cropped/big_smile.png';

import CroppedEyesRegular from 'src/assets/avatar/eyes/cropped/regular.png';
import CroppedEyesWide from 'src/assets/avatar/eyes/cropped/wide.png';
import CroppedEyesAlmond from 'src/assets/avatar/eyes/cropped/almond.png';
import CroppedEyesCyclops from 'src/assets/avatar/eyes/cropped/cyclops.png';
import CroppedEyesBig from 'src/assets/avatar/eyes/cropped/big.png';
import CroppedEyesSunglasses from 'src/assets/avatar/eyes/cropped/sunglasses.png';
import CroppedEyesFlaming from 'src/assets/avatar/eyes/cropped/flaming.png';
import CroppedEyesHotdog from 'src/assets/avatar/eyes/cropped/hotdog.png';
import CroppedEyesRaphaelWanabee from 'src/assets/avatar/eyes/cropped/raphael_wannabee.png';
import CroppedEyesKushiteKing from 'src/assets/avatar/eyes/cropped/kushite_king.png';
import CroppedEyesSpaceSkiing from 'src/assets/avatar/eyes/cropped/space_skiing.png';
import CroppedEyesSpeckTackle from 'src/assets/avatar/eyes/cropped/speck_tackle.png';
import CroppedEyesSharingan from 'src/assets/avatar/eyes/cropped/sharingan.png';

import CroppedHatBlueHair from 'src/assets/avatar/hats/cropped/blue_hair.png';
import CroppedHatPizza from 'src/assets/avatar/hats/cropped/pizza.png';
import CroppedHatOctopus from 'src/assets/avatar/hats/cropped/octopus.png';
import CroppedHatWinter from 'src/assets/avatar/hats/cropped/winter.png';
import CroppedHatHeadphones from 'src/assets/avatar/hats/cropped/headphones.png';
import CroppedHatUnicorn from 'src/assets/avatar/hats/cropped/unicorn.png';
import CroppedHatAntennaeEyes from 'src/assets/avatar/hats/cropped/antennae_eyes.png';
import CroppedHatAstronaut from 'src/assets/avatar/hats/cropped/astronaut.png';
import CroppedHatBanana from 'src/assets/avatar/hats/cropped/banana.png';
import CroppedHatHipPlant from 'src/assets/avatar/hats/cropped/hip_plant.png';
import CroppedHatChiaPet from 'src/assets/avatar/hats/cropped/chia_pet.png';
import CroppedHatFrog from 'src/assets/avatar/hats/cropped/frog.png';

import Banned from 'src/assets/avatar/banned.svg';

export type AvatarFeature = 'skin' | 'mouth' | 'eyes' | 'hat';

export type AvatarSkin =
  | 'chocolate'
  | 'caramel'
  | 'blue'
  | 'purple'
  | 'red'
  | 'pink'
  | 'ziggy'
  | 'green_warrior'
  | 'odd_yellow'
  | 'tie_dye'
  | 'air_bender'
  | 'freckles';

export type AvatarEyes =
  | 'regular'
  | 'almond'
  | 'wide'
  | 'cyclops'
  | 'big'
  | 'sunglasses'
  | 'flaming'
  | 'hotdog'
  | 'kushite_king'
  | 'raphael_wannabee'
  | 'space_skiing'
  | 'speck_tackle'
  | 'sharingan';

export type AvatarMouth =
  | 'smile'
  | 'scary'
  | 'oh'
  | 'toothy_smile'
  | 'closed'
  | 'bugs'
  | 'purple_lips'
  | 'square_tooth'
  | 'stitch'
  | 'tongue'
  | 'vampire'
  | 'big_smile';

export type AvatarHat =
  | 'nothing'
  | 'blue_hair'
  | 'pizza'
  | 'unicorn'
  | 'winter'
  | 'octopus'
  | 'headphones'
  | 'antennae_eyes'
  | 'astronaut'
  | 'banana'
  | 'chia_pet'
  | 'hip_plant'
  | 'frog';

export const getSkin = (attribute: AvatarSkin) => {
  switch (attribute) {
    case 'chocolate':
      return SkinChocolate;
    case 'caramel':
      return SkinCaramel;
    case 'blue':
      return SkinBlue;
    case 'red':
      return SkinRed;
    case 'purple':
      return SkinPurple;
    case 'pink':
      return SkinPink;
    case 'ziggy':
      return SkinZiggy;
    case 'odd_yellow':
      return SkinOddYellow;
    case 'green_warrior':
      return SkinGreenWarrior;
    case 'tie_dye':
      return SkinTieDye;
    case 'air_bender':
      return SkinAirBender;
    case 'freckles':
      return SkinFreckles;
    default:
      throw new Error('unknown avatar skin');
  }
};

export const getEyes = (attribute: AvatarEyes) => {
  switch (attribute) {
    case 'regular':
      return EyesRegular;
    case 'almond':
      return EyesAlmond;
    case 'wide':
      return EyesWide;
    case 'cyclops':
      return EyesCyclops;
    case 'big':
      return EyesBig;
    case 'sunglasses':
      return EyesSunglasses;
    case 'flaming':
      return EyesFlaming;
    case 'hotdog':
      return EyesHotdog;
    case 'kushite_king':
      return EyesKushiteKing;
    case 'raphael_wannabee':
      return EyesRaphaelWanabee;
    case 'space_skiing':
      return EyesSpaceSkiing;
    case 'speck_tackle':
      return EyesSpeckTackle;
    case 'sharingan':
      return EyesSharingan;
    default:
      throw new Error('unknown avatar eyes');
  }
};

export const getCroppedEyes = (attribute: AvatarEyes) => {
  switch (attribute) {
    case 'regular':
      return CroppedEyesRegular;
    case 'almond':
      return CroppedEyesAlmond;
    case 'wide':
      return CroppedEyesWide;
    case 'cyclops':
      return CroppedEyesCyclops;
    case 'big':
      return CroppedEyesBig;
    case 'sunglasses':
      return CroppedEyesSunglasses;
    case 'flaming':
      return CroppedEyesFlaming;
    case 'hotdog':
      return CroppedEyesHotdog;
    case 'kushite_king':
      return CroppedEyesKushiteKing;
    case 'raphael_wannabee':
      return CroppedEyesRaphaelWanabee;
    case 'space_skiing':
      return CroppedEyesSpaceSkiing;
    case 'speck_tackle':
      return CroppedEyesSpeckTackle;
    case 'sharingan':
      return CroppedEyesSharingan;
    default:
      throw new Error('unknown avatar eyes');
  }
};

export const getMouth = (attribute: AvatarMouth) => {
  switch (attribute) {
    case 'smile':
      return MouthSmile;
    case 'scary':
      return MouthScary;
    case 'oh':
      return MouthOh;
    case 'toothy_smile':
      return MouthToothySmile;
    case 'closed':
      return MouthClosed;
    case 'bugs':
      return MouthBugs;
    case 'purple_lips':
      return MouthPurpleLips;
    case 'square_tooth':
      return MouthSquareTooth;
    case 'stitch':
      return MouthStitch;
    case 'tongue':
      return MouthTongue;
    case 'vampire':
      return MouthVampire;
    case 'big_smile':
      return MouthBigSmile;
    default:
      throw new Error('unknown avatar mouth');
  }
};

export const getCroppedMouth = (attribute: AvatarMouth) => {
  switch (attribute) {
    case 'smile':
      return CroppedMouthSmile;
    case 'scary':
      return CroppedMouthScary;
    case 'oh':
      return CroppedMouthOh;
    case 'toothy_smile':
      return CroppedMouthToothySmile;
    case 'closed':
      return CroppedMouthClosed;
    case 'bugs':
      return CroppedMouthBugs;
    case 'purple_lips':
      return CroppedMouthPurpleLips;
    case 'square_tooth':
      return CroppedMouthSquareTooth;
    case 'stitch':
      return CroppedMouthStitch;
    case 'tongue':
      return CroppedMouthTongue;
    case 'vampire':
      return CroppedMouthVampire;
    case 'big_smile':
      return CroppedMouthBigSmile;
    default:
      throw new Error('unknown avatar mouth');
  }
};

export const getHat = (attribute: AvatarHat) => {
  switch (attribute) {
    case 'blue_hair':
      return HatBlueHair;
    case 'pizza':
      return HatPizza;
    case 'unicorn':
      return HatUnicorn;
    case 'winter':
      return HatWinter;
    case 'octopus':
      return HatOctopus;
    case 'headphones':
      return HatHeadphones;
    case 'antennae_eyes':
      return HatAntennaeEyes;
    case 'astronaut':
      return HatAstronaut;
    case 'banana':
      return HatBanana;
    case 'chia_pet':
      return HatChiaPet;
    case 'hip_plant':
      return HatHipPlant;
    case 'frog':
      return HatFrog;
    case 'nothing':
      return;
    default:
      throw new Error('unknown avatar hat');
  }
};

export const getCroppedHat = (attribute: AvatarHat) => {
  switch (attribute) {
    case 'blue_hair':
      return CroppedHatBlueHair;
    case 'pizza':
      return CroppedHatPizza;
    case 'unicorn':
      return CroppedHatUnicorn;
    case 'winter':
      return CroppedHatWinter;
    case 'octopus':
      return CroppedHatOctopus;
    case 'headphones':
      return CroppedHatHeadphones;
    case 'antennae_eyes':
      return CroppedHatAntennaeEyes;
    case 'astronaut':
      return CroppedHatAstronaut;
    case 'banana':
      return CroppedHatBanana;
    case 'chia_pet':
      return CroppedHatChiaPet;
    case 'hip_plant':
      return CroppedHatHipPlant;
    case 'frog':
      return CroppedHatFrog;
    case 'nothing':
      return Banned;
    default:
      throw new Error('unknown avatar hat');
  }
};

export type AvatarFieldName = 'avatarSkin' | 'avatarMouth' | 'avatarEyes' | 'avatarHat';

export const getAvatarFieldName = (feature: AvatarFeature): AvatarFieldName => {
  switch (feature) {
    case 'skin':
      return 'avatarSkin';
    case 'mouth':
      return 'avatarMouth';
    case 'eyes':
      return 'avatarEyes';
    case 'hat':
      return 'avatarHat';
  }
};

export const getAttribute = (
  kind: AvatarFeature,
  attribute: AvatarSkin | AvatarMouth | AvatarEyes | AvatarHat,
) => {
  switch (kind) {
    case 'skin':
      return getSkin(attribute as AvatarSkin);
    case 'mouth':
      return getCroppedMouth(attribute as AvatarMouth);
    case 'eyes':
      return getCroppedEyes(attribute as AvatarEyes);
    case 'hat':
      return getCroppedHat(attribute as AvatarHat);
  }
};

type AttributeData = {
  kind: AvatarFeature;
  buttonName: string;
  fieldName: AvatarFieldName;
};

export const avatarAttributeList: AttributeData[] = [
  {
    kind: 'skin',
    buttonName: 'Skin',
    fieldName: 'avatarSkin',
  },
  {
    kind: 'hat',
    buttonName: 'Hat',
    fieldName: 'avatarHat',
  },
  {
    kind: 'eyes',
    buttonName: 'Eyes',
    fieldName: 'avatarEyes',
  },
  {
    kind: 'mouth',
    buttonName: 'Mouth',
    fieldName: 'avatarMouth',
  },
];
