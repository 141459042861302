import { Switch, Route } from 'react-router-dom';
import {
  Tooltip,
  Button,
  Flex,
  FormGroup,
  TaskButton,
  Text,
  Spacer,
  Icon,
  NoStylesButton,
  LinkButton,
  ErrorText,
  FlexGrow,
  useToaster,
  Label,
  TagEditor,
  CheckboxInput,
  SharingDisclaimer,
} from 'src/components';
import { ReadingStudioLayoutSideBar } from 'src/layouts';
import { ImmutableList } from 'src/modules/Immutable';
import { useStore } from 'src/Store';
import { queryMapToString, useQuery, useHistory } from 'src/modules/Router';
import {
  moderatorOrGreater,
  DISABLED_GRADE_LEVELS,
  LicenseType,
  isSharableLicenseType,
  readingIsShared,
} from 'src/models';
import { paddings } from 'src/styles';
import { useLanguageCodeSelectOptions } from 'src/modules/LanguageCodes';

const SideBarContent = () => {
  const {
    contentType,
    name,
    authorName,
    coverImage,
    setField,
    errors,
    coverImageFile,
    coverImageFileUrl,
    uploadedPdfFile,
    uploadPdfFile,
    pdfFile,
    webSourceUrl,
    openWebSourceUrlModal,
    selectedLicenseType,
    gradeLevelId,
    languageCode,
    newTagName,
    tagNameList,
    removeTag,
    addTag,
    toggleSharing,
    reading,
    playlists,
    selectedPlaylistIds,
    primaryLicenseType,
    isSpecialEducation,
    isEll,
    readingId,
    gradeLevels,
    peekPdf,
  } = useStore((s) => s.ReadingBuilderData, []);
  const currentUser = useStore((s) => s.AppData.currentUser, []);
  const languageCodeSelectOptions = useLanguageCodeSelectOptions();

  const query = useQuery();
  const viewMode = query.get('viewMode') || '';
  const canShare = (primaryLicenseType?: LicenseType | null, licenseType?: LicenseType) => {
    if (moderatorOrGreater(currentUser?.accountType || 'student')) return true;
    if (viewMode === 'preview' || currentUser?.accountType !== 'teacher') return false;
    if (primaryLicenseType) return false;
    if (!reading) return true;

    return !licenseType || isSharableLicenseType(licenseType);
  };

  return (
    <Switch>
      <Route path={['/t/reading-builder/content', '/t/reading-builder']} exact>
        <Flex direction="column" align="stretch">
          <Text variant="h4">Pick an Assignment Type</Text>
          <Spacer size={4} />
          <TaskButton
            onClick={() => setField('contentType')('text')}
            active={contentType === 'text'}
            icon="copy"
          >
            Copy &amp; Paste (text only)
          </TaskButton>
          <TaskButton
            onClick={() => setField('contentType')('pdf_by_page')}
            active={contentType === 'pdf_by_page'}
            icon="pdf"
          >
            Upload PDF
          </TaskButton>
          <TaskButton
            onClick={() => setField('contentType')('independent')}
            active={contentType === 'independent'}
            icon="book"
          >
            Independent Reading
          </TaskButton>
          <TaskButton to="/t/library" active={false} icon="search">
            Content Library
          </TaskButton>
          {(contentType === 'pdf' || contentType === 'pdf_by_page') &&
            (uploadedPdfFile || pdfFile) && (
              <Flex direction="column">
                <Spacer size={10} />
                <Text variant="h5" color="white">
                  Uploaded File
                </Text>
                <Spacer size={3} />
                <Flex align="center" justify="space-between">
                  <Flex grow={1}>
                    <Icon icon="pdf" fill fillColor="white" strokeWidth={0} />
                    <Spacer horizontal size={4} />
                    <Text
                      variant="h6"
                      color="white"
                      lineHeightOverride="100%"
                      maxWidth="150px"
                      title={uploadedPdfFile ? uploadedPdfFile.name : pdfFile?.name}
                      ellipsis
                    >
                      {uploadedPdfFile ? uploadedPdfFile.name : pdfFile?.name}
                    </Text>
                  </Flex>
                  <NoStylesButton download href={pdfFile?.url}>
                    <Tooltip content="Download PDF">
                      <Icon
                        icon="download"
                        color="primaryBlue"
                        fill
                        fillColor="primaryBlue"
                        size="1.5em"
                        strokeWidth={0}
                      />
                    </Tooltip>
                  </NoStylesButton>
                  <Spacer horizontal size={2} />
                  <NoStylesButton onClick={() => uploadPdfFile(ImmutableList())}>
                    <Tooltip content="Delete PDF">
                      <Icon
                        icon="trash"
                        color="danger"
                        fill
                        fillColor="danger"
                        size="1.5em"
                        strokeWidth={0}
                      />
                    </Tooltip>
                  </NoStylesButton>
                </Flex>
              </Flex>
            )}
          {contentType === 'web' && (
            <Flex direction="column">
              <Spacer size={10} />
              <Text variant="h5" color="white">
                Source URL
              </Text>
              <Spacer size={3} />
              <Flex align="start" justify="space-between">
                {!webSourceUrl ? (
                  <Button onClick={openWebSourceUrlModal} icon="add" iconSize="1.25em" width="100%">
                    Add Source URL
                  </Button>
                ) : (
                  <>
                    <Flex align="center">
                      <Icon icon="link" fillColor="white" size="1.5em" strokeWidth={1.5} />
                      <Spacer horizontal size={4} />
                      <Text
                        variant="h6"
                        color="white"
                        lineHeightOverride="100%"
                        maxWidth="150px"
                        title={webSourceUrl}
                        ellipsis
                      >
                        {new window.URL(webSourceUrl).hostname}
                      </Text>
                      <Spacer horizontal size={2} />
                    </Flex>
                    <NoStylesButton
                      onClick={() => setField('webSourceUrl')('')}
                      testTag="remove-web-url"
                    >
                      <Icon icon="trash" fill fillColor="danger" size="1em" strokeWidth={0} />
                    </NoStylesButton>
                  </>
                )}
              </Flex>
            </Flex>
          )}
        </Flex>
      </Route>
      <Route path="/t/reading-builder/metadata" exact>
        <Flex direction="column">
          <Flex align="end">
            <FormGroup
              id="cover-image-input"
              name="coverImageFile"
              type="file"
              kind="imagePreview"
              width="auto"
              value={coverImage ? ImmutableList([coverImage]) : ImmutableList()}
              onChange={(files) => {
                const file = files.get(0, null);
                setField('coverImageFileUrl')(file ? URL.createObjectURL(file) : '');
                setField('coverImage')(file);
              }}
              accept="image"
              testTag="sidebar-image"
              flex
              uploadedFile={coverImageFile}
              disabled={viewMode === 'preview'}
            />

            <Spacer horizontal size={3} />
            {coverImageFileUrl || coverImageFile ? (
              <Flex align="start" justify="space-between" direction="column" maxWidth="130px">
                <Text
                  variant="h6"
                  color="white"
                  lineHeightOverride="100%"
                  title={coverImage ? coverImage.name : coverImageFile?.name}
                  ellipsis
                  maxWidth="100%"
                >
                  {coverImage ? coverImage.name : coverImageFile?.name}
                </Text>
                <Spacer size={2} />
                <LinkButton
                  onClick={() => {
                    setField('coverImageFileUrl')('');
                    setField('coverImage')(null);
                    setField('coverImageFile')(null);
                  }}
                  color="primaryBlue"
                  paddingOverride={paddings.none}
                >
                  Remove Image
                </LinkButton>
              </Flex>
            ) : (
              <Text color="white" variant="h6" maxWidth="130px">
                Upload Cover Image (optional)
              </Text>
            )}
          </Flex>
          {errors && <ErrorText errors={errors} errorKey="coverImageFile" />}
          <Spacer size={10} />
          <FormGroup
            name="name"
            type="text"
            value={name}
            label={`Title of Text${contentType === 'independent' ? ' (Optional)' : ''}`}
            onChange={setField('name')}
            errors={errors}
            disabled={viewMode === 'preview'}
          />
          <Spacer size={3} />
          <FormGroup
            name="author"
            type="text"
            value={authorName}
            label={`Author${contentType === 'independent' ? ' (Optional)' : ''}`}
            onChange={setField('authorName')}
            errors={errors}
            disabled={viewMode === 'preview'}
          />
          <Spacer size={3} />
          <FormGroup
            type="select"
            label="Language"
            name="language"
            options={languageCodeSelectOptions}
            value={languageCode}
            onChange={setField('languageCode')}
            disabled={viewMode === 'preview'}
          />
          <Spacer size={3} />
          <FormGroup
            type="select"
            label="Grade Level"
            name="gradeLevel"
            errors={errors}
            disabled={viewMode === 'preview'}
            options={gradeLevels.map((gl) => ({
              value: gl.id,
              label: gl.name,
              disabled: readingId ? DISABLED_GRADE_LEVELS.has(gl.level) : false,
            }))}
            value={gradeLevelId}
            onChange={setField('gradeLevelId')}
          />
          <Spacer size={3} />
          <CheckboxInput
            name="specialEd"
            label="Special Education Students"
            value={isSpecialEducation}
            onChange={setField('isSpecialEducation')}
            textColor="white"
          />
          <Spacer size={3} />
          <CheckboxInput
            name="ell"
            label="English Language Learners"
            value={isEll}
            onChange={setField('isEll')}
            textColor="white"
          />
          <Spacer size={3} />
          <Flex align="center">
            <Label $isTextInput $noMargin>
              Tags
            </Label>
            <Spacer horizontal />
            <Tooltip content="Add tags to help others find your content" iconColor="gray6" />
          </Flex>
          <Spacer />
          <TagEditor
            newTagName={newTagName}
            tagNameList={tagNameList}
            errors={errors}
            updateTag={setField('newTagName')}
            addTag={addTag}
            removeTag={removeTag}
            disabled={viewMode === 'preview'}
          />
          {canShare(primaryLicenseType, reading?.license) && (
            <>
              <Spacer size={3} />
              <Flex align="center">
                <Text variant="h5">Sharing</Text>
                <Spacer horizontal size={1} />
                <SharingDisclaimer isPaperUser={Boolean(currentUser?.paperSyncedAt)} />
                <Spacer horizontal size={3} />
                <FormGroup
                  flex
                  type="toggle"
                  name="toggleSharing"
                  value={readingIsShared(selectedLicenseType)}
                  onChange={toggleSharing(Boolean(currentUser?.paperId))}
                  width="auto"
                  disabled={viewMode === 'preview'}
                />
              </Flex>
              <Spacer size={3} />
              {readingIsShared(selectedLicenseType) && (
                <>
                  <FormGroup
                    name="licenseType"
                    type="select"
                    value={selectedLicenseType}
                    onChange={setField('selectedLicenseType')}
                    options={ImmutableList([
                      { value: 'school', label: 'Sharing with my School' },
                      { value: 'district', label: 'Sharing with my District' },
                      { value: 'open_source_needs_review', label: 'Sharing with Everyone' },
                      ...(currentUser && moderatorOrGreater(currentUser.accountType)
                        ? ([
                            { value: 'open_source', label: 'Open Source' },
                            {
                              value: 'private_final',
                              label: 'Private User Content (User Cannot Change)',
                            },
                            { value: 'in_copyright', label: 'In Copyright (unused)' },
                          ] as const)
                        : []),
                    ])}
                  />
                  <Spacer size={3} />
                </>
              )}
            </>
          )}
          {currentUser && moderatorOrGreater(currentUser.accountType) && (
            <>
              <FormGroup
                type="multiselect"
                label="Playlists"
                name="playlists"
                options={playlists
                  .sortBy((p) => [p.ranking, p.name])
                  .map((playlist) => ({
                    value: playlist.id,
                    label: `${playlist.name}${playlist.enabled ? '' : ' (Hidden)'}`,
                  }))}
                value={selectedPlaylistIds}
                onChange={setField('selectedPlaylistIds')}
              />
              <div>
                <Button displayType="link" to="/m/playlists" color="white">
                  Manage Playlists
                </Button>
              </div>
              <Spacer size={3} />
            </>
          )}
        </Flex>
      </Route>
      <Route path="/t/reading-builder/processing" exact>
        <FlexGrow />
      </Route>
      <Route path="/t/reading-builder/edit-pdf-text" exact>
        <Text variant="h3">PDF Text Validation</Text>
        <Spacer />
        <Text variant="h6">
          We&apos;ve extracted the PDF&apos;s content for calculating your student&apos;s fluency
          metrics, like accuracy and completion percentage.
        </Text>
        <Spacer size={2} />
        <Text variant="h6">
          For optimal results, edit and refine this text (e.g., removing headers and copyright info)
          to include only the desired reading material. Click on each thumbnail to view and edit
          page text. Give it a try!
        </Text>
        <Spacer size={9} />
        <Text variant="h6">
          Click this button to view the PDF page if you need to reference it!
        </Text>
        <Spacer size={2} />
        <Button onClick={() => setField('peekPdf')(!peekPdf)}>Toggle PDF View</Button>
        <FlexGrow />
      </Route>
    </Switch>
  );
};

export const ReadingBuilderSideBar = () => {
  const history = useHistory();
  const query = useQuery();
  const viewMode = query.get('viewMode') || '';

  const { createToast } = useToaster();
  const accountType = useStore((s) => s.AppData.currentUser?.accountType || 'teacher', []);
  const {
    contentType,
    uploadingPdf,
    submitting,
    adminUpdate,
    reading,
    basicText,
    pdfFile,
    webSourceUrl,
    overridePdfJsonText,
  } = useStore((s) => s.ReadingBuilderData, []);

  const contentSubmitted =
    (contentType === 'text' && basicText !== '') ||
    ((contentType === 'pdf' || contentType === 'pdf_by_page') && Boolean(pdfFile)) ||
    (contentType === 'web' && basicText !== '' && webSourceUrl !== '') ||
    contentType === 'independent';

  return (
    <ReadingStudioLayoutSideBar
      content={<SideBarContent />}
      primaryButton={
        <Flex direction="column" align="stretch">
          <Switch>
            <Route path={['/t/reading-builder/content', '/t/reading-builder']} exact>
              <Tooltip content="Add content before moving on" disabled={contentSubmitted}>
                <Button
                  disabled={!contentType || !contentSubmitted}
                  loading={uploadingPdf}
                  to={`/t/reading-builder/metadata${queryMapToString(query)}`}
                  width="100%"
                >
                  Next
                </Button>
              </Tooltip>
            </Route>
            <Route path="/t/reading-builder/metadata" exact>
              <>
                {reading?.id && moderatorOrGreater(accountType) && (
                  <Tooltip
                    display="block"
                    content="By using this action Moderators can update the Cover Image, Title, Author, License Type, Grade Level, and Tags in the content library"
                  >
                    <Button
                      width="100%"
                      onClick={adminUpdate(history, query, createToast)}
                      loading={submitting}
                      color="danger"
                      icon="info"
                    >
                      Moderator Update
                    </Button>
                    <Spacer size={2} />
                  </Tooltip>
                )}
                {viewMode !== 'preview' && (
                  <Button
                    to={`/t/reading-builder/content${queryMapToString(query)}`}
                    displayType="outline"
                    color="white"
                  >
                    Back
                  </Button>
                )}
                <Spacer size={2} />
                <Button loading={submitting} type="submit">
                  {viewMode === 'preview' ? 'Create Assignment' : 'Next'}
                </Button>
              </>
            </Route>
            <Route path="/t/reading-builder/processing" exact>
              <Button
                to={`/t/reading-builder/metadata${queryMapToString(query)}`}
                displayType="outline"
                color="white"
              >
                Back
              </Button>
            </Route>
            <Route path="/t/reading-builder/edit-pdf-text" exact>
              <Button
                to={`/t/reading-builder/metadata${queryMapToString(query)}`}
                displayType="outline"
                color="white"
              >
                Back
              </Button>
              <Spacer size={2} />
              <Button loading={submitting} onClick={overridePdfJsonText({ history, query })}>
                Next
              </Button>
            </Route>
          </Switch>
        </Flex>
      }
    />
  );
};
