import styled from 'styled-components';
import { colors, RequiredDollarPrefix, rgba, paddings, TEXT_VIEWER_MAX_WIDTH } from 'src/styles';
import { useStore } from 'src/Store';
import { DEFAULT_TEXT_SIZE } from 'src/models';
import { Flex, Loading, Spacer, Text } from 'src/components';
import React from 'react';

type TextContainerProps = RequiredDollarPrefix<{
  textSize: number;
  compactLayout: boolean;
}>;

const TextContainer = styled.div<TextContainerProps>`
  font-size: ${({ $textSize }) => $textSize}px;
  white-space: pre-wrap;
  max-width: ${TEXT_VIEWER_MAX_WIDTH};
  ${({ $compactLayout }) => ($compactLayout ? `min-width: 0; flex-grow: 1;` : `min-width: 350px;`)}
  overflow-y: auto;

  /* pure css implementation of shadows on scrollable content */
  background: linear-gradient(${colors.white.hex} 30%, ${rgba(colors.white.hex, 0.3)}),
    linear-gradient(${rgba(colors.white.hex, 0)}, ${colors.white.hex} 70%) 0 100%,
    radial-gradient(
      farthest-side at 50% 0,
      ${rgba(colors.backgroundDark.hex, 0.3)},
      ${rgba(colors.black.hex, 0)}
    ),
    radial-gradient(
        farthest-side at 50% 100%,
        ${rgba(colors.backgroundDark.hex, 0.3)},
        ${rgba(colors.black.hex, 0)}
      )
      0 100%;
  background-repeat: no-repeat;
  background-size: 100% 40px, 100% 40px, 100% 20px, 100% 20px;
  background-attachment: local, local, scroll, scroll;
`;

type TextViewerProps = {
  children: React.ReactNode;
  loading?: boolean;
  errored?: boolean;
  testTag?: string;
  drawerOpen?: boolean;
  compactLayout?: boolean;
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
  onMouseOver?: (event: React.MouseEvent<HTMLDivElement>) => void;
};

const getPlaceholderContent = (loading: boolean, errored: boolean) => {
  if (loading) return <Loading />;
  if (errored) return 'There was an error fetching your content';
  return 'No content available to read';
};

const bottomSpacerHeight = (compactLayout: boolean, drawerOpen: boolean) => {
  if (compactLayout) {
    return 18;
  } else if (drawerOpen) {
    return 75;
  } else {
    return 22;
  }
};

const UnmemoizedTextViewer = ({
  children,
  loading = false,
  errored = false,
  testTag,
  drawerOpen = false,
  compactLayout = false,
  onClick,
  onMouseOver,
}: TextViewerProps) => {
  const textSize = useStore((state) => state.AppData.currentUser?.textSize, []);

  return (
    <Flex
      width="100%"
      height="100%"
      justify="center"
      minHeight={0}
      testTag={testTag}
      padding={`0 ${paddings[2]}`}
    >
      <TextContainer
        $textSize={textSize || DEFAULT_TEXT_SIZE}
        $compactLayout={compactLayout}
        data-test-tag="reader"
        onClick={onClick}
        onMouseOver={onMouseOver}
      >
        {children ? (
          <>
            <Spacer size={compactLayout ? 2 : 8} />
            {children}
            <Spacer size={bottomSpacerHeight(compactLayout, drawerOpen)} />
          </>
        ) : (
          <Flex width="100%" height="100%" justify="center" align="center">
            <Text variant="h1">{getPlaceholderContent(loading, errored)}</Text>
          </Flex>
        )}
      </TextContainer>
    </Flex>
  );
};

export const TextViewer = React.memo(UnmemoizedTextViewer);
