import { useToaster } from '../Toast';
import { Button } from '../Button';
import { StandardModal } from '../Modal';
import { FormGroup } from '../FormGroup';
import { useStore } from 'src/Store';

type TextSettingsProps = {
  opened: boolean;
  close: () => void;
};
export const TextSettings = ({ opened, close }: TextSettingsProps) => {
  const { createToast } = useToaster();
  const { currentUser, setUserField } = useStore(
    ({ AppData: s }) => ({
      currentUser: s.currentUser,
      setUserField: s.setUserField,
    }),
    [],
  );

  return (
    <StandardModal
      openType="state"
      opened={opened}
      close={close}
      header="Text Settings"
      footer={<Button onClick={close}>Close</Button>}
    >
      <FormGroup
        label="Text Size"
        type="textSize"
        name="textSize"
        value={currentUser?.textSize ?? 16}
        onChange={setUserField('textSize', createToast, false)}
      />
    </StandardModal>
  );
};
