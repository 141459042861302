import { useEffect } from 'react';
import styled from 'styled-components';
import {
  Text,
  Button,
  Flex,
  AudioPlayer,
  Spacer,
  Tag,
  NoStylesButton,
  Loading,
  useToaster,
  Tooltip,
  StandardModal,
} from 'src/components';
import { useStore } from 'src/Store';
import { paddings, margins } from 'src/styles';
import { capitalize } from 'src/modules/String';
import { DEFAULT_TEXT_SIZE } from 'src/models';

const DefinitionList = styled.ol`
  padding: ${paddings.none} ${paddings[4]} ${paddings.none} ${paddings[4]};
  margin: ${margins[1]} ${margins.none} ${margins.none} ${margins.none};
`;

const Definition = styled.li`
  padding: ${paddings.none};
  margin: ${margins.none};
  font-size: ${DEFAULT_TEXT_SIZE}px;
`;

export const DictionaryModal = () => {
  const {
    displayWord,
    word,
    open,
    audioUrl,
    displayDefinitions,
    definitions,
    synonyms,
    isLoading,
    load,
    report,
    setField,
    error,
  } = useStore((s) => ({ ...s.DictionaryData }), []);

  const { createToast } = useToaster();

  useEffect(load, [load]);

  return (
    <StandardModal
      header={capitalize(displayWord?.content || word)}
      openType="state"
      opened={open}
      close={load}
      middleHeader={
        audioUrl ? (
          <>
            <Spacer size={2} horizontal />
            <AudioPlayer
              src={audioUrl}
              displayType="iconOnly"
              icon="speaker"
              iconColor="black"
              subText={
                displayWord?.lemmaWord &&
                displayWord.lemmaWord.toLowerCase() !== displayWord.content.toLowerCase()
                  ? capitalize(displayWord.lemmaWord)
                  : undefined
              }
            />
          </>
        ) : null
      }
      rightHeader={
        <>
          <Tooltip content="Report inappropriate definition">
            <Button
              icon="flag"
              testTag="report-button"
              displayType="iconOnly"
              color="white"
              iconColor="danger"
              iconSize="1.25em"
              width="30px"
              height="30px"
              paddingOverride={paddings.none}
              onClick={() => report(displayWord?.id || null, createToast)}
            />
          </Tooltip>
          <Spacer size={2} />
        </>
      }
    >
      {error ? (
        <Text variant="h5">{error}</Text>
      ) : (
        <>
          {isLoading ? (
            <Flex margin={margins[5]}>
              <Loading flex />
            </Flex>
          ) : (
            <>
              <Flex display="inline-flex" wrap="wrap" padding={`0 0 ${paddings[4]} 0`}>
                <>
                  {synonyms.isEmpty() ? (
                    <Text>Could not find synonyms at this time</Text>
                  ) : (
                    <>
                      {synonyms.map((synonym, i) => (
                        <Flex key={i} padding={`${paddings[1]} 0`}>
                          <Tag color="backgroundDark" title={synonym}>
                            {synonym}
                          </Tag>
                          <Spacer size={1} horizontal />
                        </Flex>
                      ))}
                    </>
                  )}
                </>
              </Flex>
              <NoStylesButton
                testTag="definitions-button"
                icon={displayDefinitions ? 'arrowUp' : 'arrowDown'}
                display="flex"
                justify="space-between"
                iconColor="black"
                iconPosition="right"
                iconSize="20px"
                onClick={() => setField('displayDefinitions')(!displayDefinitions)}
              >
                <Text variant="h4">Definitions</Text>
              </NoStylesButton>
              <DefinitionList>
                {displayDefinitions &&
                  definitions.map((d, i) => <Definition key={i}>{d}</Definition>)}
              </DefinitionList>
            </>
          )}
        </>
      )}
    </StandardModal>
  );
};
