import React, { useState, useEffect, useCallback } from 'react';
import { useStore } from 'src/Store';
import { DEFAULT_TEXT_SIZE, TextStoredFile, VocabTaskDetails } from 'src/models';
import { TextRange, emptyTextRange } from 'src/modules/TextRange';
import { Text, DictionaryModal } from 'src/components';
import { TextViewer } from './TextViewer';
import { useFetch } from 'src/modules/Api';
import { borderRadii, colors, paddings } from 'src/styles';
import { ImmutableList } from 'src/modules/Immutable';
import { VocabTaskOverviewModal } from '../VocabTaskOverview';
import { LanguageCodeDetails } from 'src/modules/LanguageCodes';

export type ReaderDisplayType =
  | 'textOnly' // For just showing the text with no added functinality
  | 'setTextRange' // For selecting text ranges
  | 'dictionary' // For showing definitions for individual words
  | 'setVocabWord'; // For choosing the word in a vocab task
type ContentProps =
  | {
      textFile: TextStoredFile;
      textContent?: null;
    }
  | {
      textContent: string;
      textFile?: null;
    };

type PlainTextReaderProps =
  | {
      displayType?: ReaderDisplayType;
      enableTextRangeSelection: boolean;
      drawerOpen?: boolean;
      onTextRangeSelected?: (textRange: TextRange, text: string) => void;
      textRange?: TextRange;
      highlightedWord?: string;
      setVocabWord?: (text: string) => void;
      vocabTaskDetailsList?: ImmutableList<VocabTaskDetails>;
      compactLayout?: boolean;
      languageCodeDetails: LanguageCodeDetails | null;
    } & ContentProps;

type Token = {
  startIndex: number;
  content: string;
  isWord: boolean;
};

const checkInTextRange = (
  startIndex: number,
  textRange: TextRange,
  hoveredIndex: number | null,
) => {
  if (typeof textRange.startPoint.index !== 'number') return false;
  if (typeof textRange.endPoint.index === 'number') {
    return startIndex >= textRange.startPoint.index && startIndex < textRange.endPoint.index;
  } else if (startIndex === textRange.startPoint.index) {
    return true;
  } else if (typeof hoveredIndex === 'number') {
    return (
      (startIndex >= textRange.startPoint.index && startIndex <= hoveredIndex) ||
      (startIndex <= textRange.startPoint.index && startIndex >= hoveredIndex)
    );
  }
  return false;
};

const parseSpanElement = (elem: HTMLElement) => {
  const startIndexStr = elem.getAttribute('data-start-index');
  const startIndex = startIndexStr ? parseInt(startIndexStr, 10) : 0;

  return {
    isWord: elem.getAttribute('data-elem-type') === 'word',
    startIndex,
    content: elem.innerText,
  };
};

const UnmemoizedPlainTextReader = ({
  textFile,
  textContent,
  displayType = 'textOnly',
  enableTextRangeSelection,
  textRange = emptyTextRange,
  drawerOpen = false,
  highlightedWord,
  onTextRangeSelected,
  setVocabWord,
  vocabTaskDetailsList,
  compactLayout,
  languageCodeDetails,
}: PlainTextReaderProps) => {
  const { textSize, searchDictionary } = useStore(
    (state) => ({
      textSize: state.AppData.currentUser?.textSize,
      searchDictionary: state.DictionaryData.searchDictionary,
    }),
    [],
  );

  const [openedVocabWord, setOpenedVocabWord] = useState<VocabTaskDetails>();
  const { fetchResponse } = useFetch();
  const [loading, setLoading] = useState(true);
  const [fileContent, setFileContent] = useState<string | null>(null);
  const [errored, setErrored] = useState(false);
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
  const content = textContent ?? fileContent ?? '';
  const canSetTextRange = displayType === 'setTextRange' && enableTextRangeSelection;

  useEffect(() => {
    if (!textFile?.url) return;

    const onError = () => {
      setErrored(true);
      setLoading(false);
    };

    fetchResponse(textFile.url, {
      retrySchedule: [0, 1000, 10000],
      onSuccess: async (response) => {
        const text = await response.text();
        setFileContent(text);
        setLoading(false);
      },
      onUnknownError: onError,
      onNotAuthenticated: onError,
      onNotAuthorized: onError,
      noRedirect: true,
    });
  }, [textFile?.url, fetchResponse]);

  const setTextRange = useCallback(
    (item: Token) => {
      if (
        typeof textRange.startPoint.index !== 'number' ||
        typeof textRange.startPoint.wordLength !== 'number' ||
        typeof textRange.endPoint.index === 'number'
      ) {
        onTextRangeSelected?.(
          {
            startPoint: { index: item.startIndex, wordLength: item.content.length },
            endPoint: {},
          },
          '',
        );
      } else {
        if (item.startIndex < textRange.startPoint.index) {
          const startIndex = item.startIndex;
          const endIndex = textRange.startPoint.index + textRange.startPoint.wordLength;
          onTextRangeSelected?.(
            {
              startPoint: { index: startIndex },
              endPoint: { index: endIndex },
            },
            content.substring(startIndex, endIndex),
          );
        } else {
          const startIndex = textRange.startPoint.index;
          const endIndex = item.startIndex + item.content.length;
          onTextRangeSelected?.(
            {
              startPoint: { index: startIndex },
              endPoint: { index: endIndex },
            },
            content.substring(startIndex, endIndex),
          );
        }
      }
    },
    [onTextRangeSelected, textRange, content],
  );

  let splitContent = null;
  if (displayType !== 'textOnly') {
    let currentIndex = 0;
    splitContent = content.split(/([^a-zA-Z0-9'’]+)/).map((content) => {
      const isWord = /[a-zA-Z0-9]+['’]*[a-zA-Z0-9]*/.test(content);
      const startIndex = currentIndex;
      currentIndex += content.length;
      return {
        startIndex,
        content,
        isWord,
        inTextRange: checkInTextRange(startIndex, textRange, hoveredIndex),
      };
    });
  }

  const handleClick = useCallback(
    (clickEvent: React.MouseEvent<HTMLDivElement>) => {
      if (!clickEvent.target) return;
      const elem: HTMLElement = clickEvent.target as HTMLElement;
      const item = parseSpanElement(elem);
      if (displayType === 'setVocabWord' && item.isWord && setVocabWord) {
        setVocabWord(item.content);
      } else if (
        displayType === 'dictionary' ||
        (displayType === 'setTextRange' && !enableTextRangeSelection)
      ) {
        if (item.isWord) {
          const lowerContent = item.content.toLowerCase();
          const clickedVocabWord = vocabTaskDetailsList?.find(
            (td) =>
              td.task.customWord.toLowerCase() === lowerContent ||
              td.word?.content?.toLowerCase() === lowerContent,
          );
          if (clickedVocabWord) {
            setOpenedVocabWord(clickedVocabWord);
          } else if (languageCodeDetails?.dictionaryEnabled) {
            searchDictionary(lowerContent);
          }
        }
      } else if (displayType === 'setTextRange') {
        if (!canSetTextRange) return;
        if (item.isWord) {
          setTextRange(item);
        }
      }
    },
    [
      searchDictionary,
      displayType,
      setTextRange,
      vocabTaskDetailsList,
      setOpenedVocabWord,
      canSetTextRange,
      setVocabWord,
      languageCodeDetails,
      enableTextRangeSelection,
    ],
  );

  const handleMouseOver = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      if (
        !canSetTextRange ||
        typeof textRange.startPoint.index !== 'number' ||
        typeof textRange.endPoint.index === 'number'
      )
        return;
      if (!event.target) return;
      const elem: HTMLElement = event.target as HTMLElement;
      const item = parseSpanElement(elem);
      if (item.isWord && item.startIndex !== hoveredIndex) {
        setHoveredIndex(item.startIndex);
      }
    },
    [hoveredIndex, canSetTextRange, textRange],
  );
  return (
    <TextViewer
      loading={loading}
      errored={errored}
      drawerOpen={drawerOpen}
      onClick={handleClick}
      onMouseOver={handleMouseOver}
      compactLayout={compactLayout}
    >
      {displayType !== 'textOnly' && splitContent ? (
        splitContent.map((item, i) => {
          const lowerContent = item.content.toLowerCase();
          const highlightWord =
            lowerContent === highlightedWord?.toLowerCase() ||
            vocabTaskDetailsList?.some(
              (vt) =>
                vt.task.customWord.toLowerCase() === lowerContent ||
                vt.word?.content.toLowerCase() === lowerContent,
            );
          const bgcolor = highlightWord ? '#f9c642' : 'transparent';
          // This is a native span element because there may be many thousands of these
          // on screen and optimizations demand simpler elements.
          return (
            <span
              key={i}
              data-elem-type={item.isWord ? 'word' : 'other'}
              data-start-index={item.startIndex}
              style={{
                fontSize: `${textSize || DEFAULT_TEXT_SIZE}px`,
                whiteSpace: 'pre-wrap',
                cursor: 'pointer',
                color:
                  item.inTextRange ||
                  !textRange ||
                  displayType === 'dictionary' ||
                  displayType === 'setVocabWord'
                    ? colors.black.hex
                    : colors.gray5.hex,
                backgroundColor: bgcolor,
                padding: highlightWord ? paddings.half : paddings.none,
                borderRadius: borderRadii.half,
              }}
            >
              {item.content}
            </span>
          );
        })
      ) : (
        <Text sizeOverride={textSize || DEFAULT_TEXT_SIZE} whiteSpace="pre-wrap">
          {content}
        </Text>
      )}
      <DictionaryModal />
      {openedVocabWord && (
        <VocabTaskOverviewModal
          taskDetails={openedVocabWord}
          close={() => setOpenedVocabWord(undefined)}
          compactLayout={compactLayout || false}
        />
      )}
    </TextViewer>
  );
};

export const PlainTextReader = React.memo(UnmemoizedPlainTextReader);
