import { subDataCreator } from 'src/State';
import { defaultState } from './ForgotPasswordState';
import { ErrorMapResponse } from 'src/modules/Api';
import { ImmutableMap } from 'src/modules/Immutable';

interface ForgotPasswordResponse {
  success: boolean;
  errors: ErrorMapResponse;
  sentToTeacher: boolean;
}

export const createDefaultForgotPasswordData = subDataCreator(
  'ForgotPasswordData',
  defaultState,
  ({ get, set, setField, fetchJson }) => ({
    setField,
    reset: () => set((s) => ({ ...defaultState, username: s.username })),
    submit: () => {
      const { username } = get();
      set({ errors: ImmutableMap(), submitting: true });
      fetchJson(`/api/password_resets`, {
        method: 'POST',
        data: { username },
        onSuccess: ({ success, errors, sentToTeacher }: ForgotPasswordResponse) => {
          set({ success, errors: ImmutableMap(errors), sentToTeacher, submitting: false });
        },
      });
    },
  }),
);
