import { subDataCreator } from 'src/State';
import { ErrorMapResponse } from 'src/modules/Api';
import { ImmutableMap } from 'src/modules/Immutable';
import { defaultState } from './LoginState';
import { User } from 'src/models';

type LoginResponse = {
  success: boolean;
  submitting: boolean;
  errors: ErrorMapResponse;
  user: User;
};

export const createDefaultLoginData = subDataCreator(
  'LoginData',
  defaultState,
  ({ get, set, setField, getFull, fetchJson }) => ({
    setField,
    load: () => set(defaultState),
    submit: ({ redirectPath, courseCode, history }) => {
      const { username, password } = get();
      set({ errors: ImmutableMap(), submitting: true });
      fetchJson(`/api/user_sessions`, {
        method: 'POST',
        data: {
          username,
          password,
        },
        onSuccess: ({ success, errors, user }: LoginResponse) => {
          if (!success) {
            set({ success, errors: ImmutableMap(errors), submitting: false });
            return;
          }

          getFull().AppData.onLoggedIn({
            courseCode: user.accountType === 'student' ? courseCode : null,
            redirectPath,
            history,
            method: 'password',
          });
        },
      });
    },
  }),
);
