import { useCallback, useEffect } from 'react';
import { useHistory, useQuery } from 'src/modules/Router';
import { Button, FormGroup, Spacer, StandardModal, Text, Flex } from 'src/components';
import { useStore } from 'src/Store';

export const JoinCourseModal = () => {
  const history = useHistory();
  const { courseCode, errors, submit, setField, load, loading, open, checkCode } = useStore(
    (s) => s.JoinCourseData,
    [],
  );
  const closeModal = useCallback(() => setField('open')(false), [setField]);
  const query = useQuery();
  const joinCode = query.get('joinCode') || '';

  useEffect(() => {
    load({ joinCode });
    checkCode(joinCode);
  }, [load, joinCode, checkCode]);

  if (!open) return null;
  return (
    <StandardModal
      openType="state"
      opened={open}
      close={closeModal}
      disableFadeClose
      header="Join Class"
      footer={
        <Flex justify="end">
          <Button onClick={closeModal} inheritBgColor displayType="link" color="primaryBlue">
            Close
          </Button>
          <Spacer horizontal size={3} />
          <Button disabled={loading} onClick={() => submit(history)}>
            Join Class
          </Button>
        </Flex>
      }
    >
      <Text>Please enter the class code provided by your teacher.</Text>
      <Spacer size={2} />
      <FormGroup
        type="text"
        name="courseCode"
        label="Class Code"
        placeholder="Enter Class Code"
        errors={errors}
        value={courseCode}
        onChange={setField('courseCode')}
      />
    </StandardModal>
  );
};
