import { FetchJson } from 'src/modules/Api';
import { ImmutableList } from './Immutable';
import { formatTimestamp } from './Time';

type CsvResponse = {
  url: string;
};

export const downloadCSV =
  ({
    data,
    filename,
    fetchJson,
  }: {
    data: ImmutableList<string[]>;
    filename: string;
    fetchJson: FetchJson;
  }) =>
  () => {
    const date = new Date();

    fetchJson(`/api/csv/create_csv`, {
      method: 'POST',
      data: {
        data: data,
        name: `${filename}-${formatTimestamp(date)}`,
      },
      onSuccess: (data: CsvResponse) => {
        window.location.href = data.url;
      },
    });
  };
