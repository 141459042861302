{
  "nm": "Vague02",
  "mn": "",
  "layers": [
    {
      "ty": 4,
      "nm": "Shape Layer 4",
      "mn": "",
      "sr": 1,
      "st": 0,
      "op": 600.000024438501,
      "ip": 0,
      "hd": false,
      "cl": "",
      "ln": "",
      "ddd": 0,
      "bm": 0,
      "hasMask": false,
      "td": 1,
      "ao": 0,
      "ks": {
        "a": { "a": 0, "k": [9.578, 23.578, 0], "ix": 1 },
        "s": { "a": 0, "k": [100, 100, 100], "ix": 6 },
        "sk": { "a": 0, "k": 0 },
        "p": { "a": 0, "k": [240, 240, 0], "ix": 2 },
        "sa": { "a": 0, "k": 0 },
        "o": { "a": 0, "k": 100, "ix": 11 },
        "r": { "a": 0, "k": 0, "ix": 10 }
      },
      "ef": [],
      "shapes": [
        {
          "ty": "gr",
          "bm": 0,
          "cl": "",
          "ln": "",
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Ellipse 1",
          "ix": 1,
          "cix": 2,
          "np": 3,
          "it": [
            {
              "ty": "el",
              "bm": 0,
              "cl": "",
              "ln": "",
              "hd": false,
              "mn": "ADBE Vector Shape - Ellipse",
              "nm": "Ellipse Path 1",
              "d": 1,
              "p": { "a": 0, "k": [0, 0], "ix": 3 },
              "s": { "a": 0, "k": [399.156, 399.156], "ix": 2 }
            },
            {
              "ty": "st",
              "bm": 0,
              "cl": "",
              "ln": "",
              "hd": true,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 1,
              "lj": 1,
              "ml": 4,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 2, "ix": 5 },
              "d": [],
              "c": { "a": 0, "k": [1, 1, 1], "ix": 3 }
            },
            {
              "ty": "fl",
              "bm": 0,
              "cl": "",
              "ln": "",
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.9098, 0.9098, 0.9137], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [9.578, 23.578], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        }
      ],
      "ind": 1
    },
    {
      "ty": 0,
      "nm": "vague Outlines Comp 1",
      "mn": "",
      "sr": 1,
      "st": 0,
      "op": 600.000024438501,
      "ip": 0,
      "hd": false,
      "cl": "",
      "ln": "",
      "ddd": 0,
      "bm": 0,
      "tt": 1,
      "hasMask": false,
      "ao": 0,
      "ks": {
        "a": { "a": 0, "k": [-0.046, 6.667, 0], "ix": 1 },
        "s": { "a": 0, "k": [896, 510, 100], "ix": 6 },
        "sk": { "a": 0, "k": 0 },
        "p": { "a": 0, "k": [-233.75, 258, 0], "ix": 2 },
        "sa": { "a": 0, "k": 0 },
        "o": { "a": 0, "k": 30, "ix": 11 },
        "r": { "a": 0, "k": 0, "ix": 10 }
      },
      "ef": [],
      "w": 480,
      "h": 480,
      "refId": "comp_0",
      "ind": 2
    },
    {
      "ty": 0,
      "nm": "bateau Comp 1",
      "mn": "",
      "sr": 1,
      "st": 0,
      "op": 600.000024438501,
      "ip": 0,
      "hd": false,
      "cl": "",
      "ln": "",
      "ddd": 0,
      "bm": 0,
      "hasMask": false,
      "ao": 0,
      "ks": {
        "a": { "a": 0, "k": [240, 240, 0], "ix": 1 },
        "s": { "a": 0, "k": [100, 100, 100], "ix": 6 },
        "sk": { "a": 0, "k": 0 },
        "p": {
          "a": 1,
          "k": [
            {
              "o": { "x": 0.333, "y": 0 },
              "i": { "x": 0.667, "y": 1 },
              "s": [227, 257, 0],
              "t": 0
            },
            {
              "o": { "x": 0.333, "y": 0 },
              "i": { "x": 0.667, "y": 1 },
              "s": [227, 221, 0],
              "t": 15
            },
            {
              "o": { "x": 0.333, "y": 0 },
              "i": { "x": 0.667, "y": 1 },
              "s": [227, 257, 0],
              "t": 32
            },
            {
              "o": { "x": 0.333, "y": 0 },
              "i": { "x": 0.667, "y": 1 },
              "s": [227, 221, 0],
              "t": 47
            },
            {
              "o": { "x": 0.333, "y": 0 },
              "i": { "x": 0.667, "y": 1 },
              "s": [227, 257, 0],
              "t": 62
            },
            {
              "o": { "x": 0.333, "y": 0 },
              "i": { "x": 0.667, "y": 1 },
              "s": [227, 221, 0],
              "t": 77
            },
            {
              "o": { "x": 0.333, "y": 0 },
              "i": { "x": 0.667, "y": 1 },
              "s": [227, 257, 0],
              "t": 91
            },
            {
              "o": { "x": 0.333, "y": 0 },
              "i": { "x": 0.667, "y": 1 },
              "s": [227, 221, 0],
              "t": 107
            },
            {
              "o": { "x": 0.333, "y": 0 },
              "i": { "x": 0.667, "y": 1 },
              "s": [227, 257, 0],
              "t": 122
            },
            {
              "o": { "x": 0.333, "y": 0 },
              "i": { "x": 0.667, "y": 1 },
              "s": [227, 221, 0],
              "t": 137
            },
            {
              "o": { "x": 0.333, "y": 0 },
              "i": { "x": 0.667, "y": 1 },
              "s": [227, 257, 0],
              "t": 151
            },
            {
              "o": { "x": 0.333, "y": 0 },
              "i": { "x": 0.667, "y": 1 },
              "s": [227, 221, 0],
              "t": 167
            },
            {
              "o": { "x": 0.167, "y": 0.167 },
              "i": { "x": 0.833, "y": 0.833 },
              "s": [227, 257, 0],
              "t": 180.00000733155
            }
          ],
          "ix": 2
        },
        "sa": { "a": 0, "k": 0 },
        "o": { "a": 0, "k": 100, "ix": 11 },
        "r": {
          "a": 1,
          "k": [
            { "o": { "x": 0.333, "y": 0 }, "i": { "x": 0.667, "y": 1 }, "s": [-14], "t": 0 },
            { "o": { "x": 0.333, "y": 0 }, "i": { "x": 0.667, "y": 1 }, "s": [18.5], "t": 18 },
            { "o": { "x": 0.333, "y": 0 }, "i": { "x": 0.667, "y": 1 }, "s": [-14], "t": 40 },
            { "o": { "x": 0.333, "y": 0 }, "i": { "x": 0.667, "y": 1 }, "s": [18.5], "t": 62 },
            { "o": { "x": 0.333, "y": 0 }, "i": { "x": 0.667, "y": 1 }, "s": [-14], "t": 80 },
            { "o": { "x": 0.333, "y": 0 }, "i": { "x": 0.667, "y": 1 }, "s": [18.5], "t": 101 },
            { "o": { "x": 0.333, "y": 0 }, "i": { "x": 0.667, "y": 1 }, "s": [-14], "t": 127 },
            { "o": { "x": 0.333, "y": 0 }, "i": { "x": 0.667, "y": 1 }, "s": [18.5], "t": 154 },
            {
              "o": { "x": 0.167, "y": 0.167 },
              "i": { "x": 0.833, "y": 0.833 },
              "s": [-14],
              "t": 180.00000733155
            }
          ],
          "ix": 10
        }
      },
      "ef": [],
      "w": 480,
      "h": 480,
      "refId": "comp_1",
      "ind": 3
    },
    {
      "ty": 4,
      "nm": "Shape Layer 3",
      "mn": "",
      "sr": 1,
      "st": 0,
      "op": 600.000024438501,
      "ip": 0,
      "hd": false,
      "cl": "",
      "ln": "",
      "ddd": 0,
      "bm": 0,
      "hasMask": false,
      "td": 1,
      "ao": 0,
      "ks": {
        "a": { "a": 0, "k": [9.578, 23.578, 0], "ix": 1 },
        "s": { "a": 0, "k": [100, 100, 100], "ix": 6 },
        "sk": { "a": 0, "k": 0 },
        "p": { "a": 0, "k": [240, 240, 0], "ix": 2 },
        "sa": { "a": 0, "k": 0 },
        "o": { "a": 0, "k": 100, "ix": 11 },
        "r": { "a": 0, "k": 0, "ix": 10 }
      },
      "ef": [],
      "shapes": [
        {
          "ty": "gr",
          "bm": 0,
          "cl": "",
          "ln": "",
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Ellipse 1",
          "ix": 1,
          "cix": 2,
          "np": 3,
          "it": [
            {
              "ty": "el",
              "bm": 0,
              "cl": "",
              "ln": "",
              "hd": false,
              "mn": "ADBE Vector Shape - Ellipse",
              "nm": "Ellipse Path 1",
              "d": 1,
              "p": { "a": 0, "k": [0, 0], "ix": 3 },
              "s": { "a": 0, "k": [399.156, 399.156], "ix": 2 }
            },
            {
              "ty": "st",
              "bm": 0,
              "cl": "",
              "ln": "",
              "hd": true,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 1,
              "lj": 1,
              "ml": 4,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 2, "ix": 5 },
              "d": [],
              "c": { "a": 0, "k": [1, 1, 1], "ix": 3 }
            },
            {
              "ty": "fl",
              "bm": 0,
              "cl": "",
              "ln": "",
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.9098, 0.9098, 0.9137], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [9.578, 23.578], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        }
      ],
      "ind": 4
    },
    {
      "ty": 0,
      "nm": "vague Outlines Comp 1",
      "mn": "",
      "sr": 1,
      "st": 0,
      "op": 600.000024438501,
      "ip": 0,
      "hd": false,
      "cl": "",
      "ln": "",
      "ddd": 0,
      "bm": 0,
      "tt": 1,
      "hasMask": false,
      "ao": 0,
      "ks": {
        "a": { "a": 0, "k": [-0.046, 6.667, 0], "ix": 1 },
        "s": { "a": 0, "k": [600, 300, 100], "ix": 6 },
        "sk": { "a": 0, "k": 0 },
        "p": { "a": 0, "k": [28.25, 258, 0], "ix": 2 },
        "sa": { "a": 0, "k": 0 },
        "o": { "a": 0, "k": 30, "ix": 11 },
        "r": { "a": 0, "k": 0, "ix": 10 }
      },
      "ef": [],
      "w": 480,
      "h": 480,
      "refId": "comp_0",
      "ind": 5
    },
    {
      "ty": 4,
      "nm": "Shape Layer 2",
      "mn": "",
      "sr": 1,
      "st": 0,
      "op": 600.000024438501,
      "ip": 0,
      "hd": false,
      "cl": "",
      "ln": "",
      "ddd": 0,
      "bm": 0,
      "hasMask": false,
      "ao": 0,
      "ks": {
        "a": { "a": 0, "k": [9.578, 23.578, 0], "ix": 1 },
        "s": { "a": 0, "k": [100, 100, 100], "ix": 6 },
        "sk": { "a": 0, "k": 0 },
        "p": { "a": 0, "k": [240, 240, 0], "ix": 2 },
        "sa": { "a": 0, "k": 0 },
        "o": { "a": 0, "k": 100, "ix": 11 },
        "r": { "a": 0, "k": 0, "ix": 10 }
      },
      "ef": [],
      "shapes": [
        {
          "ty": "gr",
          "bm": 0,
          "cl": "",
          "ln": "",
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Ellipse 1",
          "ix": 1,
          "cix": 2,
          "np": 3,
          "it": [
            {
              "ty": "el",
              "bm": 0,
              "cl": "",
              "ln": "",
              "hd": false,
              "mn": "ADBE Vector Shape - Ellipse",
              "nm": "Ellipse Path 1",
              "d": 1,
              "p": { "a": 0, "k": [0, 0], "ix": 3 },
              "s": { "a": 0, "k": [399.156, 399.156], "ix": 2 }
            },
            {
              "ty": "st",
              "bm": 0,
              "cl": "",
              "ln": "",
              "hd": true,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 1,
              "lj": 1,
              "ml": 4,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 2, "ix": 5 },
              "d": [],
              "c": { "a": 0, "k": [1, 1, 1], "ix": 3 }
            },
            {
              "ty": "fl",
              "bm": 0,
              "cl": "",
              "ln": "",
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.9098, 0.9098, 0.9137], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [9.578, 23.578], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        }
      ],
      "ind": 6
    }
  ],
  "ddd": 0,
  "h": 480,
  "w": 480,
  "meta": { "a": "", "k": "", "d": "", "g": "@lottiefiles/toolkit-js 0.21.3", "tc": "#000000" },
  "v": "5.3.4",
  "fr": 29.9700012207031,
  "op": 180.00000733155,
  "ip": 0,
  "assets": [
    {
      "nm": "",
      "mn": "",
      "layers": [
        {
          "ty": 4,
          "nm": "vague Outlines",
          "mn": "",
          "sr": 1,
          "st": 0,
          "op": 600.000024438501,
          "ip": 0,
          "hd": false,
          "cl": "",
          "ln": "",
          "ddd": 0,
          "bm": 0,
          "hasMask": false,
          "ao": 0,
          "ks": {
            "a": { "a": 0, "k": [408, 94.639, 0], "ix": 1 },
            "s": { "a": 0, "k": [100, 100, 100], "ix": 6 },
            "sk": { "a": 0, "k": 0 },
            "p": {
              "a": 1,
              "k": [
                {
                  "o": { "x": 0.167, "y": 0.167 },
                  "i": { "x": 0.833, "y": 0.833 },
                  "s": [248, 10.361, 0],
                  "t": 0
                },
                {
                  "o": { "x": 0.167, "y": 0.167 },
                  "i": { "x": 0.833, "y": 0.833 },
                  "s": [-232, 10.361, 0],
                  "t": 180.00000733155
                }
              ],
              "ix": 2
            },
            "sa": { "a": 0, "k": 0 },
            "o": { "a": 0, "k": 100, "ix": 11 },
            "r": { "a": 0, "k": 0, "ix": 10 }
          },
          "ef": [],
          "shapes": [
            {
              "ty": "gr",
              "bm": 0,
              "cl": "",
              "ln": "",
              "hd": false,
              "mn": "ADBE Vector Group",
              "nm": "Group 2",
              "ix": 1,
              "cix": 2,
              "np": 3,
              "it": [
                {
                  "ty": "sh",
                  "bm": 0,
                  "cl": "",
                  "ln": "",
                  "hd": false,
                  "mn": "ADBE Vector Shape - Group",
                  "nm": "Path 1",
                  "ix": 1,
                  "d": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "c": true,
                      "i": [
                        [0, 0],
                        [-12.59, -6.295],
                        [-12.59, 6.295],
                        [-12.59, -6.295],
                        [-12.59, 6.295],
                        [-12.59, -6.295],
                        [-12.59, 6.295],
                        [-12.59, -6.295],
                        [-12.59, 6.295],
                        [-12.59, -6.295],
                        [-12.59, 6.295],
                        [-12.59, -6.295],
                        [-12.59, 6.295],
                        [0, 0],
                        [0, 0]
                      ],
                      "o": [
                        [12.59, -6.295],
                        [12.59, 6.295],
                        [12.59, -6.295],
                        [12.59, 6.295],
                        [12.59, -6.295],
                        [12.59, 6.295],
                        [12.59, -6.295],
                        [12.59, 6.295],
                        [12.59, -6.295],
                        [12.59, 6.295],
                        [12.59, -6.295],
                        [12.59, 6.295],
                        [0, 0],
                        [0, 0],
                        [0, 0]
                      ],
                      "v": [
                        [-240, -349.353],
                        [-200, -349.353],
                        [-160, -349.353],
                        [-120, -349.353],
                        [-80, -349.353],
                        [-40, -349.353],
                        [0, -349.353],
                        [40, -349.353],
                        [80, -349.353],
                        [120, -349.353],
                        [160, -349.353],
                        [200, -349.353],
                        [240, -349.353],
                        [240, 550.647],
                        [-240, 550.647]
                      ]
                    },
                    "ix": 2
                  }
                },
                {
                  "ty": "st",
                  "bm": 0,
                  "cl": "",
                  "ln": "",
                  "hd": false,
                  "mn": "ADBE Vector Graphic - Stroke",
                  "nm": "Stroke 1",
                  "lc": 1,
                  "lj": 1,
                  "ml": 4,
                  "o": { "a": 0, "k": 100, "ix": 4 },
                  "w": { "a": 0, "k": 2, "ix": 5 },
                  "d": [],
                  "c": { "a": 0, "k": [0.4314, 0.4353, 0.9255], "ix": 3 }
                },
                {
                  "ty": "fl",
                  "bm": 0,
                  "cl": "",
                  "ln": "",
                  "hd": false,
                  "mn": "ADBE Vector Graphic - Fill",
                  "nm": "Fill 1",
                  "c": { "a": 0, "k": [0.4314, 0.4353, 0.9255], "ix": 4 },
                  "r": 1,
                  "o": { "a": 0, "k": 100, "ix": 5 }
                },
                {
                  "ty": "tr",
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "p": { "a": 0, "k": [880, 444.353], "ix": 2 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "o": { "a": 0, "k": 100, "ix": 7 }
                }
              ]
            },
            {
              "ty": "gr",
              "bm": 0,
              "cl": "",
              "ln": "",
              "hd": false,
              "mn": "ADBE Vector Group",
              "nm": "Group 1",
              "ix": 2,
              "cix": 2,
              "np": 3,
              "it": [
                {
                  "ty": "sh",
                  "bm": 0,
                  "cl": "",
                  "ln": "",
                  "hd": false,
                  "mn": "ADBE Vector Shape - Group",
                  "nm": "Path 1",
                  "ix": 1,
                  "d": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "c": true,
                      "i": [
                        [0, 0],
                        [-12.59, -6.295],
                        [-12.59, 6.295],
                        [-12.59, -6.295],
                        [-12.59, 6.295],
                        [-12.59, -6.295],
                        [-12.59, 6.295],
                        [-12.59, -6.295],
                        [-12.59, 6.295],
                        [-12.59, -6.295],
                        [-12.59, 6.295],
                        [-12.59, -6.295],
                        [-12.59, 6.295],
                        [0, 0],
                        [0, 0]
                      ],
                      "o": [
                        [12.59, -6.295],
                        [12.59, 6.295],
                        [12.59, -6.295],
                        [12.59, 6.295],
                        [12.59, -6.295],
                        [12.59, 6.295],
                        [12.59, -6.295],
                        [12.59, 6.295],
                        [12.59, -6.295],
                        [12.59, 6.295],
                        [12.59, -6.295],
                        [12.59, 6.295],
                        [0, 0],
                        [0, 0],
                        [0, 0]
                      ],
                      "v": [
                        [-240, -349.353],
                        [-200, -349.353],
                        [-160, -349.353],
                        [-120, -349.353],
                        [-80, -349.353],
                        [-40, -349.353],
                        [0, -349.353],
                        [40, -349.353],
                        [80, -349.353],
                        [120, -349.353],
                        [160, -349.353],
                        [200, -349.353],
                        [240, -349.353],
                        [240, 550.647],
                        [-240, 550.647]
                      ]
                    },
                    "ix": 2
                  }
                },
                {
                  "ty": "fl",
                  "bm": 0,
                  "cl": "",
                  "ln": "",
                  "hd": false,
                  "mn": "ADBE Vector Graphic - Fill",
                  "nm": "Fill 1",
                  "c": { "a": 0, "k": [0.4314, 0.4353, 0.9255], "ix": 4 },
                  "r": 1,
                  "o": { "a": 0, "k": 100, "ix": 5 }
                },
                {
                  "ty": "st",
                  "bm": 0,
                  "cl": "",
                  "ln": "",
                  "hd": false,
                  "mn": "ADBE Vector Graphic - Stroke",
                  "nm": "Stroke 1",
                  "lc": 1,
                  "lj": 1,
                  "ml": 4,
                  "o": { "a": 0, "k": 100, "ix": 4 },
                  "w": { "a": 0, "k": 2, "ix": 5 },
                  "d": [],
                  "c": { "a": 0, "k": [0.4314, 0.4353, 0.9255], "ix": 3 }
                },
                {
                  "ty": "tr",
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "p": { "a": 0, "k": [400, 444.353], "ix": 2 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "o": { "a": 0, "k": 100, "ix": 7 }
                }
              ]
            }
          ],
          "ind": 1
        }
      ],
      "id": "comp_0",
      "fr": 30
    },
    {
      "nm": "",
      "mn": "",
      "layers": [
        {
          "ty": 4,
          "nm": "Shape Layer 1",
          "mn": "",
          "sr": 1,
          "st": 0,
          "op": 600.000024438501,
          "ip": 0,
          "hd": false,
          "cl": "",
          "ln": "",
          "ddd": 0,
          "bm": 0,
          "hasMask": false,
          "ao": 0,
          "ks": {
            "a": { "a": 0, "k": [0, 0, 0], "ix": 1 },
            "s": { "a": 0, "k": [100, 100, 100], "ix": 6 },
            "sk": { "a": 0, "k": 0 },
            "p": { "a": 0, "k": [240, 240, 0], "ix": 2 },
            "sa": { "a": 0, "k": 0 },
            "o": { "a": 0, "k": 100, "ix": 11 },
            "r": { "a": 0, "k": 0, "ix": 10 }
          },
          "ef": [],
          "shapes": [
            {
              "ty": "gr",
              "bm": 0,
              "cl": "",
              "ln": "",
              "hd": false,
              "mn": "ADBE Vector Group",
              "nm": "Shape 2",
              "ix": 1,
              "cix": 2,
              "np": 3,
              "it": [
                {
                  "ty": "sh",
                  "bm": 0,
                  "cl": "",
                  "ln": "",
                  "hd": false,
                  "mn": "ADBE Vector Shape - Group",
                  "nm": "Path 1",
                  "ix": 1,
                  "d": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "c": true,
                      "i": [
                        [0, 0],
                        [0, 0],
                        [0, 0]
                      ],
                      "o": [
                        [0, 0],
                        [0, 0],
                        [0, 0]
                      ],
                      "v": [
                        [13, -8],
                        [-32.5, 51.5],
                        [81.5, 53]
                      ]
                    },
                    "ix": 2
                  }
                },
                {
                  "ty": "st",
                  "bm": 0,
                  "cl": "",
                  "ln": "",
                  "hd": false,
                  "mn": "ADBE Vector Graphic - Stroke",
                  "nm": "Stroke 1",
                  "lc": 1,
                  "lj": 1,
                  "ml": 4,
                  "o": { "a": 0, "k": 10, "ix": 4 },
                  "w": { "a": 0, "k": 2, "ix": 5 },
                  "d": [],
                  "c": { "a": 0, "k": [0, 0, 0], "ix": 3 }
                },
                {
                  "ty": "fl",
                  "bm": 0,
                  "cl": "",
                  "ln": "",
                  "hd": false,
                  "mn": "ADBE Vector Graphic - Fill",
                  "nm": "Fill 1",
                  "c": { "a": 0, "k": [0.9961, 0.9255, 0.7765], "ix": 4 },
                  "r": 1,
                  "o": { "a": 0, "k": 100, "ix": 5 }
                },
                {
                  "ty": "tr",
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "p": { "a": 0, "k": [0, 0], "ix": 2 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "o": { "a": 0, "k": 100, "ix": 7 }
                }
              ]
            },
            {
              "ty": "gr",
              "bm": 0,
              "cl": "",
              "ln": "",
              "hd": false,
              "mn": "ADBE Vector Group",
              "nm": "Shape 1",
              "ix": 2,
              "cix": 2,
              "np": 3,
              "it": [
                {
                  "ty": "sh",
                  "bm": 0,
                  "cl": "",
                  "ln": "",
                  "hd": false,
                  "mn": "ADBE Vector Shape - Group",
                  "nm": "Path 1",
                  "ix": 1,
                  "d": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "c": true,
                      "i": [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0]
                      ],
                      "o": [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0]
                      ],
                      "v": [
                        [-88.5, -27],
                        [-33.5, 51.5],
                        [80.5, 52.5],
                        [127, -24],
                        [14, -8.5]
                      ]
                    },
                    "ix": 2
                  }
                },
                {
                  "ty": "st",
                  "bm": 0,
                  "cl": "",
                  "ln": "",
                  "hd": false,
                  "mn": "ADBE Vector Graphic - Stroke",
                  "nm": "Stroke 1",
                  "lc": 1,
                  "lj": 1,
                  "ml": 4,
                  "o": { "a": 0, "k": 10, "ix": 4 },
                  "w": { "a": 0, "k": 2, "ix": 5 },
                  "d": [],
                  "c": { "a": 0, "k": [0, 0, 0], "ix": 3 }
                },
                {
                  "ty": "fl",
                  "bm": 0,
                  "cl": "",
                  "ln": "",
                  "hd": false,
                  "mn": "ADBE Vector Graphic - Fill",
                  "nm": "Fill 1",
                  "c": { "a": 0, "k": [0.9882, 0.8549, 0.549], "ix": 4 },
                  "r": 1,
                  "o": { "a": 0, "k": 100, "ix": 5 }
                },
                {
                  "ty": "tr",
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "p": { "a": 0, "k": [0, 0], "ix": 2 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "o": { "a": 0, "k": 100, "ix": 7 }
                }
              ]
            },
            {
              "ty": "gr",
              "bm": 0,
              "cl": "",
              "ln": "",
              "hd": false,
              "mn": "ADBE Vector Group",
              "nm": "Shape 4",
              "ix": 3,
              "cix": 2,
              "np": 3,
              "it": [
                {
                  "ty": "sh",
                  "bm": 0,
                  "cl": "",
                  "ln": "",
                  "hd": false,
                  "mn": "ADBE Vector Shape - Group",
                  "nm": "Path 1",
                  "ix": 1,
                  "d": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "c": true,
                      "i": [
                        [0, 0],
                        [0, 0],
                        [0, 0]
                      ],
                      "o": [
                        [0, 0],
                        [0, 0],
                        [0, 0]
                      ],
                      "v": [
                        [24.5, -68.5],
                        [4.5, 28],
                        [15, 24]
                      ]
                    },
                    "ix": 2
                  }
                },
                {
                  "ty": "st",
                  "bm": 0,
                  "cl": "",
                  "ln": "",
                  "hd": false,
                  "mn": "ADBE Vector Graphic - Stroke",
                  "nm": "Stroke 1",
                  "lc": 1,
                  "lj": 1,
                  "ml": 4,
                  "o": { "a": 0, "k": 10, "ix": 4 },
                  "w": { "a": 0, "k": 2, "ix": 5 },
                  "d": [],
                  "c": { "a": 0, "k": [0, 0, 0], "ix": 3 }
                },
                {
                  "ty": "fl",
                  "bm": 0,
                  "cl": "",
                  "ln": "",
                  "hd": false,
                  "mn": "ADBE Vector Graphic - Fill",
                  "nm": "Fill 1",
                  "c": { "a": 0, "k": [0.9961, 0.9255, 0.7765], "ix": 4 },
                  "r": 1,
                  "o": { "a": 0, "k": 100, "ix": 5 }
                },
                {
                  "ty": "tr",
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "p": { "a": 0, "k": [0, 0], "ix": 2 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "o": { "a": 0, "k": 100, "ix": 7 }
                }
              ]
            },
            {
              "ty": "gr",
              "bm": 0,
              "cl": "",
              "ln": "",
              "hd": false,
              "mn": "ADBE Vector Group",
              "nm": "Shape 3",
              "ix": 4,
              "cix": 2,
              "np": 3,
              "it": [
                {
                  "ty": "sh",
                  "bm": 0,
                  "cl": "",
                  "ln": "",
                  "hd": false,
                  "mn": "ADBE Vector Shape - Group",
                  "nm": "Path 1",
                  "ix": 1,
                  "d": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "c": true,
                      "i": [
                        [0, 0],
                        [0, 0],
                        [0, 0]
                      ],
                      "o": [
                        [0, 0],
                        [0, 0],
                        [0, 0]
                      ],
                      "v": [
                        [24.5, -68.5],
                        [-37.5, 16],
                        [74.5, 17]
                      ]
                    },
                    "ix": 2
                  }
                },
                {
                  "ty": "st",
                  "bm": 0,
                  "cl": "",
                  "ln": "",
                  "hd": false,
                  "mn": "ADBE Vector Graphic - Stroke",
                  "nm": "Stroke 1",
                  "lc": 1,
                  "lj": 1,
                  "ml": 4,
                  "o": { "a": 0, "k": 10, "ix": 4 },
                  "w": { "a": 0, "k": 2, "ix": 5 },
                  "d": [],
                  "c": { "a": 0, "k": [0, 0, 0], "ix": 3 }
                },
                {
                  "ty": "fl",
                  "bm": 0,
                  "cl": "",
                  "ln": "",
                  "hd": false,
                  "mn": "ADBE Vector Graphic - Fill",
                  "nm": "Fill 1",
                  "c": { "a": 0, "k": [0.9882, 0.8549, 0.549], "ix": 4 },
                  "r": 1,
                  "o": { "a": 0, "k": 100, "ix": 5 }
                },
                {
                  "ty": "tr",
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "p": { "a": 0, "k": [0, 0], "ix": 2 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "o": { "a": 0, "k": 100, "ix": 7 }
                }
              ]
            }
          ],
          "ind": 1
        }
      ],
      "id": "comp_1",
      "fr": 30
    }
  ]
}
