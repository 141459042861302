import { ReactNode, useCallback, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import {
  colors,
  Color,
  paddings,
  RequiredDollarPrefix,
  borderRadii,
  boxShadows,
  zIndices,
  Height,
  Width,
  cssIfTruthy,
  cssIfTrue,
} from 'src/styles';
import { useUniqueId } from 'src/modules/UniqueId';
import { findAncestor } from 'src/modules/Dom';
import { IconSize, IconType, Button, DisplayType, Tooltip, ButtonEvent } from 'src/components';
import { StyledButton } from 'src/components/Button';
import { TooltipContainer, TooltipProps } from 'src/components/Tooltip';

type Option = {
  testTag?: string;
  to?: string;
  href?: string;
  newTab?: boolean;
  onClick?: (e: ButtonEvent) => void;
  children: ReactNode;
  disabled?: boolean;
  tooltipProps?: Omit<TooltipProps, 'children' | 'display'>;
  color?: Color;
};
type PropsToPrefix = {
  rightAligned?: boolean;
  direction?: 'up' | 'down';
  mobile?: boolean;
};

type MenuInternalProps = RequiredDollarPrefix<PropsToPrefix>;

const MenuItemContainer = styled.div<MenuInternalProps>`
  position: absolute;
  z-index: ${zIndices.dropdown};
  display: flex;
  flex-direction: column;
  min-width: 100%;
  border-radius: ${({ $mobile }) =>
    $mobile ? `${borderRadii[4]} ${borderRadii[4]} 0 0` : borderRadii[2]};
  background: ${colors.white.hex};
  box-shadow: ${boxShadows.standard};
  overflow: auto;
  ${({ $direction }) => ($direction === 'down' ? 'top: 100%' : 'bottom: 100%')};
  ${({ $mobile }) => cssIfTrue('bottom', '0px', $mobile)}
  ${({ $rightAligned }) => ($rightAligned ? 'right: 0' : 'left: 0')};
`;

const MenuItem = styled.div<{ $disabled?: boolean; $color?: Color }>`
  width: 100%;
  white-space: nowrap;
  padding: ${paddings[2]} ${paddings[4]};

  ${({ $disabled, $color }) =>
    $disabled
      ? css`
          cursor: default;
          color: ${colors.gray5.hex};
        `
      : css`
          &:hover {
            background: ${colors.gray2.hex};
          }
          ${cssIfTruthy('color', colors[$color || 'black'].hex)};
        `}

  ${StyledButton}:first-child &,
  ${TooltipContainer}:first-child & {
    padding-top: ${paddings[2]};
    border-radius: ${borderRadii[2]} ${borderRadii[2]} 0 0;
  }

  ${StyledButton}:last-child &,
  ${TooltipContainer}:last-child & {
    padding-bottom: ${paddings[2]};
    border-radius: 0 0 ${borderRadii[2]} ${borderRadii[2]};
  }
`;

type DropdownMenuProps = {
  testTag?: string;
  options: Array<Option>;
  children?: ReactNode;
  color?: Color;
  noButtonPadding?: boolean;
  displayType?: DisplayType;
  width?: Width;
  height?: Height;
  icon?: IconType;
  iconSize?: IconSize;
  iconColor?: Color;
  iconPosition?: 'left' | 'right';
  inheritBgColor?: boolean;
  mobile?: boolean;
  disabled?: boolean;
} & PropsToPrefix;
const BaseDropdownMenu = styled.div<{ $mobile: boolean }>`
  position: ${({ $mobile }) => ($mobile ? 'inherit' : 'relative')};
  display: inline-flex;
  align-items: center;
`;

export const DropdownMenu = ({
  options,
  children,
  color,
  rightAligned = false,
  noButtonPadding = false,
  direction = 'down',
  displayType,
  icon,
  iconSize,
  iconColor,
  iconPosition,
  width,
  height,
  testTag,
  inheritBgColor = false,
  mobile = false,
  disabled = false,
}: DropdownMenuProps) => {
  const dropdownMenuId = useUniqueId();
  const buttonId = useUniqueId();
  const [opened, setOpened] = useState(false);
  const toggle = useCallback(() => {
    setOpened(!opened);
  }, [opened]);

  useEffect(() => {
    if (!opened) return;

    const listener = (event: MouseEvent) => {
      const elem = event.target as HTMLElement;
      // Check to see if they clicked on the button to open this dropdown.
      if (findAncestor(elem, (e) => e.id === buttonId)) {
        return;
      }

      // Check to see if they clicked on a disabled element within the dropdown
      if (
        findAncestor(elem, (e) => e.id === dropdownMenuId) &&
        findAncestor(elem, (e) => e.hasAttribute('disabled'))
      ) {
        return;
      }

      setOpened(false);
    };
    document.addEventListener('click', listener);

    return () => {
      document.removeEventListener('click', listener);
    };
  }, [buttonId, dropdownMenuId, opened, toggle]);

  return (
    <BaseDropdownMenu
      id={dropdownMenuId}
      onClick={(e) => {
        e.stopPropagation();
      }}
      $mobile={mobile}
    >
      <Button
        color={color}
        paddingOverride={noButtonPadding ? paddings.none : ''}
        display="flex"
        id={buttonId}
        onClick={toggle}
        displayType={displayType}
        width={width}
        height={height}
        icon={icon}
        iconSize={iconSize}
        iconColor={iconColor}
        iconPosition={iconPosition}
        testTag={testTag}
        inheritBgColor={inheritBgColor}
        disabled={disabled}
      >
        {children}
      </Button>
      {opened && (
        <MenuItemContainer $rightAligned={rightAligned} $direction={direction} $mobile={mobile}>
          {options.map((option, i) => {
            const { children, tooltipProps, color, onClick, ...buttonProps } = option;
            const content = (
              <MenuItem $disabled={buttonProps.disabled} $color={color}>
                {children}
              </MenuItem>
            );
            return (
              <Button
                key={i}
                displayType="noStyles"
                display="block"
                onClick={(event: ButtonEvent) => {
                  setOpened(false);
                  if (onClick) {
                    onClick(event);
                  }
                }}
                disabledOpacityOverride="1"
                {...buttonProps}
              >
                {tooltipProps ? (
                  <Tooltip display="block" {...tooltipProps}>
                    {content}
                  </Tooltip>
                ) : (
                  content
                )}
              </Button>
            );
          })}
        </MenuItemContainer>
      )}
    </BaseDropdownMenu>
  );
};
