import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { PdfStoredFile, PdfByPageStoredFile, ReadingTaskDetails } from 'src/models';
import { Flex, Button, Spacer, Divider } from 'src/components';
import { ImmutableList, ImmutableRange, ImmutableMap } from 'src/modules/Immutable';
import styled from 'styled-components';
import { PdfJsWrapper } from './PDFJsWrapper';
import { borderRadii, colors, paddings, standardMeasurements, zIndices } from 'src/styles';
import { clamp } from 'src/modules/Number';
import * as Storage from 'src/modules/Storage';

const MAX_TAB_HINTS = 40;

const PageIndicator = styled.div<{ $tabColor: string }>`
  background-color: ${({ $tabColor }) => $tabColor};
  height: ${standardMeasurements[1]};
  width: 100%;
  margin: ${standardMeasurements[1]};
  border-radius: 10px;
  cursor: pointer;
`;

const ZoomButtons = styled.div<{ $isMobile?: boolean }>`
  display: flex;
  position: absolute;
  bottom: ${({ $isMobile = false }) => ($isMobile ? '100px' : '25px')};
  right: ${({ $isMobile = false }) => ($isMobile ? '0' : '25px')};
  z-index: ${zIndices.raised};
  user-select: none;
`;

const getPages = (
  task: ReadingTaskDetails['task'] | undefined,
  contentFile: PdfStoredFile | PdfByPageStoredFile | undefined | null,
) => {
  if (task?.readingPartType === 'pages') {
    return {
      startPage: task.startPoint.pageNumber + 1,
      endPage: task.endPoint.pageNumber + 1,
    };
  }

  return {
    startPage: 1,
    endPage: contentFile?.pageCount ?? 10000,
  };
};

type PDFReaderProps =
  | {
      readingTaskDetails: ReadingTaskDetails;
      src?: null;
      drawerOpen?: boolean;
      rotation?: number;
      onRotatePDF?: () => void;
      kind?: 'compact' | 'full' | 'thumbnailsOnly';
      currentPage?: number;
      setCurrentPage?: (page: number) => void;
      hideTopBar?: boolean;
    }
  | {
      readingTaskDetails?: null;
      src: string;
      drawerOpen?: boolean;
      rotation?: number;
      onRotatePDF?: () => void;
      kind?: 'compact' | 'full' | 'thumbnailsOnly';
      currentPage?: number;
      setCurrentPage?: (page: number) => void;
      hideTopBar?: boolean;
    };

const UnmemoizedPDFReader = ({
  readingTaskDetails,
  src,
  drawerOpen = false,
  rotation = 0,
  onRotatePDF,
  kind = 'full',
  currentPage: propCurrentPage,
  setCurrentPage: propSetCurrentPage,
  hideTopBar = false,
}: PDFReaderProps) => {
  const contentFile = readingTaskDetails?.contentFile;
  if (
    contentFile &&
    contentFile.type !== 'PdfStoredFile' &&
    contentFile.type !== 'PdfByPageStoredFile'
  ) {
    throw new Error(`Trying to render a non PDF file as a PDF (is ${contentFile.type})`);
  }
  const { startPage, endPage } = getPages(readingTaskDetails?.task, contentFile);
  const [internalCurrentPage, internalSetCurrentPage] = useState(startPage);
  const currentPage = useMemo(
    () => propCurrentPage ?? internalCurrentPage,
    [propCurrentPage, internalCurrentPage],
  );
  const setCurrentPageToUse = useMemo(
    () => propSetCurrentPage ?? internalSetCurrentPage,
    [propSetCurrentPage, internalSetCurrentPage],
  );

  const savedScales = JSON.parse(Storage.getItem('selectedZoomScales') || 'null');
  const scaleMap: ImmutableMap<string, number> = ImmutableMap(savedScales);
  const savedZoomScale = readingTaskDetails && scaleMap.get(readingTaskDetails.task.id);
  const [scale, setScale] = useState(savedZoomScale ?? 1);

  const tabs = useMemo(() => {
    return endPage + 1 - startPage <= MAX_TAB_HINTS && startPage <= endPage
      ? ImmutableRange(startPage, endPage + 1).toList()
      : ImmutableList<number>();
  }, [startPage, endPage]);

  const setCurrentPage = useCallback(
    (page: number) => {
      if (page >= startPage && page <= endPage) {
        setCurrentPageToUse(page);
      }
    },
    [startPage, endPage, setCurrentPageToUse],
  );

  const setZoomScale = (zoomScale: number) => {
    if (readingTaskDetails) {
      const newMap = scaleMap.set(readingTaskDetails.task.id, zoomScale);
      Storage.setItem('selectedZoomScales', JSON.stringify(newMap));
    }
    setScale(zoomScale);
  };

  useEffect(() => {
    if (kind === 'thumbnailsOnly') return;

    const onKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'ArrowRight') {
        setCurrentPage(currentPage + 1);
      } else if (event.key === 'ArrowLeft') {
        setCurrentPage(currentPage - 1);
      }
    };
    document.addEventListener('keydown', onKeyDown);
    return () => {
      document.removeEventListener('keydown', onKeyDown);
    };
  }, [currentPage, kind, setCurrentPage]);

  useEffect(() => {
    if (currentPage < startPage || currentPage > endPage) {
      setCurrentPage(clamp({ value: currentPage, min: startPage, max: endPage }));
    }
  }, [currentPage, setCurrentPage, startPage, endPage]);

  return (
    <Flex direction="column" width={kind === 'thumbnailsOnly' ? 'auto' : '100%'} minHeight={0}>
      <Flex width="100%" padding={`0 ${paddings[5]}`}>
        {hideTopBar || kind === 'thumbnailsOnly' ? null : tabs.size < 2 ? (
          <Divider noMargin />
        ) : (
          tabs.map((tab) => (
            <Button
              displayType="noStyles"
              onClick={() => setCurrentPage(tab)}
              key={tab}
              flexGrow={1}
              testTag={`page-button-${tab}`}
            >
              <PageIndicator
                $tabColor={tab === currentPage ? colors.black.hex : colors.gray4.hex}
              />
            </Button>
          ))
        )}
      </Flex>
      <Flex height="100%" minHeight={0} grow={1} justify="center">
        <PdfJsWrapper
          currentPage={currentPage}
          scale={scale}
          rotation={rotation}
          setCurrentPage={setCurrentPage}
          src={src || contentFile?.url || ''}
          hideThumbnails={false}
          startPage={startPage}
          endPage={endPage}
          drawerOpen={drawerOpen}
          kind={kind}
        />
      </Flex>
      {kind === 'compact' && (
        <ZoomButtons $isMobile>
          <Flex direction="column">
            <Button
              icon="zoomIn"
              iconPosition="right"
              iconSize="2.25em"
              displayType="noStyles"
              disabled={scale >= 5}
              onClick={() => setZoomScale(Math.min(5, scale + 0.1))}
              iconColor="primaryBlue"
              paddingOverride={`${paddings[2]} ${paddings[3]}`}
            ></Button>

            <Button
              icon="zoomOut"
              iconPosition="right"
              iconSize="2.25em"
              displayType="noStyles"
              disabled={scale <= 0.1}
              onClick={() => setZoomScale(Math.max(scale - 0.1, 0.1))}
              iconColor="primaryBlue"
              color="white"
              paddingOverride={`${paddings[2]} ${paddings[3]}`}
            ></Button>
          </Flex>
        </ZoomButtons>
      )}
      {kind === 'full' && (
        <ZoomButtons>
          <Flex
            direction="column"
            padding={paddings[2]}
            backgroundColor={colors.white.hex}
            borderRadius={borderRadii[2]}
          >
            {onRotatePDF && (
              <>
                <Button icon="rotate" displayType="outline" onClick={onRotatePDF}>
                  Rotate
                </Button>
                <Spacer size={2} />
              </>
            )}
            <Button
              icon="zoomIn"
              displayType="outline"
              disabled={scale >= 5}
              onClick={() => setZoomScale(Math.min(5, scale + 0.1))}
            >
              Zoom In
            </Button>
            <Spacer size={2} />
            <Button
              icon="zoomOut"
              displayType="outline"
              disabled={scale <= 0.1}
              onClick={() => setZoomScale(Math.max(scale - 0.1, 0.1))}
            >
              Zoom Out
            </Button>
          </Flex>
        </ZoomButtons>
      )}
    </Flex>
  );
};

export const PDFReader = React.memo(UnmemoizedPDFReader);
