import { ReactNode, useCallback, useState } from 'react';
import { useHistory } from 'src/modules/Router';
import { Button, LmsIcon } from 'src/components';
import { GoogleScopeKey, goToGoogle } from 'src/modules/Google';
import { useFetch } from 'src/modules/Api';
import { PrepareParams } from 'src/models/User';

type GoogleButtonProps = {
  scopeKey?: GoogleScopeKey;
  successUrl?: string;
  children?: ReactNode;
  userAttributes?: PrepareParams;
  forceOauth?: boolean;
};
export const GoogleButton = ({
  scopeKey = 'default',
  children = 'Login with Google',
  successUrl = '/',
  userAttributes = {},
  forceOauth = false,
}: GoogleButtonProps) => {
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const { fetchJson } = useFetch();
  const onClick = useCallback(() => {
    setLoading(true);
    goToGoogle({ scopeKey, successUrl, history, userAttributes, fetchJson, forceOauth });
  }, [scopeKey, successUrl, history, userAttributes, fetchJson, forceOauth]);
  return (
    <Button
      color="black"
      borderColorOverride="gray5"
      displayType="outline"
      onClick={onClick}
      loading={loading}
      width="100%"
    >
      <LmsIcon lms="Google" />
      {children}
    </Button>
  );
};
