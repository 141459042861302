import { memo, MouseEvent, ReactNode, useCallback } from 'react';
import styled from 'styled-components';
import { useQuery, useParams, queryMapToString } from 'src/modules/Router';
import { ImmutableMap } from 'src/modules/Immutable';
import { getSeason, formatDate, Season } from 'src/modules/Time';
import { useStore } from 'src/Store';
import {
  Text,
  Flex,
  Spacer,
  Icon,
  BodyPortal,
  Tooltip,
  LinkButton,
  TimeLabel,
} from 'src/components';
import {
  colors,
  paddings,
  margins,
  borderRadii,
  type RequiredDollarPrefix,
  boxShadows,
} from 'src/styles';
import { formatPercentChange, useDateFilter, labelTime } from './StudentProgressChartHelpers';
import { TooltipContainerProps } from './StudentProgressMonitoringState';

export const CHART_HEIGHT = 240;

export const GraphContainer = styled.div<{
  $width?: string;
}>`
  background: ${colors.white.hex};
  border-radius: ${borderRadii[3]};
  padding: ${paddings[4]};
  width: ${({ $width = '100%' }) => $width};
  margin-bottom: ${margins[2]};
`;

const statChange = (stat: 'wcpm' | 'wpm' | 'time_read', change: number | null) => {
  if (stat === 'time_read') {
    return (change || 0) < 0 ? 'less' : 'more';
  }
  return (change || 0) < 0 ? 'slower' : 'faster';
};

type GraphLabelsProps = {
  label: ReactNode;
  stat: 'wpm' | 'wcpm' | 'time_read';
};
export const GraphLabels = ({ label, stat }: GraphLabelsProps) => {
  const { stats, prevStats, user } = useStore(
    ({ StudentProgressMonitoringData: s }) => ({
      stats: s.stats,
      prevStats: s.prevStats,
      user: s.user,
    }),
    [],
  );
  const { datePrev } = useDateFilter();
  const query = useQuery();

  const current = stats.find((el) => el.name === stat)?.value || 0;
  const prev = prevStats.find((el) => el.name === stat)?.value || 0;
  const percentChange = prev ? (current - prev) / prev : null;
  const prevValue = datePrev && prev ? `Prev. ${(prev || 0).toFixed(1)}` : null;
  const dateLabel = query.get('selectedTime') as TimeLabel;

  return (
    <Flex justify="space-between">
      <div>
        {label}

        <Text variant="h2" testTag={`${stat}-value`}>
          {stat === 'time_read' ? labelTime(current) : `${current.toFixed(1)} Avg`}
        </Text>
      </div>
      <Flex grow={0}>
        {prevValue && (
          <Tooltip
            content={
              datePrev &&
              `${user?.displayName} has read ${formatPercentChange(
                percentChange || 0,
              )} ${statChange(stat, percentChange)} than they did ${
                dateLabel === 'Current Academic Year'
                  ? 'last academic school year.'
                  : `between ${formatDate(datePrev.start)} and ${formatDate(datePrev.end)}.`
              }`
            }
            display="block"
            disabled={!datePrev}
          >
            <Flex direction="column" align="end">
              {percentChange && (
                <Flex align="center">
                  <Text variant="h3" testTag={`${stat}-percent-change`}>
                    {formatPercentChange(percentChange)}
                  </Text>
                  <Spacer size={2} horizontal />
                  <Icon icon={percentChange > 0 ? 'increase' : 'decrease'} strokeWidth={0} />
                </Flex>
              )}
              <Text testTag={`${stat}-prev`}>
                Prev. {stat === 'time_read' ? labelTime(prev) : `${prev.toFixed(1)} Avg`}
              </Text>
            </Flex>
          </Tooltip>
        )}
      </Flex>
    </Flex>
  );
};

const xAlignTransforms = {
  right: `translateX(-100%) translateX(-${paddings[2]})`, // this is weird because of chart.js
  center: `translateX(-50%) translateX(-${paddings[1]})`,
  left: `translateX(${paddings[2]})`,
};
const yAlignTransform = {
  bottom: `translateY(-100%) translateY(-${paddings[1]})`,
  center: `translateY(-50%) translateY(-${paddings[1]})`,
  top: 'translateY(0)',
};

type PrefixedTooltipContainerProps = RequiredDollarPrefix<TooltipContainerProps>;
export const ChartTooltipContainer = styled.div<PrefixedTooltipContainerProps>`
  max-width: 260px;
  max-height: 160px;
  overflow-y: auto;
  position: absolute;
  top: ${(p) => p.$top};
  left: ${(p) => p.$left};
  padding: ${paddings[3]};
  background-color: ${colors.white.hex};
  border-radius: ${borderRadii[2]};
  display: ${(p) => p.$display};
  box-shadow: ${boxShadows.standard};
  transform: ${(p) => `${xAlignTransforms[p.$xAlign]} ${yAlignTransform[p.$yAlign]}`};
`;

export const TooltipContentContainer = styled.div`
  padding: ${paddings[2]};
`;

export type ChartTooltipProps = TooltipContainerProps & {
  tooltipStateSetter: (fn: (initial: TooltipContainerProps) => TooltipContainerProps) => void;
  children: React.ReactNode;
};
const UnmemoizedChartTooltip = ({ tooltipStateSetter, ...props }: ChartTooltipProps) => {
  const mouseLeaveHandler = useCallback(
    (ev: MouseEvent) => {
      const relatedTarget = ev.relatedTarget as HTMLElement;
      if (!relatedTarget?.matches?.('canvas')) {
        tooltipStateSetter((tt) => ({ ...tt, display: 'none' }));
      }
    },
    [tooltipStateSetter],
  );
  return (
    <BodyPortal>
      <ChartTooltipContainer
        $top={props.top}
        $left={props.left}
        $display={props.display}
        $xAlign={props.xAlign}
        $yAlign={props.yAlign}
        onMouseLeave={mouseLeaveHandler}
      >
        {props.children}
      </ChartTooltipContainer>
    </BodyPortal>
  );
};

export const ChartTooltip = memo(UnmemoizedChartTooltip);

export const TooltipSubmissionLink = ({ submissionId }: { submissionId: string }) => {
  const userId = useParams<{ userId: string }>().userId;
  const query = useQuery();

  return (
    <span style={{ pointerEvents: 'auto' }}>
      <LinkButton
        to={`/submissions/${submissionId}${queryMapToString(
          ImmutableMap({
            backPath: `/t/progress-monitoring/${userId}${queryMapToString(query)}`,
          }),
        )}`}
      >
        View Submission <Icon icon="arrowRight" color="readleeBlue" />
      </LinkButton>
    </span>
  );
};

export const seasonEndDates = { fall: 'November 30', winter: 'February 28', spring: 'July 23' };

// refer to https://teach.mapnwea.org/impl/maphelp/Content/ReadFluency/InterpretiveTables.htm#Interpreting_the_WCPM
type Ranges = Record<Season, { min: number; max: number }>;
const wcpmStandards: Array<false | Ranges> = [
  false,
  { fall: { min: 0, max: 8 }, winter: { min: 29, max: 58 }, spring: { min: 60, max: 90 } },
  { fall: { min: 50, max: 83 }, winter: { min: 84, max: 108 }, spring: { min: 100, max: 123 } },
  { fall: { min: 83, max: 103 }, winter: { min: 97, max: 136 }, spring: { min: 112, max: 138 } },
  { fall: { min: 94, max: 124 }, winter: { min: 120, max: 142 }, spring: { min: 133, max: 159 } },
  { fall: { min: 121, max: 140 }, winter: { min: 133, max: 170 }, spring: { min: 146, max: 170 } },
];

export const getRange = (gradeLevel: number, seasonOpt?: Season) => {
  const sample = wcpmStandards[gradeLevel];
  if (sample) {
    const season = seasonOpt ?? getSeason();
    return sample[season];
  } else {
    return {
      min: 146,
    };
  }
};

export const getRangeText = (gradeLevel: number, seasonOpt?: Season) => {
  const range = getRange(gradeLevel, seasonOpt);
  return `${range.min}${'max' in range ? `-${range.max}` : ''}+`;
};

type ChartLabelProps = { label: string; tooltip?: string; testTag: string };

export const UnmemoizedChartLabel = ({ label, tooltip, testTag }: ChartLabelProps) => (
  <Tooltip content={tooltip} lineHeight="130%" maxWidthOverride={450}>
    <Text variant="h3" testTag={`${testTag}-label`}>
      <Flex align="center">
        <span>{label}</span>
        <Spacer horizontal /> <Icon icon="info" />
      </Flex>
    </Text>
  </Tooltip>
);

export const ChartLabel = memo(UnmemoizedChartLabel);
