import { subDataCreator } from 'src/State';
import { ErrorMapResponse, emptyErrorMap } from 'src/modules/Api';
import { defaultState, emptyNewUser } from './TeacherStudentListState';
import { ImmutableList, ImmutableMap } from 'src/modules/Immutable';
import { User, Registration, Course } from 'src/models';

export type TeacherStudentListResponse = {
  students: ImmutableList<User>;
  registrationMap: ImmutableMap<string, ImmutableList<Registration>>;
  courseMap: ImmutableMap<string, Course>;
};

type UserUpdateResponse = {
  success: boolean;
  errors: ErrorMapResponse;
};

type CreateStudentResponse = {
  success: boolean;
  errors: ErrorMapResponse;
  user: User;
};

export const createDefaultTeacherStudentListData = subDataCreator(
  'TeacherStudentListData',
  defaultState,
  ({ get, set, getFull, setField, fetchJson }) => ({
    setField,
    load: () => {
      set(defaultState);
      fetchJson(`/api/students`, {
        onSuccess: (data: TeacherStudentListResponse) => {
          set({
            loading: false,
            students: data.students,
            registrationMap: data.registrationMap,
            courseMap: data.courseMap,
          });
        },
      });
    },
    loadChangePasswordModal: () =>
      set({ passwordHidden: true, newPassword: '', passwordConfirmation: '' }),
    loadNewStudentModal: () => {
      set({ newUser: emptyNewUser() });
      get().generatePassword({ newUser: true })();
    },
    loadEditStudentModal: (student: User | undefined) =>
      set({
        tempName: student?.name || '',
        tempEmail: student?.email || student?.username || '',
        errors: emptyErrorMap(),
      }),
    loadManageStudentCoursesModal: (student) => {
      if (!student) return;

      const { courseMap, registrationMap } = get();
      const courseRegistrations = courseMap
        .map(({ id }) => id)
        .filter((courseId) =>
          registrationMap
            .get(student.id)
            ?.some(
              (registration) =>
                registration.status === 'active' && registration.courseId === courseId,
            ),
        )
        .toSet();

      set({ assignedMap: courseRegistrations });
    },
    updateStudent: ({ selectedStudent, closeModal, createToast }) => {
      if (!selectedStudent) return;
      const { tempName, tempEmail } = get();
      const splitName = tempName.split(' ');
      const givenName = splitName[0] || '';
      const familyName = splitName.slice(1).join(' ');
      const data = selectedStudent.email
        ? { name: tempName, email: tempEmail, givenName: givenName, familyName: familyName }
        : { name: tempName, username: tempEmail, givenName: givenName, familyName: familyName };

      fetchJson(`/api/users/${selectedStudent.id}`, {
        method: 'PATCH',
        data: data,
        onSuccess: ({ success, errors }: UserUpdateResponse) => {
          if (success) {
            createToast({ children: 'Student updated!', duration: 2000 });
            const updatedStudent = selectedStudent.email
              ? {
                  ...selectedStudent,
                  email: tempEmail,
                  name: tempName,
                  givenName: givenName,
                  familyName: familyName,
                }
              : {
                  ...selectedStudent,
                  username: tempEmail,
                  name: tempName,
                  givenName: givenName,
                  familyName: familyName,
                };

            set((state) => ({
              students: state.students
                .filter((student) => student.id !== updatedStudent.id)
                .push(updatedStudent),
            }));
            closeModal();
          } else {
            set({ errors: ImmutableMap(errors) });
            createToast({ children: 'Student failed to update!', color: 'danger' });
          }
        },
      });
    },
    changePassword: ({ selectedStudent, closeModal, createToast }) => {
      if (!selectedStudent) return;
      const { newPassword, passwordConfirmation } = get();

      let errors = emptyErrorMap();
      if (newPassword.length === 0) {
        errors = errors.set('newPassword', ["can't be blank"]);
      }
      if (passwordConfirmation.length === 0) {
        errors = errors.set('passwordConfirmation', ["can't be blank"]);
      }
      if (!errors.isEmpty()) {
        set({ errors });
        return;
      }

      fetchJson(`/api/users/${selectedStudent.id}`, {
        method: 'PATCH',
        data: { newPassword, passwordConfirmation },
        onSuccess: ({ success, errors }: UserUpdateResponse) => {
          if (success) {
            createToast({ children: 'Password Updated!' });
            set({ newPassword: '', passwordConfirmation: '' });
            closeModal();
          }
          set({ errors: ImmutableMap(errors) });
        },
      });
    },
    updateStudentClasses:
      ({ selectedStudent, closeModal }) =>
      () => {
        if (!selectedStudent) return;
        const { assignedMap, courseMap, load } = get();
        fetchJson(`/api/users/${selectedStudent.id}/bulk_update_registrations`, {
          method: 'PATCH',
          data: {
            registrationUpdates: courseMap
              .reduce(
                (acc, course) => acc.set(course.id, assignedMap.has(course.id)),
                ImmutableMap(),
              )
              .entrySeq(),
          },
          onSuccess: ({ success }: { success: boolean }) => {
            if (success) {
              closeModal();
              load();
              getFull().ProgressMonitoringData.clearCache();
              getFull().StudentProgressMonitoringData.clearCache();
            }
          },
        });
      },
    createStudent: ({ currentQueryString, history }) => {
      set({ creatingNewStudent: true });
      const { newUser } = get();

      fetchJson(`/api/users/create_student`, {
        method: 'POST',
        data: {
          user: {
            name: `${newUser.firstName} ${newUser.lastName}`.trim(),
            newPassword: newUser.password,
            ...(newUser.username.includes('@')
              ? { email: newUser.username }
              : { username: newUser.username }),
          },
        },
        onSuccess: ({ success, errors, user }: CreateStudentResponse) => {
          set({ newStudentErrors: ImmutableMap(errors), creatingNewStudent: false });
          if (success) {
            set((s) => ({ students: s.students.push(user) }));
            history.push(`/t/students/${user.id}/edit-classes${currentQueryString}`);
          }
        },
      });
    },
    setUserField: (field) => (value) =>
      set((state) => {
        return {
          ...state,
          newUser: {
            ...state.newUser,
            [field]: value,
          },
        };
      }),
    generatePassword:
      ({ newUser }) =>
      () =>
        fetchJson(`/api/short_ids/generate`, {
          onSuccess: ({ id }: { id: string }) => {
            if (newUser) {
              get().setUserField('password')(id);
            } else {
              set({ newPassword: id, passwordConfirmation: id });
            }
            set({ passwordHidden: false });
          },
        }),
  }),
);
