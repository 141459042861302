import { History, QueryMap } from 'src/modules/Router';
import { GoogleCourse } from 'src/modules/Google';
import { ImmutableList, ImmutableMap, ImmutableSet } from 'src/modules/Immutable';
import { Course } from 'src/models';

export type ImportGoogleCourseResponse = {
  googleCourses: ImmutableList<GoogleCourse>;
  courseMap: ImmutableMap<string, Course>;
  studentCountMap: ImmutableMap<string, number>;
  hasScopes: boolean;
};
export type SubmitImportGoogleCourseResponse = {
  success: boolean;
  courses: ImmutableList<Course>;
};

export type ImportGoogleCourseState = {
  loading: boolean;
  googleCourses: ImmutableList<GoogleCourse>;
  studentCountMap: ImmutableMap<string, number>;
  courseMap: ImmutableMap<string, Course>;
  selectedGoogleCourseIds: ImmutableSet<string>;
  hasScopes: boolean;
  submitting: boolean;
  submit: (history: History, pathname: string, query: QueryMap) => () => void;
  load: () => void;
  setSelected: (googleCourseId: string) => () => void;
  setAll: () => void;
};

export const defaultState = {
  loading: true,
  hasScopes: false,
  submitting: false,
  googleCourses: ImmutableList<GoogleCourse>(),
  selectedGoogleCourseIds: ImmutableSet<string>(),
  courseMap: ImmutableMap<string, Course>(),
  studentCountMap: ImmutableMap<string, number>(),
};
