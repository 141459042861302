import styled, { css } from 'styled-components';
import {
  Flex,
  Spacer,
  Text,
  Button,
  Loading,
  NoButtonThumbnail,
  Tooltip,
  Tag,
} from 'src/components';
import { gradeLevelText } from 'src/models';
import { paddings, borderRadii, zIndices } from 'src/styles';
import { useStore } from 'src/Store';
import { formatNumber } from 'src/modules/Stat';
import { Fragment } from 'react';
import { ReadingTileProps } from './ReadingTile';
import { useHistory, useLocation } from 'react-router-dom';
import { useQuery } from 'src/modules/Router';

const READING_TILE_HEIGHT = '260px';
const READING_TILE_WIDTH = '208px';

const sharedStyles = css`
  height: ${READING_TILE_HEIGHT};
  width: ${READING_TILE_WIDTH};
  border-radius: ${borderRadii[2]};
`;

const TileContainer = styled.div`
  ${sharedStyles}
  position: relative;
`;

const ImageContainer = styled.div`
  position: absolute;
`;

const Description = styled.div`
  ${sharedStyles}
  opacity: 0;
  transition: opacity 0.35s ease-in-out;
  display: flex;
  align-items: end;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.550042) 40.87%,
    #000000 100%
  );
  z-index: ${zIndices.raised};
  position: absolute;

  &:hover {
    opacity: 1;
    transition: opacity 0.25s ease-in-out;
  }
`;

export const StudentReadingTile = ({
  id,
  result,
  testTag,
  existingAssessmentId,
}: ReadingTileProps) => {
  const { gradeLevels, loading, launchStudentLibraryReading } = useStore(
    (s) => ({
      gradeLevels: s.ContentLibraryData.gradeLevels,
      loading: s.ContentLibraryData.loading,
      launchStudentLibraryReading: s.ContentLibraryData.launchStudentLibraryReading,
    }),
    [],
  );

  const history = useHistory();
  const query = useQuery();
  const pathname = useLocation().pathname;

  return (
    <Button
      id={id}
      displayType="noStyles"
      onClick={() =>
        launchStudentLibraryReading(
          result.reading.id,
          history,
          query,
          pathname,
          existingAssessmentId,
        )
      }
      testTag={testTag}
    >
      {loading ? (
        <Loading flex kind="boat" />
      ) : (
        <TileContainer>
          <ImageContainer>
            <NoButtonThumbnail
              src={result.imageStoredFile?.thumbnailUrl ?? undefined}
              testTag="library-image"
              size="extraLarge"
              title={result.reading.name}
            />
          </ImageContainer>
          <Description data-test-tag={`student-reading-tile-${result.reading.id}`}>
            <Flex
              direction="column"
              width={READING_TILE_WIDTH}
              justify="end"
              padding={`${paddings[8]} ${paddings[4]}`}
            >
              <Text variant="h5" color="white" title={result.reading.name} ellipsis>
                {result.reading.name}
              </Text>
              <Spacer />

              <Text
                variant="h7"
                weightOverride="medium"
                color="white"
                title={result.author?.name || 'Author Unknown'}
                ellipsis
              >
                {result.author?.name || 'Author Unknown'}
              </Text>
              <Spacer size={3} />
              <Flex wrap="wrap">
                {result.tags.slice(0, 2).map((tag, i) => (
                  <Fragment key={i}>
                    <Tag color="gray6">
                      <Text variant="h7" weightOverride="medium" color="white">
                        {tag.name}
                      </Text>
                    </Tag>
                    <Spacer horizontal />
                  </Fragment>
                ))}
                {result.tags.size > 2 && (
                  <Tooltip
                    content={
                      <Flex wrap="wrap" justify="center">
                        {result.tags.slice(2).map((tag, i) => (
                          <Fragment key={i}>
                            <Tag color="gray6">
                              <Text variant="h7" title={tag.name} color="black">
                                {tag.name}
                              </Text>
                            </Tag>
                            <Spacer horizontal />
                          </Fragment>
                        ))}
                      </Flex>
                    }
                  >
                    <Tag color="gray6">
                      <Text variant="h7" weightOverride="medium" color="white">
                        + {result.tags.slice(2).size}
                      </Text>
                    </Tag>
                  </Tooltip>
                )}
              </Flex>
              {!result.tags.isEmpty() && <Spacer size={3} />}
              <Text variant="h7" weightOverride="medium" color="white">
                {formatNumber(result.reading.totalWords)} Words &#183;{' '}
                {gradeLevelText(
                  result.reading.gradeLevelId,
                  result.reading.autoGradeLevelId,
                  gradeLevels,
                )}
              </Text>
            </Flex>
          </Description>
        </TileContainer>
      )}
    </Button>
  );
};
