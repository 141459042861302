import { useMemo, useCallback } from 'react';
import {
  StandardModal,
  Button,
  LinkButton,
  Flex,
  AvatarCard,
  UserAvatar,
  useToaster,
} from 'src/components';
import { formatName, Registration } from 'src/models';
import { ellipsify } from 'src/modules/String';
import { ImmutableList } from 'src/modules/Immutable';
import { useStore } from 'src/Store';
import { queryMapToString, useQuery, useHistory } from 'src/modules/Router';
import { margins } from 'src/styles';

export const EditStudentsModal = () => {
  const {
    editingCourse,
    students,
    registrations,
    unsavedRegistrations,
    toggleRegistration,
    updateClassStudents,
  } = useStore((s) => s.TeacherCourseListData, []);
  const query = useQuery();
  const history = useHistory();
  const { createToast } = useToaster();
  const closeModal = useCallback(
    () => history.push(`/t/classes${queryMapToString(query)}`),
    [history, query],
  );
  const courseRegistrations = useMemo(
    () => registrations.get(editingCourse?.id || '', ImmutableList<Registration>()),
    [editingCourse, registrations],
  );
  if (!editingCourse) return null;

  return (
    <StandardModal
      openType="path"
      header={`${ellipsify(editingCourse.name, 30)} Roster`}
      openedPath="/t/classes/roster"
      close={closeModal}
      footer={
        <Button
          width="100%"
          onClick={() => {
            updateClassStudents(createToast, closeModal);
          }}
        >
          Save Class
        </Button>
      }
    >
      <Flex direction="column" maxHeight="500px" overflowY="auto">
        {students
          .sortBy((student) => formatName(student).toLowerCase())
          .map((s) => {
            const registered = courseRegistrations.some((r) => r.userId === s.id);

            return (
              <Flex key={s.id} justify="space-between" margin={`${margins[2]} ${margins[4]}`}>
                <AvatarCard
                  avatar={
                    <UserAvatar
                      name={s.displayName || ''}
                      backgroundColor={s.avatarColor || '#E4EEEB'}
                      size="large"
                    />
                  }
                  primaryText={formatName(s)}
                  secondaryText={registered && 'Registered'}
                />
                <Flex align="center" height="100%">
                  <LinkButton
                    onClick={() => {
                      toggleRegistration(s.id);
                    }}
                    color="primaryBlue"
                  >
                    {unsavedRegistrations.get(s.id) ? 'Remove' : 'Add'}
                  </LinkButton>
                </Flex>
              </Flex>
            );
          })}
      </Flex>
    </StandardModal>
  );
};
