import styled from 'styled-components';

import {
  colors,
  standardMeasurements,
  margins,
  borderRadii,
  RequiredDollarPrefix,
  Width,
} from 'src/styles';
import { clamp } from 'src/modules/Number';
import { useCallback, useRef, useState, useEffect, MouseEvent as ReactMouseEvent } from 'react';

type PropsToPrefix = { playProgress: number; duration: number; minWidth?: Width };
type PrefixedProps = RequiredDollarPrefix<PropsToPrefix>;
type AudioTrackProps = PropsToPrefix & {
  seek: (location: number) => void;
};

const THUMB_SIZE = 16;

const BaseAudioTrack = styled.div<PrefixedProps>`
  position: relative;
  height: ${standardMeasurements[6]};
  margin: ${margins[1]};
  min-width: ${({ $minWidth }) => $minWidth};
  border-radius: ${borderRadii.rounded};
  cursor: pointer;
  flex-grow: 1;
`;

const UnfilledAudioTrack = styled.div<PrefixedProps>`
  position: relative;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  background: ${colors.gray4.hex};
  height: ${standardMeasurements[1]};
  border-radius: ${borderRadii.rounded};
  cursor: pointer;
`;

const FilledAudioTrack = styled.div<PrefixedProps>`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: ${colors.black.hex};
  height: ${standardMeasurements[1]};
  border-radius: ${borderRadii.rounded};
  width: ${(props) =>
    `${clamp({ value: (100 * props.$playProgress) / props.$duration, min: 0, max: 100 })}%`};
  cursor: pointer;
`;

const ThumbContainer = styled.div`
  position: absolute;
  left: ${THUMB_SIZE / 2}px;
  right: ${THUMB_SIZE / 2}px;
  top: 50%;
`;

const AudioTrackThumb = styled.div<PrefixedProps>`
  position: absolute;
  left: ${(props) =>
    `${clamp({ value: (100 * props.$playProgress) / props.$duration, min: 0, max: 100 })}%`};
  top: 50%;
  transform: translate(-50%, -50%);
  background: ${colors.black.hex};
  border-radius: ${borderRadii.rounded};
  height: ${THUMB_SIZE}px;
  width: ${THUMB_SIZE}px;
  cursor: pointer;
`;

let lastX: number | null = null;
export const AudioTrack = ({
  playProgress,
  duration,
  seek,
  minWidth = '150px',
}: AudioTrackProps) => {
  const [mouseDown, setMouseDown] = useState(false);
  const baseTrackRef = useRef<HTMLDivElement>(null);

  const seekOnTrackFn: React.MouseEventHandler<HTMLDivElement> = (event) => {
    if (!event.currentTarget) return;
    const rect = (event.currentTarget as HTMLDivElement).getBoundingClientRect();
    const x = event.clientX - rect.left;
    seek((x / rect.width) * duration);
  };

  const seekOnTrack = useCallback(seekOnTrackFn, [seek, duration]);

  const startDrag = useCallback((event: ReactMouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    event.stopPropagation();
    setMouseDown(true);
    lastX = event.clientX;
  }, []);

  useEffect(() => {
    const onMouseChange = (event: MouseEvent) => {
      event.stopPropagation();
      if (!baseTrackRef.current) return;

      // If the cursor has moved 10 pixels or more
      const xChangedSignificantly =
        lastX === null || lastX - event.clientX > 10 || lastX - event.clientX < -10;

      // OR the left mouse button has been released
      if (xChangedSignificantly || event.buttons !== 1) {
        lastX = event.clientX;

        const rect = baseTrackRef.current.getBoundingClientRect();
        const x = event.clientX - rect.left;
        seek((x / rect.width) * duration);
      }

      if (event.buttons !== 1) {
        setMouseDown(false);
        detach();
      }
    };

    const detach = () => {
      document.removeEventListener('mousemove', onMouseChange);
      document.removeEventListener('mouseup', onMouseChange);
    };

    if (mouseDown) {
      document.addEventListener('mousemove', onMouseChange);
      document.addEventListener('mouseup', onMouseChange);
    }
    return detach;
  }, [mouseDown, duration, seek]);

  return (
    <BaseAudioTrack
      $duration={duration}
      $playProgress={playProgress}
      onClick={seekOnTrack}
      ref={baseTrackRef}
      $minWidth={minWidth}
    >
      <UnfilledAudioTrack $duration={duration} $playProgress={playProgress} $minWidth={minWidth} />
      <FilledAudioTrack $duration={duration} $playProgress={playProgress} $minWidth={minWidth} />
      <ThumbContainer>
        <AudioTrackThumb
          $duration={duration}
          $playProgress={playProgress}
          $minWidth={minWidth}
          onMouseDown={startDrag}
        />
      </ThumbContainer>
    </BaseAudioTrack>
  );
};
