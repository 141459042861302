import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import { clamp } from 'src/modules/Number';
import { NumberInputProps, StyledInput } from './InputHelpers';

export const NumberInput = ({
  onChange,
  value,
  min,
  max,
  testTag,
  noSpinner,
  integerOnly = false,
  ...basicProps
}: NumberInputProps) => {
  const numberValueRef = useRef(value);
  const [stringValue, setStringValue] = useState(String(value));

  useEffect(() => {
    if (typeof value === 'number' && value !== numberValueRef.current) {
      numberValueRef.current = value;
      setStringValue(String(value));
    }
  }, [value]);

  const realOnChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const baseStringValue = event.target.value;
      const baseNumberValue = integerOnly
        ? parseInt(baseStringValue, 10)
        : parseFloat(baseStringValue);
      const newNumberValue = Number.isNaN(baseNumberValue)
        ? null
        : clamp({ value: baseNumberValue, min, max });
      numberValueRef.current = newNumberValue;
      const newStringValue =
        typeof newNumberValue === 'number' && newNumberValue !== baseNumberValue
          ? String(newNumberValue)
          : baseStringValue;

      setStringValue(newStringValue);
      onChange(numberValueRef.current, newStringValue, event);
    },
    [onChange, min, max, integerOnly],
  );

  return (
    <StyledInput
      {...basicProps}
      $noSpinner={noSpinner}
      type="number"
      value={stringValue}
      onChange={realOnChange}
      onKeyDown={(event) => event.stopPropagation()}
      min={min}
      max={max}
      data-test-tag={testTag}
    />
  );
};
