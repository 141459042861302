import { ErrorMap, emptyErrorMap } from 'src/modules/Api';
import { History } from 'src/modules/Router';
import { SetField } from 'src/State';

export type LoginState = {
  username: string;
  password: string;
  success: boolean;
  errors: ErrorMap;
  submitting: boolean;
  load: () => void;
  submit: (args: { redirectPath?: string | null; courseCode: string; history: History }) => void;
  setField: SetField<LoginState>;
};

export const defaultState = {
  username: '',
  password: '',
  success: false,
  submitting: false,
  errors: emptyErrorMap(),
};
