import { borderRadii, colors, Color, margins, rgba } from 'src/styles';
import styled, { css, keyframes } from 'styled-components';

/* stylelint-disable keyframes-name-pattern -- styled components violates this rule and we can't fix it */
const recording = ({ $color }: { $color: Color }) => keyframes`
  /* stylelint-enable */

  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 ${rgba(colors[$color].hex, 0.7)};
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px  ${rgba(colors[$color].hex, 0)};
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 ${rgba(colors[$color].hex, 0)};
  }
`;

export const RecordingStatus = styled.div<{ $color: Color; $recording: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ $color }) => colors[$color].hex};
  border-radius: ${borderRadii.rounded};
  margin: ${margins[1]};
  height: 20px;
  width: 20px;
  ${({ $recording }) =>
    $recording &&
    css`
      animation-name: ${recording};
      animation-duration: 1.5s;
      animation-iteration-count: infinite;
    `}
`;
