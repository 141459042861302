import styled from 'styled-components';
import {
  Color,
  RequiredDollarPrefix,
  standardMeasurements,
  UserColor,
  ClassColor,
  ColorHexes,
  darken,
  Height,
  Width,
} from 'src/styles';
import { StudentAvatar, Text, Tooltip } from 'src/components';
import { borderRadii, BorderRadius } from 'src/styles/BorderRadius';
import { ImmutableList } from 'src/modules/Immutable';
import { Button, ButtonProps } from 'src/components/Button';
import { User } from 'src/models';

const getRadius = (type: RadiusStyle, size: keyof typeof sizes): BorderRadius => {
  if (type === 'round') return 'rounded';
  if (size === 'normal' || size === 'slightlyLarge') {
    return '2';
  }
  return '4';
};

const sizes = {
  normal: standardMeasurements[8],
  slightlyLarge: standardMeasurements[9],
  large: standardMeasurements[12],
  extraLarge: '90px',
} as const;

type RadiusStyle = 'round' | 'mostlyRound';
export type AvatarSize = keyof typeof sizes;

export type BaseAvatarProps = CommonProps & {
  name: string;
  nameList?: ImmutableList<string>;
  textColor: Color;
  testTag?: string;
  overflow?: boolean;
  disabledTooltip?: boolean;
  user?: User | null;
  showAvatar?: boolean;
  imageUrl?: string | null;
};

type CommonProps = {
  backgroundColor: ClassColor | UserColor | ColorHexes;
  radiusType: RadiusStyle;
  size?: AvatarSize;
};

type ProfileImageProps = {
  height: Height;
  width: Width;
};

type StyleProps = RequiredDollarPrefix<CommonProps>;
const BaseAvatarStyles = styled.div<StyleProps>`
  display: grid;
  align-items: center;
  justify-content: center;

  background: ${({ $backgroundColor }) => $backgroundColor};
  height: ${({ $size }) => sizes[$size]};
  width: ${({ $size }) => sizes[$size]};
  border-radius: ${({ $radiusType, $size }) => borderRadii[getRadius($radiusType, $size)]};
  user-select: none;

  &:hover {
    background: ${({ $backgroundColor }) => darken($backgroundColor, 10)};
  }
`;

export const BaseProfilePicture = styled.img<RequiredDollarPrefix<ProfileImageProps>>`
  height: ${({ $height }) => $height};
  width: ${({ $width }) => $width};
  object-fit: cover;
  border-radius: ${borderRadii.rounded};
  user-select: none;
`;

const getAcronym = (name: string, overflow: boolean) => {
  if (overflow) return `${name}`;

  const [first, ...rest] = name.split(' ').filter(Boolean);

  if (first && rest.length > 0) {
    const firstChar = rest[0]?.charAt(0);
    if (firstChar) {
      return `${first[0]}${firstChar}`.toUpperCase();
    }
  }

  return name.substring(0, 2).toUpperCase();
};

const getTextSize = (size: keyof typeof sizes) => {
  switch (size) {
    case 'extraLarge':
      return 'h1';
    case 'large':
      return 'h5';
    case 'normal':
    default:
      return 'h7';
  }
};

export const BaseAvatar = ({
  name,
  nameList,
  backgroundColor,
  size = 'normal',
  textColor = 'white',
  radiusType,
  disabledTooltip = false,
  overflow = false,
  testTag,
  user,
  showAvatar = false,
  imageUrl = null,
}: BaseAvatarProps) => {
  const textSize = getTextSize(size);
  const formattedNames = nameList?.join(', ');
  return (
    <Tooltip content={overflow ? formattedNames : name} disabled={disabledTooltip}>
      {showAvatar && user ? (
        <StudentAvatar kind="profile" user={user} />
      ) : (
        <BaseAvatarStyles $size={size} $backgroundColor={backgroundColor} $radiusType={radiusType}>
          {imageUrl ? (
            <BaseProfilePicture
              data-test-tag="top-profile-picture"
              $height="30px"
              $width="30px"
              src={imageUrl}
            />
          ) : (
            <Text
              lineHeightOverride="100%"
              color={textColor}
              variant={textSize}
              weightOverride="semibold"
              testTag={testTag}
            >
              {getAcronym(name, overflow)}
            </Text>
          )}
        </BaseAvatarStyles>
      )}
    </Tooltip>
  );
};

type AvatarPlusButtonProps = Partial<ButtonProps> & { size?: AvatarSize; radiusType: RadiusStyle };

export const AvatarPlusButton = ({
  size = 'normal',
  radiusType,
  ...buttonProps
}: AvatarPlusButtonProps) => {
  return (
    <Button
      {...buttonProps}
      icon="plus"
      displayType="iconOnly"
      iconColor="primaryBlue"
      color="lightBlue"
      width={sizes[size]}
      height={sizes[size]}
      borderRadiusOverride={getRadius(radiusType, size)}
    />
  );
};
