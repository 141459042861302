import {
  AudioRecorder,
  Button,
  Flex,
  FormGroup,
  LinkButton,
  Spacer,
  StandardModal,
  Text,
} from 'src/components';
import { useHistory, useQuery } from 'src/modules/Router';
import { useStore } from 'src/Store';
import { borderRadii, colors, paddings } from 'src/styles';
import styled from 'styled-components';

const HeaderTag = styled.div`
  background: ${colors.backgroundDark.hex};
  color: ${colors.white.hex};
  padding: ${paddings[2]};
  border-radius: ${borderRadii[1]};
`;

export const AssignmentInstructions = () => {
  const query = useQuery();
  const history = useHistory();
  const {
    assessment,
    editingInstructions,
    instructionDetails,
    editingWrittenInstructions,
    setField,
    errors,
    saving,
    cancel,
    submit,
    onInstructionsRecordingSequenceStatus,
    isRecording,
  } = useStore(
    ({ AssignmentBuilderData: s, AppData: a }) => ({
      assessment: s.assessment,
      editingInstructions: s.editingInstructions,
      instructionDetails: s.instructionDetails,
      editingWrittenInstructions: s.editingWrittenInstructions,
      setField: s.setField,
      setAssessmentField: s.setAssessmentField,
      errors: s.errors,
      saving: s.saving,
      cancel: s.cancel,
      submit: s.submit,
      onInstructionsRecordingSequenceStatus: s.onInstructionsRecordingSequenceStatus,
      isRecording: a.isRecording,
    }),
    [],
  );
  if (!assessment) return null;

  const getInstructionsText = () => {
    if (assessment.instructions && instructionDetails?.audioFile?.url)
      return 'You added written and recorded instructions';
    else if (assessment.instructions) return 'You added written instructions';
    else if (instructionDetails?.audioFile?.url) return 'You added recorded instructions';
    else return 'No instructions given';
  };

  return (
    <>
      <Flex align="center" justify="space-between">
        <Text variant="h6" textTransform="uppercase">
          Instructions
        </Text>
        <LinkButton
          onClick={() => setField('editingInstructions')(true)}
          color="primaryBlue"
          testTag="startEditInstructions"
        >
          Edit
        </LinkButton>
      </Flex>
      <Text testTag="assessmentInstructions">{getInstructionsText()}</Text>

      {editingInstructions && (
        <StandardModal
          openType="state"
          opened={editingInstructions}
          header="Assignment Instructions"
          close={cancel}
          disableFadeClose
          disableClose={isRecording}
          footer={
            <>
              <Button
                onClick={cancel}
                displayType="link"
                color="primaryBlue"
                disabled={isRecording || saving}
              >
                Cancel
              </Button>
              <Spacer horizontal size={3} />
              <Button
                loading={saving}
                onClick={() => submit({ query, history })}
                disabled={isRecording}
              >
                Save
              </Button>
            </>
          }
        >
          <Flex width="500px" direction="column">
            <Text weightOverride="bold">
              You can add written instructions, recorded instructions, or both to the assignment to
              help guide your students.
            </Text>
            <Spacer size={8} />
            <HeaderTag>
              <Text variant="h5">Written Instructions</Text>
            </HeaderTag>
            <Spacer size={4} />
            <FormGroup
              type="text-area"
              name="instructions"
              label=""
              placeholder="Your students will see any written instructions you put here."
              value={editingWrittenInstructions}
              onChange={setField('editingWrittenInstructions')}
              errors={errors}
              width="100%"
              height="250px"
              disabled={isRecording}
            />
            {instructionDetails?.instructionsRecordingSequence && (
              <>
                <Spacer size={8} />
                <HeaderTag>
                  <Text variant="h5">Recorded Instructions</Text>
                </HeaderTag>
                <Spacer size={4} />

                <Flex align="center" direction="column">
                  <Text color="gray7">
                    Your students will be able to listen to any instructions you record here.
                  </Text>
                  <Spacer size={4} />
                  <Flex align="center">
                    <AudioRecorder
                      recordingSequence={instructionDetails.instructionsRecordingSequence}
                      audioFile={instructionDetails.audioFile}
                      onRecordingSequenceStatus={onInstructionsRecordingSequenceStatus}
                      displayType="desktopSmall"
                    />
                  </Flex>
                </Flex>
              </>
            )}
          </Flex>
        </StandardModal>
      )}
    </>
  );
};
