import { subDataCreator } from 'src/State';
import { ImmutableList } from 'src/modules/Immutable';
import { ErrorDispute, RecordingSequence } from 'src/models';
import { defaultState, LoadResponse } from './SubmissionOverviewState';
import { getReadingTaskSubmissionDetails } from './SubmissionOverviewHelpers';
import { CommentDetails } from 'src/models/Comment';

export const createDefaultSubmissionOverviewData = subDataCreator(
  'SubmissionOverviewData',
  defaultState,
  ({ get, set, setField, fetchJson, getFull }) => ({
    setField,
    setPlayProgress: (value) => {
      set(({ playIndex }) => ({ initialPlayProgress: value, playIndex: playIndex + 1 }));
    },
    load: ({ id, openComments }) => {
      set(defaultState);

      fetchJson(`/api/submissions/${id}`, {
        onSuccess: (data: LoadResponse) => {
          set({ ...data, loading: false });
          if (openComments) get().openCommentModal();
        },
      });
    },
    setCurrentTask: (taskId) => {
      set((state) => ({ ...state, currentTaskId: taskId, drawerExpanded: false }));
    },
    onSubmissionStatus: (data) => {
      set((state) => ({
        submission: data.submission,
        taskSubmissionDetailsMap: data.taskSubmissionDetailsMap,
        textDiffMap: state.textDiffMap.merge(data.textDiffMap),
      }));
    },
    disputeError: (index) => {
      const {
        user,
        submission,
        assessment,
        taskSubmissionDetailsMap,
        errorDisputesMap,
        textDiffMap,
      } = get();
      const readingTaskSubmissionDetails = getReadingTaskSubmissionDetails(
        assessment,
        taskSubmissionDetailsMap,
      );

      if (!(user && readingTaskSubmissionDetails)) return;

      const { task } = readingTaskSubmissionDetails;
      const textDiff = textDiffMap.get(task.id);
      if (!textDiff) return;

      set({
        errorDisputesMap: errorDisputesMap.set(
          task.id,
          errorDisputesMap.get(task.id, ImmutableList<ErrorDispute>()).push({
            index,
            userId: user.id,
            textDiffId: textDiff.id,
          }),
        ),
      });

      fetchJson(`/api/error_disputes`, {
        method: 'POST',
        data: {
          dispute: {
            userId: user.id,
            textDiffId: textDiff.id,
            index,
          },
          submissionId: submission?.id,
        },
      });
    },
    createComment: (comment) => {
      const { submission } = get();
      if (!submission) return;
      set({ savingComment: true });
      fetchJson(`/api/comments`, {
        method: 'POST',
        data: { submissionId: submission.id, comment },
        onSuccess: (data: { comment: CommentDetails; success: boolean }) => {
          set((s) => ({
            savingComment: false,
            comments: s.comments
              .filter((c) => c.comment.id !== data.comment.comment.id)
              .push(data.comment),
            editingCommentType: 'undecided' as const,
            editingWrittenContent: '',
            editingRecordingSequence: null,
          }));
        },
      });
    },
    createReactionComment: (reaction) => {
      const { createComment } = get();
      createComment({ reaction, commentType: 'reaction' });
    },
    createWrittenComment: () => {
      const { createComment, editingWrittenContent } = get();
      createComment({ writtenContent: editingWrittenContent, commentType: 'written' });
    },
    createAudioComment: () => {
      const { createComment, editingRecordingSequence } = get();
      if (!editingRecordingSequence) return;
      createComment({ recordingSequenceId: editingRecordingSequence.id, commentType: 'recorded' });
    },
    deleteComment: (id) => {
      const initialComments = get().comments;
      set((s) => ({ comments: s.comments.filter((c) => c.comment.id !== id) }));
      fetchJson(`/api/comments/${id}`, {
        method: 'DELETE',
        onSuccess: (data: { success: boolean }) => {
          if (!data.success) {
            set({ comments: initialComments });
          }
        },
      });
    },
    createRecordingSequence: () => {
      set({ creatingRecordingSequence: true });
      fetchJson(`/api/recording_sequences`, {
        method: 'POST',
        data: {},
        onSuccess: ({ recordingSequence }: { recordingSequence: RecordingSequence }) => {
          set({
            creatingRecordingSequence: false,
            editingCommentType: 'recorded' as const,
            editingRecordingSequence: recordingSequence,
            editingAudioFile: null,
          });
        },
      });
    },
    onRecordingSequenceStatus: (data) =>
      set({ editingAudioFile: data.audioFile, editingRecordingSequence: data.recordingSequence }),
    openCommentModal: () => {
      set({ commentModalOpened: true });
      const { submission } = get();
      const currentUser = getFull().AppData.currentUser;
      if (!submission || !currentUser || currentUser.id !== submission.userId) return;

      fetchJson(`/api/submissions/${submission.id}/mark_comments_viewed`, {
        method: 'PATCH',
        onSuccess: () => {
          set((s) => ({
            comments: s.comments.map((c) => ({
              ...c,
              comment: { ...c.comment, viewed: true },
            })),
          }));
        },
      });
    },
    upgradeAssessment: () =>
      set((s) => {
        if (!s.assessment) return s;

        return { assessment: { ...s.assessment, isUltimate: true } };
      }),
  }),
);
