import { RecordingSequence } from './RecordingSequence';
import { TranscriptableAudioStoredFile } from './TranscriptableAudioStoredFile';
import { ReadingTaskDetails } from './ReadingTask';

type ReadingTaskSubmissionProcessingStatus =
  | 'waiting'
  | 'queued'
  | 'started'
  | 'counting_words'
  | 'finished';

export type ReadingTaskSubmission = {
  id: string;
  readingTaskId: string;
  submissionId: string;
  recordingSequenceId: string;
  textDiffId: string;
  processingStatus: ReadingTaskSubmissionProcessingStatus;
  totalTime: number;
  readingTime: number;
  emptyTime: number;
  wordAssignedCount: number;
  uniqueWordAssignedCount: number;
  wordSaidCount: number;
  uniqueWordSaidCount: number;
  correctWordSaidCount: number;
  addedWordSaidCount: number;
  missedWordSaidCount: number;
  replacedWordSaidCount: number;
  wordsPerMinute: number;
  wordsCorrectPerMinute: number;
  correctCompletion: number;
  simpleCompletion: number;
  type: 'ReadingTaskSubmission';
  pointsEarned: number;
};

export type ReadingTaskSubmissionDetails = ReadingTaskDetails & {
  taskSubmission: ReadingTaskSubmission;
  recordingSequence: RecordingSequence;
  audioFile: TranscriptableAudioStoredFile | null;
};

export const readingTaskSubmissionDetailsIsErrored = (
  taskSubmissionDetails: ReadingTaskSubmissionDetails,
) => {
  return taskSubmissionDetails.audioFile?.processingStatus === 'errored';
};

export const readingTaskSubmissionDetailsIsProcessing = (
  taskSubmissionDetails: ReadingTaskSubmissionDetails,
) => {
  if (taskSubmissionDetails.recordingSequence.processingStatus === 'waiting') return false;
  if (taskSubmissionDetails.audioFile?.processingStatus === 'errored') return false;

  return (
    taskSubmissionDetails.recordingSequence.processingStatus !== 'finished' ||
    (taskSubmissionDetails.audioFile &&
      taskSubmissionDetails.audioFile.processingStatus !== 'finished') ||
    taskSubmissionDetails.taskSubmission.processingStatus !== 'finished'
  );
};
