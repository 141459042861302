import { useStore } from 'src/Store';
import { useQuery, useHistory, queryMapToString } from 'src/modules/Router';

import {
  Form,
  FormGroup,
  Button,
  Text,
  LinkButton,
  Spacer,
  Alert,
  WordDivider,
  GoogleButton,
  CleverButton,
  Flex,
} from 'src/components';
import { SimpleLayout } from 'src/layouts';
import { useCallback, useEffect } from 'react';
import { paddings } from 'src/styles';
import { isPaperReading, paperLoginPath, paperReadingPath } from 'src/config';

export const Login = () => {
  const query = useQuery();
  const history = useHistory();
  const courseCode = query.get('courseCode') || '';
  const skipRedirect = query.get('skipRedirect') || '';
  const redirectPath = query.get('redirectPath') || '';
  const ssoSuccessUrl = redirectPath || '/';

  useEffect(() => {
    if (isPaperReading && !skipRedirect) {
      if (redirectPath) {
        window.sessionStorage.setItem('postLoginRedirectUri', `${paperReadingPath}${redirectPath}`); // translate to paper's login redirect system if we have a redirect url
      } else {
        window.sessionStorage.removeItem('postLoginRedirectUri');
      }

      window.location.href = paperLoginPath;
    }
  }, [redirectPath, skipRedirect]);

  const { username, password, setField, submit, success, errors, submitting, load } = useStore(
    (state) => state.LoginData,
    [],
  );

  useEffect(() => {
    load();
  }, [load]);

  const onSubmit = useCallback(
    () => submit({ redirectPath, courseCode, history }),
    [redirectPath, courseCode, history, submit],
  );

  if (isPaperReading && !skipRedirect) return null;
  return (
    <SimpleLayout
      footer={
        <Flex width="100%" padding={`${paddings.none} ${paddings[8]}`} align="center">
          <Text color="gray5" variant="h7" weightOverride="medium">
            By proceeding you also agree to our
          </Text>
          <Spacer horizontal />
          <LinkButton sizeOverride={10} href="https://www.readlee.com/terms-of-service">
            Terms of Service
          </LinkButton>
          <Spacer horizontal />
          <Text color="gray5" variant="h7" weightOverride="medium">
            and
          </Text>
          <Spacer horizontal />
          <LinkButton sizeOverride={10} href="https://www.readlee.com/privacy-policy">
            Privacy Policy
          </LinkButton>
        </Flex>
      }
    >
      <Text variant="h2">Log In</Text>
      <Spacer size={4} />
      <Flex>
        <Text>Don&apos;t have an account? </Text>
        <Spacer horizontal />
        <LinkButton
          to={`${courseCode ? '/sign-up/student/class-code' : '/sign-up'}${queryMapToString(
            query,
          )}`}
        >
          Sign up
        </LinkButton>
      </Flex>
      <Spacer size={4} />
      <GoogleButton scopeKey="just_name" successUrl={ssoSuccessUrl} forceOauth />
      <Spacer size={2} />
      <CleverButton successUrl={ssoSuccessUrl} forceOauth />
      <Spacer size={2} />
      <WordDivider color="gray5">or</WordDivider>
      <Spacer size={2} />
      {query.get('justReset') && (
        <Alert>Your password has been reset, please log in with your new password</Alert>
      )}
      <Form onSubmit={onSubmit}>
        <FormGroup
          type="text"
          name="username"
          label="Username or Email"
          errors={errors}
          value={username}
          onChange={setField('username')}
        />
        <Spacer size={2} />
        <FormGroup
          name="password"
          label="Password"
          errors={errors}
          type="password"
          value={password}
          onChange={setField('password')}
        />
        <Spacer />
        <LinkButton to="/forgot-password">Forgot password?</LinkButton>
        <Spacer size={4} />
        <Button type="submit" loading={submitting} width="100%">
          Login
        </Button>
        {success && <Text>Login success!</Text>}
      </Form>
    </SimpleLayout>
  );
};
