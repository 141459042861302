import { SetField } from 'src/State';
import { ImmutableList, ImmutableMap } from 'src/modules/Immutable';
import {
  AssessmentExtended,
  ContentType,
  Course,
  SubmissionExtended,
  User,
  GradeLevel,
} from 'src/models';
import { TimeLabel } from 'src/components';
import { ApiStat } from 'src/modules/Stat';
import { getSeason, Season } from 'src/modules/Time';

export type BaseTooltipSubmissionData = {
  name: string;
  submissionId: string;
  finishedAt: string;
};

export type TooltipContainerProps = {
  top: string;
  left: string;
  display: 'block' | 'none';
  xAlign: 'left' | 'center' | 'right';
  yAlign: 'top' | 'center' | 'bottom';
};

export type TooltipSubmissionData = BaseTooltipSubmissionData & {
  wcpm: number;
  wpm: number;
  value?: number;
  stat?: 'wcpm' | 'wpm';
  readingTime: number;
  totalTime: number;
  contentType: ContentType;
};

export type SubmissionResponse = {
  assessmentId: string;
  submission: SubmissionExtended;
  totalTasksCount: number;
  completedTasksCount: number;
  canView: boolean;
  contentType: ContentType;
  readingName: string;
};

export type StudentProgressMonitoringResponse = {
  courseMap: ImmutableMap<string, Course>;
  user: User;
  stats: ImmutableList<ApiStat>;
  prevStats: ImmutableList<ApiStat>;
  submissionResults: ImmutableList<SubmissionResponse>;
  assessmentMap: ImmutableMap<string, AssessmentExtended>;
  assessmentToCourseMap: ImmutableMap<string, ImmutableList<string>>;
  teacherMap: ImmutableMap<string, User>;
  gradeLevelMap: ImmutableMap<string, GradeLevel>;
};

export type StudentProgressMonitoringState = {
  setField: SetField<StudentProgressMonitoringState>;
  load: (
    id: string,
    start: Date | null,
    endTime: Date | null,
    prevStartTime: Date | null,
    prevEndTime: Date | null,
    dateRange: TimeLabel,
  ) => void;
  clearCache: () => void;
  submissionResults: ImmutableList<SubmissionResponse>;
  courseMap: ImmutableMap<string, Course>;
  stats: ImmutableList<ApiStat>;
  prevStats: ImmutableList<ApiStat>;
  loading: boolean;
  user: User | null;
  assessmentMap: ImmutableMap<string, AssessmentExtended>;
  assessmentToCourseMap: ImmutableMap<string, ImmutableList<string>>;
  gradeLevelMap: ImmutableMap<string, GradeLevel>;
  monitoringCache: ImmutableMap<string, ImmutableMap<TimeLabel, StudentProgressMonitoringResponse>>;
  teacherMap: ImmutableMap<string, User>;
  gradeLevel: number;
  season: Season;
  wcpmTooltipProps: TooltipContainerProps;
  wcpmTooltipData: ImmutableList<TooltipSubmissionData>;
  search: string;
  setWcpmTooltipProps: (fn: (initial: TooltipContainerProps) => TooltipContainerProps) => void;
  setWcpmTooltipData: (data: ImmutableList<TooltipSubmissionData>) => void;
  readingTooltipProps: TooltipContainerProps;
  readingTooltipData: ImmutableList<TooltipSubmissionData>;
  setReadingTooltipProps: (fn: (initial: TooltipContainerProps) => TooltipContainerProps) => void;
  setReadingTooltipData: (data: ImmutableList<TooltipSubmissionData>) => void;
};

export const defaultState = {
  submissionResults: ImmutableList<SubmissionResponse>(),
  courseMap: ImmutableMap<string, Course>(),
  students: ImmutableList(),
  stats: ImmutableList<ApiStat>(),
  prevStats: ImmutableList<ApiStat>(),
  loading: false,
  user: null,
  assessmentMap: ImmutableMap<string, AssessmentExtended>(),
  assessmentToCourseMap: ImmutableMap<string, ImmutableList<string>>(),
  gradeLevelMap: ImmutableMap<string, GradeLevel>(),
  monitoringCache: ImmutableMap<
    string,
    ImmutableMap<TimeLabel, StudentProgressMonitoringResponse>
  >(),
  teacherMap: ImmutableMap<string, User>(),
  gradeLevel: 1,
  season: getSeason(),
  wcpmTooltipProps: {
    top: '0',
    left: '0',
    display: 'none',
    xAlign: 'right',
    yAlign: 'center',
  },
  readingTooltipProps: {
    top: '0',
    left: '0',
    display: 'none',
    xAlign: 'right',
    yAlign: 'center',
  },
  wcpmTooltipData: ImmutableList<TooltipSubmissionData>(),
  readingTooltipData: ImmutableList<TooltipSubmissionData>(),
  search: '',
} as const;
