import { CssColor, Height, Width, cssIfTruthy, cssIfTruthyOrZero, colors } from 'src/styles';
import styled from 'styled-components';

export type FlexDirection = 'column' | 'row' | 'column-reverse' | 'row-reverse';
export type Justify =
  | 'start'
  | 'end'
  | 'center'
  | 'space-between'
  | 'space-around'
  | 'space-evenly';
export type Position =
  | 'static'
  | 'relative'
  | 'absolute'
  | 'fixed'
  | 'sticky'
  | 'inherit'
  | 'initial'
  | 'revert'
  | 'unset';
export type Align = 'stretch' | 'center' | 'start' | 'end' | 'baseline';
export type Overflow = 'auto' | 'hidden' | 'scroll' | 'visible' | 'inherit' | 'initial';
type FlexWrap = 'nowrap' | 'wrap' | 'wrap-reverse' | 'inherit' | 'initial' | 'revert' | 'unset';
export type FlexDisplay = 'flex' | 'inline-flex' | 'inherit';

export type FlexPrefixProps = {
  display?: FlexDisplay;
  grow?: number;
  shrink?: number;
  flex?: number;
  direction?: FlexDirection;
  justify?: Justify;
  align?: Align;
  opacity?: number;
  padding?: string;
  margin?: string;
  backgroundColor?: CssColor;
  borderRadius?: string;
  border?: string;
  minHeight?: Height;
  minWidth?: Width;
  width?: Width;
  maxWidth?: Width;
  height?: Height;
  maxHeight?: Height;
  overflowY?: Overflow;
  overflowX?: Overflow;
  position?: Position;
  wrap?: FlexWrap;
  className?: string;
  /** ref of the div */
  innerRef?: React.RefObject<HTMLDivElement>;
};
type FlexProps = FlexPrefixProps & {
  id?: string;
  /** Contents of flex box */
  children: React.ReactNode;
  /** For e2e testing */
  testTag?: string;
};
export const FlexStyles = styled.div<FlexProps>`
  ${(props) => `
    ${cssIfTruthy('display', props.display, 'flex')}
    ${cssIfTruthyOrZero('flex-grow', props.grow)}
    ${cssIfTruthyOrZero('flex-shrink', props.shrink)}
    ${cssIfTruthyOrZero('flex', props.flex)}
    ${cssIfTruthy('flex-direction', props.direction)}
    ${cssIfTruthy('flex-wrap', props.wrap)}
    ${cssIfTruthy('justify-content', props.justify)}
    ${cssIfTruthy('align-items', props.align)}

    ${cssIfTruthyOrZero('opacity', props.opacity)}
    ${cssIfTruthy('background', props.backgroundColor)}
    ${cssIfTruthy('border-radius', props.borderRadius)}
    ${cssIfTruthy('border', props.border)}
    ${cssIfTruthy('overflow-y', props.overflowY)}
    ${cssIfTruthy('overflow-x', props.overflowX)}
    ${cssIfTruthy('position', props.position)}
    ${cssIfTruthyOrZero('padding', props.padding, 0)}
    ${cssIfTruthyOrZero('margin', props.margin, 0)}

    ${cssIfTruthyOrZero('width', props.width)}
    ${cssIfTruthyOrZero('height', props.height)}
    ${cssIfTruthyOrZero('min-width', props.minWidth)}
    ${cssIfTruthyOrZero('max-width', props.maxWidth)}
    ${cssIfTruthyOrZero('min-height', props.minHeight)}
    ${cssIfTruthyOrZero('max-height', props.maxHeight)}
  `}
`;

const InlineFlexStyles = FlexStyles.withComponent('span');

export const Flex = ({ testTag, innerRef, display = 'flex', ...props }: FlexProps) => {
  const finalProps = {
    ...props,
    'data-test-tag': testTag,
    ref: innerRef,
    display,
  };
  return display.includes('inline') ? (
    <InlineFlexStyles {...finalProps} />
  ) : (
    <FlexStyles {...finalProps} />
  );
};

export type FlexGrowProps = {
  /** Controls how much flex element should grow */
  grow?: number;
  /** Contents */
  children?: React.ReactNode;
};
export const FlexGrow = styled.div<FlexGrowProps>`
  flex-grow: ${(props: FlexGrowProps) => props.grow ?? 1};
`;

export const SplitBackgroundFlex = styled(Flex)`
  background: linear-gradient(${colors.backgroundDark.hex} 50%, ${colors.backgroundLight.hex} 50%);
`;
