import { Fragment, useRef } from 'react';
import styled, { css } from 'styled-components';

import { Button, SelectInput, Flex, Spacer } from 'src/components';
import {
  fontWeights,
  margins,
  colors,
  paddings,
  borderRadii,
  standardMeasurements,
  useBreakpoints,
} from 'src/styles';
import { useHistory, useQuery, updateQuery, useLocation } from 'src/modules/Router';
import { ImmutableList } from 'src/modules/Immutable';

export type TabViewTab = {
  id: string;
  name: string;
  count?: number;
  content?: React.ReactNode;
  to?: string;
  onlyClick?: boolean;
};

type TabViewProps = {
  queryParam: string;
  tabs: ImmutableList<TabViewTab>;
  skeumorphic?: boolean;
  noContent?: boolean;
};

type TabNameProps = {
  $active: boolean;
  $skeumorphic: boolean;
};

const CONTEXT_OFFSET = 50;

const skeumorphicStyles = css<TabNameProps>`
  background: ${({ $active }) => ($active ? colors.backgroundLight.hex : colors.gray4.hex)};
  color: ${({ $active }) => ($active ? colors.primaryBlue.hex : colors.backgroundDark.hex)};
  border-bottom: none;
  border-radius: ${borderRadii[2]} ${borderRadii[2]} 0 0;
  padding: ${paddings[2]} ${paddings[4]};
  max-width: 14em;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: center;
`;

const TabName = styled.div<TabNameProps>`
  font-weight: ${({ $active }) => ($active ? fontWeights.bold : fontWeights.default)};
  margin-right: ${margins[2]};
  display: inline-block;
  color: ${(props) => (props.$active ? colors.black.hex : colors.gray7.hex)};
  border-bottom: ${({ $active }) =>
    $active ? `${standardMeasurements.half} solid ${colors.primaryBlue.hex}` : 'none'};
  ${({ $skeumorphic }) => ($skeumorphic ? skeumorphicStyles : null)}
`;

const shouldScroll = (current: number, target: number, tabsLength: number) =>
  target - current > 0 ? target >= 2 : target <= tabsLength - 2;

const useActiveTab = ({ queryParam, tabs }: Omit<TabViewProps, 'skeumorphic'>) => {
  const query = useQuery();
  const currentTabId = query.get(queryParam);
  const activeTab = currentTabId ? tabs.find((t) => t.id === currentTabId) : tabs.first();
  return activeTab;
};

export const TabViewContent = ({ tabs, queryParam }: TabViewProps) => {
  const activeTab = useActiveTab({ queryParam, tabs });

  return (
    <>
      {tabs
        .filter((tab) => tab.content && tab.id === activeTab?.id)
        .map((tab) => (
          <div key={tab.id}>{tab.content}</div>
        ))}
    </>
  );
};

export const TabView = (props: TabViewProps) => {
  const { tabs, queryParam, skeumorphic = false, noContent = false } = props;
  const query = useQuery();
  const history = useHistory();
  const pathname = useLocation().pathname;
  const containerRef = useRef<HTMLDivElement>(null);
  const activeTab = useActiveTab({ queryParam, tabs });
  const currentIndex = tabs.findIndex((t) => t.id === activeTab?.id);
  const previousTab = currentIndex > 0 ? tabs.get(currentIndex - 1) : null;
  const nextTab = currentIndex < tabs.size - 1 ? tabs.get(currentIndex + 1) : null;

  const moveTab = (targetId: string) => () => {
    const container = containerRef.current;
    const targetIndex = tabs.findIndex((t) => t.id === targetId);
    if (targetIndex === -1) return;
    const tab = tabs.get(targetIndex);

    if (!tab) return;
    if (container && shouldScroll(currentIndex, targetIndex, tabs.size)) {
      const child = container.children[targetIndex];

      if (child instanceof HTMLElement) {
        container.scrollTo({
          left: child.offsetLeft - CONTEXT_OFFSET,
          behavior: 'smooth',
        });
      }
    }
    if (tab.to) {
      history.push(tab.to);
    } else {
      history.push(
        `${pathname}${updateQuery(query, {
          [queryParam]: tab.id,
        })}`,
      );
    }
  };

  const showMobileLayout = useBreakpoints({ smallerThanOrEqualTo: 'mobileLarge' });

  return (
    <>
      {!showMobileLayout ? (
        <Flex
          position="relative"
          align="end"
          backgroundColor={skeumorphic ? colors['backgroundDark'].hex : 'inherit'}
          width="100%"
          minWidth={0}
        >
          <Flex
            margin={`0 ${margins[2]} 0 0`}
            grow={1}
            innerRef={containerRef}
            overflowX="hidden"
            minWidth={0}
          >
            {tabs.map((tab) => (
              <Fragment key={tab.id}>
                {!skeumorphic && <Spacer horizontal size={4} />}
                <Button displayType="noStyles" onClick={moveTab(tab.id)}>
                  <TabName
                    $active={tab.id === activeTab?.id}
                    $skeumorphic={skeumorphic}
                    title={tab.name}
                  >
                    {tab.name}
                    {typeof tab.count === 'number' ? ` (${tab.count})` : ''}
                  </TabName>
                </Button>
              </Fragment>
            ))}
          </Flex>
          {skeumorphic && (
            <Flex margin="6px 6px 6px auto">
              <Button
                icon="arrowLeft"
                iconStrokeWidth={2}
                displayType="iconOnly"
                color="gray4"
                onClick={previousTab ? moveTab(previousTab.id) : undefined}
                disabled={!previousTab || previousTab.onlyClick}
                id="tabBack"
                testTag="back-tab-button"
                height="28px"
                width="28px"
              />
              <Spacer horizontal />
              <Button
                icon="arrowRight"
                displayType="iconOnly"
                color="gray4"
                onClick={nextTab ? moveTab(nextTab.id) : undefined}
                disabled={!nextTab || nextTab.onlyClick}
                id="tabForward"
                testTag="forward-tab-button"
                height="28px"
                width="28px"
                iconStrokeWidth={2}
              />
            </Flex>
          )}
        </Flex>
      ) : (
        <>
          <Flex justify="end">
            <SelectInput
              name="class"
              value={activeTab?.id}
              options={tabs.map((tab) => ({
                label: tab.name,
                value: tab.id,
              }))}
              onChange={(tabId) => {
                const tab = tabs.find((t) => t.id === tabId);
                if (!tab) return;

                if (tab.to) {
                  history.push(tab.to);
                } else {
                  history.push(`${pathname}${updateQuery(query, { [queryParam]: tab.id })}`);
                }
              }}
            />
          </Flex>
          <Spacer size={4} />
        </>
      )}
      <Spacer size={2} />
      {noContent ? null : <TabViewContent {...props} />}
    </>
  );
};
