import { KeyboardEvent } from 'react';
import { Button, Flex, FormGroup, Spacer, Tag, Text } from 'src/components';
import { ErrorMap } from 'src/modules/Api';
import { ImmutableList } from 'src/modules/Immutable';
import { paddings } from 'src/styles';

type TagEditorProps = {
  newTagName: string;
  tagNameList: ImmutableList<string>;
  disabled?: boolean;
  errors: ErrorMap;
  updateTag: (tagName: string) => void;
  addTag: () => void;
  removeTag: (tagName: string) => () => void;
};

export const TagEditor = ({
  newTagName,
  tagNameList,
  disabled = false,
  errors,
  updateTag,
  addTag,
  removeTag,
}: TagEditorProps) => {
  const onKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' || event.key === 'Tab') {
      event.preventDefault();
      addTag();
    }
  };
  return (
    <>
      <Flex>
        <FormGroup
          name="tags"
          type="text"
          value={newTagName || ''}
          onChange={updateTag}
          errors={errors}
          testTag="tag-input"
          onKeyDown={onKeyDown}
          disabled={disabled}
        />
        <Spacer horizontal />

        <Button onClick={addTag} paddingOverride={paddings[2]} disabled={disabled}>
          Add Tag
        </Button>
      </Flex>
      <Spacer size={2} />
      <Flex wrap="wrap">
        {tagNameList.map((tagName, i) => (
          <Flex direction="column" key={i}>
            <Tag
              color="white"
              removeable
              onRemove={removeTag(tagName)}
              testTag={`tag-${tagName}`}
              disabled={disabled}
            >
              <Text
                variant="h6"
                lineHeightOverride="120%"
                whiteSpace="nowrap"
                overflowY="hidden"
                ellipsis
                maxWidth="75px"
                title={tagName}
              >
                {tagName}
              </Text>
            </Tag>
            <Spacer />
          </Flex>
        ))}
      </Flex>
    </>
  );
};
