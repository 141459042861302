import { ImmutableList } from 'src/modules/Immutable';
import { borderRadii, Color, colors, paddings, rgba } from 'src/styles';
import styled from 'styled-components';
import { Button } from '../Button';
import { Flex } from '../Flex';
import { Spacer } from '../Spacer';

const Dot = styled.div<{ $color: Color }>`
  height: 8px;
  width: 8px;
  background-color: ${({ $color }) => colors[$color].hex};
  border-radius: ${borderRadii.rounded};
  margin: ${paddings.half};
`;

const DotUnderline = styled.div`
  width: 8px;
  height: 0;
  border: 1px solid ${colors.primaryBlue.hex};
  box-shadow: 0 4px 4px ${rgba(colors.black.hex, 0.25)};
  border-radius: ${borderRadii.rounded};
`;

export type Dot = {
  key: string;
  color: Color;
};

type DotNavProps = {
  dotList: ImmutableList<Dot>;
  activeDot: string;
  onClick: (key: string) => void;
};

export const DotNav = ({ dotList, activeDot, onClick }: DotNavProps) => {
  if (dotList.isEmpty()) return null;
  return (
    <Flex>
      {dotList.map(({ key, color }) => (
        <Flex key={key} direction="column" align="center">
          <Button displayType="noStyles" onClick={() => onClick(key)}>
            <Dot $color={color} />
          </Button>
          {activeDot === key && (
            <>
              <Spacer size="half" />
              <DotUnderline />
            </>
          )}
        </Flex>
      ))}
    </Flex>
  );
};
