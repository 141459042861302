import { CreateToast } from 'src/components';
import { History } from 'src/modules/Router';
import { Assessment } from 'src/models';
import { SetField } from 'src/State';

export type AssignmentStatusModelActions = 'toggleArchive' | 'delete';

export type UpdateAssignmentStatusModalState = {
  openStatusUpdateModal: (assessment: Assessment, action: AssignmentStatusModelActions) => void;
  confirmUpdateAssignmentStatus: (
    history: History,
    createToast: CreateToast,
    onSuccess?: (update: Assessment) => void,
  ) => () => void;
  setField: SetField<UpdateAssignmentStatusModalState>;
  opened: boolean;
  assessment?: Assessment;
  action: AssignmentStatusModelActions;
  getActionName: () => 'archive' | 'restore' | 'delete';
  confirmDeleteText: string;
};

export const defaultState = {
  opened: false,
  confirmDeleteText: '',
  action: 'toggleArchive' as const,
};
