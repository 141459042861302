import { ReactNode } from 'react';
import { Flex, Spacer, LinkButton, Text } from 'src/components';
import { paddings, Width } from 'src/styles';

export type EmptyStateDisplayType = 'minimal' | 'compact' | 'normal';
type EmptyStateProps = {
  primaryText?: string;
  secondaryText?: ReactNode;
  illustration: string;
  illustrationHeight?: string;
  helpHref?: string | null;
  testTag?: string;
  displayType?: EmptyStateDisplayType;
  width?: Width;
};

const emptyStatePadding = (displayType: EmptyStateDisplayType) => {
  switch (displayType) {
    case 'minimal':
      return paddings.none;
    case 'compact':
      return paddings[2];
    default:
      return paddings[10];
  }
};

export const EmptyStateBase = ({
  primaryText,
  secondaryText,
  helpHref,
  illustration,
  illustrationHeight,
  testTag,
  displayType = 'normal',
  width,
}: EmptyStateProps) => {
  return (
    <Flex
      direction="column"
      maxWidth={width ? width : '350px'}
      width={width}
      padding={emptyStatePadding(displayType)}
      testTag={testTag}
    >
      <img
        style={{
          maxHeight: displayType === 'minimal' ? '200px' : '300px',
          height: illustrationHeight,
        }}
        src={illustration}
      />
      <Spacer size={displayType === 'minimal' ? 2 : 8} />
      {primaryText && (
        <Text textAlign="center" variant="h4">
          {primaryText}
        </Text>
      )}
      <Spacer size={2} />
      <div style={{ textAlign: 'center' }}>
        {secondaryText}
        {helpHref && (
          <>
            <Text display="inline"> (</Text>
            <LinkButton newTab href={helpHref} display="inline" flexDisplay="inherit">
              learn more
            </LinkButton>
            <Text display="inline">)</Text>
          </>
        )}
      </div>
    </Flex>
  );
};
