import { subDataCreator } from 'src/State';
import { makeQueryString } from 'src/modules/Api';
import { defaultState } from './TeacherCourseListState';
import { ImmutableList, ImmutableMap, ImmutableSet, setToggle } from 'src/modules/Immutable';
import {
  Course,
  Registration,
  updateCourse,
  User,
  DISABLED_GRADE_LEVELS,
  GradeLevel,
  Subject,
} from 'src/models';
import { queryMapToString, updateQuery } from 'src/modules/Router';
import { goToGoogle } from 'src/modules/Google';
import { goToClever } from 'src/modules/Clever';
import { pluralize, capitalize } from 'src/modules/String';

export type TeacherCourseListResponse = {
  courses: ImmutableList<Course>;
  students: ImmutableList<User>;
  registrations: ImmutableMap<string, ImmutableList<Registration>>;
  studentCounts: ImmutableMap<string, number>;
  gradeLevels: ImmutableList<GradeLevel>;
  currentUser: User;
  subjects: ImmutableList<Subject>;
  subjectMap: ImmutableMap<string, ImmutableList<Subject>>;
  assessmentCountMap: ImmutableMap<string, number>;
};

export type CourseBulkUpdateResponse = {
  success: boolean;
  registrations: ImmutableMap<string, ImmutableList<Registration>>;
};

export const createDefaultTeacherCourseListData = subDataCreator(
  'TeacherCourseListData',
  defaultState,
  ({ set, get, getFull, setField, fetchJson }) => ({
    setField,
    load: ({ courseIdsToEdit = ImmutableSet<string>(), onSuccess, history, pathname }) => {
      set({ ...defaultState });
      fetchJson(`/api/courses`, {
        onSuccess: (data: TeacherCourseListResponse) => {
          if (data.currentUser.onboardingStatus === 'needed') {
            history.replace(`/t/onboarding${makeQueryString({ redirectPath: pathname })}`);
          } else {
            const filteredCourseIdsToEdit = data.courses
              .filter((c) => courseIdsToEdit.has(c.id))
              .map((c) => c.id);

            const editingCourse =
              filteredCourseIdsToEdit.size > 0
                ? data.courses.find((c) => c.id === filteredCourseIdsToEdit.first()) || null
                : null;

            const editingCourseSubjects = data.subjectMap.get(
              editingCourse?.id || '',
              ImmutableList<Subject>(),
            );

            const unsavedRegistrations = data.registrations
              .get(editingCourse?.id || '', ImmutableList<Registration>())
              .map(({ userId }) => userId)
              .toSet();

            set({
              loading: false,
              courses: data.courses,
              students: data.students,
              registrations: data.registrations,
              studentCounts: data.studentCounts,
              courseSubjectsMap: data.subjectMap,
              subjects: data.subjects,
              unsavedRegistrations,
              editingCourse,
              editingCourseSubjects,
              tempEditingCourseSubjectIds: editingCourseSubjects.map((s) => s.id),
              courseIdsToEdit: filteredCourseIdsToEdit,
              gradeLevels: ImmutableList(data.gradeLevels).map((gl) =>
                DISABLED_GRADE_LEVELS.has(gl.level)
                  ? { id: gl.id, level: gl.level, name: 'Select a Grade Level' }
                  : gl,
              ),
              assessmentCountMap: data.assessmentCountMap,
            });
            onSuccess?.();
          }
        },
      });
    },
    archive: (course, createToast, onSuccess) => () => {
      const updatedCourse: Course = {
        ...course,
        status: course.status === 'active' ? 'archived' : 'active',
      };
      set((state) => ({
        courses: state.courses.filter((c) => c.id !== course.id).push(updatedCourse),
      }));
      updateCourse({
        course: updatedCourse,
        fetchJson,
        editingInModal: false,
        onSuccess: ({ success }) => {
          if (success) {
            createToast({
              children: `Course ${
                updatedCourse.status === 'archived' ? 'archived' : 'unarchived'
              }!`,
            });
            onSuccess?.();
          } else {
            createToast({
              color: 'danger',
              children: 'Failed to update course.',
            });
          }
        },
      });
    },
    setEditCourseField: (field) => (value) => {
      set((state) => {
        if (!state.editingCourse) return state;
        return {
          ...state,
          editingCourse: {
            ...state.editingCourse,
            [field]: value,
          },
        };
      });
    },
    editCourse: (history, query, isCreate = false) => {
      const {
        editSubmitting,
        editingCourse,
        editingCourseSubjects,
        tempEditingCourseSubjectIds: newSubjects,
      } = get();
      if (!editingCourse || editSubmitting) return;

      const editingCourseSubjectIds = editingCourseSubjects.map((subject) => subject.id);
      const toDelete = editingCourseSubjectIds.filter((id) => !newSubjects.contains(id));
      const toAdd = newSubjects.filter((id) => !editingCourseSubjectIds.contains(id));

      set({ editSubmitting: true });
      updateCourse({
        editingInModal: true,
        course: editingCourse,
        newSubjectIds: toAdd,
        oldSubjectIds: toDelete,
        fetchJson,
        onSuccess: ({ course, success, errors }) => {
          if (success) {
            const { courses, courseIdsToEdit, courseSubjectsMap } = get();
            const updatedCourses = courses.filter((c) => c.id !== course.id).push(course);
            const currentIndex = courseIdsToEdit.findIndex((id) => id === course.id);
            const nextEditingCourse =
              (currentIndex === -1
                ? null
                : updatedCourses.find(
                    (c) => c.id === courseIdsToEdit.get(currentIndex + 1, null),
                  )) || null;

            set({
              courses: updatedCourses,
              editErrors: ImmutableMap(errors),
              editSubmitting: false,
              editingCourse: nextEditingCourse,
              editingCourseSubjects: courseSubjectsMap.get(
                nextEditingCourse?.id || '',
                ImmutableList<Subject>(),
              ),
              tempEditingCourseSubjectIds: courseSubjectsMap
                .get(nextEditingCourse?.id || '', ImmutableList<Subject>())
                .map((subject) => subject.id),
            });

            if (nextEditingCourse === null) {
              if (isCreate) {
                history.replace(
                  `/t/classes/${editingCourse.id}/finished${queryMapToString(query)}`,
                );
              } else {
                history.replace(`/t/classes${queryMapToString(query.remove('editIds'))}`);
              }
            }
          } else {
            set({ editErrors: ImmutableMap(errors), editSubmitting: false });
          }
        },
      });
    },
    startSyncCourse:
      ({ history, query, courses, isGoogle }) =>
      () => {
        const courseIds = courses.map(({ id }) => id);
        set({ syncLoadingCourseIds: courseIds.toSet(), coursesToSync: courses });

        const sharedAttributes = {
          successUrl: `/t/classes/sync_lms${updateQuery(query, {
            courseIds: courseIds.join(','),
            isGoogle: isGoogle ? 'true' : 'false',
          })}`,
          history,
          userAttributes: {},
          fetchJson,
        };
        if (isGoogle) {
          goToGoogle({
            scopeKey: 'import_class',
            ...sharedAttributes,
          });
        } else {
          goToClever(sharedAttributes);
        }
      },
    syncAllCourses: (courses, history, pathname, createToast, isGoogle) => () => {
      if (courses.isEmpty()) return;
      const syncKind = isGoogle ? 'google' : 'clever';
      set({ syncLoadingCourseIds: courses.map(({ id }) => id).toSet() });
      fetchJson(`/api/${syncKind}_courses`, {
        method: 'POST',
        data: { courses: courses.map((c) => ({ id: c.id, avatarColor: c.avatarColor })) },
        onSuccess: ({ hasScopes }: { hasScopes: boolean }) => {
          history.replace('/t/classes');
          get().load({
            history,
            pathname,
            onSuccess: () => {
              set({
                syncLoadingCourseIds: ImmutableSet<string>(),
                coursesToSync: ImmutableList<Course>(),
              });
              const pluralCourse = pluralize('course', courses.size, {
                noNumber: true,
              });
              if (hasScopes) {
                createToast({ children: `${capitalize(syncKind)} ${pluralCourse} synced!` });
              } else {
                createToast({
                  children: `${capitalize(
                    syncKind,
                  )} ${pluralCourse} failed to sync, please try again.`,
                  color: 'danger',
                });
              }
            },
          });
        },
      });
    },
    toggleRegistration: (id) => {
      set((state) => ({
        ...state,
        unsavedRegistrations: setToggle(state.unsavedRegistrations, id),
      }));
    },
    updateClassStudents: (createToast, closeModal) => {
      const { students, editingCourse, unsavedRegistrations } = get();
      if (!editingCourse) return;

      fetchJson(`/api/courses/${editingCourse.id}/bulk_update_registrations`, {
        method: 'PATCH',
        data: {
          registrationUpdates: students
            .reduce(
              (acc, student) => acc.set(student.id, unsavedRegistrations.has(student.id)),
              ImmutableMap(),
            )
            .entrySeq(),
        },
        onSuccess: ({ success, registrations }: CourseBulkUpdateResponse) => {
          if (success) {
            set((state) => ({ ...state, registrations }));
            getFull().ProgressMonitoringData.clearCache();
            getFull().StudentProgressMonitoringData.clearCache();
            createToast({ children: `${editingCourse?.name} roster updated!`, color: 'success' });
            closeModal();
          }
        },
      });
    },
    generateClassCode: () => {
      fetchJson(`/api/course_codes/generate`, {
        onSuccess: ({ courseCode }: { courseCode: string }) => {
          get().setEditCourseField('courseCode')(courseCode);
        },
      });
    },
  }),
);
