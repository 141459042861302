import { Button, Spacer, StandardModal, useToaster, Text, FormGroup } from 'src/components';
import { useStore } from 'src/Store';
import { useHistory } from 'src/modules/Router';

export const ReassignModal = () => {
  const { submitting, reassigning, submitReassign, reassigningName, reassigningErrors, setField } =
    useStore(
      ({ TeacherAssignmentOverviewData: taod }) => ({
        submitting: taod.submitting,
        reassigning: taod.reassigning,
        submitReassign: taod.submitReassign,
        reassigningName: taod.reassigningName,
        reassigningErrors: taod.reassigningErrors,
        setField: taod.setField,
      }),
      [],
    );
  const { createToast } = useToaster();
  const history = useHistory();
  const close = () => setField('reassigning')(false);

  return (
    <StandardModal
      testTag="reassign"
      openType="state"
      opened={reassigning}
      header="Reassign Assignment"
      close={close}
      onSubmit={submitReassign({ history, createToast })}
      footer={
        <>
          <Button onClick={close} displayType="link" color="primaryBlue">
            Cancel
          </Button>
          <Spacer horizontal size={3} />
          <Button loading={submitting} type="submit">
            Reassign
          </Button>
        </>
      }
    >
      <Text>
        This will create a copy of this assignment, and automatically reassign <strong>ALL</strong>{' '}
        the currently assigned students.
      </Text>
      <Spacer size={2} />
      <FormGroup
        type="text"
        testTag="reassign-input"
        name="name"
        label="New Assignment Name"
        errors={reassigningErrors}
        value={reassigningName}
        onChange={setField('reassigningName')}
      />
    </StandardModal>
  );
};
