import { subDataCreator } from 'src/State';
import { ErrorMapResponse } from 'src/modules/Api';
import { ImmutableMap } from 'src/modules/Immutable';
import { defaultState } from './SignUpConfirmationSentState';

type SubmitResponse = {
  errors: ErrorMapResponse;
  success: boolean;
};

let resentTimeoutId: NodeJS.Timeout | null = null;

export const createDefaultSignUpConfirmationSentData = subDataCreator(
  'SignUpConfirmationSentData',
  defaultState,
  ({ set, fetchJson }) => ({
    load: () => {
      set(defaultState);
    },
    resend: (email) => () => {
      if (!email) return;
      if (resentTimeoutId) clearTimeout(resentTimeoutId);
      set({ resending: true, resent: false, errors: ImmutableMap() });
      fetchJson(`/api/confirmation_email_tokens`, {
        method: 'POST',
        data: {
          email,
        },
        onSuccess: ({ errors, success }: SubmitResponse) => {
          set({ errors: ImmutableMap(errors), resent: success, resending: false });
          resentTimeoutId = setTimeout(() => {
            set({ resent: false, errors: ImmutableMap() });
          }, 60000);
        },
      });
    },
  }),
);
