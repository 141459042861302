import { useEffect } from 'react';
import { Tooltip } from 'src/components';
import { useUniqueId } from 'src/modules/UniqueId';

type GoogleShareButtonProps = {
  url: string;
  title?: string;
  body?: string;
  itemType?: 'announcement' | 'assignment' | 'material' | 'question';
  googleCourseId?: string;
  size?: number;
  tooltipContent?: string;
};

export const GoogleShareButton = ({
  url,
  title = 'Readlee Assignment',
  body = 'Click the link below to complete your assignment on Readlee.',
  tooltipContent = 'Click here to share in Google Classroom',
  itemType = 'assignment',
  size = 32,
  googleCourseId,
}: GoogleShareButtonProps) => {
  const baseId = useUniqueId();

  useEffect(() => {
    const script = document.createElement('script');

    script.src = 'https://apis.google.com/js/platform.js';
    script.async = true;
    script.defer = true;
    script.addEventListener('load', (_) => {
      window.gapi.sharetoclassroom.render(`google-share-button-${baseId}`, {
        itemtype: itemType,
        courseid: googleCourseId,
        body,
        url,
        size,
        title,
      });
    });

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [url, itemType, size, googleCourseId, title, body, baseId]);
  return (
    <Tooltip content={tooltipContent}>
      <div data-test-tag="google-share-button" id={`google-share-button-${baseId}`} />
    </Tooltip>
  );
};
