import { RecordingSequence } from './RecordingSequence';
import { TranscriptableAudioStoredFile } from './TranscriptableAudioStoredFile';

export type ComprehensionQuestionType = 'undecided' | 'written' | 'recorded';
export type ComprehensionResponseType = 'student_choice' | 'written' | 'recorded';

export type ComprehensionTask = {
  id: string;
  name: string;
  type: 'ComprehensionTask';
  status: 'active' | 'empty' | 'deleted';
  writtenContent: string;
  questionType: ComprehensionQuestionType;
  responseType: ComprehensionResponseType;
};

export type ComprehensionTaskDetails = {
  type: 'ComprehensionTask';
  task: ComprehensionTask;
  questionRecordingSequence: RecordingSequence;
  questionAudioFile: TranscriptableAudioStoredFile | null;
};
