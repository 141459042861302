import { formatRecordingTime } from 'src/modules/Time';
import {
  AudioPlayer,
  Button,
  Flex,
  Spacer,
  Text,
  Icon,
  DropdownMenu,
  DrawerComponent,
  GreenCheckContainer,
  RecordingTime,
  VolumeMeter,
  RecordingStatus,
  BaseAudioRecorderProps,
} from 'src/components';
import { colors, paddings } from 'src/styles';
import styled from 'styled-components';

export const MobileControlsContainer = styled.div`
  width: 100%;
  position: absolute;
  bottom: 0;
  background: ${colors.white.hex};
  padding: ${paddings[3]};
`;

export const MobileRecorder = ({
  audioFile,
  displayType,
  status,
  startedAt,
  recordingEndedAt,
  volumeLevel,
  hasJustStarted,
  disabled,
  dirty,
  recordingSequence,
  onSave,
  openMicSettings,
  startRestart,
  startDeleting,
  stop,
  beginRecording,
}: BaseAudioRecorderProps) => {
  const audioReady = !(
    (status === 'not_started' && !audioFile) ||
    status === 'loading' ||
    status === 'processing' ||
    status === 'recording'
  );

  const saved = Boolean(audioFile) && (status === 'finished' || status === 'not_started') && !dirty;

  const doneRecording = audioFile && status !== 'recording' && status !== 'processing';

  return (
    <DrawerComponent displayType={displayType} saved={saved}>
      <MobileControlsContainer>
        {saved && (
          <GreenCheckContainer>
            <Icon icon="checkmarkCircle" color="green" size="1.5em" />
          </GreenCheckContainer>
        )}

        <Flex width="100%" align="center" justify="center">
          {doneRecording ? (
            <AudioPlayer
              src={audioFile.url}
              displayType={displayType}
              showSkipButtons={false}
              trackMinWidth="150px"
            />
          ) : (
            <>
              <RecordingStatus
                $color={status === 'recording' ? 'danger' : 'gray5'}
                $recording={status === 'recording'}
              />
              <Spacer horizontal size={4} />
              {status === 'recording' ? (
                <RecordingTime startedAt={startedAt} recordingLength={recordingSequence.duration} />
              ) : (
                <Text variant="largeText" testTag="recording-length">
                  {formatRecordingTime(
                    Math.max(
                      recordingEndedAt.valueOf() - startedAt.valueOf() + recordingSequence.duration,
                      0,
                    ),
                  )}
                </Text>
              )}
              <Spacer horizontal size={5} />
              <VolumeMeter displayType="mobile" volumeLevel={volumeLevel} />
            </>
          )}
          <DropdownMenu
            testTag="audio-recorder-extra-settings"
            displayType="noStyles"
            rightAligned
            direction="up"
            icon="threeVerticalDots"
            iconColor="gray7"
            mobile
            options={[
              {
                onClick: openMicSettings,
                children: (
                  <Flex padding={paddings[2]}>
                    <Icon icon="settings" size="1.5em" fill strokeWidth={0} />
                    <Spacer horizontal size={2} />
                    <Text variant="h5" whiteSpace="nowrap">
                      Microphone Settings
                    </Text>
                  </Flex>
                ),
              },
              {
                onClick: beginRecording,
                disabled: !audioReady,
                children: (
                  <Flex padding={paddings[2]}>
                    <Icon icon="microphone" size="1.5em" />
                    <Spacer horizontal size={2} />
                    <Text variant="h5" whiteSpace="nowrap">
                      Continue Recording
                    </Text>
                  </Flex>
                ),
              },
              {
                onClick: startRestart,
                disabled: !audioReady,
                children: (
                  <Flex padding={paddings[2]}>
                    <Icon icon="redo" size="1.5em" color={audioReady ? 'primaryBlue' : 'gray5'} />
                    <Spacer horizontal size={2} />
                    <Text
                      variant="h5"
                      whiteSpace="nowrap"
                      color={audioReady ? 'primaryBlue' : 'gray5'}
                    >
                      Start Over
                    </Text>
                  </Flex>
                ),
              },
              {
                onClick: startDeleting,
                disabled: !audioReady,
                children: (
                  <Flex padding={paddings[2]}>
                    <Icon icon="trash" size="1.5em" fill color={audioReady ? 'danger' : 'gray5'} />
                    <Spacer horizontal size={2} />
                    <Text variant="h5" whiteSpace="nowrap" color={audioReady ? 'danger' : 'gray5'}>
                      Delete
                    </Text>
                  </Flex>
                ),
              },
            ]}
          />
        </Flex>
        <Spacer size={3} />
        {doneRecording ? (
          <Flex align="center" justify="center" width="100%">
            <Button
              onClick={beginRecording}
              loading={status === 'loading'}
              activeColor="white"
              color="warning"
              disabled={disabled}
              borderRadiusOverride="10"
              paddingOverride={paddings[2]}
              flexGrow={1}
            >
              <Icon icon="microphone" color="white" strokeWidth={0} size="1.25em" />
              <Spacer horizontal />
              <Text variant="h6" color="white">
                Continue
              </Text>
            </Button>
            {!saved && (
              <>
                <Spacer horizontal size={3} />
                <Button
                  onClick={onSave}
                  loading={status === 'loading'}
                  displayType="outline"
                  color="gray4"
                  disabled={disabled}
                  borderRadiusOverride="10"
                  paddingOverride={paddings[2]}
                  flexGrow={1}
                  testTag="save-recording"
                >
                  <Icon icon="save" color="black" strokeWidth={0} size="1.25em" />
                  <Spacer horizontal />
                  <Text variant="h6" color="black">
                    Save
                  </Text>
                </Button>
              </>
            )}
          </Flex>
        ) : (
          <>
            {status === 'recording' || status === 'processing' ? (
              <>
                <Button
                  width="100%"
                  loading={status === 'processing'}
                  color="warning"
                  borderRadiusOverride="10"
                  paddingOverride={paddings[3]}
                  disabled={disabled || hasJustStarted}
                  onClick={stop}
                  testTag={
                    status === 'recording' ? 'stop-recording-button' : 'start-recording-button'
                  }
                >
                  <Icon icon="pause" color="white" size="24px" />
                  <Spacer horizontal />
                  <Text color="white">Pause</Text>
                </Button>
                <Spacer horizontal size={17} />
              </>
            ) : (
              <Button
                width="100%"
                color="danger"
                loading={status === 'loading'}
                onClick={beginRecording}
                borderRadiusOverride="10"
                testTag="start-recording-button"
                disabled={disabled}
              >
                <Icon icon="microphone" strokeWidth={0} size="1.15em" />
                <Spacer horizontal />
                Record
              </Button>
            )}
          </>
        )}
      </MobileControlsContainer>
    </DrawerComponent>
  );
};
