import { useEffect } from 'react';
import {
  AudioRecorder,
  Flex,
  Loading,
  Reader,
  Drawer,
  TextAreaInput,
  ErrorText,
  DrawerExpandButton,
  Spacer,
  Text,
  AudioPlayer,
  FormGroup,
  LinkButton,
} from 'src/components';
import { ReadingStudioLayout } from 'src/layouts';
import { useHistory, useParams, useQuery } from 'src/modules/Router';
import { useStore } from 'src/Store';

import { AssignmentBuilderSideBar } from './AssignmentBuilderSideBar';
import { AssignmentBuilderTopBar } from './AssignmentBuilderTopBar';
import {
  useCurrentTaskDetails,
  useReadingTaskDetails,
  useVocabTaskDetailsList,
} from './AssignmentBuilderHelpers';
import { ImmutableList, ImmutableMap } from 'src/modules/Immutable';
import { useHideZendesk } from 'src/modules/Analytics/AnalyticsIdentity';
import { borderRadii, colors, paddings } from 'src/styles';
import styled from 'styled-components';
import { getInstructions } from 'src/models';

const VocabImageStyles = styled.img`
  max-height: 300px;
  max-width: 400px;
  object-fit: contain;
  object-position: left;
`;

const MainContent = () => {
  const readingTaskDetails = useReadingTaskDetails();
  const currentTaskDetails = useCurrentTaskDetails();
  const vocabTaskDetailsList = useVocabTaskDetailsList();
  const taskType = currentTaskDetails ? currentTaskDetails.type : '';
  const drawerOpen = taskType === 'ComprehensionTask';

  const {
    setTextRange,
    assessment,
    vocabTaskStep,
    setField,
    vocabImageFileUrl,
    setDefinition,
    setCustomWord,
    setVocabImage,
    uploadVocabImage,
    setWordContent,
  } = useStore(
    ({ AssignmentBuilderData: s }) => ({
      setTextRange: s.setTextRange,
      assessment: s.assessment,
      setVocabWord: s.setVocabWord,
      vocabTaskStep: s.vocabTaskStep,
      setField: s.setField,
      vocabImageFileUrl: s.vocabImageFileUrl,
      setDefinition: s.setDefinition,
      setCustomWord: s.setCustomWord,
      setVocabImage: s.setVocabImage,
      uploadVocabImage: s.uploadVocabImage,
      setWordContent: s.setWordContent,
    }),
    [],
  );

  return vocabTaskStep === 'details' &&
    currentTaskDetails &&
    currentTaskDetails.type === 'VocabTask' ? (
    <Flex justify="start" align="start" width="100%" padding={paddings[20]}>
      <Flex direction="column" width="100%">
        <Text color="gray6">Word</Text>
        <Flex align="center">
          <FormGroup
            name="vocabWord"
            type="text"
            value={currentTaskDetails.task.customWord}
            onChange={(word) => setCustomWord(currentTaskDetails.task.id, word)}
            width="200px"
          />
          <Spacer horizontal size={2} />
          {currentTaskDetails.word?.audioUrl && (
            <AudioPlayer
              src={currentTaskDetails.word.audioUrl}
              displayType="iconOnly"
              icon="speaker"
              iconColor="black"
              testTag="vocab-audio"
            />
          )}
        </Flex>
        <Spacer size={4} />
        <FormGroup
          name="definition"
          type="text-area"
          value={currentTaskDetails.task.definition}
          onChange={(definition) => setDefinition(currentTaskDetails.task.id, definition)}
          label="Definition"
          resize="vertical"
          minHeight="150px"
        />
        <Spacer size={4} />
        <Text color="gray6">Image (optional)</Text>

        <Spacer size={2} />
        {vocabImageFileUrl || currentTaskDetails.imageFile ? (
          <>
            <VocabImageStyles
              src={vocabImageFileUrl || currentTaskDetails.imageFile?.url}
              alt={currentTaskDetails.task.customWord}
              data-test-tag="vocab-image"
            />
            <Spacer size={2} />

            <Text variant="h6" ellipsis maxWidth="100%">
              {currentTaskDetails.imageFile?.name || (
                <Flex align="center">
                  Uploading... <Loading />
                </Flex>
              )}
            </Text>
            <LinkButton
              onClick={() => {
                setField('vocabImageFileUrl')('');
                setField('vocabImageFile')(null);
                setVocabImage(currentTaskDetails.task.id, null);
              }}
              color="danger"
              paddingOverride={paddings.none}
              width="fit-content"
            >
              Remove Image
            </LinkButton>
          </>
        ) : (
          <Flex
            border={`1px solid ${colors['primaryBlue'].hex}`}
            borderRadius={borderRadii[2]}
            width="fit-content"
          >
            <FormGroup
              id="vocab-image-input"
              name="vocabImageFile"
              type="file"
              width="auto"
              value={ImmutableList()}
              onChange={uploadVocabImage(currentTaskDetails.task.id)}
              accept="image"
              testTag="vocab-image"
              flex
              iconColor="primaryBlue"
              kind="imagePreview"
            />
          </Flex>
        )}
      </Flex>
    </Flex>
  ) : (
    <Reader
      instructions={getInstructions(assessment)}
      readingTaskDetails={readingTaskDetails}
      onTextRangeSelected={
        readingTaskDetails ? setTextRange(readingTaskDetails.task.id) : undefined
      }
      drawerOpen={drawerOpen}
      selectableText={currentTaskDetails?.type === 'VocabTask'}
      vocabTask={currentTaskDetails?.type === 'VocabTask' ? currentTaskDetails.task : null}
      highlightedWord={
        currentTaskDetails?.type === 'VocabTask' ? currentTaskDetails.word?.content : undefined
      }
      setVocabWord={(word) => {
        setField('vocabWord')(word);
        if (currentTaskDetails) {
          setWordContent(currentTaskDetails?.task.id, word);
          setCustomWord(currentTaskDetails?.task.id, word);
        }
      }}
      enableTextRangeSelection={
        !(currentTaskDetails?.type !== 'VocabTask' && currentTaskDetails?.type !== 'ReadingTask')
      }
      vocabTaskDetailsList={
        currentTaskDetails?.type !== 'VocabTask' ? vocabTaskDetailsList : undefined
      }
      displayTypeOverride={
        currentTaskDetails?.type !== 'VocabTask' && currentTaskDetails?.type !== 'ReadingTask'
          ? readingTaskDetails?.task.readingPartType === 'start_and_end_index'
            ? 'setTextRange'
            : 'dictionary'
          : undefined
      }
      compactLayout={false}
    />
  );
};

const DrawerContent = () => {
  const {
    assessment,
    onRecordingSequenceStatus,
    setWrittenContent,
    taskErrors,
    drawerExpanded,
    setField,
    emitPaperEvent,
  } = useStore(
    ({ AssignmentBuilderData: s, AppData: ad }) => ({
      assessment: s.assessment,
      onRecordingSequenceStatus: s.onRecordingSequenceStatus,
      setWrittenContent: s.setWrittenContent,
      taskErrors: s.taskErrors,
      drawerExpanded: s.drawerExpanded,
      setField: s.setField,
      emitPaperEvent: ad.emitPaperEvent,
    }),
    [],
  );
  const currentTaskDetails = useCurrentTaskDetails();
  const recordingSequenceId =
    currentTaskDetails?.type === 'ComprehensionTask' &&
    currentTaskDetails.task.questionType === 'recorded'
      ? currentTaskDetails.questionRecordingSequence.id
      : currentTaskDetails?.type === 'ModelTask'
      ? currentTaskDetails.recordingSequence.id
      : '';

  if (currentTaskDetails?.type === 'ComprehensionTask') {
    if (currentTaskDetails.task.questionType === 'recorded') {
      return (
        <Drawer>
          <Flex direction="column">
            <Flex align="center">
              <AudioRecorder
                recordingSequence={currentTaskDetails.questionRecordingSequence}
                audioFile={currentTaskDetails.questionAudioFile}
                onRecordingSequenceStatus={onRecordingSequenceStatus}
                onRecordingStart={(recordingId) => {
                  if (!assessment || !recordingSequenceId) return;

                  emitPaperEvent({
                    type: 'literacy_recording_started_v3',
                    taskId: currentTaskDetails.task.id,
                    assessmentId: assessment.id,
                    recordingSequenceId,
                    recordingId,
                  });
                }}
                onRecordingStop={(recordingId) => {
                  if (!assessment || !recordingSequenceId) return;

                  emitPaperEvent({
                    type: 'literacy_recording_ended_v3',
                    taskId: currentTaskDetails?.task.id,
                    assessmentId: assessment?.id,
                    recordingId: recordingId,
                    recordingSequenceId,
                  });
                }}
                displayType="desktopSmall"
              />
            </Flex>
          </Flex>
        </Drawer>
      );
    } else if (currentTaskDetails.task.questionType === 'written') {
      return (
        <Drawer expanded={drawerExpanded}>
          <Flex direction="column" align="end" width="100%" height="100%">
            <Flex justify="space-between" align="center" width="100%">
              <Text variant="h5">Question prompt:</Text>
              <DrawerExpandButton
                expanded={drawerExpanded}
                toggleOpen={() => setField('drawerExpanded')(!drawerExpanded)}
              />
            </Flex>
            <Spacer size={2} />
            <Flex width={drawerExpanded ? '100%' : '500px'} direction="column" height="100%">
              <TextAreaInput
                name="writtenContent"
                value={currentTaskDetails.task.writtenContent}
                onChange={(value) => setWrittenContent(currentTaskDetails.task.id, value)}
                placeholder="Write your question here"
                height="100%"
              />
              <ErrorText
                errors={taskErrors.get(currentTaskDetails.task.id, ImmutableMap())}
                errorKey="writtenContent"
              />
            </Flex>
          </Flex>
        </Drawer>
      );
    }
  } else if (currentTaskDetails?.type === 'ModelTask') {
    return (
      <div>
        <AudioRecorder
          recordingSequence={currentTaskDetails.recordingSequence}
          audioFile={currentTaskDetails.audioFile}
          onRecordingSequenceStatus={onRecordingSequenceStatus}
          displayType="desktopSmall"
          onRecordingStart={(recordingId) => {
            if (!assessment || !recordingSequenceId) return;

            emitPaperEvent({
              type: 'literacy_recording_started_v3',
              taskId: currentTaskDetails.task.id,
              assessmentId: assessment.id,
              recordingSequenceId,
              recordingId,
            });
          }}
          onRecordingStop={(recordingId) => {
            if (!assessment || !recordingSequenceId) return;

            emitPaperEvent({
              type: 'literacy_recording_ended_v3',
              taskId: currentTaskDetails?.task.id,
              assessmentId: assessment?.id,
              recordingId: recordingId,
              recordingSequenceId,
            });
          }}
        />
        <Spacer size={4} />
      </div>
    );
  }
  return null;
};

export const AssignmentBuilder = () => {
  const params = useParams<{ assessmentId: string }>();
  const { assessmentId } = params;
  const query = useQuery();
  const history = useHistory();
  const readingId = query.get('readingId', null);
  const currentTaskId = query.get('currentTaskId', null);

  const readingTaskDetails = useReadingTaskDetails();
  const { loading, load, submit, dirty, cancelling, finishCancel, drawerExpanded } = useStore(
    ({ AssignmentBuilderData: s }) => ({
      loading: s.loading,
      load: s.load,
      submit: s.submit,
      dirty: s.dirty,
      cancelling: s.cancelling,
      finishCancel: s.finishCancel,
      drawerExpanded: s.drawerExpanded,
    }),
    [],
  );

  useEffect(() => {
    load({ assessmentId, readingId, currentTaskId });
  }, [load, assessmentId, readingId, currentTaskId]);

  useEffect(() => {
    if (cancelling && !dirty) {
      finishCancel({ history, query });
    }
  }, [cancelling, dirty, finishCancel, query, history]);

  useHideZendesk({ smallerThan: 'desktop' });

  return (
    <ReadingStudioLayout
      showTopBarDivider={
        !['PdfStoredFile', 'PdfByPageStoredFile'].includes(
          readingTaskDetails?.contentFile?.type || '',
        )
      }
      loading={loading}
      topBar={<AssignmentBuilderTopBar />}
      sideBar={<AssignmentBuilderSideBar />}
      onSubmit={() => submit({ query, history })}
      dirty={dirty}
      mainContent={loading ? <Loading flex kind="boat" /> : <MainContent />}
      drawerContent={<DrawerContent />}
      drawerExpanded={drawerExpanded}
    />
  );
};
