import styled from 'styled-components';
import { standardMeasurements, RequiredDollarPrefix } from 'src/styles';

export type SpacerProps = {
  size?: keyof typeof standardMeasurements;
  horizontal?: boolean;
  square?: boolean;
};

type InternalSpacerProps = RequiredDollarPrefix<SpacerProps>;

const InternalSpacer = styled.div<InternalSpacerProps>`
  height: ${({ $size, $horizontal, $square }) =>
    ($square || !$horizontal) && standardMeasurements[$size]};
  width: ${({ $size, $horizontal, $square }) =>
    ($square || $horizontal) && standardMeasurements[$size]};
  flex-shrink: 0;
`;

export const Spacer = ({ size = 1, horizontal = false, square = false }: SpacerProps) => (
  <InternalSpacer $size={size} $horizontal={horizontal} $square={square} />
);
