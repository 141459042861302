import { useCallback, useState } from 'react';
import styled from 'styled-components';

import { LinkButton, Button, Subscribe, Flex, Text, Spacer } from 'src/components';
import { Json } from 'src/modules/Api';
import { colors, paddings } from 'src/styles';

const BaseBanner = styled.div`
  ${($visible) => !$visible && 'display: none;'};

  width: 100%;
`;

type PublicChannelMessage = {
  action: 'new_release';
  payload: Json;
};

export const ReleaseBanner = () => {
  const [newVersionAvailable, setNewVersionAvailable] = useState(false);
  const onPublicMessage = useCallback(({ action }: PublicChannelMessage) => {
    if (action === 'new_release') setNewVersionAvailable(true);
  }, []);

  return (
    <BaseBanner>
      <Subscribe channel="PublicChannel" id="public" onMessage={onPublicMessage} />
      {newVersionAvailable && (
        <Flex
          width="100%"
          backgroundColor={colors.primaryBlue.hex}
          padding={paddings[2]}
          align="center"
        >
          <Flex grow={1} justify="center" align="center">
            <Text variant="largeText" color="white">
              A new version of the app has just been released! Click
            </Text>
            <Spacer horizontal />
            <LinkButton color="white" onClick={() => window.location.reload()}>
              <Text variant="largeText" color="white" weightOverride="bold">
                here
              </Text>
            </LinkButton>
            <Spacer horizontal />
            <Text variant="largeText" color="white">
              to update!
            </Text>
          </Flex>
          <Flex justify="end">
            <Button
              displayType="iconOnly"
              icon="close"
              iconColor="white"
              iconSize="1em"
              width="32px"
              height="32px"
              color="primaryBlue"
              testTag="close-release-banner"
              onClick={() => setNewVersionAvailable(false)}
            ></Button>
          </Flex>
        </Flex>
      )}
    </BaseBanner>
  );
};
