import { useStore } from 'src/Store';
import { Button, Flex, StandardModal, FormGroup, Spacer, useToaster } from 'src/components';
import { Divider } from '../../../components/Divider/Divider';

export const ChangePasswordModal = () => {
  const {
    opened,
    saving,
    currentPassword,
    newPassword,
    passwordConfirmation,
    errors,
    closeModal,
    setField,
    changePassword,
  } = useStore((s) => s.ChangePasswordModalData, []);
  const { createToast } = useToaster();

  return (
    <StandardModal
      openType="state"
      header="Change Password"
      opened={opened}
      close={closeModal}
      disableFadeClose
      footer={
        <Flex>
          <Button onClick={closeModal} inheritBgColor displayType="link" color="primaryBlue">
            Cancel
          </Button>
          <Spacer horizontal size={4} />
          <Button type="submit" loading={saving} onClick={() => changePassword(createToast)}>
            Save
          </Button>
        </Flex>
      }
      testTag="change-password-modal"
    >
      <FormGroup
        type="password"
        name="password"
        label="Current Password"
        value={currentPassword}
        onChange={setField('currentPassword')}
        errors={errors}
      />
      <Spacer size={2} />
      <Divider size={1} />
      <Spacer size={2} />
      <FormGroup
        type="password"
        name="newPassword"
        label="New Password"
        value={newPassword}
        onChange={setField('newPassword')}
        errors={errors}
      />
      <Spacer size={2} />
      <FormGroup
        type="password"
        name="passwordConfirmation"
        label="Confirm Password"
        value={passwordConfirmation}
        onChange={setField('passwordConfirmation')}
        errors={errors}
      />
    </StandardModal>
  );
};
