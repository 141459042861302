import { useState } from 'react';
import { StandardModal } from '../Modal';
import { Button, LinkButton } from '../Button';
import { Flex } from '../Flex';
import { Icon } from '../Icon';
import { Text } from '../Text';
import { Tooltip } from '../Tooltip';

export const SharingDisclaimer = ({ isPaperUser }: { isPaperUser: boolean }) => {
  const [sharingDisclaimerOpen, setSharingDisclaimerOpen] = useState(false);

  return isPaperUser ? (
    <>
      <Button onClick={() => setSharingDisclaimerOpen(true)} displayType="noStyles">
        <Flex height="100%">
          <Icon icon="question" strokeWidth={2} color="white" fill />
        </Flex>
      </Button>
      <StandardModal
        openType="state"
        opened={sharingDisclaimerOpen}
        header="Disclaimer for teachers sharing content"
        close={() => setSharingDisclaimerOpen(false)}
        maxWidthOverride="600px"
      >
        By uploading this content, I represent and warrant that I have the proper rights to share
        this content broadly within the Paper Reading teacher community. I take full responsibility
        and shall indemnify and hold harmless Paper Education Company Inc. and its affiliates should
        any third party claim damages due to copyright infringement or any other intellectual
        property rights in this content as a result of my upload to Paper&apos;s platform. If I am
        unsure about whether I have the right to share this content, I should not upload it.
      </StandardModal>
    </>
  ) : (
    <Tooltip
      iconColor="white"
      content={
        <>
          <Text>Share this content with other teachers using Readlee!</Text>
          <LinkButton
            href="https://readleesupport.zendesk.com/hc/en-us/articles/14120752237837"
            newTab
          >
            Learn more
          </LinkButton>
        </>
      }
    />
  );
};
