import { ReactNode } from 'react';
import { Button, Flex, Spacer } from 'src/components';

type ArrowContainerProps = {
  children: ReactNode;
  onLeftClick: () => void;
  onRightClick: () => void;
  leftDisabled?: boolean;
  rightDisabled?: boolean;
};

export const ArrowContainer = ({
  children,
  onLeftClick,
  onRightClick,
  leftDisabled,
  rightDisabled,
}: ArrowContainerProps) => {
  return (
    <Flex align="center">
      <Button
        onClick={onLeftClick}
        icon="backArrow"
        displayType="iconOnly"
        iconColor={leftDisabled ? 'gray6' : 'black'}
        color="gray1"
        height="46px"
        width="46px"
        disabled={leftDisabled}
      />
      <Spacer size={4} horizontal />
      {children}
      <Spacer size={4} horizontal />
      <Button
        onClick={onRightClick}
        icon="forwardArrow"
        displayType="iconOnly"
        iconColor={rightDisabled ? 'gray6' : 'black'}
        color="gray1"
        height="46px"
        width="46px"
        disabled={rightDisabled}
      />
    </Flex>
  );
};
