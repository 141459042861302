import { colors, rgba, zIndices } from 'src/styles';
import styled, { keyframes } from 'styled-components';
import { Text } from '../Text';
import { AudioRecorderDisplayType } from './AudioRecorder';

/* stylelint-disable keyframes-name-pattern -- styled components violates this rule and we can't fix it */
const FadeAnimation = keyframes`
  /* stylelint-enable */

  0% { opacity: 0; }
  15% { opacity: 1; }
  85% { opacity: 1; }
  100% { opacity: 0; }
`;
const Fade = styled.div<{ $opened: boolean }>`
  display: ${({ $opened }) => ($opened ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
  background: ${rgba(colors.black.hex, 0.5)};
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: ${zIndices.modal};
  animation-name: ${FadeAnimation};
  animation-duration: 5.5s;
  animation-iteration-count: 1;
`;

const CountdownContainer = styled.div<{ $displayType: AudioRecorderDisplayType }>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  overflow: hidden;
  ${({ $displayType }) => $displayType !== 'mobile' && `margin-left: 300px`};
`;

/* stylelint-disable keyframes-name-pattern -- styled components violates this rule and we can't fix it */
const CountdownAnimation = keyframes`
  /* stylelint-enable */

  0% { transform: translateY(70%); }
  20% { transform: translateY(38%); }
  40% { transform: translateY(13%); }
  60% { transform: translateY(-12%); }
  80% { transform: translateY(-37%); }
  100% { transform: translateY(-37%); }
`;

const StyledCountdown = styled.div`
  animation-name: ${CountdownAnimation};
  animation-duration: 5.5s;
  animation-iteration-count: 1;
  animation-timing-function: steps;
`;

type CountdownProps = {
  opened: boolean;
  displayType: AudioRecorderDisplayType;
};

export const Countdown = ({ opened = false, displayType }: CountdownProps) => {
  return (
    <Fade $opened={opened} data-test-tag="countdown">
      <CountdownContainer $displayType={displayType}>
        <StyledCountdown>
          <Text sizeOverride={100} color="white" textAlign="center">
            3
          </Text>
          <Text sizeOverride={100} color="white" textAlign="center">
            2
          </Text>
          <Text sizeOverride={100} color="white" textAlign="center">
            1
          </Text>
          <Text sizeOverride={100} color="white" textAlign="center">
            Read!
          </Text>
        </StyledCountdown>
      </CountdownContainer>
    </Fade>
  );
};
