import { ImmutableList, ImmutableMap } from 'src/modules/Immutable';
import { SetField } from 'src/State';
import {
  ReadingSearchResult,
  GradeLevel,
  AssessmentExtended,
  User,
  OwnershipType,
  Playlist,
} from 'src/models';
import { History, QueryMap } from 'src/modules/Router';

export type SearchParams = {
  searchText: string;
  pageNumber: number;
  gradeBands: ImmutableList<string>;
  ownershipTypes: ImmutableList<OwnershipType>;
  wordCounts: ImmutableList<string>;
  tagIds: ImmutableList<string>;
  additionalFilters: ImmutableList<string>;
};

export type ContentLibraryState = {
  gradeLevels: ImmutableList<GradeLevel>;
  searchResults: ImmutableList<ReadingSearchResult>;
  playlistResults: ImmutableMap<string, ImmutableList<ReadingSearchResult>>;
  submissionMap: ImmutableMap<string, string>;
  continueReadingMap: ImmutableMap<string, ReadingSearchResult>;
  loading: boolean;
  totalResults: number;
  selectionModalOpen: boolean;
  assignmentsLoading: boolean;
  selectedSearchResult: ReadingSearchResult | null;
  assessmentResults: ImmutableList<AssessmentExtended>;
  userMap: ImmutableMap<string, User>;
  playlists: ImmutableList<Playlist>;
  gradeLevelsLoading: boolean;
  initialSearch: boolean;
  load: () => void;
  search: (params: SearchParams) => void;
  setField: SetField<ContentLibraryState>;
  getAssignments: (result: ReadingSearchResult, onSuccess: () => void) => void;
  launchStudentLibraryReading: (
    id: string,
    history: History,
    query: QueryMap,
    pathname: string,
    existingAssessmentId?: string,
  ) => void;
};

export const defaultState = {
  gradeLevels: ImmutableList<GradeLevel>(),
  searchResults: ImmutableList<ReadingSearchResult>(),
  playlistResults: ImmutableMap<string, ImmutableList<ReadingSearchResult>>(),
  submissionMap: ImmutableMap<string, string>(),
  continueReadingMap: ImmutableMap<string, ReadingSearchResult>(),
  loading: false,
  gradeLevelsLoading: false,
  initialSearch: true,
  totalResults: 0,
  selectionModalOpen: false,
  assignmentsLoading: false,
  selectedSearchResult: null,
  assessmentResults: ImmutableList<AssessmentExtended>(),
  userMap: ImmutableMap<string, User>(),
  playlists: ImmutableList<Playlist>(),
};
