import { Assessment, UltimateFeaturesList } from 'src/models';
import { useStore } from 'src/Store';
import {
  useToaster,
  Button,
  ErrorText,
  Loading,
  Spacer,
  StandardModal,
  Text,
  Icon,
  Flex,
} from 'src/components';

type UpgradeAssignmentModalProps = {
  onSuccess: (assessment: Assessment) => void;
};

export const UpgradeAssignmentModal = (props: UpgradeAssignmentModalProps) => {
  const { createToast } = useToaster();
  const { saving, errors, opened, loading, closeModal, upgradeAssignment, currentUser } = useStore(
    (state) => ({
      saving: state.UpgradeAssignmentModalData.saving,
      errors: state.UpgradeAssignmentModalData.errors,
      opened: state.UpgradeAssignmentModalData.opened,
      loading: state.UpgradeAssignmentModalData.loading,
      closeModal: state.UpgradeAssignmentModalData.closeModal,
      upgradeAssignment: state.UpgradeAssignmentModalData.upgradeAssignment,
      currentUser: state.AppData.currentUser,
    }),
    [],
  );

  return (
    <StandardModal
      opened={opened}
      openType="state"
      header="Upgrade to free Ultimate Assignment"
      middleHeader={
        <>
          <Spacer horizontal size={3} />
          <Icon icon="ultimateAssignmentCentered" size="1.35em" />
        </>
      }
      close={closeModal}
      footer={
        <>
          <Button onClick={closeModal} displayType="link" color="primaryBlue">
            Cancel
          </Button>
          <Spacer horizontal size={3} />
          <Button
            testTag="upgrade-assignment-button"
            onClick={() => upgradeAssignment(createToast, props.onSuccess)}
            loading={saving}
          >
            Upgrade Assignment
          </Button>
        </>
      }
    >
      {loading ? (
        <Loading flex />
      ) : (
        <>
          <Text variant="h5">Unlock some of our favorite features!</Text>
          <UltimateFeaturesList />

          <Text variant="h5" textAlign="center">
            Use one of your remaining Ultimate Assignments to upgrade.
          </Text>
          <Spacer size={4} />
          <Flex align="center" width="100%" justify="center">
            <Flex align="center">
              <Text
                variant="h2"
                lineHeightOverride="100%"
                color="primaryBlue"
                testTag="ultimate-assessments-available"
              >
                {currentUser?.ultimateAssessmentsAvailable}
              </Text>
              <Spacer horizontal />
              <Icon icon="ultimateAssignmentCentered" size="2.25em" />
            </Flex>

            <Spacer horizontal />
            <Icon icon="forwardArrow" size="2.25em" color="primaryBlue" />
            <Spacer horizontal />

            <Flex align="center">
              <Text
                variant="h2"
                lineHeightOverride="100%"
                color="primaryBlue"
                testTag="ultimate-assessments-available"
              >
                {(currentUser?.ultimateAssessmentsAvailable ?? 1) - 1}
              </Text>
              <Spacer horizontal />
              <Icon icon="ultimateAssignmentCentered" size="2.25em" />
            </Flex>
            <Spacer size={4} />
          </Flex>
          <ErrorText errors={errors} errorKey="assessment" />
          <ErrorText errors={errors} errorKey="user" />
        </>
      )}
    </StandardModal>
  );
};
