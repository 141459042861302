import styled from 'styled-components';
import EmptyProfile from 'src/assets/empty-profile.svg';
import { useCallback } from 'react';
import { useStore } from 'src/Store';
import { useQuery, useHistory, updateQuery, useLocation } from 'src/modules/Router';
import { DEFAULT_TEXT_SIZE } from 'src/models';
import { useCompactLayout } from '../AssignToModal/AssignToModalHelpers';
import {
  Text,
  Loading,
  Flex,
  Button,
  Spacer,
  StandardModal,
  BaseProfilePicture,
  AudioPlayer,
} from 'src/components';
import { useReadingTaskSubmissionDetails } from './ReadingStudioHelpers';

const InstructionsContainer = styled.div<{ $textSize: number }>`
  font-size: ${({ $textSize }) => $textSize}px;
  white-space: pre-wrap;
  word-break: break-word;
  max-width: 600px;
  overflow-y: auto;
`;

const InstructionsEmptyState = ({
  textSize,
  isFreeRead,
}: {
  textSize: number;
  isFreeRead: boolean;
}) => {
  const isIndependent = useReadingTaskSubmissionDetails()?.task.readingPartType === 'independent';

  return (
    <ul>
      {!isFreeRead && (
        <li>
          <Text sizeOverride={textSize}>Complete the tasks in the &quot;My Tasks&quot; list.</Text>
        </li>
      )}
      <li>
        <Text sizeOverride={textSize}>
          Record yourself reading {isIndependent ? 'from any' : 'the'} text out loud{' '}
          {isFreeRead ? '' : 'during the Read Aloud task.'}
        </Text>
      </li>
      {isFreeRead && (
        <li>
          <Text sizeOverride={textSize}>Then, answer the question to tell us what you read.</Text>
        </li>
      )}
    </ul>
  );
};

export const InstructionsModal = () => {
  const { loading, assessment, instructionDetails, textSize, profileImageFile } = useStore(
    (state) => ({
      loading: state.ReadingStudioData.loading,
      assessment: state.ReadingStudioData.assessment,
      instructionDetails: state.ReadingStudioData.instructionDetails,
      profileImageFile: state.ReadingStudioData.profileImageFile,
      textSize: state.AppData.currentUser?.textSize || DEFAULT_TEXT_SIZE,
    }),
    [],
  );

  const history = useHistory();
  const query = useQuery();
  const pathname = useLocation().pathname;
  const showInstructions = Boolean(query.get('showInstructions'));
  const profileSize = '80px';
  const compactLayout = useCompactLayout();

  const close = useCallback(() => {
    history.push(`${pathname}${updateQuery(query, { showInstructions: null })}`);
  }, [history, pathname, query]);

  if (compactLayout) return null;

  return (
    <StandardModal
      header={`${
        assessment?.instructions && !assessment?.isFreeRead ? "Your Teacher's " : ''
      }Instructions`}
      openType="state"
      opened={showInstructions}
      close={close}
    >
      {loading ? (
        <Loading flex />
      ) : (
        <>
          <Spacer size={2} />
          <Flex justify="center" testTag="instructions-modal-open">
            {profileImageFile ? (
              <BaseProfilePicture
                data-test-tag="instructions-profile-picture"
                src={profileImageFile.url}
                $height={profileSize}
                $width={profileSize}
              />
            ) : (
              <img style={{ height: profileSize, width: profileSize }} src={EmptyProfile} />
            )}
          </Flex>
          {instructionDetails?.audioFile?.url && (
            <>
              <Spacer size={6} />
              <Flex align="center" width="fit-content" margin="auto">
                <AudioPlayer
                  audioFileId={instructionDetails.audioFile.id}
                  src={instructionDetails.audioFile.url}
                  showSkipButtons={false}
                  placeholder="Listen to the Instructions"
                />
              </Flex>
            </>
          )}
          <Spacer size={6} />
          {assessment?.instructions && (
            <>
              <InstructionsContainer $textSize={textSize}>
                {assessment?.instructions}
              </InstructionsContainer>
              <Spacer size={6} />
            </>
          )}
          {!assessment?.instructions && !instructionDetails?.audioFile?.url && (
            <>
              <InstructionsEmptyState
                isFreeRead={Boolean(assessment?.isFreeRead)}
                textSize={textSize}
              />
              <Spacer size={6} />
            </>
          )}

          <Flex justify="center" align="center" width="100%">
            <Button onClick={close} color="primaryBlue">
              Got it!
            </Button>
          </Flex>
        </>
      )}
    </StandardModal>
  );
};
