import { SetField } from 'src/State';
import { ImmutableList, ImmutableMap } from 'src/modules/Immutable';
import {
  Reading,
  Registration,
  User,
  CourseAssessment,
  CourseWithGoogleId,
  SubmissionVisibility,
} from 'src/models';
import { CreateToast } from 'src/components';

export type CourseRegistrationAssessmentData = {
  student: User;
  registration: Registration;
  assigned: boolean;
  submissionVisibility: SubmissionVisibility;
};

type CourseAssessmentAssignedData = {
  courseId: string;
  assigned: boolean;
  courseAssessment?: CourseAssessment;
};

export type AssignToStateResponse = {
  courses: ImmutableList<CourseWithGoogleId>;
  courseRegistrationAssessments: ImmutableMap<
    string,
    ImmutableList<CourseRegistrationAssessmentData>
  >;
  assessmentTitle: string;
  courseAssessmentsAssigned: ImmutableList<CourseAssessmentAssignedData>;
};

export type AssignToState = {
  loading: boolean;
  saving: boolean;
  error: boolean;
  saved: boolean;
  courses: ImmutableList<CourseWithGoogleId>;
  assessmentId: string | null;
  title: string;
  reading: Reading | null;
  selectedCourse: CourseWithGoogleId | null;
  currRegistrationAssessments: ImmutableList<CourseRegistrationAssessmentData> | null;
  courseRegistrationAssessments: ImmutableMap<
    string,
    ImmutableList<CourseRegistrationAssessmentData>
  >;
  courseAssessmentsAssigned: ImmutableList<CourseAssessmentAssignedData>;
  submit: (createToast: CreateToast) => void;
  setField: SetField<AssignToState>;
  setSelectedCourse: (course: CourseWithGoogleId) => void;
  load: ({
    assessmentId,
    courseId,
    setDefault,
  }: {
    assessmentId: string;
    courseId?: string;
    setDefault?: boolean;
  }) => void;
  updateCheckbox: (data: CourseRegistrationAssessmentData, createToast: CreateToast) => () => void;
  updateVisibilitySettings: (
    data: CourseRegistrationAssessmentData,
    value: SubmissionVisibility,
    createToast: CreateToast,
  ) => () => void;
  updateCourseAssessment: (createToast: CreateToast) => () => void;
  optimisticUpdateCourseAssessment: () => void;
  toggleAll: (selected: boolean, createToast: CreateToast) => () => void;
};

export const defaultState = {
  loading: false,
  saving: false,
  error: false,
  saved: false,
  courses: ImmutableList<CourseWithGoogleId>(),
  selectedCourse: null,
  assessmentId: null,
  title: '',
  reading: null,
  currRegistrationAssessments: null,
  courseRegistrationAssessments: ImmutableMap<
    string,
    ImmutableList<CourseRegistrationAssessmentData>
  >(),
  courseAssessmentsAssigned: ImmutableList<CourseAssessmentAssignedData>(),
};
