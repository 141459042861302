import { useMemo, useCallback } from 'react';
import { useQuery } from 'src/modules/Router';
import { useStore } from 'src/Store';
import { ImmutableList, reverseIf } from 'src/modules/Immutable';
import { TabViewTab } from 'src/components';
import { AssessmentDetails } from 'src/models';

export const useCourseIdToAssessmentListMap = () => {
  const { courseMap, assessmentMap } = useStore((s) => s.TeacherAssignmentListData, []);
  const query = useQuery();
  const sortByParam = query.get('sortBy') || 'desc';
  const assessmentStatusFilterParam = query.get('assessmentStatusFilter') || 'active';

  const filterAndSortAssessmentList = useCallback(
    (assessmentList: ImmutableList<AssessmentDetails>) => {
      const filtered = assessmentList.filter(
        ({ assessment: { status } }) => status === assessmentStatusFilterParam,
      );

      if (sortByParam === 'alphAsc' || sortByParam === 'alphDesc') {
        return reverseIf(
          sortByParam === 'alphAsc',
          filtered.sortBy((details) => details.assessment.name),
        );
      } else {
        return reverseIf(
          sortByParam === 'desc',
          filtered.sortBy((details) => Date.parse(details.assessment.createdAt)),
        );
      }
    },
    [sortByParam, assessmentStatusFilterParam],
  );

  return useMemo(() => {
    const assessmentList = assessmentMap.toList();
    return courseMap
      .map((course) =>
        filterAndSortAssessmentList(
          assessmentList.filter(({ courseIds }) => courseIds.includes(course.id)),
        ),
      )
      .set('all', filterAndSortAssessmentList(assessmentList));
  }, [courseMap, assessmentMap, filterAndSortAssessmentList]);
};

const ADD_CLASS_TAB = {
  id: 'add-new',
  name: 'Add Class +',
  to: '/t/classes/new',
  onlyClick: true,
};

export const useCourseTabs = () => {
  const { courseMap, isPaperUser } = useStore(
    (s) => ({
      courseMap: s.TeacherAssignmentListData.courseMap,
      isPaperUser: Boolean(s.AppData.currentUser?.paperSyncedAt),
    }),
    [],
  );
  const courseIdToAssessmentListMap = useCourseIdToAssessmentListMap();

  return useMemo(() => {
    const map = courseMap
      .toList()
      .map(
        ({ id, name }): TabViewTab => ({
          id,
          name,
          count: courseIdToAssessmentListMap.get(id, ImmutableList()).size,
        }),
      )
      .unshift({
        id: 'all',
        name: 'All',
        count: courseIdToAssessmentListMap.get('all', ImmutableList()).size,
      });
    return isPaperUser ? map : map.push(ADD_CLASS_TAB);
  }, [courseIdToAssessmentListMap, courseMap, isPaperUser]);
};
