import { SimpleLayout } from 'src/layouts';
import { ErrorText, LinkButton, Text, Spacer } from 'src/components';
import { useStore } from 'src/Store';
import { useQuery } from 'src/modules/Router';
import { useEffect } from 'react';

export const SignUpConfirmationSent = () => {
  const query = useQuery();
  const email = query.get('email');
  const firstName = query.get('firstName');

  const { resend, resending, resent, load, errors } = useStore(
    (state) => state.SignUpConfirmationSentData,
    [],
  );
  useEffect(load, [load]);

  const str = `Thanks${firstName ? `  ${firstName}` : ''}!`;
  return (
    <SimpleLayout>
      <Text variant="h1" ellipsis ellipsifiedTitle={str}>
        {str}
      </Text>
      <Spacer size={5} />
      <Text>You’re almost ready to get started.</Text>
      <Spacer size={3} />
      <Text>
        We sent an email to {email || 'your email'} to verify your email to activate your Readlee
        account.
      </Text>
      <Spacer size={3} />
      <Text>
        Didn’t receive an email?{' '}
        <LinkButton onClick={resend(email)} loading={resending} disabled={resent}>
          Resend Confirmation
        </LinkButton>
      </Text>
      {resent && <Text>Reset sent!</Text>}
      {errors && <ErrorText errors={errors} errorKey="email" />}
    </SimpleLayout>
  );
};
