import { SetField } from 'src/State';
import { User, Registration, Course } from 'src/models';
import { ImmutableList, ImmutableMap, ImmutableSet } from 'src/modules/Immutable';
import { ErrorMap, emptyErrorMap } from 'src/modules/Api';
import { UserColor } from 'src/styles/Color';
import { TeacherStudentListHelpersHook } from './TeacherStudentListHelpers';

type StudentSignUp = {
  firstName: string;
  lastName: string;
  username: string;
  password: string;
  avatarColor: UserColor | null;
};

export type SetStudentSignUp = <K extends keyof StudentSignUp>(
  name: K,
) => (value: StudentSignUp[K]) => void;

export type TeacherStudentListState = {
  load: () => void;
  loading: boolean;
  changingPassword: boolean;
  passwordHidden: boolean;
  students: ImmutableList<User>;
  registrationMap: ImmutableMap<string, ImmutableList<Registration>>;
  courseMap: ImmutableMap<string, Course>;
  tempName: string;
  tempEmail: string;
  newPassword: string;
  passwordConfirmation: string;
  assignedMap: ImmutableSet<string>;
  setField: SetField<TeacherStudentListState>;
  errors: ErrorMap;
  updateStudent: (helpers: TeacherStudentListHelpersHook) => void;
  changePassword: (helpers: TeacherStudentListHelpersHook) => void;
  updateStudentClasses: (helpers: TeacherStudentListHelpersHook) => () => void;
  loadEditStudentModal: (student: User | undefined) => void;
  loadManageStudentCoursesModal: (student: User | undefined) => void;
  loadNewStudentModal: () => void;
  generatePassword: ({ newUser }: { newUser: boolean }) => () => void;
  newUser: StudentSignUp;
  setUserField: SetStudentSignUp;
  createStudent: (helpers: TeacherStudentListHelpersHook) => void;
  loadChangePasswordModal: () => void;
  newStudentErrors: ErrorMap;
  creatingNewStudent: boolean;
};

export const emptyNewUser = () => ({
  firstName: '',
  lastName: '',
  username: '',
  password: '',
  avatarColor: null,
});

export const defaultState = {
  loading: true,
  passwordHidden: true,
  students: ImmutableList<User>(),
  registrationMap: ImmutableMap<string, ImmutableList<Registration>>(),
  courseMap: ImmutableMap<string, Course>(),
  tempName: '',
  tempEmail: '',
  assignedMap: ImmutableSet<string>(),
  errors: emptyErrorMap(),
  newStudentErrors: emptyErrorMap(),
  creatingNewStudent: false,
  changingPassword: false,
  newUser: emptyNewUser(),
  newPassword: '',
  passwordConfirmation: '',
};
