import { useState } from 'react';
import { SelectOption } from 'src/components';
import { useFetch } from './Api';
import { ImmutableList } from './Immutable';

export type LanguageCode = `${string}-${string}`;
export const DEFAULT_LANGUAGE_CODE: LanguageCode = 'en-US';

export type LanguageCodeDetails = {
  name: string;
  enabled: boolean;
  beta: boolean;
  dictionaryEnabled: boolean;
};

export type LanguageCodeResponse = Record<LanguageCode, LanguageCodeDetails>;

let languageCodesFetched = false;
let languageCodesPromise: null | Promise<LanguageCodeResponse> = null;
let cachedLanguageCodes: LanguageCodeResponse = {};
export const useLangaugeCodes = (): LanguageCodeResponse => {
  const { fetchJson } = useFetch();
  const [fetched, setFetched] = useState<boolean>(languageCodesFetched);

  if (fetched) {
    return cachedLanguageCodes;
  } else {
    if (languageCodesPromise) {
      languageCodesPromise.then(() => {
        setFetched(true);
      });
    } else {
      languageCodesPromise = new Promise((resolve) => {
        fetchJson(`/api/language_codes`, {
          onSuccess: (data: { languageCodes: LanguageCodeResponse }) => {
            cachedLanguageCodes = data.languageCodes;
            languageCodesFetched = true;
            setFetched(true);
            resolve(data.languageCodes);
          },
        });
      });
    }
  }

  return cachedLanguageCodes;
};

export const useLanguageCodeDetails = (languageCode: LanguageCode): LanguageCodeDetails | null => {
  const languageCodes = useLangaugeCodes();
  return languageCodes[languageCode] || null;
};

let cachedSelectOptions: ImmutableList<SelectOption<LanguageCode>> | null = null;
export const useLanguageCodeSelectOptions = () => {
  const languageCodes = useLangaugeCodes();
  if (cachedSelectOptions && cachedSelectOptions.size > 0) return cachedSelectOptions;

  cachedSelectOptions = ImmutableList(
    Object.entries(languageCodes)
      .filter(([_, value]) => value.enabled)
      .sort(([aCode, aValue], [bCode, bValue]) => {
        if (aCode === DEFAULT_LANGUAGE_CODE) return -1;
        if (bCode === DEFAULT_LANGUAGE_CODE) return 1;
        return aValue.name.localeCompare(bValue.name);
      })
      .map(([code, value]) => ({
        value: code as LanguageCode,
        label: `${value.name}${value.beta ? ' (Beta)' : ''}`,
      })),
  );
  return cachedSelectOptions;
};
