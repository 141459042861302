import { useStore } from 'src/Store';
import { useQuery, useHistory, useParams, queryMapToString } from 'src/modules/Router';
import { capitalize } from 'src/modules/String';
import { Button, Spacer, Text, StandardModal } from 'src/components';
import { Route } from 'react-router-dom';

const InternalConfirmSwitchTypeModal = () => {
  const history = useHistory();
  const query = useQuery();
  const params = useParams<{ id: string }>();
  const { results, updateUser } = useStore((s) => s.UserSearchData, []);

  const user = results.find(({ user }) => user.id === params.id)?.user;
  if (!user) return null;

  const close = () => history.push(`/m/user-search${queryMapToString(query)}`);
  const targetType = user.accountType === 'teacher' ? 'student' : 'teacher';
  return (
    <StandardModal
      header="Warning!"
      close={close}
      openType="state"
      opened
      footer={
        <>
          <Button onClick={close} displayType="link" color="primaryBlue">
            Cancel
          </Button>
          <Spacer horizontal size={3} />
          <Button onClick={() => updateUser(user, history, query, 'accountType', targetType)}>
            Set to {capitalize(targetType)}
          </Button>
        </>
      }
    >
      <Text weightOverride="bold">
        Switching this account&apos;s user type will delete all their registrations!
      </Text>
      <Text>
        Students will no longer be registered to any classes, and teachers will not have access to
        their classes
      </Text>
    </StandardModal>
  );
};

export const ConfirmSwitchTypeModal = () => (
  <Route path="/m/user-search/:id/confirm">
    <InternalConfirmSwitchTypeModal />
  </Route>
);
