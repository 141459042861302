import { useStore } from 'src/Store';
import {
  Button,
  Flex,
  Loading,
  ReadingStudioStats,
  Spacer,
  StarRow,
  TaskButton,
  Text,
  Thumbnail,
} from 'src/components';
import {
  TaskSubmissionDetails,
  getTaskIcon,
  isTaskSubmissionCompleted,
  readingTaskSubmissionDetailsIsProcessing,
  secondaryTitleText,
} from 'src/models';
import { useQuery } from 'src/modules/Router';
import { colors, paddings } from 'src/styles';
import { useReadingTaskSubmissionDetails } from './SubmissionOverviewHelpers';
import { ConfettiDrop } from 'src/modules/Confetti';

export const SubmissionOverviewMobile = () => {
  const { loading, submission, taskSubmissionDetailsMap, assessment, user, currentUser } = useStore(
    ({ SubmissionOverviewData: s, AppData: a }) => ({
      loading: s.loading,
      submission: s.submission,
      taskSubmissionDetailsMap: s.taskSubmissionDetailsMap,
      assessment: s.assessment,
      user: s.user,
      currentUser: a.currentUser,
    }),
    [],
  );

  const query = useQuery();
  const readingTaskSubmissionDetails = useReadingTaskSubmissionDetails();

  const isStudent = currentUser?.accountType === 'student';

  const defaultBackPath = isStudent ? '/s' : `/t/assignments/${assessment?.id}`;
  const backPath = isStudent ? query.get('backPath') || '/s' : query.get('backPath');
  const processing = taskSubmissionDetailsMap.some(
    (t) => t.type === 'ReadingTask' && readingTaskSubmissionDetailsIsProcessing(t),
  );
  const { reading, coverImageFile, author } = readingTaskSubmissionDetails || {};

  if (loading || !submission) return <Loading flex />;

  return (
    <>
      <ConfettiDrop enabled={!processing} />
      <Flex
        direction="column"
        flex={1}
        backgroundColor={colors.backgroundDark.hex}
        padding={paddings[3]}
      >
        <Flex justify="space-between">
          <Button color="gray2" nav to={backPath || defaultBackPath} icon="backArrow">
            Back
          </Button>
          <Spacer horizontal size={2} />
          {isStudent && (
            <Button
              displayType="outline"
              color="white"
              to={`/reading-studio?assessmentId=${assessment?.id}`}
            >
              Edit My Submission
            </Button>
          )}
        </Flex>
        <Spacer size={6} />
        <Flex direction="column">
          <Flex direction="column" align="center">
            <Thumbnail src={coverImageFile?.thumbnailUrl} size="large" title={reading?.name} />
            <Spacer />
            <Text
              variant="h5"
              ellipsis
              ellipsifiedTitle={reading?.name || 'Unknown Book Title'}
              testTag="reading-studio-layout-top-bar-title"
              color="white"
              maxWidth="100%"
            >
              {reading?.name || 'Unknown Book Title'}
            </Text>

            <Text
              variant="h6"
              color="gray5"
              ellipsis
              ellipsifiedTitle={secondaryTitleText(assessment, author) || 'Unknown Author'}
              testTag="reading-studio-layout-top-bar-author"
              maxWidth="100%"
            >
              {secondaryTitleText(assessment, author) || 'Unknown Author'}
            </Text>
          </Flex>
          <Spacer size={6} />
          <Flex justify="center">
            <ReadingStudioStats
              processing={processing}
              user={user}
              readingTaskSubmissionDetails={readingTaskSubmissionDetails}
              hasPremiumStats={!isStudent}
              lastSubmitted={submission.finishedAt}
              isMobileView
            />
          </Flex>
          <Spacer size={6} />
          {submission.rating !== null && (
            <>
              <Flex align="center" justify="center">
                <Text variant="h6" color="white">
                  STUDENT INTEREST:
                </Text>
                <Spacer horizontal size={2} />
                <StarRow rating={submission.rating} borderColor="white" />
              </Flex>
              <Spacer size={6} />
            </>
          )}
          <Text variant="h6" color="white" textAlign="center">
            MY TASKS
          </Text>
          <Spacer />
          {assessment?.taskOrder
            .map((task) => taskSubmissionDetailsMap.get(task.taskId))
            .filter((t): t is TaskSubmissionDetails => Boolean(t))
            .map((taskSubmissionDetails) => {
              return (
                <TaskButton
                  key={taskSubmissionDetails.task.id}
                  icon={getTaskIcon(taskSubmissionDetails.task)}
                  active={false}
                  completed={
                    taskSubmissionDetails && isTaskSubmissionCompleted(taskSubmissionDetails)
                  }
                >
                  {taskSubmissionDetails.task.name}
                </TaskButton>
              );
            })}
        </Flex>
      </Flex>
    </>
  );
};
