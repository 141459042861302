import { useStore } from 'src/Store';
import { ReadingStudioLayoutSideBar } from 'src/layouts';
import { queryMapToString, useHistory, useQuery } from 'src/modules/Router';
import { useIsPublicPreview, SubmitButton } from './ReadingStudioHelpers';
import { useCompactLayout } from 'src/layouts/ReadingStudioLayout/ReadingStudioLayoutHelpers';
import { Spacer, Button, Loading, useToaster, Flex, BaseProfilePicture } from 'src/components';
import { ImmutableMap } from 'src/modules/Immutable';
import { TaskList } from './TaskList';

const ViewSubmissionButton = () => {
  const { submission, dirty, currentUser, isRecording } = useStore(
    ({ ReadingStudioData: s, AppData: a }) => ({
      submission: s.submission,
      dirty: s.dirty,
      currentUser: a.currentUser,
      isRecording: a.isRecording,
    }),
    [],
  );
  const query = useQuery();
  const isStudent = currentUser?.accountType === 'student';

  if (submission?.status !== 'finished' || dirty) return null;

  return (
    <>
      <Button
        width="100%"
        displayType="outline"
        to={`/submissions/${submission?.id}${queryMapToString(
          ImmutableMap({
            backPath: `/reading-studio${queryMapToString(query)}`,
          }),
        )}`}
        color="white"
        disabled={isRecording}
      >
        View Your {isStudent ? 'Results' : 'Submission'}
      </Button>
      <Spacer size={2} />
    </>
  );
};

export const ReadingStudioSideBar = () => {
  const { loading, submission, submitting, profileImageFile, addToLibrary } = useStore(
    ({ ReadingStudioData: s }) => ({
      loading: s.loading,
      submission: s.submission,
      submitting: s.submitting,
      profileImageFile: s.profileImageFile,
      addToLibrary: s.addToLibrary,
    }),
    [],
  );

  const { createToast } = useToaster();
  const isPublicPreview = useIsPublicPreview();
  const history = useHistory();
  const compactLayout = useCompactLayout();

  if (compactLayout) return null;
  if (loading) return <Loading flex flexWidth="400px" />;
  if (!submission) return null;

  return (
    <ReadingStudioLayoutSideBar
      primaryButton={
        isPublicPreview ? (
          <Button onClick={addToLibrary({ history, createToast })} loading={submitting}>
            Create Assignment
          </Button>
        ) : (
          <Flex direction="column">
            <ViewSubmissionButton />
            <SubmitButton />
          </Flex>
        )
      }
      content={
        <div>
          {profileImageFile && (
            <>
              <BaseProfilePicture
                data-test-tag="studio-profile-picture"
                src={profileImageFile.url}
                $height="50px"
                $width="50px"
              />
              <Spacer size={6} />
            </>
          )}
          <TaskList />
        </div>
      }
    />
  );
};
