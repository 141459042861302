// Pauses when they leave the tab and resumes when they come back

import { getUniqueId } from 'src/modules/UniqueId';

const timeoutClearMap = new Map<number, () => void>();

export const setFocusedTimeout = (fn: () => void, duration: number): number => {
  const focusedTimeoutId = getUniqueId();
  let finished = false;
  let timeSoFar = 0;
  let currentTimeoutStartedAt = new Date().valueOf();
  let currentTimeoutId: NodeJS.Timeout | null = null;

  const makeTimeout = () => {
    currentTimeoutStartedAt = new Date().valueOf();
    currentTimeoutId = setTimeout(() => {
      finished = true;
      document.removeEventListener('visibilitychange', handler);
      timeoutClearMap.delete(focusedTimeoutId);

      fn();
    }, duration - timeSoFar);
  };

  const handler = () => {
    if (finished) {
      return;
    } else if (document.visibilityState !== 'visible') {
      timeSoFar += new Date().valueOf() - currentTimeoutStartedAt;
      if (currentTimeoutId) clearTimeout(currentTimeoutId);
    } else {
      makeTimeout();
    }
  };

  const cancel = () => {
    if (currentTimeoutId) clearTimeout(currentTimeoutId);
    document.removeEventListener('visibilitychange', handler);
    timeoutClearMap.delete(focusedTimeoutId);
  };

  makeTimeout();

  document.addEventListener('visibilitychange', handler);
  timeoutClearMap.set(focusedTimeoutId, cancel);

  return focusedTimeoutId;
};

export const clearFocusedTimeout = (focusedTimeoutId: number) => {
  if (!timeoutClearMap.has(focusedTimeoutId)) return;
  const fn = timeoutClearMap.get(focusedTimeoutId);
  if (fn) {
    fn();
  }
};
