import { SetField } from 'src/State';
import { ImmutableList, ImmutableMap } from 'src/modules/Immutable';
import { Course, User, SubmissionExtended } from 'src/models';
import { TimeLabel } from 'src/components';

export type StatResponse = {
  student: User;
  completion: number;
  correctWordsSaid: number;
  wordAssignedCount: number;
  wordsCorrectPerMinute: number;
  wordsPerMinute: number;
  totalTime: number;
  wordsSaidCount: number;
  totalTasks: number;
  totalFinishedTasks: number;
  submissionCount: number;
  online: string;
  independentOnly: boolean;
};

export type CourseResponse = {
  courses: ImmutableList<Course>;
  stats: ImmutableMap<string, StatResponse>;
  teachersMap: ImmutableMap<string, User>;
};

type ProgressCache = {
  courses: ImmutableList<Course>;
  stats: ImmutableMap<string, ImmutableMap<TimeLabel, ImmutableMap<string, StatResponse>>>;
};

export type ProgressMonitoringState = {
  isLoading: boolean;
  load: (id: string, start: Date | null, dateRange: TimeLabel) => void;
  clearCache: () => void;
  courses: ImmutableList<Course>;
  students: ImmutableList<User>;
  stats: ImmutableMap<string, StatResponse>;
  cache: ProgressCache;
  setField: SetField<ProgressMonitoringState>;
  teachersMap: ImmutableMap<string, User>;
  search: string;
  dateRange: TimeLabel;
};

export const defaultState = {
  courses: ImmutableList<Course>(),
  students: ImmutableList<User>(),
  teachersMap: ImmutableMap<string, User>(),
  stats: ImmutableMap<string, StatResponse>(),
  studentSubmissionsMap: ImmutableMap<string, ImmutableList<SubmissionExtended>>(),
  cache: {
    courses: ImmutableList<Course>(),
    stats: ImmutableMap<string, ImmutableMap<TimeLabel, ImmutableMap<string, StatResponse>>>(),
  },
  isLoading: false,
  search: '',
  dateRange: 'Last 7 Days' as TimeLabel,
};
