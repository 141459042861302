import { SetField } from 'src/State';
import { ImmutableList } from 'src/modules/Immutable';
import { CreateToast } from '../Toast';
import { Word } from 'src/models';

export type DictionaryResponse = {
  definitions: ImmutableList<string>;
  synonyms: ImmutableList<string>;
  audioUrl: string | null;
  error: string | null;
  displayWord: Word;
  id: string;
  content: string;
};

export type DictionaryState = {
  open: boolean;
  displayDefinitions: boolean;
  isLoading: boolean;
  word: string;
  displayWord: Word | null;
  definitions: ImmutableList<string>;
  synonyms: ImmutableList<string>;
  audioUrl: string | null;
  error: string;
  searchDictionary: (word: string) => void;
  report: (wordId: string | null, createToast: CreateToast) => void;
  load: () => void;
  setField: SetField<DictionaryState>;
};

export const defaultState = {
  open: false,
  displayDefinitions: false,
  word: '',
  error: '',
  displayWord: null,
  definitions: ImmutableList([]),
  synonyms: ImmutableList([]),
  audioUrl: null,
  isLoading: false,
};
