import { memo } from 'react';
import styled from 'styled-components';
import { Divider, Flex, Icon, Spacer, Text, Tooltip } from 'src/components';
import { ImmutableList } from 'src/modules/Immutable';
import { StatType, ApiStat, immutableStatStyles, StatCountUp } from 'src/modules/Stat';
import { borderRadii, colors, paddings, useBreakpoints } from 'src/styles';

type StatProps = {
  id: StatType;
  value: number;
  showMobile?: boolean;
};

const StatsItemContainer = styled.div<{ $showMobile: boolean }>`
  min-height: ${({ $showMobile }) => ($showMobile ? '0px' : '56px')};
  ${({ $showMobile }) => ($showMobile ? 'width: 45%' : '')};
`;

const StatItem = ({ id, value, showMobile = false }: StatProps) => {
  const stat = immutableStatStyles.get(id);
  if (!stat) return null;
  return (
    <StatsItemContainer $showMobile={showMobile}>
      <Flex align="stretch" height={showMobile ? 'inherit' : '100%'} padding={paddings[2]}>
        <Flex align="center">
          <Icon
            icon={stat.icon}
            strokeWidth={0}
            size={showMobile ? stat.mobileIconSize : stat.iconSize}
            color="primaryBlue"
            fill
          />
        </Flex>
        <Spacer horizontal size={4} />
        <Flex direction="column" justify="space-between" align="start">
          <Text variant={showMobile ? 'h3' : 'h2'} weightOverride="medium">
            <StatCountUp stat={stat} value={value} />
          </Text>
          <Flex align="center">
            <Text variant="h7" color="gray5" whiteSpace="nowrap">
              {stat.label}
            </Text>
            <Spacer horizontal size={1} />
            <Tooltip
              disabled={!stat?.tooltip}
              content={stat?.tooltip ? <Text color="black">{stat.tooltip}</Text> : ''}
            />
          </Flex>
        </Flex>
      </Flex>
    </StatsItemContainer>
  );
};

const BorderedFlex = styled(Flex)`
  border: 1px solid ${colors.gray2.hex};
`;

type StatsRowProps = {
  stats: ImmutableList<ApiStat | 'div'>;
  showMobile: boolean;
};
const StatsRow = ({ stats, showMobile }: StatsRowProps) => (
  <>
    {stats
      .interpose('div')
      .map((stat, idx) =>
        stat === 'div' ? (
          <Divider key={`div-${idx}`} vertical size={1} />
        ) : (
          <StatItem key={stat.name} id={stat.name} value={stat.value} showMobile={showMobile} />
        ),
      )}
  </>
);

const UnmemoizedStatsBanner = ({ stats }: { stats: ImmutableList<ApiStat | 'div'> }) => {
  const showMobile = useBreakpoints({ smallerThanOrEqualTo: 'tabletSmall' });

  return showMobile ? (
    <BorderedFlex
      justify="space-between"
      align="stretch"
      borderRadius={borderRadii[4]}
      padding={`${paddings[4]} ${paddings[3]}`}
      backgroundColor={colors.white.hex}
      direction="column"
    >
      <Flex justify="center">
        <StatsRow stats={stats.slice(0, stats.size / 2)} showMobile={showMobile} />
      </Flex>
      <Divider size={1} noMargin />
      <Flex justify="center">
        <StatsRow stats={stats.slice(stats.size / 2)} showMobile={showMobile} />
      </Flex>
    </BorderedFlex>
  ) : (
    <BorderedFlex
      justify="space-between"
      align="stretch"
      borderRadius={borderRadii[4]}
      padding={paddings[4]}
      backgroundColor={colors.white.hex}
    >
      <StatsRow stats={stats} showMobile={showMobile} />
    </BorderedFlex>
  );
};

export const StatsBanner = memo(UnmemoizedStatsBanner);
