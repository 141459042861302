import { Fragment, ReactNode, useState } from 'react';
import styled from 'styled-components';
import { useStore } from 'src/Store';
import {
  userColors,
  colors,
  paddings,
  Breakpoints,
  useBreakpoints,
  margins,
  zIndices,
} from 'src/styles';
import {
  LogoButton,
  DropdownMenu,
  Button,
  Flex,
  Spacer,
  UserAvatar,
  Icon,
  BodyPortal,
  FlexGrow,
  Text,
  NoStylesButton,
  getLogoType,
  Divider,
} from 'src/components';
import {
  TopLevelNavLink,
  NavButtons,
  topLevelLinks,
  mobileNavLinks,
  dropdownLinks,
  TopBarLink,
} from 'src/layouts/TopBar/TopBarHelpers';
import { emptyExtendedUser } from 'src/models';
import { ImmutableList } from 'src/modules/Immutable';

const TopBarStyles = styled.div<{ min?: Breakpoints; max?: Breakpoints }>`
  border-bottom: 1px solid ${colors.gray2.hex};
  padding: ${paddings[2]};
  align-items: center;

  display: flex;
`;

const MenuOverlay = styled.div<{ $opened: boolean }>`
  display: flex;
  align-items: start;
  justify-content: center;
  background: ${colors.backgroundDark.hex};
  position: fixed;
  overflow-y: auto;
  z-index: ${zIndices.raised};

  top: 53px;
  bottom: 0;
  left: 0;
  right: 0;
`;

type TopBarProps = {
  navLinksOverride?: ImmutableList<TopBarLink>;
  showPrimaryAction?: boolean;
  primaryAction?: ReactNode;
  secondaryAction?: ReactNode;
  mobileAction?: ReactNode;
  alwaysShowSecondaryAction?: boolean;
  showProfile?: boolean;
  logoDisabled?: boolean;
};

export const TopBar = ({
  navLinksOverride,
  primaryAction,
  secondaryAction,
  mobileAction,
  showProfile = true,
  showPrimaryAction = true,
  alwaysShowSecondaryAction = false,
  logoDisabled = false,
}: TopBarProps) => {
  const isMobileWebviewOpened = window.localStorage.getItem('mobileWebviewOpened') === 'true';

  const [opened, setOpened] = useState(false);
  const showMobileLayout = useBreakpoints({ smallerThanOrEqualTo: 'desktopSmall' });

  const { loading, currentUser, currentPlan } = useStore(
    (state) => ({
      loading: state.AppData.loading,
      currentUser: state.AppData.currentUser ?? { ...emptyExtendedUser },
      currentPlan: state.AppData.currentPlan,
    }),
    [],
  );

  const mobileLinks =
    navLinksOverride || mobileNavLinks(currentUser, Boolean(currentUser.paperSyncedAt));
  const showMobileNav =
    !isMobileWebviewOpened &&
    (mobileLinks.size > 0 || (secondaryAction && !alwaysShowSecondaryAction) || showPrimaryAction);

  const readleePoints =
    !loading && currentUser && currentUser.accountType === 'student' ? (
      <>
        <Spacer horizontal size={4} />
        <NoStylesButton to="/s/avatar" id="reading-points">
          <Text testTag="point-balance">{currentUser.pointBalance}</Text>
          <Spacer horizontal size={1} />
          <Icon icon="token" size="1.75em" color="white" />
        </NoStylesButton>
      </>
    ) : null;

  const logoType = getLogoType(currentUser, currentPlan);

  return (
    <TopBarStyles>
      {!isMobileWebviewOpened && (
        <Flex align="center">
          {showMobileNav && showMobileLayout && (
            <Button
              onClick={() => setOpened(!opened)}
              displayType="iconOnly"
              icon={opened ? 'close' : 'menu'}
              iconSize={opened ? '0.5em' : '3em'}
              color="white"
              width="24px"
              height="24px"
              testTag="mobile-nav-toggle"
              paddingOverride={paddings.none}
            />
          )}

          <LogoButton
            size={logoType === 'paper-reading' ? 'medium' : 'small'}
            logoType={logoType}
            disabled={logoDisabled}
          />

          {currentUser.paperSyncedAt && !showMobileLayout && (
            <>
              <Spacer horizontal size={4} />
              <Button
                displayType="outline"
                color="paperBlue"
                icon="paperLogo"
                iconStrokeWidth={0}
                to="/back-to-paper"
                height="40px"
              >
                <Spacer horizontal />
                Back To Paper
              </Button>
              <Spacer horizontal size={4} />
              <Divider
                color={colors.gray4.hex}
                vertical
                size={0.5}
                noMargin
                endMargin={margins[2]}
              />
            </>
          )}
          <Spacer horizontal size={8} />
        </Flex>
      )}
      {isMobileWebviewOpened && <Spacer horizontal size={2} />}
      {(!showMobileLayout || isMobileWebviewOpened) && (
        <Flex align="center">
          {(navLinksOverride ?? topLevelLinks(currentUser, Boolean(currentUser.paperSyncedAt))).map(
            (props, i) => (
              <Fragment key={i}>
                <TopLevelNavLink textColor="gray6" {...props} />
                <Spacer horizontal size={isMobileWebviewOpened ? 4 : 12} />
              </Fragment>
            ),
          )}
        </Flex>
      )}
      <FlexGrow />
      {!showMobileLayout && !isMobileWebviewOpened && (
        <Flex align="center">
          {showPrimaryAction ? primaryAction || <NavButtons /> : null}
          {secondaryAction ? (
            <>
              <Spacer size={2} horizontal />
              {secondaryAction}
            </>
          ) : null}
          {readleePoints}
          {showProfile && (
            <>
              <Spacer horizontal size={4} />
              <DropdownMenu
                testTag="profile-settings-button"
                color="white"
                displayType="noStyles"
                rightAligned
                noButtonPadding
                options={dropdownLinks(currentUser, Boolean(currentUser.paperSyncedAt))
                  .map((l) => ({
                    ...l,
                    children: l.label,
                    to: l.path,
                    href: l.href,
                  }))
                  .toArray()}
              >
                <UserAvatar
                  name={currentUser.name || ''}
                  backgroundColor={currentUser.avatarColor || userColors[0]}
                  disabledTooltip
                  user={currentUser}
                  showAvatar={currentUser.accountType === 'student'}
                  imageUrl={currentUser.profileImageFile?.url}
                />
                <Spacer horizontal />
                <Icon icon="arrowDown" />
              </DropdownMenu>
            </>
          )}
          <Spacer horizontal size={2} />
        </Flex>
      )}
      {showMobileLayout && alwaysShowSecondaryAction && secondaryAction ? (
        <Flex align="center">{secondaryAction}</Flex>
      ) : null}
      {showMobileLayout && !isMobileWebviewOpened && readleePoints && (
        <>
          {readleePoints}
          <Spacer size={4} horizontal />
        </>
      )}
      {((showMobileLayout && mobileAction) || isMobileWebviewOpened) && (
        <Flex align="center">{mobileAction}</Flex>
      )}
      <BodyPortal>
        {opened && showMobileNav && showMobileLayout && (
          <MenuOverlay $opened={opened}>
            <Flex
              direction="column"
              height="100%"
              width="100%"
              padding={`${paddings[5]}`}
              testTag="mobile-overlay"
            >
              <Spacer size={5} />
              {mobileLinks.map((props, i) => (
                <Fragment key={i}>
                  <TopLevelNavLink upperCase onClick={() => setOpened(false)} {...props} />
                  <Spacer size={6} />
                </Fragment>
              ))}
              <FlexGrow />
              {showPrimaryAction ? primaryAction || <NavButtons /> : null}
              {secondaryAction ? (
                <>
                  <Spacer size={2} horizontal />
                  {secondaryAction}
                </>
              ) : null}
            </Flex>
          </MenuOverlay>
        )}
      </BodyPortal>
    </TopBarStyles>
  );
};
