import party, { sources, variation, random } from 'party-js';
import { useEffect, useRef, memo } from 'react';
import { confettiColors } from 'src/styles';
import type { EmitterConstructionOptions } from 'party-js/lib/particles/emitter';
import styled from 'styled-components';

party.resolvableShapes['book'] = `<svg width="20" height="16" viewBox="0 0 20 16" fill="none">
<path d="M3.0595 0.360107C3.05897 0.360107 3.05836 0.360107 3.05782 0.360107C2.86866 0.360107 2.69075 0.433845 2.5564 0.567852C2.42058 0.703341 2.34576 0.883745 2.34576 1.0758V11.8075C2.34576 12.201 2.66718 12.522 3.06233 12.523C4.7282 12.527 7.51918 12.8742 9.44458 14.889V3.65803C9.44458 3.52463 9.4105 3.39931 9.34619 3.29561C7.76592 0.750681 4.72914 0.364013 3.0595 0.360107Z" />
<path d="M17.6547 11.8076V1.0758C17.6547 0.883745 17.5798 0.703341 17.444 0.567852C17.3097 0.433845 17.1316 0.360107 16.9427 0.360107C16.9421 0.360107 16.9415 0.360107 16.9409 0.360107C15.2713 0.36408 12.2346 0.750748 10.6542 3.29567C10.5899 3.39938 10.5559 3.5247 10.5559 3.6581V14.889C12.4813 12.8742 15.2723 12.527 16.9382 12.523C17.3332 12.522 17.6547 12.201 17.6547 11.8076Z" />
<path d="M19.2842 2.83496H18.7652V11.8074C18.7652 12.8122 17.9466 13.6316 16.9402 13.6341C15.5272 13.6374 13.1974 13.9137 11.5474 15.4754C14.4011 14.7767 17.4095 15.2309 19.124 15.6216C19.338 15.6704 19.5592 15.62 19.7308 15.4833C19.9017 15.3469 19.9997 15.1431 19.9997 14.9243V3.55052C19.9998 3.15597 19.6787 2.83496 19.2842 2.83496Z" />
<path d="M1.23448 11.8074V2.83496H0.715558C0.321079 2.83496 0 3.15597 0 3.55052V14.9241C0 15.1429 0.0980475 15.3467 0.268957 15.4831C0.440338 15.6197 0.661349 15.6703 0.875761 15.6214C2.59024 15.2306 5.59867 14.7765 8.45228 15.4752C6.80231 13.9136 4.47247 13.6374 3.05947 13.634C2.05321 13.6316 1.23448 12.8122 1.23448 11.8074Z" />
</svg>`;

const emit = (source: HTMLDivElement, angle: number, options: EmitterConstructionOptions = {}) =>
  party.scene.current.createEmitter({
    emitterOptions: { loops: 1, duration: 8, ...options.emitterOptions },
    rendererOptions: { shapeFactory: ['square', 'circle', 'star', 'book'] },
    emissionOptions: {
      angle: variation.skew(angle, variation.evaluateVariation(variation.range(35, 45))),
      rate: 0,
      bursts: [{ time: 0, count: variation.range(20, 40) }],
      initialLifetime: 8,
      initialSpeed: variation.range(300, 600),
      initialSize: variation.skew(1, 0.2),
      initialRotation: () => random.randomUnitVector().scale(180),
      initialColor: confettiColors.map(party.Color.fromHex),
      sourceSampler: sources.elementSource(source),
      ...options,
      ...options.emissionOptions,
    },
  });

const ConfettiContainer = styled.div`
  position: absolute;
  top: -20px;
  width: 100%;
`;

export const UnmemoizedConfettiDrop = ({ enabled }: { enabled: boolean }) => (
  <ConfettiContainer>
    <ConfettiEmitter
      enabled={enabled}
      angle={270}
      loop={false}
      options={{
        emissionOptions: {
          rate: 40,
          bursts: [{ time: 0, count: 0 }],
          initialSpeed: 100,
          initialSize: 1,
        },
        emitterOptions: {
          duration: 2,
        },
      }}
    />
  </ConfettiContainer>
);

export const ConfettiDrop = memo(UnmemoizedConfettiDrop);

type ConfettiEmitterProps = {
  enabled: boolean;
  angle: number;
  options?: EmitterConstructionOptions;
  style?: Record<string, string | undefined>;
  loop: boolean;
};

const UnmemoizedConfettiEmitter = ({
  enabled,
  angle,
  options = {},
  style = {},
  loop,
}: ConfettiEmitterProps) => {
  const confettiRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    let id: ReturnType<typeof setInterval> | null = null;

    const doIt = () => {
      if (!enabled || !confettiRef.current) return;

      emit(confettiRef.current, angle, options);

      if (!loop) return;

      id = setInterval(() => {
        if (!confettiRef.current) return;
        emit(confettiRef.current, angle, options);
      }, 1250);
    };

    doIt();

    return () => {
      if (id) clearInterval(id);
      party.scene.current.clearEmitters();
    };
  }, [angle, enabled, options, loop]);

  if (!enabled) return null;

  return <div style={{ height: '1px', ...style }} ref={confettiRef}></div>;
};

export const ConfettiEmitter = memo(UnmemoizedConfettiEmitter);
