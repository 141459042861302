import { useCallback, useMemo } from 'react';
import {
  Flex,
  Tooltip,
  Spacer,
  SuperTable,
  SuperTableColumns,
  AvatarCard,
  UserAvatar,
  Text,
  EmptyState,
  SuperTableColumn,
  Icon,
  StarRow,
  DropdownMenu,
} from 'src/components';
import { useQuery, useHistory, queryMapToString } from 'src/modules/Router';
import { ImmutableList, ImmutableMap, partition } from 'src/modules/Immutable';
import { User, Submission, SubmissionExtended, formatName, Assessment, Comment } from 'src/models';
import { useStore } from 'src/Store';
import { formatDateTime } from 'src/modules/Time';
import { capitalize } from 'src/modules/String';
import { formatStat, StatStyle, statStyles } from 'src/modules/Stat';
import { colors } from 'src/styles';

const Empty = () => (
  <Text color="gray5" testTag="empty">
    -
  </Text>
);

const EmptyAssignedStudents = () => (
  <Flex height="100%" width="100%" justify="center">
    <EmptyState kind="assignedStudents" />
  </Flex>
);

const getSubmissionSecondaryText = (
  submission: Submission | undefined,
  accountType: User['accountType'],
) => {
  if (accountType !== 'student') return 'Teacher Submission';
  if (!submission) return '';
  if (submission.status === 'waiting') return 'Not yet started';
  if (submission.status === 'finished') return 'Submitted';
  return `${capitalize(submission.status)}`;
};

const renderStat = ({
  userId,
  key,
  style,
  submissionMap,
}: {
  userId: string;
  key: 'finishedAt' | 'wcpm' | 'totalWords' | 'timeRead' | 'percentRead' | 'rating';
  style?: StatStyle;
  submissionMap: ImmutableMap<string, SubmissionExtended>;
}) => {
  const submission = submissionMap.get(userId);
  if (!submission || submission.status === 'waiting') return <Empty />;
  const value = submission[key];
  if (key === 'rating' && typeof value === 'number') {
    return <StarRow rating={value} borderColor="black" />;
  } else if (typeof value === 'number' && style) {
    return formatStat(style, value);
  } else if (typeof value === 'string') {
    return formatDateTime(new Date(value));
  } else {
    return <Empty />;
  }
};

export const useTableData = () => {
  const query = useQuery();
  const {
    assessmentDetail,
    submissionMap,
    userMap,
    courseToStudentIdsMap,
    taskCompletionMap,
    commentMap,
    openDeleteSubmissionModal,
  } = useStore(
    ({ TeacherAssignmentOverviewData: taod }) => ({
      assessmentDetail: taod.assessmentDetail,
      submissionMap: taod.submissionMap,
      userMap: taod.userMap,
      courseToStudentIdsMap: taod.courseToStudentIdsMap,
      taskCompletionMap: taod.taskCompletionMap,
      commentMap: taod.commentMap,
      openDeleteSubmissionModal: taod.openDeleteSubmissionModal,
    }),
    [],
  );
  const selectedClassParam = query.get('selectedClass') || 'all';

  const filteredRows = useMemo(
    () =>
      userMap.toList().filter((u) => {
        if (u.accountType !== 'student' && !submissionMap.has(u.id)) return false;
        if (selectedClassParam === 'teachers') return u.accountType !== 'student';

        return (
          selectedClassParam === 'all' ||
          courseToStudentIdsMap.get(selectedClassParam, ImmutableList()).includes(u.id)
        );
      }),
    [courseToStudentIdsMap, selectedClassParam, userMap, submissionMap],
  );

  const [rows, teacherRows] = useMemo(
    () => partition(filteredRows, (u) => u.accountType === 'student'),
    [filteredRows],
  );

  const getRowId = useCallback((user: User) => user.id, []);

  const renderName = useCallback(
    (user: User) => {
      const submission = submissionMap.get(user.id);
      return (
        <AvatarCard
          testTag="submission-user-name"
          avatar={
            <UserAvatar
              name={user.displayName}
              backgroundColor={user.avatarColor || colors.primaryBlue.hex}
            />
          }
          primaryText={formatName(user)}
          textColor={submission && submission?.status !== 'waiting' ? 'black' : 'gray5'}
          secondaryText={getSubmissionSecondaryText(submission, user.accountType)}
        />
      );
    },
    [submissionMap],
  );

  const renderFeedback = useCallback(
    (user: User) => {
      const submission = submissionMap.get(user.id);
      const comments = commentMap.get(submission?.id || '', ImmutableList<Comment>());
      const viewedComments = comments.filter((c) => c.viewed);
      const allViewed = viewedComments.size >= comments.size;

      if (!submission || comments.isEmpty()) {
        return (
          <Tooltip content="No feedback was sent to this student.">
            <Empty />
          </Tooltip>
        );
      }

      const icon = allViewed ? 'feedbackViewed' : 'feedbackGiven';
      return (
        <Tooltip content={`${viewedComments.size}/${comments.size} comments viewed`}>
          <Icon
            icon={icon}
            color={allViewed ? 'success' : 'black'}
            size="1.75em"
            testTag={`feedback-icon-${allViewed ? 'feedbackViewed' : 'feedbackGiven'}`}
          />
        </Tooltip>
      );
    },
    [commentMap, submissionMap],
  );

  const sortByComments = useCallback(
    (user: User) => {
      const submission = submissionMap.get(user.id);
      const comments = commentMap.get(submission?.id || '', ImmutableList<Comment>());
      const viewedComments = comments.filter((c) => c.viewed);

      if (comments.isEmpty()) return 0;
      if (viewedComments.size < comments.size) return 1;
      if (viewedComments.size === comments.size) return 2;
      return -1;
    },
    [commentMap, submissionMap],
  );

  const sortByNumber = useCallback(
    (key: 'wcpm' | 'totalWords' | 'timeRead' | 'percentRead' | 'rating') =>
      ({ id }: { id: string }) => {
        const submission = submissionMap.get(id);
        if (!submission || submission.status === 'waiting') return -1;
        if (key === 'rating' && submission[key] === null) return -1;
        return submission[key] ?? -1;
      },
    [submissionMap],
  );

  const renderPercentRead = useCallback(
    (user: User) =>
      renderStat({
        userId: user.id,
        key: 'percentRead',
        style: statStyles.completion,
        submissionMap,
      }),
    [submissionMap],
  );

  const percentReadColumn: SuperTableColumn<User> = useMemo(
    () => ({
      id: 'percentRead',
      name: '% Read',
      render: renderPercentRead,
      sortType: 'sortBy',
      sortBy: sortByNumber('percentRead'),
    }),
    [renderPercentRead, sortByNumber],
  );

  const commentsColumn: SuperTableColumn<User> = useMemo(
    () => ({
      id: 'feedback',
      name: 'Feedback',
      render: renderFeedback,
      sortType: 'sortBy',
      sortBy: sortByComments,
    }),
    [renderFeedback, sortByComments],
  );

  const columns: SuperTableColumns<User> = [
    {
      id: 'name',
      name: 'Name',
      render: renderName,
      sortType: 'sortBy',
      sortBy: formatName,
    },
    ...(commentMap.isEmpty() ? [] : [commentsColumn]),
    {
      id: 'rating',
      name: 'Student Interest',
      tooltipContent: 'Student response to "How interesting was the reading?"',
      render: useCallback(
        ({ id }) => renderStat({ userId: id, key: 'rating', submissionMap }),
        [submissionMap],
      ),
      sortType: 'sortBy',
      sortBy: sortByNumber('rating'),
    },
    ...(assessmentDetail?.assessment.independentOnly ? [] : [percentReadColumn]),
    {
      id: 'wcpm',
      name: assessmentDetail?.assessment.independentOnly ? '(WPM)' : 'WCPM',
      tooltipContent: assessmentDetail?.assessment.independentOnly
        ? 'Words Per Minute'
        : 'Words Correct Per Minute',
      render: useCallback(
        ({ id }) =>
          renderStat({
            userId: id,
            key: 'wcpm',
            style: assessmentDetail?.assessment.independentOnly ? statStyles.wpm : statStyles.wcpm,
            submissionMap,
          }),
        [submissionMap, assessmentDetail],
      ),
      sortType: 'sortBy',
      sortBy: sortByNumber('wcpm'),
    },
    {
      id: 'total_words',
      name: 'Words',
      render: useCallback(
        ({ id }) =>
          renderStat({
            userId: id,
            key: 'totalWords',
            style: statStyles.total_words,
            submissionMap,
          }),
        [submissionMap],
      ),
      sortType: 'sortBy',
      sortBy: sortByNumber('totalWords'),
    },
    {
      id: 'time_read',
      name: 'Time',
      render: useCallback(
        ({ id }) =>
          renderStat({
            userId: id,
            key: 'timeRead',
            style: statStyles.time_taken,
            submissionMap,
          }),
        [submissionMap],
      ),
      sortType: 'sortBy',
      sortBy: sortByNumber('timeRead'),
    },
    {
      id: 'tasks',
      name: 'Tasks',
      render: useCallback(
        ({ id }) => {
          const studentTasks = taskCompletionMap.get(id);
          return studentTasks ? (
            <Text variant="h6">
              {studentTasks.completed} / {studentTasks.total}
            </Text>
          ) : (
            <Empty />
          );
        },
        [taskCompletionMap],
      ),
      sortType: 'sortBy',
      defaultSortDirection: 'desc',
      sortBy: useCallback(
        ({ id }) => taskCompletionMap.get(id)?.completed ?? 0,
        [taskCompletionMap],
      ),
    },
    {
      id: 'submission_date',
      name: 'Date',
      render: useCallback(
        ({ id }) =>
          renderStat({
            userId: id,
            key: 'finishedAt',
            submissionMap,
          }),
        [submissionMap],
      ),
      sortType: 'sortBy',
      sortBy: useCallback(
        ({ id }) => new Date(submissionMap.get(id, { finishedAt: 1 }).finishedAt).valueOf(),
        [submissionMap],
      ),
    },
    {
      id: 'actions',
      name: 'Actions',
      render: (student) => {
        const submission = submissionMap.get(student.id);
        return (
          <Flex align="center" justify="end">
            <DropdownMenu
              rightAligned
              displayType="iconOnly"
              color="white"
              iconColor="gray7"
              icon="threeVerticalDots"
              testTag="submission-settings"
              inheritBgColor
              noButtonPadding
              options={[
                {
                  children: 'Delete Submission',
                  onClick: () => submission && openDeleteSubmissionModal(student, submission),
                  disabled: !submission,
                  color: 'danger',
                },
              ]}
            />
          </Flex>
        );
      },
      sortType: 'none',
    },
  ];

  return { rows, teacherRows, columns, getRowId };
};

export const AssignmentSubmissionList = ({
  assessment,
  rows,
  teacherRows,
  columns,
  getRowId,
  submissionMap,
}: {
  assessment: Assessment;
  rows: ImmutableList<User>;
  teacherRows: ImmutableList<User>;
  columns: SuperTableColumns<User>;
  getRowId: (user: User) => string;
  submissionMap: ImmutableMap<string, Submission>;
}) => {
  const query = useQuery();
  const history = useHistory();

  return (
    <>
      <SuperTable
        columns={columns}
        rows={rows}
        getRowId={getRowId}
        rowClickable={({ id }) => Boolean(submissionMap.get(id, { id: null }).id)}
        queryParam="sortBy"
        onRowClick={({ id }) => {
          const submissionId = submissionMap.get(id, { id: null }).id;
          if (!id) return;

          const backPath = `/t/assignments/${assessment.id}${queryMapToString(query)}`;
          history.push(
            `/submissions/${submissionId}${queryMapToString(ImmutableMap({ backPath }))}`,
          );
        }}
        fixedTopRows={teacherRows}
        emptyStateContent={<EmptyAssignedStudents />}
      />
      <Spacer size={22} />
    </>
  );
};
