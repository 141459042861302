import { useCallback, ReactNode, MutableRefObject, KeyboardEventHandler, ChangeEvent } from 'react';
import { Flex, Icon, IconType, Spacer } from 'src/components';
import { AutoCompleteOption, StyledInput } from './InputHelpers';

export type InputOnChange = (newValue: string, evt: React.ChangeEvent<HTMLInputElement>) => void;

type InputProps = {
  /** ID of the input */
  id?: string;
  /** Type of text input */
  type?: 'email' | 'text' | 'password';
  /** Disable input **/
  disabled?: boolean;
  /** Value of input */
  value: string;
  /** Name for input in form */
  name: string;
  /** Label for input in form */
  label?: ReactNode;
  /** Event handler for change event */
  onChange: InputOnChange;
  /** Icon for the input */
  icon?: IconType;
  iconPosition?: 'left' | 'right';
  placeholder?: string;
  autoComplete?: AutoCompleteOption;
  innerRef?: MutableRefObject<HTMLInputElement | null>;
  onKeyDown?: KeyboardEventHandler<HTMLDivElement>;
  testTag?: string;
  flexGrow?: number;
  fontSize?: number;
};

export const Input = ({
  onChange,
  disabled,
  icon,
  innerRef,
  flexGrow,
  fontSize,
  iconPosition = 'left',
  ...basicProps
}: InputProps) => {
  const realOnChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => onChange(event.target.value, event),
    [onChange],
  );

  return icon ? (
    <Flex align="center">
      {iconPosition === 'left' && (
        <>
          <Icon icon={icon} size="1.5em" />
          <Spacer horizontal size={2} />
        </>
      )}
      <StyledInput
        {...basicProps}
        disabled={disabled}
        onChange={realOnChange}
        $displayType="flat"
        $fontSize={fontSize}
        ref={innerRef}
        $flexGrow={flexGrow}
      />
      {iconPosition === 'right' && (
        <>
          <Spacer horizontal size={2} />
          <Icon icon={icon} size="1.5em" />
        </>
      )}
    </Flex>
  ) : (
    <StyledInput
      {...basicProps}
      data-test-tag={basicProps.testTag}
      disabled={disabled}
      onChange={realOnChange}
      ref={innerRef}
    />
  );
};
