import { useEffect, useCallback } from 'react';
import {
  AudioPlayer,
  DiffViewer,
  Flex,
  Loading,
  NoContent,
  ComprehensionHeader,
  Drawer,
  Spacer,
  Subscribe,
  Text,
  Title,
  TaskSubmissionViewer,
  VocabDrawerHeader,
  EmptyStateBase,
  UpgradeAssignmentModal,
} from 'src/components';
import { ReadingStudioLayout } from 'src/layouts';
import { useQuery, useParams } from 'src/modules/Router';
import { ImmutableList } from 'src/modules/Immutable';
import { useStore } from 'src/Store';
import {
  usageCapFeatureEnabled,
  ErrorDispute,
  readingTaskSubmissionDetailsIsErrored,
  readingTaskSubmissionDetailsIsProcessing,
} from 'src/models';
import Bookworm from 'src/assets/bookworm.svg';

import { SubmissionOverviewSideBar } from './SubmissionOverviewSideBar';
import { SubmissionOverviewTopBar } from './SubmissionOverviewTopBar';
import {
  useCurrentTaskSubmissionDetails,
  useReadingTaskSubmissionDetails,
} from './SubmissionOverviewHelpers';
import { CommentModal } from './CommentModal';
import { SubmissionOverviewMobile } from './SubmissionOverviewMobile';
import { useBreakpoints } from 'src/styles';

const DrawerContent = () => {
  const {
    initialPlayProgress,
    playIndex,
    submission,
    drawerExpanded,
    assessment,
    setField,
    currentUser,
    currentPlan,
    startAssignmentUpgrade,
  } = useStore(
    (state) => ({
      assessment: state.SubmissionOverviewData.assessment,
      initialPlayProgress: state.SubmissionOverviewData.initialPlayProgress,
      playIndex: state.SubmissionOverviewData.playIndex,
      submission: state.SubmissionOverviewData.submission,
      drawerExpanded: state.SubmissionOverviewData.drawerExpanded,
      setField: state.SubmissionOverviewData.setField,
      currentUser: state.AppData.currentUser,
      currentPlan: state.AppData.currentPlan,
      startAssignmentUpgrade: state.UpgradeAssignmentModalData.startAssignmentUpgrade,
    }),
    [],
  );
  const currentTaskSubmissionDetails = useCurrentTaskSubmissionDetails();

  const textDiffs =
    currentTaskSubmissionDetails?.type === 'ComprehensionTask'
      ? currentTaskSubmissionDetails.responseAudioFile?.transcriptItems
      : undefined;

  if (!currentTaskSubmissionDetails) return null;

  const usageCapsEnabled = currentPlan.isUsageCapsEnabled;
  const availableUltimateAssessments = currentUser?.ultimateAssessmentsAvailable ?? 0;
  const content = (
    <TaskSubmissionViewer
      showStats={false}
      submission={submission || undefined}
      taskDetails={currentTaskSubmissionDetails}
      taskSubmissionDetails={currentTaskSubmissionDetails}
      playIndex={playIndex}
      initialPlayProgress={initialPlayProgress}
      drawerExpanded={drawerExpanded}
      textSize={currentUser?.textSize}
      canSeeTranscription={usageCapFeatureEnabled(
        'isComprehensionTranscriptEnabled',
        currentUser,
        assessment,
        true,
      )}
      comprehensionTextDiff={textDiffs}
      usageCapsEnabled={usageCapsEnabled}
      availableUltimateAssessments={availableUltimateAssessments}
      toolTipOnClick={() =>
        startAssignmentUpgrade(assessment?.id ?? '', 'comprehensionTranscription')
      }
    />
  );

  if (currentTaskSubmissionDetails.type === 'ReadingTask') {
    return <Drawer expanded={drawerExpanded}>{content}</Drawer>;
  } else if (currentTaskSubmissionDetails.type === 'ComprehensionTask') {
    return (
      <Drawer
        header={
          <ComprehensionHeader
            currentTaskSubmissionDetails={currentTaskSubmissionDetails}
            displayType={drawerExpanded ? 'drawerOpen' : 'drawerClosed'}
            toggleOpen={() => setField('drawerExpanded')(!drawerExpanded)}
            textSize={currentUser?.textSize}
          />
        }
        expanded={drawerExpanded}
      >
        {content}
      </Drawer>
    );
  } else if (currentTaskSubmissionDetails.type === 'ModelTask') {
    return (
      <Drawer
        header={
          currentTaskSubmissionDetails.audioFile ? (
            <Flex align="center">
              <AudioPlayer
                displayType="desktopSmall"
                src={currentTaskSubmissionDetails.audioFile?.url}
                audioFileId={currentTaskSubmissionDetails.audioFile?.id}
                placeholder="Listen to Model Reading"
                testTag="play-model-reading"
              />
            </Flex>
          ) : null
        }
        expanded={drawerExpanded}
      >
        {content}
      </Drawer>
    );
  } else if (currentTaskSubmissionDetails.type === 'VocabTask') {
    return (
      <Drawer
        header={<VocabDrawerHeader currentTaskSubmissionDetails={currentTaskSubmissionDetails} />}
      >
        {content}
      </Drawer>
    );
  } else {
    throw new Error('Unrecognized task type');
  }
};

const MainContent = () => {
  const {
    setPlayProgress,
    textDiffMap,
    errorDisputesMap,
    taskSubmissionDetailsMap,
    transcriptionVisible,
    currentUser,
    currentPlan,
    assessment,
    startAssignmentUpgrade,
  } = useStore(
    (state) => ({
      assessment: state.SubmissionOverviewData.assessment,
      setPlayProgress: state.SubmissionOverviewData.setPlayProgress,
      textDiffMap: state.SubmissionOverviewData.textDiffMap,
      errorDisputesMap: state.SubmissionOverviewData.errorDisputesMap,
      taskSubmissionDetailsMap: state.SubmissionOverviewData.taskSubmissionDetailsMap,
      transcriptionVisible: state.SubmissionOverviewData.transcriptionVisible,
      currentUser: state.AppData.currentUser,
      currentPlan: state.AppData.currentPlan,
      startAssignmentUpgrade: state.UpgradeAssignmentModalData.startAssignmentUpgrade,
    }),
    [],
  );

  const startUpgrade = useCallback(
    () => startAssignmentUpgrade(assessment?.id ?? '', 'fullTranscription'),
    [assessment?.id, startAssignmentUpgrade],
  );
  const readingTaskSubmissionDetails = useReadingTaskSubmissionDetails();
  const currentTaskSubmissionDetails = useCurrentTaskSubmissionDetails();

  if (!readingTaskSubmissionDetails) return null;

  const errored = taskSubmissionDetailsMap.some(
    (t) => t.type === 'ReadingTask' && readingTaskSubmissionDetailsIsErrored(t),
  );
  const processing = taskSubmissionDetailsMap.some(
    (t) => t.type === 'ReadingTask' && readingTaskSubmissionDetailsIsProcessing(t),
  );

  const isStudent = currentUser?.accountType === 'student';
  const textDiff = textDiffMap.get(readingTaskSubmissionDetails.task.id);
  const errorDisputes = errorDisputesMap.get(
    readingTaskSubmissionDetails.task.id,
    ImmutableList<ErrorDispute>(),
  );

  if (errored) {
    return (
      <Flex height="100%" width="100%" align="center" justify="center">
        <Text variant="h1">
          There was an error processing this submission, please check the recording.
        </Text>
      </Flex>
    );
  } else if (processing) {
    return (
      <Flex height="100%" width="100%" align="center" justify="center">
        <Text variant="h1">Processing</Text>
        <Spacer horizontal size={2} />
        <Loading size="large" />
      </Flex>
    );
  } else if (!transcriptionVisible) {
    return (
      <Flex width="100%" justify="center" grow={1}>
        <Flex
          direction="column"
          align="center"
          justify="center"
          maxWidth="700px"
          width="100%"
          height="100%"
        >
          <EmptyStateBase
            testTag="empty-state-submission-hidden"
            illustration={Bookworm}
            primaryText="Listen to your recording and check out your reading stats!"
          />
        </Flex>
      </Flex>
    );
  } else if (textDiff && textDiff.prunedItems.size > 0) {
    const isIndependent = readingTaskSubmissionDetails.reading.contentType === 'independent';
    const blur = !usageCapFeatureEnabled(
      'isFullTranscriptionEnabled',
      currentUser,
      assessment,
      true,
    );
    const blurAll = blur && isIndependent;

    const usageCapsEnabled = currentPlan.isUsageCapsEnabled;
    const availableUltimateAssessments = currentUser?.ultimateAssessmentsAvailable ?? 0;

    return (
      <DiffViewer
        textDiff={textDiff}
        errorDisputes={errorDisputes}
        onTimeSelected={setPlayProgress}
        showAdditions={!isStudent}
        showReportError={isStudent}
        disabled={currentTaskSubmissionDetails?.type !== 'ReadingTask'}
        blur={blur}
        blurAll={blurAll}
        startAssignmentUpgrade={startUpgrade}
        usageCapsEnabled={usageCapsEnabled}
        availableUltimateAssessments={availableUltimateAssessments}
      />
    );
  } else {
    return (
      <NoContent>
        {isStudent ? 'You have not submitted audio' : 'Student did not submit audio'}
      </NoContent>
    );
  }
};

export const SubmissionOverview = () => {
  const { id } = useParams<{ id: string }>();
  const query = useQuery();
  const currentQueryTaskId = query.get('currentTaskId');
  const openComments = query.get('openComments') === 'true';

  const {
    loading,
    load,
    submission,
    assessment,
    onSubmissionStatus,
    setCurrentTask,
    user,
    drawerExpanded,
    upgradeAssessment,
  } = useStore(
    ({ SubmissionOverviewData: s }) => ({
      loading: s.loading,
      load: s.load,
      submission: s.submission,
      assessment: s.assessment,
      onSubmissionStatus: s.onSubmissionStatus,
      setCurrentTask: s.setCurrentTask,
      user: s.user,
      drawerExpanded: s.drawerExpanded,
      upgradeAssessment: s.upgradeAssessment,
    }),
    [],
  );

  useEffect(() => {
    load({ id, openComments });
  }, [load, id, openComments]);

  useEffect(() => {
    setCurrentTask(
      currentQueryTaskId ??
        assessment?.taskOrder?.find((t) => t.type === 'ReadingTask')?.taskId ??
        null,
    );
  }, [setCurrentTask, currentQueryTaskId, assessment]);

  const readingTaskSubmissionDetails = useReadingTaskSubmissionDetails();

  const showMobileLayout = useBreakpoints({ smallerThanOrEqualTo: 'tabletSmall' });

  if (!readingTaskSubmissionDetails || !submission) return <Loading flex kind="boat" />;
  return (
    <>
      <Title title={`${assessment?.name} - ${user?.displayName}`} />

      {submission && (
        <Subscribe channel="SubmissionChannel" id={submission.id} onMessage={onSubmissionStatus} />
      )}
      {showMobileLayout ? (
        <SubmissionOverviewMobile />
      ) : (
        <>
          <ReadingStudioLayout
            showTopBarDivider
            loading={loading}
            topBar={<SubmissionOverviewTopBar />}
            sideBar={<SubmissionOverviewSideBar />}
            mainContent={
              <Flex
                minHeight={0}
                height="100%"
                width="100%"
                overflowY="auto"
                align="center"
                direction="column"
              >
                <MainContent />
              </Flex>
            }
            drawerContent={<DrawerContent />}
            drawerExpanded={drawerExpanded}
          />
          <CommentModal />
          <UpgradeAssignmentModal onSuccess={upgradeAssessment} />
        </>
      )}
    </>
  );
};
