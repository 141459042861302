import { memo } from 'react';
import ReactCountUp from 'react-countup';

type CountUpProps = {
  value: number;
  duration?: number;
};

export const UnmemoizedCountUp = ({ value, duration = 1.5 }: CountUpProps) => {
  return (
    <ReactCountUp end={value} duration={duration} formattingFn={(v) => v.toLocaleString('en-US')} />
  );
};

export const CountUp = memo(UnmemoizedCountUp);
