import { useEffect, useMemo } from 'react';
import { useToaster } from 'src/components';
import { ImmutableSet } from 'src/modules/Immutable';
import { useHistory, useQuery, useLocation } from 'src/modules/Router';
import { useStore } from 'src/Store';

export const SyncLms = () => {
  const { createToast } = useToaster();
  const { syncAllCourses, courses } = useStore(
    (s) => ({
      syncAllCourses: s.TeacherCourseListData.syncAllCourses,
      courses: s.TeacherCourseListData.courses,
    }),
    [],
  );
  const history = useHistory();
  const query = useQuery();
  const pathname = useLocation().pathname;
  const isGoogle = useMemo(() => query.get('isGoogle') === 'true', [query]);
  const coursesToSync = useMemo(() => {
    const courseIds = ImmutableSet(query.get('courseIds')?.split(','));
    return courses.filter((c) => courseIds.has(c.id));
  }, [courses, query]);

  useEffect(() => {
    syncAllCourses(coursesToSync, history, pathname, createToast, isGoogle)();
  }, [history, createToast, syncAllCourses, coursesToSync, isGoogle, pathname]);

  return null;
};
