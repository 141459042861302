import { AssessmentDetails, Course, User, Assessment } from 'src/models';
import { ImmutableMap, ImmutableSet } from 'src/modules/Immutable';
import { SetField } from 'src/State';
import { History } from 'src/modules/Router';

export type TeacherAssignmentListState = {
  loading: boolean;
  assessmentMap: ImmutableMap<string, AssessmentDetails>;
  courseMap: ImmutableMap<string, Course>;
  studentMap: ImmutableMap<string, User>;

  openAssignmentCardSet: ImmutableSet<string>;
  updateAssessments: (updatedAssessment: Assessment) => void;
  toggleAssignmentCardOpen: (value: string) => void;
  search: string;
  load: (history: History, pathname: string) => void;
  setField: SetField<TeacherAssignmentListState>;
};

export const defaultState = {
  loading: true,
  assessmentMap: ImmutableMap<string, AssessmentDetails>(),
  courseMap: ImmutableMap<string, Course>(),
  studentMap: ImmutableMap<string, User>(),
  readingNameMap: ImmutableMap<string, string>(),
  openAssignmentCardSet: ImmutableSet<string>(),
  search: '',
};
