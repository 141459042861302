import { useEffect, useCallback } from 'react';
import { useStore } from 'src/Store';
import { useQuery, useHistory, queryMapToString } from 'src/modules/Router';
import {
  Button,
  Flex,
  SuperTable,
  Form,
  FormGroup,
  Spacer,
  SuperTableColumn,
  DropdownMenu,
  Text,
} from 'src/components';
import { UserSearchResult } from './UserSearchState';
import { ConfirmSwitchTypeModal, GrantTrialModal, DeleteAccountModal } from './modals';
import { formatDateTime } from 'src/modules/Time';
import { copyTextToClipboard } from 'src/modules/Clipboard';

export const UserSearch = () => {
  const { searchQuery, submit, setField, results, loading, load, generalError, updateUserSchool } =
    useStore((s) => s.UserSearchData, []);

  const query = useQuery();
  const history = useHistory();
  const realSubmit = useCallback(() => submit(history, query), [history, query, submit]);
  const searchQueryParam = query.get('query');

  useEffect(() => {
    load({ searchQuery: searchQueryParam || '', onSuccess: realSubmit });
  }, [searchQueryParam, load, realSubmit]);

  const columns: Array<SuperTableColumn<UserSearchResult>> = [
    {
      id: 'name',
      name: 'Display Name',
      sortType: 'sortBy',
      render: ({ user }) => <Text>{user.displayName}</Text>,
      sortBy: ({ user }) => user.displayName,
    },
    {
      id: 'email',
      name: 'Email / Username',
      sortType: 'sortBy',
      render: ({ user }) => <Text>{user.email ?? user.username}</Text>,
      sortBy: ({ user }) => user.email ?? user.username,
    },
    {
      id: 'schoolName',
      name: 'School Name',
      render: ({ school }) => <Text>{school?.name || '--'}</Text>,
      sortBy: ({ school }) => school?.name || '--',
      sortType: 'sortBy',
    },
    {
      id: 'schoolRole',
      name: 'School Role',
      render: ({ userSchool }) => <Text>{userSchool?.role || '--'}</Text>,
      sortBy: ({ userSchool }) => userSchool?.role || 'z',
      sortType: 'sortBy',
    },
    {
      id: 'planName',
      name: 'Plan Type',
      render: ({ plan }) => (
        <Text textAlign="right" color={plan?.isBasicPlan ? 'danger' : 'black'}>
          {plan?.name || 'No Plan'}
        </Text>
      ),
      sortBy: ({ plan }) => plan?.name || 'No Plan',
      sortType: 'sortBy',
      textAlign: 'right',
    },
    {
      id: 'subscriptionEndDate',
      name: 'Period ends at',
      render: ({ subscription }) => (
        <Text textAlign="right">
          {subscription?.currentPeriodEndsAt
            ? formatDateTime(new Date(subscription?.currentPeriodEndsAt))
            : '--'}
        </Text>
      ),
      sortBy: ({ subscription }) => new Date(subscription?.currentPeriodEndsAt ?? 0).valueOf(),
      sortType: 'sortBy',
      textAlign: 'right',
    },
    {
      id: 'role',
      name: 'Role',
      render: ({ user }) => (
        <Text textAlign="right" testTag={`${user.id}-accountType`}>
          {user.accountType}
        </Text>
      ),
      sortBy: ({ user }) => user.accountType,
      sortType: 'sortBy',
      textAlign: 'right',
    },
    {
      id: 'settings',
      name: 'Actions',
      textAlign: 'right',
      sortType: 'none',
      render: ({ user, userSchool }) => (
        <Flex align="center" justify="end">
          <DropdownMenu
            rightAligned
            displayType="iconOnly"
            color="white"
            iconColor="gray7"
            icon="threeVerticalDots"
            iconSize="2em"
            testTag="settings"
            inheritBgColor
            noButtonPadding
            options={[
              {
                children: 'Copy User ID',
                onClick: async () => await copyTextToClipboard(user.id),
              },
              {
                children: `Set to ${user.accountType === 'teacher' ? 'Student' : 'Teacher'}`,
                to: `/m/user-search/${user.id}/confirm${queryMapToString(query)}`,
              },
              ...(user.accountType !== 'teacher'
                ? []
                : [
                    {
                      children: 'Grant Trial',
                      to: `/m/user-search/${user.id}/grant-trial${queryMapToString(query)}`,
                    },
                  ]),
              ...(!userSchool || user.accountType === 'student'
                ? []
                : [
                    {
                      children:
                        userSchool.role === 'admin' ? 'Demote Admin' : 'Promote to School Admin',
                      onClick: () =>
                        updateUserSchool(
                          userSchool,
                          userSchool.role === 'admin' ? 'teacher' : 'admin',
                          history,
                          query,
                        ),
                    },
                  ]),

              {
                children: 'Delete Account',
                color: 'danger',
                to: `/m/user-search/${user.id}/delete${queryMapToString(query)}`,
              },
            ]}
          />
        </Flex>
      ),
    },
  ];

  return (
    <Form onSubmit={realSubmit}>
      <Flex direction="column" width="100%" align="center">
        <Flex width="100%" maxWidth="1000px" align="center">
          <FormGroup
            type="text"
            name="query"
            placeholder="Search by User Name or Email, or by School name!"
            value={searchQuery}
            onChange={setField('searchQuery')}
          />
          <Spacer horizontal />
          <Button onClick={realSubmit} loading={loading}>
            Search
          </Button>
        </Flex>
        <Flex direction="column" width="100%" maxWidth="1000px">
          {results.isEmpty() ? (
            <Flex>No users to display</Flex>
          ) : (
            <>
              {generalError}
              <SuperTable
                queryParam="sortBy"
                columns={columns}
                rows={results}
                getRowId={({ user }) => user.id}
              />
            </>
          )}
        </Flex>
      </Flex>
      <ConfirmSwitchTypeModal />
      <DeleteAccountModal />
      <GrantTrialModal />
    </Form>
  );
};
