import { MILLISECONDS_IN_SECOND, SECONDS_IN_MINUTE } from 'src/modules/Time';
import { getSupportedMimeTypes, MimeTypeDetails, MimeTypeError } from 'src/modules/Media';
import { reportError } from './ErrorReporting';
import * as Storage from 'src/modules/Storage';
// Hack to deal with storybook's webpack not being able to handle these worklets
// Parcel does not parse these tags, so any code in these if statements will be
// included by parcel but ignored by storybook's webpack builder.
/// #if !STORYBOOK
import audioRecordingVolumeWorkletUrl from 'worklet:src/workers/AudioRecordingWorker.ts';
/// #endif

const DATA_INTERVAL_SECONDS = SECONDS_IN_MINUTE;
const RECORDING_DATA_INTERVAL = MILLISECONDS_IN_SECOND * DATA_INTERVAL_SECONDS;
export const AUDIO_RECORDING_RETRY_SCHEDULE = [0, 100, 1000, 10000];
export const MAX_DATA_AVAILABLE_WAITS = DATA_INTERVAL_SECONDS;

let microphoneDeviceId: string | undefined = undefined;
let microphoneDeviceIdFetched = false;

export const getCurrentMicrophone = () => {
  if (microphoneDeviceIdFetched) {
    return microphoneDeviceId;
  }
  microphoneDeviceId = Storage.getItem('selectedMicrophoneDeviceId') ?? undefined;
  microphoneDeviceIdFetched = true;
  return microphoneDeviceId;
};

export const setCurrentMicrophone = (value: string) => {
  microphoneDeviceId = value;
  microphoneDeviceIdFetched = true;
  Storage.setItem('selectedMicrophoneDeviceId', value);
};

export const openMediaStream = async () => {
  const currentMic = getCurrentMicrophone();
  const audioOptions = currentMic
    ? {
        deviceId: getCurrentMicrophone(),
      }
    : { audio: true };
  const mediaStream = await navigator.mediaDevices.getUserMedia({
    audio: audioOptions,
  });

  return mediaStream;
};

type MicrophoneAccessState = 'granted' | 'unsupported' | 'failed';
export const checkMicrophoneAccess = async (): Promise<MicrophoneAccessState> => {
  if (
    !(
      typeof MediaRecorder !== 'undefined' &&
      typeof MediaStream !== 'undefined' &&
      navigator.mediaDevices &&
      navigator.mediaDevices.getUserMedia
    )
  )
    return 'unsupported';

  try {
    const mediaStream = await openMediaStream();
    closeMediaStream(mediaStream);
    return 'granted';
  } catch (err) {
    return 'failed';
  }
};

export type VolumeMeasurementMessage = {
  type: 'volume-measurement';
  mostRecentVolume: number;
  smoothedVolume: number;
};
type AudioRecordingWorkerMessage = VolumeMeasurementMessage;

type StartRecordingOptions = {
  onDataAvailable: (blob: Blob, event: BlobEvent) => unknown;
  onVolumeLevel: (message: VolumeMeasurementMessage) => unknown;
  onStop: (event: Event) => unknown;
  onStart?: () => void;
};

export const webmAudioMimeTypeDetails: MimeTypeDetails = {
  mediaType: 'audio',
  contentType: 'webm',
  codec: null,
  mimeType: 'audio/webm',
};
const ORDERED_AUDIO_MIME_TYPE_DETAILS: MimeTypeDetails[] = [
  webmAudioMimeTypeDetails,
  { mediaType: 'audio', contentType: 'mp4', codec: null, mimeType: 'audio/mp4' },
];

export const startRecording = async ({
  onDataAvailable,
  onStop,
  onVolumeLevel,
  onStart,
}: StartRecordingOptions) => {
  const mimeTypeDetails = ORDERED_AUDIO_MIME_TYPE_DETAILS.find((mtd) =>
    MediaRecorder.isTypeSupported(mtd.mimeType),
  );
  if (!mimeTypeDetails) {
    const supportedMimeTypes = getSupportedMimeTypes('audio');
    const error = new MimeTypeError(
      `Unable to find a mime type that works on this machine. See metadata to see what this machine appears to be able to support.`,
      supportedMimeTypes,
    );
    reportError(error);
    return null;
  }
  let mediaStream: MediaStream | null = null;
  try {
    mediaStream = await openMediaStream();
  } catch (error) {
    reportError(error);
    return null;
  }

  let trackingVolume = false;
  let audioContext: AudioContext | null = null;
  let workletNode: AudioWorkletNode | null = null;
  let microphone: MediaStreamAudioSourceNode | null = null;
  // Hack to deal with storybook's webpack not being able to handle these worklets
  // Parcel does not parse these tags, so any code in these if statements will be
  // included by parcel but ignored by storybook's webpack builder.
  /// #if !STORYBOOK
  try {
    audioContext = new AudioContext();
    await audioContext.audioWorklet.addModule(audioRecordingVolumeWorkletUrl);
    microphone = audioContext.createMediaStreamSource(mediaStream);

    workletNode = new AudioWorkletNode(audioContext, 'vumeter');
    workletNode.port.onmessage = (event: MessageEvent<AudioRecordingWorkerMessage>) => {
      if (event.data.type === 'volume-measurement') {
        onVolumeLevel(event.data);
      }
    };
    microphone.connect(workletNode).connect(audioContext.destination);
    trackingVolume = true;
  } catch (e) {
    // Ignore if we can't set up the volume checker
    reportError(e);
  }
  /// #endif

  const mediaRecorder = new MediaRecorder(mediaStream, {
    mimeType: mimeTypeDetails.mimeType,
  });

  mediaRecorder.addEventListener('dataavailable', (event) => {
    const blob = event.data;
    if (blob.size > 0) {
      onDataAvailable(blob, event);
    }
  });

  mediaRecorder.addEventListener<'error'>('error', (event) => {
    reportError(event, { state: mediaRecorder.state });
  });

  mediaRecorder.addEventListener('stop', (event: Event) => {
    onStop(event);
    audioContext?.close();
    workletNode?.disconnect();
    microphone?.disconnect();
  });

  mediaRecorder.start(RECORDING_DATA_INTERVAL);

  if (onStart) onStart();

  return {
    mediaStream,
    mediaRecorder,
    mimeTypeDetails,
    trackingVolume,
  };
};

type StopRecordingOptions = {
  mediaRecorder?: MediaRecorder;
  mediaStream?: MediaStream;
};

export const closeMediaStream = (mediaStream: MediaStream | undefined) => {
  mediaStream?.getTracks().forEach((track) => track.stop());
};

export const stopRecording = ({ mediaRecorder, mediaStream }: StopRecordingOptions) => {
  mediaRecorder?.stop();
  closeMediaStream(mediaStream);
};
