import { ErrorMapResponse } from 'src/modules/Api';
import { ImmutableList, ImmutableMap } from 'src/modules/Immutable';
import { TaskType } from './Task';
import { SubmissionExtended } from './Submission';
import {
  TaskSubmissionDetails,
  isTaskSubmissionCompleted,
  LicenseType,
  Author,
  RecordingSequence,
  TranscriptableAudioStoredFile,
} from '.';
import { formatStat, statStyles } from 'src/modules/Stat';
import { Flex, Icon, MetricProps, Spacer, Text } from 'src/components';
import { formatDate } from 'src/modules/Time';

export type PublishingStatus = 'private' | 'public_preview' | 'school' | 'district';

export type Assessment = {
  id: string;
  name: string;
  instructions: string;
  taskOrder: ImmutableList<{ taskId: string; type: TaskType }>;
  createdAt: string;
  status: 'active' | 'deleted' | 'archived';
  publishingStatus: PublishingStatus;
  isSample: boolean;
  isFreeRead: boolean;
  isUltimate: boolean;
};

export type InstructionDetails = {
  instructionsRecordingSequence: RecordingSequence | null;
  audioFile: TranscriptableAudioStoredFile | null;
};

export type AssessmentExtended = Assessment & {
  coverImageUrl: string | undefined;
  estimatedReadingTime: number;
  independentOnly: boolean;
  totalWords: number;
};

export const PUBLIC_PREVIEW_PATH = '/t/public-preview';

export type DuplicateAssessmentData = {
  success: boolean;
  errors: ErrorMapResponse;
  assessment: Assessment;
};

export type AssessmentDetails = {
  assessment: AssessmentExtended;
  totalWords: number;
  coverImageUrl?: string;
  averageRating: number | null;
  submissions: ImmutableList<SubmissionExtended>;
  courseIds: ImmutableList<string>;
  userIds: ImmutableList<string>;
  primaryLicenseType: LicenseType | null;
  readingName: string;
};

export const allTasksDone = (
  assessment: Assessment | null,
  taskSubmissionDetailsMap: ImmutableMap<string, TaskSubmissionDetails>,
) =>
  assessment?.taskOrder?.every((to) => {
    const taskSubmissionDetails = taskSubmissionDetailsMap.get(to.taskId);
    if (!taskSubmissionDetails) return true;
    return isTaskSubmissionCompleted(taskSubmissionDetails);
  });

export const assignmentMetrics = ({
  assessment,
  totalWords,
  submissions,
  userIds,
  averageRating,
}: AssessmentDetails) => {
  const finishedSubmissions = submissions.filter(
    (s) => s.status === 'finished' && userIds.includes(s.userId),
  );

  const avgWpcm = Math.ceil(
    finishedSubmissions.map((s) => s.wcpm).reduce((a, b) => a + b, 0) / finishedSubmissions.size ||
      0,
  );

  const avgMinutes =
    finishedSubmissions.map((s) => s.duration).reduce((a, b) => a + b, 0) /
      finishedSubmissions.size || 0;

  const metrics = ImmutableList<MetricProps>(
    [
      ...(averageRating !== null
        ? [
            {
              label: 'Avg. Student Interest',
              value: (
                <Flex align="center">
                  <Text variant="h4">{averageRating.toFixed(1)}</Text>
                  <Spacer horizontal />
                  <Icon size="0.75em" icon="star" color="warning" fillColor="warning" fill />
                </Flex>
              ),
            },
          ]
        : []),
      ...(!assessment.independentOnly
        ? [
            {
              label: 'Total Words',
              value: formatStat(statStyles.total_words, totalWords || 0),
            },
          ]
        : []),
      {
        label: 'Submissions',
        value: `${finishedSubmissions.size}/${userIds.size}`,
      },
    ].concat(
      avgWpcm !== 0
        ? [
            {
              label: 'Avg. Time',
              value: formatStat(statStyles.time_taken, avgMinutes),
            },
            {
              label: assessment.independentOnly ? 'Avg. WPM' : 'Avg. WCPM',
              value: formatStat(statStyles.wcpm, avgWpcm || 0),
            },
          ]
        : [],
    ),
  );

  return metrics;
};

export const getInstructions = (assessment?: AssessmentExtended | null | undefined) => {
  if (!assessment) return '';
  if (assessment.instructions) return assessment.instructions;
  if (assessment.isFreeRead) return 'Record yourself reading from any text out loud!';
  if (assessment.independentOnly)
    return 'Read from your own copy of the reading, based on your teacher’s directions.';
  return 'Complete the tasks in the "My Tasks" list.';
};

export const secondaryTitleText = (
  assessment: Assessment | null | undefined,
  author: Author | null | undefined,
) => {
  if (author) return author.name;
  if (assessment?.isFreeRead) {
    return formatDate(new Date(assessment.createdAt), { includeYear: true });
  }

  return '';
};

export const assessmentIsShared = (status: PublishingStatus) => status !== 'private';
