import { ReactNode } from 'react';
import { useStore } from 'src/Store';
import { Loading } from '../Loading';

type WaitForUserProps = {
  children: ReactNode;
};

export const WaitForUser = ({ children }: WaitForUserProps) => {
  const { currentUser } = useStore(
    (s) => ({
      currentUser: s.AppData.currentUser,
    }),
    [],
  );

  return currentUser ? <>{children}</> : <Loading flex kind="boat" />;
};
