import styled, { css } from 'styled-components';
import {
  Flex,
  Icon,
  Spacer,
  Text,
  Button,
  Loading,
  IconType,
  PremiumTooltip,
  NoButtonThumbnail,
  Tag,
  Tooltip,
} from 'src/components';
import { ReadingSearchResult, ownershipTypeLabel, gradeLevelText, GradeLevel } from 'src/models';
import { paddings, colors, borderRadii } from 'src/styles';
import { useStore } from 'src/Store';
import { formatNumber } from 'src/modules/Stat';
import { ImmutableList } from 'src/modules/Immutable';
import { ReadingTileProps } from './ReadingTile';

const TagsContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: end;
  overflow: hidden;
  height: 100%;
`;

const TileTemplate = styled.div<{ $disabled: boolean }>`
  height: 250px;
  width: 100%;
  border: 1px solid ${colors.gray2.hex};
  border-radius: ${borderRadii[2]};
  padding: ${paddings[5]};
  display: flex;
  flex-direction: column;
  cursor: pointer;
  background-color: ${colors.white.hex};

  ${({ $disabled }) =>
    $disabled
      ? 'cursor: not-allowed;'
      : css`
          &:hover {
            background-color: ${colors.white.active};
          }
        `}
`;

type TileLabelProps = { icon: IconType; label: string; iconFill?: boolean; reversed?: boolean };

const TileLabel = ({ icon, label, iconFill = false, reversed = false }: TileLabelProps) => {
  return (
    <Flex align="center" direction={reversed ? 'row-reverse' : 'row'}>
      <Icon size="20px" color="gray6" icon={icon} flexShrink={0} fill={iconFill} />
      <Spacer horizontal size={2} />
      <Text variant="h6" color="gray6" ellipsis title={label}>
        {label}
      </Text>
    </Flex>
  );
};
type TileStatsProps = {
  result: ReadingSearchResult;
  gradeLevels: ImmutableList<GradeLevel>;
  reversed?: boolean;
};

export const TileStats = ({ result, gradeLevels, reversed = false }: TileStatsProps) => {
  const isBasicPlan = useStore((s) => s.AppData.currentPlan?.isBasicPlan ?? true, []);
  const paperSyncedAt = useStore((s) => s.AppData.currentUser?.paperSyncedAt, []);

  return (
    <Flex direction="column" minWidth={0}>
      <TileLabel
        icon="typedText"
        iconFill
        label={result.reading?.totalWords !== 0 ? formatNumber(result.reading?.totalWords) : '--'}
        reversed={reversed}
      />
      <TileLabel
        icon="folder"
        label={ownershipTypeLabel(result.ownershipType, isBasicPlan, Boolean(paperSyncedAt))}
        reversed={reversed}
      />
      <Spacer />
      <TileLabel
        icon="graduationCap"
        label={gradeLevelText(
          result.reading.gradeLevelId,
          result.reading.autoGradeLevelId,
          gradeLevels,
        )}
        reversed={reversed}
      />
    </Flex>
  );
};

export const TeacherReadingTile = ({ id, result, testTag }: ReadingTileProps) => {
  const { gradeLevels, loading, getAssignments, hasContentLibrary, setField } = useStore(
    (s) => ({
      gradeLevels: s.ContentLibraryData.gradeLevels,
      loading: s.ContentLibraryData.loading,
      getAssignments: s.ContentLibraryData.getAssignments,
      setField: s.ContentLibraryData.setField,
      hasContentLibrary: s.AppData.currentUser?.flags.isReadleeLibraryContentEnabled,
    }),
    [],
  );

  return (
    <PremiumTooltip
      enabled={!hasContentLibrary && result.ownershipType === 'library'}
      content="Unlock Unlimited Readlee Content"
      linkLocation="plans"
    >
      <Button
        id={id}
        displayType="noStyles"
        onClick={() => getAssignments(result, () => setField('loading')(false))}
        testTag={testTag}
        disabled={!hasContentLibrary && result.ownershipType === 'library'}
        width="100%"
      >
        <TileTemplate
          $disabled={!hasContentLibrary && result.ownershipType === 'library'}
          data-test-tag={`reading-${result.reading.id}`}
        >
          {loading ? (
            <Loading flex />
          ) : (
            <>
              <Flex>
                <NoButtonThumbnail
                  src={result.imageStoredFile?.thumbnailUrl ?? undefined}
                  testTag="reading-studio-layout-top-bar-image"
                  size="small"
                  title={result.reading.name}
                />
                <Spacer horizontal size={2} />
                <TileStats result={result} gradeLevels={gradeLevels} />
              </Flex>
              <Spacer size={5} />
              <Flex direction="column">
                <Text variant="h3" title={result.reading.name} ellipsis>
                  {result.reading.name}
                </Text>
                {result.author && (
                  <Text variant="h6" color="gray6" title={result.author.name} ellipsis>
                    {result.author.name.toUpperCase()}
                  </Text>
                )}
              </Flex>
              <Spacer size={6} />
              <TagsContainer>
                {result.tags.slice(0, 2).map((tag, i) => (
                  <Flex direction="column" key={i}>
                    <Tag color="gray7">
                      <Text
                        variant="h6"
                        lineHeightOverride="120%"
                        title={tag.name}
                        overflowY="hidden"
                        whiteSpace="nowrap"
                        ellipsis
                        maxWidth="80px"
                      >
                        {tag.name}
                      </Text>
                    </Tag>
                    <Spacer />
                  </Flex>
                ))}
                {result.tags.size > 2 && (
                  <Tooltip
                    content={
                      <Flex wrap="wrap" justify="center">
                        {result.tags.slice(2).map((tag, i) => (
                          <Flex direction="column" key={i}>
                            <Tag color="gray7">
                              <Text
                                variant="h6"
                                lineHeightOverride="120%"
                                title={tag.name}
                                whiteSpace="nowrap"
                              >
                                {tag.name}
                              </Text>
                            </Tag>
                            <Spacer />
                          </Flex>
                        ))}
                      </Flex>
                    }
                  >
                    <Tag color="gray7">
                      <Text variant="h6" lineHeightOverride="120%" whiteSpace="nowrap">
                        + {result.tags.slice(2).size}
                      </Text>
                    </Tag>
                    <Spacer />
                  </Tooltip>
                )}
              </TagsContainer>
            </>
          )}
        </TileTemplate>
      </Button>
    </PremiumTooltip>
  );
};
