import { subDataCreator } from 'src/State';
import { ErrorMapResponse, makeQueryString, emptyErrorMap } from 'src/modules/Api';
import { User, Course, School } from 'src/models';
import { ImmutableList, ImmutableMap, ImmutableSet } from 'src/modules/Immutable';
import { defaultState } from './SignUpRegisterState';
import { reportError } from 'src/modules/ErrorReporting';

type SubmitResponse = {
  user: User;
  course: Course;
  errors: ErrorMapResponse;
  success: boolean;
};

export const createDefaultSignUpRegisterData = subDataCreator(
  'SignUpRegisterData',
  defaultState,
  ({ get, set, fetchJson, getFull }) => ({
    load: ({ accountType, courseCode, schoolCode, query, history }) => {
      const errorsInQuery = query.get('errors');
      let errors = emptyErrorMap();
      if (errorsInQuery) {
        try {
          errors = ImmutableMap(JSON.parse(errorsInQuery) as ErrorMapResponse);
        } catch (err) {
          reportError(err);
        }
      }
      set({
        ...defaultState,
        loading: true,
        errors,
        user: { ...defaultState.user, accountType, email: query.get('email', '') },
      });
      const sso = query.get('sso');
      const fieldsMissingStr = query.get('fieldsMissing');
      set({
        sso,
        fieldsMissing: fieldsMissingStr
          ? ImmutableSet(fieldsMissingStr.split(','))
          : ImmutableSet(),
      });
      if (sso && !query.has('fieldsMissing')) {
        fetchJson(`/api/${sso}_oauths/set_attributes`, {
          method: 'POST',
          data: {
            accountType,
            courseCode,
            schoolCode,
            courseCodeDeclined: !courseCode,
          },
          onSuccess: () => {
            history.replace(`/${sso}-oauth-callback?oauthComplete=true`);
          },
        });
      } else {
        if (courseCode && accountType === 'student') {
          fetchJson(`/api/course_codes/${encodeURIComponent(courseCode)}`, {
            onSuccess: ({ course }: { course: Course | null }) => {
              set({ course, loading: false });
            },
          });
        }
        if (schoolCode && accountType === 'teacher') {
          fetchJson(`/api/school_codes/${encodeURIComponent(schoolCode)}`, {
            onSuccess: ({ school }: { school: School | null }) => {
              set({ school, loading: false });
            },
          });
        }
        if (!courseCode && !schoolCode) {
          set({ loading: false });
        }
      }
    },
    setUserField: (key) => (value) => {
      set((state) => ({
        user: {
          ...state.user,
          [key]: value,
        },
      }));
    },
    submit: (history, query) => () => {
      const { user: originalUser, course: originalCourse, school, sso } = get();
      set({ submitting: true });
      const referralCode = query.get('referralCode');
      const userParams = {
        ...originalUser,
        name: originalCourse?.disableName
          ? null
          : `${originalUser.firstName} ${originalUser.lastName}`.trim(),
        storedGivenName: originalUser.firstName.trim(),
        storedFamilyName: originalUser.lastName.trim(),
        passwordConfirmation: originalCourse?.disableEmail
          ? originalUser.passwordConfirmation
          : originalUser.newPassword,
      };
      if (sso) {
        fetchJson(`/api/${sso}_oauths/set_attributes`, {
          method: 'POST',
          data: {
            email: userParams.email,
            name: userParams.name,
            accountType: userParams.accountType,
            courseCode: originalCourse?.courseCode,
            courseCodeDeclined: !originalCourse?.courseCode,
            schoolCode: school?.schoolCode,
          },
          onSuccess: () => {
            history.replace(`/${sso}-oauth-callback?oauthComplete=true`);
          },
        });
      } else {
        fetchJson(`/api/users`, {
          method: 'POST',
          data: {
            sso,
            user: userParams,
            courseCode: originalCourse?.courseCode,
            schoolCode: school?.schoolCode,
            referralCode: referralCode,
          },
          onSuccess: ({ success, errors, user }: SubmitResponse) => {
            if (success) {
              if (user.email && user.emailConfirmationStatus === 'needed') {
                history.push(
                  `/confirmation-sent${makeQueryString({
                    email: user.email,
                    firstName: originalUser.firstName,
                  })}`,
                );
              } else {
                getFull().AppData.onSignedUp({
                  redirectPath: query.get('redirectPath') || '',
                  user,
                  schools: ImmutableList(),
                  history,
                  method: 'password',
                });
              }
            } else {
              set({
                errors: ImmutableMap(errors),
                submitting: false,
              });
            }
          },
        });
      }
    },
  }),
);
