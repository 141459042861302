import styled, { css } from 'styled-components';
import { useCallback, useRef, useEffect, useMemo, DragEventHandler, useState } from 'react';
import { ErrorMap } from 'src/modules/Api';
import { borderRadii, paddings, colors, rgba, Color, cssIfTrue } from 'src/styles';
import { ImmutableList } from 'src/modules/Immutable';
import { useUniqueId } from 'src/modules/UniqueId';
import { StyledInputProps } from './InputHelpers';
import { Flex, Icon, Text, Spacer, Thumbnail, ErrorText, Loading } from 'src/components';
import { ImageStoredFile, PdfByPageStoredFile, PdfStoredFile } from 'src/models';
import { parseDataTransfer } from 'src/modules/Dom';

export type FileUploadKind = 'lightImagePreview' | 'imagePreview' | 'dropArea' | 'smallDropArea';

const StyledFileInput = styled.input<StyledInputProps>`
  display: none;
`;

const mainStyleCss = css<{
  $imageOnly: boolean;
  $draggedOver: boolean;
  $kind: FileUploadKind;
}>`
  width: ${({ $kind }) => ($kind === 'smallDropArea' ? '100%' : '500px')};
  height: ${({ $kind }) => ($kind === 'smallDropArea' ? '200px' : '380px')};
  background: ${({ $draggedOver }) =>
    $draggedOver ? colors.backgroundLight.active : colors.backgroundLight.hex};
  border: 1px dashed ${colors.gray5.hex};
  padding: ${paddings[2]};

  &:hover {
    background: ${colors.backgroundLight.active};
  }
`;

type ImageCss = {
  $imageOnly: boolean;
  $draggedOver: boolean;
  $disabled: boolean;
  $kind: FileUploadKind;
  $hoverable: boolean;
};

const imageOnlyCss = css<ImageCss>`
  height: 115px;
  aspect-ratio: 4/5;

  ${({ $kind }) => {
    if ($kind === 'lightImagePreview') {
      return css<ImageCss>`
        background: ${({ $draggedOver }) => colors.white[$draggedOver ? 'active' : 'hex']};
        border: 1px dashed ${colors.gray5.hex};

        &:hover {
          ${({ $disabled, $hoverable }) =>
            cssIfTrue('background', colors.white.active, !$disabled && $hoverable)};
        }
      `;
    } else {
      return css<ImageCss>`
        background: ${({ $draggedOver }) => rgba(colors.white.hex, $draggedOver ? 0.2 : 0.1)};

        &:hover {
          ${({ $disabled, $hoverable }) =>
            cssIfTrue('background', rgba(colors.white.hex, 0.2), !$disabled && $hoverable)};
        }
      `;
    }
  }}
`;

const InputContainer = styled.label<{
  $imageOnly: boolean;
  $draggedOver: boolean;
  $disabled: boolean;
  $kind: FileUploadKind;
  $hoverable: boolean;
}>`
  ${({ $disabled }) => cssIfTrue('cursor', 'pointer', !$disabled)};
  border-radius: ${borderRadii[3]};
  ${({ $imageOnly }) => ($imageOnly ? imageOnlyCss : mainStyleCss)}
`;

export const fileLimits = {
  pdf: '.pdf,application/pdf',
  epub: '.epub,application/epub+zip',
  image: 'image/*',
  audio: 'audio/*',
  video: 'video/*',
} as const;

export type FileInputAccept = keyof typeof fileLimits;

export type FileInputOnChange = (
  newValue: ImmutableList<File>,
  evt: React.ChangeEvent<HTMLInputElement> | React.DragEvent,
) => void;

const fileTypeNames = {
  pdf: 'PDF',
  epub: 'EPUB',
  image: 'image',
  audio: 'audio',
  video: 'video',
} as const;

type FileInputProps = {
  /** ID of the input */
  id?: string;
  /** Disable input **/
  disabled?: boolean;
  /** Value of input */
  value: ImmutableList<File>;
  /** Name for input in form */
  name: string;
  /** Event handler for change event */
  onChange: FileInputOnChange;
  /** List of file extensions to accept */
  accept?: FileInputAccept;
  /** Indicate file upload status */
  uploading?: boolean;
  /** Errors to display within input */
  errors?: ErrorMap;
  /** Test tag for e2e tests */
  testTag?: string;
  /** Uploaded stored file */
  uploadedFile?: PdfStoredFile | PdfByPageStoredFile | ImageStoredFile | null;
  iconColor?: Color;
  kind: FileUploadKind;
};

export const FileInput = ({
  id,
  onChange,
  disabled = false,
  value: value,
  accept,
  uploading = false,
  errors,
  name,
  testTag,
  uploadedFile,
  iconColor = 'white',
  kind = 'dropArea',
}: FileInputProps) => {
  const inputContainerId = useUniqueId();
  const inputId = useUniqueId(id);
  const hiddenFileInput = useRef<HTMLInputElement | null>(null);
  const [draggedOver, setDraggedOver] = useState(false);
  const dragOverIndexRef = useRef(0);

  const onDragOver: DragEventHandler = useCallback((event) => {
    event.preventDefault();
  }, []);

  const onDrop: DragEventHandler = useCallback(
    (event) => {
      event.preventDefault();

      const files = parseDataTransfer(event);
      if (files.size > 0) {
        onChange(files, event);
      }
      setDraggedOver(false);
    },
    [onChange],
  );

  const realOnChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const fileList = event.target.files;
      const files = ImmutableList(fileList ?? []);

      onChange(files, event);
    },
    [onChange],
  );

  useEffect(() => {
    if (hiddenFileInput.current && value.isEmpty()) hiddenFileInput.current.value = '';
  }, [value]);

  const imageUrl = useMemo(
    () => (accept === 'image' && value.first() ? URL.createObjectURL(value.first()) : undefined),
    [value, accept],
  );

  const onDragEnter: DragEventHandler = useCallback(() => {
    setDraggedOver(true);
    dragOverIndexRef.current += 1;
  }, []);

  const onDragLeave: DragEventHandler = useCallback(() => {
    dragOverIndexRef.current -= 1;
    if (dragOverIndexRef.current <= 0) {
      setDraggedOver(false);
    }
  }, []);

  const onDragEnd: DragEventHandler = useCallback(() => {
    dragOverIndexRef.current = 0;
    setDraggedOver(false);
  }, []);

  const imageMode = ['imagePreview', 'lightImagePreview'].includes(kind);
  return (
    <>
      <InputContainer
        id={inputContainerId}
        htmlFor={inputId}
        $imageOnly={accept === 'image'}
        $draggedOver={draggedOver}
        onDragOver={onDragOver}
        onDrop={onDrop}
        onDragEnter={onDragEnter}
        onDragLeave={onDragLeave}
        onDragEnd={onDragEnd}
        $disabled={disabled}
        $kind={kind}
        $hoverable={imageMode && Boolean(!imageUrl && !uploadedFile)}
      >
        {imageMode ? (
          <>
            {(imageUrl || uploadedFile) && (
              <Thumbnail
                src={imageUrl || uploadedFile?.url || ''}
                testTag={testTag}
                size="medium"
              />
            )}
            {!uploading && !imageUrl && !uploadedFile && (
              <Flex justify="center" align="center" width="100%" height="100%">
                <Icon color={iconColor} icon="upload" size="3em" />
              </Flex>
            )}
            {uploading && <Loading flex />}
          </>
        ) : (
          <Flex
            direction="column"
            align="center"
            justify="center"
            height="100%"
            width="100%"
            padding={kind === 'smallDropArea' ? '16px' : undefined}
          >
            <Icon color="primaryBlue" icon="upload" size="3em" />
            <Spacer size={kind === 'smallDropArea' ? 2 : 5} />
            <Text variant="h6" textAlign="center" color="gray5" weightOverride="medium">
              {(value.isEmpty() && !uploadedFile) || uploading
                ? `Click or drag and drop to upload your file ${
                    accept ? `in ${fileTypeNames[accept]} format` : ''
                  }`
                : 'Success! Your file has been uploaded'}
            </Text>
            {uploading && (
              <>
                <Spacer size={kind === 'smallDropArea' ? 2 : 5} />
                <Text variant="h6" textAlign="center" color="gray5" weightOverride="medium">
                  Uploading...
                </Text>
              </>
            )}
            {errors && (
              <>
                <Spacer size={kind === 'smallDropArea' ? 2 : 5} />
                <ErrorText errors={errors} errorKey={name} />
              </>
            )}
          </Flex>
        )}
      </InputContainer>
      <StyledFileInput
        id={inputId}
        disabled={disabled}
        onChange={realOnChange}
        type="file"
        accept={accept && fileLimits[accept]}
        ref={hiddenFileInput}
        data-test-tag={testTag}
      />
    </>
  );
};
