import styled, { css } from 'styled-components';
import { borderRadii, paddings, colors, fontWeights } from 'src/styles';

export type SelectDisplayType = 'form' | 'flat' | 'icon' | 'sidebar';

export type StyledInputProps = {
  disabled?: boolean;
  $displayType?: SelectDisplayType;
  $noSpinner?: boolean;
  $flexGrow?: number;
  $fontSize?: number;
};

const noSpinnerStyles = css`
  /* stylelint-disable property-no-vendor-prefix -- Need to be able to disable these as they are the only way to hide the spinner. */

  /* Firefox */
  -moz-appearance: textfield;

  /* Chrome, Safari, Edge, Opera */
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /* stylelint-enable property-no-vendor-prefix */
`;

const baseInputStyles = css<StyledInputProps>`
  outline: 0;
  ${(props) => (props.$noSpinner ? noSpinnerStyles : '')}
`;

export const inputStyles = css<StyledInputProps>`
  ${baseInputStyles}
  width: 100%;
  font-size: 16px;
  padding: ${paddings[2]} ${paddings[4]};
  background: ${(props) => (props.disabled ? colors.gray2.hex : colors.white.hex)};
  border: 1px solid ${colors.gray2.hex};
  border-radius: ${borderRadii.input};
  height: 44px;
  flex-grow: ${(props) => props.$flexGrow};
`;

export const flatInputStyles = css<StyledInputProps>`
  ${baseInputStyles}
  display: inline;
  font-size: ${({ $fontSize = 14 }) => $fontSize}px;
  font-weight: ${fontWeights.medium};
  line-height: 150%;
  padding: ${paddings[1]} ${paddings.none};
  background: ${(props) => (props.disabled ? colors.gray2.hex : 'transparent')};
  border: 0;
  align-self: flex-start;
  flex-grow: ${(props) => props.$flexGrow};

  ::placeholder {
    color: ${colors.gray5.hex};
  }
`;

const sidebarStyles = css<StyledInputProps>`
  ${baseInputStyles}

  background: transparent;
  border: 0;
  font-size: 18px;
  font-weight: bold;
  color: ${colors.white.hex};
  max-width: 100%;
  flex-grow: ${(props) => props.$flexGrow};

  option {
    color: initial;
  }

  option:disabled {
    color: ${colors.white.hex};
  }
`;

export const StyledInput = styled.input<StyledInputProps>`
  ${({ $displayType }) => {
    if ($displayType === 'flat') {
      return flatInputStyles;
    } else if ($displayType === 'sidebar') {
      return sidebarStyles;
    } else {
      return inputStyles;
    }
  }}
`;

export type NumberInputOnChange = (
  newValue: number | null,
  stringValue: string,
  evt: React.ChangeEvent<HTMLInputElement>,
) => void;

export type NumberInputProps = {
  /** ID of the input */
  id?: string;
  /** Value of input */
  value: number | null;
  /** Name for input in form */
  name: string;
  /** Event handler for change event */
  onChange: NumberInputOnChange;
  /** Minimum value (inclusive) */
  min?: number;
  /** Maximum value (inclusive) */
  max?: number;
  /** Test tag */
  testTag?: string;
  noSpinner?: boolean;
  autoComplete?: AutoCompleteOption;
  integerOnly?: boolean;
};

export type AutoCompleteOption =
  | 'off'
  | 'on'
  | 'name'
  | 'honorific-prefix'
  | 'given-name'
  | 'additional-name'
  | 'family-name'
  | 'honorific-suffix'
  | 'nickname'
  | 'email'
  | 'username'
  | 'new-password'
  | 'current-password'
  | 'one-time-code'
  | 'organization-title'
  | 'organization'
  | 'street-address'
  | 'address-line1'
  | 'address-line2'
  | 'address-line3'
  | 'address-level4'
  | 'address-level3'
  | 'address-level2'
  | 'address-level1'
  | 'country'
  | 'country-name'
  | 'postal-code'
  | 'cc-name'
  | 'cc-given-name'
  | 'cc-additional-name'
  | 'cc-family-name'
  | 'cc-number'
  | 'cc-exp'
  | 'cc-exp-month'
  | 'cc-exp-year'
  | 'cc-csc'
  | 'cc-type'
  | 'transaction-currency'
  | 'transaction-amount'
  | 'language'
  | 'bday'
  | 'bday-day'
  | 'bday-month'
  | 'bday-year'
  | 'sex'
  | 'tel'
  | 'tel-country-code'
  | 'tel-national'
  | 'tel-area-code'
  | 'tel-local'
  | 'tel-extension'
  | 'impp'
  | 'url'
  | 'photo';
