import {
  TaskButton,
  Button,
  Flex,
  FlexGrow,
  Spacer,
  Text,
  FormGroup,
  Copyable,
  LinkButton,
  SharingDisclaimer,
} from 'src/components';
import { LicenseType, assessmentIsShared, moderatorOrGreater } from 'src/models';
import { makeQueryString } from 'src/modules/Api';
import { queryMapToString, updateQuery, useQuery, useLocation } from 'src/modules/Router';
import { useStore } from 'src/Store';
import { useReadingTaskDetails } from './AssignmentBuilderHelpers';
import { AssignmentInstructions } from './AssignmentInstructions';
import { AssignmentTitle } from './AssignmentTitle';
import { appUrl } from 'src/config';
import { SharingModal } from './SharingModal';

export const GeneralSideBar = () => {
  const query = useQuery();
  const pathname = useLocation().pathname;

  const showSampleToggle = query.get('showSample');

  const {
    assessment,
    taskDetailsMap,
    saving,
    currentUser,
    primaryLicenseType,
    updateSampleStatus,
    setField,
    startSharing,
  } = useStore(
    ({ AssignmentBuilderData: s, AppData: a }) => ({
      assessment: s.assessment,
      taskDetailsMap: s.taskDetailsMap,
      saving: s.saving,
      dirty: s.dirty,
      startSharing: s.startSharing,
      setField: s.setField,
      currentUser: a.currentUser,
      primaryLicenseType: s.primaryLicenseType,
      updateSampleStatus: s.updateSampleStatus,
    }),
    [],
  );
  const rts = useReadingTaskDetails();
  if (!assessment) return null;
  if (!currentUser) return null;

  const canShare = (primaryLicenseType?: LicenseType | null, licenseType?: LicenseType) => {
    if (moderatorOrGreater(currentUser.accountType)) return true;

    if (primaryLicenseType) {
      return ['open_source', 'open_source_needs_review', 'school', 'district'].includes(
        primaryLicenseType,
      );
    }

    return [
      'open_source',
      'open_source_needs_review',
      'private_user_content',
      'school',
      'district',
    ].includes(licenseType ?? '');
  };

  return (
    <Flex grow={1} direction="column">
      <Flex grow={1} direction="column" align="stretch" overflowY="auto" minHeight={0}>
        <AssignmentTitle />
        <Spacer size={10} />
        <AssignmentInstructions />
        <Spacer size={10} />
        <Text variant="h6" textTransform="uppercase">
          Manage Tasks
        </Text>
        {assessment.taskOrder.map(({ taskId }) => {
          const taskDetails = taskDetailsMap.get(taskId);
          if (!taskDetails) return null;

          const commonProps = {
            to: `/t/assignment-builder/${assessment.id}/tasks${updateQuery(query, {
              currentTaskId: taskId,
            })}`,
            active: taskId === taskDetails.task.id,
            children: taskDetails.task.name,
            rightText: 'Edit',
            onClick: () => setField('drawerExpanded')(false),
          };

          if (taskDetails.type === 'ReadingTask') {
            const isIndependent = taskDetails.reading.contentType === 'independent';

            return (
              <TaskButton
                {...commonProps}
                key={taskId}
                icon="microphone"
                disabled={isIndependent}
                rightText={isIndependent ? '' : 'Edit'}
              />
            );
          } else if (taskDetails.type === 'ComprehensionTask') {
            return <TaskButton {...commonProps} key={taskId} icon="task" />;
          } else if (taskDetails.type === 'ModelTask') {
            return <TaskButton {...commonProps} key={taskId} icon="microphone" />;
          } else if (taskDetails.type === 'VocabTask') {
            return (
              <TaskButton
                {...commonProps}
                key={taskId}
                icon="vocab"
                onClick={() => setField('vocabTaskStep')('details')}
              />
            );
          } else {
            throw new Error(`Unrecognized task type.`);
          }
        })}
        <TaskButton
          to={`/t/assignment-builder/${assessment.id}/tasks/new`}
          active={false}
          icon="add"
          rightIcon
        >
          Add Task
        </TaskButton>
        <Spacer size={3} />
        {canShare(primaryLicenseType, rts?.reading?.license) && (
          <>
            <Flex align="center">
              <Text variant="h5">Sharing</Text>
              <Spacer size={1} horizontal />
              <SharingDisclaimer isPaperUser={Boolean(currentUser?.paperSyncedAt)} />
              <Spacer size={2} horizontal />
              <Text testTag="sharing-on-off">
                {assessmentIsShared(assessment.publishingStatus) ? 'ON' : 'OFF'}
              </Text>
              <FlexGrow />
              <LinkButton onClick={startSharing} color="primaryBlue" testTag="startEditSharing">
                Edit
              </LinkButton>
            </Flex>
            <SharingModal />
          </>
        )}
        {moderatorOrGreater(currentUser.accountType) && (
          <>
            {assessment.publishingStatus === 'public_preview' && (
              <Flex>
                Published! <Spacer horizontal />
                <Copyable
                  kind="link"
                  copyContent={`${appUrl}/t/public-preview?assessmentId=${assessment.id}`}
                >
                  Copy link here!
                </Copyable>
              </Flex>
            )}
            {showSampleToggle === '1' && (
              <>
                <Spacer size={2} />
                <FormGroup
                  flex
                  type="toggle"
                  name="isSample"
                  label="Sample Assignment"
                  value={assessment.isSample}
                  onChange={updateSampleStatus}
                />
              </>
            )}
          </>
        )}
      </Flex>
      <Button
        to={`/reading-studio${makeQueryString({
          assessmentId: assessment?.id,
          backPath: `${pathname}${queryMapToString(query)}`,
          showInstructions: true,
        })}`}
        loading={saving}
        disabled={!assessment.name}
        displayType="outline"
        color="white"
      >
        Preview Assignment
      </Button>
      <Spacer size={2} />
      <Button
        to={`/t/assignments/${assessment.id}/assign?assessmentId=${assessment.id}`}
        loading={saving}
        disabled={!assessment.name}
      >
        Finish &amp; Review
      </Button>
    </Flex>
  );
};
