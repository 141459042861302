import styled, { css } from 'styled-components';
import { ReactNode, useState } from 'react';
import { Icon, Flex, Text, NoStylesButton, Spacer } from 'src/components';
import { colors, paddings, borderRadii, margins, zIndices } from 'src/styles';

type AccordionProps = {
  label: string;
  children: ReactNode;
  type?: 'v1' | 'v2';
  defaultOpen?: boolean;
};

const AccordionContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const BaseAccordionChildren = styled(Flex)`
  margin-top: -${margins[2]};
  z-index: 0;
`;

const AccordionChildrenV1 = styled(BaseAccordionChildren)`
  border: 2px solid ${colors.gray2.hex};
  border-top: none;
  border-radius: 0 0 ${borderRadii[2]} ${borderRadii[2]};
`;

const AccordionChildrenV2 = styled(BaseAccordionChildren)``;

const AccordionHeader = styled(Flex)`
  z-index: ${zIndices.raised}; /* raised so the border of the child element is hidden underneath */
`;

const Rotate = styled.div<{ $opened: boolean; $rotationDeg: number }>`
  display: flex;
  transition: transform 0.12s linear;

  ${({ $opened, $rotationDeg }) =>
    $opened
      ? css`
          transform: rotate(${$rotationDeg}deg);
        `
      : css`
          transform: rotate(0);
        `}
`;

export const Accordion = ({
  children,
  label,
  type = 'v1',
  defaultOpen = false,
}: AccordionProps) => {
  const [opened, setOpened] = useState(defaultOpen);

  return (
    <AccordionContainer>
      {type === 'v1' ? (
        <>
          <NoStylesButton onClick={() => setOpened(!opened)}>
            <AccordionHeader
              justify="space-between"
              align="center"
              width="100%"
              backgroundColor={colors.backgroundDark.hex}
              padding={paddings[3]}
              borderRadius={borderRadii[2]}
            >
              <Text variant="h4" color="white" testTag="accordionLabel">
                {label}
              </Text>
              <Rotate $opened={opened} $rotationDeg={180}>
                <Icon icon="arrowDown" color="white" strokeWidth={3} />
              </Rotate>
            </AccordionHeader>
          </NoStylesButton>
          {opened && (
            <AccordionChildrenV1
              padding={`${paddings[8]} ${paddings[6]} ${paddings[6]} ${paddings[6]}`}
            >
              {children}
            </AccordionChildrenV1>
          )}
        </>
      ) : (
        <>
          <NoStylesButton onClick={() => setOpened(!opened)}>
            <AccordionHeader
              justify="space-between"
              align="center"
              width="100%"
              borderRadius={borderRadii[2]}
              overflowX="hidden"
              overflowY="hidden"
            >
              <Text variant="h6" color={opened ? 'primaryBlue' : 'black'} testTag="accordionLabel">
                {label}
              </Text>
              <Rotate $opened={opened} $rotationDeg={90}>
                <Icon
                  icon="arrowRight"
                  color={opened ? 'primaryBlue' : 'black'}
                  size="25px"
                  strokeWidth={2}
                />
              </Rotate>
            </AccordionHeader>
          </NoStylesButton>
          {opened && (
            <>
              <Spacer size={5} />
              <AccordionChildrenV2>{children}</AccordionChildrenV2>
            </>
          )}
        </>
      )}
    </AccordionContainer>
  );
};
