import { SimpleLayout } from 'src/layouts';
import { FormGroup, Button, LinkButton, Form, Text, Spacer, Flex } from 'src/components';
import { useStore } from 'src/Store';
import { useHistory, useQuery } from 'src/modules/Router';
import { useEffect } from 'react';

export const SignUpClassCode = () => {
  const query = useQuery();
  const sso = query.get('sso');
  const history = useHistory();
  const courseCodeParam = query.get('courseCode') || '';
  const { errors, course, setCourseField, submit, submitting, load } = useStore(
    (state) => state.SignUpClassCodeData,
    [],
  );

  useEffect(() => {
    load();
    setCourseField('courseCode')(courseCodeParam);
    submit({
      history,
      query,
      courseCodeDeclined: false,
      courseCode: courseCodeParam,
      replace: true,
    })();
  }, [load, submit, setCourseField, courseCodeParam, history, query]);

  return (
    <SimpleLayout>
      <Form
        onSubmit={submit({
          history,
          query,
          courseCode: course.courseCode,
          courseCodeDeclined: false,
        })}
      >
        <Text variant="h1">Enter your class code</Text>
        <Spacer size={3} />

        <FormGroup
          name="courseCode"
          label="Class Code"
          errors={errors}
          type="text"
          value={course.courseCode}
          onChange={setCourseField('courseCode')}
        />
        <Spacer size={2} />
        <Button loading={submitting} type="submit" disabled={course.courseCode.length === 0}>
          Next
        </Button>
      </Form>
      <Spacer size={2} />
      <Text>Don&apos;t have a class code? Ask your teacher!</Text>
      {!sso && (
        <Flex>
          <Text>Already a user?</Text>
          <Spacer horizontal />
          <LinkButton
            to={`/login${courseCodeParam && `?courseCode=${encodeURIComponent(courseCodeParam)}`}`}
          >
            Sign In
          </LinkButton>
        </Flex>
      )}
    </SimpleLayout>
  );
};
