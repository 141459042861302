import { Text, Flex, Spacer, Button } from 'src/components';
import { paddings, borderRadii, colors } from 'src/styles';
import wcpm from 'src/assets/wcpm.png';
import cumulative_time from 'src/assets/cumulative_time.png';
import { ENTERPRISE_PLAN_LABEL } from 'src/models';

export const ChartBrochure = () => {
  return (
    <>
      <Flex
        borderRadius={borderRadii[2]}
        backgroundColor={colors.white.hex}
        direction="column"
        padding={paddings[4]}
        testTag="data-visualization-paywall"
      >
        <Text variant="h2" textAlign="center">
          Unlock Data Visualizations
        </Text>
        <Spacer size={2} />
        <Text textAlign="center" variant="h4">
          Upgrade to {ENTERPRISE_PLAN_LABEL} to unlock insights for improving student achievement
        </Text>
        <Spacer size={2} />
        <Flex justify="center">
          <Button href="https://www.readlee.com/contact" width="auto" newTab>
            Contact Us!
          </Button>
        </Flex>
        <Spacer size={4} />

        <Flex width="100%" direction="column" align="center">
          <img src={wcpm} style={{ width: '75%', height: 'auto' }} />
        </Flex>
        <Spacer size={2} />
        <Text textAlign="center" variant="h4">
          Monitor growth and track progress against national benchmarks
        </Text>

        <Spacer size={10} />

        <Flex width="100%" direction="column" align="center">
          <img src={cumulative_time} style={{ width: '75%', height: 'auto' }} />
        </Flex>
        <Spacer size={2} />
        <Text textAlign="center" variant="h4">
          Track and celebrate your student&apos;s growing reading habits
        </Text>
      </Flex>
      <Spacer size={22} />
    </>
  );
};
