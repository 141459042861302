import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import { NumberInputProps, StyledInput } from './InputHelpers';

type RangeInputProps = Omit<NumberInputProps, 'onChange'> & {
  min: number;
  max: number;
  step?: number;
  list?: string;
  children?: React.ReactNode;
  value: number;
  onChange: (
    newValue: number,
    stringValue: string,
    evt: React.ChangeEvent<HTMLInputElement>,
  ) => void;
};

export const RangeInput = ({ onChange, value, ...basicProps }: RangeInputProps) => {
  const numberValueRef = useRef(value);
  const [stringValue, setStringValue] = useState(String(value));

  useEffect(() => {
    if (value !== numberValueRef.current) {
      setStringValue(String(value));
    }
  }, [value]);

  const realOnChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const newStringValue = event.target.value;
      const newNumberValue = parseInt(newStringValue, 10);
      numberValueRef.current = Number.isNaN(newNumberValue) ? basicProps.min : newNumberValue;
      setStringValue(newStringValue);
      onChange(numberValueRef.current, newStringValue, event);
    },
    [onChange, basicProps],
  );

  return <StyledInput {...basicProps} type="range" value={stringValue} onChange={realOnChange} />;
};
