import { TranscriptableAudioStoredFile } from './TranscriptableAudioStoredFile';

export type Recording = {
  id: string;
  processingStatus: 'waiting' | 'queued' | 'started' | 'finished';
};

export type UpdateRecordingResponse = {
  audioFile: TranscriptableAudioStoredFile | null;
  recording: Recording;
};
