import { Course } from 'src/models';
import { SetField } from 'src/State';
import { emptyErrorMap, ErrorMap } from 'src/modules/Api';

export type CreateCourseResponse = {
  success: boolean;
  course: Course;
  errors: ErrorMap;
};

export type CreateCourseState = {
  courseName: string;
  course: Course | null;
  errors: ErrorMap;
  loading: boolean;
  submit: (onSuccess?: (course: Course) => void) => void;
  load: () => void;
  setField: SetField<CreateCourseState>;
};

export const defaultState = {
  loading: false,
  course: null,
  courseName: '',
  errors: emptyErrorMap(),
};
