import { useEffect } from 'react';
import { Loading } from 'src/components';
import { isPaperReading } from 'src/config';
import { cookies } from 'src/modules/Api';

export const BackToPaper = () => {
  useEffect(() => {
    cookies.remove('user_session_id');
    cookies.remove('user_session_token');
    window.location.href = `${window.location.origin}${isPaperReading ? '' : '/login'}`;
  }, []);

  return <Loading flex kind="boat" />;
};
