import { useEffect } from 'react';
import {
  Button,
  LinkButton,
  Flex,
  FormGroup,
  Spacer,
  StandardModal,
  Tooltip,
} from 'src/components';
import { useStore } from 'src/Store';
import { useTeacherStudentListHelpers } from '../../TeacherStudentListHelpers';

export const EditStudentModal = () => {
  const { setField, tempName, tempEmail, updateStudent, errors, loadEditStudentModal } = useStore(
    (s) => s.TeacherStudentListData,
    [],
  );

  const helpers = useTeacherStudentListHelpers();
  const { selectedStudent, closeModal, currentQueryString } = helpers;

  useEffect(() => {
    loadEditStudentModal(selectedStudent);
  }, [selectedStudent, loadEditStudentModal]);

  if (!selectedStudent) return null;

  return (
    <StandardModal
      opened
      openType="state"
      close={closeModal}
      disableFadeClose
      header="Edit Student"
      footer={
        <Flex justify="end">
          <LinkButton onClick={closeModal}>Cancel</LinkButton>
          <Spacer horizontal size={3} />
          <Button onClick={() => updateStudent(helpers)} disabled={selectedStudent.isSso}>
            Save
          </Button>
        </Flex>
      }
      testTag={`edit-student-modal-${selectedStudent.id}`}
    >
      <Flex direction="column">
        <Tooltip
          disabled={!selectedStudent.isSso}
          content="This student was imported from your LMS. Use your LMS to edit this student’s information"
        >
          <FormGroup
            type="text"
            label="Name"
            name="name"
            errors={errors}
            disabled={selectedStudent.isSso}
            value={tempName}
            onChange={setField('tempName')}
          />
        </Tooltip>
        <Spacer size={3} />
        <Tooltip
          disabled={!selectedStudent.isSso}
          content="This student was imported from your LMS. Use your LMS to edit this student’s information"
        >
          <FormGroup
            type="text"
            label={selectedStudent.email ? 'Email' : 'Username'}
            name={selectedStudent.email ? 'email' : 'username'}
            errors={errors}
            disabled={selectedStudent.isSso}
            value={tempEmail}
            onChange={setField('tempEmail')}
          />
        </Tooltip>
        <Spacer size={3} />
        <Flex>
          <LinkButton to={`/t/students/${selectedStudent.id}/change-password${currentQueryString}`}>
            Change Password
          </LinkButton>
        </Flex>
      </Flex>
    </StandardModal>
  );
};
