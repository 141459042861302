import { pluralize } from 'src/modules/String';
import { Tooltip } from '../Tooltip';
import { User } from 'src/models';

const tooltipContent = (user: User, isCurrent: boolean) => {
  if (isCurrent) {
    return `You have a reading streak of ${pluralize('day', user.currentStreak)}!${
      user.currentStreakStatus === 'expiring'
        ? ' Read before the end of the day to keep your streak alive!'
        : ''
    }`;
  } else {
    return `${user.name} has a reading streak of ${pluralize('day', user.currentStreak)}.`;
  }
};

export const StreakStatus = ({
  user,
  isCurrent,
}: {
  user: User | null | undefined;
  isCurrent: boolean;
}) => {
  if (user && user.currentStreakStatus !== 'none') {
    return (
      <Tooltip content={tooltipContent(user, isCurrent)} display="inline">
        {' '}
        🔥{user.currentStreak} {isCurrent && user.currentStreakStatus === 'expiring' ? `⌛` : ''}
      </Tooltip>
    );
  } else {
    return null;
  }
};
