import { Text, Button, Form, FormGroup, LinkButton, Spacer, Flex } from 'src/components';
import { SimpleLayout } from 'src/layouts';
import { useStore } from 'src/Store';

export const ForgotPassword = () => {
  const { username, sentToTeacher, setField, submit, success, errors, submitting, reset } =
    useStore((state) => state.ForgotPasswordData, []);

  return (
    <SimpleLayout>
      <Text variant="h1">Forgot Password?</Text>
      <Spacer size={5} />
      {success ? (
        <>
          <Text>
            If your username or email is valid, you will receive an email with password reset
            instructions!
          </Text>
          <Spacer size={4} />
          {!sentToTeacher && <Text>Check your email and open the link to continue.</Text>}
          <Spacer size={4} />
          {sentToTeacher ? (
            <Flex>
              <Text>
                Ask your instructor to check their email for request instructions or have them
                follow our help guide
              </Text>
              <LinkButton to="/help-docs-on-teacher-reset">here.</LinkButton>
            </Flex>
          ) : (
            <Flex>
              <Text>Didn&apos;t receive a message?</Text>
              <Spacer horizontal />
              <LinkButton onClick={reset} loading={submitting}>
                Try again.
              </LinkButton>
            </Flex>
          )}
        </>
      ) : (
        <Form onSubmit={submit}>
          <Text>Enter your email or username to receive password reset instructions.</Text>
          <Spacer size={3} />
          <FormGroup
            type="text"
            name="username"
            label="Username or Email"
            errors={errors}
            value={username}
            onChange={setField('username')}
          />
          <Spacer size={2} />
          <Button type="submit" loading={submitting}>
            Reset Password
          </Button>
          <Spacer size={3} />
          <Flex>
            <Text>If you do not have access to your email, reach out to support</Text>
            <Spacer horizontal />
            <LinkButton newTab href="https://readleesupport.zendesk.com/hc/en-us/requests/new">
              here.
            </LinkButton>
          </Flex>
        </Form>
      )}
    </SimpleLayout>
  );
};
