import { ReactNode, useCallback, useEffect, useRef, useState } from 'react';

import { useFetch, wait } from 'src/modules/Api';
import { formatRecordingTime } from 'src/modules/Time';
import {
  AddRecordingResponse,
  AudioStoredFile,
  Recording,
  RecordingSequence,
  TranscriptableAudioStoredFile,
} from 'src/models';
import {
  Bold,
  Button,
  Flex,
  MicrophoneSettings,
  Spacer,
  StandardModal,
  Subscribe,
  Text,
  useToaster,
  FileInput,
  TestMode,
  Drawer,
  PromptBeforeLeave,
  Tooltip,
  Icon,
} from 'src/components';
import { margins } from 'src/styles';
import {
  AUDIO_RECORDING_RETRY_SCHEDULE,
  checkMicrophoneAccess,
  MAX_DATA_AVAILABLE_WAITS,
  startRecording,
  stopRecording,
  VolumeMeasurementMessage,
  webmAudioMimeTypeDetails,
} from 'src/modules/AudioRecording';
import { ImmutableList } from 'src/modules/Immutable';
import { MimeTypeDetails } from 'src/modules/Media';
import { reportError } from 'src/modules/ErrorReporting';
import { MobileRecorder } from './MobileRecorder';
import { DesktopSmallRecorder } from './DesktopSmallRecorder';
import { DesktopRecorder } from './DesktopRecorder';
export { RecordingStatus } from './RecordingDot';
export { VolumeMeter } from './VolumeMeter';
import styled from 'styled-components';
import { Countdown } from './Countdown';
import { useStore } from 'src/Store';
import { emitWebviewMessage } from 'src/modules/MobileAppMessages';

export type AudioRecorderDisplayType = 'desktop' | 'desktopSmall' | 'mobile';
export type AudioRecorderStatus =
  | 'not_started'
  | 'loading'
  | 'recording'
  | 'processing'
  | 'finished'
  | 'failed';

export type BaseAudioRecorderProps = {
  audioFile: TranscriptableAudioStoredFile | null;
  displayType: AudioRecorderDisplayType;
  disabled: boolean;
  dirty: boolean;
  status: AudioRecorderStatus;
  startedAt: Date;
  recordingEndedAt: Date;
  volumeLevel: number;
  hasJustStarted: boolean;
  recordingSequence: RecordingSequence;
  onSave?: () => void;
  openMicSettings: () => Promise<void>;
  startRestart: () => void;
  startDeleting: () => void;
  stop: () => void;
  beginRecording: () => void;
};

type AudioRecorderProps = {
  recordingSequence: RecordingSequence;
  audioFile: TranscriptableAudioStoredFile | null;
  onAudioRecorderStatus?: (status: AudioRecorderStatus) => unknown;
  onRecordingSequenceStatus: (recording: AddRecordingResponse) => unknown;
  displayType?: AudioRecorderDisplayType;
  disabled?: boolean;
  dirty?: boolean;
  showCountdown?: boolean;
  onSave?: () => void;
  onRecordingStart?: (recordingId: string) => void;
  onRecordingStop?: (recordingId: string) => void;
};

export type UpdateRecordingData = {
  success: boolean;
  audioFile: AudioStoredFile | null;
  recording: Recording | null;
  recordingSequence: RecordingSequence;
  pointsEarned: number;
  pointBalance: number;
};

type AudioMenuProps = {
  status: AudioRecorderStatus;
  audioFile: TranscriptableAudioStoredFile | null;
  startRestart: () => void;
  startDeleting: () => void;
  openMicSettings: () => Promise<void>;
};

// Trying to debug safari issues via fullstory
const log = (...args: Array<unknown>) => {
  /* eslint-disable no-console */
  console.log(...args);
  /* eslint-enable no-console */
};

export const RecordingTime = ({
  startedAt,
  recordingLength,
}: {
  startedAt: Date;
  recordingLength: number;
}) => {
  const [now, setNow] = useState(new Date());
  useEffect(() => {
    const id = setInterval(() => {
      setNow(new Date());
    }, 1000);

    return () => {
      clearInterval(id);
    };
  }, []);
  return (
    <Flex minWidth="35px">
      {formatRecordingTime(Math.max(now.valueOf() - startedAt.valueOf() + recordingLength, 0))}
    </Flex>
  );
};

export const GreenCheckContainer = styled.div`
  position: absolute;
  margin: ${margins.half};
  top: 0;
  right: 0;
`;

export const DrawerComponent = ({
  children,
  displayType,
  saved,
}: {
  children: ReactNode;
  displayType: AudioRecorderDisplayType;
  saved: boolean;
}) => {
  return displayType === 'mobile' ? (
    <Flex direction="column" width="100%" align="end">
      {children}
    </Flex>
  ) : (
    <Drawer saved={saved} displayType={displayType}>
      {children}
    </Drawer>
  );
};

const SUCCESS_TOAST_DURATION = 2000;

export const AudioMenu = ({
  status,
  audioFile,
  startDeleting,
  startRestart,
  openMicSettings,
}: AudioMenuProps) => {
  const audioReady = !(
    (status === 'not_started' && !audioFile) ||
    status === 'loading' ||
    status === 'processing' ||
    status === 'recording'
  );

  const audioProcessing = status === 'loading' || status === 'processing' || status === 'recording';

  return (
    <>
      <Tooltip content="Delete your recording" disabled={!audioReady}>
        <Button
          displayType="noStyles"
          onClick={startDeleting}
          disabled={!audioReady}
          height="40px"
          width="40px"
          testTag="delete-recording"
        >
          <Icon icon="trash" size="2em" color={!audioReady ? 'gray5' : 'danger'} fill />
        </Button>
      </Tooltip>
      <Tooltip content="Start over" disabled={!audioReady}>
        <Button
          displayType="noStyles"
          disabled={!audioReady}
          onClick={startRestart}
          height="40px"
          width="40px"
        >
          <Icon
            icon="redo"
            size="2em"
            strokeWidth={1.5}
            color={!audioReady ? 'gray5' : 'primaryBlue'}
            strokeLinecap="round"
          />
        </Button>
      </Tooltip>
      <Tooltip content="Change your settings" disabled={audioProcessing}>
        <Button
          displayType="noStyles"
          onClick={openMicSettings}
          disabled={audioProcessing}
          height="40px"
          width="40px"
          testTag="recorder-settings"
        >
          <Icon
            icon="settings"
            size="1.8em"
            color={audioProcessing ? 'gray5' : 'gray7'}
            strokeWidth={0}
            fill
          />
        </Button>
      </Tooltip>
    </>
  );
};

export const AudioRecorder = ({
  recordingSequence,
  audioFile,
  onAudioRecorderStatus,
  onRecordingSequenceStatus,
  displayType = 'desktop',
  disabled = false,
  dirty = false,
  showCountdown = false,
  onSave,
  onRecordingStart,
  onRecordingStop,
}: AudioRecorderProps) => {
  const setField = useStore((s) => s.AppData.setField, []);

  const { createToast, closeToast } = useToaster();
  const { fetchJson } = useFetch();

  const mediaStreamRef = useRef<MediaStream>();
  const mediaRecorderRef = useRef<MediaRecorder>();
  const mimeTypeDetailsRef = useRef<MimeTypeDetails>();
  const chunkPromisesRef = useRef<Array<Promise<Response | null>>>();
  const recordingRef = useRef<Recording | null>(null);
  const hasReceivedSoundRef = useRef<boolean>(false);
  const noSoundWarningTimeoutIdRef = useRef<NodeJS.Timeout | null>(null);
  const noSoundWarningToastIdRef = useRef<string | null>(null);

  const [status, setBaseStatus] = useState<AudioRecorderStatus>('not_started');
  const setStatus = useCallback(
    (s: AudioRecorderStatus) => {
      setBaseStatus(s);
      onAudioRecorderStatus?.(s);
      setField('isRecording')(s === 'recording');
    },
    [onAudioRecorderStatus, setField],
  );
  const [deletingOpened, setDeletingOpened] = useState<boolean>(false);
  const [restartOpened, setRestartOpened] = useState<boolean>(false);
  const [micSettingsOpened, setMicSettingsOpened] = useState<boolean>(false);
  const [hasJustStarted, setHasJustStarted] = useState<boolean>(false);
  const [volumeLevel, setVolumeLevel] = useState<number>(0);
  const [countingDown, setCountingDown] = useState<boolean>(false);

  const [startedAt, setStartedAt] = useState<Date>(new Date());
  const [recordingEndedAt, setRecordingEnded] = useState(new Date());

  const startFakeRecording = useCallback(() => {
    setStatus('loading');
    fetchJson(`/api/recordings`, {
      method: 'POST',
      onSuccess: async (data: { recording: Recording }) => {
        const newRecording = data.recording;
        recordingRef.current = newRecording;
        chunkPromisesRef.current = [];

        setStatus('recording');
        setStartedAt(new Date());
        createToast({
          children: 'Recording started!',
          color: 'success',
          duration: SUCCESS_TOAST_DURATION,
        });
      },
    });
  }, [createToast, fetchJson, setStatus]);

  const uploadRecordingPart = useCallback(
    (blob: Blob | File) => {
      log('uploadRecordingPart', recordingRef.current, chunkPromisesRef.current?.length);
      if (!recordingRef.current) return;
      const recording = recordingRef.current;

      chunkPromisesRef.current ||= [];
      const index = chunkPromisesRef.current.length;
      const mimeTypeDetails = mimeTypeDetailsRef.current ?? webmAudioMimeTypeDetails;
      const promise = fetchJson(`/api/recordings/${recording.id}/recording_parts`, {
        method: 'POST',
        retrySchedule: AUDIO_RECORDING_RETRY_SCHEDULE,
        data: {
          index,
          mimeTypeDetails,
        },
        files: {
          blob,
        },
      });
      chunkPromisesRef.current.push(promise);
    },
    [fetchJson],
  );

  const uploadFakeRecordingPart = useCallback(
    (fileList: ImmutableList<File>) => {
      const file = fileList.first();
      if (!file) return;
      uploadRecordingPart(file);
    },
    [uploadRecordingPart],
  );

  const onRecordingFinished = useCallback(async () => {
    log(
      'onRecordingFinished',
      recordingRef.current,
      chunkPromisesRef.current?.length,
      mediaRecorderRef.current?.state,
    );
    if (!recordingRef.current) return;
    if (!chunkPromisesRef.current) return;
    const recording = recordingRef.current;
    if (onRecordingStop) onRecordingStop(recording.id);

    setRecordingEnded(new Date());

    setStatus('processing');

    const onError = () => {
      recordingRef.current = null;
      setStatus('failed');
      createToast({
        color: 'danger',
        children: 'This recording failed to process, please try again.',
      });
      reportError(new Error('This recording failed to process, please try again.'));
    };

    // Give time for the last call to go through
    await wait(1000);

    log('onRecordingFinished 2', chunkPromisesRef.current.length, mediaRecorderRef.current?.state);

    // If somehow there is still no recording data, keep waiting until there is
    for (let i = 0; i < MAX_DATA_AVAILABLE_WAITS && chunkPromisesRef.current.length === 0; i += 1) {
      await wait(1000);
    }

    log('onRecordingFinished 3', chunkPromisesRef.current.length, mediaRecorderRef.current?.state);

    if (chunkPromisesRef.current.length === 0) {
      onError();
      return;
    }

    const results = await Promise.all(chunkPromisesRef.current);

    // If a request is cancelled by the user leaving the page, each request promise will
    // finish, it will just return null.
    const allSuccessful = results.every(Boolean);
    if (allSuccessful) {
      fetchJson(`/api/recordings/${recording.id}`, {
        method: 'PATCH',
        data: {
          recordingSequenceId: recordingSequence.id,
        },
        retrySchedule: AUDIO_RECORDING_RETRY_SCHEDULE,
        onSuccess: (data: UpdateRecordingData) => {
          if (data.success) {
            onRecordingSequenceStatus({
              recordingSequence: data.recordingSequence,
              audioFile: null,
              pointsEarned: data.pointsEarned,
              pointBalance: data.pointBalance,
            });
          } else {
            onError();
          }
        },
      });
    } else {
      createToast({
        children: 'Recording not saved. Make sure you press the stop button when you are finished.',
        color: 'danger',
      });
    }
  }, [
    setStatus,
    createToast,
    fetchJson,
    recordingSequence.id,
    onRecordingSequenceStatus,
    onRecordingStop,
  ]);

  const onVolumeLevel = useCallback(
    (data: VolumeMeasurementMessage) => {
      setVolumeLevel(Math.round(data.smoothedVolume));
      if (data.smoothedVolume > 0) {
        hasReceivedSoundRef.current = true;
        if (noSoundWarningTimeoutIdRef.current) clearTimeout(noSoundWarningTimeoutIdRef.current);
        if (noSoundWarningToastIdRef.current) closeToast(noSoundWarningToastIdRef.current);
        noSoundWarningTimeoutIdRef.current = null;
        noSoundWarningToastIdRef.current = null;
      }
    },
    [closeToast],
  );

  const record = useCallback(async () => {
    log('record', chunkPromisesRef.current?.length);
    setStatus('loading');

    fetchJson(`/api/recordings`, {
      method: 'POST',
      onSuccess: async (data: { recording: Recording }) => {
        const newRecording = data.recording;
        recordingRef.current = newRecording;
        chunkPromisesRef.current = [];

        const result = await startRecording({
          onDataAvailable: uploadRecordingPart,
          onStop: onRecordingFinished,
          onVolumeLevel,
          onStart: () => onRecordingStart?.(newRecording.id),
        });

        if (!result) {
          setStatus('failed');
          createToast({
            color: 'danger',
            children: 'There was an error starting your recording, we have logged a bug report',
          });
          return;
        }
        const { mediaStream, mediaRecorder, mimeTypeDetails, trackingVolume } = result;
        mediaStreamRef.current = mediaStream;
        mediaRecorderRef.current = mediaRecorder;
        mimeTypeDetailsRef.current = mimeTypeDetails;

        setStatus('recording');
        setStartedAt(new Date());
        setHasJustStarted(true);
        // Do not allow them to stop the recording unless they've recorded for a full second
        // this prevents issues where users rapid click on the "Record" button and immediately
        // stop recording, which produces an audio clip too short for AWS Transcribe to process.
        setTimeout(() => {
          setHasJustStarted(false);
        }, 1000);
        createToast({
          children: 'Recording started!',
          color: 'success',
          duration: SUCCESS_TOAST_DURATION,
        });
        hasReceivedSoundRef.current = false;
        if (trackingVolume) {
          noSoundWarningTimeoutIdRef.current = setTimeout(() => {
            if (!hasReceivedSoundRef.current) {
              noSoundWarningToastIdRef.current = createToast({
                children: `Looks like we haven't gotten any input from your microphone, make sure you are not muted and check your settings.`,
                color: 'danger',
                forever: true,
                closeAnywhere: true,
              });
            }
          }, 5000);
        }
      },
    });
  }, [
    createToast,
    onRecordingFinished,
    uploadRecordingPart,
    fetchJson,
    setStatus,
    onVolumeLevel,
    onRecordingStart,
  ]);

  const stop = useCallback(() => {
    log('stop', mediaRecorderRef.current?.state);
    if (mediaRecorderRef.current?.state === 'recording') {
      mediaRecorderRef.current.requestData();
    }
    stopRecording({ mediaRecorder: mediaRecorderRef.current, mediaStream: mediaStreamRef.current });
    log('stop 2', mediaRecorderRef.current?.state);

    mediaRecorderRef.current = undefined;
    mediaStreamRef.current = undefined;

    setVolumeLevel(0);
    setStatus('processing');
  }, [setStatus]);

  const recordingSequenceStatusChanged = useCallback(
    (data: AddRecordingResponse) => {
      if (data.recordingSequence.processingStatus === 'finished') {
        setStatus('finished');
        onRecordingSequenceStatus(data);
        createToast({
          children: 'Recording saved!',
          color: 'success',
          duration: SUCCESS_TOAST_DURATION,
        });
      }
    },
    [onRecordingSequenceStatus, createToast, setStatus],
  );

  const startDeleting = useCallback(() => {
    setDeletingOpened(true);
  }, []);

  const closeDeleting = useCallback(() => {
    setDeletingOpened(false);
  }, []);

  const startRestart = useCallback(() => {
    setRestartOpened(true);
  }, []);

  const closeRestart = useCallback(() => {
    setRestartOpened(false);
  }, []);

  const deleteRecordingSequence = useCallback(
    (onSuccess?: () => void) => {
      setStatus('loading');
      fetchJson(`/api/recording_sequences/${recordingSequence.id}`, {
        method: 'DELETE',
        onSuccess: (data: AddRecordingResponse) => {
          setStatus('not_started');
          setDeletingOpened(false);
          setRestartOpened(false);
          setStartedAt(new Date());
          setRecordingEnded(new Date());
          onRecordingSequenceStatus({
            recordingSequence: data.recordingSequence,
            audioFile: data.audioFile,
            pointsEarned: data.pointsEarned,
            pointBalance: data.pointBalance,
          });
          if (onSuccess) onSuccess();
        },
      });
    },
    [recordingSequence, onRecordingSequenceStatus, fetchJson, setStatus],
  );

  const openMicSettings = useCallback(async () => {
    setMicSettingsOpened(true);
  }, []);

  const closeMicSettings = useCallback(() => {
    setMicSettingsOpened(false);
  }, []);

  const beginRecording = useCallback(async () => {
    const status = await checkMicrophoneAccess();

    if (status !== 'granted') {
      if (status === 'unsupported') {
        createToast({
          color: 'danger',
          children: 'Your browser does not support recording, please try a different browser.',
        });
      } else {
        emitWebviewMessage({ message: 'webview_ask_recording_permission' });
        createToast({
          color: 'danger',
          children: "We don't have access to your microphone, please check your browser settings.",
        });
      }
      setStatus('failed');
      return;
    }

    if (showCountdown) {
      setCountingDown(true);
      const countdownTimeoutId = setTimeout(() => {
        setCountingDown(false);
      }, 5500);

      const recordTimeoutId = setTimeout(() => {
        record();
      }, 3500);
      return () => {
        clearTimeout(countdownTimeoutId);
        clearTimeout(recordTimeoutId);
      };
    } else {
      record();
    }
    return;
  }, [createToast, record, setStatus, showCountdown]);

  // Try to gracefully handle navigating away
  useEffect(() => {
    return () => {
      stop();
    };
    // Only want this to run when the entire component is unmounting, not every time `stop` changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const RecorderComponent = (() => {
    switch (displayType) {
      case 'desktop':
        return DesktopRecorder;
      case 'desktopSmall':
        return DesktopSmallRecorder;
      case 'mobile':
        return MobileRecorder;
    }
  })();

  return (
    <>
      <Countdown opened={countingDown} displayType={displayType} />
      <PromptBeforeLeave dirty={status === 'recording'} />
      <TestMode>
        <Button onClick={startFakeRecording} zIndex="toast">
          Start
        </Button>
        <FileInput
          kind="dropArea"
          value={ImmutableList()}
          name="testFile"
          onChange={uploadFakeRecordingPart}
        />
        <Button onClick={onRecordingFinished} zIndex="toast">
          Finish
        </Button>
      </TestMode>

      <RecorderComponent
        audioFile={audioFile}
        displayType={displayType}
        disabled={disabled}
        dirty={dirty}
        status={status}
        startedAt={startedAt}
        recordingEndedAt={recordingEndedAt}
        volumeLevel={volumeLevel}
        hasJustStarted={hasJustStarted}
        recordingSequence={recordingSequence}
        onSave={onSave}
        openMicSettings={openMicSettings}
        startRestart={startRestart}
        startDeleting={startDeleting}
        stop={stop}
        beginRecording={beginRecording}
      />

      <StandardModal
        openType="state"
        opened={deletingOpened}
        close={closeDeleting}
        header="Delete Recording?"
        footer={
          <>
            <Button onClick={closeDeleting} inheritBgColor displayType="link" color="primaryBlue">
              Cancel
            </Button>
            <Spacer horizontal size={3} />
            <Button
              loading={status === 'loading'}
              onClick={() => deleteRecordingSequence()}
              color="danger"
            >
              Delete
            </Button>
          </>
        }
      >
        <Text>
          Your <Bold>entire</Bold> recording will be <Bold>permanently</Bold> deleted. Are you sure?
        </Text>
      </StandardModal>
      <StandardModal
        openType="state"
        opened={restartOpened}
        close={closeRestart}
        header="Restart Recording?"
        footer={
          <>
            <Button onClick={closeRestart} inheritBgColor displayType="link" color="primaryBlue">
              Cancel
            </Button>
            <Spacer horizontal size={3} />
            <Button
              loading={status === 'loading'}
              onClick={() => deleteRecordingSequence(beginRecording)}
              color="danger"
            >
              Restart
            </Button>
          </>
        }
      >
        <Text>
          Your <Bold>entire</Bold> recording will be <Bold>permanently</Bold> deleted. Are you sure?
        </Text>
      </StandardModal>
      <MicrophoneSettings opened={micSettingsOpened} close={closeMicSettings} />
      <Subscribe
        channel="RecordingSequenceChannel"
        id={recordingSequence.id}
        onMessage={recordingSequenceStatusChanged}
      />
    </>
  );
};
