import { useStore } from 'src/Store';
import styled from 'styled-components';
import { useEffect, useState } from 'react';
import { emptyUser, User, teacherOrGreater } from 'src/models';
import {
  useToaster,
  Form,
  FormGroup,
  Button,
  Flex,
  Spacer,
  Tooltip,
  LinkButton,
  Label,
  Text,
  MicrophoneSettings,
} from 'src/components';
import { capitalize, pluralize } from 'src/modules/String';
import { makeQueryString } from 'src/modules/Api';
import { ChangePasswordModal } from './ChangePasswordModal/ChangePasswordModal';
import { ImmutableList, ImmutableMap } from 'src/modules/Immutable';
import { borderRadii, colors, margins, paddings } from 'src/styles';
import { TeacherLayoutProps } from 'src/layouts';
import { startZendeskTicket } from 'src/modules/Analytics/Analytics';

type UserProfileProps = {
  ContentElement: React.ComponentType<TeacherLayoutProps>;
};

const UserProfileContainer = styled.div`
  max-width: 550px;
`;

const Card = styled.div`
  padding: ${paddings[8]} ${paddings[6]};
  margin-bottom: ${margins[5]};
  background: ${colors.white.hex};
  border-radius: ${borderRadii[3]};
  max-width: 550px;
`;

export const UserProfile = ({ ContentElement }: UserProfileProps) => {
  const {
    load,
    loading,
    currentUser,
    currentPlan,
    setUserFields,
    setUserField,
    schools,
    setField,
    dirty,
    profileImageStoredFile,
    uploadProfileImage,
    profileImageFile,
    uploadingProfileImage,
  } = useStore(
    ({ AppData: s }) => ({
      load: s.load,
      loading: s.loading,
      currentUser: s.currentUser,
      currentPlan: s.currentPlan,
      setUserFields: s.setUserFields,
      setUserField: s.setUserField,
      schools: s.schools,
      setField: s.setField,
      dirty: s.dirty,
      profileImageStoredFile: s.profileImageStoredFile,
      uploadProfileImage: s.uploadProfileImage,
      profileImageFile: s.profileImageFile,
      uploadingProfileImage: s.uploadingProfileImage,
    }),
    [],
  );
  const openChangePasswordModal = useStore((s) => s.ChangePasswordModalData.openModal, []);

  const [submitLoading, setSubmitLoading] = useState(false);
  const [micSettings, setMicSettings] = useState(false);
  // Local name variable that can be edited
  const [localUser, setLocalUser] = useState(currentUser);

  const { createToast } = useToaster();

  // Set local name when current user is updated
  useEffect(() => {
    load({
      onSuccess: (data) => {
        setLocalUser(data.currentUser);
      },
    });
  }, [load]);

  // save user data function
  const save = () => {
    setSubmitLoading(true);
    const updates = {
      name: localUser?.name ?? '',
      displayName: localUser?.name ?? '',
      profileImageFileId: profileImageStoredFile?.id ?? null,
    };
    setUserFields(ImmutableMap(updates), createToast);
    setField('dirty')(false);
    setSubmitLoading(false);
  };

  const deleteProfilePicture = () => {
    setSubmitLoading(true);
    const updates = {
      profileImageFileId: null,
    };
    setUserFields(ImmutableMap(updates), createToast);
    setField('dirty')(false);
    setField('profileImageStoredFile')(null);
    setField('profileImageFile')(null);
    setSubmitLoading(false);
  };

  const disabledEmailText = (currentUser: User) => {
    if (currentUser.accountType === 'student') return 'Contact your teacher to change your email';
    return currentUser.isSso ? (
      'Please use your LMS to control this information'
    ) : (
      <>
        Need to change your email?
        <LinkButton onClick={startZendeskTicket} sizeOverride={16}>
          Contact customer support
        </LinkButton>
      </>
    );
  };

  const disabledNameText = (currentUser: User) => {
    return currentUser.accountType === 'student'
      ? 'Contact your teacher to change your name'
      : 'Please use your LMS to control this information';
  };

  return (
    <>
      <ContentElement
        headerText="My Profile"
        headerActionButton={
          !currentUser?.paperSyncedAt && (
            <Button onClick={save} disabled={!dirty} loading={submitLoading}>
              Save Changes
            </Button>
          )
        }
        loading={loading}
      >
        <UserProfileContainer>
          <Form onSubmit={save} dirty={dirty}>
            <Flex direction="column">
              {teacherOrGreater(currentUser?.accountType ?? 'student') && (
                <Flex direction="column" align="start">
                  <Label $isTextInput>Profile Picture</Label>
                  <Flex>
                    <FormGroup
                      name="profileImageFile"
                      type="file"
                      kind="lightImagePreview"
                      width="auto"
                      value={profileImageFile ? ImmutableList([profileImageFile]) : ImmutableList()}
                      onChange={(files) => uploadProfileImage(files, createToast)}
                      accept="image"
                      testTag="profile-image"
                      flex
                      uploadedFile={profileImageStoredFile}
                      iconColor="black"
                      uploading={uploadingProfileImage}
                    />
                  </Flex>
                  {profileImageStoredFile ? (
                    <LinkButton
                      onClick={deleteProfilePicture}
                      color="primaryBlue"
                      paddingOverride={paddings.none}
                    >
                      Remove Image
                    </LinkButton>
                  ) : (
                    <>
                      <Spacer size={3} />
                      <Spacer size="half" />
                    </>
                  )}
                </Flex>
              )}
              <Spacer size={5} />
              <Tooltip
                disabled={!currentUser?.isSso}
                content={disabledNameText(currentUser || emptyUser)}
              >
                <FormGroup
                  type="text"
                  name="Name"
                  label="Name"
                  testTag="name-input"
                  value={localUser?.name || localUser?.displayName || 'Loading...'}
                  disabled={
                    loading ||
                    !localUser?.name ||
                    currentUser?.isSso ||
                    currentUser?.paperSyncedAt !== null
                  }
                  onChange={(s: string) => {
                    if (s !== currentUser?.name) setField('dirty')(true);
                    if (localUser) setLocalUser({ ...localUser, name: s });
                  }}
                />
              </Tooltip>
              {!currentUser?.paperSyncedAt && currentUser?.email && (
                <>
                  <Spacer size={6} />
                  <Tooltip content={disabledEmailText(currentUser || emptyUser)}>
                    <FormGroup
                      name={currentUser.accountType === 'teacher' ? 'Faculty Email' : 'Email'}
                      label={currentUser.accountType === 'teacher' ? 'Faculty Email' : 'Email'}
                      type="text"
                      value={currentUser.email}
                      disabled
                      onChange={() => {
                        return;
                      }}
                    />
                  </Tooltip>
                  {teacherOrGreater(currentUser?.accountType) && (
                    <>
                      <Spacer size={4} />
                      <Flex justify="end">
                        <Button displayType="link" onClick={openChangePasswordModal}>
                          Change Password
                        </Button>
                      </Flex>
                    </>
                  )}
                </>
              )}

              <Spacer size={6} />

              <FormGroup
                name="Role"
                label={
                  <Flex align="center">
                    Role
                    <Spacer horizontal />
                    <Tooltip
                      iconColor="gray6"
                      content={
                        <Flex>
                          <Text>Wrong role?</Text>
                          <Spacer horizontal />
                          <LinkButton newTab onClick={startZendeskTicket}>
                            Contact support.
                          </LinkButton>
                        </Flex>
                      }
                    />
                  </Flex>
                }
                type="text"
                value={capitalize(currentUser?.accountType || '')}
                disabled
                onChange={() => {
                  return;
                }}
              />
              {!currentUser?.paperSyncedAt && currentUser?.accountType !== 'student' && (
                <>
                  <Spacer size={6} />
                  <FormGroup
                    name="planName"
                    label="Current Plan"
                    type="text"
                    value={currentPlan?.name || 'Basic'}
                    disabled
                    onChange={() => {
                      return;
                    }}
                  />
                  <Spacer />
                  <Flex width="100%" justify="end">
                    <Button displayType="link" to="/t/plans">
                      {currentPlan.isBasicPlan
                        ? 'View Plans and Pricing'
                        : 'Manage Your Subscription'}
                    </Button>
                  </Flex>
                </>
              )}

              <Spacer size={6} />
              {!currentUser?.paperSyncedAt && currentUser?.accountType === 'teacher' ? (
                <>
                  <FormGroup
                    name="school"
                    label={pluralize('School', schools.size, { noNumber: true })}
                    type="text"
                    value={schools.map((s) => s.name).join(', ') || 'Not Set'}
                    disabled
                    onChange={() => {
                      return;
                    }}
                  />
                  <Spacer />

                  <Flex width="100%" justify="end">
                    <Button
                      displayType="link"
                      to={`/t/onboarding${makeQueryString({
                        redirectPath: '/t/profile',
                      })}`}
                    >
                      Change your School
                    </Button>
                  </Flex>
                  <Spacer size={6} />
                </>
              ) : null}
            </Flex>
            <Card>
              <Flex direction="row" align="center">
                <Text variant="h3" weightOverride="bold">
                  Text Size
                </Text>
                <Spacer horizontal />
                <Tooltip content="Adjust your default text size for readings" />
              </Flex>

              <Spacer size={2} />
              <FormGroup
                label=""
                type="textSize"
                name="textSize"
                value={currentUser?.textSize ?? 16}
                onChange={setUserField('textSize', createToast, false)}
              />
            </Card>
            <Card>
              <Text variant="h3" weightOverride="bold">
                Microphone Settings
              </Text>
              <Spacer size={4} />
              <Button displayType="outline" onClick={() => setMicSettings(true)}>
                Change Settings
              </Button>
            </Card>
            <Spacer size={22} />
          </Form>
        </UserProfileContainer>
      </ContentElement>
      <ChangePasswordModal />
      <MicrophoneSettings opened={micSettings} close={() => setMicSettings(false)} />
    </>
  );
};
