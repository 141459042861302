import { ReactNode } from 'react';
import styled from 'styled-components';

import { Flex } from 'src/components';
import { colors, paddings } from 'src/styles';

const SideBarContainer = styled.div`
  width: 400px;
  padding: ${paddings[8]} ${paddings[8]} ${paddings[5]} ${paddings[8]};
  color: ${colors.white.hex};
  background: ${colors.backgroundDark.hex};
  min-height: 0;
  overflow-y: auto;
`;

type ReadingStudioLayoutSideBarProps = {
  content?: ReactNode;
  primaryButton?: ReactNode;
};

export const ReadingStudioLayoutSideBar = ({
  primaryButton,
  content,
}: ReadingStudioLayoutSideBarProps) => {
  return (
    <SideBarContainer>
      <Flex direction="column" justify="space-between" height="100%">
        {content || <span />}
        {primaryButton || <span />}
      </Flex>
    </SideBarContainer>
  );
};
