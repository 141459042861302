import styled from 'styled-components';
import { margins } from 'src/styles';
import { Logo, LogoSize, LogoType, NoStylesButton } from 'src/components';

export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 0 ${margins[4]};

  img {
    object-fit: scale-down;
  }
`;

export const LogoButton = ({
  logoType = 'full-name',
  disabled = false,
  size = 'small',
}: {
  logoType?: LogoType;
  disabled?: boolean;
  size?: LogoSize;
}) => (
  <LogoContainer>
    <NoStylesButton testTag={logoType} to="/" disabled={disabled}>
      <Logo size={size} type={logoType} />
    </NoStylesButton>
  </LogoContainer>
);
