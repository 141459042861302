import { useCallback } from 'react';
import { Button, JoinCourseModal } from 'src/components';
import { useStore } from 'src/Store';

export const JoinCourseButton = () => {
  const setField = useStore((s) => s.JoinCourseData.setField, []);
  const openModal = useCallback(() => setField('open')(true), [setField]);

  return (
    <>
      <Button onClick={openModal} height="40px">
        Join Class
      </Button>
      <JoinCourseModal />
    </>
  );
};
