import { ImmutableList } from 'src/modules/Immutable';
import { ComprehensionTextDiffItem } from 'src/models';

export type TranscriptableAudioStoredFile = {
  id: string;
  url: string;
  transcriptJsonUrl: string | null;
  transcriptJson: {
    accountId: string;
    jobName: string;
    status: string;
    results: {
      transcripts: ImmutableList<{ transcript: string }>;
    };
  } | null;
  transcriptItems: ImmutableList<ComprehensionTextDiffItem>;
  processingStatus:
    | 'queued'
    | 'started'
    | 'finished'
    | 'errored'
    | 'transcription_sent'
    | 'transcription_queued'
    | 'transcription_started';
};
