import { useCallback } from 'react';
import { useHistory, useParams, useQuery, useLocation } from 'src/modules/Router';
import { Button, Spacer, StandardModal, Text, Flex, LinkButton } from 'src/components';
import { useStore } from 'src/Store';
import { ImmutableList } from 'src/modules/Immutable';
import { Course, ENTERPRISE_PLAN_LABEL } from 'src/models';
import { startZendeskTicket } from 'src/modules/Analytics/Analytics';

export const ManageCoTeachersModal = () => {
  const {
    isCoTeachersEnabled = false,
    courses,
    loading,
    startSyncCourse,
  } = useStore(
    (s) => ({
      isCoTeachersEnabled: s.AppData.currentUser?.flags?.isCoTeachersEnabled,
      courses: s.TeacherCourseListData.courses,
      loading: s.TeacherCourseListData.loading,
      startSyncCourse: s.TeacherCourseListData.startSyncCourse,
    }),
    [],
  );
  const history = useHistory();
  const query = useQuery();
  const pathname = useLocation().pathname;
  const closeModal = useCallback(() => history.push('/t/classes'), [history]);
  const courseId = useParams<{ courseId: string }>().courseId;
  const course = courses.find((c) => c.id === courseId);
  if (!course) return null;
  const upgradeNeeded = !isCoTeachersEnabled;

  return (
    <StandardModal
      openType="path"
      header={upgradeNeeded ? `${ENTERPRISE_PLAN_LABEL} Feature` : 'Manage Co-Teachers'}
      openedPath="/t/classes/:courseId/co-teachers"
      close={closeModal}
      maxWidthOverride="350px"
      minWidthOverride="350px"
    >
      {upgradeNeeded ? (
        <Flex direction="column">
          <Text>
            Upgrade to {ENTERPRISE_PLAN_LABEL} to enable Co-teachers to track progress, assign
            readings and more.
          </Text>
          <Spacer size={3} />

          <Button href="https://www.readlee.com/contact" width="100%" newTab>
            Contact Us!
          </Button>
        </Flex>
      ) : (
        <Flex direction="column">
          <Text>
            For imported classes (Google Class or Clever), you can manage co-teachers directly in
            your LMS. To update, press &quot;sync.&quot;
          </Text>
          <Spacer size={4} />
          <Flex>
            <Text>To add a teacher manually,</Text>
            <Spacer horizontal />
            <LinkButton onClick={startZendeskTicket}>contact support.</LinkButton>
          </Flex>
          <Spacer size={4} />
          <Button
            width="100%"
            loading={loading}
            onClick={startSyncCourse({
              history,
              query,
              pathname,
              courses: ImmutableList<Course>([course]),
              isGoogle: Boolean(course.googleSyncedAt),
            })}
            disabled={!course.isLms}
          >
            Sync Co-Teachers
          </Button>
        </Flex>
      )}
    </StandardModal>
  );
};
