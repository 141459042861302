import { subDataCreator } from 'src/State';
import { defaultState } from './AssignmentBuilderState';
import { ErrorMapResponse } from 'src/modules/Api';
import {
  Assessment,
  AssessmentExtended,
  assessmentIsShared,
  ImageStoredFile,
  InstructionDetails,
  LicenseType,
  Reading,
  setInitialReadingPartType,
  TaskDetails,
} from 'src/models';
import { ImmutableList, ImmutableMap } from 'src/modules/Immutable';
import { updateQuery, History } from 'src/modules/Router';
import { ComprehensionTask } from 'src/models/ComprehensionTask';
import { VocabTask } from 'src/models/VocabTask';
import { TaskType } from 'src/models/Task';
import { DictionaryResponse } from 'src/components/Dictionary/DictionaryState';

type LoadAssessmentData = {
  assessment: AssessmentExtended;
  reading: Reading | null;
  taskDetailsMap: ImmutableMap<string, TaskDetails>;
  instructionDetails: InstructionDetails;
  primaryLicenseType: LicenseType | null;
};

type SubmitData = {
  success: boolean;
  assessment: AssessmentExtended;
  taskDetailsMap: ImmutableMap<string, TaskDetails>;
  errors: ErrorMapResponse;
  taskErrors: ImmutableMap<string, ErrorMapResponse>;
};

type CreateTaskData = {
  success: boolean;
  taskDetails: TaskDetails;
};

export const createDefaultAssignmentBuilderData = subDataCreator(
  'AssignmentBuilderData',
  defaultState,
  ({ set, get, setField, fetchJson }) => {
    const setPage = (key: 'startPoint' | 'endPoint') => (taskId: string, pageNumber: number) => {
      set((state) => {
        const taskDetails = state.taskDetailsMap.get(taskId);
        if (
          !taskDetails ||
          taskDetails.type !== 'ReadingTask' ||
          taskDetails.task.readingPartType !== 'pages'
        ) {
          return state;
        }
        return {
          ...state,
          dirty: true,
          taskDetailsMap: state.taskDetailsMap.set(taskId, {
            ...taskDetails,
            task: {
              ...taskDetails.task,
              [key]: {
                pageNumber,
              },
            },
          }),
        };
      });
    };

    const setComprehensionTaskField = <K extends keyof ComprehensionTask>(
      taskId: string,
      key: K,
      value: ComprehensionTask[K],
    ) => {
      set((state) => {
        const taskDetails = state.taskDetailsMap.get(taskId);
        const switchingToRecorded = key === 'questionType' && value === 'recorded';

        if (!taskDetails || taskDetails.type !== 'ComprehensionTask') {
          return { ...state, drawerExpanded: false };
        }
        return {
          ...state,
          taskDetailsMap: state.taskDetailsMap.set(taskDetails.task.id, {
            ...taskDetails,
            task: { ...taskDetails.task, [key]: value },
          }),
          drawerExpanded: switchingToRecorded ? false : state.drawerExpanded,
        };
      });
    };

    const setVocabTaskField = <K extends keyof VocabTask>(
      taskId: string,
      key: K,
      value: VocabTask[K],
    ) => {
      set((state) => {
        const taskDetails = state.taskDetailsMap.get(taskId);
        if (!taskDetails || taskDetails.type !== 'VocabTask') {
          return state;
        }
        return {
          ...state,
          taskDetailsMap: state.taskDetailsMap.set(taskDetails.task.id, {
            ...taskDetails,
            task: { ...taskDetails.task, [key]: value },
          }),
        };
      });
    };

    const createTask = (taskType: TaskType) => (history: History) => {
      const assessment = get().assessment;
      if (!assessment) return;

      set({ creatingTask: true });

      const taskToMethodMap = {
        ComprehensionTask: 'comprehension_tasks',
        ModelTask: 'model_tasks',
        ReadingTask: 'reading_tasks',
        VocabTask: 'vocab_tasks',
      } as const;

      fetchJson(`/api/assessments/${assessment.id}/${taskToMethodMap[taskType]}`, {
        method: 'POST',
        data: {},
        onSuccess: (data: CreateTaskData) => {
          set((state) => ({
            creatingTask: false,
            taskDetailsMap: state.taskDetailsMap.set(data.taskDetails.task.id, data.taskDetails),
            vocabTaskStep: 'word',
          }));
          history.push(
            `/t/assignment-builder/${assessment.id}/tasks?currentTaskId=${data.taskDetails.task.id}`,
          );
        },
      });
    };

    return {
      load: ({ assessmentId, readingId, currentTaskId }) => {
        set(defaultState);
        // Fetch stuff
        fetchJson(`/api/assessments/${assessmentId}/edit`, {
          data: {
            readingId,
          },
          onSuccess: (data: LoadAssessmentData) => {
            const currentTaskDetails = currentTaskId
              ? data.taskDetailsMap.get(currentTaskId, null)
              : null;

            set({
              loading: false,
              assessment: data.assessment,
              originalAssessment: data.assessment,
              reading: data.reading,
              taskDetailsMap: data.taskDetailsMap,
              instructionDetails: data.instructionDetails,
              originalTaskDetailsMap: data.taskDetailsMap,
              vocabTaskStep:
                currentTaskDetails?.type === 'VocabTask' && currentTaskDetails?.word
                  ? 'details'
                  : 'word',
              primaryLicenseType: data.primaryLicenseType,
              editingWrittenInstructions: data.assessment.instructions,
              editingPublishingStatus: data.assessment.publishingStatus,
            });
          },
        });
      },
      setReadingPartType: (taskId, readingPartType) => {
        set((state) => {
          const taskDetails = state.taskDetailsMap.get(taskId);
          if (!taskDetails) return state;
          if (taskDetails.type !== 'ReadingTask') return state;
          return {
            ...state,
            dirty: true,
            taskDetailsMap: state.taskDetailsMap.set(
              taskId,
              setInitialReadingPartType(taskDetails, readingPartType),
            ),
          };
        });
      },
      setTextRange: (taskId) => (textRange, text) => {
        set((state) => {
          const taskDetails = state.taskDetailsMap.get(taskId);
          if (
            !taskDetails ||
            taskDetails.type !== 'ReadingTask' ||
            taskDetails.task.readingPartType !== 'start_and_end_index'
          ) {
            return state;
          }
          return {
            ...state,
            dirty: true,
            taskDetailsMap: state.taskDetailsMap.set(taskId, {
              ...taskDetails,
              task: {
                ...taskDetails.task,
                ...textRange,
                newBasicText: text,
              },
            }),
          };
        });
      },
      setVocabWord: (word) => set({ vocabWord: word }),
      setStartPage: setPage('startPoint'),
      setEndPage: setPage('endPoint'),
      submit: ({ query, history, taskOrder: baseTaskOrder = null }) => {
        const { assessment, taskDetailsMap, editingWrittenInstructions } = get();
        if (!assessment) return;
        assessment.instructions = editingWrittenInstructions;
        const taskOrder = baseTaskOrder || assessment.taskOrder;
        set({ saving: true, dirty: false });
        fetchJson(`/api/assessments/${assessment.id}`, {
          method: 'PATCH',
          data: {
            assessment,
            taskDetails: taskOrder
              .map(({ taskId }) => taskDetailsMap.get(taskId))
              .toList()
              .filter(Boolean),
          },
          onSuccess: (data: SubmitData) => {
            set((state) => ({
              dirty: state.dirty || !data.success,
              saving: false,
              errors: ImmutableMap(data.errors),
              taskErrors: data.taskErrors.map((errors) => ImmutableMap(errors)),
            }));

            if (data.success) {
              set({
                assessment: data.assessment,
                originalAssessment: data.assessment,
                taskDetailsMap: data.taskDetailsMap,
                originalTaskDetailsMap: data.taskDetailsMap,
                editingInstructions: false,
                editingTitle: false,
                deletingTaskId: undefined,
                vocabWord: '',
              });
              history.replace(
                `/t/assignment-builder/${assessment.id}/tasks${updateQuery(query, {
                  currentTaskId: null,
                })}`,
              );
            }
          },
        });
      },
      cancel: () => {
        set((state) => ({
          ...state,
          dirty: false,
          editingTitle: false,
          editingInstructions: false,
          errors: ImmutableMap(),
          assessment: state.originalAssessment,
          editingWrittenInstructions: state.assessment?.instructions,
        }));
      },
      cancelTask: (currentTaskId) => {
        if (!currentTaskId) {
          set({
            dirty: false,
            cancelling: true,
            vocabTaskStep: 'word',
          });
          return;
        }

        const { taskDetailsMap, originalTaskDetailsMap } = get();
        const oldTaskDetails = originalTaskDetailsMap.get(currentTaskId);

        set({
          dirty: false,
          cancelling: true,
          taskDetailsMap: oldTaskDetails
            ? taskDetailsMap.set(currentTaskId, oldTaskDetails)
            : taskDetailsMap.remove(currentTaskId),
          vocabImageFile: null,
          vocabImageFileUrl: '',
        });
      },
      deleteTask: (currentTaskId, query, history) => {
        const { assessment } = get();

        if (!assessment) return;

        get().submit({
          query,
          history,
          taskOrder: assessment.taskOrder.filter((to) => to.taskId !== currentTaskId),
        });
      },
      finishCancel: ({ query, history }) => {
        const { assessment } = get();
        if (!assessment) return;

        set({ cancelling: false });
        history.replace(
          `/t/assignment-builder/${assessment.id}/tasks${updateQuery(query, {
            currentTaskId: null,
          })}`,
        );
      },
      setField,
      setAssessmentField: (key) => (value) => {
        set((state) =>
          state.assessment
            ? { ...state, assessment: { ...state.assessment, [key]: value }, dirty: true }
            : state,
        );
      },
      createComprehensionTask: createTask('ComprehensionTask'),
      createModelReading: createTask('ModelTask'),
      createVocabTask: createTask('VocabTask'),
      onRecordingSequenceStatus: (data) => {
        set((state) => {
          const taskDetails = state.taskDetailsMap.find(
            (taskDetails) =>
              (taskDetails.type === 'ComprehensionTask' &&
                taskDetails.questionRecordingSequence.id === data.recordingSequence.id) ||
              (taskDetails.type === 'ModelTask' &&
                taskDetails.recordingSequence.id === data.recordingSequence.id),
          );
          if (taskDetails && taskDetails.type === 'ComprehensionTask') {
            return {
              ...state,
              taskDetailsMap: state.taskDetailsMap.set(taskDetails.task.id, {
                ...taskDetails,
                questionRecordingSequence: data.recordingSequence,
                questionAudioFile: data.audioFile,
              }),
            };
          } else if (taskDetails && taskDetails.type === 'ModelTask') {
            return {
              ...state,
              taskDetailsMap: state.taskDetailsMap.set(taskDetails.task.id, {
                ...taskDetails,
                recordingSequence: data.recordingSequence,
                audioFile: data.audioFile,
              }),
            };
          }
          return state;
        });
      },
      onInstructionsRecordingSequenceStatus: (data) =>
        set({
          instructionDetails: {
            audioFile: data.audioFile,
            instructionsRecordingSequence: data.recordingSequence,
          },
        }),
      setQuestionType: (taskId, questionType) => {
        setComprehensionTaskField(taskId, 'questionType', questionType);
      },
      setResponseType: (taskId, responseType) => {
        setComprehensionTaskField(taskId, 'responseType', responseType);
      },
      setWrittenContent: (taskId, writtenContent) => {
        setComprehensionTaskField(taskId, 'writtenContent', writtenContent);
      },

      setDefinition: (taskId, definition) => {
        setVocabTaskField(taskId, 'definition', definition);
      },
      setCustomWord: (taskId, customWord) => {
        setVocabTaskField(taskId, 'customWord', customWord);
        setVocabTaskField(taskId, 'name', customWord);
      },
      setWordContent: (taskId, word) => {
        set((state) => {
          const taskDetails = state.taskDetailsMap.get(taskId);
          if (!taskDetails || taskDetails.type !== 'VocabTask') {
            return state;
          }
          return {
            ...state,
            taskDetailsMap: state.taskDetailsMap.set(taskDetails.task.id, {
              ...taskDetails,
              word: {
                lemmaWord: word,
                content: word,
                id: '',
                audioUrl: '',
                definitions: ImmutableList<string>(),
              },
            }),
          };
        });
      },
      setVocabImage: (taskId, imageFile) => {
        set((state) => {
          const taskDetails = state.taskDetailsMap.get(taskId);
          if (!taskDetails || taskDetails.type !== 'VocabTask') {
            return state;
          }
          return {
            ...state,

            taskDetailsMap: state.taskDetailsMap.set(taskDetails.task.id, {
              ...taskDetails,
              task: {
                ...taskDetails.task,
                imageFileId: imageFile ? taskDetails.task.imageFileId : '',
              },
              imageFile: imageFile,
            }),
          };
        });
      },

      saveTask: ({ taskDetails, query, history }) => {
        const { assessment } = get();
        if (!assessment) return;

        let taskOrder = assessment.taskOrder;
        if (
          !taskOrder.some((t) => t.taskId === taskDetails.task.id && t.type === taskDetails.type)
        ) {
          if (taskDetails.type === 'ModelTask') {
            const index = (taskOrder.findIndex((t) => t.type === 'ModelTask') ?? -1) + 1;
            taskOrder = taskOrder.insert(index, {
              taskId: taskDetails.task.id,
              type: taskDetails.type,
            });
          } else if (taskDetails.type === 'VocabTask') {
            const index = (taskOrder.findIndex((t) => t.type === 'VocabTask') ?? -1) + 1;
            taskOrder = taskOrder.insert(index, {
              taskId: taskDetails.task.id,
              type: taskDetails.type,
            });
          } else {
            taskOrder = taskOrder.push({
              taskId: taskDetails.task.id,
              type: taskDetails.type,
            });
          }
        }

        get().submit({ query, history, taskOrder });
      },
      saveWord: (taskId) => {
        const { vocabWord } = get();
        set({ saving: true });
        fetchJson(`/api/words/definition`, {
          noRedirect: true,
          data: { word: vocabWord },

          onSuccess: ({ displayWord }: DictionaryResponse) => {
            set((state) => {
              const taskDetails = state.taskDetailsMap.get(taskId);
              if (!taskDetails || taskDetails.type !== 'VocabTask') {
                return state;
              }
              return {
                ...state,
                saving: false,
                vocabTaskStep: 'details',
                taskDetailsMap: state.taskDetailsMap.set(taskDetails.task.id, {
                  ...taskDetails,
                  task: {
                    ...taskDetails.task,
                    wordId: displayWord.id,
                    definition: !displayWord.definitions.isEmpty()
                      ? displayWord.definitions.join('\n\n')
                      : 'Definition could not be autofilled',
                  },
                  word: displayWord,
                }),
              };
            });
          },
        });
      },
      togglePublishingStatus: (isPaperUser) => {
        const { assessment, editingPublishingStatus } = get();
        if (!assessment) return;

        const defaultPublishingStatus = isPaperUser ? 'district' : 'public_preview';

        const newPublishingStatus = assessmentIsShared(editingPublishingStatus)
          ? 'private'
          : defaultPublishingStatus;

        set({ editingPublishingStatus: newPublishingStatus });
      },
      updatePublishingStatus: () => {
        const { assessment, editingPublishingStatus } = get();
        if (!assessment) return;

        fetchJson(`/api/assessments/${assessment.id}/simple_update`, {
          method: 'PATCH',
          data: {
            assessment: { publishingStatus: editingPublishingStatus },
          },
          onSuccess: ({ assessment: { publishingStatus } }: { assessment: Assessment }) => {
            set({
              assessment: {
                ...assessment,
                publishingStatus,
              },
              editingPublishingStatus: publishingStatus,
              sharingOpen: false,
            });
          },
        });
      },
      updateSampleStatus: (isSample) => {
        const assessment = get().assessment;
        if (!assessment) return;

        set({ assessment: { ...assessment, isSample } });
        fetchJson(`/api/assessments/${assessment.id}/simple_update`, {
          method: 'PATCH',
          data: { assessment: { isSample } },
        });
      },
      createFreeRead: (history) => {
        set({ freeReadLoading: true });
        fetchJson(`/api/free_reads`, {
          method: 'POST',
          onSuccess: ({ success, assessment }: { success: boolean; assessment: Assessment }) => {
            set({ freeReadLoading: false });
            if (success) {
              history.push(`/reading-studio?assessmentId=${assessment.id}`);
            }
          },
        });
      },
      uploadVocabImage: (taskId: string) => (files) => {
        const file = files.get(0, null);
        if (file) {
          set({ vocabImageFileUrl: file ? URL.createObjectURL(file) : '', uploading: true });
          fetchJson(`/api/image_stored_files`, {
            method: 'POST',
            files: {
              file,
            },
            onSuccess: ({ storedFile }: { storedFile: ImageStoredFile }) => {
              const { taskDetailsMap } = get();
              const currentTask = taskDetailsMap.get(taskId);
              if (!currentTask || currentTask.type !== 'VocabTask') return;
              set({
                vocabImageFileUrl: null,
                uploading: false,
                taskDetailsMap: taskDetailsMap.set(taskId, {
                  ...currentTask,
                  imageFile: storedFile,
                  task: { ...currentTask.task, imageFileId: storedFile.id },
                }),
              });
            },
          });
        }
      },
      upgradeAssessmentAndStartVocab: (history) => () => {
        set((s) => {
          if (!s.assessment) return s;
          return { assessment: { ...s.assessment, isUltimate: true } };
        });
        get().createVocabTask(history);
      },
      startSharing: () => {
        set((s) => ({
          sharingOpen: true,
          editingPublishingStatus: s.assessment?.publishingStatus,
        }));
      },
    };
  },
);
