import Bookworm from 'src/assets/bookworm.svg';
import SittingPerson from 'src/assets/sitting_person.svg';
import BookData from 'src/assets/book_data.svg';
import SyncingCloud from 'src/assets/syncing_cloud.svg';
import { Button, LinkButton } from 'src/components/Button';
import { Text } from 'src/components/Text';
import { Flex } from 'src/components/Flex';
import { Spacer } from 'src/components/Spacer';
import { PaperSyncButton } from 'src/components/PaperSync';
import { EmptyStateBase, EmptyStateDisplayType } from 'src/components';
import { FreeReadButton } from '../FreeReadButton';
import { CreateCourseButton } from 'src/pages/CreateCourseModal';
import { paddings } from 'src/styles';
import { startZendeskTicket } from 'src/modules/Analytics/Analytics';

const KINDS = {
  independentReading: {
    primaryText: 'Independent Reading',
    secondaryText: (
      <Text textAlign="center" display="inline">
        Let students choose what they want to read. They will record themselves reading. You will
        get the recording, transcript, words per minute, and more.
      </Text>
    ),
    illustration: Bookworm,
    helpHref: null,
  },
  assignments: {
    secondaryText: (
      <>
        <Text textAlign="center">
          Create an assignment to get fluency data and transcripts when your students read, saving
          you valuable class time.
        </Text>
        <Spacer size={4} />
        <Text textAlign="center" display="inline">
          Click the &quot;New Assignment&quot; button in the top right corner to get started.
        </Text>
      </>
    ),
    helpHref: 'https://readleesupport.zendesk.com/hc/en-us/articles/14120276613773',
    illustration: Bookworm,
    width: '400px',
  },
  assignmentsPaper: {
    secondaryText: (
      <>
        <Text textAlign="center">
          Create an assignment to get fluency data and transcripts when your students read, saving
          you valuable class time.
        </Text>
        <Spacer size={4} />
        <Button
          color="primaryBlue"
          to="/t/reading-builder/content"
          height="40px"
          testTag="new-assignments-empty-state"
        >
          New Assignment
        </Button>
        <Spacer size={4} />
        <Text textAlign="center">
          You can also click the &quot;New Assignment&quot; button in the top right corner at any
          time to create a new assignment.
        </Text>
      </>
    ),

    illustration: Bookworm,
    width: '400px',
  },
  archivedAssignments: {
    primaryText: 'No Archived Assignments',
    secondaryText: (
      <Text textAlign="center" display="inline">
        There are no archived assignments in this course
      </Text>
    ),
    illustration: Bookworm,
    helpHref: '',
  },
  assignedStudents: {
    primaryText: 'No Students Assigned',
    secondaryText: (
      <Text textAlign="center" display="inline">
        Assign students to this assignment to see student progress here
      </Text>
    ),
    helpHref: 'https://readleesupport.zendesk.com/hc/en-us/articles/14120276613773',
    illustration: SittingPerson,
  },
  classes: {
    primaryText: 'No Classes',
    secondaryText: (
      <>
        <Text display="inline">
          Import your class from Google Classroom or Clever to start monitoring student reading
          progress.
        </Text>
        <Spacer size={3} />
        <Text display="inline">
          You can also create a class manually, then ask your students to join via a class link or
          class code.
        </Text>
        <Spacer size={3} />

        <Text display="inline">
          Click on the ‘Create Class’ button in the top right corner to start.
        </Text>
      </>
    ),
    helpHref: 'https://readleesupport.zendesk.com/hc/en-us/articles/14120321804301',
    illustration: SittingPerson,
  },
  assignToClasses: {
    primaryText: 'No Classes',
    secondaryText: (
      <>
        <Text display="inline">
          Import your class from Google Classroom or Clever to start monitoring student reading
          progress.
        </Text>
        <Spacer size={3} />
        <Text display="inline">
          You can also create a class manually, then ask your students to join via a class link or
          class code.
        </Text>
        <Spacer size={3} />
        <CreateCourseButton
          displayType="button"
          openedPath="/t/classes/new"
          closedPath="/t/classes"
        />
      </>
    ),
    helpHref: '',
    illustration: BookData,
  },
  progressMonitoringPaper: {
    primaryText:
      'Create and assign a reading assignment to your students to start seeing their reading progress!',
    secondaryText: (
      <>
        <Spacer size={4} />
        <Button
          color="primaryBlue"
          to="/t/reading-builder/content"
          height="40px"
          testTag="pm-new-assignment"
        >
          New Assignment
        </Button>
      </>
    ),
    illustration: SittingPerson,
  },
  classesPaper: {
    primaryText: "Looks like you don't have any classes yet",
    secondaryText: (
      <Flex direction="column" align="center">
        <Spacer size={2} />
        <Text color="gray6">Sync your classes and assign your first reading.</Text>
        <Spacer size={5} />
        <PaperSyncButton />
      </Flex>
    ),
    illustration: BookData,
    illustrationHeight: '150px',
    width: '550px',
  },
  noClassesPaper: {
    primaryText: "Looks like we weren't able to find any classes",
    secondaryText: (
      <Flex direction="column" align="center">
        <Spacer size={2} />
        <Text color="gray6">
          Sync successful, but no classes were found. If you were expecting to see any classes
          please{' '}
          <LinkButton
            display="inline"
            flexDisplay="inline-flex"
            paddingOverride={paddings.none}
            onClick={startZendeskTicket}
          >
            contact support
          </LinkButton>{' '}
        </Text>
        <Spacer size={5} />
        <PaperSyncButton />
      </Flex>
    ),
    illustration: BookData,
    illustrationHeight: '150px',
    width: '550px',
  },
  classesPaperSyncing: {
    primaryText:
      "We're importing your students and classes. Please wait for this finish before asking your students to visit.",
    secondaryText: (
      <Flex direction="column" align="center">
        <Spacer size={2} />
        <Text color="gray6">
          We will notify you once we&apos;re done. Feel free to explore the rest of Paper Reading
          while you wait!
        </Text>
        <Spacer size={5} />
        <PaperSyncButton />
      </Flex>
    ),
    illustration: SyncingCloud,
    illustrationHeight: '110px',
    width: '500px',
  },
  progressMonitoring: {
    primaryText: 'Want to see the reading progress of your students? Get started in 3 quick steps:',
    secondaryText: (
      <Flex direction="column">
        <Spacer size={4} />
        <ol>
          <Flex align="center">
            <Text variant="h6" textAlign="left">
              <li>Create a reading assignment for your students</li>
            </Text>
            <Spacer horizontal />
            <Button
              displayType="iconOnly"
              icon="forwardArrow"
              color="backgroundLight"
              iconColor="primaryBlue"
              height="100%"
              to="/t/reading-builder/content"
            />
          </Flex>
          <Spacer size={4} />
          <Flex align="center">
            <Text variant="h6" textAlign="left">
              <li>Create your class or import it from Google Classroom or Clever</li>
            </Text>
            <Spacer horizontal />
            <Button
              displayType="iconOnly"
              icon="forwardArrow"
              color="backgroundLight"
              iconColor="primaryBlue"
              height="100%"
              to="/t/classes/new"
            />
          </Flex>
          <Spacer size={4} />
          <Flex align="center">
            <Text variant="h6" textAlign="left">
              <li>Assign the assignment to students from your class</li>
            </Text>
            <Spacer horizontal />
            <Button
              displayType="iconOnly"
              icon="forwardArrow"
              color="backgroundLight"
              iconColor="primaryBlue"
              height="100%"
              to="/t/assignments"
            />
          </Flex>
        </ol>
      </Flex>
    ),
    helpHref: '',
    illustration: SittingPerson,
  },
  students: {
    primaryText: 'No Students',
    secondaryText: (
      <>
        <Text textAlign="center" display="inline">
          There are currently no students assigned to this class.
        </Text>
        <Spacer />
        <Text textAlign="center" display="inline">
          Use the &quot;Add New Student&quot; button to manually add students to this class, or use
          the class filter above to view the students in another class.
        </Text>
      </>
    ),
    helpHref: 'https://readleesupport.zendesk.com/hc/en-us/articles/14127882029453',
    illustration: SittingPerson,
  },
  emptyAssignedDashboard: {
    helpHref: '',
    illustration: Bookworm,
    primaryText: "You're all caught up!",
    secondaryText: (
      <Flex direction="column">
        <Text variant="h5">Click &quot;Free Read&quot; to start reading!</Text>
        <Spacer size={2} />
        <Flex justify="center">
          <FreeReadButton displayType="button" />
        </Flex>
      </Flex>
    ),
  },
  emptySubmittedDashboard: {
    primaryText: 'No Submissions',
    secondaryText: (
      <Text textAlign="center" display="inline">
        Submitted assignments will display here once you have turned them in.
      </Text>
    ),
    helpHref: '',
    illustration: SittingPerson,
  },
  emptyStudentProgress: {
    primaryText: 'No Submissions to display',
    secondaryText: (
      <Text textAlign="center" display="inline">
        Try adjusting the Class and Date Range Filter to view more data.
      </Text>
    ),
    helpHref: '',
    illustration: Bookworm,
  },
  emptyComments: {
    primaryText: 'Leave your student motivational feedback to encourage their reading habits!',
    secondaryText: '',
    helpHref: '',
    illustration: Bookworm,
  },
} as const;

type EmptyStateProps = {
  kind: keyof typeof KINDS;
  displayType?: EmptyStateDisplayType;
};

export const EmptyState = ({ kind, ...props }: EmptyStateProps) => (
  <EmptyStateBase testTag={`empty-state-${kind}`} {...KINDS[kind]} {...props} />
);
