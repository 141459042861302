const LOCAL_STORAGE_TEST_KEY = 'LOCAL_STORAGE_TEST_KEY';
const LOCAL_STORAGE_TEST_VALUE = 'LOCAL_STORAGE_TEST_VALUE';

// Sometimes the browser does not allow access to localStorage, check to see
// if it's working
const checkLocalStorage = () => {
  try {
    localStorage.setItem(LOCAL_STORAGE_TEST_KEY, LOCAL_STORAGE_TEST_VALUE);
    const value = localStorage.getItem(LOCAL_STORAGE_TEST_KEY);
    localStorage.removeItem(LOCAL_STORAGE_TEST_KEY);
    return value === LOCAL_STORAGE_TEST_VALUE;
  } catch (e) {
    return false;
  }
};

let localStorageChecked = false;
let localStorageWorking = false;

const getMemoryStorage = () => {
  const map = new Map<string, string>();
  return {
    setItem: (key: string, value: string) => {
      map.set(key, value);
    },
    getItem: (key: string) => {
      return map.get(key);
    },
    removeItem: (key: string) => {
      map.delete(key);
    },
  };
};

const memoryStorage = getMemoryStorage();

const getStorage = () => {
  if (!localStorageChecked) {
    localStorageWorking = checkLocalStorage();
    localStorageChecked = true;
  }
  if (localStorageWorking) {
    return localStorage;
  } else {
    return memoryStorage;
  }
};

export const setItem = (key: string, value: string) => {
  return getStorage().setItem(key, value);
};

export const getItem = (key: string): string | null | undefined => {
  return getStorage().getItem(key);
};
export const removeItem = (key: string) => {
  return getStorage().removeItem(key);
};
