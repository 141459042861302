import {
  Flex,
  Spacer,
  Tag,
  Icon,
  Label,
  Copyable,
  Accordion,
  Button,
  Tooltip,
  Text,
} from 'src/components';
import { Course, courseCodeLink } from 'src/models';
import { ellipsify } from 'src/modules/String';
import { paddings, colors, borderRadii, rgba } from 'src/styles';

export const ManualConfirmation = ({ course }: { course: Course }) => {
  return (
    <>
      <Flex direction="column" align="center">
        <Text variant="h3" weightOverride="medium">
          Your class{' '}
          <Text
            variant="h3"
            color="primaryBlue"
            display="inline"
            weightOverride="medium"
            testTag="courseName"
            title={course.name}
          >
            &quot;{ellipsify(course.name || '', 20)}&quot;
          </Text>{' '}
          was created successfully
        </Text>
        <Spacer />
        <Text>Now it&apos;s time to add your students</Text>
      </Flex>

      <Spacer size={3} />
      <Flex
        direction="column"
        padding={paddings[6]}
        backgroundColor={colors.backgroundLight.hex}
        borderRadius={borderRadii[2]}
        border={`1px solid ${colors.gray2.hex}`}
      >
        <Flex align="center">
          <Text variant="h4" weightOverride="bold">
            Share the class link
          </Text>
          <Spacer size={2} horizontal />
          <Tag color="success">
            <Text variant="h6">Recommended</Text>
          </Tag>
        </Flex>
        <Spacer size={1} />
        <Text>
          Students can join your class by signing up with <b>Google and Clever</b> or by creating
          their own username and password.
        </Text>
        <Spacer size={4} />
        <Flex
          padding={`${paddings[2]} ${paddings[6]}`}
          backgroundColor={rgba(colors.primaryBlue.hex, 0.15)}
          borderRadius={borderRadii[2]}
          align="center"
        >
          <Icon icon="info" strokeWidth={2} size="1.25em" color="primaryBlue" />
          <Spacer size={2} horizontal />
          <Text weightOverride="semibold">
            90% of students prefer to login with <b>Google or Clever</b>.
          </Text>
        </Flex>
        <Spacer size={4} />
        <Label $isTextInput>Share Link</Label>
        <Copyable
          copyContent={courseCodeLink(course.courseCode)}
          displayType="link"
          color="primaryBlue"
          kind="scrollable"
        >
          <Text wordBreak="break-word">{courseCodeLink(course.courseCode)}</Text>
        </Copyable>
      </Flex>

      <Spacer size={10} />
      <Flex align="center">
        <Text variant="h4" weightOverride="bold">
          Alternative Methods
        </Text>
        <Spacer size={2} horizontal />
        <Tooltip
          content="Protecting students privacy is extremely important to us. We will not ask to collect the names of students who sign up with a class link or class code. Please instruct your students on what format to use for their unique username."
          iconColor="primaryBlue"
          iconSize="1.25em"
        />
      </Flex>
      <Spacer size={4} />
      <Accordion label="Add students manually">
        <Flex direction="column" width="100%">
          <Text>
            Enter your students&apos; information and create usernames and passwords for each
            student.
          </Text>
          <Spacer size={2} />
          <Flex width="100%" justify="end">
            <Button displayType="outline" icon="addUser" to="/t/students/new">
              Add Students
            </Button>
          </Flex>
        </Flex>
      </Accordion>
      <Spacer size={4} />
      <Accordion label="Share the class code">
        <Flex direction="column">
          <Text>
            If your students already have a Readlee account, they can login, click the &quot;Join
            Class&quot; button, and enter the class code displayed below.
          </Text>
          <Spacer size={2} />
          <Copyable
            copyContent={course.courseCode}
            displayType="link"
            color="primaryBlue"
            kind="scrollable"
            copyText="Copy Code"
          >
            <Text>{course.courseCode}</Text>
          </Copyable>
        </Flex>
      </Accordion>
    </>
  );
};
