import { Assessment } from 'src/models';
import { emptyErrorMap, ErrorMap } from 'src/modules/Api';
import { SetField } from 'src/State';
import { CreateToast } from '../Toast';

export type UpgradeAssignmentModalState = {
  setField: SetField<UpgradeAssignmentModalState>;
  loading: boolean;
  saving: boolean;
  opened: boolean;
  assessmentId: string;
  assessment: Assessment | null;
  upgradingFor: string;
  errors: ErrorMap;
  startAssignmentUpgrade: (assessmentId: string, upgradingFor: string) => void;
  upgradeAssignment: (
    createToast: CreateToast,
    onSuccess: (assessment: Assessment) => void,
  ) => void;
  closeModal: () => void;
};

export const defaultState = {
  opened: false,
  loading: true,
  saving: false,
  assessmentId: '',
  assessment: null,
  upgradingFor: '',
  errors: emptyErrorMap(),
};
