import { ComprehensionTaskSubmissionDetails } from 'src/models';
import { Text, AudioPlayer, Flex, Spacer, DrawerExpandButton } from 'src/components';
import { useStore } from 'src/Store';

export type ComprehensionDisplayType = 'drawerOpen' | 'drawerClosed' | 'mainContent';

type ComprehensionHeaderProps = {
  currentTaskSubmissionDetails: ComprehensionTaskSubmissionDetails;
  displayType: ComprehensionDisplayType;
  textSize?: number;
  toggleOpen?: () => void;
};

const QuestionContent = ({
  currentTaskSubmissionDetails,
  displayType,
  textSize = 16,
}: {
  currentTaskSubmissionDetails: ComprehensionTaskSubmissionDetails;
  displayType: ComprehensionDisplayType;
  textSize?: number;
}) => {
  const { task, questionAudioFile } = currentTaskSubmissionDetails;
  const isRecording = useStore(({ AppData }) => AppData.isRecording, []);
  if (task.questionType === 'recorded') {
    if (questionAudioFile?.url) {
      return (
        <Flex width="100%" align="center">
          <AudioPlayer
            placeholder="Listen to the Question"
            audioFileId={questionAudioFile.id}
            src={questionAudioFile.url}
            displayType="desktopSmall"
            disabled={isRecording}
            testTag="comprehension-play-button"
          />
        </Flex>
      );
    } else {
      return null;
    }
  } else {
    return (
      <Text
        whiteSpace="pre-wrap"
        maxWidth={displayType === 'drawerClosed' ? '400px' : '100%'}
        maxHeight={displayType === 'drawerClosed' ? '75px' : '100%'}
        overflowY="auto"
        sizeOverride={textSize}
      >
        {task.writtenContent}
      </Text>
    );
  }
};

export const ComprehensionHeader = ({
  displayType,
  toggleOpen,
  currentTaskSubmissionDetails,
  textSize,
}: ComprehensionHeaderProps) => (
  <Flex
    direction="column"
    width="100%"
    justify="space-between"
    height={displayType === 'mainContent' ? undefined : '100%'}
  >
    <Flex justify="space-between" align="center">
      <Text variant="h5">Question prompt:</Text>
      <Spacer horizontal size={10} />
      {displayType !== 'mainContent' && toggleOpen ? (
        <DrawerExpandButton expanded={displayType === 'drawerOpen'} toggleOpen={toggleOpen} />
      ) : null}
    </Flex>
    <Spacer size={2} />
    <QuestionContent
      currentTaskSubmissionDetails={currentTaskSubmissionDetails}
      displayType={displayType}
      textSize={textSize}
    />
  </Flex>
);
