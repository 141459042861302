import { Assessment } from 'src/models';
import { emptyErrorMap, ErrorMapResponse } from 'src/modules/Api';
import { subDataCreator } from 'src/State';
import { defaultState } from './UpgradeAssignmentModalState';
import { ImmutableMap } from 'src/modules/Immutable';
import { trackAssessmentUpgraded } from 'src/modules/Analytics/AnalyticsEvents';

type UpgradeAssignmentResponse = {
  success: boolean;
  errors: ErrorMapResponse;
  assessment: Assessment;
};

export const createDefaultUpgradeAssignmentModalData = subDataCreator(
  'UpgradeAssignmentModalData',
  defaultState,
  ({ set, setField, get, getFull, fetchJson }) => ({
    ...defaultState,
    setField,
    closeModal: () => set(defaultState),
    startAssignmentUpgrade: (assessmentId, upgradingFor) => {
      set({ ...defaultState, opened: true, upgradingFor });
      fetchJson(`/api/assessments/${assessmentId}/edit`, {
        onSuccess: ({ assessment }: { assessment: Assessment }) =>
          set({ assessment, loading: false }),
      });
    },
    upgradeAssignment: (createToast, onSuccess) => {
      const { assessment, closeModal, upgradingFor } = get();

      if (!assessment) return;
      set({ saving: true, errors: emptyErrorMap() });
      fetchJson(`/api/assessments/${assessment.id}/upgrade`, {
        method: 'POST',
        onSuccess: ({ success, errors, assessment }: UpgradeAssignmentResponse) => {
          if (success) {
            createToast({ children: `${assessment.name} has been upgraded!` });
            closeModal();
            onSuccess(assessment);
            getFull().AppData.load({ skipStateReset: true });
            trackAssessmentUpgraded({ assessment, upgradedFor: upgradingFor });
          } else {
            set({ saving: false, errors: ImmutableMap(errors) });
          }
        },
      });
    },
  }),
);
