// You can uncomment font weights here and in reset.scss to enable more
export const fontWeights = {
  // extralight: '300',
  // light: '300',
  // regular: '400',
  default: '500',
  medium: '500',
  semibold: '600',
  bold: '700',
  // extrabold: '800',
};

export type FontWeight = keyof typeof fontWeights;
