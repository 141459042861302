import { useCallback } from 'react';
import { Button, Spacer, StandardModal, useToaster, Text, Icon, Flex } from 'src/components';
import { useStore } from 'src/Store';

export const ConfirmPurchaseModal = () => {
  const { confirmModalOpen, itemToBuy, currentUser, purchaseItem, setField } = useStore(
    (state) => ({
      confirmModalOpen: state.AvatarBuilderData.confirmModalOpen,
      itemToBuy: state.AvatarBuilderData.itemToBuy,
      purchaseItem: state.AvatarBuilderData.purchaseItem,
      setField: state.AvatarBuilderData.setField,
      currentUser: state.AppData.currentUser,
    }),
    [],
  );

  const { createToast } = useToaster();
  const close = useCallback(() => setField('confirmModalOpen')(false), [setField]);

  if (!confirmModalOpen || !currentUser || !itemToBuy) return null;
  return (
    <StandardModal
      testTag="confirm-purchase"
      openType="state"
      opened={confirmModalOpen}
      header="Buy Item"
      close={close}
      onSubmit={() => purchaseItem(createToast)}
      footer={
        <>
          <Button onClick={close} displayType="link" color="primaryBlue">
            Cancel
          </Button>
          <Spacer horizontal size={3} />
          <Button type="submit">Buy</Button>
        </>
      }
    >
      <Flex justify="center" align="center" direction="column">
        <Text variant="h5" textAlign="center">
          Are you sure you want to buy this item?
        </Text>
        <Spacer size={4} />
        <Flex align="center">
          <Text variant="h5" textAlign="center">
            Cost: {itemToBuy.price}
          </Text>
          <Spacer horizontal />
          <Icon icon="token" size="1.25em" color="white" />
        </Flex>
        <Spacer size={6} />
        <Text variant="h5" weightOverride="bold" textAlign="center">
          Your Points
        </Text>
        <Flex align="center">
          <Text variant="h5" textAlign="center">
            {currentUser?.pointBalance}
          </Text>
          <Spacer horizontal />
          <Icon icon="token" size="1.25em" color="white" />
          <Spacer horizontal />
          <Icon icon="forwardArrow" size="2em" color="primaryBlue" />
          <Spacer horizontal />
          <Text variant="h5" textAlign="center">
            {currentUser.pointBalance - itemToBuy.price}
          </Text>
          <Spacer horizontal />
          <Icon icon="token" size="1.25em" color="white" />
        </Flex>
      </Flex>
    </StandardModal>
  );
};
