import styled from 'styled-components';

type Rgb = {
  r: number;
  g: number;
  b: number;
};

export type HexColor = `#${string}`;
export type RgbColor = `rgb(${string}, ${string}, ${string})`;
export type RgbaColor = `rgba(${string}, ${string}, ${string}, ${string})`;
export type CssColor = HexColor | RgbColor | RgbaColor | 'transparent' | 'inherit';

const hexToRgb = (baseHex: HexColor): Rgb => {
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  const hex =
    baseHex.length === 4
      ? `#${baseHex.charAt(1)}${baseHex.charAt(1)}${baseHex.charAt(2)}${baseHex.charAt(
          2,
        )}${baseHex.charAt(3)}${baseHex.charAt(3)}`
      : baseHex;

  if (hex.length !== 7) throw new Error(`Invalid hex code: ${baseHex}`);

  const r = parseInt(hex.substring(1, 3), 16);
  const g = parseInt(hex.substring(3, 5), 16);
  const b = parseInt(hex.substring(5, 7), 16);
  return {
    r,
    g,
    b,
  };
};

const constrainBase16 = (value: number) => {
  return Math.min(Math.max(0, Math.round(value)), 255);
};

const rgbToHex = ({ r, g, b }: Rgb): HexColor => {
  const toHex = (value: number) => constrainBase16(value).toString(16).padStart(2, '0');
  return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
};

const applyTransformation = (
  hex: HexColor,
  fn: (value: number, key: keyof Rgb) => number,
): HexColor => {
  const { r, g, b } = hexToRgb(hex);
  return rgbToHex({ r: fn(r, 'r'), g: fn(g, 'g'), b: fn(b, 'b') });
};

export const darken = (hex: HexColor, percentAmount: number): HexColor => {
  const decimal = (0 - percentAmount) / 100;
  return applyTransformation(hex, (value) => value + 255 * decimal);
};

export const lighten = (hex: HexColor, percentAmount: number): HexColor => {
  const decimal = percentAmount / 100;
  return applyTransformation(hex, (value) => value + 255 * decimal);
};

export const rgba = (hex: HexColor, opacity: number): RgbaColor => {
  const { r, g, b } = hexToRgb(hex);
  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
};

export const basicColors = {
  white: '#FFFFFF',
  black: '#000000',
  gray1: '#F7F7F7',
  gray2: '#ececec',
  gray3: '#ebebf8',
  gray4: '#CFCFCF',
  gray5: '#A6ABB6',
  gray6: '#888C94',
  gray7: '#666666',
  lightBlue: '#CDDEF6',
  primaryBlue: '#325FFF',
  readleeBlue: '#2033DF',
  paperBlue: '#0E0FE0',
  green: '#00D863',
  success: '#219653',
  danger: '#F40000',
  warning: '#F5B23E',
  backgroundDark: '#021B47',
  backgroundLight: '#F6F8FC',
  backgroundLightText: '#2B2B2B',
} as const;

export const colors = {
  white: {
    hex: basicColors.white,
    disabled: basicColors.gray5,
    active: darken(basicColors.white, 10),
    text: basicColors.backgroundLightText,
  },
  black: {
    hex: basicColors.black,
    disabled: basicColors.gray7,
    active: darken(basicColors.black, 10),
    text: basicColors.white,
  },
  gray1: {
    hex: basicColors.gray1,
    disabled: lighten(basicColors.gray1, 25),
    active: darken(basicColors.gray1, 10),
    text: basicColors.black,
  },
  gray2: {
    hex: basicColors.gray2,
    disabled: lighten(basicColors.gray2, 15),
    active: darken(basicColors.gray2, 10),
    text: basicColors.black,
  },
  gray3: {
    hex: basicColors.gray3,
    disabled: lighten(basicColors.gray3, 25),
    active: darken(basicColors.gray3, 10),
    text: basicColors.black,
  },
  gray4: {
    hex: basicColors.gray4,
    disabled: lighten(basicColors.gray4, 25),
    active: darken(basicColors.gray4, 10),
    text: basicColors.black,
  },
  gray5: {
    hex: basicColors.gray5,
    disabled: darken(basicColors.gray5, 25),
    active: darken(basicColors.gray5, 10),
    text: basicColors.black,
  },
  gray6: {
    hex: basicColors.gray6,
    disabled: lighten(basicColors.gray6, 25),
    active: darken(basicColors.gray6, 10),
    text: basicColors.black,
  },
  gray7: {
    hex: basicColors.gray7,
    disabled: lighten(basicColors.gray7, 25),
    active: darken(basicColors.gray7, 10),
    text: basicColors.black,
  },
  lightBlue: {
    hex: basicColors.lightBlue,
    disabled: lighten(basicColors.lightBlue, 25),
    active: darken(basicColors.lightBlue, 10),
    text: basicColors.black,
  },
  primaryBlue: {
    hex: basicColors.primaryBlue,
    disabled: lighten(basicColors.primaryBlue, 25),
    active: darken(basicColors.primaryBlue, 10),
    text: basicColors.white,
  },
  readleeBlue: {
    hex: basicColors.readleeBlue,
    disabled: lighten(basicColors.readleeBlue, 25),
    active: darken(basicColors.readleeBlue, 10),
    text: basicColors.white,
  },
  paperBlue: {
    hex: basicColors.paperBlue,
    disabled: lighten(basicColors.paperBlue, 25),
    active: darken(basicColors.paperBlue, 20),
    text: basicColors.white,
  },
  green: {
    hex: basicColors.green,
    disabled: lighten(basicColors.green, 25),
    active: darken(basicColors.green, 10),
    text: basicColors.white,
  },
  success: {
    hex: basicColors.success,
    disabled: lighten(basicColors.success, 25),
    active: darken(basicColors.success, 20),
    text: basicColors.white,
  },
  danger: {
    hex: basicColors.danger,
    disabled: lighten(basicColors.danger, 25),
    active: darken(basicColors.danger, 10),
    text: basicColors.white,
  },
  warning: {
    hex: basicColors.warning,
    disabled: basicColors.warning,
    active: darken(basicColors.warning, 10),
    text: basicColors.black,
  },
  backgroundDark: {
    hex: basicColors.backgroundDark,
    disabled: lighten(basicColors.backgroundDark, 25),
    active: darken(basicColors.backgroundDark, 10),
    text: basicColors.white,
  },
  backgroundLight: {
    hex: basicColors.backgroundLight,
    disabled: lighten(basicColors.backgroundLight, 25),
    active: darken(basicColors.backgroundLight, 10),
    text: basicColors.backgroundLightText,
  },
} as const;

const colorHexes = Object.values(basicColors);

export const classColors = [
  '#30BC35',
  '#581828',
  '#6F2486',
  '#767BDC',
  '#BCBD4C',
  '#E25C4A',
  '#FA1CE8',
] as const;

export const userColors = [
  '#E4EEEB',
  '#E5FCE8',
  '#E6EFF3',
  '#E6F4FC',
  '#E8F1F8',
  '#E8F4F7',
  '#E8FAF4',
  '#EDE8E6',
  '#EDEAED',
  '#F0EDF7',
  '#F0F8FA',
  '#F0FBE5',
  '#F2F3F7',
  '#F3FCEF',
  '#F6EEEC',
  '#F9F2F0',
  '#FAEEE4',
  '#FBE8E5',
  '#FCF1FA',
  '#FCF2E6',
] as const;

export const confettiColors = ['#7339AB', '#625AD8', '#1F9CE4', '#88F4FF'] as const;

export type ClassColor = (typeof classColors)[number];
export type UserColor = (typeof userColors)[number];
export type ColorHexes = (typeof colorHexes)[number];
export type Color = keyof typeof colors;

export const ColorSwatch = styled.div<{ $color: Color }>`
  background: ${({ $color }) => colors[$color].hex};
  border: 1px solid black;
  height: 140px;
  width: 140px;
`;
