import { useCallback } from 'react';
import { useStore } from 'src/Store';
import { useHistory, useQuery, useLocation } from 'src/modules/Router';
import { classColors } from 'src/styles';
import { DISABLED_GRADE_LEVELS } from 'src/models';
import {
  StandardModal,
  Flex,
  Button,
  ClassAvatar,
  FormGroup,
  Spacer,
  useToaster,
} from 'src/components';

export const EditCourseModal = () => {
  const { createToast } = useToaster();
  const history = useHistory();
  const query = useQuery();
  const closeModal = useCallback(() => history.replace('/t/classes'), [history]);
  const location = useLocation();
  const isCreate = location.pathname.includes('create');
  const {
    courseIdsToEdit,
    editingCourse,
    editCourse,
    setEditCourseField,
    gradeLevels,
    editErrors,
    archive,
    generateClassCode,
    subjects,
    tempEditingCourseSubjectIds,
    setField,
  } = useStore(
    ({ TeacherCourseListData: s }) => ({
      courseIdsToEdit: s.courseIdsToEdit,
      editingCourse: s.editingCourse,
      editCourse: s.editCourse,
      setEditCourseField: s.setEditCourseField,
      gradeLevels: s.gradeLevels,
      editErrors: s.editErrors,
      archive: s.archive,
      generateClassCode: s.generateClassCode,
      subjects: s.subjects,
      tempEditingCourseSubjectIds: s.tempEditingCourseSubjectIds,
      setField: s.setField,
    }),
    [],
  );

  if (!editingCourse) return null;
  const editingCourseIndex = courseIdsToEdit.findIndex((id) => id === editingCourse.id);
  return (
    <StandardModal
      openType="path"
      header={`${isCreate ? 'Create' : 'Edit'} Class
          ${
            courseIdsToEdit.size > 1
              ? ` — ${editingCourseIndex + 1} of ${courseIdsToEdit.size}`
              : ''
          }`}
      openedPath="/t/classes/(create|edit)"
      close={closeModal}
      disableFadeClose
      footer={
        <Button width="100%" onClick={() => editCourse(history, query, isCreate)}>
          Save Class
        </Button>
      }
    >
      <Flex align="center" justify="space-between">
        <ClassAvatar
          name={editingCourse.name || ''}
          backgroundColor={editingCourse.avatarColor || classColors[0]}
          size="extraLarge"
        />
        {!isCreate && (
          <Button
            onClick={archive(editingCourse, createToast, () => history.replace('/t/classes'))}
          >
            {editingCourse.status === 'active' ? 'Archive' : 'Unarchive'} Class
          </Button>
        )}
      </Flex>
      <Spacer size={2} />
      <FormGroup
        type="text"
        name="name"
        label="Class Name"
        value={editingCourse.name}
        onChange={setEditCourseField('name')}
        disabled={editingCourse.isLms}
        errors={editErrors}
      />
      <Spacer size={2} />
      <FormGroup
        type="select"
        label="Grade"
        name="gradeLevel"
        options={gradeLevels.map((gl) => ({
          value: gl.id,
          label: gl.name,
          disabled: DISABLED_GRADE_LEVELS.has(gl.level),
        }))}
        value={
          editingCourse.gradeLevelId ||
          gradeLevels.find((gl) => DISABLED_GRADE_LEVELS.has(gl.level))?.id
        }
        onChange={setEditCourseField('gradeLevelId')}
        errors={editErrors}
      />
      <Spacer size={2} />
      <FormGroup
        type="multiselect"
        label="Subjects"
        name="subjects"
        options={subjects.map((subject) => ({
          value: subject.id,
          label: subject.name,
        }))}
        value={tempEditingCourseSubjectIds}
        onChange={setField('tempEditingCourseSubjectIds')}
        testTag="subjects"
        errors={editErrors}
      />
      {!editingCourse.isLms && (
        <>
          <FormGroup
            type="text"
            name="courseCode"
            label="Class Code"
            value={editingCourse.courseCode}
            onChange={setEditCourseField('courseCode')}
            errors={editErrors}
          />
          <Spacer />
          <Flex width="100%" justify="end">
            <Button displayType="link" onClick={generateClassCode}>
              Generate New Class Code
            </Button>
          </Flex>
        </>
      )}
    </StandardModal>
  );
};
