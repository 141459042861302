import { useEffect } from 'react';
import { useParams } from 'src/modules/Router';
import { useStore } from 'src/Store';

export const StartPaymentSession = () => {
  const { startPaymentSession } = useStore(({ PlanListData }) => PlanListData, []);
  const { id: planId, paymentInterval } = useParams<{
    id: string;
    paymentInterval: 'annual' | 'month';
  }>();
  useEffect(() => {
    startPaymentSession(planId, paymentInterval)();
  }, [planId, paymentInterval, startPaymentSession]);

  return null;
};
