import { memoize } from 'lodash';
import { ImmutableList } from './Immutable';

type PluralizeOptions = {
  pluralVersion?: string | null;
  noNumber?: boolean;
};
export const pluralize = (
  str: string,
  num: number,
  { pluralVersion = null, noNumber = false }: PluralizeOptions = {},
) => {
  const plural = pluralVersion ?? `${str}s`;
  return `${noNumber ? '' : `${num} `}${num === 1 ? str : plural}`;
};

export const capitalize = (str: string) => {
  return `${str.charAt(0).toUpperCase()}${str.substring(1)}`;
};

export const capitalizeAll = (str: string) => {
  return str.split(' ').map(capitalize).join(' ');
};

export const ellipsify = (str: string, maxLength: number) => {
  if (str.length < maxLength) {
    return str;
  }
  return `${str.substring(0, maxLength)}...`;
};

const alphabet = 'abcdefghijklmnopqrstuvwxyz0123456789';
const permuted = 'kdpqoheawxnjzvurslmgytfbci9251076834';
export const simpleSubstitute = memoize((value: string): string => {
  return ImmutableList(value.toLocaleLowerCase())
    .map((letter) => permuted[alphabet.indexOf(letter)] ?? letter)
    .join('');
});

export const formatPrice = (priceCents: number) => {
  return (priceCents / 100).toLocaleString(undefined, {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
  });
};
