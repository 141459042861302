import { memo } from 'react';
import styled from 'styled-components';
import { borderRadii, boxShadows, colors, paddings } from 'src/styles';
import { NoStylesButton } from 'src/components';

import coverPlaceholderBook from 'src/assets/cover_image_background.svg';

export type Size = 'small' | 'medium' | 'large' | 'extraLarge';

type ThumbnailProps = {
  src?: string;
  testTag?: string;
  to?: string;
  size?: Size;
  title?: string;
  onMouseOver?:
    | React.MouseEventHandler<HTMLButtonElement>
    | React.MouseEventHandler<HTMLAnchorElement>;
  onMouseOut?:
    | React.MouseEventHandler<HTMLButtonElement>
    | React.MouseEventHandler<HTMLAnchorElement>;
};

const sizes = {
  extraLarge: { height: 260, font: '22px', lineHeight: '30px' },
  large: { height: 175, font: '14px', lineHeight: '20px' },
  medium: { height: 115, font: '10px', lineHeight: '12px' },
  small: { height: 75, font: '7px', lineHeight: '8px' },
} as const;

const PlaceholderBackground = styled.div<{ $size: Size }>`
  height: ${({ $size }) => sizes[$size].height}px;
  aspect-ratio: 4/5;
  background-image: url('${coverPlaceholderBook}');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  border-radius: ${borderRadii[2]};
`;

const PlaceholderText = styled.p<{ $size: Size }>`
  /* stylelint-disable  value-no-vendor-prefix -- this is what we need to use line clamping */
  display: -webkit-box;
  /* stylelint-enable */
  position: absolute;
  color: white;
  width: ${({ $size }) => (sizes[$size].height * 4) / 5}px;
  font-size: ${({ $size }) => sizes[$size].font};
  overflow: hidden;

  text-align: center;
  padding: ${({ $size }) => sizes[$size].height / 8}px ${paddings[2]} ${paddings.none}
    ${paddings[2]};
  word-break: break-word;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-height: ${({ $size }) => sizes[$size].lineHeight};
  user-select: none;
`;

const PlaceholderContainer = styled.div`
  position: relative;
  display: flex;
`;

const UnmemoedCoverImagePlaceholder = ({ size, title }: { size: Size; title?: string }) => (
  <PlaceholderContainer data-test-tag="thumbnail-cover-image">
    <PlaceholderBackground $size={size} />
    <PlaceholderText $size={size}>{title || 'Unknown Book Title'}</PlaceholderText>
  </PlaceholderContainer>
);

const CoverImagePlaceholder = memo(UnmemoedCoverImagePlaceholder);

const BaseImage = styled.img<{ $size: Size }>`
  border-radius: ${borderRadii[2]};
  box-shadow: ${boxShadows.standard};
  background-color: ${colors.backgroundLight.hex};
  aspect-ratio: 4/5;
  object-fit: cover;
  height: ${({ $size }) => sizes[$size].height}px;
  min-width: ${({ $size }) => (sizes[$size].height * 4) / 5}px;
`;

export const Thumbnail = ({
  src,
  testTag,
  to,
  size = 'small',
  title,
  onMouseOver,
  onMouseOut,
}: ThumbnailProps) => {
  return (
    <NoStylesButton
      to={to}
      disabled={!to}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
      disabledOpacityOverride="1"
    >
      {src ? (
        <BaseImage src={src} data-test-tag={testTag} $size={size} />
      ) : (
        <CoverImagePlaceholder size={size} title={title} />
      )}
    </NoStylesButton>
  );
};

export const NoButtonThumbnail = ({ src, testTag, title, size = 'small' }: ThumbnailProps) => {
  if (src) {
    return <BaseImage src={src} data-test-tag={testTag} $size={size} />;
  } else {
    return <CoverImagePlaceholder size={size} title={title} />;
  }
};
