import { useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useStore } from 'src/Store';
import { useHistory, useQuery, matchPath, useLocation } from 'src/modules/Router';
import { ReadingStudioLayout } from 'src/layouts';
import { Flex, Subscribe } from 'src/components';
import { ReadingBuilderSideBar } from './ReadingBuilderSideBar';
import { ReadingBuilderTopBar } from './ReadingBuilderTopBar';
import {
  ProcessingStep,
  ContentStep,
  EditPdfTextStep,
  MetadataStep,
  WebSourceUrlModal,
} from './Steps';

export const ReadingBuilder = () => {
  const {
    loading,
    load,
    pdfFileUrl,
    contentType,
    submitForProcessing,
    onPdfProcessingStatus,
    pdfFile,
  } = useStore(
    ({ ReadingBuilderData: rbd }) => ({
      loading: rbd.loading,
      load: rbd.load,
      pdfFileUrl: rbd.pdfFileUrl,
      contentType: rbd.contentType,
      submitForProcessing: rbd.submitForProcessing,
      onPdfProcessingStatus: rbd.onPdfProcessingStatus,
      pdfFile: rbd.pdfFile,
    }),
    [],
  );
  const isPaperUser = useStore((s) => Boolean(s.AppData.currentUser?.paperId), []);
  const history = useHistory();
  const query = useQuery();

  useEffect(() => {
    const assessmentId = query.get('assessmentId') || '';
    const readingId = query.get('readingId') || '';

    load({ assessmentId, readingId, isPaperUser });
  }, [load, query, isPaperUser]);

  const pathname = useLocation().pathname;
  const metadataStep = Boolean(matchPath(pathname, '/t/reading-builder/metadata'));

  return (
    <ReadingStudioLayout
      showTopBarDivider={
        !metadataStep || !['pdf', 'pdf_by_page'].includes(contentType) || !pdfFileUrl
      }
      loading={loading}
      topBar={<ReadingBuilderTopBar />}
      sideBar={<ReadingBuilderSideBar />}
      onSubmit={submitForProcessing(history, query)}
      mainContent={
        <Flex height="100%" width="100%" justify="center">
          <Switch>
            <Route path={['/t/reading-builder/content', '/t/reading-builder']} exact>
              <ContentStep />
            </Route>
            <Route path="/t/reading-builder/metadata" exact>
              <MetadataStep />
            </Route>
            <Route path="/t/reading-builder/processing" exact>
              <ProcessingStep />
            </Route>
            <Route path="/t/reading-builder/edit-pdf-text" exact>
              <EditPdfTextStep />
            </Route>
          </Switch>
          <WebSourceUrlModal />
          {pdfFile && (
            <Subscribe
              channel="StoredFileChannel"
              id={pdfFile.id}
              onMessage={onPdfProcessingStatus}
            />
          )}
        </Flex>
      }
    />
  );
};
