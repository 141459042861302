type MediaType = 'audio' | 'video';

const VIDEO_TYPES = ['webm', 'ogg', 'mp4', 'x-matroska'] as const;
const AUDIO_TYPES = ['webm', 'ogg', 'mp3', 'mp4', 'x-matroska'] as const;
const CODECS = [
  'vp9',
  'vp9.0',
  'vp8',
  'vp8.0',
  'avc1',
  'av1',
  'h265',
  'h.265',
  'h264',
  'h.264',
  'opus',
  'pcm',
  'aac',
  'mpeg',
  'mp4a',
] as const;

type ContentType = (typeof AUDIO_TYPES)[number] | (typeof VIDEO_TYPES)[number];
type Codec = (typeof CODECS)[number];

export type MimeTypeDetails = {
  mediaType: MediaType;
  contentType: ContentType;
  codec: Codec | null;
  mimeType:
    | `${MediaType}/${ContentType}`
    | `${MediaType}/${ContentType};codecs=${Codec}`
    | `${MediaType}/${ContentType};codecs=${Uppercase<Codec>}`
    | `${MediaType}/${ContentType};codecs:${Codec}`
    | `${MediaType}/${ContentType};codecs:${Uppercase<Codec>}`;
};

export const getSupportedMimeTypes = (mediaType: MediaType): MimeTypeDetails[] => {
  const contentTypes = mediaType === 'video' ? VIDEO_TYPES : AUDIO_TYPES;

  return contentTypes.flatMap((contentType) => {
    const basicMimeType: MimeTypeDetails = {
      mediaType,
      contentType,
      codec: null,
      mimeType: `${mediaType}/${contentType}`,
    };
    const mimeTypeDetails = [
      basicMimeType,
      ...CODECS.flatMap((codec) => {
        const baseWithCodec: MimeTypeDetails = {
          mediaType,
          contentType,
          codec,
          mimeType: `${mediaType}/${contentType}`,
        };
        const mimeType1: MimeTypeDetails['mimeType'] = `${mediaType}/${contentType};codecs=${codec}`;
        const mimeType2: MimeTypeDetails['mimeType'] = `${mediaType}/${contentType};codecs:${codec}`;
        const mimeType3: MimeTypeDetails['mimeType'] = `${mediaType}/${contentType};codecs=${
          codec.toUpperCase() as Uppercase<Codec>
        }`;
        const mimeType4: MimeTypeDetails['mimeType'] = `${mediaType}/${contentType};codecs:${
          codec.toUpperCase() as Uppercase<Codec>
        }`;
        return [
          { ...baseWithCodec, mimeType: mimeType1 },
          { ...baseWithCodec, mimeType: mimeType2 },
          { ...baseWithCodec, mimeType: mimeType3 },
          { ...baseWithCodec, mimeType: mimeType4 },
        ];
      }),
    ];
    return mimeTypeDetails.filter((mt) => MediaRecorder.isTypeSupported(mt.mimeType));
  });
};

export class MimeTypeError extends Error {
  supportedMimeTypes: MimeTypeDetails[];

  constructor(message: string, supportedMimeTypes: MimeTypeDetails[]) {
    super(message);
    this.supportedMimeTypes = supportedMimeTypes;
  }
}
