import styled, { css, StyledComponent } from 'styled-components';
import {
  Color,
  colors,
  DollarPrefix,
  RequiredDollarPrefix,
  fontWeights,
  WhiteSpace,
  FontStyle,
  FontWeight,
  TextDisplayTypes,
  WordBreak,
  TextAlign,
  TextTransform,
  Width,
  Breakpoints,
  breakpoints,
  Height,
  cssIfTruthyOrZero,
  cssIfTruthy,
  appFontSize,
} from 'src/styles';
import { Overflow } from 'src/components';

const ellipsisCss = css`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const commonCss = css<StyleProps>`
  ${({ $minWidth, $maxWidth, $minHeight, $maxHeight, $overflowY, $padding }) => `
    ${cssIfTruthyOrZero('min-width', $minWidth)}
    ${cssIfTruthyOrZero('max-width', $maxWidth)}
    ${cssIfTruthyOrZero('min-height', $minHeight)}
    ${cssIfTruthyOrZero('max-height', $maxHeight)}
    ${cssIfTruthy('overflow-y', $overflowY)}
    ${cssIfTruthy('padding', $padding)}
  `}

  letter-spacing: 0;
  text-decoration: none;
  margin: 0;
  font-style: ${({ $fontStyle }) => $fontStyle};
  text-align: ${({ $textAlign }) => $textAlign};
  white-space: ${({ $whiteSpace }) => $whiteSpace};
  display: ${({ $display }) => $display};
  text-transform: ${({ $textTransform }) => $textTransform};
  word-break: ${({ $wordBreak }) => $wordBreak};
  color: ${({ $color, $blurrify }) => {
    if ($blurrify) {
      return 'transparent';
    } else if ($color) {
      return colors[$color].hex;
    } else {
      return 'inherit';
    }
  }};

  :hover {
    color: ${({ $color, $blurrify, $hoverColor }) => {
      if ($blurrify) {
        return 'transparent';
      } else if ($hoverColor) {
        return colors[$hoverColor].hex;
      } else if ($color) {
        return colors[$color].hex;
      } else {
        return 'inherit';
      }
    }};
  }

  ${({ $ellipsis }) => $ellipsis && ellipsisCss}
  ${({ $ellipsisAtOrBelow }) =>
    $ellipsisAtOrBelow &&
    css`
      @media (max-width: ${breakpoints[$ellipsisAtOrBelow]}px) {
        ${ellipsisCss}
      }
    `}

  ${({ $blurrify, $color }) =>
    $blurrify &&
    css`
      text-shadow: 0 0 7px ${$color ? colors[$color].hex : 'inherit'};
      user-select: none;
    `}


  ${({ $flexGrow }) => cssIfTruthyOrZero('flex-grow', $flexGrow)}
`;

const overrideCss = css<StyleProps>`
  ${({ $sizeOverride, $weightOverride, $lineHeightOverride }) => `
    ${cssIfTruthyOrZero('font-size', `${$sizeOverride}px`)}
    ${cssIfTruthyOrZero('font-weight', $weightOverride ? fontWeights[$weightOverride] : undefined)}
    ${cssIfTruthyOrZero('line-height', $lineHeightOverride)}
  `}
`;

export const TEXT_VARIANTS = {
  h1: styled.h1<StyleProps>`
    ${commonCss}
    font-weight: 700;
    font-size: 30px;
    line-height: 130%;
    ${overrideCss}
  `,
  h2: styled.h2<StyleProps>`
    ${commonCss}
    font-weight: 700;
    font-size: 26px;
    line-height: 120%;
    ${overrideCss}
  `,
  h3: styled.h3<StyleProps>`
    ${commonCss}
    font-weight: 700;
    font-size: 20px;
    line-height: 140%;
    ${overrideCss}
  `,
  h4: styled.h4<StyleProps>`
    ${commonCss}
    font-weight: 600;
    font-size: 18px;
    line-height: 140%;
    ${overrideCss}
  `,
  h5: styled.h5<StyleProps>`
    ${commonCss}
    font-weight: 600;
    font-size: 16px;
    line-height: 140%;
    ${overrideCss}
  `,
  h6: styled.h6<StyleProps>`
    ${commonCss}
    font-weight: 600;
    font-size: ${appFontSize}px;
    line-height: 145%;
    ${overrideCss}
  `,
  h7: styled.p<StyleProps>`
    ${commonCss}
    font-weight: 600;
    font-size: 12px;
    line-height: 120%;
    ${overrideCss}
  `,
  largeText: styled.p<StyleProps>`
    ${commonCss}
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    ${overrideCss}
  `,
  normalText: styled.p<StyleProps>`
    ${commonCss}
    font-weight: 500;
    font-size: ${appFontSize}px;
    line-height: 140%;
    overflow-wrap: break-word;
    ${overrideCss}
  `,
  ins: styled.ins<StyleProps>`
    ${commonCss}
    font-weight: 500;
    font-size: ${appFontSize}px;
    line-height: 140%;
    text-decoration: none;
    ${overrideCss}
  `,
  del: styled.del<StyleProps>`
    ${commonCss}
    font-weight: 500;
    font-size: ${appFontSize}px;
    line-height: 140%;
    ${overrideCss}
  `,
} as const;

const defaultTextColor = (
  variant: keyof typeof TEXT_VARIANTS,
  color: Color | undefined,
  blurrify: boolean,
): Color | undefined => {
  if (color) return color;

  if (variant === 'ins') {
    return 'readleeBlue';
  } else if (variant === 'del') {
    return 'danger';
  } else if (blurrify) {
    return color || 'black';
  } else {
    return color;
  }
};

type ChildProps = {
  dangerouslySetInnerHTML?: { __html: string };
  children?: React.ReactNode;
};

type PassThroughProps = ChildProps & {
  title?: string;
};

type PropsToRequirePrefix = {
  fontStyle?: FontStyle;
  sizeOverride?: number | false;
  weightOverride?: FontWeight | false;
  lineHeightOverride?: string | false;
  whiteSpace?: WhiteSpace;
  display?: TextDisplayTypes;
  textAlign?: TextAlign;
  minHeight?: Height;
  maxHeight?: Height;
  minWidth?: Width;
  maxWidth?: Width;
  textTransform?: TextTransform;
  ellipsis?: boolean;
  blurrify?: boolean;
  wordBreak?: WordBreak;
};

type PropsToPrefix = {
  overflowY?: Overflow;
  color?: Color;
  hoverColor?: Color;
  ellipsisAtOrBelow?: Breakpoints;
  flexGrow?: number;
  padding?: string;
};

type StyleProps = RequiredDollarPrefix<PropsToRequirePrefix> &
  DollarPrefix<PropsToPrefix> &
  PassThroughProps;

export type TextProps = PassThroughProps &
  PropsToRequirePrefix &
  PropsToPrefix & {
    variant?: keyof typeof TEXT_VARIANTS;
    testTag?: string;
    ellipsifiedTitle?: string;
  };

export const Text = ({
  variant = 'normalText',
  children,
  color,
  hoverColor,
  whiteSpace = 'normal',
  fontStyle = 'normal',
  display = 'block',
  textAlign = 'inherit',
  sizeOverride = false,
  weightOverride = false,
  lineHeightOverride = false,
  minWidth = 'initial',
  maxWidth = 'initial',
  minHeight = 'initial',
  maxHeight = 'initial',
  textTransform = 'none',
  wordBreak = 'normal',
  testTag,
  ellipsis = false,
  blurrify = false,
  title,
  ellipsifiedTitle = title,
  ellipsisAtOrBelow,
  dangerouslySetInnerHTML,
  overflowY,
  flexGrow,
  padding,
}: TextProps) => {
  const Elem: StyledComponent<
    'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'ins' | 'del',
    object,
    StyleProps,
    never
  > = TEXT_VARIANTS[variant] as any; // eslint-disable-line @typescript-eslint/no-explicit-any

  return (
    <Elem
      $color={defaultTextColor(variant, color, blurrify)}
      $hoverColor={hoverColor}
      $sizeOverride={sizeOverride}
      $weightOverride={weightOverride}
      $lineHeightOverride={lineHeightOverride}
      $whiteSpace={whiteSpace}
      $fontStyle={fontStyle}
      $display={display}
      $textAlign={textAlign}
      $minWidth={minWidth}
      $maxWidth={maxWidth}
      $minHeight={minHeight}
      $overflowY={overflowY}
      $maxHeight={maxHeight}
      $textTransform={textTransform}
      $wordBreak={wordBreak}
      $ellipsis={ellipsis}
      $ellipsisAtOrBelow={ellipsisAtOrBelow}
      title={ellipsis ? ellipsifiedTitle : title}
      $blurrify={blurrify}
      data-test-tag={testTag}
      data-blurred={blurrify || undefined}
      dangerouslySetInnerHTML={dangerouslySetInnerHTML}
      $flexGrow={flexGrow}
      $padding={padding}
    >
      {children}
    </Elem>
  );
};
