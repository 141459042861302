import React, { Fragment, useEffect } from 'react';
import styled from 'styled-components';
import {
  Spacer,
  Flex,
  Button,
  NoStylesButton,
  useToaster,
  StudentAvatar,
  Loading,
  Icon,
  Text,
  Tooltip,
  ErrorText,
  LinkButton,
} from 'src/components';
import { colors, margins, useBreakpoints, paddings, cssIfTrue, borderRadii } from 'src/styles';
import { useStore } from 'src/Store';

import { avatarAttributeList, getAttribute } from 'src/models/Avatar';
import { ConfirmPurchaseModal } from './ConfirmPurchaseModal';
import { avatarFeatureToUserField, StoreItem } from 'src/models';

type AvatarBuilderProps = {
  ContentElement: React.ComponentType<{
    headerText: string;
    children: React.ReactNode;
    loading: boolean;
  }>;
};

const ElementSelector = styled.div<{
  $showMobile: boolean;
  $active?: boolean;
}>`
  position: relative;
  display: flex;
  justify-content: start;
  align-items: end;
  height: ${({ $showMobile }) => ($showMobile ? '75px' : '100px')};
  width: ${({ $showMobile }) => ($showMobile ? '75px' : '100px')};

  margin: 0 ${margins[2]} ${margins[2]} ${margins[2]};
  border: 1px solid ${colors.black.hex};
  ${({ $active = false }) => cssIfTrue('border', `3px solid ${colors.black.hex}`, $active)};

  &:hover,
  &:active {
    border: 3px solid ${colors.black.hex};
  }
`;

const SaveButtonContainer = styled.div<{ $showMobile: boolean }>`
  display: flex;

  ${({ $showMobile }) =>
    $showMobile &&
    `
      background-color: ${colors.backgroundLight.hex};
      width: 100%;
      position: absolute;
      bottom: 0;
  `};
`;

const PriceTag = styled.div`
  display: flex;
  align-items: center;
  background: white;
  color: black;
  position: absolute;
  border-right: 1px solid black;
  border-top: 1px solid black;
  border-radius: 0 ${borderRadii[2]} 0 0;
  padding: ${paddings.none} ${paddings[1]};
`;

const NewTag = styled.div`
  display: flex;
  align-items: center;
  background: ${colors.danger.hex};
  color: white;
  position: absolute;
  border-bottom: 1px solid black;
  border-left: 1px solid black;
  border-radius: 0 0 0 ${borderRadii[2]};
  padding: ${paddings.none} ${paddings[1]};
  top: 0;
  right: 0;
`;

export const AvatarBuilder = ({ ContentElement }: AvatarBuilderProps) => {
  const {
    customizingSelection,
    dirty,
    saving,
    errors,
    localUser,
    purchasedItems,
    itemsToBuy,
    itemToBuy,
    setField,
    load,
    save,
    setItem,
    undo,
    rollDice,
    cancelPurchase,
    currentUser,
  } = useStore(
    (state) => ({
      customizingSelection: state.AvatarBuilderData.customizingSelection,
      dirty: state.AvatarBuilderData.dirty,
      saving: state.AvatarBuilderData.saving,
      errors: state.AvatarBuilderData.errors,
      localUser: state.AvatarBuilderData.localUser,
      purchasedItems: state.AvatarBuilderData.purchasedItems,
      itemsToBuy: state.AvatarBuilderData.itemsToBuy,
      itemToBuy: state.AvatarBuilderData.itemToBuy,
      setField: state.AvatarBuilderData.setField,
      load: state.AvatarBuilderData.load,
      save: state.AvatarBuilderData.save,
      setItem: state.AvatarBuilderData.setItem,
      undo: state.AvatarBuilderData.undo,
      rollDice: state.AvatarBuilderData.rollDice,
      cancelPurchase: state.AvatarBuilderData.cancelPurchase,
      currentUser: state.AppData.currentUser,
    }),
    [],
  );

  const { createToast } = useToaster();
  const showMobile = useBreakpoints({ smallerThanOrEqualTo: 'tabletSmall' });

  useEffect(() => {
    load();
  }, [load]);

  if (!localUser || !currentUser) return <Loading flex kind="boat" />;
  const notEnoughPoints = itemToBuy ? currentUser.pointBalance < itemToBuy.price : false;
  const fieldName = avatarFeatureToUserField(customizingSelection);
  const filteredPurchasedItems = purchasedItems.filter((i) => i.itemType === customizingSelection);
  const filteredItemsToBuy = itemsToBuy
    .filter((item) => item.itemType === customizingSelection)
    .sortBy((item) => item.price);

  const FloatingBuyButton = () =>
    itemToBuy && !showMobile ? (
      <>
        <Spacer horizontal size={20} />
        <Flex direction="column">
          <Flex align="center">
            <Text variant="h3">Cost: {itemToBuy.price}</Text>
            <Spacer horizontal size={2} />
            <Icon icon="token" size="1.75em" color="white" />
          </Flex>
          <Spacer size={2} />

          <Tooltip disabled={!notEnoughPoints} content="Keep reading to earn more points!">
            <Button
              disabled={notEnoughPoints}
              loading={saving}
              onClick={() => setField('confirmModalOpen')(true)}
              width="100%"
            >
              <Text variant="h3">Buy</Text>
            </Button>
          </Tooltip>
        </Flex>
      </>
    ) : null;

  const StoreOption = ({ item, purchased }: { item: StoreItem; purchased: boolean }) => {
    return (
      <NoStylesButton onClick={setItem(item, fieldName)} testTag={item.name}>
        <ElementSelector $active={localUser[fieldName] === item.name} $showMobile={showMobile}>
          {!purchased && (
            <>
              <PriceTag>
                {item.price}
                <Spacer horizontal size="half" />
                <Icon icon="token" size="1.25em" color="white" />
              </PriceTag>
              {item.isNew && (
                <NewTag data-test-tag={`${item.id}-is-new`}>
                  <Text variant="h7" padding={paddings.half}>
                    New!
                  </Text>
                </NewTag>
              )}
            </>
          )}
          <img src={getAttribute(item.itemType, item.name)} />
        </ElementSelector>
      </NoStylesButton>
    );
  };

  return (
    <>
      <Flex direction="column" align="start">
        <Spacer />
        <LinkButton
          paddingOverride={
            showMobile ? `${paddings[5]} 0 0 ${paddings[5]}` : `${paddings[5]} 0 0 ${paddings[10]}`
          }
          color="black"
          to="/s/"
          icon="backArrow"
        >
          Back
        </LinkButton>
      </Flex>
      <ContentElement headerText="Avatar" loading={false}>
        <Flex direction="column" align={showMobile ? 'center' : 'start'} overflowY="auto">
          <Flex align="center">
            <StudentAvatar user={localUser} kind="builder" testTag="builder-avatar" />
            <FloatingBuyButton />
          </Flex>
          <Spacer size={8} />
          <Button onClick={rollDice} icon="dice" iconFill>
            Roll the dice!
          </Button>
          <Spacer size={8} />

          <Flex align="start">
            <Tooltip
              content="Buy the selected item or click cancel to continue"
              disabled={Boolean(!itemToBuy)}
            >
              <Flex direction="column">
                {avatarAttributeList.map(({ kind, buttonName }) => (
                  <Fragment key={kind}>
                    <Button
                      displayType={customizingSelection === kind ? 'button' : 'outline'}
                      onClick={() => setField('customizingSelection')(kind)}
                      testTag={`customize-${kind}`}
                      disabled={Boolean(itemToBuy)}
                    >
                      {buttonName}
                    </Button>
                    <Spacer size={2} />
                  </Fragment>
                ))}
              </Flex>
            </Tooltip>
            <Spacer horizontal size={4} />

            <Flex wrap="wrap">
              {filteredPurchasedItems.map((item) => (
                <StoreOption key={item.id} item={item} purchased />
              ))}
              {filteredItemsToBuy.map((item) => (
                <StoreOption key={item.id} item={item} purchased={false} />
              ))}
            </Flex>
          </Flex>
          <Spacer size={20} />
          <ErrorText errorKey="transaction" errors={errors} />
          <ErrorText errorKey="user" errors={errors} />
          <SaveButtonContainer $showMobile={showMobile}>
            <Button
              disabled={!dirty}
              loading={saving}
              displayType="outline"
              onClick={itemToBuy ? cancelPurchase : undo}
              borderRadiusOverride={showMobile && 'none'}
              paddingOverride={showMobile ? paddings[5] : ''}
              width={showMobile ? '40%' : 'auto'}
            >
              {itemToBuy ? 'Cancel' : 'Undo Changes'}
            </Button>
            {!showMobile && <Spacer horizontal size={4} />}

            {itemToBuy ? (
              <Tooltip
                disabled={!notEnoughPoints}
                content="Keep reading to earn more points!"
                containerWidthOverride="100%"
              >
                <Button
                  disabled={notEnoughPoints}
                  loading={saving}
                  onClick={() => setField('confirmModalOpen')(true)}
                  borderRadiusOverride={showMobile && 'none'}
                  paddingOverride={showMobile ? paddings[5] : ''}
                  width={showMobile ? '100%' : 'auto'}
                  height="100%"
                >
                  Buy for {itemToBuy.price} <Spacer horizontal />{' '}
                  <Icon icon="token" size="1.25em" />
                </Button>
              </Tooltip>
            ) : (
              <Button
                disabled={!dirty}
                loading={saving}
                onClick={() => save(createToast)}
                borderRadiusOverride={showMobile && 'none'}
                paddingOverride={showMobile ? paddings[5] : ''}
                width={showMobile ? '60%' : 'auto'}
              >
                Save Changes
              </Button>
            )}
          </SaveButtonContainer>
          <Spacer size={4} />
        </Flex>
        <ConfirmPurchaseModal />
      </ContentElement>
    </>
  );
};
