import { Fragment, memo } from 'react';
import { useStore } from 'src/Store';
import { Course } from 'src/models/Course';
import { Assessment } from 'src/models/Assessment';
import { ImmutableList, ImmutableMap } from 'src/modules/Immutable';
import { Spacer, Flex, ClassAvatar, Button, Icon, Tag, Text, ButtonEvent } from 'src/components';
import { classColors, basicColors, paddings, useBreakpoints } from 'src/styles';
import { AssignmentCardProps, AssignmentCard } from './AssignmentCard';
import { DropdownMenu } from '../DropdownMenu';
import { moderatorOrGreater } from 'src/models';

type AddedProps = {
  assessment: Assessment;
  courseIds: ImmutableList<string>;
  courseMap: ImmutableMap<string, Course>;
  canAssign: boolean;
  assignToPath: string;
  hideViewDetails?: boolean;
  hideShare?: boolean;
  readingName?: string;
};

type TeacherAssignmentCardProps = AddedProps &
  Pick<AssignmentCardProps, 'to' | 'coverImg' | 'open' | 'onToggleCardOpen' | 'metrics'>;

const AssignedCourses = ({
  courseIds,
  assessment,
  courseMap,
}: Pick<AddedProps, 'courseIds' | 'assessment' | 'courseMap'>) => {
  const visible = courseIds.slice(0, 3);
  const overflow = courseIds.slice(3);

  return (
    <Flex align="center">
      {visible
        .map((id) => {
          const course = courseMap.get(id);
          if (!course) return null;
          return (
            <Fragment key={id}>
              <ClassAvatar
                name={course.name}
                backgroundColor={course.avatarColor || classColors[0]}
                testTag={`assigned-course-${course.id}`}
              />
              <Spacer horizontal />
            </Fragment>
          );
        })
        .push(
          overflow.isEmpty() ? null : (
            <ClassAvatar
              key={`${assessment.id}-overflow-avatar`}
              name={`+${overflow.size}`}
              nameList={overflow.map((id) => courseMap.get(id, { name: '' }).name)}
              backgroundColor={basicColors.black}
              overflow
            />
          ),
        )
        .filter(Boolean)}
    </Flex>
  );
};

const UnmemoizedTeacherAssignmentCard = ({
  assessment,
  courseIds,
  courseMap,
  canAssign,
  assignToPath,
  hideViewDetails,
  hideShare = false,
  ...baseAssignmentCardProps
}: TeacherAssignmentCardProps) => {
  const {
    startAssignmentUpgrade,
    openStatusUpdateModal,
    openDuplicateModal,
    openShareModal,
    currentPlan,
    currentUser,
    setField,
  } = useStore(
    (s) => ({
      startAssignmentUpgrade: s.UpgradeAssignmentModalData.startAssignmentUpgrade,
      openStatusUpdateModal: s.UpdateAssignmentStatusModalData.openStatusUpdateModal,
      openDuplicateModal: s.DuplicateModalData.openDuplicateModal,
      openShareModal: s.ShareModalData.openShareModal,
      currentUser: s.AppData.currentUser,
      currentPlan: s.AppData.currentPlan,
      setField: s.AppData.setField,
    }),
    [],
  );

  const showUltimate = currentPlan.isUsageCapsEnabled && assessment.isUltimate;
  const showMobile = useBreakpoints({ smallerThanOrEqualTo: 'tabletSmall' });
  const moderatorPlus = moderatorOrGreater(currentUser?.accountType || 'student');

  const shouldSeeUseUpgradeButton = () => {
    if (assessment.isUltimate) return false;
    if (moderatorPlus) return true;
    if (!currentPlan.isUsageCapsEnabled) return false;

    return true;
  };

  return (
    <AssignmentCard
      {...baseAssignmentCardProps}
      testTag={assessment.id}
      showMobile={showMobile}
      title={assessment.name}
      ultimate={showUltimate}
      subTitle={
        <Flex align="stretch" height="32px" direction="row" justify="center">
          {moderatorPlus && assessment.isSample && (
            <Tag color="danger">
              <Text variant="h3">SAMPLE ASSIGNMENT</Text>
            </Tag>
          )}
          {canAssign && (
            <>
              <Button
                to={assignToPath}
                displayType="outline"
                color="primaryBlue"
                paddingOverride={paddings.none}
                testTag={`${assessment.id}-assign-to-button`}
                onClick={(e: ButtonEvent) => e.stopPropagation()}
              >
                <Flex padding={paddings[2]} align="center">
                  <Icon icon="plus" size="0.75em" />
                  <Spacer horizontal />
                  Assign
                </Flex>
              </Button>
              <Spacer horizontal size={2} />
            </>
          )}
          <AssignedCourses courseMap={courseMap} assessment={assessment} courseIds={courseIds} />
        </Flex>
      }
    >
      <Flex justify="end" align="end" grow={1} padding={`0 ${paddings[4]} 0 0`}>
        <DropdownMenu
          displayType="outline"
          options={[
            ...(shouldSeeUseUpgradeButton()
              ? [
                  {
                    children: 'Upgrade Assignment',
                    onClick:
                      currentUser?.ultimateAssessmentsAvailable === 0 && !moderatorPlus
                        ? () => setField('ultimateAssessmentsOverviewOpened')(true)
                        : () => startAssignmentUpgrade(assessment.id, 'assignmentCard'),
                    color: 'primaryBlue' as const,
                  },
                ]
              : []),
            ...(hideViewDetails
              ? []
              : [
                  {
                    children: 'View Details',
                    to: baseAssignmentCardProps.to,
                  },
                ]),
            {
              children: 'Assign',
              to: assignToPath,
            },
            {
              children: 'Edit',
              to: `/t/assignment-builder/${assessment.id}`,
            },
            ...(hideShare
              ? []
              : [
                  {
                    children: 'Share',
                    onClick: () => openShareModal(assessment.id),
                  },
                ]),
            {
              children: 'Duplicate',
              onClick: () => openDuplicateModal(assessment.id),
            },

            {
              children: `${assessment.status === 'archived' ? 'Una' : 'A'}rchive`,
              onClick: () => openStatusUpdateModal(assessment, 'toggleArchive'),
            },
            {
              children: 'Delete',
              onClick: () => openStatusUpdateModal(assessment, 'delete'),
              color: 'danger',
            },
          ]}
        >
          Actions
        </DropdownMenu>
      </Flex>
    </AssignmentCard>
  );
};

export const TeacherAssignmentCard = memo(UnmemoizedTeacherAssignmentCard);
