import { useMemo } from 'react';
import { useStore } from 'src/Store';
import { basicColors } from 'src/styles';
import { ImmutableList } from 'src/modules/Immutable';
import { GraphContainer, GraphLabels, ChartLabel } from './StudentProgressChartComponents';
import {
  useDateFilter,
  labels,
  isBaseTooltipData,
  objHasPropOfType,
  getGraphData,
  externalTooltipFunc,
  useGraphOrigin,
  mapToDataArray,
  useSubmissionResponses,
  aggregateAssignments,
} from './StudentProgressChartHelpers';
import { TooltipSubmissionData } from './StudentProgressMonitoringState';
import { StudentProgressMonitoringChart } from './StudentProgressMonitoringChart';

const isTooltipSubmissionData = (obj: unknown): obj is TooltipSubmissionData =>
  isBaseTooltipData(obj) && objHasPropOfType(obj, 'wpm', 'number');

export const StudentWpmGraph = () => {
  const { assessmentMap, setWcpmTooltipProps, setWcpmTooltipData } = useStore(
    ({ StudentProgressMonitoringData: s }) => ({
      assessmentMap: s.assessmentMap,
      setWcpmTooltipProps: s.setWcpmTooltipProps,
      setWcpmTooltipData: s.setWcpmTooltipData,
    }),
    [],
  );
  const { dateOption } = useDateFilter();
  const submissionResponses = useSubmissionResponses();

  const wpmData = useMemo(
    () =>
      submissionResponses.map((group) => ({
        wpm: Math.round(group.reduce((acc, { submission: s }) => acc + s.wpm, 0) / group.count()),
        assignments: aggregateAssignments(group, assessmentMap, (sr) => ({
          wpm: Math.round(sr.submission.wpm),
        })),
      })),
    [submissionResponses, assessmentMap],
  );
  const origin = useGraphOrigin({ excludeIndependent: false });
  const graphData = useMemo(() => getGraphData(wpmData, dateOption), [wpmData, dateOption]);

  const data = useMemo(
    () => ({
      labels: labels(origin),
      datasets: [
        {
          label: '(WPM)',
          id: 4,
          data: mapToDataArray(graphData.map((el) => el.wpm)),
          borderColor: basicColors.readleeBlue,
          backgroundColor: basicColors.readleeBlue,
          fill: false,
        },
      ],
    }),
    [origin, graphData],
  );

  const maxValue = useMemo(() => (graphData.maxBy((a) => a.wpm)?.wpm ?? 0) + 10, [graphData]);

  const tooltipHandler = useMemo(
    () =>
      externalTooltipFunc(setWcpmTooltipProps, (raw) => {
        const items =
          graphData
            .get(raw.x)
            ?.assignments.filter(isTooltipSubmissionData)
            .map((el) => ({ ...el, value: el.wpm, stat: 'wpm' as const })) ??
          ImmutableList<TooltipSubmissionData>();
        setWcpmTooltipData(items);
      }),
    [graphData, setWcpmTooltipProps, setWcpmTooltipData],
  );

  return (
    <GraphContainer>
      <GraphLabels
        label={
          <ChartLabel
            label="WPM"
            tooltip="Words Per Minute (WPM, Total Words Said / Total Time Read) is shown for all assessments."
            testTag="wpm"
          />
        }
        stat="wpm"
      />
      <StudentProgressMonitoringChart
        datasetIdKey="wpm"
        data={data}
        maxValue={maxValue}
        tooltipPropsHandler={tooltipHandler}
      />
    </GraphContainer>
  );
};
