import styled from 'styled-components';
import { colors, DISABLED_OPACITY, RequiredDollarPrefix } from 'src/styles';
import { Button } from '../Button';

type ToggleProps = {
  id?: string;
  value: boolean;
  disabled?: boolean;
  onChange: (value: boolean) => void;
  name?: string;
};
type ToggleInternalProps = RequiredDollarPrefix<Omit<ToggleProps, 'onChange' | 'id' | 'name'>>;

const ToggleContainer = styled.div<ToggleInternalProps>`
  box-sizing: border-box;
  width: 40px;
  height: 22px;
  padding: 2px;
  border-radius: 11px;
  background-color: ${({ $value }) => ($value ? colors.primaryBlue.hex : colors.gray7.hex)};
  opacity: ${({ $disabled }) => ($disabled ? DISABLED_OPACITY : '1')};
  cursor: pointer;
  transition: background-color 0.5s;
`;
const ToggleSwitch = styled.div<ToggleInternalProps>`
  width: 18px;
  height: 18px;
  border-radius: 100%;
  background-color: ${colors.white.hex};
  margin-left: ${({ $value }) => ($value ? '50' : '0')}%;
  transition: margin-left 0.25s;
`;

export const Toggle = ({ id, name, value, disabled = false, onChange }: ToggleProps) => {
  const onClick = () => {
    if (!disabled) {
      onChange(!value);
    }
  };
  return (
    <Button id={id} displayType="noStyles" name={name} onClick={onClick}>
      <ToggleContainer $value={value} $disabled={disabled} data-test-tag="toggle-container">
        <ToggleSwitch $value={value} $disabled={disabled} />
      </ToggleContainer>
    </Button>
  );
};
