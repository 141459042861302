import { subDataCreator } from 'src/State';
import { defaultState } from './ShareModalState';
import { ErrorMapResponse } from 'src/modules/Api';
import { ImmutableMap } from 'src/modules/Immutable';

type ShareResponse = {
  success: boolean;
  errors: ErrorMapResponse;
};

export const createDefaultShareModalData = subDataCreator(
  'ShareModalData',
  defaultState,
  ({ set, setField, get, fetchJson }) => ({
    setField,
    openShareModal: (assessmentId) => set({ assessmentId, opened: true }),
    submitShare: (createToast) => () => {
      const { sharingEmail, assessmentId } = get();
      set({ submitting: true });
      fetchJson(`/api/assessments/${assessmentId}/share`, {
        method: 'POST',
        data: { email: sharingEmail },
        onSuccess: (data: ShareResponse) => {
          set({ submitting: false, sharingErrors: ImmutableMap(data.errors) });
          if (data.success) {
            set({ sharingEmail: '', opened: false });
            createToast({ children: 'Assignment has been shared!' });
          }
        },
      });
    },
  }),
);
