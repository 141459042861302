import { useEffect } from 'react';
import { useHistory, useParams } from 'src/modules/Router';
import { Text, Form, FormGroup, Button } from 'src/components';
import { SimpleLayout } from 'src/layouts';
import { useStore } from 'src/Store';

export const ResetPassword = () => {
  const { id, token } = useParams<{ id: string; token: string }>();
  const { user, setUserField, submit, errors, load, loading } = useStore(
    (state) => state.ResetPasswordData,
    [],
  );
  const history = useHistory();
  useEffect(() => load({ id, token }), [id, token, load]);
  return (
    <SimpleLayout loading={loading}>
      <Text variant="h1">Reset Password</Text>
      <Form onSubmit={submit(history)}>
        <FormGroup
          name="newPassword"
          label="New Password"
          errors={errors}
          type="password"
          value={user.newPassword}
          onChange={setUserField('newPassword')}
        />
        <FormGroup
          name="passwordConfirmation"
          label="Confirm Password"
          errors={errors}
          type="password"
          value={user.passwordConfirmation}
          onChange={setUserField('passwordConfirmation')}
        />
        <Button type="submit">Reset Password</Button>
      </Form>
    </SimpleLayout>
  );
};
