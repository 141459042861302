import { useMemo } from 'react';
import { ReadingTaskDetails, TaskDetails } from 'src/models';
import { VocabTaskDetails } from 'src/models/VocabTask';
import { ImmutableList } from 'src/modules/Immutable';
import { useQuery } from 'src/modules/Router';
import { useStore } from 'src/Store';

export const useReadingTaskDetails = (): ReadingTaskDetails | null => {
  const { assessment, taskDetailsMap } = useStore(
    ({ AssignmentBuilderData: s }) => ({
      assessment: s.assessment,
      taskDetailsMap: s.taskDetailsMap,
    }),
    [],
  );

  if (!assessment) return null;

  const readingTaskData = assessment.taskOrder.find(
    ({ taskId, type }) => type === 'ReadingTask' && taskDetailsMap.has(taskId),
  );
  if (!readingTaskData) return null;
  const readingTaskId = readingTaskData.taskId;
  const readingTaskDetails = taskDetailsMap.get(readingTaskId);
  if (!readingTaskDetails || readingTaskDetails.type !== 'ReadingTask') return null;

  return readingTaskDetails;
};

export const useCurrentTaskDetails = (): TaskDetails | null => {
  const { taskDetailsMap } = useStore(
    ({ AssignmentBuilderData: s }) => ({ taskDetailsMap: s.taskDetailsMap }),
    [],
  );
  const query = useQuery();

  const currentTaskId = query.get('currentTaskId', null);
  const currentTaskDetails = currentTaskId ? taskDetailsMap.get(currentTaskId, null) : null;

  return currentTaskDetails;
};

export const useVocabTaskDetailsList = (): ImmutableList<VocabTaskDetails> => {
  const { taskDetailsMap, assessment } = useStore(
    ({ AssignmentBuilderData: s }) => ({
      taskDetailsMap: s.taskDetailsMap,
      assessment: s.assessment,
    }),
    [],
  );

  const vocabTaskDetailsList = useMemo(() => {
    return (assessment?.taskOrder || ImmutableList())
      .map((ti) => taskDetailsMap.get(ti.taskId))
      .filter((t) => t && t.type === 'VocabTask')
      .toList() as ImmutableList<VocabTaskDetails>;
  }, [taskDetailsMap, assessment?.taskOrder]);

  return vocabTaskDetailsList;
};
