import { useStore } from 'src/Store';
import { formatDateTime } from 'src/modules/Time';
import { TooltipSubmissionData } from './StudentProgressMonitoringState';
import {
  ChartTooltip,
  getRangeText,
  seasonEndDates,
  TooltipContentContainer,
  TooltipSubmissionLink,
} from './StudentProgressChartComponents';
import { Spacer, Text } from 'src/components';

const NationalStandardsTooltipContent = () => {
  const { gradeLevel, season } = useStore(
    ({ StudentProgressMonitoringData: s }) => ({
      gradeLevel: s.gradeLevel,
      season: s.season,
    }),
    [],
  );
  return (
    <Text>
      The national benchmark for {gradeLevel} graders is {getRangeText(gradeLevel, season)} WCPM by{' '}
      {seasonEndDates[season]}
    </Text>
  );
};

const WcpmTooltipContent = ({
  name,
  value,
  submissionId,
  finishedAt,
  stat,
}: TooltipSubmissionData) => {
  return value ? (
    <TooltipContentContainer>
      <Text variant="h3" ellipsis>
        {name}
      </Text>
      <Spacer size={2} />
      <Text>
        {stat === 'wpm' ? 'WPM' : 'WCPM'}: {value}
      </Text>
      <Text>Submission Date: {formatDateTime(new Date(finishedAt))}</Text>
      <TooltipSubmissionLink submissionId={submissionId} />
    </TooltipContentContainer>
  ) : null;
};
export const StudentWcpmTooltip = () => {
  const { wcpmTooltipProps, wcpmTooltipData, setWcpmTooltipProps } = useStore(
    ({ StudentProgressMonitoringData: s }) => ({
      wcpmTooltipProps: s.wcpmTooltipProps,
      wcpmTooltipData: s.wcpmTooltipData,
      setWcpmTooltipProps: s.setWcpmTooltipProps,
    }),
    [],
  );

  return (
    <ChartTooltip {...wcpmTooltipProps} tooltipStateSetter={setWcpmTooltipProps}>
      {wcpmTooltipData.size ? (
        wcpmTooltipData.map((a) => <WcpmTooltipContent key={a.submissionId} {...a} />)
      ) : (
        <NationalStandardsTooltipContent />
      )}
    </ChartTooltip>
  );
};
