import { useEffect } from 'react';
import { BaseStudentAvatar, Flex, Button, Text, Spacer } from 'src/components';
import { SimpleLayout } from 'src/layouts';
import { useStore } from 'src/Store';

const NUM_AVATAR_RESULTS = 12;

export const AvatarGallery = () => {
  const { load, loadMore, page, total, loading, avatars } = useStore(
    (state) => ({
      load: state.AvatarGalleryData.load,
      loadMore: state.AvatarGalleryData.loadMore,
      page: state.AvatarGalleryData.page,
      total: state.AvatarGalleryData.total,
      loading: state.AvatarGalleryData.loading,
      avatars: state.AvatarGalleryData.avatars,
    }),
    [],
  );

  useEffect(() => {
    load();
  }, [load]);

  return (
    <SimpleLayout loading={false}>
      <div>
        <Flex direction="column" align="center" justify="center">
          <Text variant="h1" textAlign="center">
            Avatars of Readlee
          </Text>
          <Spacer size={4} />
          <Flex wrap="wrap" align="center" justify="center">
            {avatars.map(({ avatarSkin, avatarEyes, avatarMouth, avatarHat }, i) => (
              <Flex key={i} padding="16px" direction="column" height="auto" testTag="avatar">
                <BaseStudentAvatar
                  kind="dashboard"
                  avatarSkin={avatarSkin}
                  avatarEyes={avatarEyes}
                  avatarMouth={avatarMouth}
                  avatarHat={avatarHat}
                />
              </Flex>
            ))}
          </Flex>

          {moreToLoad(page, total) && (
            <Button onClick={loadMore} loading={loading} width="200px">
              Load More
            </Button>
          )}
        </Flex>
      </div>
    </SimpleLayout>
  );
};

function moreToLoad(page: number, total: number) {
  return page * NUM_AVATAR_RESULTS < total;
}
