import {
  User,
  School,
  SchoolDomain,
  District,
  Plan,
  emptyPlan,
  ExtendedUser,
  ImageStoredFile,
} from 'src/models';
import { History } from 'src/modules/Router';
import { CreateToast } from 'src/components';
import { ImmutableList, ImmutableMap } from 'src/modules/Immutable';
import { SetField } from 'src/State';
import { AuthMethod } from 'src/modules/Analytics/AnalyticsEvents';
import { PaperEvent } from 'src/modules/PaperEvent';

export type AppResponse = {
  currentUser: ExtendedUser;
  currentPlan: Plan;
  schools: ImmutableList<School>;
  schoolDomains: ImmutableList<SchoolDomain>;
  suggestedSchoolMap: ImmutableMap<string, School>;
  districtMap: ImmutableMap<string, District>;
  courseCount: number;
};

export type AppImageResponse = {
  storedFile: ImageStoredFile;
};

export type AppState = {
  setField: SetField<AppState>;
  currentUser: ExtendedUser | null;
  loading: boolean;
  ultimateAssessmentsOverviewOpened: boolean;
  schools: ImmutableList<School>;
  currentPlan: Plan;
  load: (opts?: { onSuccess?: (data: AppResponse) => void; skipStateReset?: boolean }) => void;
  logout: (history: History) => void;
  setUserField: <P extends keyof User>(
    field: P,
    createToast: CreateToast,
    successToast?: boolean,
    onSuccess?: () => void,
  ) => (v: User[P]) => void;
  setUserFields: (
    fields: ImmutableMap<string, User[keyof User]>,
    createToast: CreateToast,
    successToast?: boolean,
    onSuccess?: () => void,
  ) => void;
  onSignedUp: (args: {
    user: User;
    schools: ImmutableList<School>;
    redirectPath?: string | null;
    courseCode?: string | null;
    history: History;
    method: AuthMethod;
    replace?: boolean;
    replaceLocation?: boolean;
  }) => void;
  onLoggedIn: (args: {
    redirectPath?: string | null;
    courseCode?: string | null;
    history: History;
    method: AuthMethod;
    replace?: boolean;
    replaceLocation?: boolean;
  }) => void;
  uploadProfileImage: (files: ImmutableList<File>, createToast?: CreateToast) => void;
  profileImageFile: File | null;
  profileImageStoredFile: ImageStoredFile | null;
  uploadingProfileImage: boolean;
  dirty: boolean;
  courseCount: number;
  isRecording: boolean;
  emitPaperEvent: (event: PaperEvent) => void;
};

export const defaultState = {
  currentUser: null,
  loading: true,
  schools: ImmutableList<School>(),
  currentPlan: emptyPlan(),
  profileImageFile: null,
  profileImageStoredFile: null,
  dirty: false,
  uploadingProfileImage: false,
  ultimateAssessmentsOverviewOpened: false,
  courseCount: 0,
  isRecording: false,
};
