import { User } from 'src/models';
import { FetchJson } from './Api';

export type PaperRecordingStartedEvent = {
  type: 'literacy_recording_started_v3';
  recordingSequenceId: string;
  taskId: string;
  assessmentId: string;
  recordingId: string;
};

export type PaperRecordingEndedEvent = {
  type: 'literacy_recording_ended_v3';
  recordingSequenceId: string;
  taskId: string;
  assessmentId: string;
  recordingId: string;
};

export type PaperEvent = PaperRecordingStartedEvent | PaperRecordingEndedEvent;

export const emitPaperEvent = (
  currentUser: User | null,
  fetchJson: FetchJson,
  event: PaperEvent,
) => {
  if (!currentUser?.paperSyncedAt) return;

  const { type, ...data } = event;
  fetchJson(`/api/paper_events/${type}`, { method: 'POST', data });
};
