import React, { ReactNode } from 'react';

import styled from 'styled-components';
import { borderRadii, colors, paddings } from 'src/styles';
import { Loading, Divider, Flex, Form } from 'src/components';
import { commonLayoutStyles } from 'src/modules/Layout';
import { useCompactLayout } from './ReadingStudioLayoutHelpers';

const ReadingStudioLayoutBase = styled.div`
  ${commonLayoutStyles}

  display: flex;
  flex-direction: column;
  background-color: ${colors.backgroundDark.hex};
`;

const DrawerContainer = styled.div<{ expanded: boolean }>`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  ${({ expanded }) => expanded && ' top: 82px;'}
`;

const BottomBar = styled.div``;

type ReadingStudioLayoutProps = {
  loading: boolean;
  showTopBarDivider: boolean;
  bannerContent?: ReactNode;
  mainContent?: ReactNode;
  drawerContent?: ReactNode;
  topBar: ReactNode;
  sideBar: ReactNode;
  bottomBar?: ReactNode | null;
  onSubmit?: React.FormEventHandler<HTMLFormElement>;
  dirty?: boolean;
  drawerExpanded?: boolean;
};

export const ReadingStudioLayout = ({
  loading,
  bannerContent,
  showTopBarDivider,
  mainContent,
  drawerContent,
  topBar,
  sideBar,
  bottomBar,
  onSubmit,
  dirty,
  drawerExpanded = false,
}: ReadingStudioLayoutProps) => {
  const compactLayout = useCompactLayout();
  if (loading) return <Loading flex kind="boat" />;

  const content = (
    <>
      {sideBar}
      <Flex
        direction="column"
        grow={1}
        backgroundColor={colors.white.hex}
        borderRadius={compactLayout ? '0' : `${borderRadii[5]} 0 0 ${borderRadii[5]}`}
        height="100%"
        width="100%"
        position="relative"
        minWidth={0}
        minHeight={0}
        justify="center"
      >
        {topBar}
        {showTopBarDivider && (
          <Flex width="100%" padding={`0 ${paddings[5]}`}>
            <Divider noMargin />
          </Flex>
        )}

        <Flex grow={1} width="100%" height="100%" minHeight={0} overflowY="auto" justify="center">
          {mainContent}
        </Flex>

        {drawerContent ? (
          <DrawerContainer expanded={drawerExpanded}>{drawerContent}</DrawerContainer>
        ) : null}
        {bottomBar ? <BottomBar>{bottomBar}</BottomBar> : null}
      </Flex>
    </>
  );

  return (
    <ReadingStudioLayoutBase>
      {bannerContent || null}
      {onSubmit ? (
        <Form onSubmit={onSubmit} dirty={dirty} display="flex" grow={1} minHeight={0}>
          {content}
        </Form>
      ) : (
        <Flex grow={1} minHeight={0}>
          {content}
        </Flex>
      )}
    </ReadingStudioLayoutBase>
  );
};
