import styled from 'styled-components';
import { DollarPrefix, paddings, Width, Height, Resize } from 'src/styles';
import { useCallback, ReactNode, ChangeEvent } from 'react';
import { StyledInput } from './InputHelpers';
import { cssIfTruthyOrZero } from '../../styles/Base';

export type TextAreaDisplayType = 'form' | 'flat';
export type TextAreaOnChange = (newValue: string, evt: ChangeEvent<HTMLTextAreaElement>) => void;

type TextAreaInputProps = {
  displayType?: TextAreaDisplayType;
  width?: Width;
  maxWidth?: Width;
  height?: Height;
  minHeight?: Height;
  name: string;
  testTag?: string;
  label?: ReactNode;
  value: string;
  onChange: TextAreaOnChange;
  placeholder?: string;
  disabled?: boolean;
  id?: string;
  textSize?: number;
  resize?: Resize;
};

type TextAreaInputInternalProps = DollarPrefix<
  Pick<
    TextAreaInputProps,
    'width' | 'maxWidth' | 'height' | 'minHeight' | 'displayType' | 'textSize' | 'resize'
  >
>;

const StyledTextArea = StyledInput.withComponent('textarea');

const TextAreaInputWrapper = styled(StyledTextArea)<TextAreaInputInternalProps>`
  ${({ $width, $maxWidth, $height, $minHeight }) => `
    ${cssIfTruthyOrZero('width', $width, '100%')}
    ${cssIfTruthyOrZero('max-width', $maxWidth)}
    ${cssIfTruthyOrZero('height', $height, '100%')}
    ${cssIfTruthyOrZero('height', $minHeight)}
  `}

  padding: ${paddings[2]};
  resize: ${({ $resize = 'none' }) => $resize};

  ${({ $displayType }) =>
    $displayType === 'flat' &&
    `
    border: none;
    outline: none;
  `}
  ${({ $textSize }) => $textSize && `font-size: ${$textSize}px;`}
`;

export const TextAreaInput = ({
  width,
  maxWidth,
  height,
  minHeight,
  resize,
  displayType = 'form',
  textSize = 16,
  testTag = '',
  onChange,
  ...basicProps
}: TextAreaInputProps) => {
  const realOnChange = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement>) => onChange(event.target.value, event),
    [onChange],
  );
  return (
    <TextAreaInputWrapper
      {...basicProps}
      $width={width}
      $maxWidth={maxWidth}
      $height={height}
      $minHeight={minHeight}
      $displayType={displayType}
      $textSize={textSize}
      $resize={resize}
      onChange={realOnChange}
      data-test-tag={testTag}
    />
  );
};
