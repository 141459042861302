import {
  appcues,
  DevAnalyticsEventPrefix,
  FullAnalyticsEvent,
  gtm,
  ReadleeAnalyticsEvent,
  sendAmplitudeEvent,
  sendGoogleAnalyticsEvent,
} from './Analytics';
import { Assessment, Course, School, TaskType, User } from 'src/models';
import { identify } from './AnalyticsIdentity';
import { ImmutableList } from '../Immutable';

const devEventPrefix = (process.env.DEV_ANALYTICS_EVENT_PREFIX ||
  '__dev__') as DevAnalyticsEventPrefix;

const trackEvent = async (
  eventName: ReadleeAnalyticsEvent,
  options: Record<string, string> = {},
) => {
  const prefix: DevAnalyticsEventPrefix | '' =
    process.env.REACT_ENV === 'production' ? '' : devEventPrefix;
  const event: FullAnalyticsEvent = `${prefix}${eventName}`;
  const path = `${window.location.pathname}${window.location.search}${window.location.hash}`;

  const extendedOptions = {
    ...options,
    path,
    url: window.location.href,
    pathname: window.location.pathname,
    host: window.location.host,
    query: window.location.search,
    hash: window.location.hash,
    origin: window.location.origin,
  };
  sendGoogleAnalyticsEvent(event, extendedOptions);
  await sendAmplitudeEvent(event, extendedOptions);
};

// see https://github.com/ReactTraining/react-router/issues/4278#issuecomment-278477167
export const trackPageView =
  ({ loading }: { loading: boolean }) =>
  // eslint-disable-next-line react/display-name
  () => {
    if (!loading) {
      appcues((ac) => {
        ac.page();
      });

      const path = `${window.location.pathname}${window.location.search}${window.location.hash}`;
      gtm('set', 'page_path', path);
      trackEvent('page_view');
    }

    return null;
  };

export type AuthMethod = 'google' | 'clever' | 'password' | 'paper';

export const trackLogin = async ({
  method,
  user,
  schools,
}: {
  method: AuthMethod;
  user: User;
  schools: ImmutableList<School>;
}) => {
  await identify({ user, schools });
  await trackEvent('login', { method, userId: user.id });
};

export const trackSignUp = async ({
  method,
  user,
  schools,
}: {
  method: AuthMethod;
  user: User;
  schools: ImmutableList<School>;
}) => {
  await identify({ user, schools });
  await trackEvent(`${user.accountType}_signup`, { method, userId: user.id });
};
export const trackAssessmentCreated = async ({ assessment }: { assessment: Assessment }) => {
  await trackEvent('create_assessment', {
    assessmentId: assessment.id,
    assessmentName: assessment.name,
    timestamp: assessment.createdAt,
  });
};

export const trackAssessmentUpgraded = async ({
  assessment,
  upgradedFor,
}: {
  assessment: Assessment;
  upgradedFor: string;
}) => {
  await trackEvent('upgrade_assessment', {
    assessmentId: assessment.id,
    assessmentName: assessment.name,
    timestamp: assessment.createdAt,
    upgradedFor,
  });
};

export const trackCourseCreated = async ({ course }: { course: Course }) => {
  await trackEvent('create_course', {
    courseId: course.id,
    courseName: course.name,
    timestamp: course.createdAt,
  });
};

export const trackCourseImported = async ({ course }: { course: Course }) => {
  await trackEvent('import_course', {
    courseId: course.id,
    courseName: course.name,
    timestamp: course.createdAt,
  });
};

export const trackAssessmentAssigned = async ({ assessmentId }: { assessmentId: string }) => {
  await trackEvent('assign_assessment', {
    assessmentId,
  });
};

export const trackAssessmentSubmitted = async (options: {
  assessmentId: string;
  kind: 'submit' | 'resubmit';
}) => {
  await trackEvent('submit_assignment', {
    ...options,
    timestamp: String(new Date().getTime()),
  });
};

export const trackRecording = async (options: {
  assessmentId: string;
  taskId: string;
  taskType: TaskType;
  durationInMilliseconds: string;
  kind: 'new' | 'extension';
}) => {
  await trackEvent('recording_completed_event', {
    ...options,
    timestamp: String(new Date().getTime()),
  });
};
