import { ReactNode } from 'react';

import { ImmutableList } from 'src/modules/Immutable';
import {
  Text,
  Icon,
  FlexGrow,
  Spacer,
  Button,
  NoStylesButton,
  Flex,
  NoButtonThumbnail,
  Thumbnail,
  MetricProps,
  Tooltip,
} from 'src/components';
import { colors, margins } from 'src/styles';
import { useHistory } from 'src/modules/Router';

export type AssignmentCardContentProps = {
  testTag?: string;
  open?: boolean;
  to?: string;
  coverImg?: string;
  title: string;
  subTitle: ReactNode;
  readingName?: string;
  children: ReactNode;
  metrics?: ImmutableList<MetricProps>;
  showMobile?: boolean;
  onToggleCardOpen?: () => void;
  setHoveringClickableArea: (value: boolean) => void;
  ultimate: boolean;
  isStudent: boolean;
};
const ToggleCardOpen = ({
  open,
  visible,
  onToggleCardOpen,
}: Pick<AssignmentCardContentProps, 'open' | 'onToggleCardOpen'> & { visible: boolean }) =>
  !visible ? null : (
    <>
      <Spacer horizontal size={2} />
      <Button displayType="noStyles" onClick={onToggleCardOpen} testTag="toggle">
        <Icon
          icon={`arrow${open ? 'Up' : 'Down'}`}
          strokeWidth={2}
          color="gray7"
          cssColor={colors.gray7.hex}
        />
      </Button>
    </>
  );

export const AssignmentCardContent = ({
  to,
  title,
  subTitle,
  readingName,
  coverImg,
  children,
  metrics,
  showMobile,
  onToggleCardOpen,
  setHoveringClickableArea,
  open,
  ultimate,
  isStudent,
}: AssignmentCardContentProps) => {
  const history = useHistory();

  return (
    <Flex width="100%" height="100%">
      {showMobile ? (
        <Flex width="100%" direction="column">
          <NoStylesButton
            onClick={() => (to ? history.push(to) : null)}
            onMouseOver={() => setHoveringClickableArea(true)}
            onMouseOut={() => setHoveringClickableArea(false)}
          >
            <NoButtonThumbnail src={coverImg} size="small" title={readingName} />
            <Spacer horizontal size={2} />
            <Flex direction="column" align="start" width="100%" minWidth={0}>
              {ultimate && (
                <>
                  <Tooltip content="Upgraded to Ultimate Assignment">
                    <Icon
                      icon="ultimateAssignmentCentered"
                      size="1.5em"
                      testTag="ultimate-assessment"
                    />
                  </Tooltip>
                  <Spacer horizontal size={2} />
                </>
              )}
              <Text
                variant="h5"
                weightOverride="bold"
                testTag="assignment-card-title"
                minWidth="100%"
                maxWidth="100%"
                ellipsis
              >
                {title}
              </Text>
              <Spacer size={2} />
              {subTitle}
            </Flex>
          </NoStylesButton>
          <ToggleCardOpen
            visible={Boolean(metrics)}
            open={open}
            onToggleCardOpen={onToggleCardOpen}
          />
        </Flex>
      ) : (
        <>
          <Thumbnail
            to={to}
            src={coverImg}
            size="medium"
            onMouseOver={() => setHoveringClickableArea(true)}
            onMouseOut={() => setHoveringClickableArea(false)}
            title={readingName}
          />
          <Spacer horizontal size={3} />
          <FlexGrow>
            <Flex align="center" height="100%">
              {isStudent ? (
                <NoStylesButton
                  to={to}
                  flexGrow={1}
                  onMouseOver={() => setHoveringClickableArea(true)}
                  onMouseOut={() => setHoveringClickableArea(false)}
                >
                  <Flex flex={2} width="100%" padding={`0 ${margins[10]} 0 0`}>
                    <Flex direction="column" width="100%" align="start">
                      <Flex align="stretch">
                        {ultimate && (
                          <Flex height="100%" padding="0 5px 0 0">
                            <Tooltip content="Upgraded to Ultimate Assignment">
                              <Icon
                                icon="ultimateAssignment"
                                size="1.5em"
                                testTag="ultimate-assessment"
                              />
                            </Tooltip>
                          </Flex>
                        )}
                        <Text variant="h3" testTag="assignment-card-title">
                          {title}
                        </Text>
                      </Flex>
                      <Spacer size={2} />
                      {subTitle}
                    </Flex>
                  </Flex>
                </NoStylesButton>
              ) : (
                <Flex flex={2} width="100%" padding={`0 ${margins[10]} 0 0`}>
                  <Flex direction="column" width="100%" align="start">
                    <NoStylesButton
                      to={to}
                      flexGrow={1}
                      onMouseOver={() => setHoveringClickableArea(true)}
                      onMouseOut={() => setHoveringClickableArea(false)}
                    >
                      <Flex align="stretch">
                        {ultimate && (
                          <Flex height="100%" padding="0 5px 0 0">
                            <Tooltip content="Upgraded to Ultimate Assignment">
                              <Icon
                                icon="ultimateAssignment"
                                size="1.5em"
                                testTag="ultimate-assessment"
                              />
                            </Tooltip>
                          </Flex>
                        )}
                        <Text variant="h3" testTag="assignment-card-title">
                          {title}
                        </Text>
                      </Flex>
                    </NoStylesButton>
                    <Spacer size={2} />
                    {subTitle}
                  </Flex>
                </Flex>
              )}
              <Flex flex={1} width="100%" height="100%">
                <Flex align="center" justify="space-between" width="100%">
                  {isStudent ? (
                    <NoStylesButton
                      to={to}
                      flexGrow={1}
                      onMouseOver={() => isStudent && setHoveringClickableArea(true)}
                      onMouseOut={() => isStudent && setHoveringClickableArea(false)}
                      disabled={!isStudent}
                    >
                      {children}
                    </NoStylesButton>
                  ) : (
                    <> {children} </>
                  )}

                  <ToggleCardOpen
                    visible={Boolean(metrics)}
                    open={open}
                    onToggleCardOpen={onToggleCardOpen}
                  />
                </Flex>
              </Flex>
            </Flex>
          </FlexGrow>
        </>
      )}
    </Flex>
  );
};
