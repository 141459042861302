import { ChangeEvent, useCallback } from 'react';
import { InputOnChange } from './Input';
import { AutoCompleteOption, StyledInput } from './InputHelpers';

export type DateInputProps = {
  id?: string;
  value: string;
  name: string;
  onChange: InputOnChange;
  min?: string;
  max?: string;
  testTag?: string;
  autoComplete?: AutoCompleteOption;
};

export const minDateToday = (): string => {
  const today = new Date();
  const month = `${today.getMonth() + 1}`.padStart(2, '0');
  const day = `${today.getDate()}`.padStart(2, '0');

  return `${today.getFullYear()}-${month}-${day}`;
};

export const DateInput = ({ onChange, min, max, testTag, ...basicProps }: DateInputProps) => {
  const realOnChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => onChange(event.target.value, event),
    [onChange],
  );

  return (
    <StyledInput
      {...basicProps}
      type="date"
      onChange={realOnChange}
      min={min}
      max={max}
      data-test-tag={testTag}
    />
  );
};
