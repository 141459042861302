import { useMemo } from 'react';
import { useStore } from 'src/Store';
import { basicColors } from 'src/styles';
import { ApiStat } from 'src/modules/Stat';
import { ImmutableList } from 'src/modules/Immutable';
import { MILLISECONDS_IN_MINUTE } from 'src/modules/Time';
import { ChartLabel, GraphContainer, GraphLabels } from './StudentProgressChartComponents';
import {
  getXIntercept,
  useDateFilter,
  labels,
  objHasPropOfType,
  isBaseTooltipData,
  useSubmissionResponses,
  getGraphData,
  externalTooltipFunc,
  mapToDataArray,
  aggregateAssignments,
} from './StudentProgressChartHelpers';
import { TooltipSubmissionData } from './StudentProgressMonitoringState';
import { StudentProgressMonitoringChart } from './StudentProgressMonitoringChart';

const isReadingTooltipData = (obj: unknown): obj is TooltipSubmissionData =>
  isBaseTooltipData(obj) &&
  objHasPropOfType(obj, 'readingTime', 'number') &&
  objHasPropOfType(obj, 'totalTime', 'number');

const findTimeRead = (s: ApiStat) => s.name === 'time_read';

export const StudentProgressReadingChart = () => {
  const { submissionResults, assessmentMap, stats, setReadingTooltipData, setReadingTooltipProps } =
    useStore(
      ({ StudentProgressMonitoringData: s }) => ({
        submissionResults: s.submissionResults,
        assessmentMap: s.assessmentMap,
        stats: s.stats,
        setReadingTooltipData: s.setReadingTooltipData,
        setReadingTooltipProps: s.setReadingTooltipProps,
      }),
      [],
    );
  const { dateOption: startDate } = useDateFilter();
  const submissionResponses = useSubmissionResponses();

  const durationData = useMemo(
    () =>
      submissionResponses
        .map((group) => ({
          totalTime: group.reduce((acc, { submission: s }) => acc + s.timeRead, 0),
          assignments: aggregateAssignments(group, assessmentMap, (sr) => ({
            readingTime: sr.submission.timeRead,
          })),
        }))
        .map((item, key, self) => {
          const totalPrevTime = self.reduce(
            (acc, cur, k) => (k < key ? acc + cur.totalTime : acc),
            0,
          );
          const totalDaysTime = item.assignments.reduce((acc, cur) => acc + cur.readingTime, 0);
          return {
            totalTime: totalPrevTime + totalDaysTime,
            assignments: item.assignments.map((a, _idx, assignments) => ({
              ...a,
              totalTime: assignments.reduce(
                (acc, cur) =>
                  Date.parse(cur.finishedAt) < Date.parse(a.finishedAt)
                    ? acc + cur.readingTime
                    : acc,
                totalPrevTime + a.readingTime,
              ),
            })),
          };
        }),
    [submissionResponses, assessmentMap],
  );

  const currentTimeRead = stats.find(findTimeRead)?.value || 0;

  const dataset = useMemo(
    () =>
      getGraphData(durationData, startDate).map((v) => ({
        ...v,
        totalGraphedTime: v.totalTime / MILLISECONDS_IN_MINUTE,
      })),
    [durationData, startDate],
  );

  const data = useMemo(() => {
    const origin = getXIntercept(startDate, submissionResults);
    return {
      labels: labels(origin),
      datasets: [
        {
          data: mapToDataArray(dataset.map((el) => el.totalTime / MILLISECONDS_IN_MINUTE)),
          borderColor: basicColors.readleeBlue,
          backgroundColor: basicColors.readleeBlue,
        },
      ],
    };
  }, [dataset, startDate, submissionResults]);

  const maxValue = useMemo(
    () => ((currentTimeRead / MILLISECONDS_IN_MINUTE) * 10) / 9,
    [currentTimeRead],
  );

  const tooltipHandler = useMemo(
    () =>
      externalTooltipFunc(setReadingTooltipProps, (raw) => {
        const items =
          dataset.get(raw.x)?.assignments.filter(isReadingTooltipData) ??
          ImmutableList<TooltipSubmissionData>();
        setReadingTooltipData(items);
      }),
    [dataset, setReadingTooltipData, setReadingTooltipProps],
  );

  return (
    <GraphContainer>
      <GraphLabels
        label={
          <ChartLabel
            label="Total Time Read"
            tooltip="Cumulative time spent reading during the selected period"
            testTag="time_read-label"
          />
        }
        stat="time_read"
      />
      <StudentProgressMonitoringChart
        datasetIdKey="readingtime"
        data={data}
        maxValue={maxValue}
        tooltipPropsHandler={tooltipHandler}
      />
    </GraphContainer>
  );
};
