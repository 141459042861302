import { Button, ButtonProps } from 'src/components';
import { CreateCourseModal } from './CreateCourseModal';

export const CreateCourseButton = ({
  openedPath,
  closedPath,
  displayType,
}: {
  openedPath: string;
  closedPath: string;
  displayType: ButtonProps['displayType'];
}) => {
  return (
    <>
      <Button to={openedPath} displayType={displayType}>
        Create Class
      </Button>
      <CreateCourseModal openedPath={openedPath} closedPath={closedPath} />
    </>
  );
};
