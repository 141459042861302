import { useEffect, useState } from 'react';
import { useStore } from 'src/Store';
import { ImmutableMap } from 'src/modules/Immutable';
import { queryMapToString, useHistory, useQuery } from 'src/modules/Router';

import { Flex, FormGroup, PDFReader, ErrorText } from 'src/components';
import { TEXT_VIEWER_MAX_WIDTH } from 'src/styles';
import { ContentContainer } from 'src/pages/ReadingBuilder/ReadingBuilderHelpers';

export const EditPdfTextStep = () => {
  const history = useHistory();
  const query = useQuery();
  const { pdfFile, pdfFileUrl, uploadingPdf, submit, errors, setPdfPageText, peekPdf } = useStore(
    (state) => ({
      pdfFile: state.ReadingBuilderData.pdfFile,
      pdfFileUrl: state.ReadingBuilderData.pdfFileUrl,
      uploadingPdf: state.ReadingBuilderData.uploadingPdf,
      submit: state.ReadingBuilderData.submit,
      errors: state.ReadingBuilderData.errors,
      setPdfPageText: state.ReadingBuilderData.setPdfPageText,
      peekPdf: state.ReadingBuilderData.peekPdf,
    }),
    [],
  );

  useEffect(() => {
    if (!pdfFile || pdfFile.type !== 'PdfByPageStoredFile') {
      history.push(`/t/reading-builder/content${queryMapToString(query)}`);
    }
  }, [pdfFile, uploadingPdf, history, submit, query]);

  const [currentPage, setCurrentPage] = useState(0);

  if (!pdfFileUrl || !pdfFile || pdfFile.type !== 'PdfByPageStoredFile') {
    return <div>could not display content</div>;
  }

  const currentPageText = ImmutableMap(pdfFile?.jsonText ?? {}).get(String(currentPage - 1), '');

  return (
    <Flex width="100%" height="100%">
      <PDFReader
        src={pdfFileUrl}
        kind={peekPdf ? 'full' : 'thumbnailsOnly'}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        hideTopBar
      />
      {!peekPdf && (
        <ContentContainer>
          <ErrorText errors={errors} errorKey="contentFile" />
          <FormGroup
            label={null}
            name="contentFile"
            type="text-area"
            displayType="flat"
            value={currentPageText}
            onChange={(text) => setPdfPageText(currentPage - 1, text)}
            placeholder="Update the text student should be reading aloud for this PDF page."
            height="100%"
            maxWidth={TEXT_VIEWER_MAX_WIDTH}
            testTag="edit-pdf-text-step-text-area"
          />
        </ContentContainer>
      )}
    </Flex>
  );
};
