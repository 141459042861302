import { margins } from 'src/styles';
import styled from 'styled-components';
import { Icon } from 'src/components';
export type SortDirection = 'asc' | 'desc';

type SortArrowProps = {
  direction: SortDirection;
};

const BaseSortArrow = styled.div`
  margin-left: ${margins[1]};
  display: flex;
`;

export const SortArrow = ({ direction }: SortArrowProps) => {
  return (
    <BaseSortArrow>
      {direction === 'asc' ? (
        <Icon icon="sortUp" size="1em" color="white" fill fillColor="primaryBlue" />
      ) : (
        <Icon icon="sortDown" size="1em" color="white" fill fillColor="primaryBlue" />
      )}
    </BaseSortArrow>
  );
};
