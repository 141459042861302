import { useCallback, useRef, useState, useEffect, ReactNode } from 'react';
import { clamp } from 'src/modules/Number';
import { formatRecordingTime } from 'src/modules/Time';
import {
  AudioTrack,
  Button,
  Spacer,
  Text,
  FlexGrow,
  IconType,
  Flex,
  DropdownMenu,
  Icon,
} from 'src/components';
import { standardMeasurements, Color, paddings, Width } from 'src/styles';
import { teacherOrGreater } from 'src/models';
import { useStore } from 'src/Store';
import * as Storage from 'src/modules/Storage';
import { PlaybackSpeedModal } from './PlaybackSpeedModal';

const Placeholder = ({ children }: { children: ReactNode }) => {
  return (
    <FlexGrow>
      <Text>{children}</Text>
    </FlexGrow>
  );
};

export type AudioPlayerDisplayType = 'desktop' | 'desktopSmall' | 'mobile' | 'iconOnly';

type AudioPlayerProps = {
  src: string;
  audioFileId?: string;
  initialPlayProgress?: number;
  playIndex?: number;
  placeholder?: string;
  testTag?: string;
  onPlay?: () => void;
  subText?: string;
  iconColor?: Color;
  showSkipButtons?: boolean;
  displayType?: AudioPlayerDisplayType;
  icon?: IconType;
  additionalControls?: ReactNode;
  trackMinWidth?: Width;
  disabled?: boolean;
};

export const AudioPlayer = ({
  src,
  audioFileId,
  initialPlayProgress,
  playIndex,
  showSkipButtons = true,
  placeholder,
  testTag,
  onPlay,
  iconColor,
  displayType = 'desktop',
  icon,
  additionalControls,
  trackMinWidth = '200px',
  disabled = false,
}: AudioPlayerProps) => {
  const { accountType, isRecording } = useStore(
    (s) => ({
      accountType: s.AppData.currentUser?.accountType,
      isRecording: s.AppData.isRecording,
    }),
    [],
  );
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const playIntervalRef = useRef<NodeJS.Timeout | null>(null);

  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [playbackRate, setPlaybackRate] = useState<number>(1);
  const [playProgress, setPlayProgress] = useState<number>(0);
  const [duration, setDuration] = useState<number>(0);
  const [played, setPlayed] = useState<boolean>(false);
  const [isEditingSpeed, setIsEditingSpeed] = useState<boolean>(false);

  const showPlaybackControls = teacherOrGreater(accountType || 'student') && audioFileId;

  const onAudioLoaded = useCallback(() => {
    if (audioRef.current) {
      audioRef.current.playbackRate = playbackRate;
      setDuration(audioRef.current.duration * 1000);
    }
  }, [playbackRate]);

  const play = useCallback(async () => {
    if (!audioRef.current) return;

    await audioRef.current.play();
    setIsPlaying(true);
    setPlayed(true);
    setPlayProgress(audioRef.current.currentTime * 1000);

    if (playIntervalRef.current) clearInterval(playIntervalRef.current);
    playIntervalRef.current = setInterval(() => {
      setPlayProgress((audioRef.current?.currentTime ?? 0) * 1000);
    }, 100);
    onPlay?.();
  }, [onPlay]);

  const pause = useCallback(() => {
    if (!isPlaying) return;
    setIsPlaying(false);
    audioRef.current?.pause();
    if (playIntervalRef.current) clearInterval(playIntervalRef.current);
  }, [isPlaying]);

  const seek = useCallback(
    (timestamp: number) => {
      if (audioRef.current) {
        const value = clamp({ value: timestamp / 1000, min: 0, max: duration });
        audioRef.current.currentTime = value;
        setPlayProgress(timestamp);
      }
    },
    [duration],
  );

  const changePlaybackRate = (newRate: number) => {
    if (audioRef.current) {
      audioFileId && Storage.setItem(`audio-playback-rate-${audioFileId}`, newRate.toString());
      audioRef.current.playbackRate = newRate;
      setPlaybackRate(newRate);
    }
  };

  useEffect(() => {
    if (typeof initialPlayProgress === 'number' && typeof playIndex === 'number') {
      seek(initialPlayProgress);
    }
  }, [initialPlayProgress, seek, playIndex]);

  useEffect(() => {
    const savedPlaybackRate =
      (audioFileId && Number(Storage.getItem(`audio-playback-rate-${audioFileId}`))) ||
      playbackRate;

    if (playbackRate !== savedPlaybackRate) setPlaybackRate(savedPlaybackRate);
  }, [audioFileId, playbackRate]);

  const onAudioFinished = useCallback(() => {
    setIsPlaying(false);
    setPlayProgress(duration);
    if (playIntervalRef.current) clearInterval(playIntervalRef.current);
  }, [duration]);

  const backFive = useCallback(() => {
    if (audioRef.current) {
      seek((audioRef.current.currentTime - 5) * 1000);
    }
  }, [seek]);

  const forwardFive = useCallback(() => {
    if (audioRef.current) {
      seek((audioRef.current.currentTime + 5) * 1000);
    }
  }, [seek]);

  const buttonSize = standardMeasurements[8];

  const sharedIconProps = {
    iconSize: buttonSize,
    width: standardMeasurements[6],
    height: standardMeasurements[6],
    displayType: 'iconOnly',
    color: 'backgroundLight',
    iconColor: 'black',
    paddingOverride: paddings.none,
    disabled,
  } as const;

  const audio = (
    <audio
      ref={audioRef}
      src={src}
      onCanPlayThrough={onAudioLoaded}
      onDurationChange={onAudioLoaded}
      onEnded={onAudioFinished}
    />
  );

  const skipButtonsVisible = showSkipButtons && (!placeholder || played);

  const sharedPlayButtonProps = {
    displayType: 'iconOnly',
    onClick: isPlaying ? pause : play,
    icon: icon || (isPlaying ? 'pause' : 'play'),
    paddingOverride: paddings.none,
    height: buttonSize,
    width: buttonSize,
    iconColor: iconColor ?? 'white',
    testTag,
    disabled,
  } as const;

  const playControls =
    displayType === 'iconOnly' ? (
      <Button color="white" {...sharedPlayButtonProps} />
    ) : (
      <>
        {skipButtonsVisible && (
          <>
            <Button icon="backFiveSeconds" onClick={backFive} {...sharedIconProps} />
            <Spacer horizontal />
          </>
        )}
        <Button
          color="primaryBlue"
          iconSize={isPlaying ? '20px' : '14px'}
          iconFill
          {...sharedPlayButtonProps}
        />
        {skipButtonsVisible && (
          <>
            <Spacer horizontal />
            <Button icon="forwardFiveSeconds" onClick={forwardFive} {...sharedIconProps} />
          </>
        )}
      </>
    );

  const track = (
    <>
      {placeholder && !played ? (
        <Placeholder>{placeholder}</Placeholder>
      ) : (
        <AudioTrack
          playProgress={playProgress}
          duration={duration}
          seek={seek}
          minWidth={trackMinWidth}
        />
      )}
    </>
  );

  const timestamps = (
    <>
      {/* Min width prevents wiggle as the numbers change */}
      <Text
        variant="h7"
        color="gray7"
        testTag={`playback-location-${formatRecordingTime(duration)}`}
        weightOverride="semibold"
        whiteSpace="nowrap"
        minWidth="70px"
        textAlign="right"
      >
        {placeholder && !played
          ? null
          : `${formatRecordingTime(clamp({ value: playProgress, max: duration, min: 0 }))} / `}
        {formatRecordingTime(duration)}
      </Text>
    </>
  );

  if (displayType === 'iconOnly') {
    return (
      <>
        {audio}
        {playControls}
      </>
    );
  } else if (displayType === 'mobile') {
    return (
      <Flex align="center" width="100%">
        {playControls}
        <Spacer horizontal size={2} />
        {audio}
        {track}
        {timestamps}
      </Flex>
    );
  } else {
    return (
      <Flex align="center" width="100%">
        {audio}
        {playControls}
        <Spacer horizontal size={4} />
        {track}
        {timestamps}
        <Spacer horizontal size={3} />
        {additionalControls}
        {!additionalControls && showPlaybackControls && (
          <>
            <DropdownMenu
              testTag="audio-player-menu"
              displayType="noStyles"
              rightAligned
              direction="up"
              icon="threeVerticalDots"
              iconColor="gray7"
              disabled={isRecording}
              options={[
                {
                  onClick: () => setIsEditingSpeed(true),
                  children: (
                    <Flex padding={paddings[2]}>
                      <Icon icon="playbackSpeed" size="1.5em" />
                      <Spacer horizontal size={2} />
                      <Text variant="h5" whiteSpace="nowrap">
                        Playback Speed
                      </Text>
                    </Flex>
                  ),
                },
              ]}
            />
            <PlaybackSpeedModal
              audioFileId={audioFileId}
              open={isEditingSpeed}
              close={() => setIsEditingSpeed(false)}
              onChange={changePlaybackRate}
              playbackRate={playbackRate}
            />
          </>
        )}
      </Flex>
    );
  }
};
