import { useMemo } from 'react';
import { matchPath, useHistory, useLocation } from 'react-router-dom';
import {
  isTaskSubmissionCompleted,
  ModelTaskSubmissionDetails,
  PUBLIC_PREVIEW_PATH,
  ReadingTaskSubmissionDetails,
  SubmissionStatus,
  TaskSubmissionDetails,
  VocabTaskDetails,
} from 'src/models';
import { compact, ImmutableList } from 'src/modules/Immutable';
import { useStore } from 'src/Store';
import { Button } from 'src/components';
import { useQuery } from 'src/modules/Router';
import { useCompactLayout } from '../AssignToModal/AssignToModalHelpers';

export const useReadingTaskSubmissionDetails = (): ReadingTaskSubmissionDetails | null => {
  const { assessment, taskSubmissionDetailsMap } = useStore(
    ({ ReadingStudioData: s }) => ({
      assessment: s.assessment,
      taskSubmissionDetailsMap: s.taskSubmissionDetailsMap,
    }),
    [],
  );

  if (!assessment) return null;

  const readingTaskData = assessment.taskOrder.find(
    ({ taskId, type }) => type === 'ReadingTask' && taskSubmissionDetailsMap.has(taskId),
  );
  if (!readingTaskData) return null;
  const readingTaskId = readingTaskData.taskId;
  const readingTaskSubmissionDetails = taskSubmissionDetailsMap.get(readingTaskId);
  if (!readingTaskSubmissionDetails || readingTaskSubmissionDetails.type !== 'ReadingTask')
    return null;

  return readingTaskSubmissionDetails;
};

export const useFirstModelTaskSubmissionDetails = (): ModelTaskSubmissionDetails | null => {
  const { assessment, taskSubmissionDetailsMap } = useStore(
    ({ ReadingStudioData: s }) => ({
      assessment: s.assessment,
      taskSubmissionDetailsMap: s.taskSubmissionDetailsMap,
    }),
    [],
  );

  if (!assessment) return null;

  const modelTaskData = assessment.taskOrder.find(
    ({ taskId, type }) => type === 'ModelTask' && taskSubmissionDetailsMap.has(taskId),
  );
  if (!modelTaskData) return null;
  const modelTaskId = modelTaskData.taskId;
  const modelTaskSubmissionDetails = taskSubmissionDetailsMap.get(modelTaskId);
  if (!modelTaskSubmissionDetails || modelTaskSubmissionDetails.type !== 'ModelTask') return null;

  return modelTaskSubmissionDetails;
};

export const useCurrentTaskSubmissionDetails = (): TaskSubmissionDetails | null => {
  const { taskSubmissionDetailsMap, currentTaskId } = useStore(
    ({ ReadingStudioData: s }) => ({
      taskSubmissionDetailsMap: s.taskSubmissionDetailsMap,
      currentTaskId: s.currentTaskId,
    }),
    [],
  );

  const currentTaskSubmissionDetails = currentTaskId
    ? taskSubmissionDetailsMap.get(currentTaskId, null)
    : null;

  return currentTaskSubmissionDetails;
};

export const useVocabTaskDetailsList = (): ImmutableList<VocabTaskDetails> => {
  const { taskSubmissionDetailsMap, assessment } = useStore(
    ({ ReadingStudioData: s }) => ({
      taskSubmissionDetailsMap: s.taskSubmissionDetailsMap,
      currentTaskId: s.currentTaskId,
      assessment: s.assessment,
    }),
    [],
  );

  const vocabTaskDetailsList = useMemo(() => {
    return (assessment?.taskOrder || ImmutableList())
      .map((ti) => taskSubmissionDetailsMap.get(ti.taskId))
      .filter((t) => t && t.type === 'VocabTask')
      .toList() as ImmutableList<VocabTaskDetails>;
  }, [taskSubmissionDetailsMap, assessment?.taskOrder]);

  return vocabTaskDetailsList;
};

export const useIsPublicPreview = () => {
  const pathname = useLocation().pathname;
  const isPublicPreview = Boolean(matchPath(pathname, PUBLIC_PREVIEW_PATH));
  return isPublicPreview;
};

export const useIsInstructionsPage = () => {
  const { assessment, taskSubmissionDetailsMap } = useStore(
    ({ ReadingStudioData: s }) => ({
      assessment: s.assessment,
      taskSubmissionDetailsMap: s.taskSubmissionDetailsMap,
    }),
    [],
  );
  const query = useQuery();
  const realCurrentTaskId = query.get('currentTaskId');
  const compactLayout = useCompactLayout();

  const currentTaskSubmissionDetails = realCurrentTaskId
    ? taskSubmissionDetailsMap.get(realCurrentTaskId)
    : null;
  const currentTaskIndex = assessment?.taskOrder.findIndex((to) => to.taskId === realCurrentTaskId);
  const taskDetailsList = compact(
    (assessment?.taskOrder || ImmutableList()).map((to) => taskSubmissionDetailsMap.get(to.taskId)),
  );

  return Boolean(
    compactLayout &&
      taskDetailsList.some(
        (t) => t.type === 'ReadingTask' && t.reading.contentType !== 'independent',
      ) &&
      (!realCurrentTaskId ||
        (assessment?.taskOrder.some((ti) => ti.type === 'ModelTask') &&
          (!currentTaskSubmissionDetails ||
            typeof currentTaskIndex !== 'number' ||
            (currentTaskIndex === 0 && currentTaskSubmissionDetails.type === 'ModelTask')))),
  );
};

export const useBackPath = () => {
  const { assessment, userType } = useStore(
    (state) => ({
      assessment: state.ReadingStudioData.assessment,
      userType: state.AppData.currentUser?.accountType || '',
    }),
    [],
  );

  const query = useQuery();
  const backPathParam = query.get('backPath');
  const defaultBackPath = userType === 'student' ? '/' : `/t/assignments/${assessment?.id}`;

  return backPathParam || defaultBackPath;
};

export const useIncompleteTaskId = () => {
  const { assessment, taskSubmissionDetailsMap } = useStore(
    ({ ReadingStudioData: s }) => ({
      assessment: s.assessment,
      taskSubmissionDetailsMap: s.taskSubmissionDetailsMap,
    }),
    [],
  );
  const readingTaskSubmissionDetails = useReadingTaskSubmissionDetails();
  if (!assessment) return null;
  const incompleteTask = assessment.taskOrder
    .map((to) => taskSubmissionDetailsMap.find((tsd) => tsd.task.id === to.taskId))
    .find((tds) => Boolean(tds && !isTaskSubmissionCompleted(tds)));
  const incompleteTaskId = incompleteTask?.task.id ?? readingTaskSubmissionDetails?.task.id;
  return incompleteTaskId;
};

export const PreviousTaskLink = () => {
  const { assessment, currentTaskId, switchTask } = useStore(
    ({ ReadingStudioData: s }) => ({
      assessment: s.assessment,
      currentTaskId: s.currentTaskId,
      switchTask: s.switchTask,
    }),
    [],
  );
  const query = useQuery();
  const history = useHistory();
  const isPublicPreview = useIsPublicPreview();
  const currentTaskSubmissionDetails = useCurrentTaskSubmissionDetails();
  const backPath = useBackPath();
  const isInstructionsPage = useIsInstructionsPage();
  const leaveButton = (
    <Button to={backPath} borderRadiusOverride="none" color="gray2">
      Leave
    </Button>
  );
  if (isInstructionsPage || !currentTaskId || !assessment || !currentTaskSubmissionDetails) {
    return leaveButton;
  }
  const currentTaskIndex = assessment.taskOrder.findIndex((to) => to.taskId === currentTaskId);
  if (currentTaskIndex === -1 || currentTaskIndex === 0) {
    return leaveButton;
  }

  const prevTaskOrderItem = assessment.taskOrder.get(currentTaskIndex - 1);
  if (!prevTaskOrderItem) return leaveButton;

  return (
    <Button
      onClick={switchTask({
        taskId: prevTaskOrderItem?.taskId,
        query,
        history,
        isPublicPreview,
      })}
      testTag="previous-task-button"
      color="gray2"
      borderRadiusOverride="none"
    >
      Previous
    </Button>
  );
};

const submissionButtonLabel = (status: SubmissionStatus, freeRead = false) => {
  if (freeRead) {
    return status === 'finished' ? 'Re-Submit' : 'Submit';
  }

  return status === 'finished' ? 'Re-Submit Assignment' : 'Turn In Assignment';
};

export const SubmitButton = () => {
  const { assessment, submission, submitting, isRecording } = useStore(
    ({ ReadingStudioData: s, AppData: a }) => ({
      assessment: s.assessment,
      submission: s.submission,
      submitting: s.submitting,
      isRecording: a.isRecording,
    }),
    [],
  );
  const compactLayout = useCompactLayout();

  if (!submission) return null;

  return (
    <Button
      type="submit"
      loading={submitting}
      flexGrow={1}
      testTag="submit-assignment-button"
      borderRadiusOverride={compactLayout ? 'none' : undefined}
      disabled={isRecording}
    >
      {submissionButtonLabel(submission.status, assessment?.isFreeRead)}
    </Button>
  );
};

export const NextTaskLink = () => {
  const { assessment, switchTask, currentTaskId } = useStore(
    ({ ReadingStudioData: s }) => ({
      assessment: s.assessment,
      switchTask: s.switchTask,
      currentTaskId: s.currentTaskId,
    }),
    [],
  );
  const query = useQuery();
  const history = useHistory();
  const isInstructionsPage = useIsInstructionsPage();
  const isPublicPreview = useIsPublicPreview();
  if (!assessment) return null;
  let currentTaskIndex = null;
  if (isInstructionsPage) {
    if (assessment.taskOrder.some((t) => t.type === 'ModelTask')) {
      currentTaskIndex = 0;
    } else {
      currentTaskIndex = -1;
    }
  } else {
    currentTaskIndex = assessment.taskOrder.findIndex((to) => to.taskId === currentTaskId);
  }

  const nextTaskOrderItem = assessment.taskOrder.get(currentTaskIndex + 1);

  if (!nextTaskOrderItem) {
    return <SubmitButton />;
  }

  return (
    <Button
      onClick={switchTask({
        taskId: nextTaskOrderItem.taskId,
        query,
        history,
        isPublicPreview,
      })}
      flexGrow={1}
      testTag="next-task-button"
      borderRadiusOverride="none"
    >
      Next
    </Button>
  );
};
