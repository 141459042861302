import { DateString } from 'src/modules/Time';
import { UserColor } from 'src/styles';
import { AvatarSkin, AvatarMouth, AvatarEyes, AvatarHat, AvatarFeature } from 'src/models/Avatar';
import { ImageStoredFile } from 'src/models/ImageStoredFile';
import { GradeLevel } from './GradeLevel';

export type AccountType = 'student' | 'teacher' | 'admin' | 'moderator';
export type EmailConfirmationStatus = 'needed' | 'wanted' | 'not_needed' | ' confirmed';
export type OnboardingStatus = 'needed' | 'skipped' | 'completed';
export type OnboardingQuestionStatus = 'unanswered' | 'no' | 'yes';

export const ADMIN_ACCOUNT_TYPES: AccountType[] = ['admin'];
export const MODERATOR_ACCOUNT_TYPES: AccountType[] = ADMIN_ACCOUNT_TYPES.concat(['moderator']);
export const TEACHER_ACCOUNT_TYPES: AccountType[] = MODERATOR_ACCOUNT_TYPES.concat(['teacher']);

export const DEFAULT_TEXT_SIZE = 16;

type UserStatus = 'active' | 'deleted' | 'pre_registered';
type StreakStatus = 'active' | 'expiring' | 'none';

export type PrepareParams = {
  accountType?: AccountType;
  courseCode?: string;
  courseCodeDeclined?: boolean;
  name?: string;
  email?: string;
  referralCode?: string;
};

export type Flags = {
  isCoTeachersEnabled: boolean;
  isAdvancedAnalyticsEnabled: boolean;
  isFullTranscriptionEnabled: boolean;
  isPrioritizedSupportEnabled: boolean;
  isExtendedDateFilteringEnabled: boolean;
  isDataVisualizationEnabled: boolean;
  isAllDateFilteringEnabled: boolean;
  isReadleeLibraryContentEnabled: boolean;
  isCsvExportEnabled: boolean;
  isVocabularyEnabled: boolean;
  isComprehensionTranscriptEnabled: boolean;
  isTeacherFeedbackEnabled: boolean;
  isStudentLibraryEnabled: boolean;
  isStudentHomepageLibraryEnabled: boolean;
};

export const avatarFeatureToUserField = (kind: AvatarFeature) => {
  switch (kind) {
    case 'eyes':
      return 'avatarEyes';
    case 'hat':
      return 'avatarHat';
    case 'mouth':
      return 'avatarMouth';
    case 'skin':
      return 'avatarSkin';
  }
};

export type AvatarProps = {
  avatarSkin: AvatarSkin;
  avatarMouth: AvatarMouth;
  avatarEyes: AvatarEyes;
  avatarHat: AvatarHat;
};

export type User = AvatarProps & {
  id: string;
  paperId: number | null;
  paperDistrictId: number | null;
  username: string;
  name: string | null;
  givenName: string | null;
  familyName: string | null;
  avatarColor: UserColor | null;
  displayName: string;
  email: string | null;
  accountType: AccountType;
  emailConfirmationStatus: EmailConfirmationStatus;
  completionPercentageEnabled: boolean;
  accuracyEnabled: boolean;
  wordsPerMinuteEnabled: boolean;
  textSize: number;
  lastActiveAt: string;
  isSso: boolean;
  hasGoogleSso: boolean;
  hasCleverSso: boolean;
  status: UserStatus;
  googleSyncedAt: string | null;
  cleverSyncedAt: string | null;
  onboardingStatus: OnboardingStatus;
  trialMonthsAvailable: number;
  positions: string;
  teachesEll: OnboardingQuestionStatus;
  teachesSpecialEducation: OnboardingQuestionStatus;
  ellPercentage: number | null;
  createdAt: string;
  updatedAt: string;
  currentStreak: number;
  currentStreakStatus: StreakStatus;
  currentStreakEndsOn: DateString | null;
  ultimateAssessmentsAvailable: number;
  pointBalance: number;
  paperSyncedAt: string | null;
  paperRosterSyncStatus: PaperRosterSyncStatus;
  paperRosterSyncedAt: DateString | null;
  paperRosterAutoSyncCount: number;
  paperRosterManualSyncCount: number;
};

export type PaperRosterSyncStatus = 'not_needed' | 'needed' | 'started' | 'finished' | 'errored';

export type ExtendedUser = User & {
  flags: Flags;
  profileImageFile: ImageStoredFile | null;
  paperGradeLevel: GradeLevel | null;
};

export type SignupUser = User & {
  firstName: string;
  lastName: string;
  newPassword: string;
  passwordConfirmation: string;
  textSize: number;
};

export const emptyUser: User = {
  id: '',
  paperId: null,
  paperDistrictId: null,
  username: '',
  name: '',
  avatarColor: null,
  displayName: '',
  givenName: null,
  familyName: null,
  email: '',
  accountType: 'student',
  emailConfirmationStatus: 'needed',
  completionPercentageEnabled: true,
  accuracyEnabled: true,
  wordsPerMinuteEnabled: true,
  textSize: DEFAULT_TEXT_SIZE,
  lastActiveAt: new Date().toString(),
  isSso: false,
  hasGoogleSso: false,
  hasCleverSso: false,
  status: 'active',
  googleSyncedAt: null,
  cleverSyncedAt: null,
  createdAt: new Date().toString(),
  updatedAt: new Date().toString(),
  onboardingStatus: 'needed',
  trialMonthsAvailable: 0,
  positions: '',
  teachesEll: 'unanswered',
  teachesSpecialEducation: 'unanswered',
  ellPercentage: null,
  avatarSkin: 'blue',
  avatarMouth: 'smile',
  avatarEyes: 'regular',
  avatarHat: 'nothing',
  currentStreak: 0,
  currentStreakStatus: 'none',
  currentStreakEndsOn: null,
  ultimateAssessmentsAvailable: 0,
  pointBalance: 0,
  paperSyncedAt: null,
  paperRosterSyncStatus: 'not_needed',
  paperRosterSyncedAt: null,
  paperRosterAutoSyncCount: 0,
  paperRosterManualSyncCount: 0,
};

export const emptyExtendedUser: ExtendedUser = {
  ...emptyUser,
  profileImageFile: null,
  flags: {
    isCoTeachersEnabled: false,
    isAdvancedAnalyticsEnabled: false,
    isFullTranscriptionEnabled: false,
    isPrioritizedSupportEnabled: false,
    isExtendedDateFilteringEnabled: false,
    isDataVisualizationEnabled: false,
    isAllDateFilteringEnabled: false,
    isReadleeLibraryContentEnabled: false,
    isCsvExportEnabled: false,
    isVocabularyEnabled: false,
    isComprehensionTranscriptEnabled: false,
    isTeacherFeedbackEnabled: false,
    isStudentLibraryEnabled: false,
    isStudentHomepageLibraryEnabled: false,
  },
  paperGradeLevel: null,
};

export const emptySignupUser: SignupUser = {
  ...emptyUser,
  firstName: '',
  lastName: '',
  newPassword: '',
  passwordConfirmation: '',
  emailConfirmationStatus: 'needed',
  lastActiveAt: new Date().toString(),
};

export type SetSignupUserField = <K extends keyof SignupUser>(
  name: K,
) => (value: SignupUser[K]) => void;

export const getLmsLabel = (student: User) => {
  if (student.hasGoogleSso) {
    return 'Google';
  }
  if (student.hasCleverSso) {
    return 'Clever';
  }
  return '-';
};

export const formatName = (student: User) => {
  if (student.familyName && student.givenName) {
    return `${student.familyName}, ${student.givenName}`;
  } else if (student.familyName) {
    return student.familyName;
  } else if (student.givenName) {
    return student.givenName;
  } else if (student.displayName) {
    return student.displayName;
  } else {
    return '--';
  }
};

export const adminOrGreater = (accountType: AccountType) => {
  return ADMIN_ACCOUNT_TYPES.includes(accountType);
};

export const moderatorOrGreater = (accountType: AccountType) => {
  return MODERATOR_ACCOUNT_TYPES.includes(accountType);
};

export const teacherOrGreater = (accountType: AccountType) => {
  return TEACHER_ACCOUNT_TYPES.includes(accountType);
};
