import { useEffect, useState } from 'react';
import { GoogleButton } from 'src/components';
import { makeQueryString, useFetch, ErrorMap } from 'src/modules/Api';
import { useHistory, useQuery } from 'src/modules/Router';
import { GoogleOauthResponse, GoogleScopeKey } from 'src/modules/Google';
import { useStore } from 'src/Store';
import { ImmutableList, ImmutableMap } from 'src/modules/Immutable';
import { SimpleLayout } from 'src/layouts';

export const GoogleOauthCallback = () => {
  const history = useHistory();
  const query = useQuery();
  const [loading, setLoading] = useState(true);
  const [errored, setErrored] = useState(false);
  const [successUrl, setSuccessUrl] = useState<string>();
  const [attemptedScopeKey, setAttemptedScopeKey] = useState<GoogleScopeKey>();
  const [requiredScopeKey, setRequiredScopeKey] = useState<GoogleScopeKey>();
  const { fetchJson } = useFetch();

  const { onLoggedIn, onSignedUp } = useStore(
    (state) => ({
      onLoggedIn: state.AppData.onLoggedIn,
      onSignedUp: state.AppData.onSignedUp,
    }),
    [],
  );
  const code = query.get('code');
  const oauthComplete = query.get('oauthComplete');
  useEffect(() => {
    setLoading(true);
    if (!(code || oauthComplete)) {
      const message = `Google login failed.`;
      history.replace(
        `/500?message=${encodeURIComponent(message)}&originalPath=${encodeURIComponent('/login')}`,
      );
    }

    fetchJson(`/api/google_oauths`, {
      method: 'POST',
      data: {
        code,
      },
      onSuccess: (data: GoogleOauthResponse) => {
        if (data.status === 'success') {
          if (data.user && data.isNew) {
            onSignedUp({
              redirectPath: data.successUrl,
              replace: true,
              history,
              method: 'google',
              user: data.user,
              schools: ImmutableList(),
            });
          } else {
            onLoggedIn({
              redirectPath: data.successUrl,
              replace: true,
              history,
              method: 'google',
            });
          }
        } else if (data.status === 'oauth_required') {
          history.replace(
            `/login${makeQueryString({
              redirectPath: data.successUrl ?? '',
            })}`,
          );
        } else if (data.status === 'errored') {
          setErrored(true);
          setLoading(false);
          setSuccessUrl(data.successUrl);
          setAttemptedScopeKey(data.scopeKey);
        } else if (data.status === 'fields_missing') {
          const errors: ErrorMap = ImmutableMap(data.errors || {});
          if (errors.has('accountType')) {
            history.replace(
              `/sign-up${makeQueryString({
                sso: 'google',
                ssoName: 'Google',
                redirectPath: data.successUrl ?? '',
              })}`,
            );
          } else if (errors.has('email') && errors.has('name')) {
            setRequiredScopeKey('full_info');
            setLoading(false);
          } else if (errors.has('email')) {
            setRequiredScopeKey('just_email');
            setLoading(false);
          } else if (errors.has('name')) {
            setRequiredScopeKey('just_name');
            setLoading(false);
          } else {
            history.replace(
              `/sign-up/${data.user?.accountType ?? 'student'}/register${makeQueryString({
                sso: 'google',
                ssoName: 'Google',
                fieldsMissing: Array.from(errors.keys()).join(','),
                errors: JSON.stringify(data.errors || {}),
                email: data.user?.email ? data.user.email : '',
                redirectPath: data.successUrl ?? '',
              })}`,
            );
          }
        } else if (data.status === 'needs_email_confirmation') {
          history.replace(
            `/confirmation-sent${makeQueryString(
              data.user?.email
                ? {
                    email: data.user.email,
                    firstName: data.user.displayName,
                  }
                : {},
            )}`,
          );
        }
      },
    });
  }, [code, oauthComplete, history, onLoggedIn, onSignedUp, fetchJson]);
  return (
    <SimpleLayout loading={loading}>
      {requiredScopeKey && (
        <>
          We need a bit more information from Google to complete your account, please authorize
          again:
          <GoogleButton scopeKey={requiredScopeKey} successUrl={successUrl} forceOauth>
            Authorize with Google
          </GoogleButton>
        </>
      )}
      {errored && attemptedScopeKey && (
        <>
          There was an error trying to login, please click the button below to try again:{' '}
          <GoogleButton scopeKey={attemptedScopeKey} successUrl={successUrl} forceOauth>
            Try Again
          </GoogleButton>
        </>
      )}
    </SimpleLayout>
  );
};
