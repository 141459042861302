import { ReactNode } from 'react';
import { Flex, Tooltip, LinkButton, Text, Icon, Spacer } from 'src/components';

type LinkLocation = 'plans' | 'contact';

export const PremiumTooltip = ({
  children,
  enabled,
  content,
  display,
  linkLocation,
  onClick,
  usageCapsEnabled,
  availableUltimateAssessments = 0,
}: {
  children: ReactNode;
  enabled: boolean;
  content: string;
  linkLocation: LinkLocation;
  display?: 'block' | 'inline-block' | 'inline' | 'flex';
  onClick?: () => void;
  usageCapsEnabled?: boolean;
  availableUltimateAssessments?: number;
}) => {
  if (usageCapsEnabled && availableUltimateAssessments > 0) {
    return (
      <Tooltip
        display={display}
        content={
          <>
            <Flex align="center">
              <Text color="black">Use an Ultimate Assignment</Text>
              <Spacer horizontal size={1} />
              <Icon icon="ultimateAssignmentCentered" size="1.2em" />
              <Spacer horizontal size={1} />
              <Text>to access this feature!</Text>
            </Flex>
            <Flex width="100%" justify="center" align="center">
              <LinkButton onClick={onClick} display="inline" flexDisplay="inline-flex">
                Upgrade Assignment
              </LinkButton>{' '}
              <Text color="black">({availableUltimateAssessments} remaining)</Text>
            </Flex>
          </>
        }
        disabled={!enabled}
      >
        {children}
      </Tooltip>
    );
  }

  return (
    <Tooltip
      display={display}
      content={
        <Text color="black">
          {content}{' '}
          <LinkButton
            to={linkLocation !== 'contact' ? '/t/plans' : undefined}
            display="inline"
            flexDisplay="inline-flex"
            href={linkLocation === 'contact' ? 'https://www.readlee.com/contact' : undefined}
            newTab={linkLocation === 'contact'}
          >
            {linkLocation === 'contact' ? 'Contact Us!' : 'View Plans and Pricing'}
          </LinkButton>
        </Text>
      }
      disabled={!enabled}
    >
      {children}
    </Tooltip>
  );
};
