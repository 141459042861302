import { padStart } from 'lodash';
import { capitalizeAll, pluralize } from './String';

export const MILLISECONDS_IN_SECOND = 1000;
export const SECONDS_IN_MINUTE = 60;
export const MINUTES_IN_HOUR = 60;
export const HOURS_IN_DAY = 24;
export const DAYS_IN_WEEK = 7;
export const MONTHS_IN_YEAR = 12;
export const APPROX_DAYS_IN_MONTH = 30;
export const MILLISECONDS_IN_MINUTE = MILLISECONDS_IN_SECOND * SECONDS_IN_MINUTE;
export const MILLISECONDS_IN_DAY =
  MILLISECONDS_IN_SECOND * SECONDS_IN_MINUTE * MINUTES_IN_HOUR * HOURS_IN_DAY;

const today = new Date();
today.setHours(0, 0, 0, 0);
export { today };

export const isSameDay = (date1: Date, ...otherDates: Date[]) => {
  return otherDates.every(
    (d) =>
      date1.getFullYear() === d.getFullYear() &&
      date1.getMonth() === d.getMonth() &&
      date1.getDate() === d.getDate(),
  );
};
export const isToday = (date: Date) => isSameDay(today, date);

const shortenedMonthNames = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

const dayNames = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
] as const;
export type DayName = (typeof dayNames)[number];

export const getShortenedMonthName = (date: Date) => {
  return shortenedMonthNames[date.getMonth()];
};
export const getDayName = (date: Date): DayName => {
  const name = dayNames[date.getDay()];
  if (!name) throw new Error(`Couldn't get name of day ${date.getDay()}`);
  return name;
};

export const isWeekend = (date: Date): boolean => {
  return getDayName(date) === 'Saturday' || getDayName(date) === 'Sunday';
};

export const formatDate = (
  date: Date,
  { includeYear = false, includeYearIfNotCurrent = false } = {},
) => {
  return `${getShortenedMonthName(date)} ${date.getDate()}${
    includeYear || (includeYearIfNotCurrent && date.getFullYear() !== new Date().getFullYear())
      ? `, ${date.getFullYear()}`
      : ''
  }`;
};

export const formatTimestamp = (date: Date) => {
  return date.toISOString().split('T')[0];
};

export const formatTime = (date: Date) => {
  return `${date.getHours() % 12 || 12}:${padStart(String(date.getMinutes()), 2, '0')}${
    date.getHours() >= 12 ? 'pm' : 'am'
  }`;
};

export const formatDateTime = (date: Date) => {
  return `${formatDate(date)}, ${formatTime(date)}`;
};

export const formatTimeAgoHelper = (date: Date, { ago }: { ago: boolean }) => {
  const now = new Date();
  const diff = now.valueOf() - date.valueOf();

  const secondDiff = Math.floor(diff / MILLISECONDS_IN_SECOND);
  const minuteDiff = Math.floor(secondDiff / SECONDS_IN_MINUTE);
  const hourDiff = Math.floor(minuteDiff / MINUTES_IN_HOUR);
  const dayDiff = Math.floor(hourDiff / HOURS_IN_DAY);
  const weekDiff = Math.floor(dayDiff / DAYS_IN_WEEK);
  const monthDiff = Math.floor(dayDiff / APPROX_DAYS_IN_MONTH);

  const agoText = ago ? ` ago` : '';

  if (monthDiff >= 12) {
    return `${pluralize('year', Math.floor(monthDiff / 12))}${agoText}`;
  }

  if (monthDiff > 1) {
    return `${pluralize('month', monthDiff)}${agoText}`;
  }
  if (weekDiff > 0) {
    return `${pluralize('week', weekDiff)}${agoText}`;
  }
  if (dayDiff === 1) {
    return `yesterday`;
  }
  if (dayDiff > 0) {
    return `${pluralize('day', dayDiff)}${agoText}`;
  }
  if (hourDiff > 0) {
    return `${pluralize('hour', hourDiff)}${agoText}`;
  }
  if (minuteDiff > 0) {
    return `${pluralize('min', minuteDiff)}${agoText}`;
  }
  return 'just now';
};

export const formatTimeAgo = (
  date: Date,
  { ago = true, capitalizeAll: capAll = false }: { ago?: boolean; capitalizeAll?: boolean } = {},
) => {
  return capAll
    ? capitalizeAll(formatTimeAgoHelper(date, { ago }))
    : formatTimeAgoHelper(date, { ago });
};

export const addDays = (date: Date, days: number) => {
  const newDate = new Date(date);
  newDate.setDate(newDate.getDate() + days);
  return newDate;
};

export const formatTimeFromNow = (date: Date) => {
  const now = new Date();
  const tomorrow = addDays(now, 1);
  const sixDaysFromToday = addDays(now, 6);

  if (
    date.getDate() === now.getDate() &&
    date.getMonth() === now.getMonth() &&
    date.getFullYear() === now.getFullYear()
  ) {
    return `at ${formatTime(date)}`;
  }
  if (
    date.getDate() === tomorrow.getDate() &&
    date.getMonth() === tomorrow.getMonth() &&
    date.getFullYear() === tomorrow.getFullYear()
  ) {
    return `tomorrow at ${formatTime(date)}`;
  }
  if (date > sixDaysFromToday) {
    return `${getDayName(date)} at ${formatTime(date)}`;
  } else {
    return `${formatDate(date)} at ${formatTime(date)}`;
  }
};

export const formatRecordingTime = (durationMillis: number) => {
  // const secondDiff = Math.floor(durationMillis / MILLISECONDS_IN_SECOND);
  // const minuteDiff = Math.floor(secondDiff / SECONDS_IN_MINUTE);
  // const hourDiff = Math.floor(minuteDiff / MINUTES_IN_HOUR);

  const secondDiff = Math.floor((durationMillis / MILLISECONDS_IN_SECOND) % SECONDS_IN_MINUTE);
  const minuteDiff = Math.floor(
    (durationMillis / (MILLISECONDS_IN_SECOND * SECONDS_IN_MINUTE)) % MINUTES_IN_HOUR,
  );
  const hourDiff = Math.floor(
    durationMillis / (MILLISECONDS_IN_SECOND * SECONDS_IN_MINUTE * MINUTES_IN_HOUR),
  );

  const secondsAndMinutes = `${minuteDiff.toString().padStart(1, '0')}:${secondDiff
    .toString()
    .padStart(2, '0')}`;

  if (hourDiff > 0) {
    return `${hourDiff}:${secondsAndMinutes}`;
  } else {
    return secondsAndMinutes;
  }
};

export const getMinutes = (milliseconds: number) =>
  milliseconds / MILLISECONDS_IN_SECOND / SECONDS_IN_MINUTE;

export const formatTimeAmount = (durationMillis: number) => {
  const durationMinutes = Math.floor(durationMillis / MILLISECONDS_IN_SECOND / SECONDS_IN_MINUTE);
  if (durationMinutes < MINUTES_IN_HOUR) {
    return `${durationMinutes} minutes`;
  } else {
    const durationHours = Math.floor(durationMinutes / MINUTES_IN_HOUR);
    return `${durationHours} hours`;
  }
};

export type Season = 'fall' | 'winter' | 'spring';

export const getSeason = (): Season => {
  const today = new Date();
  const month = today.getMonth();

  if (month >= 7 && month <= 10) {
    return 'fall';
  } else if (month >= 11 && month <= 1) {
    return 'winter';
  }
  return 'spring';
};

export const getTimeZone = () => {
  try {
    return {
      timeZoneStatus: 'assumed',
      timeZoneKey: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };
  } catch (_e) {
    return { timeZoneStatus: 'unknown' };
  }
};

type Digit = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9;
export type DateString = `${number}-${0 | 1}${Digit}-${0 | 1 | 2 | 3}${Digit}`;
// Calling new Date() on a date string (like '2022-07-18') will interpret the input as a UTC
// date, and the Date coming out will be converted to current time zone, causing it to be the
// wrong date if it's a negative offset time zone
export const parseDate = (dateString: DateString) => {
  const [year, baseMonth, date] = dateString.split('-').map((x) => parseInt(x, 10));
  if (typeof year !== 'number' || typeof baseMonth !== 'number' || typeof date !== 'number')
    throw new Error(`Invalid date string: '${dateString}'`);
  const month = baseMonth - 1;
  return new Date(year, month, date);
};
