import { useEffect } from 'react';

export const Title = ({ title }: { title: string }) => {
  useEffect(() => {
    document.title = `${title} | Readlee`;
    return () => {
      document.title = 'Readlee';
    };
  }, [title]);

  return null;
};
