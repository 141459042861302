import React, { useEffect } from 'react';
import { ImmutableList } from './Immutable';

export const findAncestor = (
  startElement: HTMLElement,
  fn: (elem: HTMLElement) => unknown,
): HTMLElement | null => {
  if (fn(startElement)) return startElement;
  if (!startElement.parentElement || startElement.parentElement === startElement) return null;

  return findAncestor(startElement.parentElement, fn);
};

// These events can burst hard, so I'd recomend throttling any function you pass here
export const addEventListenerToAllAncestors = (
  element: HTMLElement,
  eventType: keyof DocumentEventMap,
  fn: (event: Event) => void,
) => {
  const current = element.parentElement;
  if (!current) return;
  current.addEventListener(eventType, fn);
  addEventListenerToAllAncestors(current, eventType, fn);
};

export const removeEventListenerFromAllAncestors = (
  element: HTMLElement,
  eventType: keyof DocumentEventMap,
  fn: (event: Event) => void,
) => {
  const current = element.parentElement;
  if (!current) return;
  current.removeEventListener(eventType, fn);
  removeEventListenerFromAllAncestors(current, eventType, fn);
};

export const parseDataTransfer = (event: React.DragEvent) => {
  const files = [];
  if (event.dataTransfer.items) {
    for (const item of Array.from(event.dataTransfer.items)) {
      if (item.kind === 'file') {
        const file = item.getAsFile();
        if (file) {
          files.push(file);
        }
      }
    }
  } else {
    for (const file of Array.from(event.dataTransfer.files)) {
      files.push(file);
    }
  }

  return ImmutableList(files);
};

export const useExternalClick = (id: string, onExternalClickFn: (() => void) | undefined) => {
  useEffect(() => {
    if (!onExternalClickFn) return;

    const listener = (event: MouseEvent) => {
      const elem = event.target as HTMLElement;
      if (findAncestor(elem, (elem) => id === elem.id)) {
        return;
      }

      onExternalClickFn();
    };
    document.addEventListener('click', listener);

    return () => {
      document.removeEventListener('click', listener);
    };
  }, [id, onExternalClickFn]);
};
