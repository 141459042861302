import { Switch, Route, Redirect } from 'react-router-dom';
import styled from 'styled-components';

import { StudentDashboard } from 'src/pages/StudentDashboard/StudentDashboard';
import { UserProfile } from 'src/pages/UserProfile';
import { StudentLayoutContent } from 'src/layouts/StudentLayout';
import { useHistory, RedirectHomeOnAccountType } from 'src/modules/Router';
import { commonLayoutStyles } from 'src/modules/Layout';
import { TopBar } from 'src/layouts/TopBar';
import { Button, Title, WaitForUser } from 'src/components';
import { useStore } from 'src/Store';
import { AvatarBuilder } from 'src/pages/AvatarBuilder';
import { CenteredLayout } from 'src/layouts/CenteredLayout';
import { TeacherLayoutContent } from 'src/layouts/TeacherLayout/TeacherLayoutContent';
import { ContentLibrary } from 'src/pages/ContentLibrary';

const BaseStudentLayoutStyles = styled.div`
  ${commonLayoutStyles}

  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
`;

export const StudentLayout = () => {
  const {
    createFreeRead,
    freeReadLoading,
    isStudentHomepageLibraryEnabled,
    isStudentLibraryEnabled,
  } = useStore(
    (s) => ({
      createFreeRead: s.AssignmentBuilderData.createFreeRead,
      freeReadLoading: s.AssignmentBuilderData.freeReadLoading,
      isStudentLibraryEnabled: s.AppData.currentUser?.flags?.isStudentLibraryEnabled ?? false,
      isStudentHomepageLibraryEnabled:
        s.AppData.currentUser?.flags?.isStudentHomepageLibraryEnabled ?? false,
    }),
    [],
  );

  const history = useHistory();

  return (
    <BaseStudentLayoutStyles>
      <RedirectHomeOnAccountType accountTypes={['teacher']} />
      <TopBar
        mobileAction={
          <Button
            newTab
            color="primaryBlue"
            onClick={() => createFreeRead(history)}
            disabled={freeReadLoading}
            loading={freeReadLoading}
          >
            Free Read
          </Button>
        }
        alwaysShowSecondaryAction={false}
      />
      <Switch>
        <Route path="/s/profile" exact>
          <Title title="My Profile" />
          <UserProfile ContentElement={TeacherLayoutContent} />
        </Route>
        <Route path="/s/avatar" exact>
          <Title title="Edit Avatar" />
          <CenteredLayout>
            <AvatarBuilder ContentElement={StudentLayoutContent} />
          </CenteredLayout>
        </Route>
        <Route path="/s/assignments">
          <Title title="Assignments" />
          <StudentDashboard />
        </Route>
        <Route path="/s/library">
          <ContentLibrary />
        </Route>
        <WaitForUser>
          {isStudentLibraryEnabled ? (
            <Redirect to={isStudentHomepageLibraryEnabled ? '/s/library' : '/s/assignments'} />
          ) : (
            <Redirect to="/s/assignments" />
          )}
        </WaitForUser>
      </Switch>
    </BaseStudentLayoutStyles>
  );
};
