import styled from 'styled-components';
import { useParams, useHistory } from 'src/modules/Router';
import { useStore } from 'src/Store';
import { standardMeasurements, paddings } from 'src/styles';
import { Flex, Button, Modal } from 'src/components';
import { LmsConfirmation } from './LmsConfirmation';
import { ManualConfirmation } from './ManualConfirmation';

const Container = styled.div`
  position: relative;
  height: auto;
  overflow-y: auto;
`;

const ModalCloseButtonWrapper = styled.span`
  position: absolute;
  top: ${standardMeasurements[7]};
  right: ${standardMeasurements[7]};
`;

export const ClassCreatedConfirmationModal = () => {
  const history = useHistory();
  const courseId = useParams<{ courseId: string }>().courseId;
  const courses = useStore((s) => s.TeacherCourseListData.courses, []);
  const course = courses.find((c) => c.id === courseId);
  if (!course) return null;

  return (
    <Modal openType="state" opened maxWidthOverride="720px">
      <Container>
        <ModalCloseButtonWrapper>
          <Button
            color="gray3"
            icon="close"
            iconSize="0.75em"
            width="36px"
            height="36px"
            displayType="iconOnly"
            onClick={() => history.push('/t/classes')}
            testTag="close"
            paddingOverride={paddings.none}
          />
        </ModalCloseButtonWrapper>
        <Flex
          direction="column"
          justify="center"
          padding={`${paddings[7]} ${paddings[18]}`}
          overflowY="auto"
        >
          {course.googleSyncedAt && <LmsConfirmation lms="google" />}
          {course.cleverSyncedAt && <LmsConfirmation lms="clever" />}
          {!course.googleSyncedAt && !course.cleverSyncedAt && (
            <ManualConfirmation course={course} />
          )}
        </Flex>
      </Container>
    </Modal>
  );
};
