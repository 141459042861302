import { ImmutableList } from 'src/modules/Immutable';

export type VisibleRole =
  | 'student'
  | 'teacher'
  | 'pro_teacher'
  | 'school_license_teacher'
  | 'moderator';

export const visibleRoleOptions: ImmutableList<{ value: VisibleRole; label: string }> =
  ImmutableList([
    {
      value: 'student',
      label: 'Student',
    },
    {
      value: 'teacher',
      label: 'Teacher',
    },
    {
      value: 'pro_teacher',
      label: 'Pro Teacher',
    },
    {
      value: 'school_license_teacher',
      label: 'Ultimate Teacher',
    },
    {
      value: 'moderator',
      label: 'Moderator',
    },
  ]);

export type Playlist = {
  id: string;
  name: string;
  ranking: number;
  status: 'active' | 'archived' | 'deleted';
  visibleRoles: ImmutableList<VisibleRole>;
  enabled: boolean;
};

export const emptyPlaylist: () => Playlist = () => ({
  id: '',
  name: '',
  ranking: 0,
  status: 'active',
  visibleRoles: ImmutableList(),
  enabled: false,
});

export const CONTINUE_READING_PLAYLIST = (resultCount: number) => {
  return {
    id: 'continue-reading',
    name: 'Continue Reading',
    ranking: -1,
    status: 'active',
    visibleRoles: ImmutableList<VisibleRole>(['student']),
    enabled: resultCount > 0,
  } as const;
};
