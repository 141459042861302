import { formatRecordingTime } from 'src/modules/Time';
import {
  AudioPlayer,
  Button,
  Flex,
  Spacer,
  Icon,
  DrawerComponent,
  RecordingTime,
  VolumeMeter,
  AudioMenu,
  Tooltip,
  Text,
  RecordingStatus,
  BaseAudioRecorderProps,
} from 'src/components';
import { paddings } from 'src/styles';

export const DesktopRecorder = ({
  audioFile,
  status,
  startedAt,
  recordingEndedAt,
  volumeLevel,
  hasJustStarted,
  displayType = 'desktop',
  disabled = false,
  dirty = false,
  recordingSequence,
  onSave,
  openMicSettings,
  startRestart,
  startDeleting,
  stop,
  beginRecording,
}: BaseAudioRecorderProps) => {
  const saved = Boolean(audioFile) && (status === 'finished' || status === 'not_started') && !dirty;

  const doneRecording = audioFile && status !== 'recording' && status !== 'processing';

  return (
    <DrawerComponent displayType={displayType} saved={saved}>
      {doneRecording ? (
        <AudioPlayer
          src={audioFile.url}
          displayType={displayType}
          showSkipButtons
          trackMinWidth="150px"
          additionalControls={
            <>
              <Tooltip content="Continue Recording">
                <Button
                  onClick={beginRecording}
                  loading={status === 'loading'}
                  activeColor="white"
                  color="warning"
                  disabled={disabled}
                  borderRadiusOverride="10"
                  paddingOverride={`${paddings[2]} ${paddings[3]}`}
                >
                  <Icon icon="microphone" color="white" strokeWidth={0} size="1.15em" />
                  <Spacer horizontal />
                  <Text weightOverride="bold" color="white">
                    Continue
                  </Text>
                </Button>
              </Tooltip>
              <Spacer horizontal size={2} />
              {dirty && onSave ? (
                <Tooltip content="Save Recording">
                  <Button
                    displayType="outline"
                    onClick={onSave}
                    loading={status === 'loading'}
                    disabled={disabled}
                    color="gray7"
                    borderRadiusOverride="10"
                    paddingOverride={`${paddings[2]} ${paddings[3]}`}
                    testTag="save-recording"
                  >
                    <Icon icon="save" color="black" size="1.15em" />
                    <Spacer horizontal />
                    <Text weightOverride="bold" color="black">
                      Save
                    </Text>
                  </Button>
                </Tooltip>
              ) : (
                <Spacer horizontal size={2} />
              )}
              <Spacer horizontal size={3} />
              <AudioMenu
                status={status}
                audioFile={audioFile}
                startDeleting={startDeleting}
                startRestart={startRestart}
                openMicSettings={openMicSettings}
              />
            </>
          }
        />
      ) : (
        <Flex justify="space-evenly" align="center">
          <RecordingStatus
            $color={status === 'recording' ? 'danger' : 'gray5'}
            $recording={status === 'recording'}
          />
          <Spacer horizontal size={4} />
          <>
            {status === 'recording' ? (
              <RecordingTime startedAt={startedAt} recordingLength={recordingSequence.duration} />
            ) : (
              <Text variant="largeText" testTag="recording-length">
                {formatRecordingTime(
                  Math.max(
                    recordingEndedAt.valueOf() - startedAt.valueOf() + recordingSequence.duration,
                    0,
                  ),
                )}
              </Text>
            )}
            <Spacer horizontal size={5} />
            <VolumeMeter displayType="desktop" volumeLevel={volumeLevel} />
            <Spacer horizontal size={3} />
            {status === 'recording' || status === 'processing' ? (
              <Button
                loading={status === 'processing'}
                color="warning"
                borderRadiusOverride="10"
                paddingOverride={`${paddings[2]} ${paddings[3]}`}
                disabled={disabled || hasJustStarted}
                onClick={stop}
                testTag={
                  status === 'recording' ? 'stop-recording-button' : 'start-recording-button'
                }
              >
                <Icon icon="pause" color="white" size="24px" /> <Spacer horizontal />
                <Text weightOverride="bold" color="white">
                  Pause
                </Text>
              </Button>
            ) : (
              <Button
                color="danger"
                loading={status === 'loading'}
                onClick={beginRecording}
                borderRadiusOverride="10"
                testTag="start-recording-button"
              >
                <Icon icon="microphone" strokeWidth={0} size="1.15em" />
                <Spacer horizontal />
                Record
              </Button>
            )}
          </>
          <Spacer horizontal size={20} />
          <AudioMenu
            status={status}
            audioFile={audioFile}
            startDeleting={startDeleting}
            startRestart={startRestart}
            openMicSettings={openMicSettings}
          />
        </Flex>
      )}
    </DrawerComponent>
  );
};
