import { ErrorMap, emptyErrorMap } from 'src/modules/Api';
import { History, QueryMap } from 'src/modules/Router';
import { SetField } from 'src/State';

export type SignUpSchoolCodeState = {
  setField: SetField<SignUpSchoolCodeState>;
  schoolCode: string;
  submitting: boolean;
  errors: ErrorMap;
  load: () => void;
  submit: (opts: {
    history: History;
    query: QueryMap;
    schoolCode: string;
    replace?: boolean;
  }) => () => void;
};

export const defaultState = {
  submitting: false,
  errors: emptyErrorMap(),
  schoolCode: '',
};
