import { Button } from 'src/components';
import { ReadingStudioLayoutTopBar } from 'src/layouts';
import { useStore } from 'src/Store';
import { useReadingTaskDetails } from './AssignmentBuilderHelpers';

export const AssignmentBuilderTopBar = () => {
  const { assessment, isRecording } = useStore(
    ({ AssignmentBuilderData: s, AppData: a }) => ({
      assessment: s.assessment,
      isRecording: a.isRecording,
    }),
    [],
  );
  const { coverImageFile, reading, author } = useReadingTaskDetails() ?? {};

  return (
    <ReadingStudioLayoutTopBar
      rightButtons={
        <>
          {assessment && reading && (
            <Button
              displayType="outline"
              to={`/t/reading-builder?assessmentId=${assessment.id}&readingId=${reading.id}`}
              disabled={isRecording}
            >
              {reading.contentType === 'independent' ? 'Add Content' : 'Edit Text'}
            </Button>
          )}
        </>
      }
      thumbnailUrl={coverImageFile?.thumbnailUrl}
      readingTitle={reading?.name}
      authorName={author?.name}
      webSourceUrl={reading?.webSourceUrl}
    />
  );
};
