import { subDataCreator } from 'src/State';
import { defaultState } from './SchoolSearchState';
import { ErrorMapResponse } from 'src/modules/Api';
import { USStateAbbreviation } from 'src/modules/Location';
import { ImmutableList, ImmutableMap } from 'src/modules/Immutable';
import { School, District } from 'src/models';
import { debounce } from 'lodash';
import { QueryMap, queryMapToString } from 'src/modules/Router';
import { AppResponse } from 'src/AppState';

type SearchResponse = {
  schools: ImmutableList<School>;
  districtMap: ImmutableMap<string, District>;
};

type SaveSchoolData = {
  success: boolean;
  errors: ErrorMapResponse;
  school: School;
};

export const createDefaultSchoolSearchData = subDataCreator(
  'SchoolSearchData',
  defaultState,
  ({ get, set, setField, fetchJson }) => {
    const search = debounce((query: string, stateAbbreviation: USStateAbbreviation | undefined) => {
      if (query.length === 0) return;

      set({ searching: true, waitingToSearch: false });
      fetchJson(`/api/schools/search`, {
        method: 'POST',
        data: { query, stateAbbreviation },
        onSuccess: (data: SearchResponse) => {
          set((state) => ({
            schoolResults: data.schools,
            districtMap: state.districtMap.merge(data.districtMap),
            searching: false,
          }));
        },
      });
    }, 200);

    return {
      ...defaultState,
      setField,
      load: () => {
        set(defaultState);
        fetchJson(`/api/users/me`, {
          onSuccess: (data: AppResponse) => {
            set({
              districtMap: data.districtMap,
              schoolDomains: data.schoolDomains,
              suggestedSchoolMap: data.suggestedSchoolMap,
            });
          },
        });
      },
      setSchool: (school, onSuccess) => {
        set({ schools: ImmutableList([school]) });
        onSuccess();
      },
      setSchoolQuery: (value: string) => {
        set({ schoolQuery: value, schoolResults: ImmutableList(), waitingToSearch: value !== '' });
        search(value, get().stateAbbreviation);
      },
      setStateAbbreviation: (stateAbbreviation) => {
        set({ stateAbbreviation });
        search(get().schoolQuery, stateAbbreviation);
      },
      submitSchool: (history, query: QueryMap) => {
        const { currentUser, schools } = get();
        if (!currentUser || schools.size === 0) return;
        fetchJson(`/api/users/${currentUser.id}/update_schools`, {
          method: 'PATCH',
          data: { onboardingStatus: 'completed', schoolIds: schools.map((s) => s.id) },
          onSuccess: () => {
            history.push(`/t/onboarding/questions${queryMapToString(query)}`);
          },
        });
      },
      setManualSchoolField: (key) => (value) => {
        set((state) => ({ manualSchool: { ...state.manualSchool, [key]: value } }));
      },
      saveManualSchool: (onSuccess) => {
        const { manualSchool } = get();
        set({ saving: true });
        fetchJson(`/api/schools`, {
          method: 'POST',
          data: { school: manualSchool },
          onSuccess: (data: SaveSchoolData) => {
            if (data.success) {
              set({
                schools: ImmutableList([data.school]),
                manualSchool: data.school,
                saving: false,
                manualSchoolErrors: ImmutableMap(),
              });
              onSuccess();
            } else {
              set({
                manualSchoolErrors: ImmutableMap(data.errors),
                saving: false,
              });
            }
          },
        });
      },
    };
  },
);
