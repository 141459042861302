import React, { FormEvent, useCallback } from 'react';
import { cssIfTruthy, DollarPrefix, Height } from 'src/styles';
import styled from 'styled-components';
import { PromptBeforeLeave, FlexDirection } from 'src/components';

type FormCssProps = {
  /** Display CSS property */
  display?: 'block' | 'flex' | 'inline' | 'inline-block' | 'inline-flex';
  /** Amount to grow if this form element is in a flex container */
  grow?: number;
  minHeight?: Height;
  flexDirection?: FlexDirection;
  height?: Height;
};

type FormProps = FormCssProps & {
  /** Event handler for form submit event */
  onSubmit: React.FormEventHandler<HTMLFormElement>;
  children: React.ReactNode;
  /** Allows default form submit handling, instead of the custom event handler */
  forceDefault?: boolean;
  /** Optionally monitor if form has changed */
  dirty?: boolean;
};

const FormStyles = styled.form<DollarPrefix<FormCssProps>>`
  display: ${({ $display = 'block' }) => $display};
  ${({ $grow }) => (typeof $grow === 'number' ? `flex-grow: ${$grow};` : '')};
  ${({ $minHeight }) => ($minHeight || $minHeight === 0 ? `min-height: ${$minHeight};` : '')}
  ${({ $flexDirection }) => ($flexDirection ? `flex-direction: ${$flexDirection}` : '')};
  ${({ $height }) => cssIfTruthy('height', $height)}
`;

export const Form = ({
  onSubmit,
  children,
  dirty = false,
  forceDefault = false,
  display,
  grow,
  minHeight,
  flexDirection,
  height,
}: FormProps) => {
  const realOnSubmit = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      if (!forceDefault) event.preventDefault();
      return onSubmit(event);
    },
    [onSubmit, forceDefault],
  );

  return (
    <FormStyles
      onSubmit={realOnSubmit}
      $display={display}
      $grow={grow}
      $minHeight={minHeight}
      $flexDirection={flexDirection}
      $height={height}
    >
      <PromptBeforeLeave dirty={dirty} />
      {children}
    </FormStyles>
  );
};
