import { Playlist } from 'src/models/Playlist';
import { emptyErrorMap, ErrorMap } from 'src/modules/Api';
import { ImmutableList } from 'src/modules/Immutable';
import { SetField } from 'src/State';

export type PlaylistManagerState = {
  loading: boolean;
  playlists: ImmutableList<Playlist>;
  editingPlaylist: Playlist | null;
  deletingPlaylist: Playlist | null;
  errors: ErrorMap;
  load: () => void;
  setField: SetField<PlaylistManagerState>;
  setEditingPlaylistField: <K extends keyof Playlist>(key: K) => (value: Playlist[K]) => void;
  savePlaylist: () => void;
  startCreate: () => void;
  confirmDelete: () => void;
  updatePlaylistRank: (playlist: Playlist, ranking: number) => void;
};

export const defaultState = {
  loading: true,
  playlists: ImmutableList<Playlist>(),
  editingPlaylist: null,
  deletingPlaylist: null,
  errors: emptyErrorMap(),
};
