import { SetField } from 'src/State';
import { CreateToast } from 'src/components';
import { ErrorMap, emptyErrorMap } from 'src/modules/Api';

export type ChangePasswordModalState = {
  opened: boolean;
  saving: boolean;
  currentPassword: string;
  newPassword: string;
  passwordConfirmation: string;
  errors: ErrorMap;
  openModal: () => void;
  closeModal: () => void;
  setField: SetField<ChangePasswordModalState>;
  changePassword: (createToast: CreateToast) => void;
};

export const defaultState = {
  opened: false,
  saving: false,
  currentPassword: '',
  newPassword: '',
  passwordConfirmation: '',
  errors: emptyErrorMap(),
};
