import { subDataCreator } from 'src/State';
import { choice, ImmutableList, ImmutableSet, setToggle } from 'src/modules/Immutable';
import {
  defaultState,
  ImportGoogleCourseResponse,
  SubmitImportGoogleCourseResponse,
} from './ImportGoogleCourseState';
import { classColors } from 'src/styles';
import { editCoursePath } from 'src/models';
import { trackCourseCreated, trackCourseImported } from 'src/modules/Analytics/AnalyticsEvents';

export const createDefaultImportGoogleCourseData = subDataCreator(
  'ImportGoogleCourseData',
  defaultState,
  ({ set, get, fetchJson, getFull }) => ({
    load: () => {
      set(defaultState);
      fetchJson(`/api/google_courses`, {
        onSuccess: (data: ImportGoogleCourseResponse) => {
          set({
            hasScopes: data.hasScopes,
            googleCourses: data.googleCourses.map((c) => ({
              ...c,
              avatarColor: data.courseMap.get(c.id, {
                avatarColor: choice(ImmutableList(classColors)),
              }).avatarColor,
            })),
            courseMap: data.courseMap,
            studentCountMap: data.studentCountMap,
            selectedGoogleCourseIds: ImmutableSet(data.googleCourses.map((c) => c.id)),
            loading: false,
          });
        },
      });
    },
    submit: (history, pathname, query) => () => {
      set({ submitting: true });
      const { googleCourses, selectedGoogleCourseIds, courseMap: originalCourseMap } = get();
      fetchJson(`/api/google_courses`, {
        method: 'POST',
        data: {
          googleCourses: googleCourses
            .filter((c) => selectedGoogleCourseIds.has(c.id))
            .map(({ id, avatarColor }) => ({ id, avatarColor })),
        },
        onSuccess: ({ success, courses }: SubmitImportGoogleCourseResponse) => {
          if (success) {
            getFull().TeacherCourseListData.load({
              history,
              pathname,
              onSuccess: () => {
                history.replace(
                  editCoursePath(
                    'create',
                    query,
                    courses.map((c) => c.id),
                  ),
                );
                set({ submitting: false });
              },
            });
            const originalCourseIds = originalCourseMap.map((oc) => oc.id);
            courses
              .filter((c) => !originalCourseIds.includes(c.id))
              .forEach((course) => {
                trackCourseImported({ course });
                trackCourseCreated({ course });
              });
          } else {
            set({
              hasScopes: false,
            });
          }
        },
      });
    },
    setAll: () => {
      set((s) => ({
        selectedGoogleCourseIds: s.selectedGoogleCourseIds.isEmpty()
          ? ImmutableSet(s.googleCourses.map((c) => c.id))
          : ImmutableSet(),
      }));
    },
    setSelected: (googleCourseId) => () => {
      set((state) => ({
        selectedGoogleCourseIds: setToggle(state.selectedGoogleCourseIds, googleCourseId),
      }));
    },
  }),
);
