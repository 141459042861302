import { subDataCreator } from 'src/State';
import { choice, ImmutableList, ImmutableSet, setToggle } from 'src/modules/Immutable';
import {
  defaultState,
  ImportCleverCourseResponse,
  SubmitImportCleverCourseResponse,
} from './ImportCleverCourseState';
import { classColors } from 'src/styles';
import { editCoursePath } from '../../models/Course';
import { trackCourseCreated, trackCourseImported } from 'src/modules/Analytics/AnalyticsEvents';

export const createDefaultImportCleverCourseData = subDataCreator(
  'ImportCleverCourseData',
  defaultState,
  ({ set, get, fetchJson, getFull }) => ({
    load: () => {
      set(defaultState);
      fetchJson(`/api/clever_courses`, {
        onSuccess: (data: ImportCleverCourseResponse) => {
          set({
            hasScopes: data.hasScopes,
            cleverSections: data.cleverSections.map((s) => ({
              ...s,
              avatarColor: data.courseMap.get(s.id, {
                avatarColor: choice(ImmutableList(classColors)),
              }).avatarColor,
            })),
            courseMap: data.courseMap,
            selectedCleverSectionIds: ImmutableSet(data.cleverSections.map((s) => s.id)),
            loading: false,
          });
        },
      });
    },
    submit: (history, pathname, query) => () => {
      set({ submitting: true });
      const { selectedCleverSectionIds, cleverSections, courseMap: originalCourseMap } = get();
      fetchJson(`/api/clever_courses`, {
        method: 'POST',
        data: {
          cleverSections: cleverSections
            .filter((s) => selectedCleverSectionIds.has(s.id))
            .map((s) => ({ id: s.id, avatarColor: s.avatarColor })),
        },
        onSuccess: ({ success, courses }: SubmitImportCleverCourseResponse) => {
          if (success) {
            getFull().TeacherCourseListData.load({
              history,
              pathname,
              onSuccess: () => {
                history.replace(
                  editCoursePath(
                    'create',
                    query,
                    courses.map((c) => c.id),
                  ),
                );
                set({ submitting: false });
              },
            });
            const originalCourseIds = originalCourseMap.map((oc) => oc.id);
            courses
              .filter((c) => !originalCourseIds.includes(c.id))
              .forEach((course) => {
                trackCourseImported({ course });
                trackCourseCreated({ course });
              });
          } else {
            set({
              hasScopes: false,
            });
          }
        },
      });
    },
    setAll: () => {
      set((s) => ({
        selectedCleverSectionIds: s.selectedCleverSectionIds.isEmpty()
          ? ImmutableSet(s.cleverSections.map((c) => c.id))
          : ImmutableSet(),
      }));
    },
    setSelected: (cleverSectionId: string) => () => {
      set((state) => ({
        selectedCleverSectionIds: setToggle(state.selectedCleverSectionIds, cleverSectionId),
      }));
    },
  }),
);
