import { ReactNode } from 'react';
import { borderRadii, colors, paddings, margins } from 'src/styles';
import styled from 'styled-components';
import { Icon, Flex, Spacer, AudioRecorderDisplayType } from 'src/components';

const xPadding = paddings[4];

const DrawerStyles = styled.div<{
  $expanded: boolean;
  $saved: boolean;
  $displayType: AudioRecorderDisplayType;
}>`
  display: flex;
  background-color: ${colors.gray1.hex};
  padding: ${({ $saved }) =>
    $saved
      ? `${paddings.half} ${paddings.half} ${paddings[4]} ${paddings[4]}`
      : `${paddings[4]} ${xPadding}`};

  border-radius: ${borderRadii[3]};

  ${({ $expanded }) => $expanded && 'width: 100%;'}
  ${({ $saved }) => `border: 1px solid ${$saved ? colors.green.hex : 'transparent'};`}
  ${({ $displayType }) => $displayType === 'desktop' && `margin-bottom: ${margins[5]};`};
`;

const DrawerHeader = styled.div`
  display: flex;
  align-items: start;
  max-height: 125px;
`;

const DrawerDivider = styled.div`
  height: 1px;
  flex-shrink: 0;
  background-color: ${colors.gray4.hex};
  align-self: stretch;
  margin: ${margins[2]} ${`-${xPadding}`};
`;

const DrawerContent = styled.div<{ expanded: boolean }>`
  display: flex;
  width: 100%;
  align-items: ${({ expanded }) => (expanded ? 'start' : 'center')};
  align-self: flex-start;
  justify-content: center;

  ${({ expanded }) => expanded && `min-height: 0; height: 100%; `}
`;

type DrawerProps = {
  children: ReactNode;
  header?: ReactNode;
  expanded?: boolean;
  saved?: boolean;
  displayType?: AudioRecorderDisplayType;
};

export const Drawer = ({
  children,
  header,
  expanded = false,
  saved = false,
  displayType = 'desktop',
}: DrawerProps) => {
  return (
    <DrawerStyles $expanded={expanded} $saved={saved} $displayType={displayType}>
      <Flex direction="column" width="100%">
        {saved && <Spacer size={4} />}
        {header && <DrawerHeader>{header}</DrawerHeader>}
        {header && <DrawerDivider />}

        <DrawerContent expanded={expanded}>{children}</DrawerContent>
      </Flex>
      {saved && <Icon icon="checkmarkCircle" color="green" size="1.75em" />}
    </DrawerStyles>
  );
};
