import { useStore } from 'src/Store';
import {
  Text,
  Spacer,
  Loading,
  Flex,
  StatsBanner,
  WordToggleInput,
  SelectInput,
  StudentAssignmentCard,
  EmptyState,
  SplitBackgroundFlex,
  StudentAvatar,
  NoStylesButton,
  Tooltip,
  StreakStatus,
} from 'src/components';
import { makeQueryString } from 'src/modules/Api';
import { reverseIf, ImmutableList } from 'src/modules/Immutable';
import { useCallback, useEffect } from 'react';
import { colors, paddings, margins, useBreakpoints } from 'src/styles';
import { useHistory, useQuery, updateQuery, useLocation } from 'src/modules/Router';
import { CenteredLayout } from 'src/layouts';
import { formatStat, statStyles, studentStats } from 'src/modules/Stat';
import {
  AssessmentExtended,
  isTaskSubmissionCompleted,
  SubmissionExtended,
  submissionStatusTagProps,
  Comment,
} from 'src/models';
import { Footer } from 'src/components/Footer';

const getEmptyStateKind = (
  assessmentStatusFilterParam: string,
): 'emptyAssignedDashboard' | 'emptySubmittedDashboard' => {
  if (assessmentStatusFilterParam === 'turned_in') {
    return 'emptySubmittedDashboard';
  }

  return 'emptyAssignedDashboard';
};

const assignmentLink = (
  submission: SubmissionExtended | undefined,
  assessment: AssessmentExtended,
  hasFeedback: boolean,
) => {
  if (submission && hasFeedback) {
    return `/submissions/${submission.id}?openComments=true`;
  } else if (submission?.status === 'finished') {
    return `/submissions/${submission.id}`;
  }
  return `/reading-studio${makeQueryString({
    assessmentId: assessment.id,
    showInstructions: 'true',
  })}`;
};

export const StudentDashboard = () => {
  const history = useHistory();
  const query = useQuery();
  const pathname = useLocation().pathname;
  const assessmentStatusFilterParam = query.get('assessmentStatusFilter') || 'new';
  const sortByParam = query.get('sortBy') || 'desc';
  const {
    currentUser,
    submissionMap,
    taskSubmissions,
    load,
    loading,
    stats,
    courseMap,
    assessmentMap,
    assessmentToCourseMap,
    openAssignmentCardSet,
    toggleAssignmentCardOpen,
    commentMap,
    readingNameMap,
  } = useStore(
    (state) => ({
      submissionMap: state.StudentDashboardData.submissionMap,
      taskSubmissions: state.StudentDashboardData.taskSubmissions,
      load: state.StudentDashboardData.load,
      loading: state.StudentDashboardData.loading,
      stats: state.StudentDashboardData.stats,
      courseMap: state.StudentDashboardData.courseMap,
      assessmentMap: state.StudentDashboardData.assessmentMap,
      assessmentToCourseMap: state.StudentDashboardData.assessmentToCourseMap,
      openAssignmentCardSet: state.StudentDashboardData.openAssignmentCardSet,
      toggleAssignmentCardOpen: state.StudentDashboardData.toggleAssignmentCardOpen,
      commentMap: state.StudentDashboardData.commentMap,
      readingNameMap: state.StudentDashboardData.readingNameMap,
      currentUser: state.AppData.currentUser,
    }),
    [],
  );
  const updateAssessmentStatusFilter = useCallback(
    (value: string) =>
      history.push(`${pathname}${updateQuery(query, { assessmentStatusFilter: value })}`),
    [history, pathname, query],
  );

  const updateSortBy = useCallback(
    (value: string) => history.push(`${pathname}${updateQuery(query, { sortBy: value })}`),
    [history, pathname, query],
  );

  useEffect(() => {
    load();
  }, [load]);

  const sortedFilteredAssessments = reverseIf(
    sortByParam === 'desc',
    assessmentMap
      .toList()
      .map((a) => {
        const submission = submissionMap.get(a.id);
        const comments = commentMap.get(submission?.id || '', ImmutableList<Comment>());
        const viewedComments = comments.filter((c) => c.viewed);
        const hasFeedback = viewedComments.size !== comments.size;

        return { assessment: a, submission, comments, hasFeedback };
      })
      .filter(({ submission, hasFeedback }) => {
        const statusToWordToggle =
          submission?.status === 'finished' && !hasFeedback ? 'turned_in' : 'new';
        return statusToWordToggle === assessmentStatusFilterParam;
      })
      .sortBy(({ assessment: { createdAt, isSample }, hasFeedback }) => {
        return [!isSample, hasFeedback, Date.parse(createdAt)];
      }),
  );

  const getMetrics = (
    assessment: AssessmentExtended,
    completedTaskCount: number,
    totalTaskCount: number,
    submission?: SubmissionExtended,
  ) => {
    let metrics = ImmutableList<{ label: string; value: string }>();

    if (submission?.status !== 'finished') return undefined;
    if (assessment.independentOnly) {
      metrics = metrics.push({
        label: 'WPM',
        value: formatStat(statStyles.wpm, submission.wcpm || 0),
      });
    } else {
      metrics = metrics.push({
        label: 'WCPM',
        value: formatStat(statStyles.wcpm, submission.wcpm || 0),
      });
    }
    metrics = metrics.push(
      {
        label: 'Words Read',
        value: formatStat(statStyles.total_words, submission.totalWords || 0),
      },
      {
        label: 'Time Read',
        value: formatStat(statStyles.time_taken, submission.timeRead),
      },
    );

    if (totalTaskCount > 0) {
      metrics = metrics.push({
        label: 'Tasks',
        value: `${completedTaskCount}/${totalTaskCount}`,
      });
    }
    return metrics;
  };

  const emptyStateKind = getEmptyStateKind(assessmentStatusFilterParam);
  const showMobile = useBreakpoints({ smallerThanOrEqualTo: 'tabletSmall' });

  if (loading) return <Loading flex kind="boat" />;

  return (
    <Flex
      width="100%"
      height="100%"
      direction="column"
      backgroundColor={colors.backgroundLight.hex}
      minWidth={0}
      overflowY="auto"
    >
      <CenteredLayout
        backgroundColor={colors.backgroundDark.hex}
        padding={`0 ${paddings[6]}`}
        minWidth={0}
      >
        <Flex
          width="100%"
          padding={`${showMobile ? paddings[5] : paddings[6]} 0 0`}
          direction="column"
          align="stretch"
          minWidth={0}
        >
          <Flex
            width="100%"
            justify={showMobile ? 'center' : 'space-between'}
            align="center"
            wrap="wrap"
            minWidth={0}
            direction={showMobile ? 'column' : 'row'}
          >
            <Flex align={showMobile ? 'center' : 'end'} direction={showMobile ? 'column' : 'row'}>
              {currentUser && (
                <>
                  <Tooltip content="Click here to edit your avatar!">
                    <NoStylesButton to="/s/avatar">
                      <StudentAvatar
                        user={currentUser}
                        kind="dashboard"
                        testTag="dashboard-avatar"
                      />
                    </NoStylesButton>
                  </Tooltip>
                  <Spacer horizontal size={3} />
                </>
              )}

              <Text variant="h1" color="white" lineHeightOverride="140%" testTag="user-name">
                {currentUser?.displayName}
                <StreakStatus user={currentUser} isCurrent />
              </Text>
            </Flex>
            <Flex justify="end" margin={`${margins[2]} 0`}>
              <WordToggleInput
                id="assignmentStatus"
                value={assessmentStatusFilterParam}
                onChange={updateAssessmentStatusFilter}
                options={ImmutableList([
                  { label: 'New', value: 'new' },
                  { label: 'Turned In', value: 'turned_in' },
                ])}
                showMobile={showMobile}
              />
            </Flex>
          </Flex>
        </Flex>
      </CenteredLayout>
      <SplitBackgroundFlex
        width="100%"
        height="auto"
        direction="column"
        align="center"
        padding={showMobile ? paddings[3] : paddings[6]}
      >
        <Flex direction="column" maxWidth="900px" width={showMobile ? 'auto' : '100%'}>
          <StatsBanner stats={studentStats(stats)} />
        </Flex>
      </SplitBackgroundFlex>

      <CenteredLayout>
        {sortedFilteredAssessments.isEmpty() ? (
          <Flex direction="column" justify="center" align="center">
            <EmptyState kind={emptyStateKind} displayType="minimal" />
            <Spacer size={8} />
          </Flex>
        ) : (
          <>
            <Flex justify="start" align="center">
              <Text display="inline" color="gray6">
                Sort By:
              </Text>
              <SelectInput
                id="sortBySelect"
                displayType="flat"
                name="assignmentSort"
                value={sortByParam}
                onChange={updateSortBy}
                options={ImmutableList([
                  { label: 'Newest', value: 'desc' },
                  { label: 'Oldest', value: 'asc' },
                ])}
              />
            </Flex>
            {!showMobile && <Spacer size={6} />}
            {sortedFilteredAssessments.map(({ assessment, submission, hasFeedback, comments }) => {
              const taskSubmissionDetailsMap = taskSubmissions.find(
                (taskSubmission) => taskSubmission.submissionId === submission?.id,
              )?.taskSubmissionDetailsMap;
              const reaction = comments.filter((c) => c.reaction !== 'undecided').last()?.reaction;
              const totalTaskCount = assessment.taskOrder.size;
              const completedTaskCount = taskSubmissionDetailsMap
                ? taskSubmissionDetailsMap.filter(isTaskSubmissionCompleted).size
                : 0;

              return (
                <StudentAssignmentCard
                  key={assessment.id}
                  assessment={assessment}
                  courseIds={assessmentToCourseMap.get(assessment.id, ImmutableList())}
                  courseMap={courseMap}
                  status={submissionStatusTagProps(submission, hasFeedback)}
                  reaction={reaction ?? 'undecided'}
                  open={openAssignmentCardSet.has(assessment.id)}
                  onToggleCardOpen={() => toggleAssignmentCardOpen(assessment.id)}
                  to={assignmentLink(submission, assessment, hasFeedback)}
                  metrics={getMetrics(assessment, completedTaskCount, totalTaskCount, submission)}
                  readingName={readingNameMap.get(assessment.id)}
                />
              );
            })}
            <Spacer size={22} />
          </>
        )}
      </CenteredLayout>
      {currentUser?.paperId && <Footer accountType="student" />}
    </Flex>
  );
};
