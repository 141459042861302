import { useMemo } from 'react';

let currentUniqueId = 0;

export const getUniqueId = (): number => {
  currentUniqueId += 1;
  return currentUniqueId;
};

export const useUniqueId = (originalId?: string | null) => {
  return useMemo(() => originalId || `unique-elem-${getUniqueId()}`, [originalId]);
};
