import { subDataCreator } from 'src/State';
import { ImmutableMap } from 'src/modules/Immutable';
import { defaultState } from './SignUpClassCodeState';
import { updateQuery } from 'src/modules/Router';
import { checkCourseCode } from 'src/models';

export const createDefaultSignUpClassCodeData = subDataCreator(
  'SignUpClassCodeData',
  defaultState,
  ({ set, fetchJson }) => ({
    load: () => set(defaultState),
    setCourseField: (key) => (value) => {
      set((state) => ({
        course: {
          ...state.course,
          [key]: value,
        },
      }));
    },
    submit:
      ({ history, query, courseCodeDeclined, courseCode, replace = false }) =>
      () => {
        const finish = () => {
          const url = `/sign-up/student/register${updateQuery(query, {
            courseCode,
          })}`;
          replace ? history.replace(url) : history.push(url);
        };

        if (courseCodeDeclined) {
          finish();
        } else {
          if (!courseCode) return;
          set({ submitting: true });
          checkCourseCode({
            courseCode,
            fetchJson,
            onSuccess: ({ success, errors }) => {
              if (success) {
                finish();
              } else {
                set({
                  errors: ImmutableMap(errors),
                  submitting: false,
                });
              }
            },
          });
        }
      },
  }),
);
