import {
  TaskSubmissionDetails,
  User,
  Assessment,
  TextDiff,
  ErrorDispute,
  SubmissionExtended,
  RecordingSequence,
  TranscriptableAudioStoredFile,
  AddRecordingResponse,
} from 'src/models';
import { ImmutableMap, ImmutableList } from 'src/modules/Immutable';
import { SetField } from 'src/State';
import {
  CommentDetails,
  CommentReaction,
  CreateCommentType,
  CommentType,
} from 'src/models/Comment';

export type LoadResponse = {
  user: User;
  assessment: Assessment;
  submission: SubmissionExtended;
  taskSubmissionDetailsMap: ImmutableMap<string, TaskSubmissionDetails>;
  textDiffMap: ImmutableMap<string, TextDiff>;
  errorDisputesMap: ImmutableMap<string, ImmutableList<ErrorDispute>>;
  userToSubmissionIdMap: ImmutableMap<string, string>;
  userMap: ImmutableMap<string, User>;
  comments: ImmutableList<CommentDetails>;
  transcriptionVisible: boolean;
};

export type StatusResponse = {
  submission: SubmissionExtended;
  taskSubmissionDetailsMap: ImmutableMap<string, TaskSubmissionDetails>;
  textDiffMap: ImmutableMap<string, TextDiff>;
};

export type SubmissionOverviewState = {
  loading: boolean;
  user: User | null;
  submission: SubmissionExtended | null;
  assessment: Assessment | null;
  taskSubmissionDetailsMap: ImmutableMap<string, TaskSubmissionDetails>;
  initialPlayProgress: number;
  playIndex: number;
  currentTaskId: string | null;
  showAdvancedStats: boolean;
  textDiffMap: ImmutableMap<string, TextDiff>;
  errorDisputesMap: ImmutableMap<string, ImmutableList<ErrorDispute>>;
  drawerExpanded: boolean;
  setField: SetField<SubmissionOverviewState>;
  setPlayProgress: (value: number) => void;
  onSubmissionStatus: (data: StatusResponse) => void;
  setCurrentTask: (taskId: string | null) => void;
  disputeError: (index: number) => void;
  load: ({ id, openComments }: { id: string; openComments: boolean }) => void;
  userToSubmissionIdMap: ImmutableMap<string, string>;
  userMap: ImmutableMap<string, User>;
  commentModalOpened: boolean;
  comments: ImmutableList<CommentDetails>;
  editingCommentType: CommentType;
  editingWrittenContent: string;
  editingRecordingSequence: RecordingSequence | null;
  editingAudioFile: TranscriptableAudioStoredFile | null;
  creatingRecordingSequence: boolean;
  savingComment: boolean;
  deleteComment: (id: string) => void;
  createRecordingSequence: () => void;
  createReactionComment: (reaction: CommentReaction) => void;
  createAudioComment: () => void;
  createWrittenComment: () => void;
  onRecordingSequenceStatus: (data: AddRecordingResponse) => unknown;
  openCommentModal: () => void;
  createComment: (comment: CreateCommentType) => void;
  transcriptionVisible: boolean;
  upgradeAssessment: (assessment: Assessment) => void;
};

export const defaultState = {
  loading: true,
  creatingRecordingSequence: false,
  savingComment: false,
  user: null,
  submission: null,
  assessment: null,
  currentTaskId: null,
  showAdvancedStats: false,
  initialPlayProgress: 0,
  playIndex: 0,
  taskSubmissionDetailsMap: ImmutableMap<string, TaskSubmissionDetails>(),
  textDiffMap: ImmutableMap<string, TextDiff>(),
  errorDisputesMap: ImmutableMap<string, ImmutableList<ErrorDispute>>(),
  drawerExpanded: false,
  userToSubmissionIdMap: ImmutableMap<string, string>(),
  userMap: ImmutableMap<string, User>(),
  commentModalOpened: false,
  comments: ImmutableList<CommentDetails>(),
  editingCommentType: 'undecided' as const,
  editingWrittenContent: '',
  editingRecordingSequence: null,
  editingAudioFile: null,
  transcriptionVisible: false,
};
