import { PrepareParams, User } from 'src/models/User';
import { ErrorMapResponse, FetchJson } from 'src/modules/Api';
import { History } from 'src/modules/Router';
import { ClassColor } from 'src/styles';

export type GoogleScopeKey = 'default' | 'just_email' | 'just_name' | 'full_info' | 'import_class';

type GoogleDriveFolder = {
  id: string;
  title: string;
  alternateLink: string;
};

type GoogleDriveFile = {
  id: string;
  title: string;
  alternateLink: string;
  thumbnailUrl: string;
};

type GoogleYoutubeVideo = {
  id: string;
  title: string;
  alternateLink: string;
  thumbnailUrl: string;
};

type GoogleLink = {
  url: string;
  title: string;
  thumbnailUrl: string;
};

type GoogleForm = {
  formUrl: string;
  responseUrl: string;
  title: string;
  thumbnailUrl: string;
};

type GoogleCourseMaterial = {
  driveFile: GoogleDriveFile;
  youTubeVideo: GoogleYoutubeVideo;
  link: GoogleLink;
  form: GoogleForm;
};

type GoogleCourseMaterialSet = {
  title: string;
  materials: Array<GoogleCourseMaterial>;
};

export type GoogleCourse = {
  id: string;
  name: string;
  section: string;
  descriptionHeading: string;
  description?: string;
  room: string;
  ownerId: string;
  creationTime: string;
  updateTime: string;
  enrollmentCode: string;
  courseState: 'COURSE_STATE_UNSPECIFIED' | 'ACTIVE' | 'PROVISIONED' | 'DECLINED' | 'SUSPENDED';
  alternateLink?: string;
  teacherGroupEmail?: string;
  courseGroupEmail?: string;
  teacherFolder?: GoogleDriveFolder;
  courseMaterialSets?: Array<GoogleCourseMaterialSet>;
  guardiansEnabled: boolean;
  calendarId: string;
  avatarColor: ClassColor;
};

type CreateLinkData = {
  authUrl: string;
  alreadyValid: boolean;
};

export const goToGoogle = ({
  scopeKey,
  successUrl,
  history,
  userAttributes,
  fetchJson,
  forceOauth = false,
}: {
  scopeKey: GoogleScopeKey;
  successUrl: string;
  history: History;
  userAttributes: PrepareParams;
  fetchJson: FetchJson;
  forceOauth?: boolean;
}) => {
  fetchJson(`/api/google_oauths/create_link`, {
    method: 'POST',
    data: {
      ...userAttributes,
      scopeKey,
      successUrl,
    },
    onSuccess: (data: CreateLinkData) => {
      if (data.alreadyValid && !forceOauth) {
        history.push(successUrl);
      } else {
        window.location.href = data.authUrl;
      }
    },
  });
};

export type GoogleButtonStatus =
  | 'success'
  | 'errored'
  | 'fields_missing'
  | 'oauth_required'
  | 'needs_email_confirmation';

export type GoogleOauthResponse = {
  status: GoogleButtonStatus;
  successUrl?: string;
  user?: User;
  errors?: ErrorMapResponse;
  isNew?: boolean;
  scopeKey: GoogleScopeKey;
};
