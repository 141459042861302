import { useCallback } from 'react';
import { Button, Spacer, StandardModal, useToaster, Text, FormGroup } from 'src/components';
import { useStore } from 'src/Store';

export const ShareModal = () => {
  const { sharingEmail, submitting, sharingErrors, submitShare, setField, opened } = useStore(
    (state) => state.ShareModalData,
    [],
  );

  const { createToast } = useToaster();
  const close = useCallback(() => setField('opened')(false), [setField]);

  if (!opened) return null;
  return (
    <StandardModal
      testTag="share"
      openType="state"
      opened={opened}
      header="Share With Teacher"
      close={close}
      onSubmit={submitShare(createToast)}
      footer={
        <>
          <Button onClick={close} displayType="link" color="primaryBlue">
            Cancel
          </Button>
          <Spacer horizontal size={3} />
          <Button loading={submitting} type="submit">
            Share
          </Button>
        </>
      }
    >
      <Text>
        Sharing sends other teachers a copy of your assignment to use with their own students.
      </Text>
      <Spacer size={2} />
      <FormGroup
        type="text"
        name="email"
        label="Teacher Email"
        errors={sharingErrors}
        value={sharingEmail}
        onChange={setField('sharingEmail')}
      />
    </StandardModal>
  );
};
