type EmptyTextRange = {
  startPoint: {
    index?: undefined;
    wordLength?: undefined;
  };
  endPoint: {
    index?: undefined;
  };
};

type PartialTextRange = {
  startPoint: {
    index: number;
    wordLength: number;
  };
  endPoint: {
    index?: undefined;
  };
};

type CompleteTextRange = {
  startPoint: {
    index: number;
    wordLength?: undefined;
  };
  endPoint: {
    index: number;
  };
};

export type TextRange = EmptyTextRange | PartialTextRange | CompleteTextRange;

export const emptyTextRange = { startPoint: {}, endPoint: {} };
