import { SetField } from 'src/State';
import { ImmutableList } from '../../modules/Immutable';
import { AvatarProps } from 'src/models';

export type AvatarGalleryState = {
  loading: boolean;
  page: number;
  total: number;
  avatars: ImmutableList<AvatarProps>;
  setField: SetField<AvatarGalleryState>;
  load: () => void;
  loadMore: () => void;
};

export const defaultState = {
  loading: true,
  page: 0,
  total: 0,
  avatars: ImmutableList<AvatarProps>(),
};
