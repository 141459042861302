import { Button, Spacer, StandardModal } from 'src/components';
import { useStore } from 'src/Store';

export const DeletePlaylistModal = () => {
  const { deletingPlaylist, setField, confirmDelete } = useStore(
    ({ PlaylistManagerData }) => PlaylistManagerData,
    [],
  );

  return (
    <StandardModal
      openType="state"
      opened={Boolean(deletingPlaylist)}
      header="Delete Playlist?"
      close={() => setField('deletingPlaylist')(null)}
      footer={
        <>
          <Button
            displayType="link"
            color="primaryBlue"
            onClick={() => setField('deletingPlaylist')(null)}
          >
            Cancel
          </Button>
          <Spacer horizontal size={3} />
          <Button color="danger" onClick={confirmDelete}>
            Delete
          </Button>
        </>
      }
    >
      Delete &quot;{deletingPlaylist?.name}&quot;?
    </StandardModal>
  );
};
