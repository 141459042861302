import { AudioPlayer } from 'src/components';
import { ModelTaskSubmissionDetails } from 'src/models';
import { useStore } from 'src/Store';

export const ModelTaskStudio = ({
  taskSubmissionDetails,
}: {
  taskSubmissionDetails: ModelTaskSubmissionDetails;
}) => {
  const onCompleteTask = useStore(({ ReadingStudioData: s }) => s.onCompleteTask, []);
  if (!taskSubmissionDetails.audioFile) return null;

  return (
    <AudioPlayer
      audioFileId={taskSubmissionDetails.audioFile.id}
      src={taskSubmissionDetails.audioFile.url}
      placeholder="Listen to Model Reading"
      onPlay={() => onCompleteTask(taskSubmissionDetails)}
      testTag="play-model-reading"
      trackMinWidth="150px"
    />
  );
};
