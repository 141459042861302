import { Button, Text, LinkButton, Spacer, Flex } from 'src/components';
import { SimpleLayout } from 'src/layouts';
import { useQuery, queryMapToString } from 'src/modules/Router';
import Teacher from 'src/assets/teacher.svg';
import Student from 'src/assets/student.svg';
import styled from 'styled-components';
import { borderRadii, colors, paddings } from 'src/styles';

const TypeButtonContainer = styled.div`
  background: ${colors.backgroundLight.hex};
  padding: ${paddings[4]} ${paddings[8]};
  border-radius: ${borderRadii[2]};

  :hover {
    background: ${colors.backgroundLight.active};
  }
`;

export const SignUpAccountType = () => {
  const query = useQuery();
  const sso = query.get('sso');

  return (
    <SimpleLayout>
      <Flex direction="column" align="center">
        <Flex justify="center" align="end" padding={`${paddings[4]} ${paddings[8]}`}>
          <Button
            displayType="noStyles"
            to={`/sign-up/teacher/school-code${queryMapToString(query)}`}
          >
            <TypeButtonContainer>
              <Flex direction="column" justify="center" align="center">
                <img style={{ maxHeight: '80px', maxWidth: '80px' }} src={Teacher} />
                <Spacer size={3} />
                <Text variant="h5" weightOverride="medium" whiteSpace="nowrap">
                  I&apos;m a teacher
                </Text>
              </Flex>
            </TypeButtonContainer>
          </Button>
          <Spacer size={6} horizontal />
          <Button
            displayType="noStyles"
            to={`/sign-up/student/class-code${queryMapToString(query)}`}
          >
            <TypeButtonContainer>
              <Flex direction="column" justify="center" align="center">
                <img style={{ maxHeight: '80px', maxWidth: '80px' }} src={Student} />
                <Spacer size={3} />
                <Text variant="h5" weightOverride="medium" whiteSpace="nowrap">
                  I&apos;m a student
                </Text>
              </Flex>
            </TypeButtonContainer>
          </Button>
        </Flex>
        <Spacer size={2} />
        {!sso && (
          <Flex>
            <Text>Already a user?</Text>
            <Spacer horizontal />
            <LinkButton to="/login">Sign In</LinkButton>
          </Flex>
        )}
      </Flex>
    </SimpleLayout>
  );
};
