import { subDataCreator } from 'src/State';
import { defaultState, CreateCourseResponse } from './CreateCourseState';
import { choice, ImmutableList, ImmutableMap } from 'src/modules/Immutable';
import { classColors } from 'src/styles';
import { trackCourseCreated } from 'src/modules/Analytics/AnalyticsEvents';

export const createDefaultCreateCourseData = subDataCreator(
  'CreateCourseData',
  defaultState,
  ({ set, get, getFull, setField, fetchJson }) => ({
    setField,
    load: () => set(defaultState),
    submit: (onSuccess) => {
      set({ loading: true });
      fetchJson(`/api/courses`, {
        method: 'POST',
        data: {
          courseName: get().courseName,
          avatarColor: choice(ImmutableList(classColors)),
        },
        onSuccess: ({ success, course, errors }: CreateCourseResponse) => {
          if (success) {
            set(defaultState);
            onSuccess?.(course);
            trackCourseCreated({ course });
            getFull().AppData.load({ skipStateReset: true });
          } else {
            set({
              course,
              errors: ImmutableMap(errors),
              loading: false,
            });
          }
        },
      });
    },
  }),
);
