import { DEFAULT_TEXT_SIZE, ImageStoredFile, VocabTask, VocabTaskDetails, Word } from 'src/models';
import { Text, Flex, AudioPlayer, Spacer } from 'src/components';
import { capitalize } from 'src/modules/String';
import styled, { css } from 'styled-components';
import { borderRadii, colors, margins, paddings } from 'src/styles';
import { StandardModal } from '../Modal';
import { useStore } from 'src/Store';

type VocabTaskOverviewProps = {
  taskDetails: VocabTaskDetails;
  compactLayout: boolean;
};
type VocabTaskOverviewModalProps = VocabTaskOverviewProps & { close: () => void };

const VocabTaskOverviewStyles = styled.div<{ $compactLayout: boolean }>`
  display: flex;
  border: 1px solid ${colors.gray2.hex};
  padding: ${paddings[6]};
  border-radius: ${borderRadii[2]};

  height: auto;
  justify-content: space-between;
  ${({ $compactLayout }) =>
    $compactLayout
      ? css`
          flex-wrap: wrap;
          margin: ${margins[2]};
          align-self: stretch;
          flex: 1;
        `
      : css`
          min-width: 600px;
          margin: ${margins[12]};
          max-height: 75%;
          align-self: center;
        `}
`;
const VocabImageStyles = styled.img<{ $compactLayout: boolean }>`
  max-height: 300px;
  object-fit: contain;
  ${({ $compactLayout }) => ($compactLayout ? `max-width: 80vw` : 'max-width: 400px')};
`;

export const VocabAudio = ({ word, task }: { word: Word | null; task: VocabTask }) => {
  return word?.audioUrl ? (
    <Flex align="center">
      <Spacer size={2} horizontal />
      <AudioPlayer
        src={word.audioUrl}
        displayType="iconOnly"
        icon="speaker"
        iconColor="black"
        testTag="vocab-audio"
        subText={
          word?.lemmaWord && word.lemmaWord.toLowerCase() !== task.customWord.toLowerCase()
            ? capitalize(word.lemmaWord)
            : undefined
        }
      />
    </Flex>
  ) : null;
};

export const VocabDefinition = ({ task }: { task: VocabTask }) => {
  const textSize = useStore((s) => s.AppData.currentUser?.textSize, []) || DEFAULT_TEXT_SIZE;

  return (
    <>
      <Text variant="h6" color="gray7" sizeOverride={textSize}>
        Definition
      </Text>
      <Text whiteSpace="pre-wrap" overflowY="auto" sizeOverride={textSize}>
        {task.definition}
      </Text>
    </>
  );
};

export const VocabImage = ({
  imageFile,
  task,
  compactLayout,
}: {
  task: VocabTask;
  imageFile: ImageStoredFile | null;
  compactLayout: boolean;
}) => {
  if (!imageFile?.url) return null;

  return (
    <>
      <Spacer horizontal size={20} />
      <VocabImageStyles
        $compactLayout={compactLayout}
        src={imageFile.url}
        alt={task.customWord}
        data-test-tag="vocab-image"
      />
    </>
  );
};

export const VocabTaskOverview = (props: VocabTaskOverviewProps) => {
  const { compactLayout, taskDetails } = props;
  const { task, word, imageFile } = taskDetails;

  return (
    <VocabTaskOverviewStyles $compactLayout={compactLayout}>
      <Flex direction="column">
        <Flex>
          <Text variant="h3">{capitalize(task.customWord)}</Text>
          <VocabAudio word={word} task={task} />
        </Flex>
        <Spacer size={4} />
        <VocabDefinition task={task} />
      </Flex>
      <VocabImage imageFile={imageFile} task={task} compactLayout={compactLayout} />
    </VocabTaskOverviewStyles>
  );
};

export const VocabTaskOverviewModal = (props: VocabTaskOverviewModalProps) => {
  const { compactLayout, taskDetails } = props;
  const { task, word, imageFile } = taskDetails;

  return (
    <StandardModal
      header={capitalize(task.customWord)}
      middleHeader={<VocabAudio word={word} task={task} />}
      openType="state"
      opened
      close={props.close}
    >
      <Flex wrap={compactLayout ? 'wrap' : 'unset'}>
        <Flex direction="column">
          <VocabDefinition task={task} />
        </Flex>
        <VocabImage imageFile={imageFile} task={task} compactLayout={compactLayout} />
      </Flex>
    </StandardModal>
  );
};
