import { ReactNode } from 'react';
import styled from 'styled-components';
import { NoStylesButton, Text } from 'src/components';
import { borderRadii, colors, paddings, rgba } from 'src/styles';

type TagButtonProps = {
  onClick: () => void;
  active?: boolean;
  children: ReactNode;
};

const TagButtonStyles = styled.div<{ $active: boolean }>`
  border-radius: ${borderRadii.rounded};
  background: ${({ $active }) => rgba(colors.white.hex, $active ? 0.3 : 0.1)};
  padding: ${paddings[2]} ${paddings[4]};
`;

export const TagButton = ({ onClick, active = false, children }: TagButtonProps) => {
  return (
    <NoStylesButton onClick={onClick} disabled={active} disabledOpacityOverride="1">
      <TagButtonStyles $active={active}>
        <Text color="white" weightOverride="bold" variant="h6" whiteSpace="nowrap">
          {children}
        </Text>
      </TagButtonStyles>
    </NoStylesButton>
  );
};
