import { useHistory, queryMapToString, useQuery } from 'src/modules/Router';
import {
  Flex,
  Loading,
  Spacer,
  Text,
  TaskButton,
  FlexGrow,
  ReadingStudioStats,
  Button,
  SelectInput,
  Icon,
  DiffItemText,
  Divider,
  StarRow,
  StudentAvatar,
} from 'src/components';
import { useStore } from 'src/Store';
import { ReadingStudioLayoutSideBar } from 'src/layouts';
import {
  isTaskSubmissionCompleted,
  readingTaskSubmissionDetailsIsProcessing,
  TaskSubmissionDetails,
  getTaskIcon,
  formatName,
} from 'src/models';
import { useReadingTaskSubmissionDetails } from './SubmissionOverviewHelpers';
import { ellipsify } from 'src/modules/String';
import { borderRadii, paddings } from 'src/styles';

const TranscriptionKey = () => {
  const { currentUser } = useStore((s) => s.AppData, []);
  const isStudent = currentUser?.accountType === 'student';

  return (
    <>
      <Flex
        direction="column"
        backgroundColor="#4B7AC1"
        borderRadius={borderRadii[3]}
        padding={`${paddings[4]}`}
      >
        <Text variant="h4">Pro Tip</Text>
        <Spacer size={2} />
        <Text variant="h5">Click on any word to jump to that part of the recording.</Text>
      </Flex>
      <Spacer size={4} />
      <span style={{ color: 'black' }}>
        <Flex
          direction="column"
          backgroundColor="#ECF0FD"
          borderRadius={borderRadii[3]}
          padding={`${paddings[4]} ${paddings[4]} 0 ${paddings[4]}`}
        >
          <Text variant="h4">What do the colors mean?</Text>
          <ul style={{ paddingLeft: `${paddings[4]}` }}>
            <li>
              <Text variant="h5">We understood this</Text>
            </li>
            <Spacer size={1} />
            <li>
              <DiffItemText textSizeOverride={16} variant="del">
                We didn&apos;t hear this
              </DiffItemText>
            </li>
            <Spacer size={1} />
            <li>
              {isStudent ? (
                <DiffItemText textSizeOverride={16} color="success">
                  You marked this as correct
                </DiffItemText>
              ) : (
                <DiffItemText textSizeOverride={16} variant="ins">
                  We heard this instead
                </DiffItemText>
              )}
            </li>
          </ul>
        </Flex>
      </span>
      <Spacer size={4} />
    </>
  );
};

export const SubmissionOverviewSideBar = () => {
  const {
    submission,
    taskSubmissionDetailsMap,
    assessment,
    currentTaskId,
    user,
    currentUser,
    userToSubmissionIdMap,
    userMap,
    comments,
    openCommentModal,
    transcriptionVisible,
  } = useStore(
    (state) => ({
      submission: state.SubmissionOverviewData.submission,
      taskSubmissionDetailsMap: state.SubmissionOverviewData.taskSubmissionDetailsMap,
      assessment: state.SubmissionOverviewData.assessment,
      currentTaskId: state.SubmissionOverviewData.currentTaskId,
      user: state.SubmissionOverviewData.user,
      userToSubmissionIdMap: state.SubmissionOverviewData.userToSubmissionIdMap,
      userMap: state.SubmissionOverviewData.userMap,
      comments: state.SubmissionOverviewData.comments,
      openCommentModal: state.SubmissionOverviewData.openCommentModal,
      transcriptionVisible: state.SubmissionOverviewData.transcriptionVisible,
      currentUser: state.AppData.currentUser,
    }),
    [],
  );

  const query = useQuery();
  const readingTaskSubmissionDetails = useReadingTaskSubmissionDetails();

  const history = useHistory();

  const processing = taskSubmissionDetailsMap.some(
    (t) => t.type === 'ReadingTask' && readingTaskSubmissionDetailsIsProcessing(t),
  );
  const isStudent = currentUser?.accountType === 'student';

  const sortedUserList = userMap
    .toList()
    .sortBy((u) => [!userToSubmissionIdMap?.has(u.id), formatName(u)]);

  const sortedUsersWithSubmissions = sortedUserList.filter((student) =>
    userToSubmissionIdMap?.has(student.id),
  );

  const studentOptions = sortedUserList.map((user) => ({
    label: `${userToSubmissionIdMap?.has(user.id) ? '' : '(DNC) '}${ellipsify(
      user.displayName,
      20,
    )}`,
    value: user.id,
    disabled: !userToSubmissionIdMap?.has(user.id),
  }));

  if (!readingTaskSubmissionDetails || !submission) return <Loading flex flexWidth="400px" />;

  const submissionIndex = sortedUsersWithSubmissions.findIndex((s) => s.id === user?.id);
  const previous = submissionIndex > 0 ? sortedUsersWithSubmissions.get(submissionIndex - 1) : null;
  const previousSubmission = previous ? userToSubmissionIdMap?.get(previous.id) : null;
  const next = sortedUsersWithSubmissions.get(submissionIndex + 1);
  const nextSubmission = next ? userToSubmissionIdMap?.get(next.id) : null;
  const reaction = comments.filter(({ comment }) => comment.reaction !== 'undecided').last()
    ?.comment.reaction;

  const showNavigation = sortedUserList.count() > 1;

  return (
    <ReadingStudioLayoutSideBar
      content={
        readingTaskSubmissionDetails && (
          <Flex direction="column" grow={1}>
            {!isStudent && showNavigation && (
              <>
                <Flex direction="row" align="center" justify="space-between">
                  <Button
                    displayType="noStyles"
                    color="backgroundDark"
                    disabled={!previous}
                    testTag="prev-submission"
                    to={
                      previousSubmission
                        ? `/submissions/${previousSubmission}${queryMapToString(query)}`
                        : undefined
                    }
                  >
                    <Icon icon="arrowLeft" size="1.75em" color={previous ? 'white' : 'gray7'} />
                    <Text color={previous ? 'white' : 'gray7'} variant="h6">
                      Previous
                    </Text>
                  </Button>
                  <Button
                    displayType="noStyles"
                    color="backgroundDark"
                    disabled={!next}
                    testTag="next-submission"
                    to={
                      nextSubmission
                        ? `/submissions/${nextSubmission}${queryMapToString(query)}`
                        : undefined
                    }
                  >
                    <Text color={next ? 'white' : 'gray7'} variant="h6">
                      Next
                    </Text>
                    <Icon icon="arrowRight" size="1.75em" color={next ? 'white' : 'gray7'} />
                  </Button>
                </Flex>
                <Spacer size={2} />
              </>
            )}
            {isStudent && (
              <Flex align="center">
                {user && (
                  <>
                    <StudentAvatar user={user} kind="submission" testTag="submission-avatar" />
                    <Spacer horizontal size={2} />
                  </>
                )}

                <Text variant="h3">{user?.displayName}</Text>
              </Flex>
            )}
            {!isStudent && (
              <>
                {showNavigation ? (
                  <SelectInput
                    id="studentSelect"
                    displayType="sidebar"
                    name="Student Select"
                    value={user?.id}
                    title={user?.name ?? undefined}
                    onChange={(newVal) => {
                      const next = userToSubmissionIdMap?.get(newVal);
                      if (next) history.push(`/submissions/${next}${queryMapToString(query)}`);
                    }}
                    options={studentOptions}
                  />
                ) : (
                  <Text variant="h4">{user?.name}</Text>
                )}
              </>
            )}
            {submission.rating !== null && (
              <>
                <Spacer size={4} />
                <Flex align="center">
                  <Text variant="h6">STUDENT INTEREST:</Text>
                  <Spacer horizontal size={2} />
                  <StarRow rating={submission.rating} borderColor="white" />
                </Flex>
              </>
            )}
            <Spacer size={4} />
            <ReadingStudioStats
              processing={processing}
              user={user}
              readingTaskSubmissionDetails={readingTaskSubmissionDetails}
              hasPremiumStats={!isStudent}
              lastSubmitted={submission.finishedAt}
            />
            <Spacer size={4} />
            <Text variant="h6" color="white">
              MY TASKS
            </Text>
            <Spacer size={4} />
            {assessment?.taskOrder
              .map((task) => taskSubmissionDetailsMap.get(task.taskId))
              .filter((t): t is TaskSubmissionDetails => Boolean(t))
              .map((taskSubmissionDetails) => {
                return (
                  <TaskButton
                    key={taskSubmissionDetails.task.id}
                    icon={getTaskIcon(taskSubmissionDetails.task)}
                    active={taskSubmissionDetails.task.id === currentTaskId}
                    to={`/submissions/${submission.id}${queryMapToString(
                      query.set('currentTaskId', taskSubmissionDetails.task.id),
                    )}`}
                    completed={
                      taskSubmissionDetails && isTaskSubmissionCompleted(taskSubmissionDetails)
                    }
                  >
                    {taskSubmissionDetails.task.name}
                  </TaskButton>
                );
              })}
            {isStudent && comments.size > 0 && (
              <>
                <Divider />
                <Spacer size={2} />
                <TaskButton
                  reaction={reaction}
                  icon="chatBubble"
                  onClick={openCommentModal}
                  completed={comments.every((c) => c.comment.viewed === true)}
                  active={false}
                >
                  Teacher Feedback ({comments.size})
                </TaskButton>
              </>
            )}
            <FlexGrow />
            <Spacer size={2} />
            {transcriptionVisible &&
              readingTaskSubmissionDetails.task.readingPartType !== 'independent' && (
                <TranscriptionKey />
              )}
            {isStudent && (
              <>
                <Spacer size={2} />
                <Button
                  displayType="outline"
                  color="white"
                  to={`/reading-studio?assessmentId=${assessment?.id}`}
                >
                  Edit My Submission{' '}
                </Button>
              </>
            )}
          </Flex>
        )
      }
    />
  );
};
