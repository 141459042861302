import { useStore } from 'src/Store';
import { Spacer, Text, Flex, Button } from 'src/components';
import { CenteredLayout, SearchResultsContainer } from 'src/layouts';
import { ImmutableList } from 'src/modules/Immutable';
import { ReadingSearchResult } from 'src/models';
import { paddings } from 'src/styles';
import { CenteredLoading, useCurrentPlaylist } from './ContentLibraryHelpers';
import { matchPath, useLocation } from 'react-router-dom';
import { ReadingTile } from './ReadingTile';

export const PlaylistDetails = () => {
  const { playlistResults, loading, continueReadingMap } = useStore(
    ({ ContentLibraryData: s }) => ({
      playlistResults: s.playlistResults,
      continueReadingMap: s.continueReadingMap,
      loading: s.loading,
    }),
    [],
  );

  const pathname = useLocation().pathname;
  const isStudent = Boolean(matchPath(pathname, '/s/library'));

  const playlist = useCurrentPlaylist();

  if (loading) return <CenteredLoading />;
  if (!playlist) return null;
  const results =
    playlist.id === 'continue-reading'
      ? continueReadingMap.toList()
      : playlistResults.get(playlist.id, ImmutableList<ReadingSearchResult>());
  return (
    <CenteredLayout centeredMaxWidth="100%">
      <Spacer size={8} />
      <Flex align="center" padding={`${paddings.none} ${paddings[4]}`}>
        <Button
          icon="backArrow"
          color="backgroundLight"
          to={`/${isStudent ? 's' : 't'}/library`}
          paddingOverride={`${paddings[1]} ${paddings[3]}`}
        >
          Back
        </Button>
        <Spacer size={3} horizontal />
        <Text variant="h2">{playlist.name}</Text>
      </Flex>
      <Spacer size={3} />
      <SearchResultsContainer testTag={`playlist-results-${playlist.id}`}>
        {results
          .sortBy((res) => res.reading.name.toLocaleLowerCase())
          .map((res, idx) => (
            <ReadingTile
              id={`playlist-result-${idx}`}
              key={res.reading.id}
              result={res}
              testTag={`playlist-result-${idx}`}
              existingAssessmentId={res.assessmentId}
            />
          ))}
      </SearchResultsContainer>
    </CenteredLayout>
  );
};
