import {
  Flex,
  Text,
  Spacer,
  GoogleShareButton,
  FlexGrow,
  Copyable,
  Tooltip,
  Label,
  Divider,
} from 'src/components';
import { StudentSelector } from './StudentSelector';
import { PreAssignSelector } from './PreAssignSelector';
import { StudentSelectionHeader } from './StudentSelectionHeader';
import { useStore } from 'src/Store';
import SittingPerson from 'src/assets/sitting_person.svg';
import { borderRadii, colors, paddings, useBreakpoints } from 'src/styles';
import styled from 'styled-components';
import { compactLayoutBreakpoint, useCompactLayout } from './AssignToModalHelpers';
import { makeQueryString } from 'src/modules/Api';
import { appUrl } from 'src/config';

const SelectionCard = styled.div`
  background: ${colors.backgroundLight.hex};
  width: 100%;
  border-radius: ${borderRadii[3]};

  @media (min-width: ${compactLayoutBreakpoint}px) {
    padding: ${paddings[3]};
    width: 45%;
  }
`;

export const RightContent = () => {
  const {
    error,
    saving,
    saved,
    currRegistrationAssessments,
    selectedCourse,
    assessmentId = '',
    currentUser,
  } = useStore(
    (s) => ({
      error: s.AssignToData.error,
      saving: s.AssignToData.saving,
      saved: s.AssignToData.saved,
      currRegistrationAssessments: s.AssignToData.currRegistrationAssessments,
      selectedCourse: s.AssignToData.selectedCourse,
      assessmentId: s.AssignToData.assessmentId,
      currentUser: s.AppData.currentUser,
    }),
    [],
  );

  const showCompactLayout = useCompactLayout();
  const showSuperCompactLayout = useBreakpoints({ smallerThanOrEqualTo: 'mobileSmall' });

  const getSavingText = () => {
    if (error) return 'There was an error. Please try again.';
    if (saved) return 'Changes Saved';
    return saving ? 'Saving...' : '';
  };

  if (selectedCourse && assessmentId) {
    const assignUrl = `${appUrl}/a${makeQueryString({
      assessmentId,
      courseId: selectedCourse.id,
      courseCode: selectedCourse.courseCode,
    })}`;

    return (
      <SelectionCard>
        <Flex
          direction="column"
          height="100%"
          width="100%"
          padding={showCompactLayout ? paddings[2] : paddings[5]}
        >
          <StudentSelectionHeader />
          <Spacer size={2} />
          {!currentUser?.paperSyncedAt && (
            <Flex align="center" wrap="wrap" justify="space-between">
              <Flex align="center">
                <Label>Share with Class</Label>
                <Tooltip content="Students who use the link will be taken directly to this assignment in Readlee." />
              </Flex>
              <Flex align="center">
                <Copyable
                  kind="link"
                  copyContent={assignUrl}
                  displayType="link"
                  color="primaryBlue"
                >
                  <Text weightOverride="bold">Copy Link</Text>
                </Copyable>
                {selectedCourse.googleId && (
                  <>
                    <Spacer horizontal size={2} />
                    <Text weightOverride="bold">OR</Text>
                    <Spacer horizontal size={2} />
                    <GoogleShareButton googleCourseId={selectedCourse.googleId} url={assignUrl} />
                  </>
                )}
              </Flex>
            </Flex>
          )}
          <Divider color="#CECECE" size={1} />
          {currRegistrationAssessments?.size ? <StudentSelector /> : <PreAssignSelector />}
          <FlexGrow />
          {!saving && !saved ? (
            <Spacer size={4} />
          ) : (
            <Text color={error ? 'danger' : 'gray7'}>{getSavingText()}</Text>
          )}
        </Flex>
      </SelectionCard>
    );
  } else if (!showSuperCompactLayout) {
    return (
      <SelectionCard>
        <Flex
          direction="column"
          align="center"
          justify="center"
          height="100%"
          padding={showCompactLayout ? paddings[2] : paddings[5]}
        >
          {!showCompactLayout && <img src={SittingPerson} />}
          <Text textAlign="center" variant={showCompactLayout ? 'h4' : 'h1'}>
            Select a class
          </Text>
          <Spacer size={4} />
          <Text color="gray6" variant="largeText" textAlign="center">
            Choose a class from the left to assign this task to your students
          </Text>
        </Flex>
      </SelectionCard>
    );
  }
  {
    return null;
  }
};
