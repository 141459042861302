import { useCallback } from 'react';
import { useHistory, useQuery, updateQuery, useLocation } from 'src/modules/Router';
import { ImmutableList } from 'src/modules/Immutable';
import { colors, paddings, margins, useBreakpoints } from 'src/styles';
import { CenteredLayout } from 'src/layouts';
import { Flex, Text, WordToggleInput } from 'src/components';

const assessmentStatusWordToggleOptions = ImmutableList([
  { value: 'active', label: 'Active' },
  { value: 'archived', label: 'Archived' },
] as const);

export const TeacherAssignmentListHeader = () => {
  const history = useHistory();
  const query = useQuery();
  const pathname = useLocation().pathname;

  const statusFilter = query.get('assessmentStatusFilter') || 'active';
  const updateAssessmentStatusFilter = useCallback(
    (value: string) =>
      history.push(`${pathname}${updateQuery(query, { assessmentStatusFilter: value })}`),
    [history, pathname, query],
  );

  const showMobile = useBreakpoints({ smallerThanOrEqualTo: 'tabletSmall' });

  return (
    <CenteredLayout backgroundColor={colors.backgroundDark.hex} padding={`0 ${paddings[6]}`}>
      <Flex
        width="100%"
        justify={showMobile ? 'center' : 'space-between'}
        align="center"
        wrap="wrap"
        padding={`${showMobile ? paddings[5] : paddings[7]} 0`}
        margin={`-${margins[2]} 0`}
      >
        <Flex
          margin={showMobile ? `${margins[2]} 0` : `${margins[2]} ${margins[8]} ${margins[2]} 0`}
        >
          <Text variant="h2" color="white" textAlign="center">
            Assignments
          </Text>
        </Flex>
        <Flex justify="end" margin={`${margins[2]} 0`}>
          <WordToggleInput
            onChange={updateAssessmentStatusFilter}
            value={statusFilter}
            options={assessmentStatusWordToggleOptions}
            showMobile={showMobile}
          />
        </Flex>
      </Flex>
    </CenteredLayout>
  );
};
