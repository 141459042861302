import { CreateToast } from 'src/components';
import { emptyErrorMap, ErrorMap } from 'src/modules/Api';
import { History } from 'src/modules/Router';
import { SetField } from 'src/State';

export type DuplicateModalState = {
  openDuplicateModal: (assessmentId: string) => void;
  opened: boolean;
  assessmentId: string;
  submitting: boolean;
  duplicatingName: string;
  duplicatingErrors: ErrorMap;
  submitDuplicate: (createToast: CreateToast, history: History) => () => void;
  setField: SetField<DuplicateModalState>;
};

export const defaultState = {
  assessmentId: '',
  opened: false,
  submitting: false,
  duplicatingName: '',
  duplicatingErrors: emptyErrorMap(),
};
