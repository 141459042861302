import { subDataCreator } from 'src/State';
import { defaultState, LoadResponse } from './TeacherAssignmentOverviewState';
import { emptyErrorMap } from 'src/modules/Api';
import { formatDate } from 'src/modules/Time';
import { ImmutableMap, setToggle } from 'src/modules/Immutable';
import { DuplicateAssessmentData } from 'src/models';

type DeleteSubmissionData = {
  success: boolean;
};

export const createDefaultTeacherAssignmentOverviewData = subDataCreator(
  'TeacherAssignmentOverviewData',
  defaultState,
  ({ set, get, setField, fetchJson }) => ({
    setField,
    load: (id) => {
      set(defaultState);
      fetchJson(`/api/assessments/${id}/teacher_show`, {
        onSuccess: (data: LoadResponse) => {
          set({
            commentMap: data.commentMap,
            assessmentDetail: data.assessmentDetail,
            submissionMap: data.submissionMap,
            userMap: data.userMap,
            courseMap: data.courseMap,
            courseToStudentIdsMap: data.courseToStudentIdsMap,
            taskDetailsMap: data.taskDetailsMap,
            studentToTaskSubmissionDetailsMap: data.studentToTaskSubmissionDetailsMap,
            taskCompletionMap: data.taskCompletionMap,
            loading: false,
          });
        },
      });
    },
    updateSubmissionMap: ({ submission }) => {
      set((state) => ({
        ...state,
        submissionMap: state.submissionMap.map((value) =>
          value.id === submission.id ? submission : value,
        ),
      }));
    },

    startReassigning: () => {
      const { assessmentDetail } = get();

      set({
        reassigning: true,
        reassigningName: assessmentDetail
          ? `${assessmentDetail.assessment.name} - ${formatDate(new Date(), {
              includeYear: true,
            })}`
          : '',
        reassigningErrors: emptyErrorMap(),
      });
    },
    submitReassign:
      ({ history, createToast }) =>
      () => {
        const { assessmentDetail, reassigningName } = get();
        if (!assessmentDetail) return;
        set({ submitting: true });

        fetchJson(`/api/assessments/${assessmentDetail.assessment.id}/duplicate`, {
          method: 'POST',
          data: {
            name: reassigningName,
            reassign: true,
          },
          onSuccess: (data: DuplicateAssessmentData) => {
            set({
              submitting: false,
              reassigning: !data.success,
              reassigningErrors: ImmutableMap(data.errors),
            });

            if (data.success) {
              history.push(`/t/assignments/${data.assessment.id}`);
              createToast({ children: 'Assignment has been duplicated and reassigned!' });
            }
          },
        });
      },

    toggleTaskCardOpen: (value: string) => () => {
      set(({ openTaskCardSet }) => ({
        openTaskCardSet: setToggle(openTaskCardSet, value),
      }));
    },
    openDeleteSubmissionModal: (student, submission) => {
      set({
        deleteSubmissionModalOpened: true,
        deleteSubmissionStudent: student,
        submissionToDelete: submission,
      });
    },
    closeDeleteSubmissionModal: () => {
      set({
        deleteSubmissionModalOpened: false,
        deleteSubmissionStudent: null,
        submissionToDelete: null,
        confirmDeleteText: '',
      });
    },
    deleteSubmission: (createToast) => () => {
      const {
        submissionToDelete,
        deleteSubmissionStudent,
        submissionMap,
        taskCompletionMap,
        closeDeleteSubmissionModal,
      } = get();
      if (submissionToDelete && deleteSubmissionStudent) {
        fetchJson(`/api/submissions/${submissionToDelete.id}`, {
          method: 'DELETE',
          onSuccess: (data: DeleteSubmissionData) => {
            if (data.success) {
              const newSubmissionMap = submissionMap.delete(deleteSubmissionStudent.id);
              const newTaskCompletionMap = taskCompletionMap.delete(deleteSubmissionStudent.id);
              set({ submissionMap: newSubmissionMap, taskCompletionMap: newTaskCompletionMap });
              closeDeleteSubmissionModal();
              createToast({ children: 'Submission has been deleted' });
            } else {
              createToast({ children: 'Submission was unable to be deleted', color: 'danger' });
            }
          },
        });
      }
    },
  }),
);
