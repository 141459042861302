import styled from 'styled-components';
import { Text, LayoutContent } from 'src/components';
import { breakpoints, colors, paddings, useBreakpoints } from 'src/styles';
import { ReactNode } from 'react';

const StudentLayoutContentHeaderStyles = styled.div<{ $showMobile: boolean }>`
  display: grid;
  grid-template-columns: auto 1fr auto;
  padding: ${({ $showMobile }) => ($showMobile ? paddings[5] : paddings[10])};
  background: ${colors.backgroundLight.hex};
  color: ${colors.backgroundLight.text};
`;

export const StudentLayoutContentHeader = ({
  headerText,
  headerActionButton = null,
  showMobile,
}: {
  headerText: string;
  headerActionButton?: ReactNode;
  showMobile: boolean;
}) => {
  return (
    <StudentLayoutContentHeaderStyles $showMobile={showMobile}>
      <Text variant="h2">{headerText}</Text>
      <div />
      {headerActionButton}
    </StudentLayoutContentHeaderStyles>
  );
};

const ContentStyles = styled.div`
  height: 100%;
  background: ${colors.backgroundLight.hex};
  color: ${colors.backgroundLight.text};
  display: grid;
  grid-template-rows: auto 1fr;
  grid-column: span 2;
  overflow-x: auto;
  overflow-y: auto;

  @media (min-width: ${breakpoints.tablet}px) {
    grid-column: span 1;
  }
`;

const ChildrenContainer = styled.div<{ $showMobile: boolean }>`
  height: 100%;
  padding: ${({ $showMobile }) =>
    $showMobile ? `${paddings.none} ${paddings[5]}` : `${paddings.none} ${paddings[10]}`};
`;

interface StudentLayoutProps {
  headerText: string;
  headerActionButton?: ReactNode;
  children: ReactNode;
  loading?: boolean;
}

export const StudentLayoutContent = ({
  headerText,
  headerActionButton,
  children,
  loading = false,
}: StudentLayoutProps) => {
  const headerProps = { headerText, headerActionButton };

  const showMobile = useBreakpoints({ smallerThanOrEqualTo: 'mobileLarge' });

  return (
    <ContentStyles>
      <StudentLayoutContentHeader {...headerProps} showMobile={showMobile} />
      <ChildrenContainer $showMobile={showMobile}>
        <LayoutContent loading={loading}>{children}</LayoutContent>
      </ChildrenContainer>
    </ContentStyles>
  );
};
