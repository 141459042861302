import { useEffect, useCallback } from 'react';
import { useStore } from 'src/Store';
import { AssignToModal } from 'src/pages/AssignToModal';
import {
  Flex,
  Text,
  TeacherAssignmentCard,
  Loading,
  TabView,
  Spacer,
  SelectInput,
  EmptyState,
  SearchInput,
  UpgradeAssignmentModal,
  Footer,
} from 'src/components';
import { colors, margins, useBreakpoints } from 'src/styles';
import { ImmutableList, ImmutableMap, searchFilter } from 'src/modules/Immutable';
import {
  useQuery,
  useHistory,
  updateQuery,
  queryMapToString,
  useLocation,
} from 'src/modules/Router';
import { AssessmentDetails, assignmentMetrics } from 'src/models';
import { CenteredLayout } from 'src/layouts';
import { TeacherAssignmentListHeader } from './TeacherAssignmentListHeader';
import { useCourseIdToAssessmentListMap, useCourseTabs } from './TeacherAssignmentListHelper';
import { UpdateAssignmentStatusModal } from 'src/pages/UpdateAssignmentStatusModal';
import { ShareModal } from 'src/pages/ShareModal';
import { DuplicateModal } from 'src/pages/DuplicateModal';

const emptyStateKind = (filter: string, isPaperUser: boolean) =>
  filter === 'active' ? (isPaperUser ? 'assignmentsPaper' : 'assignments') : 'archivedAssignments';

const sortOptions = ImmutableList([
  { value: 'desc', label: 'Newest' },
  { value: 'asc', label: 'Oldest' },
  { value: 'alphDesc', label: 'A-Z' },
  { value: 'alphAsc', label: 'Z-A' },
] as const);

const Assignments = ({
  selectedCourseId,
  statusFilter,
}: {
  selectedCourseId: string;
  statusFilter: string;
}) => {
  const { courseMap, openAssignmentCardSet, toggleAssignmentCardOpen, search } = useStore(
    (s) => s.TeacherAssignmentListData,
    [],
  );
  const courseIdToAssessmentListMap = useCourseIdToAssessmentListMap();
  const query = useQuery();

  const assessmentList = searchFilter(
    search,
    courseIdToAssessmentListMap.get(selectedCourseId, ImmutableList<AssessmentDetails>()),
    (ad: AssessmentDetails) => ad.assessment.name,
  );

  return (
    <Flex width="100%" height="100%" direction="column" align="center">
      {assessmentList.map((assignmentDetails) => {
        const { assessment, coverImageUrl, courseIds, primaryLicenseType, readingName } =
          assignmentDetails;
        const metrics = assignmentMetrics(assignmentDetails);

        return (
          <TeacherAssignmentCard
            key={assessment.id}
            assessment={assessment}
            courseIds={courseIds}
            courseMap={courseMap}
            canAssign={statusFilter !== 'archived'}
            to={`/t/assignments/${assessment.id}${queryMapToString(
              ImmutableMap({
                backPath: `/t/assignments${queryMapToString(query)}`,
                selectedClass: selectedCourseId,
              }),
            )}`}
            open={openAssignmentCardSet.has(assessment.id)}
            onToggleCardOpen={() => toggleAssignmentCardOpen(assessment.id)}
            coverImg={coverImageUrl}
            metrics={metrics}
            assignToPath={`/t/assignments/assign${updateQuery(query, {
              assessmentId: assessment.id,
            })}`}
            hideShare={primaryLicenseType === 'open_source'}
            readingName={readingName}
          />
        );
      })}
      <Spacer size={20} />
    </Flex>
  );
};

export const TeacherAssignmentList = () => {
  const history = useHistory();
  const pathname = useLocation().pathname;
  const query = useQuery();
  const { search, setField, loading, load, updateAssessments, currentUser } = useStore(
    (state) => ({
      search: state.TeacherAssignmentListData.search,
      setField: state.TeacherAssignmentListData.setField,
      loading: state.TeacherAssignmentListData.loading,
      load: state.TeacherAssignmentListData.load,
      updateAssessments: state.TeacherAssignmentListData.updateAssessments,
      currentUser: state.AppData.currentUser,
    }),
    [],
  );

  useEffect(() => {
    load(history, pathname);
  }, [load, history, pathname]);

  const selectedCourseId = query.get('selectedCourseId') || 'all';
  const assessmentStatusFilterParam = query.get('assessmentStatusFilter') || 'active';
  const sortByParam = query.get('sortBy') || 'desc';

  const updateSortBy = useCallback(
    (value: string) => history.push(`${pathname}${updateQuery(query, { sortBy: value })}`),
    [history, pathname, query],
  );

  const courseIdToAssessmentListMap = useCourseIdToAssessmentListMap();
  const courseTabs = useCourseTabs();

  const showMobile = useBreakpoints({ smallerThanOrEqualTo: 'mobileLarge' });
  const showTablet = useBreakpoints({ smallerThanOrEqualTo: 'tabletSmall' });

  return (
    <>
      <Flex
        width="100%"
        height="100%"
        minWidth={0}
        minHeight={0}
        direction="column"
        backgroundColor={colors.backgroundLight.hex}
        overflowY="auto"
      >
        <TeacherAssignmentListHeader />
        <CenteredLayout backgroundColor={colors.backgroundDark.hex} minWidth={0}>
          <Flex minWidth={0} maxWidth="100%" justify="center">
            <TabView skeumorphic queryParam="selectedCourseId" tabs={courseTabs} />
          </Flex>
          {showMobile && <Spacer size={5} />}
        </CenteredLayout>

        <CenteredLayout minHeight={0} height="100%" backgroundColor={colors.backgroundLight.hex}>
          {loading ? (
            <Loading flex kind="boat" />
          ) : (
            <div>
              {courseIdToAssessmentListMap
                .get(selectedCourseId, ImmutableList<AssessmentDetails>())
                .isEmpty() ? (
                <Flex width="100%" height="100%" direction="column" align="center">
                  <EmptyState
                    kind={emptyStateKind(
                      assessmentStatusFilterParam,
                      Boolean(currentUser?.paperSyncedAt),
                    )}
                  />
                </Flex>
              ) : (
                <>
                  <Flex
                    justify="space-between"
                    align="center"
                    height={showTablet ? 'auto' : '60px'}
                    direction={showTablet ? 'column-reverse' : 'row'}
                  >
                    <Flex
                      justify="start"
                      align="center"
                      margin={showTablet ? `${margins[3]} 0 0 0` : `${margins[4]} 0`}
                      width="100%"
                    >
                      <Text display="inline" color="gray6">
                        Sort By:
                      </Text>
                      <Spacer horizontal />
                      <SelectInput
                        id="sortBySelect"
                        displayType="flat"
                        name="Sort Assessments By"
                        value={sortByParam}
                        onChange={updateSortBy}
                        options={sortOptions}
                      />
                    </Flex>
                    <SearchInput
                      value={search}
                      onChange={setField('search')}
                      placeholder="Search Assignments"
                      displayType={showTablet ? 'large' : 'normal'}
                    />
                    {showTablet && <Spacer size={3} />}
                  </Flex>
                  <Assignments
                    selectedCourseId={selectedCourseId}
                    statusFilter={assessmentStatusFilterParam}
                  />
                </>
              )}
            </div>
          )}
        </CenteredLayout>
        {currentUser?.paperId && <Footer accountType="teacher" />}
      </Flex>
      <AssignToModal
        openedPath="/t/assignments/assign"
        closedPath="/t/assignments"
        onClose={() => load(history, pathname)}
        preserveQuery
      />
      <UpdateAssignmentStatusModal onSuccess={updateAssessments} />
      <UpgradeAssignmentModal onSuccess={updateAssessments} />
      <ShareModal />
      <DuplicateModal />
    </>
  );
};
