import React from 'react';

import { Flex } from 'src/components';

export interface LayoutProps {
  children: React.ReactNode;
}

export const Center = ({ children }: LayoutProps) => (
  <Flex align="center" justify="center" height="100%">
    {children}
  </Flex>
);
