import { useHistory } from 'src/modules/Router';
import { Button, Flex, Spacer, Text } from 'src/components';
import { useStore } from 'src/Store';
import { SchoolChip } from './SchoolChip';

type ConfirmPageProps = {
  onConfirm: () => void;
  cancelLink: string;
};

export const ConfirmSchool = ({ onConfirm, cancelLink }: ConfirmPageProps) => {
  const { schools, districtMap } = useStore((s) => s.SchoolSearchData, []);
  const { savedSchools } = useStore((s) => ({ savedSchools: s.AppData.schools }), []);
  const history = useHistory();

  return (
    <>
      <Text>Is this your school?</Text>
      <Spacer size={2} />
      {schools.isEmpty()
        ? savedSchools?.map((school) => (
            <SchoolChip
              key={school.id}
              school={school}
              district={districtMap.get(school?.districtId || '', null)}
            />
          ))
        : schools.map((school) => (
            <SchoolChip
              key={school.id}
              school={school}
              district={districtMap.get(school?.districtId || '', null)}
            />
          ))}
      <Spacer size={4} />

      <Flex justify="end">
        <Button onClick={() => history.replace(cancelLink)}>Change</Button>
        <Spacer horizontal size={2} />
        <Button onClick={onConfirm} disabled={schools.size === 0 && savedSchools.size === 0}>
          Confirm
        </Button>
      </Flex>
    </>
  );
};
