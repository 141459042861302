import { useHistory } from 'src/modules/Router';
import {
  Text,
  Flex,
  FlexGrow,
  TaskButton,
  Spacer,
  Button,
  PremiumTooltip,
  UpgradeAssignmentModal,
  Tooltip,
} from 'src/components';
import { ENTERPRISE_PLAN_LABEL, usageCapFeatureEnabled } from 'src/models';
import { useStore } from 'src/Store';

export const NewTaskSideBar = () => {
  const {
    createComprehensionTask,
    createModelReading,
    creatingTask,
    cancelTask,
    createVocabTask,
    assessment,
    currentUser,
    currentPlan,
    startAssignmentUpgrade,
    upgradeAssessmentAndStartVocab,
  } = useStore(
    ({ AssignmentBuilderData: s, AppData: ad, UpgradeAssignmentModalData: ua }) => ({
      upgradeAssessmentAndStartVocab: s.upgradeAssessmentAndStartVocab,
      assessment: s.assessment,
      cancelTask: s.cancelTask,
      createComprehensionTask: s.createComprehensionTask,
      createModelReading: s.createModelReading,
      createVocabTask: s.createVocabTask,
      creatingTask: s.creatingTask,
      currentUser: ad.currentUser,
      currentPlan: ad.currentPlan,
      startAssignmentUpgrade: ua.startAssignmentUpgrade,
    }),
    [],
  );
  const history = useHistory();
  const vocabEnabled = usageCapFeatureEnabled(
    'isVocabularyEnabled',
    currentUser,
    assessment,
    false,
  );
  const usageCapsEnabled = currentPlan.isUsageCapsEnabled;
  const availableUltimateAssessments = currentUser?.ultimateAssessmentsAvailable ?? 0;
  const canUseUltimateAssessment = usageCapsEnabled && availableUltimateAssessments > 0;
  const hasModelTask = !assessment?.taskOrder.filter((task) => task.type === 'ModelTask').isEmpty();

  return (
    <Flex grow={1} direction="column">
      <FlexGrow>
        <Text variant="h6" textTransform="uppercase">
          New Task
        </Text>
        <Spacer />
        <Text variant="h2">Select Task Format</Text>
        <Spacer />
        <TaskButton
          active={false}
          icon="task"
          onClick={() => createComprehensionTask(history)}
          loading={creatingTask}
        >
          Comprehension Question
        </TaskButton>
        <Tooltip
          content="You can only add one model reading per assignment"
          disabled={!hasModelTask}
          containerWidthOverride="100%"
        >
          <TaskButton
            active={false}
            icon="microphone"
            onClick={() => createModelReading(history)}
            loading={creatingTask}
            disabled={hasModelTask}
          >
            Model Reading
          </TaskButton>
        </Tooltip>
        <PremiumTooltip
          enabled={!vocabEnabled}
          content={`Upgrade to ${ENTERPRISE_PLAN_LABEL} and unlock vocabulary scaffolding`}
          linkLocation="contact"
          display="flex"
          usageCapsEnabled={usageCapsEnabled}
          availableUltimateAssessments={availableUltimateAssessments}
          onClick={() => startAssignmentUpgrade(assessment?.id ?? '', 'vocabTask')}
        >
          <TaskButton
            disabled={!vocabEnabled && !canUseUltimateAssessment}
            active={false}
            icon="vocab"
            onClick={
              !vocabEnabled && !assessment?.isUltimate
                ? () => startAssignmentUpgrade(assessment?.id ?? '', 'vocabTask')
                : () => createVocabTask(history)
            }
            rightText={vocabEnabled ? '' : ENTERPRISE_PLAN_LABEL}
            rightTextColor="gray5"
          >
            Vocabulary
          </TaskButton>
        </PremiumTooltip>
      </FlexGrow>
      <Button onClick={() => cancelTask()} displayType="outline" color="white">
        Cancel
      </Button>
      <UpgradeAssignmentModal onSuccess={upgradeAssessmentAndStartVocab(history)} />
    </Flex>
  );
};
