import { subDataCreator } from 'src/State';
import { defaultState } from './PlaylistManagerState';
import { ImmutableList, ImmutableMap } from 'src/modules/Immutable';
import { emptyPlaylist, Playlist } from 'src/models/Playlist';
import { ErrorMapResponse, emptyErrorMap } from 'src/modules/Api';

type PlaylistManagerData = {
  playlists: ImmutableList<Playlist>;
};

export const createDefaultPlaylistManagerData = subDataCreator(
  'PlaylistManagerData',
  defaultState,
  ({ get, set, setField, fetchJson }) => ({
    setField,
    load: () => {
      set(defaultState);
      fetchJson(`/api/playlists`, {
        onSuccess: (data: PlaylistManagerData) => set({ ...data, loading: false }),
      });
    },
    setEditingPlaylistField: (key) => (value) => {
      const playlist = get().editingPlaylist;
      if (!playlist) return;

      set({ editingPlaylist: { ...playlist, [key]: value } });
    },
    startCreate: () => {
      set((state) => ({
        errors: emptyErrorMap(),
        editingPlaylist: {
          ...emptyPlaylist(),
          ranking: (state.playlists.max((p) => p.ranking)?.ranking ?? 0) + 1,
        },
      }));
    },
    savePlaylist: () => {
      const { editingPlaylist, playlists } = get();
      if (!editingPlaylist) return;

      const method = editingPlaylist.id ? 'PATCH' : 'POST';
      const url = `/api/playlists/${editingPlaylist.id || ''}`;

      fetchJson(url, {
        method,
        data: { playlist: editingPlaylist },
        onSuccess: ({
          success,
          errors,
          playlist,
        }: {
          success: boolean;
          errors: ErrorMapResponse;
          playlist: Playlist;
        }) => {
          if (success) {
            set({
              editingPlaylist: null,
              playlists: playlists.filter((p) => p.id !== playlist.id).push(playlist),
            });
          } else {
            set({ errors: ImmutableMap(errors) });
          }
        },
      });
    },
    updatePlaylistRank: (playlist, ranking) => {
      fetchJson(`/api/playlists/${playlist.id}`, {
        method: 'PATCH',
        data: { playlist: { ...playlist, ranking } },
        onSuccess: ({ success, playlist }: { success: boolean; playlist: Playlist }) => {
          if (success) {
            set({
              editingPlaylist: null,
              playlists: get()
                .playlists.filter((p) => p.id !== playlist.id)
                .push(playlist),
            });
          }
        },
      });
    },
    confirmDelete: () => {
      const { deletingPlaylist, playlists } = get();
      if (!deletingPlaylist) return;

      set({
        deletingPlaylist: null,
        playlists: playlists.filter((p) => p.id !== deletingPlaylist.id),
      });

      fetchJson(`/api/playlists/${deletingPlaylist.id}`, {
        method: 'PATCH',
        data: {
          playlist: {
            ...deletingPlaylist,
            status: 'deleted',
            enabled: false,
          },
        },
      });
    },
  }),
);
