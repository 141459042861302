import { Spacer, TaskCard } from 'src/components';
import { isTaskSubmissionCompleted, User, TaskDetails } from 'src/models';
import { ImmutableList } from 'src/modules/Immutable';
import { useQuery } from 'src/modules/Router';
import { useStore } from 'src/Store';

export const AssignmentQuestionList = () => {
  const {
    assessmentDetail,
    taskDetailsMap,
    studentToTaskSubmissionDetailsMap,
    userMap,
    openTaskCardSet,
    courseToStudentIdsMap,
    submissionMap,
    toggleTaskCardOpen,
  } = useStore(
    ({ TeacherAssignmentOverviewData: taod }) => ({
      assessmentDetail: taod.assessmentDetail,
      taskDetailsMap: taod.taskDetailsMap,
      studentToTaskSubmissionDetailsMap: taod.studentToTaskSubmissionDetailsMap,
      userMap: taod.userMap,
      openTaskCardSet: taod.openTaskCardSet,
      courseToStudentIdsMap: taod.courseToStudentIdsMap,
      submissionMap: taod.submissionMap,
      toggleTaskCardOpen: taod.toggleTaskCardOpen,
    }),
    [],
  );

  const query = useQuery();
  const selectedClassParam = query.get('selectedClass') || 'all';

  if (!assessmentDetail) return null;

  return (
    <div>
      {assessmentDetail.assessment.taskOrder
        .map(({ taskId }) => taskDetailsMap.get(taskId))
        .filter((el): el is TaskDetails => Boolean(el))
        .map((taskDetails) => {
          const completedStudents = studentToTaskSubmissionDetailsMap
            .filter((taskSubmissionDetailsMap) => {
              const taskSubmissionDetails = taskSubmissionDetailsMap.get(taskDetails.task.id);
              return taskSubmissionDetails && isTaskSubmissionCompleted(taskSubmissionDetails);
            })
            .map((_v, userId) => userMap.get(userId))
            .toList()
            .filter((user): user is User => {
              if (!user) return false;
              if (user.accountType !== 'student' && !submissionMap.has(user.id)) {
                return false;
              }
              if (selectedClassParam === 'teachers') {
                return user.accountType !== 'student';
              }
              return (
                selectedClassParam === 'all' ||
                courseToStudentIdsMap.get(selectedClassParam, ImmutableList()).includes(user.id)
              );
            });
          const incompleteStudents = userMap.toList().filter((user) => {
            if (completedStudents.contains(user)) return false;
            if (user.accountType !== 'student' && !submissionMap.has(user.id)) {
              return false;
            }
            if (selectedClassParam === 'teachers') {
              return user.accountType !== 'student';
            }
            return (
              selectedClassParam === 'all' ||
              courseToStudentIdsMap.get(selectedClassParam, ImmutableList()).includes(user.id)
            );
          });
          return (
            <TaskCard
              key={taskDetails.task.id}
              taskDetails={taskDetails}
              completedStudents={completedStudents}
              incompleteStudents={incompleteStudents}
              open={openTaskCardSet.contains(taskDetails.task.id)}
              onToggleCardOpen={toggleTaskCardOpen(taskDetails.task.id)}
            />
          );
        })}
      <Spacer size={22} />
    </div>
  );
};
