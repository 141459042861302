import { Flex, Text, Spacer, LinkButton, FlexGrow, useToaster } from 'src/components';
import { useStore } from 'src/Store';
import { useCommonStateValues } from './AssignToModalHelpers';

export const PreAssignSelector = () => {
  const { updateCourseAssessment } = useStore((s) => s.AssignToData, []);
  const { hasCourseAssessment } = useCommonStateValues();
  const { createToast } = useToaster();

  return hasCourseAssessment ? (
    <Flex justify="center" align="center" direction="column" grow={1}>
      <FlexGrow />
      <Text variant="h4" color="success">
        Assigned!
      </Text>
      <Spacer size={4} />
      <Text textAlign="center">
        Students will automatically be assigned this reading as they join this class
      </Text>
    </Flex>
  ) : (
    <Flex justify="center" align="center" direction="column" height="100%">
      <Flex justify="center">
        <Text display="inline" textAlign="center">
          Click
        </Text>
        <Spacer horizontal />
        <LinkButton display="inline" onClick={updateCourseAssessment(createToast)}>
          Assign Class
        </LinkButton>
      </Flex>
      <Text display="inline" textAlign="center">
        to add students automatically as they join this class
      </Text>
    </Flex>
  );
};
