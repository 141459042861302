import { useEffect, useRef } from 'react';
import { useStore } from 'src/Store';
import { paddings, borderRadii, colors } from 'src/styles';
import { formatDateTime } from 'src/modules/Time';
import { CommentDetails } from 'src/models/Comment';
import {
  Flex,
  Spacer,
  Text,
  DropdownMenu,
  AudioPlayer,
  Reaction,
  FlexGrow,
  UserAvatar,
} from 'src/components';

export const Comment = ({
  commentDetails,
  isLast,
}: {
  commentDetails: CommentDetails;
  isLast: boolean;
}) => {
  const { deleteComment, currentUser, userMap, commentModalOpened, isRecording } = useStore(
    (s) => ({
      deleteComment: s.SubmissionOverviewData.deleteComment,
      userMap: s.SubmissionOverviewData.userMap,
      commentModalOpened: s.SubmissionOverviewData.commentModalOpened,
      currentUser: s.AppData.currentUser,
      isRecording: s.AppData.isRecording,
    }),
    [],
  );
  const bottomRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isLast && commentModalOpened) {
      bottomRef.current?.scrollIntoView({ behavior: 'auto' });
    }
  }, [isLast, bottomRef, commentModalOpened]);

  if (!currentUser) return null;
  const { comment, audioFile } = commentDetails;
  const commenter = userMap.set(currentUser.id, currentUser).get(comment.creatorId);
  if (!commenter) return null;

  return (
    <>
      <Flex align="start">
        <UserAvatar
          backgroundColor={commenter.avatarColor || colors.primaryBlue.hex}
          name={`${commenter.displayName} - ${formatDateTime(new Date(comment.createdAt))}`}
        />
        <Spacer size={2} horizontal />
        <Flex align="center" width="100%" testTag="comment">
          <Flex
            backgroundColor={colors.backgroundLight.hex}
            borderRadius={borderRadii[4]}
            padding={paddings[2]}
            align="center"
            justify="start"
            width="fit-content"
          >
            {comment.commentType === 'written' && (
              <FlexGrow>
                <Text color="black">{comment.writtenContent}</Text>
              </FlexGrow>
            )}
            {comment.commentType === 'recorded' && audioFile && (
              <FlexGrow>
                <Flex align="center">
                  <AudioPlayer
                    placeholder="Listen to the comment"
                    audioFileId={audioFile.id}
                    src={audioFile.url}
                    displayType="desktopSmall"
                    trackMinWidth="140px"
                    disabled={isRecording}
                    testTag="recorded-comment"
                  />
                </Flex>
              </FlexGrow>
            )}
            {comment.commentType === 'reaction' && (
              <Reaction kind={comment.reaction} size="large" />
            )}
          </Flex>
          <Spacer size={2} horizontal />
          <FlexGrow>
            <Flex justify="end">
              {currentUser.id === comment.creatorId && (
                <DropdownMenu
                  displayType="iconOnly"
                  color="backgroundLight"
                  iconColor="black"
                  icon="threeVerticalDots"
                  iconSize="1.5em"
                  testTag={`comment-settings-${comment.id}`}
                  noButtonPadding
                  rightAligned
                  disabled={isRecording}
                  options={[
                    {
                      children: 'Delete Comment',
                      onClick: () => deleteComment(comment.id),
                    },
                  ]}
                />
              )}
            </Flex>
          </FlexGrow>
        </Flex>
      </Flex>
      <Spacer size={2} />
      {isLast && <div ref={bottomRef} />}
    </>
  );
};
