import { subDataCreator } from 'src/State';
import { defaultState } from './MobileGateState';

type LoadResponse = {
  success: boolean;
};

export const createDefaultMobileGateData = subDataCreator(
  'MobileGateData',
  {},
  ({ fetchJson, set }) => ({
    ...defaultState,
    submit: (createToast) => () => {
      set({ sending: true });
      fetchJson(`/api/users/send_mobile_link_email`, {
        method: 'POST',
        data: { url: window.location.href },
        onSuccess: (data: LoadResponse) => {
          set({ sending: false });
          if (data.success) {
            createToast({
              children: 'Link sent!',
              color: 'success',
              duration: 1000,
            });
          } else {
            createToast({
              children: 'Link failed to send',
              color: 'danger',
              duration: 1000,
            });
          }
        },
      });
    },
  }),
);
