import { useEffect, useCallback, useMemo } from 'react';
import {
  Flex,
  FlexGrow,
  LinkButton,
  Spacer,
  TeacherAssignmentCard,
  TabView,
  SelectInput,
  Subscribe,
  Loading,
  Icon,
  Title,
  PremiumTooltip,
  Tooltip,
  Button,
  TabViewContent,
  Text,
  UpgradeAssignmentModal,
  Footer,
} from 'src/components';
import { CenteredLayout } from 'src/layouts';
import {
  updateQuery,
  useQuery,
  useHistory,
  useLocation,
  queryMapToString,
  useParams,
} from 'src/modules/Router';
import { assignmentMetrics, ENTERPRISE_PLAN_LABEL, ReadingTaskSubmissionDetails } from 'src/models';
import { ImmutableList } from 'src/modules/Immutable';
import { useStore } from 'src/Store';
import { paddings } from 'src/styles';
import { AssignToModal } from 'src/pages/AssignToModal';
import { UpdateAssignmentStatusModal } from 'src/pages/UpdateAssignmentStatusModal';
import { ReassignModal } from './ReassignModal';
import { formatDateTime } from 'src/modules/Time';
import { formatNumber, formatStat, statStyles } from 'src/modules/Stat';
import { downloadCSV } from 'src/modules/Csv';
import { makeQueryString, useFetch } from 'src/modules/Api';
import { AssignmentSubmissionList, useTableData } from './AssignmentSubmissionList';
import { AssignmentQuestionList } from './AssignmentQuestionList';
import { ShareModal } from 'src/pages/ShareModal';
import { DuplicateModal } from 'src/pages/DuplicateModal';
import { DeleteSubmissionModal } from 'src/pages/DeleteSubmissionModal';

const queryParam = 'viewId';

export const TeacherAssignmentOverview = () => {
  const { id } = useParams<{ id: string }>();
  const query = useQuery();
  const history = useHistory();
  const pathname = useLocation().pathname;
  const {
    load,
    assessmentDetail,
    submissionMap,
    assignmentCardOpen,
    userMap,
    courseMap,
    setField,
    updateSubmissionMap,
    loading,
    studentToTaskSubmissionDetailsMap,
    startReassigning,
    isCsvExportEnabled = false,
    isPaperUser,
  } = useStore(
    ({ AppData: ad, TeacherAssignmentOverviewData: taod }) => ({
      load: taod.load,
      assessmentDetail: taod.assessmentDetail,
      submissionMap: taod.submissionMap,
      assignmentCardOpen: taod.assignmentCardOpen,
      userMap: taod.userMap,
      courseMap: taod.courseMap,
      setField: taod.setField,
      updateSubmissionMap: taod.updateSubmissionMap,
      loading: taod.loading,
      studentToTaskSubmissionDetailsMap: taod.studentToTaskSubmissionDetailsMap,
      startReassigning: taod.startReassigning,
      isCsvExportEnabled: ad.currentUser?.flags.isCsvExportEnabled,
      isPaperUser: Boolean(ad.currentUser?.paperId),
    }),
    [],
  );

  useEffect(() => {
    load(id);
  }, [load, id]);

  const { fetchJson } = useFetch();

  const selectedClassParam = query.get('selectedClass') || 'all';
  const updateClassParam = useCallback(
    (value: string) => history.push(`${pathname}${updateQuery(query, { selectedClass: value })}`),
    [history, pathname, query],
  );
  const backPath = query.get('backPath') || '/t/assignments';

  const selectedClassOptions = useMemo(() => {
    const courseFilters = courseMap.toList().map((c) => ({ value: c.id, label: c.name }));
    const teacherFilter = submissionMap.some((s) =>
      userMap
        .filter((u) => u.accountType !== 'student')
        .keySeq()
        .includes(s.userId),
    )
      ? [{ value: 'teachers', label: 'Teacher Submissions' }]
      : [];
    const allFilter = { value: 'all', label: 'All Classes' };

    return courseFilters.unshift(allFilter).concat(teacherFilter);
  }, [courseMap, submissionMap, userMap]);

  const { rows, teacherRows, columns, getRowId } = useTableData();

  const studentData = useMemo(
    () =>
      rows.map((user) => {
        const submission = submissionMap.get(user.id);
        const taskSubmissions = studentToTaskSubmissionDetailsMap.get(user.id);
        const readingTaskSubmissionDetails = taskSubmissions?.find(
          (task) => task.type === 'ReadingTask',
        ) as ReadingTaskSubmissionDetails;

        return [
          user.familyName || user.displayName,
          user.givenName || '',
          submission && submission.wcpm !== 0
            ? formatStat(statStyles.completion, submission.percentRead)
            : '',
          submission && submission.wcpm !== 0
            ? formatStat(
                assessmentDetail?.assessment.independentOnly ? statStyles.wpm : statStyles.wcpm,
                submission.wcpm,
              )
            : '',
          submission && submission.totalWords !== 0
            ? formatStat(statStyles.total_words, submission.totalWords)
            : '',
          submission && submission.timeRead !== 0
            ? formatStat(statStyles.time_taken, submission.timeRead)
            : '',
          submission ? formatDateTime(new Date(submission.finishedAt)) : '',
          ...(readingTaskSubmissionDetails
            ? [
                formatStat(
                  statStyles.time_taken,
                  readingTaskSubmissionDetails.taskSubmission.readingTime,
                ),
                formatStat(
                  statStyles.time_taken,
                  readingTaskSubmissionDetails.taskSubmission.emptyTime,
                ),
                formatNumber(readingTaskSubmissionDetails.taskSubmission.wordAssignedCount),
                formatNumber(readingTaskSubmissionDetails.taskSubmission.uniqueWordAssignedCount),
                formatNumber(readingTaskSubmissionDetails.taskSubmission.correctWordSaidCount),
                formatNumber(readingTaskSubmissionDetails.taskSubmission.addedWordSaidCount),
                formatNumber(readingTaskSubmissionDetails.taskSubmission.missedWordSaidCount),
                formatNumber(readingTaskSubmissionDetails.taskSubmission.replacedWordSaidCount),
                formatStat(
                  statStyles.wcpm,
                  readingTaskSubmissionDetails.taskSubmission.wordsPerMinute,
                ),
                formatStat(
                  statStyles.completion,
                  readingTaskSubmissionDetails.taskSubmission.correctCompletion,
                ),
              ]
            : []),
        ];
      }),
    [rows, submissionMap, assessmentDetail, studentToTaskSubmissionDetailsMap],
  );

  if (!assessmentDetail) return null;

  const { assessment, coverImageUrl, courseIds, readingName } = assessmentDetail;

  const columnNames = [
    'Last Name/Username',
    'First Name',
    '% Read',
    'WCPM',
    'Words',
    'Time',
    'Date',
    `Reading Time`,
    `Non Reading Time`,
    `Words Assigned`,
    `Unique Words Assigned`,
    `Correct Words Read`,
    `Words Added`,
    `Words Missed`,
    `Words Replaced`,
    `Words Per Minute`,
    `Accuracy`,
  ];

  const fileData = studentData.insert(0, columnNames);

  const tabs = ImmutableList([
    {
      id: 'summary',
      name: `Summary`,
      content: (
        <AssignmentSubmissionList
          rows={rows}
          teacherRows={teacherRows}
          columns={columns}
          getRowId={getRowId}
          assessment={assessment}
          submissionMap={submissionMap}
        />
      ),
    },
    {
      id: 'tasks',
      name: `Task Details`,
      content: <AssignmentQuestionList />,
    },
  ]);

  const metrics = assignmentMetrics(assessmentDetail);
  const previewPath = `/reading-studio${makeQueryString({
    assessmentId: assessment?.id,
    backPath: `${pathname}${queryMapToString(query)}`,
    showInstructions: true,
  })}`;

  const hasCsvExport = Boolean(isCsvExportEnabled);
  const TooltipElem = hasCsvExport ? Tooltip : PremiumTooltip;
  const tooltipProps = {
    content: hasCsvExport
      ? 'Export Table as CSV'
      : `Upgrade to ${ENTERPRISE_PLAN_LABEL} to seamlessly export all your data`,
    linkLocation: 'contact' as const,
  };

  const content = loading ? (
    <Loading flex kind="boat" />
  ) : (
    <>
      {submissionMap.toList().map((s) => (
        <Subscribe
          key={s.id}
          channel="SubmissionChannel"
          id={s.id}
          onMessage={updateSubmissionMap}
        />
      ))}
      <Flex align="center" padding={`${paddings[4]} 0`}>
        <LinkButton color="black" to={backPath} icon="backArrow">
          Back
        </LinkButton>
        <FlexGrow />
        <Button color="primaryBlue" displayType="link" onClick={startReassigning}>
          Reassign
        </Button>
        <Tooltip
          iconColor="primaryBlue"
          content={
            <>
              <Text textAlign="left">
                Reassign an assignment to all students originally assigned to easily benchmark
                students with the same texts
              </Text>
              <Spacer size={3} />
              <Text textAlign="left">
                Looking to fix a student&apos;s submission or have them resubmit?{' '}
                <LinkButton
                  paddingOverride={paddings.none}
                  newTab
                  href="https://readleesupport.zendesk.com/hc/en-us/articles/14127695073677"
                >
                  Click Here
                </LinkButton>
              </Text>
            </>
          }
        />
        <Spacer horizontal size={7} />
        <LinkButton
          color="primaryBlue"
          icon="preview"
          iconPosition="right"
          iconSize="1.5em"
          to={previewPath}
        >
          Preview
        </LinkButton>
        <Spacer horizontal size={7} />
        <LinkButton color="primaryBlue" to={`/t/assignment-builder/${assessment.id}`}>
          Edit Assignment
        </LinkButton>
        <Spacer horizontal size={4} />
        <TooltipElem enabled {...tooltipProps} display="flex">
          <Button
            displayType="link"
            icon="download"
            iconSize="1.25em"
            iconPosition="right"
            color="primaryBlue"
            width="100%"
            disabled={!isCsvExportEnabled}
            onClick={downloadCSV({
              data: fileData,
              filename: `${assessment.name.substring(0, 20)}-progress-report`,
              fetchJson,
            })}
          >
            Export CSV
          </Button>
        </TooltipElem>
      </Flex>

      <TeacherAssignmentCard
        key={assessment.id}
        assessment={assessment}
        courseIds={courseIds}
        courseMap={courseMap}
        open={assignmentCardOpen}
        onToggleCardOpen={() => setField('assignmentCardOpen')(!assignmentCardOpen)}
        coverImg={coverImageUrl}
        metrics={metrics}
        canAssign={assessment.status !== 'archived'}
        assignToPath={`/t/assignments/${assessment.id}/assign${updateQuery(query, {
          assessmentId: assessment.id,
        })}`}
        hideViewDetails
        hideShare={assessmentDetail.primaryLicenseType === 'open_source'}
        readingName={readingName}
      />
      <Spacer size={4} />
      <Flex justify="space-between" align="center">
        <TabView noContent tabs={tabs} queryParam={queryParam} />
        <Flex align="center">
          <Icon
            icon="filter"
            color="readleeBlue"
            fill={selectedClassParam !== 'all'}
            strokeWidth={1.5}
            strokeLinecap="round"
          />
          <Spacer horizontal />
          <SelectInput
            id="sortBySelect"
            displayType="flat"
            name="Sort Assessments By"
            value={selectedClassParam}
            onChange={updateClassParam}
            options={selectedClassOptions}
          />
        </Flex>
      </Flex>
      <Spacer size={2} />

      <TabViewContent tabs={tabs} queryParam={queryParam} />

      <AssignToModal
        openedPath={`/t/assignments/${assessment.id}/assign`}
        closedPath={`/t/assignments/${assessment.id}`}
        onClose={() => load(assessment.id)}
      />
      <ShareModal />
      <DuplicateModal />
      <ReassignModal />
      <UpdateAssignmentStatusModal />
      <UpgradeAssignmentModal onSuccess={({ id }) => load(id)} />
      <DeleteSubmissionModal />
    </>
  );

  return (
    <>
      <Title title={assessment.name} />
      <CenteredLayout overflowY="auto">{content}</CenteredLayout>
      {isPaperUser && <Footer accountType="teacher" />}
    </>
  );
};
