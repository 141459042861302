import styled, { css } from 'styled-components';
import { colors, Height, Width } from 'src/styles';
import { Flex } from 'src/components';
import Lottie from 'lottie-react';
import boatAnimation from 'src/assets/lottie/paper-boat.json';

type LoadingProps = {
  darkBg?: boolean;
  size?: 'x-small' | 'small' | 'medium' | 'large';
  flex?: boolean;
  flexWidth?: Width;
  flexHeight?: Height;
  kind?: 'spinner' | 'boat';
};

const ChildStyles = css<{ darkBg: boolean; spinnerWidth: number }>`
  position: absolute;
  width: 100%;
  height: 100%;
  max-width: 100px;
  max-height: 100px;
  margin: 8px;
  border: ${({ spinnerWidth }) => spinnerWidth}px solid;
  border-radius: 50%;
  animation: ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;

  ${({ darkBg }) =>
    `border-color: ${
      darkBg ? colors.white.hex : colors.readleeBlue.hex
    } transparent transparent transparent;`}
`;

const SpinnerStyles = styled.div<{ darkBg: boolean; sizeNumber: number; spinnerWidth: number }>`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: ${({ sizeNumber }) => sizeNumber}px;
  height: ${({ sizeNumber }) => sizeNumber}px;

  div {
    ${ChildStyles}
  }

  div:nth-child(1) {
    animation-delay: -0.45s;
  }

  div:nth-child(2) {
    animation-delay: -0.3s;
  }

  div:nth-child(3) {
    animation-delay: -0.15s;
  }

  @keyframes ring {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
`;

const Spinner = ({
  darkBg,
  sizeNumber,
  spinnerWidth,
}: {
  darkBg: boolean;
  sizeNumber: number;
  spinnerWidth: number;
}) => (
  <SpinnerStyles darkBg={darkBg} sizeNumber={sizeNumber} spinnerWidth={spinnerWidth}>
    <div />
    <div />
    <div />
    <div />
  </SpinnerStyles>
);

const computeSize = (size: 'x-small' | 'small' | 'medium' | 'large') => {
  if (size === 'x-small') {
    return { sizeNumber: 10, spinnerWidth: 2 };
  } else if (size === 'small') {
    return { sizeNumber: 15, spinnerWidth: 2 };
  } else if (size === 'medium') {
    return { sizeNumber: 25, spinnerWidth: 4 };
  } else if (size === 'large') {
    return { sizeNumber: 50, spinnerWidth: 6 };
  } else {
    throw new Error(`Unrecognized spinner size ${size}`);
  }
};

export const Loading = ({
  darkBg = false,
  size = 'large',
  flex = false,
  flexWidth = '100%',
  flexHeight = '100%',
  kind = 'spinner',
}: LoadingProps) => {
  const { sizeNumber, spinnerWidth } = computeSize(size);

  const component =
    kind === 'spinner' ? (
      <Spinner darkBg={darkBg} sizeNumber={sizeNumber} spinnerWidth={spinnerWidth} />
    ) : (
      <Lottie animationData={boatAnimation} style={{ width: `${sizeNumber * 3}px` }} />
    );

  return flex ? (
    <Flex justify="center" align="center" height={flexHeight} width={flexWidth}>
      {component}
    </Flex>
  ) : (
    component
  );
};
