import {
  Assessment,
  Course,
  SubmissionExtended,
  TaskDetails,
  TaskSubmissionDetails,
  TaskType,
  User,
} from 'src/models';
import { ImmutableList, ImmutableMap } from 'src/modules/Immutable';
import { SetField } from 'src/State';

export type LoadResponse = {
  success: boolean;
  assessment: Assessment;
  taskDetails: TaskDetails | null;
  submissionMap: ImmutableMap<string, SubmissionExtended>;
  studentIdToAssignedCoursesMap: ImmutableMap<string, ImmutableList<Course>>;
  submissionIdToTaskSubmissionDetailsMap: ImmutableMap<string, TaskSubmissionDetails>;
  userMap: ImmutableMap<string, User>;
  courseMap: ImmutableMap<string, Course>;
};

export type TaskOverviewState = {
  loading: boolean;
  assessment: Assessment | null;
  taskDetails: TaskDetails | null;
  submissionMap: ImmutableMap<string, SubmissionExtended>;
  studentIdToAssignedCoursesMap: ImmutableMap<string, ImmutableList<Course>>;
  submissionIdToTaskSubmissionDetailsMap: ImmutableMap<string, TaskSubmissionDetails>;
  taskCardOpen: boolean;
  userMap: ImmutableMap<string, User>;
  courseMap: ImmutableMap<string, Course>;
  load: (id: string, type: TaskType) => void;
  setField: SetField<TaskOverviewState>;
  upgradeAssessment: () => void;
};

export const defaultState = {
  loading: true,
  assessment: null,
  taskDetails: null,
  submissionMap: ImmutableMap<string, SubmissionExtended>(),
  studentIdToAssignedCoursesMap: ImmutableMap<string, ImmutableList<Course>>(),
  submissionIdToTaskSubmissionDetailsMap: ImmutableMap<string, TaskSubmissionDetails>(),
  taskCardOpen: true,
  userMap: ImmutableMap<string, User>(),
  courseMap: ImmutableMap<string, Course>(),
};
