import { debounce } from 'lodash';
import { ImmutableList, ImmutableMap } from 'src/modules/Immutable';
import { subDataCreator } from 'src/State';
import {
  Assessment,
  AssessmentExtended,
  GradeLevel,
  ReadingSearchResult,
  User,
  bandsToGradeLevelIds,
} from 'src/models';
import { defaultState, SearchParams } from './ContentLibraryState';
import { wordRangeMap } from './ContentLibraryHelpers';
import { CONTINUE_READING_PLAYLIST, Playlist } from 'src/models/Playlist';
import { queryMapToString } from 'src/modules/Router';

type SearchResponse = {
  playlists: ImmutableList<Playlist>;
  gradeLevels: ImmutableList<GradeLevel>;
  results: ImmutableList<ReadingSearchResult>;
  playlistResults: ImmutableMap<string, ImmutableList<ReadingSearchResult>>;
  submissionMap: ImmutableMap<string, string>;
  continueReadingMap: ImmutableMap<string, ReadingSearchResult>;
  total: number;
};

type GetAssessmentsData = {
  assessments: ImmutableList<AssessmentExtended>;
  userMap: ImmutableMap<string, User>;
};

export const createDefaultContentLibraryData = subDataCreator(
  'ContentLibraryData',
  defaultState,
  ({ set, get, setField, fetchJson }) => {
    const search = debounce((params: SearchParams) => {
      set({ loading: true });
      const {
        searchText,
        gradeBands,
        ownershipTypes,
        pageNumber,
        wordCounts,
        tagIds,
        additionalFilters,
      } = params;
      fetchJson('/api/readings/search', {
        method: 'POST',
        data: {
          query: searchText,
          gradeLevelIds: bandsToGradeLevelIds(gradeBands, get().gradeLevels),
          ownershipTypes,
          page: pageNumber,
          wordCounts: wordCounts.map((key: string) => wordRangeMap.get(key)),
          tagIds,
          additionalFilters,
        },
        onSuccess: (data: SearchResponse) => {
          set({
            searchResults: data.results,
            playlistResults: data.playlistResults,
            totalResults: data.total,
            playlists: data.playlists.push(CONTINUE_READING_PLAYLIST(data.continueReadingMap.size)),
            gradeLevels: data.gradeLevels,
            submissionMap: data.submissionMap,
            continueReadingMap: data.continueReadingMap,
            loading: false,
            initialSearch: false,
          });
        },
      });
    }, 200);

    return {
      load: () => {
        set((s) => ({ ...defaultState, gradeLevels: s.gradeLevels }));
        if (get().gradeLevels.isEmpty()) {
          fetchJson(`/api/grade_levels`, {
            method: 'GET',
            onSuccess: (data: { gradeLevels: ImmutableList<GradeLevel> }) => {
              set({ gradeLevels: data.gradeLevels, gradeLevelsLoading: false });
            },
          });
        } else {
          set({ gradeLevelsLoading: false });
        }
      },
      search,
      setField,
      getAssignments: (result, onSuccess) => {
        set({ selectionModalOpen: true, assignmentsLoading: true, selectedSearchResult: result });
        fetchJson(`/api/readings/${result.reading.id}/assessments`, {
          method: 'GET',
          onSuccess: (data: GetAssessmentsData) => {
            set({
              assignmentsLoading: false,
              assessmentResults: data.assessments,
              userMap: data.userMap,
            });
            onSuccess();
          },
        });
      },
      launchStudentLibraryReading: (id, history, query, pathname, existingAssessmentId) => {
        const { submissionMap } = get();
        const submissionId = submissionMap.get(id);
        if (existingAssessmentId) {
          history.push(
            `/reading-studio${queryMapToString({
              backPath: `${pathname}${queryMapToString(query)}`,
              assessmentId: existingAssessmentId,
            })}`,
          );
        } else if (submissionId) {
          history.push(
            `/submissions/${submissionId}${queryMapToString({
              backPath: `${pathname}${queryMapToString(query)}`,
            })}`,
          );
        } else {
          fetchJson(`/api/free_reads`, {
            method: 'POST',
            data: { reading_id: id },
            onSuccess: ({ success, assessment }: { success: boolean; assessment: Assessment }) => {
              if (success) {
                history.push(
                  `/reading-studio${queryMapToString({
                    backPath: `${pathname}${queryMapToString(query)}`,
                    assessmentId: assessment.id,
                  })}`,
                );
              }
            },
          });
        }
      },
    };
  },
);
