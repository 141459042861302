import { SimpleLayout } from 'src/layouts';
import {
  FormGroup,
  FormRow,
  Button,
  LinkButton,
  Form,
  WordDivider,
  GoogleButton,
  CleverButton,
  Text,
  Spacer,
  Flex,
  ErrorText,
} from 'src/components';
import { useStore } from 'src/Store';
import { useHistory, useParams, useQuery, queryMapToString } from 'src/modules/Router';
import { AccountType } from 'src/models';
import { useEffect } from 'react';

export const SignUpRegister = () => {
  const history = useHistory();
  const { accountType } = useParams<{ accountType: AccountType }>();
  const query = useQuery();
  const courseCode = query.get('courseCode') || '';
  const schoolCode = query.get('schoolCode') || '';
  const redirectPathParam = query.get('redirectPath') || '';
  const redirectPath = redirectPathParam || '/';

  const {
    loading,
    errors,
    user,
    course,
    school,
    setUserField,
    submit,
    submitting,
    load,
    fieldsMissing,
  } = useStore(
    ({ SignUpRegisterData: surd }) => ({
      loading: surd.loading,
      errors: surd.errors,
      user: surd.user,
      course: surd.course,
      school: surd.school,
      setUserField: surd.setUserField,
      submit: surd.submit,
      submitting: surd.submitting,
      load: surd.load,
      fieldsMissing: surd.fieldsMissing,
    }),
    [],
  );

  useEffect(
    () => load({ accountType, courseCode, schoolCode, query, history }),
    [accountType, courseCode, load, query, history, schoolCode],
  );

  const referralCode = query.get('referralCode');
  const restrictFields = fieldsMissing.size > 0;
  const userAttributes = {
    accountType,
    courseCode,
    courseCodeDeclined: !courseCode,
    referralCode: referralCode,
  };

  useEffect(() => {
    if (loading) return;

    if (user.accountType === 'student' && !course) {
      history.push(`/sign-up/student/class-code${queryMapToString(query)}`);
    }
    if (user.accountType === 'teacher' && !school) {
      history.push(`/sign-up/teacher/school-code${queryMapToString(query)}`);
    }
  }, [course, courseCode, history, loading, query, school, user.accountType]);

  return (
    <SimpleLayout loading={loading}>
      <Spacer size={2} />
      <Text variant="h1">Let&apos;s get you signed up with a {user.accountType} account!</Text>
      {!restrictFields && (
        <>
          <Spacer size={3} />
          <Text>Make sure you sign up using your school accounts!</Text>
          <Spacer />

          <GoogleButton
            scopeKey={accountType === 'student' ? 'just_name' : 'full_info'}
            userAttributes={userAttributes}
            forceOauth
            successUrl={redirectPath}
          >
            Sign Up with Google
          </GoogleButton>
          <Spacer size={2} />
          <CleverButton userAttributes={userAttributes} forceOauth successUrl={redirectPath}>
            Sign Up with Clever
          </CleverButton>
          <Spacer size={2} />
          <WordDivider>OR</WordDivider>
        </>
      )}
      <Spacer size={2} />
      <Form onSubmit={submit(history, query)}>
        {!course?.disableName && (!restrictFields || fieldsMissing.has('name')) && (
          <FormRow>
            <FormGroup
              name="name"
              label="First Name"
              errors={errors}
              type="text"
              value={user.firstName}
              onChange={setUserField('firstName')}
            />
            <Spacer horizontal />
            <FormGroup
              name="lastName"
              label="Last Name"
              errors={errors}
              type="text"
              value={user.lastName}
              onChange={setUserField('lastName')}
            />
          </FormRow>
        )}
        <Spacer size={2} />
        {(!restrictFields || fieldsMissing.has('email')) && (
          <>
            {course?.disableEmail ? (
              <FormGroup
                name="username"
                label="Username"
                errors={errors}
                type="text"
                value={user.username}
                onChange={setUserField('username')}
              />
            ) : (
              <FormGroup
                name="email"
                label="Email"
                errors={errors}
                type="email"
                value={user.email || ''}
                onChange={setUserField('email')}
              />
            )}
          </>
        )}

        {(!restrictFields || fieldsMissing.has('password')) && (
          <>
            <Spacer size={2} />
            <FormGroup
              name="newPassword"
              label="Password"
              errors={errors}
              type="password"
              value={user.newPassword}
              onChange={setUserField('newPassword')}
            />
          </>
        )}

        {course?.disableEmail && (!restrictFields || fieldsMissing.has('password')) && (
          <>
            <Spacer size={2} />
            <FormGroup
              name="passwordConfirmation"
              label="Confirm Password"
              errors={errors}
              type="password"
              value={user.passwordConfirmation}
              onChange={setUserField('passwordConfirmation')}
            />
          </>
        )}

        <Spacer size={3} />
        <ErrorText errors={errors} errorKey="accountType" />
        <Button loading={submitting} type="submit">
          Create Account
        </Button>
      </Form>
      <Spacer size={2} />
      <Text>By signing up for Readlee, you agree to</Text>
      <Flex display="inline-flex" align="center">
        <Text>our</Text>
        <Spacer horizontal />
        <LinkButton display="inline" href="https://www.readlee.com/terms-of-service">
          Terms of Service
        </LinkButton>
        <Spacer horizontal />
        <Text>and</Text>
        <Spacer horizontal />
        <LinkButton display="inline" href="https://www.readlee.com/privacy-policy">
          Privacy Policy
        </LinkButton>
      </Flex>
      <Spacer />
      {!restrictFields && (
        <Flex>
          <Text>Already a user?</Text>
          <Spacer horizontal />
          <LinkButton
            to={`/login${courseCode ? `?courseCode=${encodeURIComponent(courseCode)}` : ''}`}
          >
            Sign In
          </LinkButton>
        </Flex>
      )}
    </SimpleLayout>
  );
};
