import styled from 'styled-components';
import { PreviousTaskLink, NextTaskLink } from './ReadingStudioHelpers';

const ReadingStudioBottomBarStyles = styled.div`
  display: flex;
`;

export const ReadingStudioBottomBar = () => {
  return (
    <ReadingStudioBottomBarStyles>
      <PreviousTaskLink />
      <NextTaskLink />
    </ReadingStudioBottomBarStyles>
  );
};
