import { useEffect } from 'react';
import { useStore } from 'src/Store';
import { useTeacherStudentListHelpers } from '../../TeacherStudentListHelpers';
import { Button, LinkButton, Flex, Spacer, StandardModal, FormGroup } from 'src/components';

export const ChangePasswordModal = () => {
  const {
    errors,
    newPassword,
    setField,
    passwordConfirmation,
    changingPassword,
    changePassword,
    generatePassword,
    passwordHidden,
    loadChangePasswordModal,
  } = useStore((s) => s.TeacherStudentListData, []);
  const helpers = useTeacherStudentListHelpers();

  useEffect(() => {
    loadChangePasswordModal();
  }, [loadChangePasswordModal]);

  return (
    <StandardModal
      opened
      openType="state"
      header={`Change Password for ${helpers.selectedStudent?.displayName}`}
      close={helpers.closeModal}
      disableFadeClose
      footer={
        <Flex justify="end">
          <LinkButton onClick={helpers.closeModal}>Close</LinkButton>
          <Spacer horizontal size={3} />
          <Button disabled={changingPassword} onClick={() => changePassword(helpers)}>
            Save
          </Button>
        </Flex>
      }
    >
      <FormGroup
        type={passwordHidden ? 'password' : 'text'}
        label="New Password"
        name="newPassword"
        value={newPassword}
        onChange={setField('newPassword')}
        errors={errors}
      />
      <Spacer />
      <Flex width="100%" justify="space-between">
        {newPassword.length > 0 ? (
          <LinkButton onClick={() => setField('passwordHidden')(!passwordHidden)}>
            {passwordHidden ? 'Show' : 'Hide'} password
          </LinkButton>
        ) : (
          <div />
        )}
        <LinkButton onClick={generatePassword({ newUser: false })}>Generate Password</LinkButton>
      </Flex>
      <Spacer size={5} />
      <FormGroup
        type="password"
        label="Confirm Password"
        name="passwordConfirmation"
        value={passwordConfirmation}
        onChange={setField('passwordConfirmation')}
        errors={errors}
      />
    </StandardModal>
  );
};
