import { Spacer, Button, LinkButton, JoinCourseButton, Flex, FreeReadButton } from 'src/components';
import { ExtendedUser } from 'src/models';
import { useStore } from 'src/Store';
import { UltimateAssignmentsIndicator } from './UltimateAssignmentsIndicator';
import { appUrl } from 'src/config';
import { startZendeskTicket } from 'src/modules/Analytics/Analytics';
import { ImmutableList } from 'src/modules/Immutable';

export const NavButtons = () => {
  const currentUser = useStore((s) => s.AppData.currentUser, []);
  if (!currentUser?.accountType) return null;
  if (currentUser?.accountType === 'student')
    return (
      <Flex justify="space-around">
        <FreeReadButton displayType={currentUser?.paperSyncedAt ? 'button' : 'outline'} />
        {!currentUser?.paperSyncedAt && (
          <>
            <Spacer horizontal size={2} />
            <JoinCourseButton />
          </>
        )}
      </Flex>
    );

  return (
    <Flex justify="space-around" align="center">
      <UltimateAssignmentsIndicator />
      <Button color="primaryBlue" to="/t/reading-builder/content" height="40px">
        New Assignment
      </Button>
    </Flex>
  );
};

export const TopLevelNavLink = ({
  path,
  label,
  upperCase,
  textColor,
  ...baseButtonProps
}: {
  path?: string;
  label: string;
  href?: string;
  newTab?: boolean;
  upperCase?: boolean;
  textColor?: 'gray6' | 'white';
  onClick?: () => void;
}) => {
  return (
    <LinkButton
      nav
      to={path}
      color={textColor || 'white'}
      hoverColor="primaryBlue"
      activeColor="primaryBlue"
      linkTextStyle="linkNavBar"
      {...baseButtonProps}
    >
      {upperCase ? label.toUpperCase() : label}
    </LinkButton>
  );
};

export type TopBarLink = {
  showTeacher: boolean;
  showStudent: boolean;
  paperOnly?: boolean;
  readleeOnly?: boolean;
  dropdownLink: boolean;
  mobileOnly: boolean;
  desktopOnly: boolean;
  label: string;
  showModerator?: boolean;
  showAdmin?: boolean;
  newTab?: boolean;
  href?: string;
  path?: string;
  exact?: boolean;
  onClick?: () => void;
  studentRank?: number;
  teacherRank?: number;
};

const links = (currentUser: ExtendedUser): ImmutableList<TopBarLink> =>
  ImmutableList([
    {
      showTeacher: true,
      showStudent: true,
      dropdownLink: false,
      mobileOnly: false,
      desktopOnly: false,
      exact: currentUser.accountType === 'student',
      path: `${currentUser.accountType === 'student' ? '/s/assignments' : '/t/assignments'}`,
      label: 'Assignments',
      studentRank: currentUser.flags?.isStudentHomepageLibraryEnabled ? 2 : 1,
      teacherRank: 1,
    },
    {
      showTeacher: true,
      showStudent: false,
      dropdownLink: false,
      mobileOnly: false,
      desktopOnly: false,
      path: '/t/classes',
      label: 'Classes',
      teacherRank: 2,
    },
    {
      showTeacher: true,
      showStudent: false,
      dropdownLink: false,
      mobileOnly: false,
      desktopOnly: false,
      path: '/t/progress-monitoring',
      label: 'Progress Monitoring',
      teacherRank: 3,
    },
    {
      showTeacher: true,
      showStudent: currentUser.flags?.isStudentLibraryEnabled ?? false,
      dropdownLink: false,
      mobileOnly: false,
      desktopOnly: false,
      path: `${currentUser.accountType === 'student' ? '/s' : '/t'}/library`,
      label: 'Library',
      studentRank: currentUser.flags?.isStudentHomepageLibraryEnabled ? 1 : 2,
      teacherRank: 4,
    },
    {
      showTeacher: true,
      showStudent: true,
      dropdownLink: true,
      mobileOnly: false,
      desktopOnly: false,
      path: `${currentUser.accountType === 'student' ? '/s/' : '/t/'}profile`,
      label: 'My Profile',
      studentRank: 11,
      teacherRank: 10,
    },
    {
      showTeacher: false,
      showStudent: true,
      dropdownLink: true,
      mobileOnly: false,
      desktopOnly: false,
      path: '/s/avatar',
      label: 'Edit Avatar',
      studentRank: 10,
    },
    {
      showTeacher: true,
      showStudent: false,
      dropdownLink: true,
      mobileOnly: false,
      desktopOnly: false,
      onClick: startZendeskTicket,
      label: 'Contact Support',
      newTab: true,
      teacherRank: 11,
    },
    {
      showTeacher: true,
      showStudent: false,
      dropdownLink: true,
      mobileOnly: false,
      desktopOnly: false,
      newTab: true,
      href: 'https://readleesupport.zendesk.com/hc/en-us/',
      label: 'Help Center',
      teacherRank: 12,
    },
    {
      showTeacher: true,
      showStudent: true,
      dropdownLink: true,
      mobileOnly: false,
      desktopOnly: false,
      readleeOnly: true,
      path: '/logout',
      label: 'Log out',
      studentRank: 12,
      teacherRank: 13,
    },
    {
      showTeacher: true,
      showStudent: true,
      dropdownLink: true,
      mobileOnly: false,
      desktopOnly: false,
      paperOnly: true,
      path: '/back-to-paper',
      label: 'Back To Paper',
      studentRank: 12,
      teacherRank: 13,
    },
    {
      showTeacher: false,
      showStudent: false,
      showAdmin: true,
      dropdownLink: true,
      mobileOnly: false,
      desktopOnly: false,
      href: `${appUrl}/api/admin`,
      label: 'Admin Dashboard',
      teacherRank: 20,
    },
    {
      showTeacher: false,
      showStudent: false,
      showAdmin: true,
      showModerator: true,
      dropdownLink: true,
      mobileOnly: false,
      desktopOnly: false,
      path: `/m/user-search`,
      label: 'Admin User Search',
      teacherRank: 21,
    },
    {
      showTeacher: false,
      showStudent: false,
      showAdmin: true,
      showModerator: true,
      dropdownLink: true,
      mobileOnly: false,
      desktopOnly: false,
      path: `/m/playlists`,
      label: 'Playlist Manager',
      teacherRank: 22,
    },
  ] as const);

const filterByRole = (currentUser: ExtendedUser, l: TopBarLink, isPaperUser: boolean): boolean => {
  if (l.paperOnly && !isPaperUser) return false;
  if (l.readleeOnly && isPaperUser) return false;

  if (currentUser.accountType === 'student') {
    return l.showStudent;
  }

  if (currentUser.accountType === 'teacher') {
    return l.showTeacher;
  }

  if (currentUser.accountType === 'moderator') {
    return Boolean(l.showTeacher || l.showModerator);
  }

  if (currentUser.accountType === 'admin') {
    return Boolean(l.showAdmin || l.showModerator || l.showTeacher);
  }

  return false;
};

export const topLevelLinks = (currentUser: ExtendedUser, isPaperUser: boolean) =>
  links(currentUser)
    .filter(
      (l) =>
        filterByRole(currentUser, l, isPaperUser) &&
        !l.dropdownLink &&
        !l.mobileOnly &&
        !l.desktopOnly,
    )
    .sortBy((l) => (currentUser.accountType === 'student' ? l.studentRank : l.teacherRank));

export const dropdownLinks = (currentUser: ExtendedUser, isPaperUser: boolean) =>
  links(currentUser)
    .filter((l) => l.dropdownLink && filterByRole(currentUser, l, isPaperUser))
    .sortBy((l) => (currentUser.accountType === 'student' ? l.studentRank : l.teacherRank));

export const mobileNavLinks = (currentUser: ExtendedUser, isPaperUser: boolean) =>
  links(currentUser)
    .filter((l) => filterByRole(currentUser, l, isPaperUser) && !l.desktopOnly)
    .sortBy((l) => (currentUser.accountType === 'student' ? l.studentRank : l.teacherRank));
