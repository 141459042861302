import { ImmutableList, ImmutableMap } from 'src/modules/Immutable';
import { getMinutes } from 'src/modules/Time';
import { CountUp, IconSize, IconType } from 'src/components';
import { roundTo } from 'src/modules/Number';

export type StatType =
  | 'unique_words'
  | 'total_words'
  | 'wcpm'
  | 'wpm'
  | 'time_read'
  | 'time_taken'
  | 'completion';

type StatFormat = 'number' | 'time_spent' | 'average_time' | 'percentage';

const STAT_COUNT_UP_DURATION = 1.5;

export type ApiStat = {
  name: StatType;
  value: number;
};

export type StatStyle = {
  id: StatType;
  icon: IconType;
  iconSize: IconSize;
  mobileIconSize: IconSize;
  label: string;
  format: StatFormat;
  unit?: string;
  tooltip?: string;
};

export const statStyles: Record<StatType, StatStyle> = {
  unique_words: {
    id: 'unique_words',
    icon: 'star',
    label: 'Unique Words',
    format: 'number',
    iconSize: '1.75em',
    mobileIconSize: '1.25em',
    tooltip:
      'This tracks each new word you say! Most fluent, adult readers know about 40,000 different words!',
  },
  total_words: {
    id: 'total_words',
    icon: 'typedText',
    label: 'Total Words',
    format: 'number',
    iconSize: '2.25em',
    mobileIconSize: '1.75em',
    tooltip: 'This counts all the words you read! How many can you read?',
  },
  completion: {
    id: 'completion',
    icon: 'eye',
    label: '% Read',
    format: 'percentage',
    iconSize: '2em',
    mobileIconSize: '1.5em',
  },
  wcpm: {
    id: 'wcpm',
    icon: 'speedometer',
    label: 'Speed (WCPM)',
    format: 'number',
    iconSize: '2em',
    mobileIconSize: '1.5em',

    tooltip: 'This keeps track of how many words you say correctly a minute.',
  },
  wpm: {
    id: 'wpm',
    icon: 'speedometer',
    label: 'Speed (WPM)',
    format: 'number',
    iconSize: '2em',
    mobileIconSize: '1.5em',

    tooltip: 'This keeps track of how many words you say per minute.',
  },
  time_read: {
    id: 'time_read',
    icon: 'hourglass',
    label: 'Minutes Read',
    format: 'time_spent',
    iconSize: '1.6em',
    mobileIconSize: '1.1em',
    tooltip:
      'Did you know if you read for 15 minutes a day or more you’ll become a stronger reader?',
  },
  time_taken: {
    id: 'time_taken',
    icon: 'hourglass',
    label: 'Avg. Time',
    format: 'average_time',
    iconSize: '2em',
    mobileIconSize: '1.5em',
  },
} as const;

export const formatNumber = (value: number) => value.toLocaleString('en-US');

const formatTimeSpentFull = (milliseconds: number) => {
  const seconds = Math.round(milliseconds / 1000);
  const minutes = Math.floor(seconds / 60);

  return `${formatNumber(minutes)}m ${seconds % 60}s`;
};

export const immutableStatStyles = ImmutableMap<StatType, StatStyle>(statStyles);

export const formatStat = (stat: StatStyle, value: number): string => {
  if (stat.format === 'number') {
    return `${formatNumber(roundTo(value, 0))}`;
  } else if (stat.format === 'percentage') {
    return `${roundTo(value * 100, 0)}%`;
  } else if (stat.format === 'time_spent') {
    return `${formatNumber(Math.ceil(getMinutes(value)))}`;
  } else if (stat.format === 'average_time') {
    return formatTimeSpentFull(value);
  } else {
    throw new Error(`Unknown stat format '${value}'`);
  }
};

export const formatSeconds = (milliseconds: number) => {
  const seconds = Math.round(milliseconds / 1000);

  return seconds % 60;
};

export const formatMinutes = (milliseconds: number) => {
  const seconds = Math.round(milliseconds / 1000);
  const minutes = Math.floor(seconds / 60);

  return minutes;
};

export const formatCountUpStat = (stat: StatStyle, value: number): number => {
  if (stat.format === 'number') {
    return roundTo(value, 0);
  } else if (stat.format === 'percentage') {
    return roundTo(value * 100, 0);
  } else if (stat.format === 'time_spent') {
    return Math.ceil(getMinutes(value));
  } else {
    throw new Error(`Unknown stat format '${value}'`);
  }
};

export const countUpUnit = (stat: StatStyle): string => {
  if (stat.format === 'number' || stat.format === 'time_spent') {
    return '';
  } else if (stat.format === 'percentage') {
    return '%';
  } else {
    throw new Error(`Unknown stat format '${stat.format}'`);
  }
};

export const StatCountUp = ({ stat, value }: { stat: StatStyle; value: number }) => {
  return stat.format === 'average_time' ? (
    <>
      <CountUp value={formatMinutes(value)} duration={STAT_COUNT_UP_DURATION} />m{' '}
      <CountUp value={formatSeconds(value)} duration={STAT_COUNT_UP_DURATION} />s
    </>
  ) : (
    <>
      <CountUp value={formatCountUpStat(stat, value)} duration={STAT_COUNT_UP_DURATION} />
      {countUpUnit(stat)}
    </>
  );
};

export const studentStats = (stats: ImmutableList<ApiStat>) =>
  stats.filter((s) => s.name !== 'wpm');
