import styled from 'styled-components';
import { Button, Flex, Spacer, IconType, Icon, Text } from 'src/components';
import { colors, standardMeasurements } from 'src/styles';
import { useHistory, useQuery, updateQuery, useLocation } from 'src/modules/Router';
import { ImmutableList } from 'src/modules/Immutable';

export type NavigationTab = {
  id: string;
  name: string;
  content?: React.ReactNode;
  to?: string;
  icon: IconType;
  rightComponent?: React.ReactNode;
  disabled?: boolean;
};

type NavigationTabViewProps = {
  queryParam: string;
  tabs: ImmutableList<NavigationTab>;
  noContent?: boolean;
};

type NavigationTabWrapperProps = {
  $active: boolean;
  $disabled?: boolean;
};

const NavigationTabWrapper = styled.div<NavigationTabWrapperProps>`
  border-bottom: ${({ $active, $disabled }) =>
    $active && !$disabled
      ? `${standardMeasurements.half} solid ${colors.primaryBlue.hex}`
      : 'none'};
  width: 100%;
  cursor: ${({ $disabled }) => ($disabled ? 'none' : 'pointer')};
  margin: 0 8px;
`;

const RightAlign = styled.div`
  position: absolute;
  right: 10px;
`;

const useActiveTab = ({ queryParam, tabs }: NavigationTabViewProps) => {
  const query = useQuery();
  const currentTabId = query.get(queryParam);
  const activeTab = currentTabId ? tabs.find((t) => t.id === currentTabId) : tabs.first();
  return activeTab;
};

export const NavigationTabView = (props: NavigationTabViewProps) => {
  const { tabs, queryParam, noContent = false } = props;
  const query = useQuery();
  const history = useHistory();
  const pathname = useLocation().pathname;
  const activeTab = useActiveTab({ queryParam, tabs });

  const moveTab = (targetId: string) => () => {
    const tab = tabs.filter((t) => t.id === targetId).first();
    if (!tab) return;
    if (tab.to) {
      history.push(tab.to);
      return;
    }
    history.push(`${pathname}${updateQuery(query, { [queryParam]: tab.id })}`);
  };

  return (
    <Flex direction="column">
      <Flex width="100%">
        {tabs.map((tab) => (
          <NavigationTabWrapper
            key={tab.id}
            $disabled={tab.disabled}
            $active={tab.id === activeTab?.id}
            onClick={tab.disabled ? undefined : moveTab(tab.id)}
          >
            <Flex position="relative">
              <Flex grow={1} justify="center">
                <Button displayType="noStyles" disabled={tab.disabled}>
                  <Flex align="center" justify="center">
                    <Icon
                      icon={tab.icon}
                      color={
                        tab.disabled ? 'gray7' : tab.id === activeTab?.id ? 'primaryBlue' : 'black'
                      }
                      size="24px"
                    />
                    <Spacer horizontal />
                    <Text
                      color={tab.disabled ? 'gray7' : 'black'}
                      weightOverride={tab.disabled ? 'default' : 'bold'}
                    >
                      {tab.name}
                    </Text>
                  </Flex>
                </Button>
              </Flex>
              {tab.rightComponent && (
                <Flex align="center">
                  <RightAlign>{tab.rightComponent}</RightAlign>
                </Flex>
              )}
            </Flex>
          </NavigationTabWrapper>
        ))}
      </Flex>
      <Spacer size={2} />
      {noContent || activeTab?.disabled || !activeTab?.content ? null : (
        <div>{activeTab.content}</div>
      )}
    </Flex>
  );
};
