import { Loading, ErrorText } from 'src/components';
import { SimpleLayout } from 'src/layouts';
import { useParams, useHistory } from 'src/modules/Router';
import { useEffect, useState } from 'react';
import { ErrorMapResponse, ErrorMap, useFetch } from 'src/modules/Api';
import { User } from 'src/models';
import { ImmutableList, ImmutableMap } from 'src/modules/Immutable';
import { useStore } from 'src/Store';

type LoadResponse = {
  errors: ErrorMapResponse;
  success: boolean;
  user: User;
};

export const ConfirmEmail = () => {
  const { id, token } = useParams<{ id: string; token: string }>();
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState(false);
  const [errors, setErrors] = useState<ErrorMap>(ImmutableMap());
  const { fetchJson } = useFetch();
  const onSignedUp = useStore((state) => state.AppData.onSignedUp, []);

  useEffect(() => {
    setLoading(true);
    fetchJson(`/api/confirmation_email_tokens/${id}`, {
      method: 'PATCH',
      data: { token },
      onSuccess: ({ success, errors, user }: LoadResponse) => {
        setSuccess(success);
        setLoading(false);
        setErrors(ImmutableMap(errors));
        if (success) {
          onSignedUp({
            user,
            schools: ImmutableList(),
            history,
            redirectPath: user.accountType === 'teacher' ? `/t/assignments` : '/',
            method: 'password',
            replace: true,
          });
        }
      },
    });
  }, [id, token, history, onSignedUp, fetchJson]);

  if (loading || success) return <Loading flex kind="boat" />;

  return (
    <SimpleLayout>
      <ErrorText errors={errors} errorKey="token" />
    </SimpleLayout>
  );
};
