import { UserColor, ColorHexes } from 'src/styles';
import { BaseAvatar, BaseAvatarProps } from './BaseAvatar';

type InheritedProps = Omit<BaseAvatarProps, 'radiusType' | 'textColor' | 'backgroundColor'>;

type UserAvatarProps = InheritedProps & {
  backgroundColor: UserColor | ColorHexes;
};

export const UserAvatar = (props: UserAvatarProps) => {
  return (
    <BaseAvatar {...props} radiusType="round" textColor={props.overflow ? 'white' : 'black'} />
  );
};
