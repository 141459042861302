import { useCallback, useEffect } from 'react';
import { matchPath, useHistory, useLocation, useQuery } from 'src/modules/Router';
import {
  Button,
  LinkButton,
  Spacer,
  StandardModal,
  Text,
  Flex,
  Loading,
  FlexGrow,
  CheckboxInput,
  ClassAvatar,
  AvatarCard,
  Icon,
  CleverButton,
} from 'src/components';

import { useStore } from 'src/Store';
import { formatTimeAgo } from 'src/modules/Time';
import { pluralize } from 'src/modules/String';
import styled from 'styled-components';
import { margins, paddings } from 'src/styles';

const CheckboxListItem = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin: ${margins[6]} 0;
`;

const CoursesContainer = styled.div`
  overflow-y: auto;
  max-height: 300px;
  padding: 0 ${paddings[6]};
`;

export const ImportCleverCourseModal = ({
  openedPath,
  closedPath,
}: {
  openedPath: string;
  closedPath: string;
}) => {
  const {
    cleverSections,
    submit,
    load,
    loading,
    courseMap,
    selectedCleverSectionIds,
    setSelected,
    hasScopes,
    submitting,
    setAll,
  } = useStore((s) => s.ImportCleverCourseData, []);
  const history = useHistory();
  const query = useQuery();
  const pathname = useLocation().pathname;

  const opened = Boolean(matchPath(pathname, openedPath));
  const closeModal = useCallback(() => history.replace(closedPath), [history, closedPath]);

  useEffect(() => {
    if (opened) {
      load();
    }
  }, [load, opened]);

  return (
    <StandardModal
      openType="path"
      header="Import from Clever"
      footer={
        <Flex width="100%">
          <Button
            width="100%"
            loading={submitting}
            type="submit"
            onClick={submit(history, pathname, query)}
            disabled={!hasScopes || selectedCleverSectionIds.size === 0}
          >
            Import Classes
          </Button>
        </Flex>
      }
      openedPath={openedPath}
      close={closeModal}
    >
      {loading ? (
        <Loading flex />
      ) : (
        <>
          <Flex width="100%" justify="space-between" align="center">
            <Text variant="h4">Classes ({cleverSections.size})</Text>
            <LinkButton onClick={setAll} color="primaryBlue">
              {selectedCleverSectionIds.isEmpty() ? 'Select All' : 'Deselect All'}
            </LinkButton>
          </Flex>
          <CoursesContainer>
            {hasScopes ? (
              cleverSections.map((cleverSection) => {
                const course = courseMap.get(cleverSection.id);

                return (
                  <CheckboxListItem key={cleverSection.id} onClick={setSelected(cleverSection.id)}>
                    <AvatarCard
                      avatar={
                        <ClassAvatar
                          size="large"
                          backgroundColor={cleverSection.avatarColor}
                          name={cleverSection.name}
                        />
                      }
                      primaryText={
                        <Flex align="center">
                          {cleverSection.name}
                          {course && (
                            <>
                              <Spacer horizontal />
                              <Icon icon="sync" size="1.5em" />
                            </>
                          )}
                        </Flex>
                      }
                      secondaryText={
                        <>
                          {course ? (
                            <>
                              Last Synced{' '}
                              {course.cleverSyncedAt
                                ? formatTimeAgo(new Date(course.cleverSyncedAt))
                                : 'Unknown'}
                            </>
                          ) : (
                            pluralize('Student', cleverSection.students.size)
                          )}
                        </>
                      }
                    />
                    <FlexGrow />
                    <Spacer horizontal size={8} />
                    <CheckboxInput
                      onChange={setSelected(cleverSection.id)}
                      name={cleverSection.id}
                      value={selectedCleverSectionIds.has(cleverSection.id)}
                    />
                  </CheckboxListItem>
                );
              })
            ) : (
              <>
                <Text>Please authorize Readlee to access your courses on Clever Classroom.</Text>
                <CleverButton successUrl="/t/classes/clever_import">
                  Authorize Clever Classroom
                </CleverButton>
              </>
            )}
          </CoursesContainer>
        </>
      )}
    </StandardModal>
  );
};
