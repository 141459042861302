import { subDataCreator } from 'src/State';
import { defaultState, StudentDashboardResponse } from './StudentDashboardState';
import { setToggle } from 'src/modules/Immutable';

export const createDefaultStudentDashboardData = subDataCreator(
  'StudentDashboardData',
  defaultState,
  ({ set, fetchJson }) => ({
    load: () => {
      set({ ...defaultState, loading: true });
      fetchJson(`/api/users/student_dashboard`, {
        onSuccess: (data: StudentDashboardResponse) => {
          set({
            assessmentToCourseMap: data.assessmentToCourseMap,
            assessmentMap: data.assessmentMap,
            submissionMap: data.submissionMap,
            taskSubmissions: data.taskSubmissions,
            courseMap: data.courseMap,
            stats: data.stats,
            commentMap: data.commentMap,
            readingNameMap: data.readingNameMap,
            loading: false,
          });
        },
      });
    },
    toggleAssignmentCardOpen: (value) => {
      set(({ openAssignmentCardSet }) => ({
        openAssignmentCardSet: setToggle(openAssignmentCardSet, value),
      }));
    },
  }),
);
