import { ImmutableList } from 'src/modules/Immutable';
import { LanguageCode } from 'src/modules/LanguageCodes';
import { Author } from './Author';
import { ImageStoredFile } from './ImageStoredFile';
import { Tag } from './Tag';

export type ContentType = 'epub' | 'text' | 'pdf' | 'pdf_by_page' | 'independent' | 'web';

export type OwnershipType = 'library' | 'user' | 'shared' | 'community' | 'school' | 'district';

export type LicenseType =
  | 'open_source'
  | 'open_source_needs_review'
  | 'in_copyright'
  | 'private_user_content'
  | 'private_final'
  | 'school'
  | 'district';

export const OWNERSHIP_TYPES = ImmutableList<OwnershipType>([
  'user',
  'shared',
  'library',
  'community',
  'school',
  'district',
]);

export type Reading = {
  id: string;
  name: string;
  authorId: string | null;
  contentType: ContentType;
  webSourceUrl: string | null;
  coverImageFileId: string | null;
  gradeLevelId: string | undefined;
  autoGradeLevelId: string | undefined;
  totalWords: number;
  license: LicenseType;
  languageCode: LanguageCode;
  userId: string;
  isSpecialEducation: boolean;
  isEll: boolean;
};

export type ReadingSearchResult = {
  reading: Reading;
  author: Author | null;
  imageStoredFile: ImageStoredFile | null;
  ownershipType: OwnershipType;
  tags: ImmutableList<Tag>;
  totalWords: number;
  playlistIds: string[];
  assessmentId?: string;
};

export const ownershipTypeLabel = (
  ownershipType: OwnershipType,
  isBasicPlan: boolean,
  isPaperUser: boolean,
) => {
  switch (ownershipType) {
    case 'user':
      return 'My Content';
    case 'library':
      return isPaperUser ? 'Paper Library' : `Readlee Library ${isBasicPlan ? ' (Ultimate)' : ''}`;
    case 'shared':
      return 'Shared with Me';
    case 'community':
      return isPaperUser ? 'Paper Community' : `Readlee Community${isBasicPlan ? ' (Free)' : ''}`;
    case 'school':
      return 'School Library';
    case 'district':
      return 'District Library';
    default:
      throw new Error(`unknown ownership type: ${ownershipType}`);
  }
};

const SHARING_LICENSE_TYPES = ['open_source', 'open_source_needs_review', 'district', 'school'];

export const readingIsShared = (licenseType: LicenseType) =>
  SHARING_LICENSE_TYPES.includes(licenseType ?? '');

const SHARABLE_LICENSE_TYPES = [
  'open_source',
  'open_source_needs_review',
  'private_user_content',
  'district',
  'school',
];

export const isSharableLicenseType = (licenseType: LicenseType) =>
  SHARABLE_LICENSE_TYPES.includes(licenseType ?? '');
