import { NoStylesButton, Flex, Text, Spacer, useToaster } from 'src/components';
import { useStore } from 'src/Store';
import { useBreakpoints } from 'src/styles';
import { useCommonStateValues, useCompactLayout } from './AssignToModalHelpers';

export const StudentSelectionHeader = () => {
  const { currRegistrationAssessments, selectedCourse, updateCourseAssessment, toggleAll } =
    useStore((s) => s.AssignToData, []);
  const { createToast } = useToaster();

  const selectedCount = currRegistrationAssessments?.filter(
    (registration) => registration.assigned,
  ).size;

  const allAssigned =
    currRegistrationAssessments?.every(
      (registrationAssessment) => registrationAssessment.assigned,
    ) || false;

  const { hasCourseAssessment } = useCommonStateValues();

  const showCompactLayout = useCompactLayout();
  const showSuperCompactLayout = useBreakpoints({ smallerThanOrEqualTo: 'mobileLarge' });

  return (
    <>
      {showCompactLayout && selectedCourse && (
        <>
          <Text variant="h4">{selectedCourse?.name}</Text>
          <Spacer size={2} />
        </>
      )}
      <Flex justify="space-between" align="center" wrap="wrap">
        <div>
          {!showSuperCompactLayout && (
            <Text
              display="inline"
              variant={showCompactLayout ? 'h5' : 'h3'}
              weightOverride="medium"
            >
              Assigned to{' '}
            </Text>
          )}
          <Text
            variant={showCompactLayout ? 'h6' : 'h3'}
            weightOverride="medium"
            color="primaryBlue"
            display="inline"
          >
            {selectedCount}
          </Text>
          <Text
            variant={showCompactLayout ? 'h6' : 'h3'}
            weightOverride="medium"
            color="gray6"
            display="inline"
          >
            /{currRegistrationAssessments?.size}
          </Text>{' '}
          <Text display="inline" variant={showCompactLayout ? 'h5' : 'h3'} weightOverride="medium">
            {showSuperCompactLayout ? 'Assigned' : 'students assigned'}
          </Text>
        </div>
        <Spacer horizontal size={2} />
        {currRegistrationAssessments?.size ? (
          <NoStylesButton onClick={toggleAll(allAssigned, createToast)}>
            <Text color="primaryBlue" weightOverride="bold">
              {allAssigned ? 'Unassign All' : 'Assign All'}
            </Text>
          </NoStylesButton>
        ) : (
          <NoStylesButton onClick={updateCourseAssessment(createToast)}>
            <Text color="primaryBlue" weightOverride="bold">
              {hasCourseAssessment ? 'Unassign Class' : 'Assign Class'}
            </Text>
          </NoStylesButton>
        )}
      </Flex>
    </>
  );
};
