import { Spacer, Text } from 'src/components';
import { formatDateTime } from 'src/modules/Time';
import { useStore } from 'src/Store';
import {
  ChartTooltip,
  TooltipContentContainer,
  TooltipSubmissionLink,
} from './StudentProgressChartComponents';
import { labelTime } from './StudentProgressChartHelpers';
import { TooltipSubmissionData } from './StudentProgressMonitoringState';

const ReadingTooltipContent = ({
  name,
  readingTime,
  submissionId,
  finishedAt,
  totalTime,
}: TooltipSubmissionData) => {
  return (
    <TooltipContentContainer>
      <Text variant="h3" ellipsis>
        {name}
      </Text>
      <Spacer size={2} />
      <Text>Total Time Read: {labelTime(totalTime)}</Text>
      <Text>Submission Time Read: {labelTime(readingTime)}</Text>
      <Text>Submission Date: {formatDateTime(new Date(finishedAt))}</Text>
      <TooltipSubmissionLink submissionId={submissionId} />
    </TooltipContentContainer>
  );
};

export const StudentProgressReadingTooltip = () => {
  const { readingTooltipProps, readingTooltipData, setReadingTooltipProps } = useStore(
    ({ StudentProgressMonitoringData: s }) => ({
      readingTooltipProps: s.readingTooltipProps,
      readingTooltipData: s.readingTooltipData,
      setReadingTooltipProps: s.setReadingTooltipProps,
    }),
    [],
  );

  return (
    <ChartTooltip {...readingTooltipProps} tooltipStateSetter={setReadingTooltipProps}>
      {readingTooltipData.map((a) => (
        <ReadingTooltipContent key={a.submissionId} {...a} />
      ))}
    </ChartTooltip>
  );
};
