import { Fragment, useEffect } from 'react';
import { Accordion, CheckboxInput, Flex, Spacer, Text } from 'src/components';
import { GradeBand, OWNERSHIP_TYPES, gradeBandsToLevels, ownershipTypeLabel } from 'src/models';
import {
  getWordRangeLabel,
  wordRanges,
  getWordRangeKey,
  useQueryValues,
  useSetQuery,
} from './ContentLibraryHelpers';
import { ImmutableMap } from 'src/modules/Immutable';
import { useStore } from 'src/Store';
import { Route } from 'react-router-dom';
import { useLocation, matchPath } from 'src/modules/Router';
import styled, { css } from 'styled-components';
import { useBreakpoints } from 'src/styles';
import { levelsToGradeBands } from 'src/models';

const FilterContainer = styled.div<{ $isMobile: boolean }>`
  overflow-y: auto;
  height: 100%;
  ${({ $isMobile }) =>
    $isMobile
      ? css``
      : css`
          padding-bottom: 150px;
        `}
`;

export const Filters = () => {
  const {
    selectedWordCounts,
    toggleWordCount,
    selectedBands,
    toggleBand,
    selectedOwnershipTypes,
    toggleOwnershipType,
    additionalFilters,
    toggleAdditionalFilter,
  } = useQueryValues();
  const { isBasicPlan, paperSyncedAt, paperGradeLevel, initialSearch, accountType } = useStore(
    ({ AppData: a, ContentLibraryData: s }) => ({
      isBasicPlan: a.currentPlan?.isBasicPlan ?? true,
      paperSyncedAt: a.currentUser?.paperSyncedAt,
      paperGradeLevel: a.currentUser?.paperGradeLevel,
      accountType: a.currentUser?.accountType,
      initialSearch: s.initialSearch,
    }),
    [],
  );

  const pathname = useLocation().pathname;
  const isStudent = Boolean(matchPath(pathname, '/s/library'));
  const isMobile = useBreakpoints({ smallerThan: 'tabletSmall' });
  const setQuery = useSetQuery();

  useEffect(() => {
    if (initialSearch && paperGradeLevel && accountType === 'student' && !selectedBands.size) {
      const levelAsString = paperGradeLevel.level.toString();
      if (levelAsString in levelsToGradeBands) {
        setQuery({ gradeBands: levelsToGradeBands[levelAsString as GradeBand] });
      }
    }
  }, [accountType, initialSearch, paperGradeLevel, selectedBands.size, setQuery, toggleBand]);

  return (
    <>
      <Text variant="h3">Content Library</Text>
      <Spacer size={5} />
      <FilterContainer $isMobile={isMobile}>
        <Accordion type="v2" label="Grade" defaultOpen={isStudent}>
          <Flex direction="column">
            {ImmutableMap(gradeBandsToLevels)
              .keySeq()
              .map((value) => (
                <Fragment key={value}>
                  <CheckboxInput
                    name={value}
                    value={selectedBands.has(value)}
                    onChange={toggleBand(value)}
                    label={value}
                    textColor="black"
                  />
                  <Spacer size={4} />
                </Fragment>
              ))}
          </Flex>
        </Accordion>
        <Route path="/t/library">
          <Spacer size={8} />
          <Accordion type="v2" label="Content Sources">
            <Flex direction="column">
              {OWNERSHIP_TYPES.map((type) => (
                <Fragment key={type}>
                  <CheckboxInput
                    name={type}
                    value={selectedOwnershipTypes.has(type)}
                    onChange={toggleOwnershipType(type)}
                    label={ownershipTypeLabel(type, isBasicPlan, Boolean(paperSyncedAt))}
                    textColor="black"
                  />
                  <Spacer size={4} />
                </Fragment>
              ))}
            </Flex>
          </Accordion>
          <Spacer size={8} />
          <Accordion type="v2" label="Word Count">
            <Flex direction="column">
              {wordRanges.map((range) => {
                const value = getWordRangeKey(range);
                const label = getWordRangeLabel(range);
                return (
                  <Fragment key={value}>
                    <CheckboxInput
                      name={label}
                      value={selectedWordCounts.has(value.toString())}
                      onChange={toggleWordCount(value.toString())}
                      label={label}
                      textColor="black"
                    />
                    <Spacer size={4} />
                  </Fragment>
                );
              })}
            </Flex>
          </Accordion>
          <Spacer size={8} />
          <Accordion type="v2" label="Additional Filters">
            <Flex direction="column">
              <CheckboxInput
                name="specialEducation"
                value={additionalFilters.contains('specialEducation')}
                onChange={() => toggleAdditionalFilter('specialEducation')}
                label="Special Education"
                testTag="specialEducation"
                textColor="black"
              />
              <Spacer size={4} />
              <CheckboxInput
                name="ell"
                value={additionalFilters.contains('ell')}
                onChange={() => toggleAdditionalFilter('ell')}
                label="English Language Learner"
                testTag="ell"
                textColor="black"
              />
            </Flex>
          </Accordion>
        </Route>
      </FilterContainer>
    </>
  );
};
