import { useStore } from 'src/Store';
import { ImmutableList } from 'src/modules/Immutable';

import { FormGroup, PDFReader, ErrorText, EmptyState } from 'src/components';
import { TEXT_VIEWER_MAX_WIDTH } from 'src/styles';
import { ContentContainer } from 'src/pages/ReadingBuilder/ReadingBuilderHelpers';

export const ContentStep = () => {
  const {
    contentType,
    uploadedPdfFile,
    basicText,
    setField,
    errors,
    uploadPdfFile,
    uploadingPdf,
    pdfFile,
    rotatePdf,
    pdfRotation,
    pdfFileUrl,
  } = useStore(
    ({ ReadingBuilderData: s }) => ({
      contentType: s.contentType,
      uploadedPdfFile: s.uploadedPdfFile,
      basicText: s.basicText,
      setField: s.setField,
      errors: s.errors,
      uploadPdfFile: s.uploadPdfFile,
      uploadingPdf: s.uploadingPdf,
      pdfFile: s.pdfFile,
      rotatePdf: s.rotatePdf,
      pdfRotation: s.pdfRotation,
      pdfFileUrl: s.pdfFileUrl,
    }),
    [],
  );

  if (['text', 'web'].includes(contentType)) {
    return (
      <ContentContainer>
        <ErrorText errors={errors} errorKey="contentFile" />
        <FormGroup
          label={null}
          name="contentFile"
          type="text-area"
          displayType="flat"
          value={basicText}
          onChange={setField('basicText')}
          placeholder="✏️ Copy and paste a short story, news article, poem, or excerpt from a book here for students to read."
          height="100%"
          maxWidth={TEXT_VIEWER_MAX_WIDTH}
        />
      </ContentContainer>
    );
  } else if (contentType === 'pdf' || contentType === 'pdf_by_page') {
    return pdfFileUrl ? (
      <PDFReader onRotatePDF={rotatePdf} rotation={pdfRotation} src={pdfFileUrl} hideTopBar />
    ) : (
      <ContentContainer>
        <FormGroup
          id="cover-image-input"
          type="file"
          kind="dropArea"
          value={uploadedPdfFile ? ImmutableList([uploadedPdfFile]) : ImmutableList()}
          name="contentFile"
          onChange={uploadPdfFile}
          accept="pdf"
          errors={errors}
          flex
          justify="center"
          uploading={uploadingPdf}
          uploadedFile={pdfFile}
        />
      </ContentContainer>
    );
  } else if (contentType === 'independent') {
    return (
      <ContentContainer>
        <EmptyState kind="independentReading" />
      </ContentContainer>
    );
  } else {
    throw new Error(`Unsupported content type '${contentType}'`);
  }
};
