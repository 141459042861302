export type RegistrationStatus = 'active' | 'deleted';
export type RegistrationRoles = 'student' | 'teacher';

export type Registration = {
  id: string;
  userId: string;
  courseId: string;
  status: RegistrationStatus;
  role: RegistrationRoles;
};
