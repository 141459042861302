import { Route } from 'react-router-dom';
import { useEffect, useMemo } from 'react';
import { useStore } from 'src/Store';
import { TeacherLayoutProps } from 'src/layouts';
import {
  DropdownMenu,
  Flex,
  SuperTable,
  SuperTableColumn,
  UserAvatar,
  Spacer,
  Text,
  ClassAvatar,
  AvatarCard,
  LmsIcon,
  Button,
  SelectOption,
  FormGroup,
  Icon,
  PaperSyncButton,
} from 'src/components';
import { userColors, basicColors, margins } from 'src/styles';
import { getLmsLabel, User, formatName } from 'src/models';
import { ImmutableList } from 'src/modules/Immutable';
import { formatTimeAgo } from 'src/modules/Time';
import { queryMapToString, useQuery, useLocation, useHistory } from 'src/modules/Router';
import {
  EditStudentModal,
  NewStudentModal,
  ManageStudentCoursesModal,
  ChangePasswordModal,
} from './modals';
import { makeQueryString } from 'src/modules/Api';

type TeacherStudentListProps = {
  ContentElement: React.ComponentType<TeacherLayoutProps>;
};

const getStudentSecondaryText = (student: User) => {
  if (student.status === 'pre_registered') {
    return 'Pre-Registered';
  } else if (student.googleSyncedAt) {
    return `Last Synced ${formatTimeAgo(new Date(student.googleSyncedAt))}`;
  } else if (student.cleverSyncedAt) {
    return `Last Synced ${formatTimeAgo(new Date(student.cleverSyncedAt))}`;
  }
  return null;
};

const disabledTooltip = (lms: string) => {
  return `This student was imported from ${lms}. Use ${lms} to edit this student’s information`;
};

export const TeacherStudentList = ({ ContentElement }: TeacherStudentListProps) => {
  const { loading, load, students, registrationMap, courseMap } = useStore(
    (state) => state.TeacherStudentListData,
    [],
  );

  const currentUser = useStore((state) => state.AppData.currentUser, []);
  const history = useHistory();
  const location = useLocation();
  const query = useQuery();

  useEffect(() => {
    load();
  }, [load]);

  const unassignedStudents = students.filter((user) => {
    const registrations = registrationMap.get(user.id, ImmutableList());
    return registrations.isEmpty();
  });

  const selectedCourseId = query.get('selectedCourseId') || 'all';

  useEffect(() => {
    if (!loading && unassignedStudents.isEmpty() && selectedCourseId === 'unassigned') {
      history.replace('/t/students?selectedCourseId=all');
    }
  }, [loading, history, selectedCourseId, unassignedStudents]);

  const baseCourseOptions = ImmutableList<SelectOption<string>>(
    courseMap
      .toList()
      .sortBy((course) => course.status)
      .map((course) => ({
        label: course.status === 'archived' ? `(ARCHIVED) ${course.name}` : course.name,
        value: course.id,
      }))
      .unshift({ label: 'All Active Classes', value: 'all' }),
  );

  const courseOptions = unassignedStudents.isEmpty()
    ? baseCourseOptions
    : baseCourseOptions.push({ label: 'Unassigned Students', value: 'unassigned' });

  const activeCourseIds = courseMap
    .filter((course) => course.status === 'active')
    .map((course) => course.id);

  const selectedStudents = useMemo(() => {
    switch (selectedCourseId) {
      case 'all':
        return students.filter((user) => {
          const registrations = registrationMap
            .get(user.id)
            ?.map((registration) => registration.courseId);
          return registrations?.some((registration) => activeCourseIds.contains(registration));
        });
      case 'unassigned':
        return unassignedStudents;
      default:
        return students.filter((user) => {
          const registrations = registrationMap
            .get(user.id)
            ?.map((registration) => registration.courseId);
          return registrations?.contains(selectedCourseId);
        });
    }
  }, [activeCourseIds, registrationMap, selectedCourseId, students, unassignedStudents]);

  const lmsButtonProps = (student: User) => ({
    tooltipProps: {
      content: disabledTooltip(getLmsLabel(student)),
      disabled: !student.isSso,
      position: 'left' as const,
      width: '200px' as const,
      containerWidthOverride: '100%' as const,
    },
    disabled: student.isSso,
  });

  const overflowAvatar = (student: User) => {
    const extraRegistrations = registrationMap.get(student.id)?.skip(3);

    const courseNames = extraRegistrations?.map(
      (registration) => courseMap.get(registration.courseId)?.name || '',
    );

    return courseNames && courseNames.size > 0 ? (
      <ClassAvatar
        backgroundColor={basicColors.black}
        name={`+${courseNames.size}`}
        nameList={courseNames}
        overflow
        testTag="class"
      />
    ) : (
      ''
    );
  };

  const columns: Array<SuperTableColumn<User> & { isPaperColumn: boolean }> = [
    {
      id: 'name',
      name: 'Student',
      render: (student) => (
        <AvatarCard
          avatar={
            <UserAvatar
              name={student.displayName}
              backgroundColor={student?.avatarColor || userColors[0]}
            />
          }
          primaryText={formatName(student)}
          secondaryText={getStudentSecondaryText(student)}
          textColor={student.status === 'pre_registered' ? 'gray5' : 'black'}
        ></AvatarCard>
      ),
      sortType: 'sortBy',
      sortBy: formatName,
      isPaperColumn: true,
    },
    {
      id: 'email',
      name: 'Email / Username',
      render: (student) => {
        return (
          <Flex align="center">
            <LmsIcon lms={getLmsLabel(student)} />
            <Text variant="h6" color={student.status === 'pre_registered' ? 'gray5' : 'black'}>
              {student.email ?? student.username}
            </Text>
          </Flex>
        );
      },
      sortType: 'sortBy',
      sortBy: (student) => student.email ?? student.username,
      isPaperColumn: false,
    },
    {
      id: 'classes',
      name: 'Classes',
      render: (student) =>
        registrationMap.get(student.id) ? (
          <Flex align="center">
            {registrationMap
              .get(student.id)
              ?.slice(0, 3)
              .map((registration) => {
                const course = courseMap.get(registration.courseId);
                return (
                  course && (
                    <Flex key={course.id} testTag="class">
                      <ClassAvatar
                        backgroundColor={
                          course.status === 'archived' ? basicColors.gray5 : course.avatarColor
                        }
                        name={course.name}
                        testTag="class"
                      />
                      <Spacer horizontal />
                    </Flex>
                  )
                );
              })}

            {overflowAvatar(student)}
          </Flex>
        ) : (
          <Flex justify="start">-</Flex>
        ),
      sortType: 'none',
      isPaperColumn: true,
    },
    {
      id: 'lastActiveAt',
      name: 'Last Active',
      render: (student) => (
        <Text textAlign="right" color={student.status === 'pre_registered' ? 'danger' : 'black'}>
          {student.status === 'pre_registered'
            ? 'Never'
            : formatTimeAgo(new Date(student.lastActiveAt))}
        </Text>
      ),
      sortType: 'sortBy',
      sortBy: (student) => new Date(student.lastActiveAt).valueOf(),
      textAlign: 'right',
      isPaperColumn: true,
    },
    {
      id: 'settings',
      name: 'Actions',
      textAlign: 'right',
      render: (student) => (
        <Flex align="center" justify="end">
          <DropdownMenu
            rightAligned
            displayType="iconOnly"
            color="white"
            iconColor="gray7"
            icon="threeVerticalDots"
            iconSize="2em"
            testTag="settings"
            inheritBgColor
            noButtonPadding
            options={[
              {
                ...lmsButtonProps(student),
                children: 'Edit Student',
                to: `/t/students/${student.id}/edit-student${queryMapToString(query)}`,
              },
              {
                children: 'Edit Classes',
                to: `/t/students/${student.id}/edit-classes${queryMapToString(query)}`,
              },
              {
                ...lmsButtonProps(student),
                children: 'Change Password',
                to: `/t/students/${student.id}/change-password${queryMapToString(query)}`,
              },
            ]}
          />
        </Flex>
      ),
      sortType: 'none',
      isPaperColumn: false,
    },
  ];

  const filteredColumns = columns.filter((column) =>
    currentUser?.paperSyncedAt ? column.isPaperColumn : true,
  );

  return (
    <ContentElement
      headerText="Students"
      backPath="/t/classes"
      headerActionButton={
        !currentUser?.paperSyncedAt && (
          <Button to={`/t/students/new${queryMapToString(query)}`}>Add New Student</Button>
        )
      }
      loading={loading}
    >
      {currentUser?.paperSyncedAt && (
        <Flex direction="column" align="end" width="100%" margin={`0 0 -${margins[5]} 0`}>
          <PaperSyncButton showSyncStatus />
        </Flex>
      )}

      <Flex align="center">
        <Icon
          icon="filter"
          color="readleeBlue"
          fill={selectedCourseId !== 'all'}
          strokeWidth={1.5}
          strokeLinecap="round"
        />
        <Spacer horizontal />
        <FormGroup
          type="select"
          options={courseOptions}
          value={selectedCourseId}
          name="courses"
          displayType="flat"
          onChange={(id) => history.replace(`/t/students?selectedCourseId=${id}`)}
        />
      </Flex>
      <Spacer size={2} />
      <SuperTable
        columns={filteredColumns}
        rows={selectedStudents}
        getRowId={(student) => student.id}
        queryParam="sortBy"
        rowClickable
        onRowClick={(user) =>
          history.push(
            `/t/progress-monitoring/${user.id}${makeQueryString({
              backPath: `${location.pathname}${queryMapToString(query)}`,
            })}`,
          )
        }
        initialSortSettings={{
          columnId: 'name',
          sortDirection: 'asc',
        }}
        emptyStateContent={
          <Flex justify="center">
            <Flex direction="column" maxWidth="375px">
              <Spacer size={5} />
              <Text textAlign="center" display="inline">
                There are currently no students assigned to this class.
              </Text>
              <Spacer size={3} />
              <Text textAlign="center" display="inline">
                Use the &quot;Add New Student&quot; button to manually add students to this class,
                or use the class filter above to view the students in another class.
              </Text>
              <Spacer size={8} />
            </Flex>
          </Flex>
        }
      />
      <Spacer size={22} />
      <Route path="/t/students/:id/edit-student">
        <EditStudentModal />
      </Route>
      <Route path="/t/students/:id/change-password">
        <ChangePasswordModal />
      </Route>
      <Route path="/t/students/new">
        <NewStudentModal />
      </Route>
      <Route path="/t/students/:id/edit-classes">
        <ManageStudentCoursesModal />
      </Route>
    </ContentElement>
  );
};
