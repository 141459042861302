import { subDataCreator } from 'src/State';
import { defaultState, AppResponse, AppImageResponse } from './AppState';
import { resetSocketConnection } from 'src/modules/Socket';
import { trackLogin, trackSignUp } from 'src/modules/Analytics/AnalyticsEvents';
import { deidentify, identify, identifyAnonymous } from 'src/modules/Analytics/AnalyticsIdentity';
import { MILLISECONDS_IN_DAY, getTimeZone } from 'src/modules/Time';
import { ExtendedUser, teacherOrGreater } from 'src/models';
import { cookies } from 'src/modules/Api';
import { emitPaperEvent, PaperEvent } from 'src/modules/PaperEvent';

type UserUpdateResponse = {
  success: boolean;
  user: ExtendedUser;
};

export const createDefaultAppData = subDataCreator(
  'AppData',
  defaultState,
  ({ set, get, fetchJson, getFull, setField }) => ({
    setField,
    load: ({ onSuccess, skipStateReset } = {}) => {
      if (!skipStateReset) set({ ...defaultState, loading: true });
      fetchJson(`/api/users/me`, {
        noRedirect: true,
        data: { timeZone: getTimeZone() },
        onNotAuthenticated: async () => {
          set({ loading: false });
          await identifyAnonymous();
        },
        onUnknownError: () => set({ loading: false }),
        onNotAuthorized: () => set({ loading: false }),
        onSuccess: (data: AppResponse) => {
          set({
            currentUser: data.currentUser,
            currentPlan: data.currentPlan,
            loading: false,
            schools: data.schools,
            profileImageStoredFile: data.currentUser.profileImageFile,
            courseCount: data.courseCount,
          });
          identify({ user: data.currentUser, schools: data.schools });
          onSuccess?.(data);
          const { paperId, accountType, paperRosterSyncedAt } = data.currentUser;
          if (!paperId || !teacherOrGreater(accountType)) return;

          if (
            !paperRosterSyncedAt ||
            new Date().valueOf() - new Date(paperRosterSyncedAt).valueOf() > MILLISECONDS_IN_DAY
          ) {
            getFull().PaperSyncData.sync('auto');
          }
        },
      });
    },
    onSignedUp: ({
      user,
      schools,
      redirectPath,
      history,
      courseCode,
      method,
      replace = false,
      replaceLocation,
    }) => {
      trackSignUp({ user, method, schools });
      get().onLoggedIn({ redirectPath, history, courseCode, method, replace, replaceLocation });
    },
    onLoggedIn: ({
      redirectPath,
      history,
      courseCode,
      method,
      replace = false,
      replaceLocation,
    }) => {
      resetSocketConnection();

      const loadUserAndRedirect = (path?: string) => {
        get().load({
          onSuccess: (data) => {
            trackLogin({ method, user: data.currentUser, schools: data.schools });
            const finalPath = path || redirectPath || '/';
            if (replace) {
              if (replaceLocation) {
                window.location.replace(finalPath);
              } else {
                history.replace(finalPath);
              }
            } else {
              history.push(finalPath);
            }
          },
        });
      };

      if (courseCode) {
        fetchJson(`/api/registrations`, {
          method: 'POST',
          data: { courseCode },
          onSuccess: () => loadUserAndRedirect(),
        });
      } else {
        loadUserAndRedirect();
      }
    },
    logout: (history) => {
      set(defaultState);
      fetchJson(`/api/user_sessions`, {
        method: 'DELETE',
        onSuccess: () => {
          history.replace('/login');
          deidentify();
          resetSocketConnection();
          getFull().StudentProgressMonitoringData.clearCache();
          getFull().ProgressMonitoringData.clearCache();
          cookies.remove('refresh_token');
          cookies.remove('access_token');
        },
      });
    },
    setUserField:
      (field, createToast, successToast = true, onSuccess) =>
      (v) => {
        const { currentUser } = get();
        const update = { [field]: v };
        if (!currentUser) return;
        set({ currentUser: { ...currentUser, ...update } });
        fetchJson(`/api/users/${currentUser.id}`, {
          method: 'PATCH',
          data: update,
          onSuccess: ({ success, user }: UserUpdateResponse) => {
            if (success) {
              set({ currentUser: user });
              successToast &&
                createToast({ children: 'Setting updated!', color: 'success', duration: 1000 });
              onSuccess?.();
            } else {
              set({ currentUser: { ...currentUser } });
              createToast({ children: 'Setting failed to update!', color: 'danger' });
            }
          },
        });
      },
    setUserFields: (fields, createToast, successToast = true, onSuccess) => {
      const { currentUser } = get();
      if (!currentUser) return;
      set({ currentUser: { ...currentUser, ...fields.toObject() } });
      fetchJson(`/api/users/${currentUser.id}`, {
        method: 'PATCH',
        data: fields,
        onSuccess: ({ success, user }: UserUpdateResponse) => {
          if (success) {
            set({ currentUser: user });
            successToast &&
              createToast({ children: 'Setting updated!', color: 'success', duration: 1000 });
            onSuccess?.();
          } else {
            set({ currentUser: { ...currentUser } });
            createToast({ children: 'Setting failed to update!', color: 'danger' });
          }
        },
      });
    },
    uploadProfileImage: (files, createToast) => {
      const file = files.get(0, null);
      const { currentUser } = get();
      if (!currentUser || !file) return;

      set({ uploadingProfileImage: true });
      fetchJson(`/api/image_stored_files`, {
        method: 'POST',
        files: { file },
        onSuccess: ({ storedFile }: AppImageResponse) => {
          fetchJson(`/api/users/${currentUser.id}`, {
            method: 'PATCH',
            data: { profileImageFileId: storedFile.id },
            onSuccess: ({ success, user }: UserUpdateResponse) => {
              if (success) {
                set({
                  currentUser: user,
                  profileImageStoredFile: storedFile,
                  profileImageFile: file,
                  uploadingProfileImage: false,
                });

                createToast?.({ children: 'Setting updated!', color: 'success', duration: 1000 });
              } else {
                set({ currentUser: { ...currentUser } });
                createToast?.({ children: 'Setting failed to update!', color: 'danger' });
              }
            },
          });
        },
      });
    },
    emitPaperEvent: (event: PaperEvent) => {
      emitPaperEvent(getFull().AppData.currentUser, fetchJson, event);
    },
  }),
);
