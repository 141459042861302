import { VocabTaskSubmissionDetails } from 'src/models';
import { Text, Flex } from 'src/components';
import { Bold } from '../Bold';

type VocabDrawerHeaderProps = {
  currentTaskSubmissionDetails: VocabTaskSubmissionDetails;
};

export const VocabDrawerHeader = (props: VocabDrawerHeaderProps) => {
  const { task } = props.currentTaskSubmissionDetails;

  return (
    <Flex direction="column">
      <Text variant="h5">
        Vocabulary Word: <Bold>{task.customWord}</Bold>
      </Text>
    </Flex>
  );
};
