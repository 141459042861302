import { SetField } from 'src/State';
import { ImmutableList } from 'src/modules/Immutable';
import { ExtendedUser } from 'src/models/User';
import { UserSchool, UserSchoolRole } from 'src/models/UserSchool';
import { History, QueryMap } from 'src/modules/Router';
import { Plan, School, Subscription } from 'src/models';
import { emptyErrorMap, ErrorMap } from 'src/modules/Api';

export type UserSearchResult = {
  user: ExtendedUser;
  userSchool: UserSchool | null;
  plan: Plan | null;
  subscription: Subscription | null;
  school: School | null;
  hasCoTeachers: boolean;
};

export type UserSearchResponse = {
  success: boolean;
  results: ImmutableList<UserSearchResult>;
};

export type UserSearchState = {
  searchQuery: string;
  generalError: string;
  errors: ErrorMap;
  loading: boolean;
  updateUser: <K extends keyof ExtendedUser>(
    user: ExtendedUser,
    history: History,
    query: QueryMap,
    param: K,
    value: ExtendedUser[K],
  ) => void;
  updateUserSchool: (
    userSchool: UserSchool,
    role: UserSchoolRole,
    history: History,
    query: QueryMap,
  ) => void;
  results: ImmutableList<UserSearchResult>;
  submit: (history: History, query: QueryMap, targetUrl?: string) => void;
  load: ({ searchQuery, onSuccess }: { searchQuery: string; onSuccess: () => void }) => void;
  setField: SetField<UserSearchState>;
  grantTrial: (user: ExtendedUser, history: History, query: QueryMap) => void;
  closeTrialModal: (history: History, query: QueryMap) => () => void;
  deleteUser: (user: ExtendedUser, history: History, query: QueryMap) => () => void;
  trialEndDate: string;
};

export const defaultState = {
  loading: false,
  trialEndDate: '',
  searchQuery: '',
  generalError: '',
  errors: emptyErrorMap(),
  results: ImmutableList<UserSearchResult>(),
};
