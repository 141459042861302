import { useHistory } from 'src/modules/Router';
import { useStore } from 'src/Store';
import { DisplayType, Button } from '../Button';

export const FreeReadButton = ({ displayType }: { displayType: DisplayType }) => {
  const { createFreeRead, freeReadLoading } = useStore(
    (s) => ({
      currentUser: s.AppData.currentUser,
      createFreeRead: s.AssignmentBuilderData.createFreeRead,
      freeReadLoading: s.AssignmentBuilderData.freeReadLoading,
    }),
    [],
  );
  const history = useHistory();

  return (
    <Button
      color="primaryBlue"
      displayType={displayType}
      loading={freeReadLoading}
      onClick={() => createFreeRead(history)}
      height="40px"
    >
      Free Read
    </Button>
  );
};
