import { ReadingTaskSubmissionDetails } from './ReadingTaskSubmission';
import { ComprehensionTaskSubmissionDetails } from './ComprehensionTaskSubmission';
import { ModelTaskSubmissionDetails } from './ModelTaskSubmission';
import { VocabTaskSubmissionDetails } from './VocabTaskSubmission';

export type TaskSubmissionDetails =
  | ReadingTaskSubmissionDetails
  | ComprehensionTaskSubmissionDetails
  | ModelTaskSubmissionDetails
  | VocabTaskSubmissionDetails;

export const isTaskSubmissionCompleted = (
  taskSubmissionDetails: TaskSubmissionDetails,
): boolean => {
  if (taskSubmissionDetails.type === 'ReadingTask') {
    const { recordingSequence, audioFile } = taskSubmissionDetails;
    return Boolean(recordingSequence.processingStatus !== 'waiting' || audioFile);
  } else if (taskSubmissionDetails.type === 'ComprehensionTask') {
    const { task, taskSubmission } = taskSubmissionDetails;

    const recordingValid = () => {
      const { responseRecordingSequence, responseAudioFile } = taskSubmissionDetails;
      return Boolean(responseRecordingSequence.processingStatus !== 'waiting' || responseAudioFile);
    };
    const writtenValid = () => taskSubmission.responseContent.length > 0;
    if (task.responseType === 'recorded') {
      return recordingValid();
    } else if (task.responseType === 'written') {
      return writtenValid();
    } else if (task.responseType === 'student_choice') {
      return writtenValid() || recordingValid();
    } else {
      throw new Error(`Invalid comprehension task response type ${task.responseType}`);
    }
  } else if (taskSubmissionDetails.type === 'ModelTask') {
    return taskSubmissionDetails.taskSubmission.completed;
  } else if (taskSubmissionDetails.type === 'VocabTask') {
    return taskSubmissionDetails.taskSubmission.completed;
  } else {
    throw new Error(`Unrecognized task type ${JSON.stringify(taskSubmissionDetails)}`);
  }
};
