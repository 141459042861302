import { useStore } from 'src/Store';
import { queryMapToString } from 'src/modules/Router';
import { useBackPath, useReadingTaskSubmissionDetails } from './ReadingStudioHelpers';
import { ImmutableMap } from 'src/modules/Immutable';
import {
  Text,
  Loading,
  Flex,
  Button,
  Spacer,
  StandardModal,
  StarRow,
  Divider,
  Icon,
  Bold,
} from 'src/components';

export const TurnedInModal = () => {
  const {
    loading,
    setField,
    showFinishedModal,
    submission,
    submitRating,
    rating,
    taskSubmissionDetailsMap,
    currentUser,
  } = useStore(
    (state) => ({
      loading: state.ReadingStudioData.loading,
      setField: state.ReadingStudioData.setField,
      showFinishedModal: state.ReadingStudioData.showFinishedModal,
      submission: state.ReadingStudioData.submission,
      submitRating: state.ReadingStudioData.submitRating,
      rating: state.ReadingStudioData.rating,
      taskSubmissionDetailsMap: state.ReadingStudioData.taskSubmissionDetailsMap,
      currentUser: state.AppData.currentUser,
    }),
    [],
  );

  const readingTaskSubmissionDetails = useReadingTaskSubmissionDetails();
  const pointsEarned = readingTaskSubmissionDetails?.taskSubmission.pointsEarned || 0;
  const backPath = useBackPath();
  const processing = taskSubmissionDetailsMap.some(
    (t) => t.type === 'ReadingTask' && t.recordingSequence.processingStatus !== 'finished',
  );

  return (
    <StandardModal
      header="Turned In!"
      openType="state"
      showConfetti={showFinishedModal}
      opened={!loading && showFinishedModal}
      close={() => setField('showFinishedModal')(false)}
      footer={
        <Flex justify="end" align="center" width="100%">
          <Button width="auto" displayType="outline" color="primaryBlue" to={backPath}>
            {currentUser?.accountType === 'teacher' ? 'Back to Assignment' : 'Home'}
          </Button>
          <Spacer horizontal size={2} />
          <Button
            to={`/submissions/${submission?.id}${queryMapToString(ImmutableMap({ backPath }))}`}
            color="primaryBlue"
          >
            View My Results
          </Button>
        </Flex>
      }
    >
      <Text>You can review your results or click home to find more content to read!</Text>
      <Spacer size={4} />
      <Flex direction="column" align="center">
        {processing ? (
          <>
            <Flex align="center">
              <Icon icon="token" color="white" size="1.5em" />
              <Spacer horizontal size={2} />
              <Text variant="h4" weightOverride="medium">
                Calculating points now!
              </Text>
              <Spacer horizontal size={2} />
              <Icon icon="token" color="white" size="1.5em" />
            </Flex>
            <Spacer size={2} /> <Loading size="medium" />
          </>
        ) : (
          <>
            {pointsEarned === 0 ? (
              <Flex>
                <Text variant="h3" weightOverride="medium">
                  Keep reading throughout the week to earn more points!
                </Text>
                <Spacer horizontal />
                <Icon icon="token" color="white" size="1.5em" />
              </Flex>
            ) : (
              <>
                <Flex align="center">
                  <Text variant="h3" weightOverride="medium">
                    You earned <Bold>{pointsEarned}</Bold>
                  </Text>
                  <Spacer horizontal />
                  <Icon icon="token" color="white" size="1.5em" />
                </Flex>
                <Text variant="h3" weightOverride="medium">
                  on this reading!
                </Text>
              </>
            )}
          </>
        )}
      </Flex>
      <Spacer size={3} />
      <Divider />
      <Spacer size={3} />
      <Text textAlign="center" variant="h4">
        How interesting was the reading?
      </Text>
      <Spacer size={2} />
      <Flex justify="center">
        <StarRow
          onClick={(val) => submitRating(val + 1)}
          rating={rating ?? -1}
          borderColor="black"
        />
      </Flex>
      <Spacer size={3} />
    </StandardModal>
  );
};
