import { ImageStoredFile } from './ImageStoredFile';
import { Word } from './Word';

export type VocabTask = {
  id: string;
  name: string;
  type: 'VocabTask';
  status: 'active' | 'empty' | 'deleted';
  wordId: string | null;
  customWord: string;
  definition: string;
  imageFileId: string | null;
};

export type VocabTaskDetails = {
  type: 'VocabTask';
  task: VocabTask;
  word: Word | null;
  imageFile: ImageStoredFile | null;
};
