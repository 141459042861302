import { useCallback, useEffect, useMemo } from 'react';
import {
  useParams,
  useQuery,
  useHistory,
  updateQuery,
  queryMapToString,
  useLocation,
} from 'src/modules/Router';
import {
  Flex,
  LinkButton,
  Loading,
  Title,
  TaskCard,
  TaskSubmissionCard,
  Spacer,
  Text,
  SelectInput,
  Icon,
  UpgradeAssignmentModal,
} from 'src/components';
import { CenteredLayout } from 'src/layouts';
import { paddings } from 'src/styles';
import { useStore } from 'src/Store';
import {
  usageCapFeatureEnabled,
  Course,
  formatName,
  isTaskSubmissionCompleted,
  TaskType,
  teacherOrGreater,
} from 'src/models';
import { ImmutableList, reverseIf } from 'src/modules/Immutable';
import { partition } from '../../modules/Immutable';

const sortOptions = ImmutableList([
  { value: 'nameDesc', label: 'Name (A-Z)' },
  { value: 'nameAsc', label: 'Name (Z-A)' },
  { value: 'completion', label: 'Completion' },
] as const);

export const TaskOverview = () => {
  const query = useQuery();
  const history = useHistory();
  const pathname = useLocation().pathname;
  const { type, id } = useParams<{ type: TaskType; id: string }>();
  const {
    load,
    assessment,
    loading,
    taskDetails,
    submissionMap,
    submissionIdToTaskSubmissionDetailsMap,
    studentIdToAssignedCoursesMap,
    taskCardOpen,
    userMap,
    courseMap,
    setField,
    upgradeAssessment,
    currentUser,
    currentPlan,
    startAssignmentUpgrade,
  } = useStore(
    (state) => ({
      load: state.TaskOverviewData.load,
      assessment: state.TaskOverviewData.assessment,
      loading: state.TaskOverviewData.loading,
      taskDetails: state.TaskOverviewData.taskDetails,
      submissionMap: state.TaskOverviewData.submissionMap,
      submissionIdToTaskSubmissionDetailsMap:
        state.TaskOverviewData.submissionIdToTaskSubmissionDetailsMap,
      studentIdToAssignedCoursesMap: state.TaskOverviewData.studentIdToAssignedCoursesMap,
      taskCardOpen: state.TaskOverviewData.taskCardOpen,
      userMap: state.TaskOverviewData.userMap,
      courseMap: state.TaskOverviewData.courseMap,
      setField: state.TaskOverviewData.setField,
      upgradeAssessment: state.TaskOverviewData.upgradeAssessment,
      currentUser: state.AppData.currentUser,
      currentPlan: state.AppData.currentPlan,
      startAssignmentUpgrade: state.UpgradeAssignmentModalData.startAssignmentUpgrade,
    }),
    [],
  );

  useEffect(() => {
    load(id, type);
  }, [load, id, type]);

  const selectedClassParam = query.get('selectedClass') || 'all';
  const sortByParam = query.get('sortBy') || 'name';
  const backPath = query.get('backPath') || `/t/assignments/${assessment?.id}`;
  const usageCapsEnabled = currentPlan.isUsageCapsEnabled;
  const availableUltimateAssessments = currentUser?.ultimateAssessmentsAvailable ?? 0;

  const updateClassParam = useCallback(
    (value: string) => history.push(`${pathname}${updateQuery(query, { selectedClass: value })}`),
    [history, pathname, query],
  );
  const updateSortBy = useCallback(
    (value: string) => history.push(`${pathname}${updateQuery(query, { sortBy: value })}`),
    [history, pathname, query],
  );

  const selectedClassOptions = useMemo(() => {
    const courseFilters = courseMap.toList().map((c) => ({ value: c.id, label: c.name }));
    const teacherFilter = submissionMap.some((s) =>
      userMap
        .filter((u) => u.accountType !== 'student')
        .keySeq()
        .includes(s.userId),
    )
      ? [{ value: 'teachers', label: 'Teacher Submissions' }]
      : [];
    const allFilter = { value: 'all', label: 'All Classes' };

    return courseFilters.unshift(allFilter).concat(teacherFilter);
  }, [courseMap, submissionMap, userMap]);

  if (loading) return <Loading flex kind="boat" />;
  if (!taskDetails) return null;

  const currentTaskIndex =
    assessment?.taskOrder.findIndex(
      (ti) => ti.taskId === taskDetails.task.id && ti.type === taskDetails.type,
    ) || 0;

  const previousTaskInfo =
    currentTaskIndex > 0 ? assessment?.taskOrder.get(currentTaskIndex - 1) : null;

  const nextTaskInfo =
    assessment && currentTaskIndex < assessment.taskOrder.size
      ? assessment?.taskOrder.get(currentTaskIndex + 1)
      : null;

  const baseUsers = userMap
    .toList()
    .filter((u) => u.accountType === 'student' || submissionMap.has(u.id));

  const [completedStudents, incompleteStudents] = partition(baseUsers, (s) => {
    const submission = submissionMap.get(s.id);
    if (!submission) return false;
    const taskSubmissionDetails = submissionIdToTaskSubmissionDetailsMap.get(submission.id);
    if (!taskSubmissionDetails) return false;

    return isTaskSubmissionCompleted(taskSubmissionDetails);
  });

  const filteredUsers = reverseIf(
    sortByParam === 'nameAsc',
    baseUsers
      .filter((s) => {
        if (selectedClassParam === 'all') return true;
        if (selectedClassParam === 'teachers') return teacherOrGreater(s.accountType);
        const courses = studentIdToAssignedCoursesMap.get(s.id);

        return courses && !courses?.filter((c) => c.id === selectedClassParam).isEmpty();
      })
      .sortBy((s) => {
        if (sortByParam === 'completion') {
          const completed = completedStudents.map((u) => u.id).includes(s.id) ? 0 : 1;
          return [completed, formatName(s)];
        }
        return formatName(s);
      }),
  );

  return (
    <CenteredLayout overflowY="auto">
      <Title title="Question 1" />
      <Flex direction="column">
        <Flex padding={`${paddings[4]} 0`} justify="space-between" align="center">
          <LinkButton color="black" to={backPath} icon="backArrow">
            Back to Assignment
          </LinkButton>
          <Flex>
            <LinkButton
              color="black"
              to={`/t/assignments/task/${previousTaskInfo?.type}/${
                previousTaskInfo?.taskId
              }${queryMapToString(query)}`}
              icon="arrowLeft"
              disabled={!previousTaskInfo}
            >
              Previous
            </LinkButton>
            <Spacer horizontal size={4} />
            <LinkButton
              color="black"
              to={`/t/assignments/task/${nextTaskInfo?.type}/${
                nextTaskInfo?.taskId
              }${queryMapToString(query)}`}
              icon="arrowRight"
              iconPosition="right"
              disabled={!nextTaskInfo}
            >
              Next
            </LinkButton>
          </Flex>
        </Flex>
        <TaskCard
          taskDetails={taskDetails}
          completedStudents={completedStudents}
          incompleteStudents={incompleteStudents}
          open={taskCardOpen}
          onToggleCardOpen={() => setField('taskCardOpen')(!taskCardOpen)}
        />
        <Spacer size={4} />
        <Flex justify="space-between" align="center" width="100%">
          <Flex align="center">
            <Text display="inline" color="gray6">
              Sort By:
            </Text>
            <SelectInput
              id="sortBySelect"
              displayType="flat"
              name="Sort Assessments By"
              value={sortByParam}
              onChange={updateSortBy}
              options={sortOptions}
            />
          </Flex>
          <Flex align="center">
            <Icon
              icon="filter"
              color="readleeBlue"
              fill={selectedClassParam !== 'all'}
              strokeWidth={1.5}
              strokeLinecap="round"
            />
            <Spacer horizontal />
            <SelectInput
              id="filterClassSelect"
              displayType="flat"
              name="Sort Assessments By"
              value={selectedClassParam}
              onChange={updateClassParam}
              options={selectedClassOptions}
            />
          </Flex>
        </Flex>
        <Spacer size={2} />

        {filteredUsers.map((student) => {
          const submission = submissionMap.get(student.id);
          const taskSubmissionDetails = submission
            ? submissionIdToTaskSubmissionDetailsMap.get(submission.id, null)
            : null;

          const textDiffs =
            taskSubmissionDetails?.type === 'ComprehensionTask'
              ? taskSubmissionDetails.responseAudioFile?.transcriptItems
              : undefined;

          return (
            <TaskSubmissionCard
              key={student.id}
              usageCapsEnabled={usageCapsEnabled}
              availableUltimateAssessments={availableUltimateAssessments}
              toolTipOnClick={() =>
                startAssignmentUpgrade(assessment?.id ?? '', 'comprehensionTranscription')
              }
              taskDetails={taskDetails}
              taskSubmissionDetails={taskSubmissionDetails}
              assignedCourses={studentIdToAssignedCoursesMap.get(
                student.id,
                ImmutableList<Course>(),
              )}
              user={student}
              submissionMap={submissionMap}
              comprehensionTextDiff={textDiffs}
              canSeeTranscription={usageCapFeatureEnabled(
                'isComprehensionTranscriptEnabled',
                currentUser,
                assessment,
                true,
              )}
            />
          );
        })}
      </Flex>
      <Spacer size={22} />
      <UpgradeAssignmentModal onSuccess={upgradeAssessment} />
    </CenteredLayout>
  );
};
