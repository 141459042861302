import React from 'react';
import { colors, paddings, borderRadii, TextAlign } from 'src/styles';
import styled, { css } from 'styled-components';

export const TableRow = styled.tr<{ clickable?: boolean }>`
  ${({ clickable }) =>
    clickable
      ? `
        cursor: pointer;
        &:hover {
          background: ${colors.white.active};
        }
        &:nth-child(even) {
          &:hover {
            background ${colors.backgroundLight.active}
          }
        }
      `
      : ''}

  &:nth-child(even) {
    background: ${colors.backgroundLight.hex};
    border-radius: ${borderRadii[4]};
  }
  padding: ${paddings[2]};
`;
export const TableBody = styled.tbody``;
export const TableHead = styled.thead`
  border-bottom: 1px solid ${colors.gray2.hex};
`;

export const TableHeaderEntry = styled.th<{ $textAlign?: TextAlign }>`
  text-align: ${(props) => props.$textAlign || 'left'};
  padding: ${paddings[1]};
  color: ${colors.gray7.hex};
  vertical-align: bottom;
`;
export const TableEntry = styled.td<{ $textAlign?: TextAlign }>`
  text-align: ${(props) => props.$textAlign || 'left'};
  padding: ${paddings[3]} ${paddings[1]};

  &:first-child {
    border-top-left-radius: ${borderRadii[4]};
    border-bottom-left-radius: ${borderRadii[4]};
  }

  &:last-child {
    border-top-right-radius: ${borderRadii[4]};
    border-bottom-right-radius: ${borderRadii[4]};
  }
`;

export const EmptyStateContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: ${colors.white.hex};
  border-bottom-left-radius: ${borderRadii[3]};
  border-bottom-right-radius: ${borderRadii[3]};
`;

const BaseTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  background: ${colors.white.hex};
`;

const TableCard = styled.div<{ $noPadding: boolean; $showEmptyState: boolean }>`
  background: ${colors.white.hex};
  padding: ${({ $noPadding }) => ($noPadding ? paddings.none : paddings[4])};
  width: 100%;
  height: fit-content;

  ${({ $showEmptyState }) =>
    $showEmptyState
      ? css`
          border-top-left-radius: ${borderRadii[3]};
          border-top-right-radius: ${borderRadii[3]};
        `
      : `border-radius: ${borderRadii[3]};`}
`;

type TableProps = {
  children: React.ReactNode;
  noPadding?: boolean;
  showEmptyState?: boolean;
};

export const Table = ({ children, noPadding = false, showEmptyState = false }: TableProps) => {
  return (
    <TableCard $noPadding={noPadding} $showEmptyState={showEmptyState}>
      <BaseTable>{children}</BaseTable>
    </TableCard>
  );
};
