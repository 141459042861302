import { ErrorMap, emptyErrorMap } from 'src/modules/Api';
import { History } from 'src/modules/Router';
import { PasswordReset } from 'src/models';

type ResetUser = {
  newPassword: string;
  passwordConfirmation: string;
};

export type ResetPasswordState = {
  user: ResetUser;
  passwordReset: PasswordReset | null;
  errors: ErrorMap;
  submitting: boolean;
  loading: boolean;
  load: (reset: PasswordReset) => void;
  submit: (history: History) => () => void;
  setUserField: <K extends keyof ResetUser>(key: K) => (value: ResetUser[K]) => void;
};

export const defaultState = {
  passwordReset: null,
  user: {
    newPassword: '',
    passwordConfirmation: '',
  },
  errors: emptyErrorMap(),
  submitting: false,
  loading: true,
};
