import { useEffect, useState } from 'react';
import {
  ApiErrorHandlers,
  ApiErrorType,
  Json,
  transformApiResponse,
  useFetch,
} from 'src/modules/Api';
import { TestMode } from 'src/components';
import { consumer } from 'src/modules/Socket';

type ErrorMessage = {
  isRejectionMessage: true;
  errorType: ApiErrorType;
  message: string;
};

type SubscribeProps = ApiErrorHandlers & {
  /** Name of the channel */
  channel:
    | 'RecordingChannel'
    | 'RecordingSequenceChannel'
    | 'SubmissionChannel'
    | 'PublicChannel'
    | 'StoredFileChannel'
    | 'SubscriptionChannel'
    | 'UserChannel';
  id?: string;
  onMessage: (data: Json) => void;
  onConnected?: () => void;
  onDisconnected?: () => void;
};

export const Subscribe = ({
  channel,
  id,
  onMessage,
  onConnected,
  onDisconnected,
  onNotAuthenticated,
  onNotAuthorized,
  onUnknownError,
  noRedirect = false,
}: SubscribeProps) => {
  const { handleApiError } = useFetch();
  const [connected, setConnected] = useState(false);
  useEffect(() => {
    const subscription = consumer.subscriptions.create(
      { channel, id },
      {
        received: (data: Json) => {
          const parsedData = transformApiResponse(data);
          if (parsedData.isRejectionMessage) {
            const errorData: ErrorMessage = parsedData;
            handleApiError({
              errorType: errorData.errorType,
              message: errorData.message,
              onNotAuthenticated,
              onNotAuthorized,
              onUnknownError,
              noRedirect,
            });
          } else {
            onMessage(parsedData);
          }
        },
        connected: () => {
          onConnected?.();
        },
        disconnected: () => {
          onDisconnected?.();
        },
      },
    );
    setConnected(true);

    return () => {
      setConnected(false);
      subscription.unsubscribe();
    };
  }, [
    channel,
    onMessage,
    onConnected,
    onDisconnected,
    id,
    onNotAuthenticated,
    onUnknownError,
    onNotAuthorized,
    noRedirect,
    handleApiError,
  ]);

  return (
    <TestMode>
      <p>
        {channel}-{id} status: {String(connected)}
      </p>
    </TestMode>
  );
};
