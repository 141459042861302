import { RecordingSequence } from './RecordingSequence';
import { TranscriptableAudioStoredFile } from './TranscriptableAudioStoredFile';

export type CommentReaction =
  | 'undecided'
  | 'check'
  | 'clapping'
  | 'one_hundred'
  | 'parrot'
  | 'rocket'
  | 'thinking';

export type CommentType = 'undecided' | 'written' | 'recorded' | 'reaction';

export type CreateCommentType =
  | {
      reaction: CommentReaction;
      commentType: 'reaction';
    }
  | {
      commentType: 'written';
      writtenContent: string;
    }
  | {
      recordingSequenceId: string;
      commentType: 'recorded';
    };

export type Comment = {
  id: string;
  writtenContent: string;
  commentType: CommentType;
  reaction: CommentReaction;
  creatorId: string;
  viewed: boolean;
  createdAt: string;
};

export type CommentDetails = {
  comment: Comment;
  recordingSequence: RecordingSequence | null;
  audioFile: TranscriptableAudioStoredFile | null;
};
