import { subDataCreator } from 'src/State';
import { defaultState } from './TeacherOnboardingState';
import { queryMapToString } from 'src/modules/Router';
import { AppResponse } from 'src/AppState';

export const createDefaultTeacherOnboardingData = subDataCreator(
  'TeacherOnboardingData',
  defaultState,
  ({ get, set, setField, fetchJson }) => {
    return {
      ...defaultState,
      setField,
      load: () => {
        set({ ...defaultState, loading: true });
        fetchJson(`/api/users/me`, {
          onSuccess: (data: AppResponse) => {
            set({
              currentUser: data.currentUser,
              tempPositions: data.currentUser.positions || '',
              tempTeachesSpecialEd: data.currentUser.teachesSpecialEducation,
              tempTeachesEll: data.currentUser.teachesEll,
              userSchools: data.schools,
              loading: false,
            });
          },
        });
      },
      submitSchool: (history, query, schools, savedSchools) => () => {
        const { currentUser } = get();
        if (!currentUser || (schools.size === 0 && savedSchools.size === 0)) return;
        fetchJson(`/api/users/${currentUser.id}/update_schools`, {
          method: 'PATCH',
          data: {
            onboardingStatus: 'completed',
            schoolIds: schools.isEmpty() ? savedSchools.map((s) => s.id) : schools.map((s) => s.id),
          },
          onSuccess: () => {
            history.push(`/t/onboarding/questions${queryMapToString(query)}`);
          },
        });
      },
      submitResponses: (history, redirectPath) => () => {
        const { currentUser, tempPositions, tempTeachesSpecialEd, tempTeachesEll } = get();
        if (!currentUser) return;
        fetchJson(`/api/users/${currentUser.id}`, {
          method: 'PATCH',
          data: {
            positions: tempPositions,
            teachesSpecialEducation: tempTeachesSpecialEd,
            teachesEll: tempTeachesEll,
          },
          onSuccess: () => {
            history.push(redirectPath || '/');
          },
        });
      },
      skip: (history, redirectPath) => {
        const { currentUser } = get();
        if (!currentUser) return;
        if (currentUser.onboardingStatus === 'completed') {
          history.push(redirectPath || '/');
          return;
        }
        fetchJson(`/api/users/${currentUser.id}/update_schools`, {
          method: 'PATCH',
          data: {
            onboardingStatus: 'skipped',
          },
          onSuccess: () => {
            history.push(redirectPath || '/');
          },
        });
      },
    };
  },
);
