import * as ErrorReporting from 'src/modules/ErrorReporting';
import { AmplitudeClient } from 'amplitude-js';

export type DevAnalyticsEventPrefix = '__dev__';

export type ReadleeAnalyticsEvent =
  | 'teacher_signup'
  | 'student_signup'
  | 'moderator_signup'
  | 'admin_signup'
  | 'login'
  | 'create_assessment'
  | 'upgrade_assessment'
  | 'create_course'
  | 'assign_assessment'
  | 'page_view'
  | 'import_course'
  | 'recording_completed_event'
  | 'submit_assignment';

export type FullAnalyticsEvent =
  | ReadleeAnalyticsEvent
  | `${DevAnalyticsEventPrefix}${ReadleeAnalyticsEvent}`;

export const gtm = (...args: unknown[]) => {
  ErrorReporting.catchAndReportErrors(() => {
    if (typeof window.gtag === 'function') {
      window.gtag(...args);
    }
  });
};

export const zendeskDisplay = (
  widget: string,
  action: string,
  options?: Record<string, unknown>,
) => {
  if (process.env.REACT_ENV !== 'production') {
    return;
  }
  const doZendesk = window.zE;

  ErrorReporting.catchAndReportErrors(() => {
    if (doZendesk && typeof doZendesk === 'function') {
      doZendesk(widget, action, options);
    }
  });
};

export const startZendeskTicket = () => {
  zendeskDisplay('webWidget', 'open');
};

// I don't know why this API is so weird. Docs here:
// https://api.zopim.com/files/meshim/widget/controllers/LiveChatAPI-js.html
export const zendesk = (fn: (zendesk: NonNullable<typeof $zopim>) => void) => {
  if (process.env.REACT_ENV !== 'production') {
    return;
  }
  const $zopim = window.$zopim;

  ErrorReporting.catchAndReportErrors(() => {
    if ($zopim && typeof $zopim === 'function') {
      $zopim(function () {
        fn($zopim);
      });
    }
  });
};

export const appcues = (fn: (appcues: NonNullable<typeof window.Appcues>) => void) => {
  const Appcues = window.Appcues;

  if (Appcues && typeof Appcues.identify === 'function' && process.env.REACT_ENV === 'production') {
    ErrorReporting.catchAndReportErrors(() => {
      fn(Appcues);
    });
  }
};

export const sendGoogleAnalyticsEvent = (
  eventName: FullAnalyticsEvent,
  options?: Record<string, string>,
) => {
  if (process.env.REACT_ENV !== 'production' && !process.env.ENABLE_DEV_ANALYTICS) {
    return;
  }
  gtm('event', eventName, options);
};

let amplitudeInstanceCache: AmplitudeClient | null = null;
let amplitudeInstanceFetched = false;
let amplitudeInstancePromise: Promise<AmplitudeClient | null> | null = null;

export const getAmplitude = async (): Promise<AmplitudeClient | null> => {
  if (process.env.REACT_ENV !== 'production' && !process.env.ENABLE_DEV_ANALYTICS) {
    return null;
  }
  if (amplitudeInstanceFetched) return amplitudeInstanceCache;
  if (!amplitudeInstancePromise) {
    amplitudeInstancePromise = ErrorReporting.catchAndReportAsyncErrors(async () => {
      const amplitudeLibrary = (await import('amplitude-js')).default;
      const apiKey = process.env.AMPLITUDE_API_KEY;
      if (!apiKey) {
        ErrorReporting.reportError(new Error('Amplitude API key was not defined.'));
        return null;
      }
      amplitudeLibrary
        .getInstance()
        .init(apiKey, undefined, { trackingOptions: { city: false, ip_address: false } }); // initializes default instance of Amplitude client
      amplitudeInstanceCache = amplitudeLibrary.getInstance();
      amplitudeInstanceFetched = true;
      return amplitudeInstanceCache;
    });
  }
  const instance = await amplitudeInstancePromise;
  return instance;
};

export const sendAmplitudeEvent = async (
  event: FullAnalyticsEvent,
  options?: Record<string, string>,
) => {
  const amplitude = await getAmplitude();
  amplitude?.logEvent(event, options);
};

export const init = async () => {
  ErrorReporting.init();
  await getAmplitude();
};
