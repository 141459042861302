import { useMemo } from 'react';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';
import { Chart as ChartType, TooltipModel, ChartData } from 'chart.js';
import { CHART_HEIGHT } from './StudentProgressChartComponents';
import { tooltipPlugin, useOptions } from './StudentProgressChartHelpers';

type StudentProgressMonitoringChartProps = {
  datasetIdKey: string;
  data: ChartData<'line', { x: string; y: number }[] | null, unknown>;
  maxValue: number;
  tooltipPropsHandler: (ctx: { chart: ChartType; tooltip: TooltipModel<'line'> }) => void;
};
export const StudentProgressMonitoringChart = ({
  datasetIdKey,
  data,
  maxValue,
  tooltipPropsHandler,
}: StudentProgressMonitoringChartProps) => {
  const baseOptions = useOptions();
  const options = useMemo(
    () => ({
      ...baseOptions,
      scales: { ...baseOptions.scales, y: { min: 0, max: maxValue } },
      parsing: false as const,
      normalized: true,
      plugins: {
        ...baseOptions.plugins,
        tooltip: { ...baseOptions.plugins.tooltip, external: tooltipPropsHandler },
      },
    }),
    [baseOptions, maxValue, tooltipPropsHandler],
  );

  return (
    <div>
      <Line
        datasetIdKey={datasetIdKey}
        data={data}
        options={options}
        plugins={tooltipPlugin}
        height={CHART_HEIGHT}
      />
    </div>
  );
};
