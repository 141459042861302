import { VocabTaskDetails } from './VocabTask';

export type VocabTaskSubmission = {
  id: string;
  completed: boolean;
};

export type VocabTaskSubmissionDetails = VocabTaskDetails & {
  taskSubmission: VocabTaskSubmission;
};
