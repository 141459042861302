import React, { useState, useEffect, useRef, RefObject } from 'react';
import styled, { css } from 'styled-components';

import * as pdfjs from 'pdfjs-dist';
import * as pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';
import { PDFDocumentProxy } from 'pdfjs-dist/types/src/display/api';

import { Flex, Spacer, Loading, Text } from 'src/components';
import { borderRadii, colors, margins, useBreakpoints } from 'src/styles';
import { reportError } from 'src/modules/ErrorReporting';

pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

type PageThumbnail = {
  image: string | null;
  // height: number;
  // width: number;
  page: number;
};

const CanvasContainer = styled.div`
  margin: 0 auto;
  outline: none;
`;

type ImgStyleProps = {
  $height: number;
  $width: number;
};
const imgStyles = css<ImgStyleProps>`
  display: flex;
  height: ${({ $height }) => $height}px;
  min-width: ${({ $width }) => $width}px;
  border-radius: ${borderRadii[2]};
  user-select: none;
`;

const ThumbnailImg = styled.img<ImgStyleProps>`
  ${imgStyles}
  cursor: pointer;
`;
const LoadingImg = styled.div<ImgStyleProps>`
  ${imgStyles}
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;

const ThumbnailContainer = styled.div<{ $selected: boolean }>`
  margin: ${margins[3]} ${margins[5]} ${margins[1]};
  border-radius: ${borderRadii[2]};
  width: fit-content;

  ${({ $selected }) => {
    const borderColor = $selected ? colors.black.hex : colors.gray4.hex;
    return `box-shadow: 0 0 0 2px ${borderColor};`;
  }};
`;

const PDFContainer = styled.div<{ $showMobile: boolean }>`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: ${({ $showMobile }) => ($showMobile ? 'start' : 'center')};
  justify-content: center;
  overflow-y: auto;
`;

const smoothScrollRefIntoView = (ref: RefObject<HTMLElement>) => {
  if (!ref.current) return;

  ref.current.scrollIntoView({
    block: 'nearest',
    inline: 'center',
  });
};

const UnmemoizedThumbnailList = ({
  thumbnails,
  setCurrentPage,
  currentPage,
  height = 200,
  width = 130,
}: {
  thumbnails: PageThumbnail[];
  setCurrentPage: (page: number) => void;
  currentPage: number;
  height?: number;
  width?: number;
}) => {
  const selectedRef = useRef<HTMLImageElement>(null);
  useEffect(() => smoothScrollRefIntoView(selectedRef), [currentPage]);
  return thumbnails.length === 0 ? null : (
    <>
      {thumbnails.map(({ image, page }) => (
        <Flex key={page} direction="column" align="center">
          <ThumbnailContainer $selected={currentPage === page}>
            {image ? (
              <ThumbnailImg
                ref={currentPage === page ? selectedRef : null}
                onClick={() => setCurrentPage(page)}
                src={image}
                $height={height}
                $width={width}
                alt={`Thumbnail of page ${page}`}
                title={`Page ${page}`}
                data-test-tag={`thumbnail-${page}`}
              />
            ) : (
              <LoadingImg $height={height} $width={width} title={`Page ${page}`}>
                <Loading size="large" />
              </LoadingImg>
            )}
          </ThumbnailContainer>
          <Text>{page}</Text>
        </Flex>
      ))}
    </>
  );
};
const ThumbnailList = React.memo(UnmemoizedThumbnailList);

type PDFJsWrapperProps = {
  src: string;
  currentPage: number;
  scale: number;
  rotation: number;
  setCurrentPage: (pageNumber: number) => void;
  hideThumbnails?: boolean;
  startPage?: number;
  endPage?: number;
  drawerOpen?: boolean;
  kind: 'compact' | 'full' | 'thumbnailsOnly';
};

const UnmemoizedPdfJsWrapper = ({
  scale = 1,
  rotation,
  src,
  currentPage = 1,
  startPage: baseStartPage = 1,
  endPage: baseEndPage = 999999,
  setCurrentPage,
  hideThumbnails = false,
  drawerOpen = false,
  kind,
}: PDFJsWrapperProps) => {
  const [loading, setLoading] = useState(true);
  const [pdf, setPDF] = useState<PDFDocumentProxy | null>(null);
  const [errored, setErrored] = useState<boolean>();
  const [generatedThumbnails, setGeneratedThumbnails] = useState<PageThumbnail[]>([]);

  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const canvasContainerRef = useRef<HTMLDivElement | null>(null);
  const thumbCanvasRef = useRef<HTMLCanvasElement | null>(null);
  const pageToRenderRef = useRef<number | null>(null);
  const renderingPageRef = useRef<number | null>(null);
  const generatingThumbnailsRef = useRef<boolean>(false);
  const regenerateThumbnailsNeededRef = useRef<[number, number] | null>(null);
  const thumbnailCacheRef = useRef<Map<number, Map<number, PageThumbnail>>>(new Map());
  const thumbnailDimensionsRef = useRef<Map<number, { width: number; height: number }>>(new Map());
  const lastSrcUrlRef = useRef<URL>();

  const showMobile = useBreakpoints({ smallerThanOrEqualTo: 'tablet' });

  const startPage = pdf ? Math.max(baseStartPage, 1) : baseStartPage;
  const endPage = pdf ? Math.min(baseEndPage, pdf.numPages) : baseEndPage;

  const updatePage = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.preventDefault();
    event.stopPropagation();
    const width = document.body.clientWidth;
    const side = event.clientX > width / 2 ? 'right' : 'left';
    setCurrentPage(side === 'right' ? currentPage + 1 : currentPage - 1);
  };

  useEffect(() => {
    const newSrcUrl = new URL(src);
    // Don't re-render if it's a new version of the same PDF
    if (
      lastSrcUrlRef.current &&
      newSrcUrl.host === lastSrcUrlRef.current.host &&
      newSrcUrl.pathname === lastSrcUrlRef.current.pathname
    ) {
      return;
    }
    const fetchPdf = async () => {
      const pdfDoc = await pdfjs.getDocument({ url: src }).promise;
      thumbnailCacheRef.current.clear();
      setPDF(pdfDoc);
      setLoading(false);
      lastSrcUrlRef.current = newSrcUrl;
    };
    fetchPdf();
  }, [src]);

  useEffect(() => {
    const generateThumbnails = async (start: number, end: number) => {
      const canvas = thumbCanvasRef.current;
      if (hideThumbnails || kind === 'compact') return;
      if (pdf === null) return;
      if (canvas === null) return;

      if (generatingThumbnailsRef.current) {
        regenerateThumbnailsNeededRef.current = [start, end];
        return;
      }

      generatingThumbnailsRef.current = true;

      const loadingImagelist = [];

      for (let pageNo = start; pageNo <= end; pageNo++) {
        const cachedThumbnail = thumbnailCacheRef.current.get(rotation)?.get(pageNo);
        if (cachedThumbnail) {
          loadingImagelist.push(cachedThumbnail);
        } else {
          loadingImagelist.push({ page: pageNo, image: null });
        }
      }
      setGeneratedThumbnails(loadingImagelist);
      if (loadingImagelist.some((x) => !x.image)) {
        const imageList = [];

        for (let pageNo = start; pageNo <= end; pageNo++) {
          const cachedThumbnail = thumbnailCacheRef.current.get(rotation)?.get(pageNo);
          if (cachedThumbnail) {
            imageList.push(cachedThumbnail);
            continue;
          }
          const page = await pdf.getPage(pageNo);
          const viewport = page.getViewport({ scale: 0.25, rotation: page.rotate + rotation });

          // Prepare canvas using PDF page dimensions
          canvas.height = viewport.height;
          canvas.width = viewport.width;

          // Render PDF page into canvas context
          const canvasContext = canvas.getContext('2d');
          canvasContext?.clearRect(0, 0, canvas.width, canvas.height);
          canvasContext?.beginPath();
          const renderTask = page.render({
            canvasContext: canvasContext || {},
            viewport,
          });

          await renderTask.promise;

          if (!thumbnailDimensionsRef.current.has(rotation)) {
            thumbnailDimensionsRef.current.set(rotation, {
              height: viewport.height,
              width: viewport.width,
            });
          }

          const thumbnail = {
            image: canvas.toDataURL('image/png'),
            page: pageNo,
          };

          if (!thumbnailCacheRef.current.has(rotation)) {
            const newMap = new Map().set(pageNo, thumbnail);
            thumbnailCacheRef.current.set(rotation, newMap);
          } else {
            thumbnailCacheRef.current.get(rotation)?.set(pageNo, thumbnail);
          }

          imageList.push(thumbnail);
        }
        setGeneratedThumbnails(imageList);
      }

      generatingThumbnailsRef.current = false;
      if (regenerateThumbnailsNeededRef.current) {
        const [newStart, newEnd] = regenerateThumbnailsNeededRef.current;
        regenerateThumbnailsNeededRef.current = null;
        generateThumbnails(newStart, newEnd);
      }
    };

    generateThumbnails(startPage, endPage);
  }, [kind, hideThumbnails, pdf, startPage, endPage, rotation]);

  useEffect(() => {
    const updateCurrentPage = async (pageNumber: number) => {
      pageToRenderRef.current = pageNumber;

      const canvas = canvasRef.current;
      if (!pdf) return;
      if (!canvas) return;
      if (typeof renderingPageRef.current === 'number') return;

      renderingPageRef.current = pageNumber;

      try {
        const page = await pdf.getPage(pageNumber);
        const viewport = page.getViewport({ scale, rotation: page.rotate + rotation });

        // Prepare canvas using PDF page dimensions
        canvas.height = viewport.height;
        canvas.width = viewport.width;

        // Render PDF page into canvas context
        const canvasContext = canvas.getContext('2d');
        canvasContext?.clearRect(0, 0, canvas.width, canvas.height);
        canvasContext?.beginPath();
        const renderTask = page.render({
          canvasContext: canvasContext || {},
          viewport,
        });
        await renderTask.promise;
        smoothScrollRefIntoView(canvasContainerRef);

        renderingPageRef.current = null;
        const currentPageToRender = pageToRenderRef.current;

        if (typeof currentPageToRender === 'number' && currentPageToRender !== pageNumber) {
          await updateCurrentPage(currentPageToRender);
        }
      } catch (error) {
        reportError(error);
        setErrored(true);
      }
    };

    updateCurrentPage(currentPage);
  }, [currentPage, generatedThumbnails, hideThumbnails, pdf, scale, rotation, setCurrentPage]);

  useEffect(() => {
    const onKeyDown = (event: KeyboardEvent) => {
      if (!canvasContainerRef.current) return;
      if (['ArrowDown', 'ArrowUp'].includes(event.key)) {
        canvasContainerRef.current.focus();
      }
    };
    document.addEventListener('keydown', onKeyDown);
    return () => {
      document.removeEventListener('keydown', onKeyDown);
    };
  }, []);

  return (
    <Flex height="100%" width="100%" justify="center">
      {!hideThumbnails && (
        <Flex
          direction="column"
          overflowY="auto"
          overflowX="hidden"
          width="fit-content"
          height="100%"
        >
          <canvas
            style={{ display: 'none' }}
            onContextMenu={(e) => e.preventDefault()}
            ref={thumbCanvasRef}
            width={100}
          />
          <ThumbnailList
            thumbnails={generatedThumbnails}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            width={thumbnailDimensionsRef.current.get(rotation)?.width}
            height={thumbnailDimensionsRef.current.get(rotation)?.height}
          />
        </Flex>
      )}
      {kind === 'thumbnailsOnly' ? null : (
        <PDFContainer onClick={updatePage} $showMobile={showMobile} data-test-tag="pdf-container">
          <Flex height="100%" grow={1} overflowY="auto">
            <Spacer size={8} />
            <CanvasContainer ref={canvasContainerRef} tabIndex={0}>
              <Spacer size={2} />
              {loading && (
                <Flex direction="column" align="center" justify="center" height="100%">
                  <Text variant="h2">This awesome PDF will be ready in just a moment</Text>
                  <Spacer size={8} />
                  <Loading />
                </Flex>
              )}
              {errored && (
                <Text variant="h1">Failed to load this reading, please talk your instructor</Text>
              )}
              <canvas
                onContextMenu={(e) => e.preventDefault()}
                ref={canvasRef}
                width={100}
                height={window.innerHeight}
                data-test-tag="main-pdf-canvas"
              />
              <Spacer size={drawerOpen ? 75 : 32} />
            </CanvasContainer>
          </Flex>
        </PDFContainer>
      )}
    </Flex>
  );
};

export const PdfJsWrapper = React.memo(UnmemoizedPdfJsWrapper);
