import { useCallback } from 'react';
import { Button, Flex, Loading, Spacer, TextSettings, PremiumTooltip } from 'src/components';
import { useStore } from 'src/Store';
import { ReadingStudioLayoutTopBar } from 'src/layouts';
import { useReadingTaskSubmissionDetails } from './SubmissionOverviewHelpers';
import { useState } from 'react';
import { useQuery } from 'src/modules/Router';
import { ENTERPRISE_PLAN_LABEL, secondaryTitleText, usageCapFeatureEnabled } from 'src/models';
import { paddings } from 'src/styles';

export const SubmissionOverviewTopBar = () => {
  const query = useQuery();
  const { assessment, openCommentModal, comments, startAssignmentUpgrade } = useStore(
    ({ SubmissionOverviewData: s, UpgradeAssignmentModalData: ua }) => ({
      assessment: s.assessment,
      openCommentModal: s.openCommentModal,
      comments: s.comments,
      startAssignmentUpgrade: ua.startAssignmentUpgrade,
    }),
    [],
  );
  const currentUser = useStore((s) => s.AppData.currentUser, []);
  const currentPlan = useStore((s) => s.AppData.currentPlan, []);
  const [textSettingsOpened, setTextSettingsOpened] = useState(false);
  const readingTaskSubmissionDetails = useReadingTaskSubmissionDetails();
  const isStudent = currentUser?.accountType === 'student';
  const defaultBackPath = isStudent ? '/s' : `/t/assignments/${assessment?.id}`;
  const backPath = isStudent ? query.get('backPath') || '/s' : query.get('backPath');
  const usageCapsEnabled = currentPlan.isUsageCapsEnabled;
  const availableUltimateAssessments = currentUser?.ultimateAssessmentsAvailable ?? 0;
  const canGiveFeedback = usageCapFeatureEnabled(
    'isTeacherFeedbackEnabled',
    currentUser,
    assessment,
    false,
  );

  const startUpgrade = useCallback(
    () => startAssignmentUpgrade(assessment?.id ?? '', 'feedback'),
    [assessment?.id, startAssignmentUpgrade],
  );

  if (!readingTaskSubmissionDetails)
    return (
      <>
        <Spacer size={2} />
        <Loading flex flexHeight="auto" />
        <Spacer size={2} />
      </>
    );

  const { reading, coverImageFile, author, contentFile } = readingTaskSubmissionDetails || {};

  return (
    <ReadingStudioLayoutTopBar
      leftButton={
        <Button color="gray2" nav to={backPath || defaultBackPath} icon="backArrow">
          Back
        </Button>
      }
      thumbnailUrl={coverImageFile?.thumbnailUrl}
      readingTitle={reading?.name}
      authorName={secondaryTitleText(assessment, author)}
      rightButtons={
        <Flex align="center">
          {!isStudent && (
            <>
              <PremiumTooltip
                enabled={!canGiveFeedback}
                content={`Upgrade to ${ENTERPRISE_PLAN_LABEL} to provide your students individualized feedback on their assignments!`}
                linkLocation="contact"
                usageCapsEnabled={usageCapsEnabled}
                availableUltimateAssessments={availableUltimateAssessments}
                onClick={startUpgrade}
              >
                <Button
                  onClick={openCommentModal}
                  testTag="teacher-open-comments"
                  disabled={!canGiveFeedback}
                >
                  Student Feedback{comments.isEmpty() ? '' : ` (${comments.size})`}
                </Button>
              </PremiumTooltip>
              <Spacer horizontal size={2} />
            </>
          )}
          {!['PdfStoredFile', 'PdfByPageStoredFile'].includes(contentFile?.type || '') && (
            <Button
              icon="text"
              onClick={() => setTextSettingsOpened(true)}
              testTag="text-settings"
              displayType="iconOnly"
              width="36px"
              height="36px"
              color="gray2"
              iconColor="black"
              iconSize="24px"
              paddingOverride={paddings.none}
            />
          )}
          <TextSettings opened={textSettingsOpened} close={() => setTextSettingsOpened(false)} />
        </Flex>
      }
    />
  );
};
