import { ErrorMap, emptyErrorMap } from 'src/modules/Api';
import { SetField } from 'src/State';

export type ForgotPasswordState = {
  username: string;
  success: boolean;
  sentToTeacher: boolean;
  errors: ErrorMap;
  resent: boolean;
  submitting: boolean;
  submit: () => void;
  setField: SetField<ForgotPasswordState>;
  reset: () => void;
};

export const defaultState = {
  username: '',
  success: false,
  sentToTeacher: false,
  errors: emptyErrorMap(),
  submitting: false,
  resent: false,
};
