import styled from 'styled-components';
import { borderRadii, colors, standardMeasurements, zIndices } from 'src/styles';

export const NUM_SOUND_BARS_DESKTOP = 15;
export const NUM_SOUND_BARS_DESKTOP_SMALL = 12;
export const NUM_SOUND_BARS_MOBILE = 23;

const getNumBars = (displayType: 'desktop' | 'desktopSmall' | 'mobile') => {
  switch (displayType) {
    case 'desktop':
      return NUM_SOUND_BARS_DESKTOP;
    case 'desktopSmall':
      return NUM_SOUND_BARS_DESKTOP_SMALL;
    default:
      return NUM_SOUND_BARS_MOBILE;
  }
};

const VolumeTick = styled.div`
  width: ${standardMeasurements[1]};
  height: 15px;
  border-radius: ${borderRadii.rounded};
  z-index: ${zIndices.raised};
  margin-right: 4px;
`;

export const VolumeMeter = ({
  volumeLevel,
  displayType,
}: {
  volumeLevel: number;
  displayType: 'desktop' | 'desktopSmall' | 'mobile';
}) => {
  const numTicks = getNumBars(displayType);
  return (
    <>
      {Array.from({ length: numTicks }).map((_, i) => (
        <VolumeTick
          key={i}
          style={{
            background: volumeLevel > i * (100 / numTicks) ? colors.green.hex : colors.gray5.hex,
          }}
        />
      ))}
    </>
  );
};
