import { subDataCreator } from 'src/State';
import { defaultState, UserSearchResponse } from './UserSearchState';
import { updateQuery, queryMapToString, QueryMap, History } from 'src/modules/Router';
import { emptyErrorMap, ErrorMap } from '../../modules/Api';
import { ImmutableMap } from 'src/modules/Immutable';

export const createDefaultUserSearchData = subDataCreator(
  'UserSearchData',
  defaultState,
  ({ set, get, setField, fetchJson }) => {
    const onSuccessfulAction =
      (history: History, query: QueryMap) =>
      ({ success, errors }: { success: boolean; errors?: ErrorMap }) => {
        if (success) {
          get().submit(history, query, `/m/user-search${queryMapToString(query)}`);
        } else {
          set({
            generalError: errors ? '' : 'Something went wrong! Reach out to #engineering for help!',
            errors: errors ? ImmutableMap(errors) : emptyErrorMap(),
          });
        }
      };

    return {
      setField,
      load: ({ searchQuery, onSuccess }) => {
        set({ ...defaultState, searchQuery });
        onSuccess();
      },
      submit: (history, query, targetUrl) => {
        set({ loading: true });
        const { searchQuery } = get();
        fetchJson(`/api/users/admin_search?query=${searchQuery}`, {
          method: 'GET',
          onSuccess: ({ results }: UserSearchResponse) => {
            set({ ...defaultState, results, searchQuery });
            history.push(
              targetUrl ||
                `${history.location.pathname}${updateQuery(query, { query: searchQuery })}`,
            );
          },
        });
      },
      updateUser: (user, history, query, param, value) => {
        fetchJson(`/api/users/${user.id}/admin_update`, {
          method: 'PATCH',
          data: { [param]: value },
          onSuccess: onSuccessfulAction(history, query),
        });
      },
      updateUserSchool: (userSchool, role, history, query) => {
        fetchJson(`/api/user_schools/${userSchool.id}`, {
          method: 'PATCH',
          data: { role },
          onSuccess: onSuccessfulAction(history, query),
        });
      },
      closeTrialModal: (history, query) => () => {
        set({ trialEndDate: '', errors: emptyErrorMap() });
        history.push(`/m/user-search${queryMapToString(query)}`);
      },
      deleteUser: (user, history, query) => () => {
        fetchJson(`/api/users/${user.id}`, {
          method: 'DELETE',
          onSuccess: onSuccessfulAction(history, query),
        });
      },
      grantTrial: (user, history, query) => {
        const { trialEndDate } = get();

        if (!user || !trialEndDate) return;

        fetchJson(`/api/users/${user.id}/grant_trial`, {
          method: 'POST',
          data: { trialEndDate: new Date(trialEndDate) },
          onSuccess: onSuccessfulAction(history, query),
        });
      },
    };
  },
);
