import { default as InfoIcon } from 'src/icons/info.svg';
import { default as UserIcon } from 'src/icons/user.svg';
import { default as SettingsIcon } from 'src/icons/settings.svg';
import { default as GroupIcon } from 'src/icons/group.svg';
import { default as UsersIcon } from 'src/icons/users.svg';
import { default as ArrowDownIcon } from 'src/icons/arrow_down.svg';
import { default as ArrowUpIcon } from 'src/icons/arrow_up.svg';
import { default as SortDownIcon } from 'src/icons/sort_down.svg';
import { default as SortUpIcon } from 'src/icons/sort_up.svg';
import { default as ArrowRightIcon } from 'src/icons/arrow_right.svg';
import { default as ArrowLeftIcon } from 'src/icons/arrow_left.svg';
import { default as SelectArrowUpIcon } from 'src/icons/select_arrow_up.svg';
import { default as SelectArrowDownIcon } from 'src/icons/select_arrow_down.svg';
import { default as ChatBubbleIcon } from 'src/icons/chat_bubble.svg';
import { default as HeadphonesIcon } from 'src/icons/headphones.svg';
import { default as MicrophoneIcon } from 'src/icons/microphone.svg';
import { default as TaskIcon } from 'src/icons/task.svg';
import { default as StopIcon } from 'src/icons/stop.svg';
import { default as MenuIcon } from 'src/icons/menu.svg';
import { default as PlayIcon } from 'src/icons/play.svg';
import { default as SpeakerIcon } from 'src/icons/speaker.svg';
import { default as BackFiveSecondsIcon } from 'src/icons/back_five_seconds.svg';
import { default as ForwardFiveSecondsIcon } from 'src/icons/forward_five_seconds.svg';
import { default as ThreeVerticalDotsIcon } from 'src/icons/three_vertical_dots.svg';
import { default as ThreeHorizontalDotsIcon } from 'src/icons/three_horizontal_dots.svg';
import { default as PauseIcon } from 'src/icons/pause.svg';
import { default as TextIcon } from 'src/icons/text.svg';
import { default as BackArrowIcon } from 'src/icons/back_arrow.svg';
import { default as ForwardArrowIcon } from 'src/icons/forward_arrow.svg';
import { default as BookIcon } from 'src/icons/book.svg';
import { default as CopyIcon } from 'src/icons/copy.svg';
import { default as CheckIcon } from 'src/icons/check.svg';
import { default as CheckboxCheckedIcon } from 'src/icons/checkbox_checked.svg';
import { default as CheckboxUncheckedIcon } from 'src/icons/checkbox_unchecked.svg';
import { default as CloseIcon } from 'src/icons/close.svg';
import { default as SyncIcon } from 'src/icons/sync.svg';
import { default as EyeIcon } from 'src/icons/eye.svg';
import { default as PictureIcon } from 'src/icons/picture.svg';
import { default as CheckmarkIcon } from 'src/icons/checkmark.svg';
import { default as PlusIcon } from 'src/icons/plus.svg';
import { default as PdfIcon } from 'src/icons/pdf.svg';
import { default as AddIcon } from 'src/icons/add.svg';
import { default as HourglassIcon } from 'src/icons/hourglass.svg';
import { default as SpeedometerIcon } from 'src/icons/speedometer.svg';
import { default as StarIcon } from 'src/icons/star.svg';
import { default as TypedTextIcon } from 'src/icons/typedText.svg';
import { default as UploadIcon } from 'src/icons/upload.svg';
import { default as TrashIcon } from 'src/icons/trash.svg';
import { default as PencilIcon } from 'src/icons/pencil.svg';
import { default as EditIcon } from 'src/icons/edit.svg';
import { default as PreviewIcon } from 'src/icons/preview.svg';
import { default as CalendarIcon } from 'src/icons/calendar.svg';
import { default as LinkIcon } from 'src/icons/link.svg';
import { default as FilterIcon } from 'src/icons/filter.svg';
import { default as SearchIcon } from 'src/icons/search.svg';
import { default as FlagIcon } from 'src/icons/flag.svg';
import { default as DownloadIcon } from 'src/icons/download.svg';
import { default as VisibilityHiddenIcon } from 'src/icons/visibility_hidden.svg';
import { default as VisibilityVisibleIcon } from 'src/icons/visibility_visible.svg';
import { default as ModelReadingIcon } from 'src/icons/model_reading.svg';
import { default as ExpandIcon } from 'src/icons/expand.svg';
import { default as CollapseIcon } from 'src/icons/collapse.svg';
import { default as IncreaseIcon } from 'src/icons/ic_up.svg';
import { default as DecreaseIcon } from 'src/icons/ic_down.svg';
import { default as VocabIcon } from 'src/icons/vocab.svg';
import { default as FolderIcon } from 'src/icons/folder.svg';
import { default as AddUserIcon } from 'src/icons/add_user.svg';
import { default as GraduationCapIcon } from 'src/icons/graduation_cap.svg';
import { default as StickerIcon } from 'src/icons/sticker.svg';
import { default as FeedbackGivenIcon } from 'src/icons/feedback_given.svg';
import { default as FeedbackViewedIcon } from 'src/icons/feedback_viewed.svg';
import { default as EditAvatarIcon } from 'src/icons/edit_avatar.svg';
import { default as DiceIcon } from 'src/icons/dice.svg';
import { default as UltimateAssignmentIcon } from 'src/icons/ultimate_assignment.svg';
import { default as UltimateAssignmentCenteredIcon } from 'src/icons/ultimate_assignment_centered.svg';
import { default as TokenIcon } from 'src/icons/token.svg';
import { default as ZoomInIcon } from 'src/icons/zoom_in.svg';
import { default as ZoomOutIcon } from 'src/icons/zoom_out.svg';
import { default as RotateIcon } from 'src/icons/rotate.svg';
import { default as PaperLogoIcon } from 'src/icons/paper_logo.svg';
import { default as SaveIcon } from 'src/icons/save.svg';
import { default as RedoIcon } from 'src/icons/redo.svg';
import { default as RecordingStatusIcon } from 'src/icons/recording_status.svg';
import { default as CheckmarkCircleIcon } from 'src/icons/checkmark_circle.svg';
import { default as PlaybackSpeedIcon } from 'src/icons/playback_speed.svg';
import { default as ResyncIcon } from 'src/icons/resync.svg';
import { default as QuestionIcon } from 'src/icons/question.svg';
import { colors, Color, CssColor } from 'src/styles';

export const iconMap = {
  info: InfoIcon,
  microphone: MicrophoneIcon,
  user: UserIcon,
  settings: SettingsIcon,
  group: GroupIcon,
  users: UsersIcon,
  arrowDown: ArrowDownIcon,
  arrowUp: ArrowUpIcon,
  sortDown: SortDownIcon,
  sortUp: SortUpIcon,
  arrowRight: ArrowRightIcon,
  arrowLeft: ArrowLeftIcon,
  menu: MenuIcon,
  selectArrowUp: SelectArrowUpIcon,
  selectArrowDown: SelectArrowDownIcon,
  chatBubble: ChatBubbleIcon,
  headphones: HeadphonesIcon,
  task: TaskIcon,
  play: PlayIcon,
  pause: PauseIcon,
  backFiveSeconds: BackFiveSecondsIcon,
  forwardFiveSeconds: ForwardFiveSecondsIcon,
  threeVerticalDots: ThreeVerticalDotsIcon,
  threeHorizontalDots: ThreeHorizontalDotsIcon,
  stop: StopIcon,
  text: TextIcon,
  backArrow: BackArrowIcon,
  forwardArrow: ForwardArrowIcon,
  book: BookIcon,
  copy: CopyIcon,
  check: CheckIcon,
  checkboxChecked: CheckboxCheckedIcon,
  checkboxUnchecked: CheckboxUncheckedIcon,
  close: CloseIcon,
  sync: SyncIcon,
  eye: EyeIcon,
  picture: PictureIcon,
  preview: PreviewIcon,
  visibilityHidden: VisibilityHiddenIcon,
  visibilityVisible: VisibilityVisibleIcon,
  checkmark: CheckmarkIcon,
  plus: PlusIcon,
  pdf: PdfIcon,
  add: AddIcon,
  hourglass: HourglassIcon,
  speedometer: SpeedometerIcon,
  star: StarIcon,
  typedText: TypedTextIcon,
  upload: UploadIcon,
  trash: TrashIcon,
  edit: EditIcon,
  pencil: PencilIcon,
  speaker: SpeakerIcon,
  calendar: CalendarIcon,
  link: LinkIcon,
  filter: FilterIcon,
  search: SearchIcon,
  flag: FlagIcon,
  download: DownloadIcon,
  modelReading: ModelReadingIcon,
  expand: ExpandIcon,
  collapse: CollapseIcon,
  increase: IncreaseIcon,
  decrease: DecreaseIcon,
  vocab: VocabIcon,
  folder: FolderIcon,
  graduationCap: GraduationCapIcon,
  sticker: StickerIcon,
  ultimateAssignment: UltimateAssignmentIcon,
  ultimateAssignmentCentered: UltimateAssignmentCenteredIcon,
  feedbackGiven: FeedbackGivenIcon,
  feedbackViewed: FeedbackViewedIcon,
  editAvatar: EditAvatarIcon,
  dice: DiceIcon,
  addUser: AddUserIcon,
  token: TokenIcon,
  zoomIn: ZoomInIcon,
  zoomOut: ZoomOutIcon,
  rotate: RotateIcon,
  paperLogo: PaperLogoIcon,
  save: SaveIcon,
  redo: RedoIcon,
  recordingStatus: RecordingStatusIcon,
  checkmarkCircle: CheckmarkCircleIcon,
  playbackSpeed: PlaybackSpeedIcon,
  resync: ResyncIcon,
  question: QuestionIcon,
};

export type IconSize = `${number}${'px' | 'em'}`;
const getColor = (color: Color | 'currentColor') =>
  color === 'currentColor' ? color : colors[color].hex;

export type IconType = keyof typeof iconMap;
type IconProps = {
  /** Icon to be rendered */
  icon: IconType;
  /** Width and height of the icon */
  size?: IconSize;
  /** Stroke color */
  color?: Color | 'currentColor';
  /** Direct hex code for the color */
  cssColor?: CssColor;
  /** Whether or not to fill the icon */
  fill?: boolean;
  /** Fill color */
  fillColor?: Color | 'currentColor';
  strokeWidth?: number;
  strokeLinecap?: 'butt' | 'round' | 'square';
  id?: string;
  flexShrink?: number;
  length?: string;
  testTag?: string;
};
export const Icon = ({
  icon,
  size = '1em',
  color = 'currentColor',
  cssColor,
  fill = false,
  fillColor = color,
  flexShrink,
  testTag,
  ...props
}: IconProps) => {
  const El = iconMap[icon];
  const actualColor = cssColor ?? getColor(color);
  const actualFillColor = fill ? cssColor || getColor(fillColor) : 'none';

  return (
    <El
      width={size}
      height={size}
      stroke={actualColor}
      fill={actualFillColor}
      style={{ flexShrink }}
      color={actualColor}
      data-test-tag={testTag}
      {...props}
    />
  );
};
