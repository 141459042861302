import { useMemo, useState } from 'react';
import {
  usageCapFeatureEnabled,
  ENTERPRISE_PLAN_LABEL_SHORT,
  ReadingTaskSubmissionDetails,
  User,
  ENTERPRISE_PLAN_LABEL,
} from 'src/models';
import { useStore } from 'src/Store';
import { Flex, Spacer, Text, LinkButton, Loading, PremiumTooltip, Icon } from 'src/components';
import { formatStat, formatNumber, statStyles } from 'src/modules/Stat';
import { formatDate } from 'src/modules/Time';
import { paddings } from 'src/styles';

type StatProps = {
  name: string;
  value: string;
  blur?: boolean;
};
const Stat = ({ name, value }: StatProps) => {
  return (
    <Flex direction="column">
      <Text variant="h5" color="white">
        {value}
      </Text>
      <Text variant="h7" color="gray5">
        {name}
      </Text>
    </Flex>
  );
};

const PremiumStat = ({ name, value, blur }: StatProps) => {
  const renderedValue = useMemo(
    () => (blur ? Math.round(Math.random() * 10 ** String(value).length) : value),
    [blur, value],
  );
  return (
    <>
      <Flex>
        <Text variant="h6" color="gray5">
          {name}:
        </Text>
        <Spacer horizontal />
        <Text variant="h6" color="white" blurrify={blur}>
          {renderedValue}
        </Text>
      </Flex>
      <Spacer />
    </>
  );
};

type StatsListProps = { readingTaskSubmissionDetails: ReadingTaskSubmissionDetails };
const FreeStats = ({
  readingTaskSubmissionDetails,
  lastSubmitted,
}: StatsListProps & { lastSubmitted: string }) => {
  const { taskSubmission, task } = readingTaskSubmissionDetails;
  return (
    <Flex>
      <Flex direction="column">
        <Stat
          name="Total Time Read"
          value={formatStat(statStyles.time_taken, taskSubmission.totalTime)}
        />
        <Spacer size={2} />
        <Stat name="Last Submitted" value={formatDate(new Date(lastSubmitted))} />
        <Spacer size={2} />
        <Stat name="Total Words" value={formatNumber(taskSubmission.wordSaidCount)} />
      </Flex>
      <Spacer horizontal size={3} />
      <Flex direction="column">
        {task.readingPartType === 'independent' ? (
          <Stat
            name="Speed (WPM)"
            value={formatStat(statStyles.wcpm, taskSubmission.wordsPerMinute)}
          />
        ) : (
          <>
            <Stat
              name="Completion"
              value={`${formatStat(statStyles.completion, taskSubmission.simpleCompletion)}`}
            />
            <Spacer size={2} />
            <Stat
              name="Speed (WCPM)"
              value={formatStat(statStyles.wcpm, taskSubmission.wordsCorrectPerMinute)}
            />
          </>
        )}
        <Spacer size={2} />
        <Stat name="Unique Words Read" value={formatNumber(taskSubmission.uniqueWordSaidCount)} />
      </Flex>
    </Flex>
  );
};

const PremiumStats = ({ readingTaskSubmissionDetails }: StatsListProps) => {
  const { currentUser, currentPlan, assessment, startAssignmentUpgrade } = useStore(
    (s) => ({
      currentUser: s.AppData.currentUser,
      currentPlan: s.AppData.currentPlan,
      assessment: s.SubmissionOverviewData.assessment,
      startAssignmentUpgrade: s.UpgradeAssignmentModalData.startAssignmentUpgrade,
    }),
    [],
  );
  const [showAdvancedStats, setShowAdvancedStats] = useState(false);
  const { taskSubmission, task } = readingTaskSubmissionDetails;
  const isAdvancedAnalyticsEnabled = usageCapFeatureEnabled(
    'isAdvancedAnalyticsEnabled',
    currentUser,
    assessment,
    false,
  );
  const blur = !isAdvancedAnalyticsEnabled;
  const usageCapsEnabled = currentPlan.isUsageCapsEnabled;
  const availableUltimateAssessments = currentUser?.ultimateAssessmentsAvailable ?? 0;

  return (
    <>
      <div>
        <Spacer size={4} />
        <LinkButton
          color="white"
          onClick={() => setShowAdvancedStats(!showAdvancedStats)}
          paddingOverride={paddings.none}
        >
          ADVANCED STATS
          {!isAdvancedAnalyticsEnabled ? ` - ${ENTERPRISE_PLAN_LABEL_SHORT.toUpperCase()}` : ''}
          <Spacer horizontal size={2} />
          <Icon icon={showAdvancedStats ? 'selectArrowUp' : 'selectArrowDown'} size="1em" />
        </LinkButton>
      </div>
      {showAdvancedStats && (
        <>
          <Spacer />
          <PremiumTooltip
            content={`Upgrade to ${ENTERPRISE_PLAN_LABEL} to unlock advanced reading data to drive academic outcomes`}
            linkLocation="contact"
            enabled={!isAdvancedAnalyticsEnabled}
            usageCapsEnabled={usageCapsEnabled}
            availableUltimateAssessments={availableUltimateAssessments}
            onClick={() => startAssignmentUpgrade(assessment?.id ?? '', 'advancedStats')}
          >
            <PremiumStat
              name="Reading Time"
              value={formatStat(statStyles.time_taken, taskSubmission.readingTime)}
              blur={blur}
            />
            <PremiumStat
              name="Non-Reading Time"
              value={formatStat(statStyles.time_taken, taskSubmission.emptyTime)}
              blur={blur}
            />
            {task.readingPartType !== 'independent' && (
              <>
                <PremiumStat
                  name="Words Assigned"
                  value={formatNumber(taskSubmission.wordAssignedCount)}
                  blur={blur}
                />
                <PremiumStat
                  name="Unique Words Assigned"
                  value={formatNumber(taskSubmission.uniqueWordAssignedCount)}
                  blur={blur}
                />
                <PremiumStat
                  name="Correct Words Read"
                  value={formatNumber(taskSubmission.correctWordSaidCount)}
                  blur={blur}
                />
                <PremiumStat
                  name="Words Added"
                  value={formatNumber(taskSubmission.addedWordSaidCount)}
                  blur={blur}
                />
                <PremiumStat
                  name="Words Missed"
                  value={formatNumber(taskSubmission.missedWordSaidCount)}
                  blur={blur}
                />
                <PremiumStat
                  name="Words Replaced"
                  value={formatNumber(taskSubmission.replacedWordSaidCount)}
                  blur={blur}
                />
                <PremiumStat
                  name="Speed (WPM)"
                  value={formatStat(statStyles.wcpm, taskSubmission.wordsPerMinute)}
                  blur={blur}
                />
                <PremiumStat
                  name="Accuracy"
                  value={`${formatStat(statStyles.completion, taskSubmission.correctCompletion)}`}
                  blur={blur}
                />
              </>
            )}
          </PremiumTooltip>
        </>
      )}
    </>
  );
};

type ReadingStudioStatsProps = {
  processing: boolean;
  user: User | null;
  readingTaskSubmissionDetails: ReadingTaskSubmissionDetails | null;
  hasPremiumStats?: boolean;
  lastSubmitted: string;
  isMobileView?: boolean;
};
export const ReadingStudioStats = ({
  processing,
  user,
  readingTaskSubmissionDetails,
  hasPremiumStats = false,
  lastSubmitted,
  isMobileView = false,
}: ReadingStudioStatsProps) => {
  return (
    readingTaskSubmissionDetails &&
    user && (
      <Flex direction="column">
        {processing ? (
          <>
            <Text variant="largeText" textAlign="center" color="white">
              This recording is being processed, please wait...
            </Text>
            <Spacer size={3} />
            <Loading flex />
          </>
        ) : (
          <>
            <Text
              variant="h6"
              textTransform="uppercase"
              color="white"
              textAlign={isMobileView ? 'center' : 'left'}
            >
              My Reading Stats
            </Text>
            <Spacer />
            <FreeStats
              lastSubmitted={lastSubmitted}
              readingTaskSubmissionDetails={readingTaskSubmissionDetails}
            />

            {hasPremiumStats && (
              <PremiumStats readingTaskSubmissionDetails={readingTaskSubmissionDetails} />
            )}
          </>
        )}
      </Flex>
    )
  );
};
