import {
  NoStylesButton,
  Flex,
  Text,
  CheckboxInput,
  useToaster,
  Tooltip,
  DropdownMenu,
  Icon,
  Spacer,
} from 'src/components';
import { useStore } from 'src/Store';
import { margins, paddings } from 'src/styles';
import { useCommonStateValues, useCompactLayout } from './AssignToModalHelpers';

export const StudentSelector = () => {
  const {
    currRegistrationAssessments,
    updateCheckbox,
    updateCourseAssessment,
    updateVisibilitySettings,
  } = useStore((s) => s.AssignToData, []);
  const { createToast } = useToaster();

  const { hasCourseAssessment } = useCommonStateValues();
  const showCompactLayout = useCompactLayout();
  if (!currRegistrationAssessments) return null;

  return (
    <Flex
      direction="column"
      align="center"
      overflowY="auto"
      height="100%"
      margin={`0 0 ${margins[5]} 0`}
    >
      <Flex
        width="100%"
        justify="space-between"
        padding={`${paddings[2]} ${paddings.none}`}
        key="assign-new"
      >
        <NoStylesButton onClick={updateCourseAssessment(createToast)}>
          <Tooltip content="When checked, Students will be assigned this content when they join your class">
            <Text variant="h5" color="success">
              Assign New Students
            </Text>
          </Tooltip>
        </NoStylesButton>
        <CheckboxInput
          onChange={updateCourseAssessment(createToast)}
          value={hasCourseAssessment}
          name="assign-new"
          testTag="assign-courseAssessment-checkbox"
        />
      </Flex>
      {currRegistrationAssessments.map((data) => (
        <Flex
          width="100%"
          justify="space-between"
          padding={`${paddings[2]} ${paddings.none}`}
          key={data.student.id}
          testTag={`student-${data.student.id}`}
        >
          <NoStylesButton onClick={updateCheckbox(data, createToast)}>
            <Text variant="h5">{data.student.displayName}</Text>
          </NoStylesButton>
          <Flex>
            {data.assigned && (
              <Tooltip
                disabled={showCompactLayout}
                hideOnClick
                content={
                  data.submissionVisibility === 'hidden'
                    ? 'Student will not be able to see their transcription results after they submit their assignment.'
                    : 'Student will be able to view their transcription results after they submit.'
                }
              >
                <DropdownMenu
                  testTag="visibility-dropdown"
                  color="white"
                  displayType="noStyles"
                  rightAligned
                  noButtonPadding
                  options={[
                    {
                      onClick: updateVisibilitySettings(data, 'visible', createToast),
                      children: (
                        <Flex align="center">
                          <Icon icon="visibilityVisible" />
                          <Spacer horizontal />
                          <Text>Visible</Text>
                        </Flex>
                      ),
                    },
                    {
                      onClick: updateVisibilitySettings(data, 'hidden', createToast),
                      children: (
                        <Flex align="center">
                          <Icon icon="visibilityHidden" />
                          <Spacer horizontal />
                          <Text>Hidden</Text>
                        </Flex>
                      ),
                    },
                  ]}
                >
                  <Icon
                    size="1.25em"
                    icon={
                      data.submissionVisibility === 'hidden'
                        ? 'visibilityHidden'
                        : 'visibilityVisible'
                    }
                    testTag={`visibility-value-${data.submissionVisibility}`}
                  />
                </DropdownMenu>
              </Tooltip>
            )}

            <Spacer horizontal size={2} />
            <CheckboxInput
              onChange={updateCheckbox(data, createToast)}
              value={data.assigned}
              name={data.student.id}
              testTag={`assign-checkbox-${data.student.id}`}
            />
          </Flex>
        </Flex>
      ))}
    </Flex>
  );
};
