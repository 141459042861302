import { useCallback } from 'react';
import { Button, Spacer, StandardModal, useToaster, Text, FormGroup } from 'src/components';
import { useHistory } from 'src/modules/Router';
import { useStore } from 'src/Store';

export const DuplicateModal = () => {
  const { duplicatingName, submitting, duplicatingErrors, submitDuplicate, setField, opened } =
    useStore((state) => state.DuplicateModalData, []);
  const { createToast } = useToaster();
  const history = useHistory();
  const close = useCallback(() => setField('opened')(false), [setField]);

  if (!opened) return null;
  return (
    <StandardModal
      testTag="duplicate"
      openType="state"
      opened={opened}
      header="Duplicate Assignment"
      close={close}
      onSubmit={submitDuplicate(createToast, history)}
      footer={
        <>
          <Button onClick={close} displayType="link" color="primaryBlue">
            Cancel
          </Button>
          <Spacer horizontal size={3} />
          <Button loading={submitting} type="submit">
            Duplicate
          </Button>
        </>
      }
    >
      <Text>Create a copy of this assignment. Student submissions will not be copied.</Text>
      <Spacer size={2} />
      <FormGroup
        type="text"
        name="name"
        testTag="duplicate-input"
        label="New Assignment Name"
        errors={duplicatingErrors}
        value={duplicatingName}
        onChange={setField('duplicatingName')}
      />
    </StandardModal>
  );
};
