import { ImmutableMap } from 'src/modules/Immutable';
import { ReadingTaskSubmissionDetails, TaskSubmissionDetails, Assessment } from 'src/models';
import { useStore } from 'src/Store';

export const getReadingTaskSubmissionDetails = (
  assessment: Assessment | null,
  taskSubmissionDetailsMap: ImmutableMap<string, TaskSubmissionDetails>,
) => {
  if (!assessment) return null;

  const readingTaskData = assessment.taskOrder.find(
    ({ taskId, type }) => type === 'ReadingTask' && taskSubmissionDetailsMap.has(taskId),
  );
  if (!readingTaskData) return null;
  const readingTaskId = readingTaskData.taskId;
  const readingTaskSubmissionDetails = taskSubmissionDetailsMap.get(readingTaskId);
  if (!readingTaskSubmissionDetails || readingTaskSubmissionDetails.type !== 'ReadingTask')
    return null;

  return readingTaskSubmissionDetails;
};
export const useReadingTaskSubmissionDetails = (): ReadingTaskSubmissionDetails | null => {
  const { assessment, taskSubmissionDetailsMap } = useStore(
    ({ SubmissionOverviewData: s }) => ({
      assessment: s.assessment,
      taskSubmissionDetailsMap: s.taskSubmissionDetailsMap,
    }),
    [],
  );

  return getReadingTaskSubmissionDetails(assessment, taskSubmissionDetailsMap);
};

export const useCurrentTaskSubmissionDetails = (): TaskSubmissionDetails | null => {
  const { taskSubmissionDetailsMap, currentTaskId } = useStore(
    ({ SubmissionOverviewData: s }) => ({
      taskSubmissionDetailsMap: s.taskSubmissionDetailsMap,
      currentTaskId: s.currentTaskId,
    }),
    [],
  );

  const currentTaskSubmissionDetails = currentTaskId
    ? taskSubmissionDetailsMap.get(currentTaskId, null)
    : null;

  return currentTaskSubmissionDetails;
};
