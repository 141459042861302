import styled, { css } from 'styled-components';
import { Color, colors, RequiredDollarPrefix } from 'src/styles';

export type ArrowDirection = 'left' | 'right' | 'up' | 'down';

type ArrowProps = {
  direction: ArrowDirection;
  size: number;
  color: Color;
  position: ArrowDirection;
};

type BaseArrowProps = RequiredDollarPrefix<ArrowProps>;

const getPositionCss = (pos: ArrowDirection) => {
  if (pos === 'up') {
    return css`
      width: 24px;
      height: 12px;
      top: 100%;
      left: 50%;
      transform: translate(-50%, 0%);
      padding: 0 50px;
    `;
  } else if (pos === 'down') {
    return css`
      bottom: 100%;
      left: 50%;
      width: 24px;
      height: 12px;
      transform: translate(-50%, 0%);
      padding: 0 50px;
    `;
  } else if (pos === 'left') {
    return css`
      left: 100%;
      top: 50%;
      width: 12px;
      height: 24px;
      transform: translate(0, -50%);
      padding: 50px 0;
    `;
  } else {
    return css`
      right: 100%;
      top: 50%;
      width: 12px;
      height: 24px;
      transform: translate(0, -50%);
      padding: 50px 0;
    `;
  }
};
const getPseudoCss = (direction: ArrowDirection) => {
  if (direction === 'up') {
    return css`
      left: 50%;
      transform: translate(-50%, 50%) rotate(45deg);
    `;
  } else if (direction === 'down') {
    return css`
      left: 50%;
      transform: translate(-50%, -50%) rotate(45deg);
    `;
  } else if (direction === 'left') {
    return css`
      left: 100%;
      top: 50%;
      transform: translate(-50%, -50%) rotate(-45deg);
    `;
  } else {
    return css`
      right: 100%;
      top: 50%;
      transform: translate(50%, -50%) rotate(-45deg);
    `;
  }
};

const opposites: Record<ArrowDirection, ArrowDirection> = {
  up: 'down',
  down: 'up',
  left: 'right',
  right: 'left',
};

export const oppositeDirection = (pos: ArrowDirection): ArrowDirection => {
  return opposites[pos];
};

export const BaseArrow = styled.div<BaseArrowProps>`
  position: absolute;
  overflow: hidden;
  ${({ $position }) => getPositionCss($position)}

  &::after {
    content: '';
    position: absolute;
    width: 12px;
    height: 12px;
    background-color: ${colors.white.hex};
    box-shadow: 0 0 8px -2px rgb(0 0 0 / 50%);
    border-radius: 2px;

    ${({ $direction }) => getPseudoCss($direction)}
  }
`;

export const Arrow = ({ direction, size, color, position }: ArrowProps) => {
  return <BaseArrow $direction={direction} $size={size} $color={color} $position={position} />;
};
