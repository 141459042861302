import { useMemo, useCallback } from 'react';
import { useStore } from 'src/Store';
import { Text, SelectInput, Tooltip, LinkButton, Icon, Flex, Spacer } from 'src/components';
import { basicColors } from 'src/styles';
import { ImmutableList } from 'src/modules/Immutable';
import { Season } from 'src/modules/Time';
import * as Storage from 'src/modules/Storage';
import { StudentProgressMonitoringChart } from './StudentProgressMonitoringChart';
import {
  GraphContainer,
  GraphLabels,
  getRange,
  getRangeText,
  seasonEndDates,
  ChartLabel,
} from './StudentProgressChartComponents';
import {
  useDateFilter,
  labels,
  isBaseTooltipData,
  objHasPropOfType,
  getGraphData,
  externalTooltipFunc,
  useGraphOrigin,
  mapToDataArray,
  useSubmissionResponses,
  aggregateAssignments,
} from './StudentProgressChartHelpers';
import { TooltipSubmissionData } from './StudentProgressMonitoringState';

const isTooltipSubmissionData = (obj: unknown): obj is TooltipSubmissionData =>
  isBaseTooltipData(obj) && objHasPropOfType(obj, 'wcpm', 'number');

const transparent = 'rgba(0,0,0,0)';
const gray5 = 'rgba(0,0,0,.2)';
const standardsOptions = {
  borderColor: gray5,
  backgroundColor: gray5,
  pointBackgroundColor: transparent,
  pointBorderColor: transparent,
  borderDash: [6, 6],
};

const flatLine = (labels: string[], wcpm: number) => labels.map((l) => ({ x: l, y: wcpm }));
const getStandards = (gradeLevel: number, labels: string[], season: Season) => {
  const range = getRange(gradeLevel, season);

  const datasets = [
    {
      ...standardsOptions,
      label: 'min',
      id: 1,
      data: flatLine(labels, range.min),
      fill: false,
    },
    {
      ...standardsOptions,
      label: 'max',
      id: 2,
      data: 'max' in range ? flatLine(labels, range.max) : [],
      fill: {
        target: '-1',
        above: gray5,
        below: transparent,
      },
    },
  ];
  return datasets;
};

const SeasonSelector = () => {
  const { season, setField, user } = useStore(
    ({ StudentProgressMonitoringData: s }) => ({
      season: s.season,
      setField: s.setField,
      user: s.user,
    }),
    [],
  );

  const onChange = useCallback(
    (v: Season) => {
      if (user) Storage.setItem(`progress-monitoring-season-${user.id}`, v.toString());
      setField('season')(v);
    },
    [setField, user],
  );

  return (
    <>
      {' '}
      <Text display="inline">by</Text>{' '}
      <SelectInput
        id="selectSeason"
        name="Select Season"
        displayType="flat"
        display="inline"
        value={season}
        onChange={onChange}
        options={ImmutableList<{ label: string; value: Season }>(
          Object.entries(seasonEndDates).map(
            ([key, value]) =>
              ({
                label: value,
                value: key,
              } as { label: string; value: Season }),
          ),
        )}
      />
    </>
  );
};

const gradeLevelOptions = ImmutableList([
  { label: '1st Grade', value: 1 },
  { label: '2nd Grade', value: 2 },
  { label: '3rd Grade', value: 3 },
  { label: '4th Grade', value: 4 },
  { label: '5th Grade', value: 5 },
  { label: '6th Grade+', value: 6 },
]);

const StandardSelector = () => {
  const { gradeLevel, season, setField, user } = useStore(
    ({ StudentProgressMonitoringData: s }) => ({
      gradeLevel: s.gradeLevel,
      season: s.season,
      user: s.user,
      setField: s.setField,
    }),
    [],
  );

  const onChange = useCallback(
    (v: number) => {
      if (user) Storage.setItem(`progress-monitoring-gradeLevel-${user.id}`, v.toString());
      setField('gradeLevel')(v);
    },
    [setField, user],
  );

  return (
    <Flex align="center">
      <Text display="inline">The national benchmark for</Text>
      <Spacer horizontal />
      <SelectInput
        id="selectGrade"
        name="Select Grade Standards"
        display="inline"
        displayType="flat"
        value={gradeLevel}
        onChange={onChange}
        options={gradeLevelOptions}
      />
      <Spacer horizontal />
      <Text display="inline">is {getRangeText(gradeLevel, season)} WCPM</Text>
      <Spacer horizontal />
      {gradeLevel < 6 && <SeasonSelector />}
      <Tooltip
        display="flex"
        lineHeight="140%"
        content={
          <LinkButton
            display="inline"
            newTab
            href="https://readleesupport.zendesk.com/hc/en-us/articles/14128804631693"
          >
            Learn more about this data
          </LinkButton>
        }
      >
        <Icon icon="info" strokeWidth={2} />
      </Tooltip>
    </Flex>
  );
};

export const StudentWcpmGraph = () => {
  const { assessmentMap, gradeLevel, season, setWcpmTooltipData, setWcpmTooltipProps } = useStore(
    ({ StudentProgressMonitoringData: s }) => ({
      assessmentMap: s.assessmentMap,
      gradeLevel: s.gradeLevel,
      season: s.season,
      setWcpmTooltipData: s.setWcpmTooltipData,
      setWcpmTooltipProps: s.setWcpmTooltipProps,
    }),
    [],
  );
  const { dateOption } = useDateFilter();
  const submissionResponses = useSubmissionResponses();

  const wcpmData = useMemo(
    () =>
      submissionResponses
        .map((group) => ({
          wcpm: Math.round(
            group.reduce((acc, { submission: s }) => acc + s.wcpm, 0) / group.count(),
          ),
          assignments: aggregateAssignments(group, assessmentMap, (sr) => ({
            wcpm: Math.round(sr.submission.wcpm),
          })).filter((r) => r.contentType !== 'independent'),
        }))
        .filter((item) => item.assignments.count() > 0),
    [submissionResponses, assessmentMap],
  );
  const origin = useGraphOrigin({ excludeIndependent: true });
  const graphData = useMemo(() => getGraphData(wcpmData, dateOption), [wcpmData, dateOption]);
  const standards = useMemo(() => getRange(gradeLevel, season), [gradeLevel, season]);
  const standardsGraph = useMemo(
    () => getStandards(gradeLevel, labels(origin), season),
    [gradeLevel, origin, season],
  );

  const data = useMemo(
    () => ({
      labels: labels(origin),
      datasets: [
        ...standardsGraph,
        {
          label: '(WCPM)',
          id: 3,
          data: mapToDataArray(graphData.map((v) => v.wcpm)),
          borderColor: basicColors.readleeBlue,
          backgroundColor: basicColors.readleeBlue,
          fill: false,
        },
      ],
    }),
    [origin, standardsGraph, graphData],
  );

  const maxValue = useMemo(
    () =>
      Math.max(
        (graphData.maxBy((a) => a.wcpm)?.wcpm ?? 0) + 10,
        ('max' in standards ? standards.max : standards.min) + 10,
      ),
    [graphData, standards],
  );

  const tooltipHandler = useMemo(
    () =>
      externalTooltipFunc(setWcpmTooltipProps, (raw, datasetIdx) => {
        const items =
          datasetIdx === standardsGraph.length
            ? graphData
                .get(raw.x)
                ?.assignments.filter(isTooltipSubmissionData)
                .map((el) => ({ ...el, value: el.wcpm, stat: 'wcpm' as const })) ??
              ImmutableList<TooltipSubmissionData>()
            : ImmutableList<TooltipSubmissionData>();
        setWcpmTooltipData(items);
      }),
    [graphData, standardsGraph, setWcpmTooltipProps, setWcpmTooltipData],
  );

  return (
    <GraphContainer>
      <GraphLabels
        label={
          <ChartLabel
            label="WCPM"
            tooltip="Words Correct Per Minute (WCPM, Total Words Correct / Total Time Read) is shown for all assessments with an assigned reading."
            testTag="wcpm"
          />
        }
        stat="wcpm"
      />
      <StudentProgressMonitoringChart
        datasetIdKey="wcpm"
        data={data}
        maxValue={maxValue}
        tooltipPropsHandler={tooltipHandler}
      />
      <StandardSelector />
    </GraphContainer>
  );
};
