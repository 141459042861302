import { useCallback, useEffect } from 'react';
import { useStore } from 'src/Store';
import { useQuery, useHistory, queryMapToString } from 'src/modules/Router';
import { EmptyState, Flex, Loading, Spacer, StandardModal } from 'src/components';
import { ellipsify } from 'src/modules/String';
import { RightContent } from './RightContent';
import { CourseSelector } from './CourseSelector';

const MAX_TITLE_LENGTH = 40;

type AssignToModalProps = {
  closedPath: string;
  openedPath: string;
  preserveQuery?: boolean;
  onClose?: () => void;
};

export const AssignToModal = ({
  closedPath,
  openedPath,
  preserveQuery = false,
  onClose,
}: AssignToModalProps) => {
  const {
    loading,
    isSyncing,
    title,
    courses,
    load,
    setField,
    isPaperUser,
    paperRosterAutoSyncCount,
    paperRosterManualSyncCount,
  } = useStore(
    (s) => ({
      loading: s.AssignToData.loading,
      title: s.AssignToData.title,
      courses: s.AssignToData.courses,
      load: s.AssignToData.load,
      setField: s.AssignToData.setField,
      isSyncing: s.PaperSyncData.getIsSyncing(),
      isPaperUser: Boolean(s.AppData.currentUser?.paperSyncedAt),
      paperRosterAutoSyncCount: s.AppData.currentUser?.paperRosterAutoSyncCount || 0,
      paperRosterManualSyncCount: s.AppData.currentUser?.paperRosterManualSyncCount || 0,
    }),
    [],
  );
  const query = useQuery();
  const assessmentId = query.get('assessmentId');
  const courseId = query.get('selectedCourseId') || '';
  useEffect(() => {
    assessmentId && load({ assessmentId, courseId });
  }, [load, assessmentId, courseId]);

  const history = useHistory();
  const closeModal = useCallback(() => {
    setField('selectedCourse')(null);
    onClose?.();
    history.replace(`${closedPath}${preserveQuery ? queryMapToString(query) : ''}`);
  }, [closedPath, history, preserveQuery, query, onClose, setField]);

  const syncCount = paperRosterAutoSyncCount + paperRosterManualSyncCount;
  const getPaperEmptyState = () => {
    if (isSyncing) return 'classesPaperSyncing';
    return syncCount > 0 ? 'noClassesPaper' : 'classesPaper';
  };

  const modalContent = (
    <>
      {isPaperUser && courses.isEmpty() && (
        <Flex align="center" justify="center">
          <EmptyState kind={getPaperEmptyState()} />
        </Flex>
      )}

      {!isPaperUser && courses.isEmpty() && (
        <Flex align="center" justify="center">
          <EmptyState kind="assignToClasses" />
        </Flex>
      )}

      {!courses.isEmpty() && (
        <Flex minHeight="300px" height="100%">
          <CourseSelector />
          <Spacer horizontal size={4} />
          <RightContent />
        </Flex>
      )}
    </>
  );

  return (
    <StandardModal
      header={`Assign "${ellipsify(title, MAX_TITLE_LENGTH)}" to a selected class`}
      openedPath={openedPath}
      close={closeModal}
      openType="path"
      testTag="assign-to-modal"
    >
      {loading ? <Loading flex /> : modalContent}
    </StandardModal>
  );
};
