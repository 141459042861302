import { AppState } from 'src/AppState';
import { JoinCourseState } from 'src/components/JoinCourse/JoinCourseState';
import { DictionaryState } from 'src/components/Dictionary/DictionaryState';
import { ForgotPasswordState } from 'src/pages/ForgotPassword/ForgotPasswordState';
import { LoginState } from 'src/pages/Login/LoginState';
import { ResetPasswordState } from 'src/pages/ResetPassword/ResetPasswordState';
import { ReadingStudioState } from 'src/pages/ReadingStudio/ReadingStudioState';
import { SignUpClassCodeState } from 'src/pages/SignUpClassCode/SignUpClassCodeState';
import { SignUpSchoolCodeState } from 'src/pages/SignUpSchoolCode/SignUpSchoolCodeState';
import { SignUpRegisterState } from 'src/pages/SignUpRegister/SignUpRegisterState';
import { SignUpConfirmationSentState } from 'src/pages/SignUpConfirmationSent/SignUpConfirmationSentState';
import { TeacherCourseListState } from 'src/pages/TeacherCourseList/TeacherCourseListState';
import { CreateCourseState } from 'src/pages/CreateCourseModal/CreateCourseState';
import { ImportGoogleCourseState } from 'src/pages/ImportGoogleCourseModal/ImportGoogleCourseState';
import { ImportCleverCourseState } from 'src/pages/ImportCleverCourseModal/ImportCleverCourseState';
import { TeacherStudentListState } from 'src/pages/TeacherStudentList/TeacherStudentListState';
import { StudentDashboardState } from 'src/pages/StudentDashboard/StudentDashboardState';
import { TeacherAssignmentListState } from 'src/pages/TeacherAssignmentList/TeacherAssignmentListState';
import { TeacherAssignmentOverviewState } from 'src/pages/TeacherAssignmentOverview/TeacherAssignmentOverviewState';
import { SubmissionOverviewState } from 'src/pages/SubmissionOverview/SubmissionOverviewState';
import { UserSearchState } from 'src/pages/UserSearch/UserSearchState';
import { ProgressMonitoringState } from 'src/pages/ProgressMonitoring/ProgressMonitoringState';
import { StudentProgressMonitoringState } from 'src/pages/StudentProgressMonitoring/StudentProgressMonitoringState';
import { AssignToState } from 'src/pages/AssignToModal/AssignToState';
import { AssignmentBuilderState } from 'src/pages/AssignmentBuilder/AssignmentBuilderState';
import { ReadingBuilderState } from 'src/pages/ReadingBuilder/ReadingBuilderState';
import { TeacherOnboardingState } from 'src/pages/TeacherOnboarding/TeacherOnboardingState';
import { TaskOverviewState } from 'src/pages/TaskOverview/TaskOverviewState';
import { PlanListState } from 'src/pages/PlanList/PlanListState';
import { UpdateAssignmentStatusModalState } from 'src/pages/UpdateAssignmentStatusModal/UpdateAssignmentStatusModalState';
import { ContentLibraryState } from 'src/pages/ContentLibrary/ContentLibraryState';
import { ShareModalState } from 'src/pages/ShareModal/ShareModalState';
import { DuplicateModalState } from 'src/pages/DuplicateModal/DuplicateModalState';
import { MobileGateState } from 'src/components/MobileGate/MobileGateState';
import { ChangePasswordModalState } from 'src/pages/UserProfile/ChangePasswordModal/ChangePasswordModalState';
import { UpgradeAssignmentModalState } from 'src/components/UpgradeAssignmentModal/UpgradeAssignmentModalState';
import { AvatarBuilderState } from 'src/pages/AvatarBuilder/AvatarBuilderState';
import { AvatarGalleryState } from 'src/pages/AvatarGallery/AvatarGalleryState';
import { PlaylistManagerState } from 'src/pages/PlaylistManager/PlaylistManagerState';
import { PaperSyncState } from 'src/components/PaperSync/PaperSyncState';
import { StoreApi } from 'zustand';
import { FetchState, FetchJson, FetchResponse } from 'src/modules/Api';
import { SchoolSearchState } from './pages/SchoolSearch/SchoolSearchState';

export type State = {
  AppData: AppState;
  ForgotPasswordData: ForgotPasswordState;
  LoginData: LoginState;
  ResetPasswordData: ResetPasswordState;
  ReadingStudioData: ReadingStudioState;
  SignUpClassCodeData: SignUpClassCodeState;
  SignUpSchoolCodeData: SignUpSchoolCodeState;
  SignUpRegisterData: SignUpRegisterState;
  SignUpConfirmationSentData: SignUpConfirmationSentState;
  TeacherCourseListData: TeacherCourseListState;
  CreateCourseData: CreateCourseState;
  ImportGoogleCourseData: ImportGoogleCourseState;
  ImportCleverCourseData: ImportCleverCourseState;
  TeacherStudentListData: TeacherStudentListState;
  JoinCourseData: JoinCourseState;
  StudentDashboardData: StudentDashboardState;
  TeacherAssignmentListData: TeacherAssignmentListState;
  TeacherAssignmentOverviewData: TeacherAssignmentOverviewState;
  SubmissionOverviewData: SubmissionOverviewState;
  AssignToData: AssignToState;
  AssignmentBuilderData: AssignmentBuilderState;
  ReadingBuilderData: ReadingBuilderState;
  UserSearchData: UserSearchState;
  DictionaryData: DictionaryState;
  TeacherOnboardingData: TeacherOnboardingState;
  ProgressMonitoringData: ProgressMonitoringState;
  StudentProgressMonitoringData: StudentProgressMonitoringState;
  PlanListData: PlanListState;
  TaskOverviewData: TaskOverviewState;
  UpdateAssignmentStatusModalData: UpdateAssignmentStatusModalState;
  ShareModalData: ShareModalState;
  DuplicateModalData: DuplicateModalState;
  ContentLibraryData: ContentLibraryState;
  MobileGateData: MobileGateState;
  ChangePasswordModalData: ChangePasswordModalState;
  UpgradeAssignmentModalData: UpgradeAssignmentModalState;
  AvatarBuilderData: AvatarBuilderState;
  AvatarGalleryData: AvatarGalleryState;
  SchoolSearchData: SchoolSearchState;
  PlaylistManagerData: PlaylistManagerState;
  PaperSyncData: PaperSyncState;
  FetchData: FetchState;
};

export type SetField<T> = <K extends keyof T>(key: K) => (value: T[K]) => void;
export type SetFieldWithFunction<T> = <K extends keyof T>(
  key: K,
) => (value: (oldValue: T[K]) => T[K]) => void;

const createSubSetAndGet = <DataKey extends keyof State>(
  dataKey: DataKey,
  {
    getFull,
    setFull,
  }: {
    getFull: StoreApi<State>['getState'];
    setFull: StoreApi<State>['setState'];
  },
) => {
  const get: StoreApi<State[DataKey]>['getState'] = () => getFull()[dataKey];
  const set: StoreApi<State[DataKey]>['setState'] = (properties, replace = false) => {
    // This any return type is safe-ish because of the typing of the set functions in our state files
    // and because it allows us type State as type instead of an interface so we don't need to extend Record<string,unknown>
    // Essentially it's more restrictive than it was, while still being imperfect
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setFull((state: State): any => {
      const updates = typeof properties === 'function' ? properties(state[dataKey]) : properties;
      return {
        [dataKey]: {
          ...(replace ? {} : state[dataKey]),
          ...updates,
        },
      };
    });
  };

  // Same as above
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const setField: SetField<State[DataKey]> = (key) => (value) => set({ [key]: value } as any);

  // We can't just make set field handle functions as well as direct values, because if the value we're trying
  // to set is actually a function, it will try to run it as the "set" function rather than directly set it
  const setFieldWithFunction: SetFieldWithFunction<State[DataKey]> = (key) => (value) =>
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    set({ [key]: value(get()[key]) } as any);
  const fetchData: FetchState = {
    fetchJson: (...args) => getFull().FetchData.fetchJson(...args),
    fetchResponse: (...args) => getFull().FetchData.fetchResponse(...args),
    handleApiError: (...args) => getFull().FetchData.handleApiError(...args),
    refreshPaperAccessToken: (...args) => getFull().FetchData.refreshPaperAccessToken(...args),
  };
  return {
    set,
    get,
    setField,
    setFieldWithFunction,
    ...fetchData,
  };
};

export const subDataCreator =
  <K extends keyof State, Default extends Partial<State[K]>>(
    key: K,
    defaultState: Default,
    create: (createArgs: {
      getFull: StoreApi<State>['getState'];
      setFull: StoreApi<State>['setState'];
      get: StoreApi<State[K]>['getState'];
      set: StoreApi<State[K]>['setState'];
      setField: SetField<State[K]>;
      setFieldWithFunction: SetFieldWithFunction<State[K]>;
      fetchJson: FetchJson;
      fetchResponse: FetchResponse;
      refreshPaperAccessToken: () => Promise<void>;
    }) => Omit<State[K], keyof Default>,
  ): ((_: {
    getFull: StoreApi<State>['getState'];
    setFull: StoreApi<State>['setState'];
  }) => Default & Omit<State[K], keyof Default>) =>
  ({ getFull, setFull }) => ({
    ...defaultState,
    ...create({ getFull, setFull, ...createSubSetAndGet(key, { getFull, setFull }) }),
  });
