type UpdatedRefreshTokenMessage = {
  message: 'webview_updated_refresh_token';
  refreshToken: string;
};

type RecordingPermissionRequestMessage = {
  message: 'webview_ask_recording_permission';
};

type WebviewMessage = UpdatedRefreshTokenMessage | RecordingPermissionRequestMessage;

export const emitWebviewMessage = (message: WebviewMessage) => {
  const isMobileWebviewOpened = window.localStorage.getItem('mobileWebviewOpened');
  if (isMobileWebviewOpened && window.ReactNativeWebView) {
    window.ReactNativeWebView.postMessage(JSON.stringify(message), '*');
  }
};
