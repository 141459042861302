import { ClassColor, ColorHexes } from 'src/styles';
import { BaseAvatar, BaseAvatarProps } from './BaseAvatar';

type InheritedProps = Omit<BaseAvatarProps, 'radiusType' | 'textColor' | 'backgroundColor'>;

type ClassAvatarProps = InheritedProps & {
  backgroundColor: ClassColor | ColorHexes;
};

export const ClassAvatar = (props: ClassAvatarProps) => {
  return <BaseAvatar {...props} radiusType="mostlyRound" textColor="white" />;
};
