import { Color } from 'src/styles';

export type SubmissionStatus = 'waiting' | 'started' | 'finished';

export const MAX_RATING = 5;

export type Submission = {
  id: string;
  status: SubmissionStatus;
  userId: string;
  duration: number;
  finishedAt: string;
  rating: number | null;
  updatedAt: string;
};

export type SubmissionExtended = Submission & {
  wcpm: number;
  wpm: number;
  totalWords: number;
  timeRead: number;
  percentRead: number;
};

export type SubmissionStatusDetails = {
  label: string;
  color: Color;
};

export const submissionStatusTagProps = (
  submission: SubmissionExtended | undefined,
  hasFeedback: boolean,
): SubmissionStatusDetails => {
  if (hasFeedback) {
    return { label: 'New Feedback', color: 'success' };
  }
  if (submission?.status === 'finished') {
    return { label: 'Submitted', color: 'primaryBlue' };
  }

  return { label: 'To Do', color: 'primaryBlue' };
};
