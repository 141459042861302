import { CreateToast } from 'src/components';

export type MobileGateState = {
  submit: (createToast: CreateToast) => () => void;
  sending: boolean;
};

export const defaultState = {
  sending: false,
};
