import { subDataCreator } from 'src/State';
import { defaultState } from './PlanListState';
import { Plan, Subscription, User } from 'src/models';
import { ImmutableList } from 'src/modules/Immutable';
import { CreateToast } from 'src/components';
import { History } from 'src/modules/Router';
import { formatDate, formatTime } from 'src/modules/Time';

type PlanListData = {
  currentUser: User;
  currentPlan: Plan | null;
  currentSubscription: Subscription | null;
  plans: ImmutableList<Plan>;
};
type DowngradeData = Omit<PlanListData, 'plans'>;
type CreateSessionData = {
  sessionUrl: string;
};

export const createDefaultPlanListData = subDataCreator(
  'PlanListData',
  defaultState,
  ({ get, set, setField, fetchJson }) => {
    const onPlanActivated = (createToast: CreateToast) => {
      createToast({ children: 'Payment successful! Your plan has been activated!' });
    };
    const loadWithoutReset = (createToast: CreateToast, history: History) => {
      fetchJson(`/api/plans`, {
        onSuccess: (data: PlanListData) => {
          set({ ...data, loading: false });
          const query = new URLSearchParams(window.location.search);
          if (query.get('payment-status') === 'success' && data.currentSubscription) {
            onPlanActivated(createToast);
            history.replace(`/t/plans`);
          } else if (query.get('payment-status') === 'cancelled') {
            createToast({
              children:
                'Payment cancelled, you have not been charged and your plan has not been changed.',
              color: 'warning',
            });
            history.replace(`/t/plans`);
          }
        },
      });
    };
    return {
      ...defaultState,
      setField,
      load: (createToast, history) => {
        set(defaultState);
        loadWithoutReset(createToast, history);
      },
      startDowngrade: (plan) => () => {
        set({
          downgradingPlan: plan,
        });
      },
      downgrade: (plan, createToast) => () => {
        const { currentPlan, currentSubscription } = get();
        if (!currentPlan || !currentSubscription) return;

        set({ creatingSession: true, downgradingPlan: null });

        fetchJson(`/api/plans/downgrade`, {
          method: 'POST',
          data: {
            planId: plan.id,
            paymentInterval: 'month',
          },
          onSuccess: (data: DowngradeData) => {
            set({ ...data, creatingSession: false });
            const endsAt = new Date(currentSubscription.currentPeriodEndsAt);
            createToast({
              children: `Your subscription has been cancelled and you will not be charged
              next ${currentSubscription.paymentInterval}. You can keep using your Readlee
              ${currentPlan.name} subscription until the current payment period ends on
              ${formatDate(endsAt)} at ${formatTime(endsAt)}.`,
              color: 'warning',
            });
          },
        });
      },
      startPaymentSession: (planId, paymentInterval) => () => {
        set({ creatingSession: true });
        fetchJson(`/api/plans/create_session`, {
          method: 'POST',
          data: {
            planId,
            paymentInterval,
          },
          onSuccess: (data: CreateSessionData) => {
            window.location.replace(data.sessionUrl);
            set({ creatingSession: false });
          },
        });
      },
      onSubscriptionStatus: ({ createToast, action, payload }) => {
        set({
          currentPlan: payload.plan,
          currentSubscription: payload.subscription,
        });
        if (action === 'payment_completed') {
          onPlanActivated(createToast);
        }
      },
      onSubscriptionConnected: (createToast, history) => {
        // Avoid race conditions with the initial load and websocket by re-fetching when it's connected
        loadWithoutReset(createToast, history);
      },
    };
  },
);
