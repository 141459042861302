import { ErrorMap, emptyErrorMap } from 'src/modules/Api';
import { History, QueryMap } from 'src/modules/Router';

export type SignUpClassCodeState = {
  course: { courseCode: string };
  submitting: boolean;
  errors: ErrorMap;
  load: () => void;
  setCourseField: (name: 'courseCode') => (value: string) => void;
  submit: (opts: {
    history: History;
    query: QueryMap;
    courseCodeDeclined: boolean;
    courseCode: string;
    replace?: boolean;
  }) => () => void;
};

export const defaultState = {
  submitting: false,
  errors: emptyErrorMap(),
  course: {
    courseCode: '',
  },
};
