import { KeyboardEventHandler, ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { margins, colors, Height, Width, cssIfTruthyOrZero, Resize, Color } from 'src/styles';
import {
  Input,
  NumberInput,
  InputOnChange,
  TextAreaOnChange,
  NumberInputOnChange,
  SelectInput,
  SelectOption,
  ErrorText,
  CheckboxInput,
  CheckboxInputOnChange,
  Toggle,
  MultiSelectInput,
  MultiSelectInputOption,
  TextSizeInput,
  TextSizeInputOnChange,
  Justify,
  FileInputOnChange,
  FileInput,
  SelectDisplayType,
  TextAreaDisplayType,
  FileInputAccept,
  TextAreaInput,
  DatePicker,
  TimeLabel,
  DatePickerOption,
  AutoCompleteOption,
  DateInput,
  FileUploadKind,
} from 'src/components';

import { ErrorMap } from 'src/modules/Api';
import { useUniqueId } from 'src/modules/UniqueId';
import { ImmutableList } from 'src/modules/Immutable';
import { ImageStoredFile, PdfByPageStoredFile, PdfStoredFile } from 'src/models';

type LabelProps = { $type?: string; $isTextInput?: boolean; $noMargin?: boolean };
export const Label = styled.label<LabelProps>`
  ${({ $noMargin }) => ($noMargin ? 'margin: 0' : `margin-right: ${margins[2]}`)};
  display: block;
  ${({ $type }) => ($type === 'checkbox' ? `cursor: pointer` : '')}
  ${({ $isTextInput }) =>
    $isTextInput &&
    css`
      color: ${colors.gray6.hex};
      font-size: 14px;
      ${({ $noMargin }: LabelProps) => ($noMargin ? '' : `margin-bottom: ${margins[1]};`)}
    `}
`;

const BaseFormGroup = styled.div<{
  $flex: boolean;
  $justify: Justify;
  $height?: Height;
  $width?: Width;
}>`
  ${({ $height, $width }) => `
    ${cssIfTruthyOrZero('height', $height)}
    ${cssIfTruthyOrZero('width', $width)}
  `}
  ${({ $flex, $justify }) =>
    $flex &&
    css`
      display: flex;
      align-items: center;
      justify-content: ${$justify};
    `};
`;

type BaseFormGroupProps = {
  /** ID of the input element */
  id?: string;
  /** Label for the grouped inputs */
  label?: ReactNode;
  /** A name for some corresponding input */
  name: string;
  /** Make the group a flex element, primarily for checkboxes */
  flex?: boolean;
  /** Determines how children should be spaced when using flex */
  justify?: Justify;
  /** Errors */
  errors?: ErrorMap;
  /** Control when input is disabled */
  disabled?: boolean;
  /** Set height */
  height?: Height;
  /** Set width */
  width?: Width;
  noSpinner?: boolean;
};

type BaseTextFormGroupProps = BaseFormGroupProps & {
  placeholder?: string;
  value: string;
  disabled?: boolean;
  onChange: InputOnChange;
  autoComplete?: AutoCompleteOption;
  onKeyDown?: KeyboardEventHandler<HTMLInputElement>;
};

type TextFormGroupProps = BaseTextFormGroupProps & {
  type: 'text';
  testTag?: string;
};
type PasswordFormGroupProps = BaseTextFormGroupProps & { type: 'password'; testTag?: string };
type EmailFormGroupProps = BaseTextFormGroupProps & { type: 'email'; testTag?: string };
type TextAreaFormGroupProps = BaseTextFormGroupProps & {
  type: 'text-area';
  width?: Width;
  maxWidth?: Width;
  height?: Height;
  minHeight?: Height;
  resize?: Resize;
  displayType?: TextAreaDisplayType;
  onChange: TextAreaOnChange;
  testTag?: string;
};

type NumberFormGroupProps = BaseFormGroupProps & {
  type: 'number';
  value: number | null;
  min?: number;
  max?: number;
  onChange: NumberInputOnChange;
  autoComplete?: AutoCompleteOption;
  integerOnly?: boolean;
};

type DateFormGroupProps = BaseTextFormGroupProps & {
  type: 'date';
  min?: string;
  max?: string;
  testTag?: string;
};

type TextSizeFormGroupProps = BaseFormGroupProps & {
  type: 'textSize';
  value: number;
  onChange: TextSizeInputOnChange;
};

type CheckboxFormGroupProps = BaseFormGroupProps & {
  type: 'checkbox';
  value: boolean;
  onChange: CheckboxInputOnChange;
};

type FileFormGroupProps = BaseFormGroupProps & {
  type: 'file';
  value: ImmutableList<File>;
  onChange: FileInputOnChange;
  accept?: FileInputAccept;
  uploading?: boolean;
  testTag?: string;
  uploadedFile?: PdfStoredFile | PdfByPageStoredFile | ImageStoredFile | null;
  iconColor?: Color;
  kind: FileUploadKind;
};

type ToggleFormGroupProps = BaseFormGroupProps & {
  type: 'toggle';
  value: boolean;
  onChange: (value: boolean) => void;
};

type SelectFormGroupProps<T> = BaseFormGroupProps & {
  type: 'select';
  value: T;
  options: ImmutableList<SelectOption<T>>;
  onChange: (value: T) => void;
  displayType?: SelectDisplayType;
  minWidth?: Width;
  testTag?: string;
};

type MultiSelectGroupProps<T> = BaseFormGroupProps & {
  type: 'multiselect';
  options: ImmutableList<MultiSelectInputOption<T>>;
  value: ImmutableList<T>;
  onChange: (value: ImmutableList<T>) => void;
  placeholder?: string;
  testTag?: string;
  maxSelectedShown?: number;
};

type DatePickerGroupProps = BaseFormGroupProps & {
  type: 'date-picker';
  name: string;
  value: TimeLabel;
  onChange: (value: DatePickerOption) => void;
};

type FormGroupProps<T> =
  | TextFormGroupProps
  | TextAreaFormGroupProps
  | PasswordFormGroupProps
  | NumberFormGroupProps
  | CheckboxFormGroupProps
  | ToggleFormGroupProps
  | EmailFormGroupProps
  | TextSizeFormGroupProps
  | FileFormGroupProps
  | MultiSelectGroupProps<T>
  | SelectFormGroupProps<T>
  | DatePickerGroupProps
  | DateFormGroupProps;

export function FormGroup<T extends string | number | string[] | undefined>({
  disabled = false,
  width = '100%',
  ...props
}: FormGroupProps<T>) {
  const id = useUniqueId(props.id);
  const isTextInput = props.type === 'text' || props.type === 'password' || props.type === 'email';
  let content = null;
  if (isTextInput) {
    content = (
      <Input
        id={id}
        name={props.name}
        label={props.label}
        type={props.type}
        value={props.value}
        onChange={props.onChange}
        placeholder={props.placeholder}
        disabled={disabled}
        autoComplete={props.autoComplete}
        testTag={props.testTag}
        onKeyDown={props.onKeyDown}
      />
    );
  } else if (props.type === 'text-area') {
    content = (
      <TextAreaInput
        id={id}
        name={props.name}
        label={props.label}
        value={props.value}
        onChange={props.onChange}
        placeholder={props.placeholder}
        disabled={disabled}
        width={width}
        maxWidth={props.maxWidth}
        height={props.height}
        minHeight={props.minHeight}
        resize={props.resize}
        displayType={props.displayType}
        testTag={props.testTag}
      />
    );
  } else if (props.type === 'number') {
    content = (
      <NumberInput
        id={id}
        name={props.name}
        value={props.value}
        onChange={props.onChange}
        noSpinner={props.noSpinner}
        autoComplete={props.autoComplete}
        min={props.min}
        max={props.max}
        integerOnly={props.integerOnly}
      />
    );
  } else if (props.type === 'checkbox') {
    content = (
      <CheckboxInput
        id={id}
        name={props.name}
        value={props.value}
        onChange={props.onChange}
        label={props.label}
        disabled={disabled}
      />
    );
  } else if (props.type === 'toggle') {
    content = (
      <Toggle
        id={id}
        disabled={disabled}
        name={props.name}
        value={props.value}
        onChange={props.onChange}
      />
    );
  } else if (props.type === 'multiselect') {
    content = (
      <MultiSelectInput
        id={id}
        name={props.name}
        placeholder={props.placeholder}
        options={props.options}
        value={props.value}
        onChange={props.onChange}
        maxSelectedShown={props.maxSelectedShown}
        testTag={props.testTag}
      />
    );
  } else if (props.type === 'select') {
    content = (
      <SelectInput
        id={id}
        name={props.name}
        options={props.options}
        value={props.value}
        onChange={props.onChange}
        displayType={props.displayType}
        disabled={disabled}
        testTag={props.testTag}
      />
    );
  } else if (props.type === 'date-picker') {
    content = (
      <DatePicker id={id} name={props.name} value={props.value} onChange={props.onChange} />
    );
  } else if (props.type === 'file') {
    content = (
      <FileInput
        id={id}
        name={props.name}
        value={props.value}
        onChange={props.onChange}
        accept={props.accept}
        disabled={disabled}
        uploading={props.uploading}
        errors={props.errors}
        testTag={props.testTag}
        uploadedFile={props.uploadedFile}
        iconColor={props.iconColor}
        kind={props.kind}
      />
    );
  } else if (props.type === 'textSize') {
    content = <TextSizeInput id={id} value={props.value} onChange={props.onChange} />;
  } else if (props.type === 'date') {
    content = (
      <DateInput
        id={id}
        name={props.name}
        min={props.min}
        max={props.max}
        value={props.value}
        onChange={props.onChange}
        testTag={props.testTag}
      />
    );
  }

  const label =
    props.type === 'checkbox' || props.type === 'file' || !props.label ? null : (
      <Label
        $isTextInput={
          isTextInput ||
          props.type === 'select' ||
          props.type === 'multiselect' ||
          props.type === 'number' ||
          props.type === 'date' ||
          props.type === 'text-area'
        }
        $type={props.type}
        htmlFor={id}
      >
        {props.label}
      </Label>
    );

  return (
    <BaseFormGroup
      $flex={props.flex || false}
      $justify={props.justify || 'start'}
      $height={props.height}
      $width={width}
    >
      {label}
      {content}
      {props.errors && props.type !== 'file' && (
        <ErrorText errors={props.errors} errorKey={props.name} />
      )}
    </BaseFormGroup>
  );
}
