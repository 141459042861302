import { useCallback } from 'react';
import { Flex, Loading, Spacer, useToaster, Text } from 'src/components';
import { appUrl, isPaperReading, paperReadingPath } from 'src/config';
import { ImmutableList, ImmutableMap } from 'src/modules/Immutable';
import { ReadleeError, ReadleeErrorMeta, reportError } from './ErrorReporting';
import { useHistory } from './Router';
import jwtDecode, { JwtPayload } from 'jwt-decode';
import Cookies from 'universal-cookie';
import { emitWebviewMessage } from './MobileAppMessages';

/* eslint-disable @typescript-eslint/no-explicit-any */
export type Json = any;
type Data = Record<string, Json>;
/* eslint-enable @typescript-eslint/no-explicit-any */

export type QueryObject = Record<string, string | number | boolean>;

export const BASIC_ERROR = 'Something went wrong!';

const NOT_AUTHORIZED = 401;
const NOT_AUTHENTICATED = 403;
const GATEWAY_TIMEOUT = 504;
const GATEWAY_TIMEOUT_RETRY_SCHEDULE = [0, 2000, 10000];
// Longer initial delay here so if the page is being closed the new
// request won't be fired off immediately
const NO_INTERNET_RETRY_SCHEDULE = [1000, 2000, 10000];

export type ErrorMapResponse = Record<string, string[]>;
export type ErrorMap = ImmutableMap<string, string[]>;
export const emptyErrorMap = () => ImmutableMap<string, string[]>();

export type ApiErrorType =
  | 'NotAuthorizedError'
  | 'NotAuthenticatedError'
  | 'UnknownError'
  | 'FailedConnectionError';

export type ApiErrorHandlers = {
  retrySchedule?: Array<number>;
  noRedirect?: boolean;
  onUnknownError?: (errorOpts: { message: string; originalPath: string }) => unknown;
  onFailedConnectionError?: (errorOpts: { originalPath: string }) => unknown;
  onNotAuthenticated?: (errorOpts: { originalPath: string }) => unknown;
  onNotAuthorized?: (errorOpts: { originalPath: string; message: string }) => unknown;
};

type HandleApiErrorProps = ApiErrorHandlers & {
  errorType: ApiErrorType;
  message: string | null;
  meta?: ReadleeErrorMeta;
};

export type HandleApiError = (options: HandleApiErrorProps) => Promise<void>;

export type FetchState = {
  handleApiError: HandleApiError;
  fetchJson: FetchJson;
  fetchResponse: FetchResponse;
  refreshPaperAccessToken: () => Promise<void>;
};

type FetchSettings = ApiErrorHandlers & {
  method?: 'GET' | 'PUT' | 'PATCH' | 'POST' | 'DELETE';
  data?: Data;
  query?: QueryObject;
  headers?: Record<string, string>;
  files?: Record<string, string | File | Blob | File[] | Blob[]>;
  failedConnectionRetryToastId?: string;
  refreshingPaperAccessToken?: boolean;
};

type FetchResponseSettings = FetchSettings & {
  onSuccess?: (resp: Response) => unknown | Promise<unknown>;
};

type FetchJsonSetttings<T> = FetchSettings & {
  onSuccess?: (json: T, resp: Response) => unknown;
};

export type FetchResponse = (
  url: string,
  options?: FetchResponseSettings,
) => Promise<Response | null>;
export type FetchJson = <T>(
  url: string,
  options?: FetchJsonSetttings<T>,
) => Promise<Response | null>;

const deepUseImmutable = (json: Json): Json => {
  if (Array.isArray(json)) {
    return ImmutableList(json.map((item) => deepUseImmutable(item)));
  } else if (json && typeof json === 'object') {
    if (json.__map__) {
      return ImmutableMap(json.__map__.map(([key, value]: Json) => [key, deepUseImmutable(value)]));
    } else {
      return Object.fromEntries(
        Object.entries(json).map(([key, value]) => [key, deepUseImmutable(value)]),
      );
    }
  } else {
    return json;
  }
};

export const transformApiResponse = deepUseImmutable;

interface ParsedFetchSettings {
  headers: Record<string, string>;
  bodyOptions: {
    body?: string | FormData;
  };
  queryObject: QueryObject;
}

export const parseFetchSettings = ({
  method = 'GET',
  files,
  data,
  headers = {},
  query = {},
}: FetchSettings): ParsedFetchSettings => {
  if (method === 'GET') {
    if (files) throw new Error('Can not send files in GET request.');
    return {
      headers,
      bodyOptions: {},
      queryObject: data ? { __json__: JSON.stringify(data), ...query } : query,
    };
  } else if (files) {
    if (headers['Content-Type']) {
      throw new Error('You can not set content type on a FormData request (i.e. one with files).');
    }

    const formData = new FormData();
    Object.entries(files).forEach(([key, file]) => {
      if (Array.isArray(file)) {
        file.forEach((filePart) => {
          // Rails will interpret ?key[]=thing&key[]=thing2 as [thing, thing2]
          formData.append(`${key}[]`, filePart);
        });
      } else {
        formData.append(key, file);
      }
    });

    formData.append('__json__', JSON.stringify(data || {}));

    return {
      headers,
      bodyOptions: { body: formData },
      queryObject: query,
    };
  } else {
    return {
      headers: {
        'Content-Type': 'application/json',
        ...headers,
      },
      bodyOptions: { body: JSON.stringify(data) },
      queryObject: query,
    };
  }
};

export const makeQueryString = (queryObject: QueryObject) => {
  const str = Object.entries(queryObject)
    .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
    .join('&');
  return str.length > 0 ? `?${str}` : '';
};

export const wait = (time: number): Promise<void> => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, time);
  });
};

export const cookies = new Cookies();
export const getPaperAccessToken = (): string | undefined => cookies.get('access_token');
export const getPaperRefreshToken = (): string | undefined => cookies.get('refresh_token');
export const getTokenClient = (): string | undefined => cookies.get('token_client');
export const hasPaperRefreshToken = () => Boolean(cookies.get('refresh_token'));

export const getPaperClaims = () => {
  // NOTE: this will turn null after the jwt expires because the access_token
  // cookie is set to expire, but we want to refresh early
  const token = getPaperAccessToken();
  if (token) {
    try {
      return jwtDecode<JwtPayload>(token);
    } catch {
      return;
    }
  }
  return;
};

type RefreshResponse = {
  access_token: string;
  expires_in: number;
  refresh_token: string;
};

const REFRESH_TOKEN_DURATION = 15552000000; // 180 Days
const isValidRefreshResponse = (data: unknown): data is RefreshResponse =>
  typeof data === 'object' &&
  data !== null &&
  'access_token' in data &&
  'refresh_token' in data &&
  'expires_in' in data;

const createRefreshPaperAccessTokenHook = () => {
  let refreshing = false;
  let freeLock: () => void;
  let lock: Promise<void>;
  const createLock = () => {
    lock = new Promise<void>((resolve) => (freeLock = resolve));
  };
  createLock();
  return () => {
    return useCallback(() => {
      const refreshUrl = `${window.location.origin}/laravel/refresh-token`;
      return new Promise<void>((resolve) => {
        const refreshDone = () => {
          refreshing = false;
          freeLock();
          resolve();
        };
        if (!hasPaperRefreshToken()) {
          refreshDone();
          return;
        }
        if (!refreshing) {
          refreshing = true;
          createLock();
          const accessToken = getPaperAccessToken();
          const tokenClient = getTokenClient();
          const isMobileWebviewOpened =
            window.localStorage.getItem('mobileWebviewOpened') === 'true';

          const fetchRefreshToken = async () => {
            let valid = false;
            let parsedJson = false;
            try {
              const response = await fetch(refreshUrl, {
                headers: {
                  Authorization: `Bearer ${accessToken}`,
                  Accept: 'application/json',
                  'Content-Type': 'application/json',
                  ...(isMobileWebviewOpened && tokenClient ? { 'token-client': tokenClient } : {}),
                },
                method: 'POST',
                body: JSON.stringify({ refresh_token: getPaperRefreshToken() }),
              });

              const data: unknown = await response.json();

              parsedJson = true;
              if (isValidRefreshResponse(data)) {
                valid = true;
                cookies.set('refresh_token', data['refresh_token'], {
                  expires: new Date(new Date().getTime() + REFRESH_TOKEN_DURATION),
                  path: '/',
                  secure: true,
                });
                cookies.set('access_token', data['access_token'], {
                  expires: new Date(new Date().getTime() + 1000 * data['expires_in']),
                  path: '/',
                  secure: true,
                });
              }
            } finally {
              if (valid) {
                emitWebviewMessage({
                  message: 'webview_updated_refresh_token',
                  refreshToken: getPaperRefreshToken() as string, // we know it's a string because of the valid check above
                });

                refreshDone();
              } else {
                if (!parsedJson && process.env.REACT_ENV === 'development') {
                  refreshDone();
                } else {
                  window.location.href = `${appUrl}/logout`;
                }
              }
            }
          };
          fetchRefreshToken();
        } else {
          lock.then(() => refreshDone());
        }
      });
    }, []);
  };
};

export const useRefreshPaperAccessToken = createRefreshPaperAccessTokenHook();

const getPathname = () => window.location.pathname.replace(new RegExp(`^${paperReadingPath}`), '');

const getOriginalPath = () => {
  const pathname = getPathname();
  const { search, hash } = window.location;
  return `${pathname}${search}${hash}`;
};

// These errors indicate a request was cancelled (usually by navigating away) and should not
// be retried
const cancelledRequestErrors = [
  { name: 'TypeError', message: 'cancelled' },
  { name: 'AbortError', message: 'The operation was aborted.' },
  { name: 'Error', message: 'aborted' },
] as const;

// These errors indicate either a cancelled request or that the user has no internet,
// we want to retry them but after a delay to see which it was.
const noInternetErrors = [
  { name: 'TypeError', message: 'NetworkError when attempting to fetch resource.' },
  { name: 'TypeError', message: 'Failed to fetch' },
  { name: 'TypeError', message: 'Load failed' },
] as const;

export const useFetch = (): FetchState => {
  const history = useHistory();
  if (!history) {
    throw new Error(
      'You tried to use `useFetch` in a context where `useHistory` was not available.',
    );
  }
  const { createToast, closeToast } = useToaster();
  const refreshPaperAccessToken = useRefreshPaperAccessToken();

  const handleApiError: HandleApiError = useCallback(
    async ({
      errorType,
      message,
      onNotAuthenticated,
      onNotAuthorized,
      onUnknownError,
      onFailedConnectionError,
      noRedirect,
      meta,
    }: HandleApiErrorProps) => {
      const finalMessage = message || BASIC_ERROR;
      const redirect = (path: string) => (noRedirect ? null : history.replace(path));
      const originalPath = getOriginalPath();

      if (errorType === 'NotAuthenticatedError') {
        if (onNotAuthenticated) onNotAuthenticated({ originalPath });
        redirect(`/login${makeQueryString({ redirectPath: originalPath })}`);
      } else if (errorType === 'NotAuthorizedError') {
        const errorQuery = makeQueryString({
          message: finalMessage,
          originalPath,
        });
        if (onNotAuthorized) onNotAuthorized({ message: finalMessage, originalPath });
        redirect(`/404${errorQuery}`);
      } else if (errorType === 'FailedConnectionError') {
        if (onFailedConnectionError) onFailedConnectionError({ originalPath });

        createToast({
          children:
            'Could not connect to the internet, please check your connection and reload the page.',
          color: 'danger',
          forever: true,
          closeAnywhere: false,
        });
      } else {
        if (onUnknownError) onUnknownError({ message: finalMessage, originalPath });
        const errorQuery = makeQueryString({
          message: finalMessage,
          originalPath,
        });
        redirect(`/500${errorQuery}`);
        reportError(new ReadleeError(finalMessage, meta || {}));
      }
    },
    [history, createToast],
  );

  const fetchResponse: FetchResponse = useCallback(
    async (originalUrl: string, options: FetchResponseSettings = {}): Promise<Response | null> => {
      if (isPaperReading && !options.refreshingPaperAccessToken && getPathname() !== '/logout') {
        const now = Date.now() / 1000;
        const refreshTime = (getPaperClaims()?.exp ?? 0) - 60 * 3; // refresh 3 minutes early
        if (now > refreshTime) {
          await refreshPaperAccessToken();
        }
      }
      const url = originalUrl.startsWith('/api/') ? `${appUrl}${originalUrl}` : originalUrl;
      const { bodyOptions, queryObject, headers } = parseFetchSettings(options);
      const queryString = makeQueryString(queryObject);

      let response: Response | null = null;
      try {
        response = await fetch(`${url}${queryString}`, {
          method: options.method || 'GET',
          headers,
          ...bodyOptions,
        });
      } catch (err) {
        const error = err;
        if (!(error instanceof Error)) throw error;
        if (
          cancelledRequestErrors.some((e) => e.name === error.name && e.message === error.message)
        ) {
          // Cancel request and return immediately
          return null;
        } else if (
          noInternetErrors.some((e) => e.name === error.name && e.message === error.message)
        ) {
          // Continue and process as a no internet error
          response = null;
        } else {
          throw error;
        }
      }

      const isNoInternetError = !response;
      const isGatewayTimeoutError = response?.status === GATEWAY_TIMEOUT;
      const isFailedConnectionError = isNoInternetError || isGatewayTimeoutError;

      let schedule = null;
      if (options.retrySchedule) {
        schedule = options.retrySchedule;
      } else if (isNoInternetError) {
        schedule = NO_INTERNET_RETRY_SCHEDULE;
      } else if (isGatewayTimeoutError) {
        schedule = GATEWAY_TIMEOUT_RETRY_SCHEDULE;
      }

      if (!response || response.status >= 400 || response.status === 0) {
        let body = null;
        let message = BASIC_ERROR;
        if (response) {
          try {
            body = await response.clone().text();
            message = JSON.parse(body).message;
          } catch (error) {
            message = BASIC_ERROR;
          }
        }

        const handleSharedOptions = {
          onNotAuthenticated: options.onNotAuthenticated,
          onNotAuthorized: options.onNotAuthorized,
          onUnknownError: options.onUnknownError,
          onFailedConnectionError: options.onFailedConnectionError,
          message,
          noRedirect: options.noRedirect,
          meta: {
            url: originalUrl,
            method: options.method,
            code: response?.status ?? -1,
            body: body,
            message: message,
          },
        };
        if (response?.status === NOT_AUTHENTICATED) {
          await handleApiError({
            ...handleSharedOptions,
            errorType: 'NotAuthenticatedError',
            message: 'Not logged in',
          });
        } else if (response?.status === NOT_AUTHORIZED) {
          await handleApiError({
            ...handleSharedOptions,
            errorType: 'NotAuthorizedError',
          });
        } else if (schedule && schedule.length > 0 && typeof schedule[0] === 'number') {
          let failedConnectionRetryToastId = options.failedConnectionRetryToastId;
          if (isFailedConnectionError && !failedConnectionRetryToastId) {
            failedConnectionRetryToastId = createToast({
              children: (
                <Flex align="center">
                  <Text>Could not connect to Readlee, attempting to reconnect...</Text>
                  <Spacer horizontal />
                  <Loading size="small" />
                </Flex>
              ),
              forever: true,
              color: 'warning',
              closeAnywhere: false,
            });
          }
          const time = schedule[0];
          await wait(time);
          const retryResult = await fetchResponse(originalUrl, {
            ...options,
            failedConnectionRetryToastId,
            retrySchedule: schedule.slice(1),
          });
          return retryResult;
        } else if (isFailedConnectionError) {
          if (options.failedConnectionRetryToastId) {
            closeToast(options.failedConnectionRetryToastId);
          }
          await handleApiError({
            ...handleSharedOptions,
            errorType: 'FailedConnectionError',
            message: BASIC_ERROR,
          });
        } else {
          await handleApiError({
            ...handleSharedOptions,
            errorType: 'UnknownError',
          });
        }
        // Success
      } else if (response) {
        if (options.failedConnectionRetryToastId) {
          closeToast(options.failedConnectionRetryToastId);
        }
        if (options.onSuccess) {
          await options.onSuccess(response);
        }
      }

      return response;
    },
    [refreshPaperAccessToken, handleApiError, createToast, closeToast],
  );

  const fetchJson: FetchJson = useCallback(
    async function <T>(url: string, options: FetchJsonSetttings<T> = {}): Promise<Response | null> {
      return fetchResponse(url, {
        ...options,
        onSuccess: async (response: Response) => {
          if (options.onSuccess) {
            try {
              const result = await response.json();
              options.onSuccess(transformApiResponse(result), response);
            } catch (err) {
              if (options.onUnknownError) {
                const originalPath = getOriginalPath();
                if (err instanceof Error) {
                  options.onUnknownError({ message: err.message, originalPath });
                } else {
                  options.onUnknownError({ message: 'UnknownError', originalPath });
                }
              }
            }
          }
        },
      });
    },
    [fetchResponse],
  );

  return {
    handleApiError,
    fetchResponse,
    fetchJson,
    refreshPaperAccessToken,
  };
};
