import { ImmutableMap } from 'src/modules/Immutable';

export type PdfByPageStoredFile = {
  id: string;
  type: 'PdfByPageStoredFile';
  url: string;
  name: string;
  pageCount: number;
  processingStatus: 'queued' | 'started' | 'finished' | 'errored';
  jsonText: ImmutableMap<string, string>;
};
