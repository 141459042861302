export const appFontSize = 14;

export const baseMeasurement = 4;

export const standardMeasurements = {
  none: '0px',
  quarter: `${baseMeasurement / 4}px`,
  half: `${baseMeasurement / 2}px`,
  1: `${baseMeasurement}px`,
  2: `${2 * baseMeasurement}px`,
  3: `${3 * baseMeasurement}px`,
  4: `${4 * baseMeasurement}px`,
  5: `${5 * baseMeasurement}px`,
  6: `${6 * baseMeasurement}px`,
  7: `${7 * baseMeasurement}px`,
  8: `${8 * baseMeasurement}px`,
  9: `${9 * baseMeasurement}px`,
  10: `${10 * baseMeasurement}px`,
  11: `${11 * baseMeasurement}px`,
  12: `${12 * baseMeasurement}px`,
  13: `${13 * baseMeasurement}px`,
  14: `${14 * baseMeasurement}px`,
  15: `${15 * baseMeasurement}px`,
  16: `${16 * baseMeasurement}px`,
  17: `${17 * baseMeasurement}px`,
  18: `${18 * baseMeasurement}px`,
  19: `${19 * baseMeasurement}px`,
  20: `${20 * baseMeasurement}px`,
  21: `${21 * baseMeasurement}px`,
  22: `${22 * baseMeasurement}px`,
  23: `${23 * baseMeasurement}px`,
  32: `${32 * baseMeasurement}px`,
  75: `${75 * baseMeasurement}px`,
} as const;

export const DISABLED_OPACITY = 0.4;

export const TEXT_VIEWER_MAX_WIDTH = '600px';

export type DollarPrefix<Type> = {
  [Property in keyof Type as `$${string & Property}`]: Type[Property];
};

export type RequiredDollarPrefix<Type> = Required<DollarPrefix<Type>>;

export type FontStyle = 'normal' | 'italic' | 'oblique';
export type WhiteSpace =
  | 'initial'
  | 'nowrap'
  | 'normal'
  | 'pre'
  | 'pre-wrap'
  | 'pre-line'
  | 'break-spaces';
export type TextDisplayTypes = 'inline' | 'block' | 'inline-block' | 'inline-flex';
export type TextAlign = 'inherit' | 'left' | 'right' | 'center';
export type TextTransform = 'none' | 'uppercase' | 'lowercase' | 'capitalize';
export type WordBreak = 'normal' | 'break-all' | 'break-word' | 'keep-all';
export type Resize = 'none' | 'both' | 'horizontal' | 'vertical';

type SharedSizing = 'auto' | 'initial' | 'inherit' | 'revert' | 'unset';

export type Height = 0 | `${number}${'px' | 'em' | '%' | 'vh'}` | 'fit-content' | SharedSizing;
export type Width =
  | 0
  | `${number}${'px' | 'em' | '%' | 'vw'}`
  | 'fit-content'
  | 'min-content'
  | SharedSizing;

export const cssIfTruthyOrZero = (
  cssName: string,
  value: number | string | false | undefined,
  defaultValue?: number | string,
): string => {
  const realValue = value || value === 0 ? value : defaultValue;
  return Boolean(realValue) || realValue === 0 ? `${cssName}: ${realValue};` : '';
};

export const cssIfTruthy = (
  cssName: string,
  value: number | string | false | undefined,
  defaultValue?: number | string,
): string =>
  Boolean(value) || Boolean(defaultValue) ? `${cssName}: ${value || defaultValue};` : '';

export const cssIfTrue = (cssName: string, value: number | string, booleanCheck: boolean): string =>
  booleanCheck ? `${cssName}: ${value};` : '';
