import { AvatarProps, getSkin, getEyes, getHat, getMouth, User } from 'src/models';
import { borderRadii, colors, useBreakpoints } from 'src/styles';
import styled from 'styled-components';
import BlueBorder from 'src/assets/avatar/blue_border.svg';

const AvatarContainer = styled.div<{
  $size: string;
  $borderRadius: string;
  $boxShadow: string;
}>`
  position: relative;
  height: ${({ $size }) => $size}px;
  width: ${({ $size }) => $size}px;
  background: ${colors.white.hex};
  border-radius: ${({ $borderRadius }) => $borderRadius};
  box-shadow: ${({ $boxShadow }) => $boxShadow};
`;

const AvatarElement = styled.img`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
`;

type AvatarKind = 'builder' | 'dashboard' | 'submission' | 'profile';

type BaseStudentAvatarProps = AvatarProps & {
  kind: AvatarKind;
  testTag?: string;
};

type StudentAvatarProps = {
  user: User;
  kind: AvatarKind;
  testTag?: string;
};

export const BaseStudentAvatar = ({
  avatarSkin,
  avatarEyes,
  avatarMouth,
  avatarHat,
  kind,
  testTag,
}: BaseStudentAvatarProps) => {
  const showMobile = useBreakpoints({ smallerThanOrEqualTo: 'mobileLarge' });

  const getAttributes = () => {
    switch (kind) {
      case 'builder':
        return {
          size: showMobile ? '200' : '300',
          borderRadius: borderRadii[3],
          boxShadow: `3px 3px 10px ${colors.gray7.hex}`,
        };
      case 'dashboard':
        return {
          size: '80',
          borderRadius: borderRadii[3],
          boxShadow: `3px 3px 10px ${colors.black.hex}`,
        };
      case 'submission':
        return {
          size: '60',
          borderRadius: borderRadii[3],
          boxShadow: `3px 3px 10px ${colors.black.hex}`,
        };
      case 'profile':
      default:
        return {
          size: '40',
          borderRadius: borderRadii[1],
          boxShadow: `1px 1px 5px ${colors.gray5.hex}`,
        };
    }
  };

  const { size, borderRadius, boxShadow } = getAttributes();
  return (
    <AvatarContainer
      $size={size}
      $borderRadius={borderRadius}
      $boxShadow={boxShadow}
      data-test-tag={testTag}
    >
      <AvatarElement src={getSkin(avatarSkin)} data-test-tag={`avatar-color-${avatarSkin}`} />
      <AvatarElement src={getEyes(avatarEyes)} data-test-tag={`avatar-eyes-${avatarEyes}`} />
      <AvatarElement src={getMouth(avatarMouth)} data-test-tag={`avatar-mouth-${avatarMouth}`} />
      <AvatarElement src={getHat(avatarHat)} data-test-tag={`avatar-hat-${avatarHat}`} />
      <AvatarElement src={BlueBorder} data-test-tag="avatar-border" />
    </AvatarContainer>
  );
};

export const StudentAvatar = ({ user, ...baseProps }: StudentAvatarProps) => (
  <BaseStudentAvatar {...baseProps} {...user} />
);
