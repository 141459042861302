import { User, PrepareParams } from 'src/models/User';
import { ErrorMapResponse, FetchJson } from 'src/modules/Api';
import { History } from 'src/modules/Router';
import { ImmutableList } from 'src/modules/Immutable';
import { ClassColor } from 'src/styles';

export type CleverButtonStatus =
  | 'success'
  | 'errored'
  | 'fields_missing'
  | 'oauth_required'
  | 'needs_email_confirmation';

export type CleverOauthResponse = {
  status: CleverButtonStatus;
  successUrl?: string;
  user?: User;
  errors?: ErrorMapResponse;
  isNew?: boolean;
};

type CreateLinkData = {
  alreadyValid: boolean;
  authUrl: string;
};
export type CleverSection = {
  id: string;
  grade: string;
  name: string;
  subject: string;
  teacher: string;
  students: ImmutableList<string>;
  teachers: ImmutableList<string>;
  avatarColor: ClassColor;
};

export const goToClever = ({
  successUrl,
  history,
  userAttributes,
  fetchJson,
  forceOauth = false,
}: {
  successUrl: string;
  history: History;
  userAttributes: PrepareParams;
  fetchJson: FetchJson;
  forceOauth?: boolean;
}) => {
  fetchJson(`/api/clever_oauths/create_link`, {
    method: 'POST',
    data: {
      ...userAttributes,
      successUrl,
    },
    onSuccess: (data: CreateLinkData) => {
      if (data.alreadyValid && !forceOauth) {
        history.push(successUrl);
      } else {
        window.location.href = data.authUrl;
      }
    },
  });
};
