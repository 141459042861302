import { AudioRecorder, Button, Flex, Spacer, TextAreaInput, ReactionButton } from 'src/components';
import { useStore } from 'src/Store';

export const CreateComment = () => {
  const {
    onRecordingSequenceStatus,
    createRecordingSequence,
    createReactionComment,
    createWrittenComment,
    createAudioComment,
    creatingRecordingSequence,
    savingComment,
    setField,
    editingCommentType,
    editingRecordingSequence,
    editingAudioFile,
    editingWrittenContent,
    isRecording,
  } = useStore(
    (s) => ({
      onRecordingSequenceStatus: s.SubmissionOverviewData.onRecordingSequenceStatus,
      createRecordingSequence: s.SubmissionOverviewData.createRecordingSequence,
      createReactionComment: s.SubmissionOverviewData.createReactionComment,
      createWrittenComment: s.SubmissionOverviewData.createWrittenComment,
      createAudioComment: s.SubmissionOverviewData.createAudioComment,
      creatingRecordingSequence: s.SubmissionOverviewData.creatingRecordingSequence,
      savingComment: s.SubmissionOverviewData.savingComment,
      setField: s.SubmissionOverviewData.setField,
      editingCommentType: s.SubmissionOverviewData.editingCommentType,
      editingRecordingSequence: s.SubmissionOverviewData.editingRecordingSequence,
      editingAudioFile: s.SubmissionOverviewData.editingAudioFile,
      editingWrittenContent: s.SubmissionOverviewData.editingWrittenContent,
      isRecording: s.AppData.isRecording,
    }),
    [],
  );

  if (editingCommentType === 'undecided') {
    return (
      <Flex align="stretch" height="40px" justify="center" width="100%">
        <Button
          onClick={() => setField('editingCommentType')('written')}
          icon="pencil"
          iconFill
          iconStrokeWidth={0}
          iconSize="1.25em"
          zIndex="toast"
        >
          Write
        </Button>
        <Spacer size={2} horizontal />
        <Button
          loading={creatingRecordingSequence}
          onClick={createRecordingSequence}
          icon="microphone"
          iconStrokeWidth={1}
        >
          Record
        </Button>
        <Spacer size={2} horizontal />
        <ReactionButton onClick={createReactionComment} loading={savingComment} />
      </Flex>
    );
  }

  const ActionButtons = ({ action }: { action: () => void }) => (
    <>
      <Spacer size={2} />
      <Flex align="center" justify="end">
        <Button
          displayType="link"
          onClick={() => setField('editingCommentType')('undecided')}
          disabled={isRecording}
          testTag="cancel-recording"
        >
          Cancel
        </Button>
        <Spacer size={2} horizontal />
        <Button
          onClick={action}
          loading={savingComment}
          disabled={isRecording || (editingCommentType === 'written' && !editingWrittenContent)}
        >
          Send
        </Button>
      </Flex>
    </>
  );

  if (editingCommentType === 'recorded' && editingRecordingSequence) {
    return (
      <Flex width="100%" justify="center" align="stretch" direction="column">
        <Flex align="center" justify="center" direction="column">
          <Flex align="center" justify="center">
            <AudioRecorder
              recordingSequence={editingRecordingSequence}
              audioFile={editingAudioFile}
              onRecordingSequenceStatus={onRecordingSequenceStatus}
              displayType="desktopSmall"
            />
          </Flex>
          {!editingAudioFile && (
            <>
              <Spacer size={2} />
              <Button
                displayType="link"
                onClick={() => setField('editingCommentType')('undecided')}
                disabled={isRecording}
                testTag="cancel-recording"
              >
                Cancel
              </Button>
            </>
          )}
        </Flex>
        {editingAudioFile && <ActionButtons action={createAudioComment} />}
      </Flex>
    );
  } else {
    return (
      <Flex direction="column" width="100%">
        <TextAreaInput
          name="writtenContent"
          value={editingWrittenContent}
          onChange={setField('editingWrittenContent')}
          placeholder="Write your feedback here"
          height="100%"
          width="100%"
        />
        <ActionButtons action={createWrittenComment} />
      </Flex>
    );
  }
};
