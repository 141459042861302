import { Text } from 'src/components';
import { ErrorMap } from 'src/modules/Api';

type ErrorTextProps =
  | { message: string; errors?: null; errorKey?: null }
  | {
      message?: null;
      errors: ErrorMap;
      errorKey: string;
    };

export const ErrorText = ({ message = null, errors = null, errorKey = null }: ErrorTextProps) => {
  let text = null;
  if (typeof message === 'string') {
    text = message;
  } else if (errors && errorKey) {
    text = errors.get(errorKey)?.join(', ');
  }
  if (!text) return null;
  return <Text color="danger">{text}</Text>;
};
