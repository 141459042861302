import { ChangeEventHandler, useCallback } from 'react';
import { ImmutableList } from 'src/modules/Immutable';
import { StyledInput, SelectDisplayType } from './InputHelpers';
import styled, { css } from 'styled-components';
import { borderRadii, colors } from 'src/styles';
import { Icon, IconType, Flex, Spacer } from 'src/components';

export type SelectOption<T> = {
  value: T;
  label: string;
  disabled?: boolean;
};

type SelectInputProps<T> = {
  /** ID of the input */
  id?: string;
  /** Value of input */
  value: T | undefined;
  /** Name for input in form */
  name: string;
  /** Event handler for change event */
  onChange: (newValue: T, evt: React.ChangeEvent<HTMLSelectElement>) => void;
  /** Options */
  options: ImmutableList<SelectOption<T>>;
  /** Display type */
  displayType?: SelectDisplayType;
  icon?: IconType;
  title?: string;
  display?: 'inline' | 'block' | 'inline-block';
  disabled?: boolean;
  testTag?: string;
};

const StyledSelect = styled(StyledInput.withComponent('select'))<{
  $displayType: SelectDisplayType;
  $display: 'inline' | 'block' | 'inline-block';
}>`
  cursor: pointer;
  display: ${({ $display }) => $display};
  ${({ $displayType }) =>
    $displayType === 'icon'
      ? css`
          border: none;
          appearance: none;
        `
      : ''};
`;

export function SelectInput<T extends string | number | string[] | undefined>({
  onChange,
  value,
  options,
  displayType = 'form',
  display = 'block',
  icon,
  testTag,
  ...basicProps
}: SelectInputProps<T>) {
  const realOnChange = useCallback(
    (event: Parameters<ChangeEventHandler<HTMLSelectElement>>[0]) => {
      const option = options.get(event.target.selectedIndex);
      if (!option) return;
      onChange(option.value, event);
    },
    [onChange, options],
  );

  return (
    <Flex
      display={display.includes('inline') ? 'inline-flex' : 'flex'}
      align="center"
      borderRadius={borderRadii[2]}
      backgroundColor={displayType === 'icon' ? colors.white.hex : undefined}
      border={displayType === 'icon' ? `2px solid ${colors.gray2.hex}` : undefined}
    >
      <StyledSelect
        {...basicProps}
        $displayType={displayType}
        value={value}
        onChange={realOnChange}
        $display={display}
        data-test-tag={testTag}
      >
        {options.map((option, i) => (
          <option key={i} value={option.value} disabled={option.disabled}>
            {option.label}
          </option>
        ))}
      </StyledSelect>
      {icon && (
        <>
          <Icon icon={icon} size="2em" color="black" />
          <Spacer horizontal size={4} />
        </>
      )}
    </Flex>
  );
}
