import { Flex, Thumbnail, Spacer, Text, LinkButton } from 'src/components';

type ReadingStudioLayoutTopBarProps = {
  thumbnailUrl?: string;
  readingTitle?: string;
  authorName?: string;
  webSourceUrl?: string | null;
  justify?: 'center' | 'start';
};

export const ReadingStudioLayoutTitle = ({
  thumbnailUrl,
  readingTitle,
  authorName,
  webSourceUrl,
  justify = 'center',
}: ReadingStudioLayoutTopBarProps) => {
  return (
    <Flex grow={2} width="100%" align="center" justify={justify}>
      <Thumbnail
        src={thumbnailUrl}
        testTag="reading-studio-layout-top-bar-image"
        size="small"
        title={readingTitle}
      />
      <Spacer horizontal size={2} />
      <Flex direction="column" maxWidth="280px">
        <Text
          variant="h6"
          ellipsis
          ellipsifiedTitle={readingTitle || 'Unknown Book Title'}
          testTag="reading-studio-layout-top-bar-title"
        >
          {readingTitle || 'Unknown Book Title'}
        </Text>
        <Flex align="center">
          {webSourceUrl && (
            <>
              <LinkButton newTab href={webSourceUrl}>
                {new window.URL(webSourceUrl).hostname}
              </LinkButton>
              <Spacer size={1} horizontal />
              -
              <Spacer size={1} horizontal />
            </>
          )}

          <Text
            variant="h7"
            color="gray5"
            ellipsis
            ellipsifiedTitle={authorName || 'Unknown Author'}
            testTag="reading-studio-layout-top-bar-author"
          >
            {authorName || 'Unknown Author'}
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
};
