import { useEffect, useState, ReactNode } from 'react';
import ReactDOM from 'react-dom';
import { useUniqueId } from 'src/modules/UniqueId';

export type BodyPortalProps = {
  children: ReactNode;
};

const getBodyPortalRoot = (): HTMLElement => {
  const existing = document.getElementById('portal-root');
  if (existing) return existing;

  const portalRoot = document.createElement('div');
  portalRoot.id = 'portal-root';
  document.body.appendChild(portalRoot);
  return portalRoot;
};

export const BodyPortal = ({ children }: BodyPortalProps) => {
  const parentElementId = useUniqueId();
  const [parentElement, setParentElement] = useState<HTMLDivElement | null>(null);
  useEffect(() => {
    if (!parentElement) {
      const portalRoot = getBodyPortalRoot();
      const newParentElement = document.createElement('div');
      newParentElement.id = `portal-${parentElementId}`;
      portalRoot.appendChild(newParentElement);

      setParentElement(newParentElement);
    }

    return () => {
      if (parentElement) parentElement.remove();
    };
  }, [parentElementId, parentElement, setParentElement]);

  if (!parentElement) return null;

  return ReactDOM.createPortal(children, parentElement);
};
