import { ReactNode } from 'react';
import styled from 'styled-components';

import { Spacer, Icon, Button, IconType, Text, ButtonEvent, Reaction } from 'src/components';
import { borderRadii, Color, colors, paddings, rgba, margins } from 'src/styles';
import { CommentReaction } from 'src/models/Comment';

const StudioButton = styled.div<{ $active: boolean; $completed: boolean }>`
  width: 100%;
  color: ${colors.white.hex};
  background: ${({ $active = false, $completed }) => {
    return $completed
      ? $active
        ? colors.success.hex
        : colors.success.active
      : rgba(colors.white.hex, $active ? 0.3 : 0.1);
  }};
  display: flex;
  flex-grow: 0;
  align-items: center;
  border: none;
  border-radius: ${borderRadii[1]};
  margin-bottom: ${margins[2]};
  padding: ${paddings[2]} ${paddings[3]};
`;

const RightContent = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
`;

type TaskButtonProps = {
  to?: string;
  onClick?: (evt: ButtonEvent) => void;
  active: boolean;
  icon?: IconType;
  reaction?: CommentReaction;
  children: ReactNode;
  rightIcon?: boolean;
  rightText?: string;
  rightTextColor?: Color;
  loading?: boolean;
  disabled?: boolean;
  completed?: boolean;
};

export const TaskButton = ({
  to,
  onClick,
  icon,
  reaction,
  children,
  active,
  rightIcon = false,
  rightText,
  rightTextColor = 'white',
  loading = false,
  disabled = false,
  completed = false,
}: TaskButtonProps) => {
  const reactionContent = reaction ? <Reaction kind={reaction} /> : null;

  const iconContent = icon ? (
    <Icon
      icon={icon}
      color={disabled ? 'gray5' : 'white'}
      size={icon === 'pdf' ? '1em' : '1.25em'}
      fill={icon === 'pdf' || icon === 'book'}
      fillColor="white"
      strokeWidth={icon === 'book' || icon === 'pdf' ? 0 : 1}
    />
  ) : null;

  return (
    <Button
      display="block"
      displayType="noStyles"
      to={to}
      onClick={onClick}
      width="100%"
      loading={loading}
      disabled={disabled}
      testTag="task-button"
    >
      <StudioButton $active={active} $completed={completed}>
        {!rightIcon ? (
          <>
            {reactionContent || iconContent} <Spacer horizontal size={3} />
          </>
        ) : null}

        <Text whiteSpace="nowrap" color="white" variant="h6">
          {children}
        </Text>
        <RightContent>
          {rightText && (
            <Text whiteSpace="nowrap" color={rightTextColor} variant="h6">
              {rightText}
            </Text>
          )}
          {rightIcon && (reactionContent || iconContent)}
          {completed && <Icon icon="check" size="1.25em" />}
        </RightContent>
      </StudioButton>
    </Button>
  );
};
