import { subDataCreator } from 'src/State';
import { defaultState, DictionaryResponse } from './DictionaryState';
import { ImmutableList } from 'src/modules/Immutable';
import { CreateToast } from '../Toast';
import { Word } from 'src/models';

const dictionaryCache: {
  [word: string]: {
    definitions: ImmutableList<string>;
    synonyms: ImmutableList<string>;
    audioUrl: string | null;
    displayWord: Word;
  };
} = {};

export const MISSING_DEFINITIONS_ERROR = 'Could not load definitions or synonyms at this time';

export const createDefaultDictionaryData = subDataCreator(
  'DictionaryData',
  defaultState,
  ({ set, setField, fetchJson }) => ({
    setField,
    load: () => set(defaultState),
    searchDictionary: (query) => {
      set({ open: true, isLoading: true, word: query });

      const cacheResult = dictionaryCache[query];
      if (cacheResult) {
        const { definitions, synonyms, audioUrl, displayWord } = cacheResult;
        set({
          displayWord,
          definitions,
          synonyms,
          audioUrl,
          error: '',
          isLoading: false,
          displayDefinitions: true,
        });
        return;
      }

      fetchJson(`/api/words/definition`, {
        noRedirect: true,
        data: { word: query },
        onUnknownError: () => set({ error: MISSING_DEFINITIONS_ERROR, isLoading: false }),
        onSuccess: ({
          definitions,
          synonyms,
          audioUrl,
          error,
          displayWord,
        }: DictionaryResponse) => {
          if (error) {
            set({ error: MISSING_DEFINITIONS_ERROR, isLoading: false });
            return;
          }

          dictionaryCache[query] = { definitions, synonyms, audioUrl, displayWord };
          set({
            definitions,
            synonyms,
            audioUrl,
            displayWord,
            isLoading: false,
            displayDefinitions: true,
            error: '',
          });
        },
      });
    },
    report: (wordId, createToast: CreateToast) => {
      if (!wordId) return;

      fetchJson(`/api/dictionary_reports`, {
        method: 'POST',
        data: { wordId },
        onSuccess: () => createToast({ children: 'Word reported for review!', color: 'success' }),
      });
    },
  }),
);
