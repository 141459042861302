import { useStore } from 'src/Store';
import { useQuery, useHistory, useParams } from 'src/modules/Router';
import { Button, Spacer, Text, StandardModal, FormGroup, minDateToday } from 'src/components';
import { Route } from 'react-router-dom';

export const InternalGrantTrialModal = () => {
  const history = useHistory();
  const query = useQuery();
  const params = useParams<{ id: string }>();
  const { results, grantTrial, trialEndDate, setField, errors, closeTrialModal } = useStore(
    (s) => s.UserSearchData,
    [],
  );

  const user = results.find(({ user }) => user.id === params.id)?.user;
  if (!user) return null;

  return (
    <StandardModal
      header="Grant Trial"
      close={closeTrialModal(history, query)}
      openType="state"
      opened
      footer={
        <>
          <Button onClick={closeTrialModal(history, query)} displayType="link" color="primaryBlue">
            Cancel
          </Button>
          <Spacer size={3} horizontal />
          <Button disabled={!trialEndDate} onClick={() => grantTrial(user, history, query)}>
            Save Trial
          </Button>
        </>
      }
    >
      <Text>
        If the user has an existing plan, the plan will be cancelled, prorated, and a new plan will
        begin under the name Readlee Ultimate (Trial).
      </Text>
      <Spacer size={4} />
      <FormGroup
        width="auto"
        label="Trial End Date"
        type="date"
        name="plan"
        min={minDateToday()}
        value={trialEndDate}
        onChange={setField('trialEndDate')}
        errors={errors}
        testTag="trial-end-date"
      />
      <Spacer size={4} />
      {trialEndDate && (
        <Text variant="h4">
          {user.displayName} will have a free trial until {trialEndDate}
        </Text>
      )}
    </StandardModal>
  );
};

export const GrantTrialModal = () => (
  <Route path="/m/user-search/:id/grant-trial">
    <InternalGrantTrialModal />
  </Route>
);
