import { TaskDetails, getTaskIcon, User, formatName } from 'src/models';
import {
  Flex,
  Text,
  Icon,
  Spacer,
  Button,
  Divider,
  AudioPlayer,
  LinkButton,
  IconType,
  VocabAudio,
  VocabImage,
  VocabDefinition,
} from 'src/components';
import { basicColors, borderRadii, colors, margins, paddings, userColors } from 'src/styles';
import styled from 'styled-components';
import { ImmutableList } from 'src/modules/Immutable';
import { UserAvatar } from '../Avatar';
import { Fragment, useState } from 'react';
import { queryMapToString, useQuery, useLocation, matchPath } from 'src/modules/Router';
import { updateQuery } from '../../modules/Router';

const TaskCardStyles = styled.div`
  background: ${colors.white.hex};
  margin-bottom: ${margins[3]};
  border-radius: ${borderRadii[4]};
  border: 1px solid ${colors.gray2.hex};
`;

type ToggleCardOpenProps = {
  open: boolean;
  visible: boolean;
  onToggleCardOpen: () => void;
  taskId: string;
};

const ToggleCardOpen = ({ open, visible, onToggleCardOpen, taskId }: ToggleCardOpenProps) =>
  !visible ? null : (
    <Button displayType="noStyles" onClick={onToggleCardOpen} testTag={`toggle-${taskId}`}>
      <Icon
        icon={`arrow${open ? 'Up' : 'Down'}`}
        strokeWidth={2}
        color="gray5"
        cssColor={colors.gray5.hex}
      />
    </Button>
  );

const UserBreakdown = ({
  label,
  users,
  taskId,
  toggleTestTag,
}: {
  label: string;
  users: ImmutableList<User>;
  taskId: string;
  toggleTestTag: string;
}) => {
  const [open, toggleOpened] = useState(false);
  const visibleUsers = users.slice(0, 3);
  const overflowUsers = users.slice(3);

  return (
    <>
      <Flex align="center">
        <Text minWidth="150px">
          {label} ({users.size}):
        </Text>
        <Flex direction="column">
          <Flex align="center" minHeight="32px" justify="end">
            <Spacer horizontal size={8} />
            {visibleUsers.map((user) => (
              <Fragment key={user.id}>
                <UserAvatar
                  name={user.displayName}
                  backgroundColor={user.avatarColor || userColors[0]}
                />
                <Spacer horizontal />
              </Fragment>
            ))}
            {!overflowUsers.isEmpty() && (
              <Fragment key={`${taskId}-completed-overflow`}>
                <UserAvatar
                  name={`+${overflowUsers.size}`}
                  nameList={overflowUsers.map((s) => s.displayName)}
                  backgroundColor={basicColors.black}
                  overflow
                />
                <Spacer horizontal />
              </Fragment>
            )}
            {!users.isEmpty() && (
              <Button
                displayType="link"
                color="black"
                onClick={() => toggleOpened(!open)}
                icon={open ? 'arrowUp' : 'arrowDown'}
                iconPosition="right"
                iconSize="1em"
                testTag={toggleTestTag}
              >
                {open ? 'Collapse' : 'Show All'}
              </Button>
            )}
          </Flex>
        </Flex>
      </Flex>

      {open && (
        <Flex direction="column" padding={`0 ${paddings[3]}`}>
          {users.map((user) => (
            <Fragment key={user.id}>
              <Flex align="center">
                <UserAvatar
                  name={user.displayName}
                  backgroundColor={user.avatarColor || userColors[0]}
                />

                <Spacer horizontal size={2} />
                <Text>{formatName(user)}</Text>
              </Flex>
              <Spacer size={2} />
            </Fragment>
          ))}
        </Flex>
      )}
    </>
  );
};

const TaskIcon = ({ icon }: { icon: IconType }) => (
  <Flex
    backgroundColor={colors.backgroundLight.hex}
    borderRadius={borderRadii[2]}
    align="center"
    justify="center"
    minWidth="68px"
    height="68px"
    testTag="large-cover-image-placeholder"
  >
    <Flex
      align="center"
      justify="center"
      backgroundColor={colors.white.hex}
      width="52px"
      height="52px"
      borderRadius={borderRadii.rounded}
    >
      <Icon icon={icon} size="26px" color="readleeBlue" />
    </Flex>
  </Flex>
);

export const TaskCard = ({
  taskDetails,
  completedStudents,
  incompleteStudents,
  open,
  onToggleCardOpen,
}: {
  taskDetails: TaskDetails;
  completedStudents: ImmutableList<User>;
  incompleteStudents: ImmutableList<User>;
  open: boolean;
  onToggleCardOpen: () => void;
}) => {
  const query = useQuery();
  const pathname = useLocation().pathname;
  const onTaskOverviewPage = Boolean(matchPath(pathname, '/t/assignments/task'));
  const linkToTask = `/t/assignments/task/${taskDetails.type}/${taskDetails.task.id}${updateQuery(
    query,
    { backPath: `${pathname}${queryMapToString(query)}` },
  )}`;

  const BottomContent = () => {
    if (taskDetails.type === 'ModelTask') {
      return (
        <>
          <Text color="gray7">Listen To Model Reading</Text>
          <Spacer size={2} />
          <Flex>
            <AudioPlayer
              audioFileId={taskDetails.audioFile?.id || ''}
              src={taskDetails.audioFile?.url || ''}
            />
          </Flex>
        </>
      );
    } else if (taskDetails.type === 'ComprehensionTask' && taskDetails.questionAudioFile) {
      return (
        <>
          <Text color="gray7">Listen To {taskDetails.task.name}</Text>
          <Spacer size={2} />
          <Flex>
            <AudioPlayer
              testTag="play-question"
              src={taskDetails.questionAudioFile.url}
              audioFileId={taskDetails.questionAudioFile.id}
            />
          </Flex>
        </>
      );
    } else if (taskDetails.type === 'ComprehensionTask' && !taskDetails.questionAudioFile) {
      return (
        <>
          <Text color="gray7">{taskDetails.task.name}</Text>
          <Spacer size={2} />
          <Text>{taskDetails.task.writtenContent}</Text>
        </>
      );
    } else if (taskDetails.type === 'VocabTask') {
      return (
        <Flex justify="space-between">
          <Flex direction="column">
            <Flex align="center">
              <Text variant="h4">{taskDetails.task.customWord}</Text>
              <VocabAudio word={taskDetails.word} task={taskDetails.task} />
            </Flex>
            <Spacer size={2} />
            <VocabDefinition task={taskDetails.task} />
          </Flex>
          <VocabImage
            imageFile={taskDetails.imageFile}
            task={taskDetails.task}
            compactLayout={false}
          />
        </Flex>
      );
    } else return null;
  };

  const hasBottomContent = taskDetails.type !== 'ReadingTask';

  return (
    <TaskCardStyles data-test-tag={`task-card-${taskDetails.task.id}`}>
      <Flex direction="column" width="100%">
        <Flex align="stretch" width="100%" padding={paddings[5]}>
          <TaskIcon icon={getTaskIcon(taskDetails.task)} />
          <Spacer horizontal size={3} />
          <Flex direction="column" width="100%">
            <Flex>
              <Button
                displayType="noStyles"
                disabled={onTaskOverviewPage}
                to={linkToTask}
                disabledOpacityOverride="1"
              >
                <Text variant="h3">
                  {taskDetails.type === 'VocabTask' && 'Word: '}
                  {taskDetails.task.name}
                </Text>
              </Button>
              {!onTaskOverviewPage && (
                <>
                  <Spacer horizontal size={2} />
                  <LinkButton to={linkToTask}>
                    <Text whiteSpace="nowrap">View Responses</Text>
                  </LinkButton>
                </>
              )}
            </Flex>

            <Spacer size={2} />

            <Flex direction="column">
              <UserBreakdown
                label={taskDetails.type === 'ModelTask' ? 'Listened' : 'Submitted'}
                users={completedStudents.sortBy(formatName)}
                taskId={taskDetails.task.id}
                toggleTestTag={`toggle-complete-${taskDetails.task.id}`}
              />
              <Spacer size={2} />
              <UserBreakdown
                label={`Did Not ${taskDetails.type === 'ModelTask' ? 'Listen' : 'Submit'}`}
                users={incompleteStudents.sortBy(formatName)}
                taskId={taskDetails.task.id}
                toggleTestTag={`toggle-incomplete-${taskDetails.task.id}`}
              />
            </Flex>
          </Flex>
          <ToggleCardOpen
            visible={hasBottomContent}
            open={open}
            onToggleCardOpen={onToggleCardOpen}
            taskId={taskDetails.task.id}
          />
        </Flex>

        {hasBottomContent && open && (
          <>
            <Divider size={1} noMargin />
            <Flex margin={`${margins[5]} ${margins[10]}`} direction="column">
              <BottomContent />
            </Flex>
          </>
        )}
      </Flex>
    </TaskCardStyles>
  );
};
