import { useState, useEffect, useCallback } from 'react';
import { useToaster, Button, StandardModal, Spacer, Loading, FormGroup } from 'src/components';
import { ImmutableList, ImmutableMap } from 'src/modules/Immutable';
import {
  closeMediaStream,
  getCurrentMicrophone,
  openMediaStream,
  setCurrentMicrophone,
} from 'src/modules/AudioRecording';

type MicrophoneSettingsProps = {
  opened: boolean;
  close: () => void;
};

export const MicrophoneSettings = ({ opened, close }: MicrophoneSettingsProps) => {
  const { createToast } = useToaster();
  const [loading, setLoading] = useState(true);
  const [devices, setDevices] = useState<ImmutableList<MediaDeviceInfo>>(ImmutableList());
  const [selectedDeviceId, setSelectedDeviceId] = useState<string | undefined>(undefined);
  const [error, setError] = useState('');
  useEffect(() => {
    const doStuff = async () => {
      try {
        const mediaStream = await openMediaStream();
        const foundDevices = await navigator.mediaDevices.enumerateDevices();
        closeMediaStream(mediaStream);

        setDevices(ImmutableList(foundDevices));
        setSelectedDeviceId(getCurrentMicrophone() || '');
        setLoading(false);
      } catch {
        createToast({ children: 'Failed to load your audio devices!', color: 'danger' });
      }
    };
    if (opened) {
      doStuff();
    }
  }, [opened, createToast]);

  const save = useCallback(() => {
    if (selectedDeviceId) {
      setError('');
      setCurrentMicrophone(selectedDeviceId);
      createToast({ children: 'Your microphone has been changed!' });
      close();
    } else {
      setError('You must select a microphone to save!');
    }
  }, [selectedDeviceId, createToast, close]);

  return (
    <StandardModal
      openType="state"
      opened={opened}
      header="Microphone Settings"
      close={close}
      disableFadeClose
      footer={
        <>
          <Button onClick={close} inheritBgColor displayType="link" color="primaryBlue">
            Cancel
          </Button>
          <Spacer horizontal size={3} />
          <Button onClick={save}>Save</Button>
        </>
      }
    >
      {loading ? (
        <Loading flex />
      ) : (
        <FormGroup
          name="deviceId"
          label="Select Microphone"
          type="select"
          value={selectedDeviceId}
          onChange={setSelectedDeviceId}
          errors={ImmutableMap({ deviceId: [error] })}
          options={devices
            .filter((device) => device.kind === 'audioinput')
            .map((device) => ({
              value: device.deviceId,
              label: device.label,
            }))
            .unshift({ value: '', label: 'No device selected' })}
        />
      )}
    </StandardModal>
  );
};
