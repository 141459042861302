import { subDataCreator } from 'src/State';
import { defaultState, StudentProgressMonitoringResponse } from './StudentProgressMonitoringState';
// import { TimeLabel } from 'src/components';
import { ImmutableMap } from 'src/modules/Immutable';
import * as Storage from 'src/modules/Storage';
import { getSeason, Season } from 'src/modules/Time';

export const createDefaultStudentProgressMonitoringData = subDataCreator(
  'StudentProgressMonitoringData',
  defaultState,
  ({ set, get, setField, setFieldWithFunction, fetchJson }) => ({
    setField,
    clearCache: () => set({ monitoringCache: ImmutableMap() }),
    load: (id, startTime, endTime, prevStartTime, prevEndTime, dateRange) => {
      const { monitoringCache, user } = get();

      if (user?.id !== id) {
        set({ monitoringCache: ImmutableMap() });
      } else {
        const cachedResponse = monitoringCache.get(id)?.get(dateRange);
        if (cachedResponse) {
          set({ ...cachedResponse });
          return;
        }
      }

      set({ loading: true });
      fetchJson(`/api/users/${id}/progress_monitoring`, {
        data: { startTime, endTime, prevStartTime, prevEndTime },
        onSuccess: (data: StudentProgressMonitoringResponse) => {
          // Caching has been messing people up more and more, let's incur the cost to see if people complain
          // const updatedMonitoringCache = monitoringCache.update(id, (dateCache) =>
          //   dateCache
          //     ? dateCache.set(dateRange, data)
          //     : ImmutableMap<TimeLabel, StudentProgressMonitoringResponse>(),
          // );

          const storedGradeLevelStr = Storage.getItem(
            `progress-monitoring-gradeLevel-${data.user.id}`,
          );
          const storedGradeLevel = storedGradeLevelStr ? parseInt(storedGradeLevelStr, 10) : null;
          const storedSeason = Storage.getItem(
            `progress-monitoring-season-${data.user.id}`,
          ) as Season | null;

          set({
            ...data,
            gradeLevel:
              typeof storedGradeLevel === 'number' && !Number.isNaN(storedGradeLevel)
                ? storedGradeLevel
                : Math.min(
                    data.gradeLevelMap.filter((v) => v).maxBy((v) => v.level)?.level ?? 1,
                    6,
                  ),
            season: storedSeason ?? getSeason(),
            // monitoringCache: updatedMonitoringCache,
            loading: false,
          });
        },
      });
    },

    setWcpmTooltipProps: setFieldWithFunction('wcpmTooltipProps'),
    setWcpmTooltipData: setField('wcpmTooltipData'),
    setReadingTooltipProps: setFieldWithFunction('readingTooltipProps'),
    setReadingTooltipData: setField('readingTooltipData'),
  }),
);
