import { Fragment } from 'react';
import { Tooltip, Button, Flex, Spacer } from 'src/components';
import Check from 'src/assets/emojis/check.png';
import Clapping from 'src/assets/emojis/clapping.png';
import OneHundred from 'src/assets/emojis/one_hundred.png';
import Rocket from 'src/assets/emojis/rocket.png';
import ThinkingFace from 'src/assets/emojis/thinking_face.png';
import Parrot from 'src/assets/emojis/parrot.gif';
import { ImmutableMap } from 'src/modules/Immutable';
import { CommentReaction } from 'src/models/Comment';
import { paddings } from 'src/styles';

const reactions = ImmutableMap<CommentReaction, { value: CommentReaction; src: string }>({
  check: { value: 'check', src: Check },
  clapping: { value: 'clapping', src: Clapping },
  one_hundred: { value: 'one_hundred', src: OneHundred },
  rocket: { value: 'rocket', src: Rocket },
  thinking: { value: 'thinking', src: ThinkingFace },
  parrot: { value: 'parrot', src: Parrot },
});

type ReactionProps = { kind: CommentReaction; size?: 'small' | 'large' };

export const Reaction = ({ kind, size = 'small' }: ReactionProps) => {
  const reaction = reactions.get(kind);
  if (!reaction) return null;

  const sizeToUse = size === 'small' ? '24px' : '64px';
  return (
    <img
      data-test-tag={`reaction-${kind}`}
      src={reaction.src}
      style={{ height: sizeToUse, width: sizeToUse }}
    />
  );
};

const sharedProps = {
  paddingOverride: paddings.none,
  displayType: 'iconOnly',
  width: '36px',
  height: '36px',
  color: 'gray2',
  iconColor: 'black',
  iconSize: '24px',
} as const;

type ReactionButtonProps = {
  onClick: (reaction: CommentReaction) => void;
  loading: boolean;
};

export const ReactionButton = ({ onClick, loading }: ReactionButtonProps) => {
  return (
    <Tooltip
      content={
        <Flex justify="space-around">
          {reactions.toList().map((reaction) => (
            <Fragment key={reaction.value}>
              <Spacer horizontal />
              <Button
                {...sharedProps}
                onClick={() => onClick(reaction.value)}
                testTag={`reaction-${reaction.value}`}
              >
                <Reaction kind={reaction.value} />
              </Button>
              <Spacer horizontal />
            </Fragment>
          ))}
        </Flex>
      }
    >
      <Button color="primaryBlue" icon="sticker" loading={loading}>
        React
      </Button>
    </Tooltip>
  );
};
