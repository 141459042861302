import { useLocation, useHistory, Redirect } from 'react-router-dom';
import { AccountType } from 'src/models';
import { ImmutableMap } from 'src/modules/Immutable';
import { useStore } from 'src/Store';

export { useHistory, useLocation, matchPath } from 'react-router-dom';
export { useParams } from 'react-router-dom';
export type History = ReturnType<typeof useHistory>;
export type Location = ReturnType<typeof useLocation>;

export type QueryMap = ImmutableMap<string, string>;

const queryCache = new Map<string, QueryMap>();

export const useQuery = (): QueryMap => {
  const location = useLocation();
  const queryString = location.search;
  const cached = queryCache.get(queryString);
  if (cached) return cached;

  const value = ImmutableMap<string, string>(new URLSearchParams(queryString).entries());
  queryCache.set(queryString, value);
  return value;
};

export const queryMapToString = (queryMap: QueryMap | Record<string, string>): string => {
  const entries =
    typeof queryMap.entries === 'function' ? queryMap.entries() : Object.entries(queryMap);
  const str = new URLSearchParams(Array.from(entries)).toString();
  if (str.length > 0) return `?${str}`;
  return '';
};

type RedirectIfAccountTypeProps = {
  accountTypes: AccountType[];
};

export const RedirectHomeOnAccountType = ({ accountTypes = [] }: RedirectIfAccountTypeProps) => {
  const { currentUser, loading } = useStore(
    (state) => ({
      currentUser: state.AppData.currentUser,
      loading: state.AppData.loading,
    }),
    [],
  );

  if (
    !loading &&
    currentUser &&
    accountTypes.length > 0 &&
    accountTypes.includes(currentUser.accountType)
  ) {
    return <Redirect to="/" />;
  }

  return null;
};

export const updateQuery = (query: QueryMap, updates: Record<string, string | null>) => {
  const newQuery = query.merge(updates).filter(Boolean) as QueryMap;
  return queryMapToString(newQuery);
};
