import {
  AddRecordingResponse,
  Reading,
  ReadingTask,
  TaskDetails,
  ComprehensionQuestionType,
  ComprehensionResponseType,
  ImageStoredFile,
  AssessmentExtended,
  LicenseType,
  InstructionDetails,
  PublishingStatus,
} from 'src/models';
import { ImmutableList, ImmutableMap } from 'src/modules/Immutable';
import { TextRange } from 'src/modules/TextRange';
import { History, QueryMap } from 'src/modules/Router';
import { SetField } from 'src/State';
import { emptyErrorMap, ErrorMap } from 'src/modules/Api';

export type VocabTaskStep = 'word' | 'details';

export type AssignmentBuilderState = {
  loading: boolean;
  assessment: AssessmentExtended | null;
  originalAssessment: AssessmentExtended | null;
  reading: Reading | null;
  taskDetailsMap: ImmutableMap<string, TaskDetails>;
  originalTaskDetailsMap: ImmutableMap<string, TaskDetails>;
  dirty: boolean;
  saving: boolean;
  editingInstructions: boolean;
  editingWrittenInstructions: string;
  editingTitle: boolean;
  errors: ErrorMap;
  taskErrors: ImmutableMap<string, ErrorMap>;
  cancelling: boolean;
  deletingTaskId: string | undefined;
  creatingTask: boolean;
  drawerExpanded: boolean;
  vocabTaskStep: VocabTaskStep;
  vocabWord: string;
  vocabImageFile: ImageStoredFile | null;
  vocabImageFileUrl: string | null;
  uploading: boolean;
  primaryLicenseType: LicenseType | null;
  instructionDetails: InstructionDetails | null;
  cancel: () => void;
  load: (opts: {
    assessmentId: string | null;
    readingId: string | null;
    currentTaskId: string | null;
  }) => void;
  setReadingPartType: (taskId: string, readingPartType: ReadingTask['readingPartType']) => void;
  setTextRange: (taskId: string) => (textRange: TextRange, text: string) => void;
  submit: (opts: {
    history: History;
    query: QueryMap;
    taskOrder?: AssessmentExtended['taskOrder'];
  }) => void;
  cancelTask: (currentTaskId?: string) => void;
  deleteTask: (currentTaskId: string, query: QueryMap, history: History) => void;
  setStartPage: (taskId: string, value: number) => void;
  setEndPage: (taskId: string, value: number) => void;
  setField: SetField<AssignmentBuilderState>;
  setAssessmentField: SetField<AssessmentExtended>;
  createComprehensionTask: (history: History) => void;
  createModelReading: (history: History) => void;
  createVocabTask: (history: History) => void;
  setQuestionType: (taskId: string, questionType: ComprehensionQuestionType) => void;
  setResponseType: (taskId: string, responseType: ComprehensionResponseType) => void;
  setWrittenContent: (taskId: string, writtenContent: string) => void;
  onRecordingSequenceStatus: (data: AddRecordingResponse) => unknown;
  onInstructionsRecordingSequenceStatus: (data: AddRecordingResponse) => unknown;
  saveTask: (opts: { taskDetails: TaskDetails; history: History; query: QueryMap }) => unknown;
  finishCancel: (opts: { history: History; query: QueryMap }) => void;
  togglePublishingStatus: (isPaperUser: boolean) => void;
  updatePublishingStatus: () => void;
  updateSampleStatus: (isSample: boolean) => void;
  createFreeRead: (history: History) => void;
  saveWord: (taskId: string) => void;
  setDefinition: (taskId: string, definition: string) => void;
  setCustomWord: (taskId: string, customWord: string) => void;
  setWordContent: (taskId: string, customWord: string) => void;
  setVocabImage: (taskId: string, imageFileId: ImageStoredFile | null) => void;
  setVocabWord: (word: string) => void;
  uploadVocabImage: (taskId: string) => (files: ImmutableList<File>) => void;
  freeReadLoading: boolean;
  upgradeAssessmentAndStartVocab: (history: History) => () => void;
  sharingOpen: boolean;
  editingPublishingStatus: PublishingStatus;
  startSharing: () => void;
};

export const defaultState = {
  loading: true,
  freeReadLoading: false,
  assessment: null,
  originalAssessment: null,
  reading: null,
  dirty: false,
  saving: false,
  taskDetailsMap: ImmutableMap<string, TaskDetails>(),
  originalTaskDetailsMap: ImmutableMap<string, TaskDetails>(),
  editingInstructions: false,
  editingWrittenInstructions: '',
  editingTitle: false,
  errors: emptyErrorMap(),
  taskErrors: ImmutableMap<string, ErrorMap>(),
  cancelling: false,
  deletingTaskId: undefined,
  creatingTask: false,
  drawerExpanded: false,
  vocabTaskStep: 'word',
  vocabWord: '',
  vocabImageFile: null,
  vocabImageFileUrl: '',
  uploading: false,
  primaryLicenseType: null,
  instructionDetails: null,
  sharingOpen: false,
  editingPublishingStatus: 'private',
} as const;
