import { ImmutableList } from 'src/modules/Immutable';
import { useStore } from 'src/Store';
import { assessmentIsShared } from 'src/models';
import { Button, StandardModal, FormGroup, Flex, Text, Spacer } from 'src/components';

const EXPLANATIONS = {
  private: 'Your assignment will not be shared with other teachers.',
  school: 'Your assignment will be shared with all teachers in your school.',
  district: 'Your assignment will be shared with all teachers in your district.',
  public_preview: 'Your assignment will be shared with all other teachers.',
};

export const SharingModal = () => {
  const {
    sharingOpen,
    setField,
    editingPublishingStatus,
    updatePublishingStatus,
    togglePublishingStatus,
    currentUser,
  } = useStore(
    ({ AssignmentBuilderData: s, AppData: a }) => ({
      sharingOpen: s.sharingOpen,
      setField: s.setField,
      editingPublishingStatus: s.editingPublishingStatus,
      updatePublishingStatus: s.updatePublishingStatus,
      togglePublishingStatus: s.togglePublishingStatus,
      currentUser: a.currentUser,
    }),
    [],
  );

  return (
    <StandardModal
      openType="state"
      opened={sharingOpen}
      header="Assignment Sharing Settings"
      close={() => setField('sharingOpen')(false)}
      disableFadeClose
      footer={
        <Button onClick={updatePublishingStatus} testTag="sharingSaveButton">
          {assessmentIsShared(editingPublishingStatus)
            ? 'Share with teachers'
            : 'Do not share with teachers'}
        </Button>
      }
    >
      <Flex align="center" justify="center">
        <Text variant="h5">Share assignment with other teachers?</Text>
        <Spacer size={2} horizontal />
        <FormGroup
          flex
          type="toggle"
          name="toggleSharing"
          value={assessmentIsShared(editingPublishingStatus)}
          onChange={() => togglePublishingStatus(Boolean(currentUser?.paperId))}
          width="auto"
        />
      </Flex>
      {assessmentIsShared(editingPublishingStatus) && (
        <>
          <Spacer size={4} />
          <FormGroup
            name="publishingType"
            type="select"
            value={editingPublishingStatus}
            onChange={setField('editingPublishingStatus')}
            options={ImmutableList([
              { value: 'school', label: 'Sharing with my School' },
              { value: 'district', label: 'Sharing with my District' },
              { value: 'public_preview', label: 'Sharing with Everyone' },
            ])}
          />
        </>
      )}
      <Spacer size={6} />
      <Text variant="h6" color="paperBlue">
        {EXPLANATIONS[editingPublishingStatus]}
      </Text>
    </StandardModal>
  );
};
