import { createConsumer } from '@rails/actioncable';
import { appUrl } from 'src/config';

export const consumer = createConsumer(`${appUrl}/api/cable`);

export const resetSocketConnection = () => {
  // Disconnecting and reconnecting re-establishes the connection,
  // which is needed to authenticate a differnet user, and re-creates
  // the subscriptions
  consumer.disconnect();
  consumer.connect();
};

const resetInSeconds = 5000; // gcp load balancer kills connections after 6000 seconds, refresh a bit early
setInterval(() => {
  resetSocketConnection();
}, resetInSeconds * 1000);
