import { Button, Flex, Spacer, Text } from 'src/components';
import { useUniqueId } from 'src/modules/UniqueId';

export type TextSizeInputOnChange = (type: number) => void;

export type TextSizeInputProps = {
  id?: string;
  value: number;
  onChange: TextSizeInputOnChange;
};

const MaxSize = 48;
const MinSize = 12;

export const TextSizeInput = ({ id: propId, value, onChange }: TextSizeInputProps) => {
  const id = useUniqueId(propId);
  const decreaseFont = () => {
    onChange(value - 1);
  };
  const increaseFont = () => {
    onChange(value + 1);
  };

  return (
    <div id={id}>
      <Flex height="75px" align="center">
        <Text sizeOverride={value} lineHeightOverride="100%">
          Example Text Size
        </Text>
      </Flex>
      <Spacer size={2} />
      <Flex>
        <Button
          display="flex"
          justify="center"
          align="center"
          width="40px"
          height="40px"
          onClick={decreaseFont}
          disabled={value <= MinSize}
          displayType="outline"
        >
          <Text color="primaryBlue" variant="h1">
            -
          </Text>
        </Button>
        <Spacer size={4} horizontal />
        <Button
          display="flex"
          justify="center"
          align="center"
          width="40px"
          height="40px"
          onClick={increaseFont}
          disabled={value >= MaxSize}
          displayType="outline"
        >
          <Text color="primaryBlue" variant="h1">
            +
          </Text>
        </Button>
      </Flex>
    </div>
  );
};
