import { CreateToast } from 'src/components';
import {
  ContentType,
  ImageStoredFile,
  PdfStoredFile,
  Reading,
  TextStoredFile,
  GradeLevel,
  Tag,
  LicenseType,
  PdfByPageStoredFile,
} from 'src/models';
import { Playlist } from 'src/models/Playlist';
import { emptyErrorMap, ErrorMap } from 'src/modules/Api';
import { ImmutableList } from 'src/modules/Immutable';
import { DEFAULT_LANGUAGE_CODE, LanguageCode } from 'src/modules/LanguageCodes';
import { History, QueryMap } from 'src/modules/Router';
import { SetField } from 'src/State';

export type ReadingBuilderState = {
  loading: boolean;
  submitting: boolean;
  contentType: ContentType;
  reading: Reading | null;
  uploadedPdfFile: File | null;
  basicText: string;
  pdfFile: PdfStoredFile | PdfByPageStoredFile | null;
  pdfFileUrl: string | null;
  pdfRotation: number;
  coverImage: File | null;
  coverImageFile: ImageStoredFile | null;
  coverImageFileUrl: string | null;
  basicTextFile: TextStoredFile | null;
  name: string;
  authorName: string;
  assessmentId: string;
  readingId: string;
  gradeLevelId?: string;
  isEll: boolean;
  isSpecialEducation: boolean;
  errors: ErrorMap;
  uploadingPdf: boolean;
  webModalOpen: boolean;
  webSourceUrl: string;
  editingWebSourceUrl: string;
  scrapingWebSourceUrl: boolean;
  scrapeErrors: ErrorMap;
  selectedLicenseType: LicenseType;
  gradeLevels: ImmutableList<GradeLevel>;
  tagNameList: ImmutableList<string>;
  tags: ImmutableList<Tag>;
  languageCode: LanguageCode;
  newTagName: string;
  selectedPlaylistIds: ImmutableList<string>;
  playlists: ImmutableList<Playlist>;
  primaryLicenseType: LicenseType | null;
  pdfTextDirty: boolean;
  peekPdf: boolean;
  setField: SetField<ReadingBuilderState>;
  rotatePdf: () => void;
  load: (opts: { assessmentId: string; readingId: string; isPaperUser: boolean }) => void;
  submitForProcessing: (history: History, query: QueryMap) => () => void;
  submit: (opts: { history: History; query: QueryMap }) => () => void;
  uploadPdfFile: (files: ImmutableList<File>) => void;
  createRotatedPdf: (onSuccess: () => void) => void;
  onPdfProcessingStatus: (data: { storedFile: PdfStoredFile | PdfByPageStoredFile }) => void;
  goToAssignmentBuilder: (opts: { history: History }) => void;
  fetchArticle: () => void;
  toggleSharing: (isPaperUser: boolean) => () => void;
  openWebSourceUrlModal: () => void;
  adminUpdate: (history: History, query: QueryMap, createToast: CreateToast) => () => void;
  removeTag: (tagName: string) => () => void;
  addTag: () => void;
  setPdfPageText: (page: number, pageText: string) => void;
  overridePdfJsonText: (opts: { history: History; query: QueryMap }) => () => void;
};

export const defaultState = {
  loading: true,
  submitting: false,
  contentType: 'text',
  reading: null,
  uploadedPdfFile: null,
  basicText: '',
  pdfFile: null,
  pdfFileUrl: null,
  pdfRotation: 0,
  coverImage: null,
  coverImageFile: null,
  coverImageFileUrl: '',
  basicTextFile: null,
  name: '',
  authorName: '',
  assessmentId: '',
  readingId: '',
  errors: emptyErrorMap(),
  scrapeErrors: emptyErrorMap(),
  uploadingPdf: false,
  webModalOpen: false,
  scrapingWebSourceUrl: false,
  webSourceUrl: '',
  editingWebSourceUrl: '',
  selectedLicenseType: 'open_source_needs_review',
  gradeLevels: ImmutableList<GradeLevel>(),
  tags: ImmutableList<Tag>(),
  languageCode: DEFAULT_LANGUAGE_CODE,
  tagNameList: ImmutableList<string>(),
  newTagName: '',
  selectedPlaylistIds: ImmutableList<string>(),
  playlists: ImmutableList<Playlist>(),
  primaryLicenseType: null,
  isEll: false,
  isSpecialEducation: false,
  pdfTextDirty: false,
  peekPdf: false,
} as const;
