import { ComprehensionResponseType, ComprehensionTaskDetails } from './ComprehensionTask';
import { RecordingSequence } from './RecordingSequence';
import { TranscriptableAudioStoredFile } from './TranscriptableAudioStoredFile';

export type ComprehensionTaskSubmissionResponseType =
  | 'undecided'
  | Exclude<ComprehensionResponseType, 'student_choice'>;

export type ComprehensionTaskSubmission = {
  id: string;
  comprehensionTaskId: string;
  recordingSequenceId: string;
  submissionId: string;
  responseType: ComprehensionTaskSubmissionResponseType;
  responseContent: string;
  type: 'ComprehensionTaskSubmission';
};

export type ComprehensionTaskSubmissionDetails = ComprehensionTaskDetails & {
  taskSubmission: ComprehensionTaskSubmission;
  responseRecordingSequence: RecordingSequence;
  responseAudioFile: TranscriptableAudioStoredFile | null;
};
