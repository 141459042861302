import { Assessment } from 'src/models';
import { subDataCreator } from 'src/State';
import { defaultState } from './UpdateAssignmentStatusModalState';

export const createDefaultUpdateAssignmentStatusModalData = subDataCreator(
  'UpdateAssignmentStatusModalData',
  defaultState,
  ({ fetchJson, get, set, setField, getFull }) => ({
    openStatusUpdateModal: (assessment, action) =>
      set({ assessment, action, opened: true, confirmDeleteText: '' }),
    confirmUpdateAssignmentStatus: (history, createToast, onSuccess) => () => {
      const { assessment, action, getActionName } = get();
      if (!assessment) return;

      const getStatus = (): Assessment['status'] => {
        if (action === 'toggleArchive') {
          return assessment?.status === 'active' ? 'archived' : 'active';
        }

        return 'deleted';
      };

      fetchJson(`/api/assessments/${assessment.id}/simple_update`, {
        method: 'PATCH',
        data: { status: getStatus() },
        onSuccess: ({ assessment }: { assessment: Assessment }) => {
          history.push('/t/assignments');
          createToast({ children: `Assessment ${getActionName()}d!`, color: 'success' });
          set({ opened: false });
          getFull().ProgressMonitoringData.clearCache();
          getFull().StudentProgressMonitoringData.clearCache();
          onSuccess?.(assessment);
        },
      });
    },
    getActionName: () => {
      const { assessment, action } = get();
      if (action === 'toggleArchive') {
        return assessment?.status === 'active' ? 'archive' : 'restore';
      }

      return 'delete';
    },
    setField,
  }),
);
