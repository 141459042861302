import { subDataCreator } from 'src/State';
import {
  AssessmentExtended,
  Submission,
  TaskSubmissionDetails,
  ComprehensionTaskSubmission,
  isTaskSubmissionCompleted,
  DuplicateAssessmentData,
  ImageStoredFile,
  emptyExtendedUser,
  InstructionDetails,
} from 'src/models';
import { defaultState, TurnInResponse } from './ReadingStudioState';
import { ImmutableMap } from 'src/modules/Immutable';
import { updateQuery } from 'src/modules/Router';
import { trackAssessmentSubmitted, trackRecording } from 'src/modules/Analytics/AnalyticsEvents';

type ReadingStudioResponse = {
  assessment: AssessmentExtended | null;
  submission: Submission | null;
  taskSubmissionDetailsMap: ImmutableMap<string, TaskSubmissionDetails>;
  instructionDetails: InstructionDetails;
  currentTaskId: string | null;
  profileImageFile: ImageStoredFile | null;
};

export const createDefaultReadingStudioData = subDataCreator(
  'ReadingStudioData',
  defaultState,
  ({ set, get, setFull, setField, fetchJson }) => {
    const setComprehensionTaskField =
      <K extends keyof ComprehensionTaskSubmission>(key: K) =>
      (value: ComprehensionTaskSubmission[K]) => {
        set((state) => {
          const taskSubmissionDetails =
            state.currentTaskId && state.taskSubmissionDetailsMap.get(state.currentTaskId);
          if (!taskSubmissionDetails || taskSubmissionDetails.type !== 'ComprehensionTask')
            return state;
          return {
            ...state,
            dirty: true,
            taskSubmissionDetailsMap: state.taskSubmissionDetailsMap.set(
              taskSubmissionDetails.task.id,
              {
                ...taskSubmissionDetails,
                taskSubmission: {
                  ...taskSubmissionDetails.taskSubmission,
                  [key]: value,
                },
              },
            ),
          };
        });
      };

    const setTaskField =
      <T extends TaskSubmissionDetails>(taskSubmissionDetails: T) =>
      <K extends keyof T['taskSubmission']>(key: K) =>
      (value: T['taskSubmission'][K]) => {
        set((state) => {
          return {
            ...state,
            dirty: true,
            taskSubmissionDetailsMap: state.taskSubmissionDetailsMap.set(
              taskSubmissionDetails.task.id,
              {
                ...taskSubmissionDetails,
                taskSubmission: {
                  ...taskSubmissionDetails.taskSubmission,
                  [key]: value,
                },
                // Typescript is just not smart enough to recognize that this is type safe
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
              } as any,
            ),
          };
        });
      };

    return {
      setField,
      load: ({ assessmentId, isPublicPreview }) => {
        set(defaultState);
        fetchJson(`/api/readings/studio`, {
          data: { assessmentId, isPublicPreview },
          onSuccess: (data: ReadingStudioResponse) => {
            set({
              assessment: data.assessment,
              taskSubmissionDetailsMap: data.taskSubmissionDetailsMap,
              instructionDetails: data.instructionDetails,
              submission: data.submission,
              rating: data.submission?.rating ?? null,
              profileImageFile: data.profileImageFile,
              loading: false,
            });
          },
        });
      },
      setResponseType: setComprehensionTaskField('responseType'),
      setResponseContent: setComprehensionTaskField('responseContent'),
      onRecordingSequenceStatus: (data) => {
        const { taskSubmissionDetailsMap, submit, assessment } = get();
        const taskSubmissionDetails = taskSubmissionDetailsMap.find(
          (taskSubmissionDetails) =>
            taskSubmissionDetails.type === 'ReadingTask' &&
            taskSubmissionDetails.recordingSequence.id === data.recordingSequence.id,
        );
        if (!taskSubmissionDetails || taskSubmissionDetails.type !== 'ReadingTask' || !assessment)
          return;

        if (data.recordingSequence.processingStatus === 'finished') {
          trackRecording({
            assessmentId: assessment.id,
            taskId: taskSubmissionDetails.task.id,
            taskType: taskSubmissionDetails.task.type,
            durationInMilliseconds: String(data.recordingSequence.duration),
            kind: taskSubmissionDetails.recordingSequence.duration > 0 ? 'extension' : 'new',
          });
        }

        set({
          taskSubmissionDetailsMap: taskSubmissionDetailsMap.set(taskSubmissionDetails.task.id, {
            ...taskSubmissionDetails,
            audioFile: data.audioFile,
            recordingSequence: data.recordingSequence,
            taskSubmission: {
              ...taskSubmissionDetails.taskSubmission,
              pointsEarned: data.pointsEarned,
            },
          }),
        });

        setFull((state) => ({
          ...state,
          AppData: {
            ...state.AppData,
            currentUser: {
              ...(state.AppData.currentUser || emptyExtendedUser),
              pointBalance: data.pointBalance,
            },
          },
        }));

        if (data.recordingSequence.processingStatus === 'finished') {
          submit({});
        }
      },
      onResponseRecordingSequenceStatus: (data) => {
        const { taskSubmissionDetailsMap, assessment, submit } = get();
        const taskSubmissionDetails = taskSubmissionDetailsMap.find(
          (taskSubmissionDetails) =>
            taskSubmissionDetails.type === 'ComprehensionTask' &&
            taskSubmissionDetails.responseRecordingSequence.id === data.recordingSequence.id,
        );
        if (
          !taskSubmissionDetails ||
          taskSubmissionDetails.type !== 'ComprehensionTask' ||
          !assessment
        )
          return;
        set({
          taskSubmissionDetailsMap: taskSubmissionDetailsMap.set(taskSubmissionDetails.task.id, {
            ...taskSubmissionDetails,
            responseAudioFile: data.audioFile,
            responseRecordingSequence: data.recordingSequence,
          }),
        });
        if (data.recordingSequence.processingStatus === 'finished') {
          trackRecording({
            assessmentId: assessment.id,
            taskId: taskSubmissionDetails.task.id,
            taskType: taskSubmissionDetails.task.type,
            durationInMilliseconds: String(data.recordingSequence.duration),
            kind:
              taskSubmissionDetails.responseRecordingSequence.duration > 0 ? 'extension' : 'new',
          });
          submit({});
        }
      },
      onCompleteTask: (taskSubmissionDetails) => {
        const { submit } = get();
        if (
          taskSubmissionDetails &&
          (taskSubmissionDetails.type === 'ModelTask' ||
            taskSubmissionDetails.type === 'VocabTask') &&
          !taskSubmissionDetails.taskSubmission.completed
        ) {
          setTaskField(taskSubmissionDetails)('completed')(true);
          submit({ clearDirty: true });
        }
      },
      submitRating: (rate: number) => {
        const { submit } = get();
        set({ rating: rate });
        submit({ finished: true, override: true, clearDirty: true });
      },
      onAudioRecorderStatus: (audioRecorderStatus) => {
        if (audioRecorderStatus === 'finished') set({ dirty: true });
        set({ audioRecorderStatus });
      },
      setCurrentTask: (taskId) => {
        set((state) => ({ ...state, currentTaskId: taskId, drawerExpanded: false }));
      },
      submit: ({ finished = false, onSuccess, override = false, clearDirty = false }) => {
        const { submission, taskSubmissionDetailsMap, assessment, rating } = get();
        if (!submission || !assessment) return;
        const allDone = assessment?.taskOrder?.every((to) => {
          const taskSubmissionDetails = taskSubmissionDetailsMap.get(to.taskId);
          if (!taskSubmissionDetails) return true;
          if (assessment.isFreeRead && taskSubmissionDetails.type !== 'ReadingTask') return true;
          return isTaskSubmissionCompleted(taskSubmissionDetails);
        });
        if (finished && !override && !allDone) {
          set({ confirmingFinished: true });
          return;
        }

        set({ submitting: true, dirty: !clearDirty });
        fetchJson(`/api/submissions/${submission.id}`, {
          method: 'PATCH',
          data: {
            submission: {
              status: finished ? 'finished' : 'started',
              rating,
            },
            taskSubmissionDetails: taskSubmissionDetailsMap.toList(),
          },
          onSuccess: (data: TurnInResponse) => {
            set((newState) => ({
              ...newState,
              submitting: false,
              dirty: newState.dirty || !data.success,
              submission: data.submission,
              taskErrors: data.taskErrors.map((errors) => ImmutableMap(errors)),
              ...(data.success
                ? {
                    showFinishedModal: newState.showFinishedModal || finished,
                    confirmingFinished: false,
                  }
                : {}),
            }));

            onSuccess?.(data);
            trackAssessmentSubmitted({
              assessmentId: assessment.id,
              kind: submission.finishedAt ? 'resubmit' : 'submit',
            });
          },
        });
      },
      leave: (backPath, history) => () => {
        const { taskSubmissionDetailsMap, assessment } = get();

        const allDone = assessment?.taskOrder?.every((to) => {
          const taskSubmissionDetails = taskSubmissionDetailsMap.get(to.taskId);
          if (!taskSubmissionDetails) return true;
          if (assessment.isFreeRead && taskSubmissionDetails.type !== 'ReadingTask') return true;
          return isTaskSubmissionCompleted(taskSubmissionDetails);
        });

        if (!allDone) {
          set({ confirmingFinished: true, leaving: true });
          return;
        } else {
          history.push(backPath);
        }
      },
      switchTask:
        ({ taskId, history, query, isPublicPreview }) =>
        () => {
          const { dirty, submit } = get();
          set({ confirmingFinished: false, mobileTaskListOpened: false });
          const doIt = () => {
            history.push(
              `${isPublicPreview ? '/t/public-preview' : '/reading-studio'}${updateQuery(query, {
                currentTaskId: taskId,
              })}`,
            );
          };
          if (dirty) {
            submit({
              clearDirty: true,
              onSuccess: (data) => {
                if (data.success) {
                  doIt();
                }
              },
            });
          } else {
            doIt();
          }
        },
      addToLibrary:
        ({ history, createToast }) =>
        () => {
          const { assessment } = get();
          if (!assessment) return;
          set({ submitting: true });
          fetchJson(`/api/assessments/${assessment.id}/duplicate`, {
            method: 'POST',
            data: { name: assessment.name },
            onSuccess: (data: DuplicateAssessmentData) => {
              if (data.success) {
                history.push(`/t/assignments/${data.assessment.id}`);
                createToast({ children: 'Assignment has copied to your library!' });
              } else {
                createToast({
                  children: 'Unable to add assignment to your library!',
                  color: 'danger',
                });
              }
            },
          });
        },
      toggleMobileTaskList: () =>
        set(({ mobileTaskListOpened: opened }) => ({ mobileTaskListOpened: !opened })),
      openContentModal: () => set({ contentModalOpened: true }),
      closeContentModal: () => set({ contentModalOpened: false }),
      closeConfirmationModal: () => set({ confirmingFinished: false, leaving: false }),
    };
  },
);
