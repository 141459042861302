import { ReactNode } from 'react';
import { Flex, Justify } from 'src/components';
import { paddings } from 'src/styles';

export const ContentContainer = ({
  children,
  justify = 'center',
}: {
  children: ReactNode;
  justify?: Justify;
}) => (
  <Flex padding={paddings[10]} width="100%" justify="center">
    <Flex
      maxWidth="700px"
      width="100%"
      direction="column"
      align="center"
      justify={justify}
      height="100%"
    >
      {children}
    </Flex>
  </Flex>
);
