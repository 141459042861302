import {
  NoStylesButton,
  Flex,
  Text,
  Spacer,
  ClassAvatar,
  Icon,
  Button,
  AvatarPlusButton,
} from 'src/components';
import { useStore } from 'src/Store';
import { borderRadii, colors, margins, paddings } from 'src/styles';
import styled from 'styled-components';
import { compactLayoutBreakpoint, useCompactLayout } from './AssignToModalHelpers';

type ClassButtonProps = {
  $selected: boolean;
  $showCompactLayout: boolean;
};

const ClassButton = styled.div<ClassButtonProps>`
  background: ${({ $selected }) => ($selected ? colors.backgroundLight.hex : colors.white.hex)};
  opacity: ${({ $selected, $showCompactLayout }) => ($selected || !$showCompactLayout ? 100 : 60)}%;
  cursor: pointer;
  margin-bottom: ${margins[2]};
  border: none;
  flex: 1;
  border-radius: ${borderRadii[2]};
  padding: ${paddings[2]};

  @media (min-width: ${compactLayoutBreakpoint}px) {
    padding: ${paddings[3]};
    width: 300px;
  }
`;

export const CourseSelector = () => {
  const {
    courses,
    courseRegistrationAssessments,
    courseAssessmentsAssigned,
    selectedCourse,
    setSelectedCourse,
    currentUser,
  } = useStore(
    (s) => ({
      courses: s.AssignToData.courses,
      courseRegistrationAssessments: s.AssignToData.courseRegistrationAssessments,
      courseAssessmentsAssigned: s.AssignToData.courseAssessmentsAssigned,
      selectedCourse: s.AssignToData.selectedCourse,
      setSelectedCourse: s.AssignToData.setSelectedCourse,
      currentUser: s.AppData.currentUser,
    }),
    [],
  );

  const showCompactLayout = useCompactLayout();

  const getAssignedText = (courseId: string) => {
    const students = courseRegistrationAssessments.get(courseId);

    const assignedCount = students?.filter((student) => student.assigned).size;
    const currentCourseHasCourseAssessment = courseAssessmentsAssigned.some(
      (courseAssessmentData) =>
        courseAssessmentData.courseId === courseId && courseAssessmentData.assigned,
    );
    if (currentCourseHasCourseAssessment && assignedCount === students?.size)
      return 'Assigned to Class';
    return assignedCount !== 0 || currentCourseHasCourseAssessment
      ? `Assigned ${assignedCount}/${students?.size}`
      : 'Unassigned';
  };
  return (
    <Flex
      direction="column"
      overflowY="auto"
      shrink={0}
      width={showCompactLayout && !selectedCourse ? '55%' : 'auto'}
      maxWidth="300px"
    >
      <Flex direction="column" align="stretch" overflowY="auto">
        {courses.map((course) => (
          <NoStylesButton
            onClick={() => setSelectedCourse(course)}
            key={course.id}
            testTag={`assign-modal-list-${course.id}`}
          >
            <ClassButton
              $selected={course.id === selectedCourse?.id}
              $showCompactLayout={showCompactLayout}
            >
              <Flex justify="space-between" align="center">
                <Flex align="center">
                  <ClassAvatar
                    size="large"
                    backgroundColor={course.avatarColor}
                    name={course.name}
                    disabledTooltip
                  />
                  {!showCompactLayout || !selectedCourse ? (
                    <>
                      <Spacer horizontal size={2} />
                      <Flex direction="column">
                        <Text variant="h5" wordBreak={showCompactLayout ? 'break-word' : 'normal'}>
                          {course.name}
                        </Text>
                        <Text variant="h6" weightOverride="medium" color="gray6">
                          {getAssignedText(course.id)}
                        </Text>
                      </Flex>
                    </>
                  ) : null}
                </Flex>
                {!showCompactLayout && (
                  <Icon icon="arrowRight" color="gray5" size="1.5em" flexShrink={0} />
                )}
              </Flex>
            </ClassButton>
          </NoStylesButton>
        ))}

        {showCompactLayout && selectedCourse && !currentUser?.paperSyncedAt ? (
          <>
            <Spacer size={2} />

            <Flex>
              <Spacer horizontal size={2} />
              <AvatarPlusButton
                to="/t/classes/new"
                size="large"
                radiusType="mostlyRound"
                testTag="avatar-plus-button"
              />
            </Flex>
          </>
        ) : null}
      </Flex>
      {(!showCompactLayout || !selectedCourse) && !currentUser?.paperSyncedAt ? (
        <>
          <Spacer size={2} />
          <Button displayType="outline" to="/t/classes/new">
            Create New Class
          </Button>
        </>
      ) : null}
    </Flex>
  );
};
