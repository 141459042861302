import { useCallback, useMemo } from 'react';
import { CreateToast, useToaster } from 'src/components';
import { useQuery, useHistory, queryMapToString, useParams, History } from 'src/modules/Router';
import { useStore } from 'src/Store';
import { User } from 'src/models';

export type TeacherStudentListHelpersHook = {
  closeModal: () => void;
  selectedStudent: User | undefined;
  createToast: CreateToast;
  currentQueryString: string;
  history: History;
};

export const useTeacherStudentListHelpers = (): TeacherStudentListHelpersHook => {
  const query = useQuery();
  const history = useHistory();
  const params = useParams<{ id?: string }>();
  const { createToast } = useToaster();
  const currentQueryString = useMemo(() => queryMapToString(query), [query]);
  const closeModal = useCallback(
    () => history.push(`/t/students${queryMapToString(query)}`),
    [history, query],
  );
  const { students } = useStore((s) => s.TeacherStudentListData, []);
  const selectedStudent = useMemo(
    () => students.find((s) => s.id === params.id),
    [params.id, students],
  );

  return {
    closeModal,
    selectedStudent,
    createToast,
    currentQueryString,
    history,
  };
};
