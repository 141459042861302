import { CreateToast } from 'src/components';
import {
  AssessmentExtended,
  Comment,
  Course,
  LicenseType,
  SubmissionExtended,
  TaskDetails,
  TaskSubmissionDetails,
  User,
} from 'src/models';
import { ImmutableList, ImmutableMap, ImmutableSet } from 'src/modules/Immutable';
import { SetField } from 'src/State';
import { History } from 'src/modules/Router';
import { emptyErrorMap, ErrorMap } from 'src/modules/Api';

export type AssessmentDetail = {
  assessment: AssessmentExtended;
  averageRating: number | null;
  totalWords: number;
  coverImageUrl?: string;
  courseIds: ImmutableList<string>;
  userIds: ImmutableList<string>;
  submissions: ImmutableList<SubmissionExtended>;
  primaryLicenseType: LicenseType | null;
  readingName: string;
};

type TasksCompletionStatus = {
  completed: number;
  total: number;
};

export type LoadResponse = {
  assessmentDetail: AssessmentDetail;
  submissionMap: ImmutableMap<string, SubmissionExtended>;
  commentMap: ImmutableMap<string, ImmutableList<Comment>>;
  userMap: ImmutableMap<string, User>;
  courseMap: ImmutableMap<string, Course>;
  courseToStudentIdsMap: ImmutableMap<string, ImmutableList<string>>;
  taskDetailsMap: ImmutableMap<string, TaskDetails>;
  studentToTaskSubmissionDetailsMap: ImmutableMap<
    string,
    ImmutableMap<string, TaskSubmissionDetails>
  >;
  taskCompletionMap: ImmutableMap<string, TasksCompletionStatus>;
  readingName: string;
};

export type TeacherAssignmentOverviewState = {
  loading: boolean;
  assignmentCardOpen: boolean;
  assessmentDetail: AssessmentDetail | null;
  submissionMap: ImmutableMap<string, SubmissionExtended>;
  userMap: ImmutableMap<string, User>;
  courseMap: ImmutableMap<string, Course>;
  courseToStudentIdsMap: ImmutableMap<string, ImmutableList<string>>;
  submitting: boolean;
  reassigning: boolean;
  reassigningName: string;
  reassigningErrors: ErrorMap;
  taskDetailsMap: ImmutableMap<string, TaskDetails>;
  studentToTaskSubmissionDetailsMap: ImmutableMap<
    string,
    ImmutableMap<string, TaskSubmissionDetails>
  >;
  openTaskCardSet: ImmutableSet<string>;
  taskCompletionMap: ImmutableMap<string, TasksCompletionStatus>;
  commentMap: ImmutableMap<string, ImmutableList<Comment>>;
  deleteSubmissionModalOpened: boolean;
  deleteSubmissionStudent: User | null;
  confirmDeleteText: string;
  submissionToDelete: SubmissionExtended | null;
  startReassigning: () => void;
  load: (id: string) => void;
  setField: SetField<TeacherAssignmentOverviewState>;
  submitReassign: (opts: { history: History; createToast: CreateToast }) => () => void;
  updateSubmissionMap: ({ submission }: { submission: SubmissionExtended }) => void;
  toggleTaskCardOpen: (value: string) => () => void;
  openDeleteSubmissionModal: (student: User, submission: SubmissionExtended) => void;
  closeDeleteSubmissionModal: () => void;
  deleteSubmission: (createToast: CreateToast) => () => void;
};

export const defaultState = {
  loading: true,
  assignmentCardOpen: true,
  assessmentDetail: null,
  submitting: false,
  reassigning: false,
  reassigningErrors: emptyErrorMap(),
  reassigningName: '',
  submissionMap: ImmutableMap<string, SubmissionExtended>(),
  userMap: ImmutableMap<string, User>(),
  courseMap: ImmutableMap<string, Course>(),
  courseToStudentIdsMap: ImmutableMap<string, ImmutableList<string>>(),
  taskDetailsMap: ImmutableMap<string, TaskDetails>(),
  studentToTaskSubmissionDetailsMap: ImmutableMap<
    string,
    ImmutableMap<string, TaskSubmissionDetails>
  >(),
  openTaskCardSet: ImmutableSet<string>(),
  taskCompletionMap: ImmutableMap<string, TasksCompletionStatus>(),
  commentMap: ImmutableMap<string, ImmutableList<Comment>>(),
  deleteSubmissionModalOpened: false,
  deleteSubmissionStudent: null,
  confirmDeleteText: '',
  submissionToDelete: null,
};
