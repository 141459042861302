import { ReactNode, useState, memo } from 'react';
import styled from 'styled-components';
import { ImmutableList } from 'src/modules/Immutable';
import { Flex, Metric, MetricProps, NoStylesButton } from 'src/components';
import { borderRadii, colors, margins, paddings } from 'src/styles';
import { AssignmentCardContent } from './AssignmentCardContent';

export type AssignmentCardProps = {
  testTag?: string;
  open?: boolean;
  to?: string;
  coverImg?: string;
  title: string;
  subTitle: ReactNode;
  children: ReactNode;
  metrics?: ImmutableList<MetricProps>;
  showMobile?: boolean;
  onToggleCardOpen?: () => void;
  ultimate?: boolean;
  isStudent?: boolean;
  readingName?: string;
};

const AssignmentCardContainer = styled.div<{
  $clickable: boolean;
  $hovering: boolean;
  $showMobile: boolean;
}>`
  width: 100%;
  display: flex;
  flex-direction: column;
  background: ${(p) => (p.$clickable && p.$hovering ? colors.white.active : colors.white.hex)};
  padding: ${(showMobile) => (showMobile ? `${paddings[3]} ${paddings[4]}` : paddings[5])};
  margin-bottom: ${margins[2]};
  border-radius: ${borderRadii[4]};
  border: 1px solid ${colors.gray2.hex};
`;

export const UnmemoizedAssignmentCard = ({
  testTag,
  open,
  onToggleCardOpen,
  coverImg,
  to,
  title,
  subTitle,
  children,
  metrics,
  readingName,
  showMobile = false,
  ultimate = false,
  isStudent = false,
}: AssignmentCardProps) => {
  const [hoveringClickableArea, setHoveringClickableArea] = useState(false);

  return (
    <AssignmentCardContainer
      data-test-tag={`assignment-card-${testTag}`}
      $clickable={Boolean(to)}
      $hovering={hoveringClickableArea}
      $showMobile={showMobile}
    >
      <AssignmentCardContent
        to={to}
        title={title}
        subTitle={subTitle}
        coverImg={coverImg}
        metrics={metrics}
        open={open}
        showMobile={showMobile}
        onToggleCardOpen={onToggleCardOpen}
        setHoveringClickableArea={setHoveringClickableArea}
        ultimate={ultimate}
        isStudent={isStudent}
        readingName={readingName}
      >
        {children}
      </AssignmentCardContent>
      {metrics && open && (
        <>
          {isStudent ? (
            <NoStylesButton
              to={to}
              onMouseOver={() => setHoveringClickableArea(true)}
              onMouseOut={() => setHoveringClickableArea(false)}
              justify="start"
            >
              <Flex margin={`${margins[5]} 0 0 0`} wrap="wrap">
                {metrics.map((metric) => (
                  <Metric key={metric.label} {...metric} />
                ))}
              </Flex>
            </NoStylesButton>
          ) : (
            <Flex margin={`${margins[5]} 0 0 0`} wrap="wrap">
              {metrics.map((metric) => (
                <Metric key={metric.label} {...metric} />
              ))}
            </Flex>
          )}
        </>
      )}
    </AssignmentCardContainer>
  );
};

export const AssignmentCard = memo(UnmemoizedAssignmentCard);
