import { useEffect } from 'react';
import { useHistory } from 'src/modules/Router';
import { Loading } from 'src/components';
import { useStore } from 'src/Store';

export const Logout = () => {
  const history = useHistory();
  const logout = useStore((state) => state.AppData.logout, []);
  useEffect(() => {
    logout(history);
  }, [logout, history]);

  return <Loading flex />;
};
