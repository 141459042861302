import { Text, Spacer, TaskButton, Flex, Button } from 'src/components';
import { useStore } from 'src/Store';

import { isTaskSubmissionCompleted, getTaskIcon } from 'src/models';
import { updateQuery, useHistory, useLocation, useQuery } from 'src/modules/Router';
import { useIsInstructionsPage, useIsPublicPreview } from './ReadingStudioHelpers';
import { useCompactLayout } from 'src/layouts/ReadingStudioLayout/ReadingStudioLayoutHelpers';

export const TaskList = ({ showClose = false }: { showClose?: boolean }) => {
  const {
    assessment,
    taskSubmissionDetailsMap,
    currentTaskId,
    isRecording,
    switchTask,
    toggleMobileTaskList,
  } = useStore(
    ({ ReadingStudioData: s, AppData: a }) => ({
      assessment: s.assessment,
      taskSubmissionDetailsMap: s.taskSubmissionDetailsMap,
      currentTaskId: s.currentTaskId,
      isRecording: a.isRecording,
      switchTask: s.switchTask,
      toggleMobileTaskList: s.toggleMobileTaskList,
    }),
    [],
  );
  const query = useQuery();
  const history = useHistory();
  const pathname = useLocation().pathname;
  const isPublicPreview = useIsPublicPreview();
  const compactLayout = useCompactLayout();
  const isInstructionsPage = useIsInstructionsPage();

  const firstModelTaskInfo = assessment?.taskOrder.find((to) => to.type === 'ModelTask');
  const firstModelTask = firstModelTaskInfo
    ? taskSubmissionDetailsMap.get(firstModelTaskInfo.taskId)
    : null;

  return (
    <>
      <Flex align="center" justify="space-between">
        <Text variant="h4" color="white">
          My Tasks
        </Text>
        {showClose && (
          <Button
            displayType="noStyles"
            icon="close"
            testTag="close-mobile-task-list"
            iconColor="white"
            iconSize="1em"
            onClick={toggleMobileTaskList}
            disabled={isRecording}
          />
        )}
      </Flex>
      <Spacer size={2} />
      {compactLayout && assessment?.taskOrder.some((ti) => ti.type === 'ModelTask') && (
        <TaskButton
          icon="modelReading"
          active={isInstructionsPage}
          onClick={switchTask({ taskId: null, query, history, isPublicPreview })}
          completed={firstModelTask ? isTaskSubmissionCompleted(firstModelTask) : true}
          disabled={isRecording}
        >
          Instructions
        </TaskButton>
      )}
      {!compactLayout && (
        <TaskButton
          icon="modelReading"
          active={false}
          onClick={() =>
            history.push(`${pathname}${updateQuery(query, { showInstructions: 'true' })}`)
          }
          disabled={isRecording}
        >
          Instructions
        </TaskButton>
      )}
      {assessment?.taskOrder
        .map(({ taskId }) => taskSubmissionDetailsMap.get(taskId)?.task)
        .map((task) => {
          const taskSubmissionDetails = task && taskSubmissionDetailsMap.get(task.id);
          return (
            task &&
            !(compactLayout && task.id === firstModelTask?.task.id) && (
              <TaskButton
                key={task.id}
                icon={getTaskIcon(task)}
                active={!isInstructionsPage && task.id === currentTaskId}
                onClick={switchTask({ taskId: task.id, query, history, isPublicPreview })}
                completed={
                  taskSubmissionDetails && isTaskSubmissionCompleted(taskSubmissionDetails)
                }
                disabled={isRecording}
              >
                {task.name}
              </TaskButton>
            )
          );
        })}
    </>
  );
};
