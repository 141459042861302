import { Text, Flex, Loading, Spacer, LinkButton } from 'src/components';
import { colors, paddings } from 'src/styles';
import { ReactNode } from 'react';
import { CenteredLayout } from 'src/layouts';

export interface TeacherLayoutProps {
  headerText: string;
  headerActionButton?: ReactNode;
  children: ReactNode;
  loading?: boolean;
  backPath?: string;
}

export const TeacherLayoutContent = ({
  headerText,
  headerActionButton,
  children,
  loading,
  backPath,
}: TeacherLayoutProps) => {
  return (
    <Flex direction="column">
      <Flex
        direction="column"
        padding={`${paddings[7]} 0`}
        backgroundColor={colors.backgroundDark.hex}
      >
        {backPath && (
          <CenteredLayout backgroundColor={colors.backgroundDark.hex} minWidth={0}>
            <Flex>
              <LinkButton to={backPath} icon="backArrow" color="gray4">
                Back
              </LinkButton>
            </Flex>
          </CenteredLayout>
        )}
        <CenteredLayout backgroundColor={colors.backgroundDark.hex}>
          <Flex justify="space-between" align="center">
            <Text variant="h2" color="white">
              {headerText}
            </Text>
            {headerActionButton}
          </Flex>
        </CenteredLayout>
      </Flex>

      <CenteredLayout height="100%">
        {loading ? (
          <Loading flex kind="boat" />
        ) : (
          <>
            <Spacer size={4} />
            {children}
          </>
        )}
      </CenteredLayout>
    </Flex>
  );
};
