import { Flex, Reader, Spacer, Text } from 'src/components';
import { useStore } from 'src/Store';
import {
  useFirstModelTaskSubmissionDetails,
  useReadingTaskSubmissionDetails,
} from './ReadingStudioHelpers';
import { getInstructions, secondaryTitleText } from 'src/models';
import { ModelTaskStudio } from './ModelTaskStudio';
import styled from 'styled-components';
import { ReadingStudioLayoutTitle } from 'src/layouts/ReadingStudioLayout/ReadingStudioLayoutTitle';
import { paddings } from 'src/styles';
import { useCompactLayout } from 'src/layouts/ReadingStudioLayout/ReadingStudioLayoutHelpers';
import { MobileReader } from './MainContent';

const ReadingStudioInstructionsStyles = styled.div`
  width: 100%;
  padding: ${paddings[4]};
`;

export const ReadingStudioInstructions = () => {
  const assessment = useStore(({ ReadingStudioData: s }) => s.assessment, []);
  const readingTaskSubmissionDetails = useReadingTaskSubmissionDetails();
  const modelTaskSubmissionDetails = useFirstModelTaskSubmissionDetails();
  const instructions = getInstructions(assessment || undefined);
  const { reading, coverImageFile, author } = readingTaskSubmissionDetails || {};
  const compactLayout = useCompactLayout();

  return (
    <ReadingStudioInstructionsStyles>
      <ReadingStudioLayoutTitle
        thumbnailUrl={coverImageFile?.thumbnailUrl}
        readingTitle={reading?.name}
        authorName={secondaryTitleText(assessment, author)}
        webSourceUrl={reading?.webSourceUrl}
        justify="center"
      />
      <Spacer size={2} />
      <Spacer size={2} />
      {instructions && (
        <>
          <Text variant="h4">Teacher Instructions</Text>
          <Text>{instructions}</Text>
        </>
      )}
      <Spacer size={2} />
      {modelTaskSubmissionDetails && (
        <>
          <Flex align="center">
            <ModelTaskStudio taskSubmissionDetails={modelTaskSubmissionDetails} />
          </Flex>
          <Spacer size={2} />
          <MobileReader>
            <Reader
              instructions={getInstructions(assessment)}
              readingTaskDetails={readingTaskSubmissionDetails}
              enableTextRangeSelection={false}
              compactLayout={compactLayout}
            />
          </MobileReader>
        </>
      )}
    </ReadingStudioInstructionsStyles>
  );
};
