import { useStore } from 'src/Store';
import { Text, Spacer, Flex, Icon, StandardModal, Button } from 'src/components';
import { useCallback } from 'react';
import { UltimateFeaturesList } from 'src/models';
import { paddings } from 'src/styles';

const LearnMoreModal = () => {
  const { availableAssignments, setField, ultimateAssessmentsOverviewOpened } = useStore(
    (s) => ({
      setField: s.AppData.setField,
      availableAssignments: s.AppData.currentUser?.ultimateAssessmentsAvailable ?? 0,
      ultimateAssessmentsOverviewOpened: s.AppData.ultimateAssessmentsOverviewOpened,
    }),
    [],
  );

  const close = useCallback(() => setField('ultimateAssessmentsOverviewOpened')(false), [setField]);

  return (
    <StandardModal
      openType="state"
      opened={ultimateAssessmentsOverviewOpened}
      header={
        availableAssignments > 0
          ? `You have ${availableAssignments} free Ultimate Assignments`
          : 'Want more Ultimate Assignments?'
      }
      middleHeader={
        <>
          <Spacer horizontal />
          <Icon icon="ultimateAssignmentCentered" size="1.35em" />
        </>
      }
      close={close}
      footer={
        availableAssignments ? (
          <Button width="100%" onClick={close}>
            Got it!
          </Button>
        ) : (
          <Button width="100%" onClick={close} href="https://www.readlee.com/contact" newTab>
            Contact Us!
          </Button>
        )
      }
    >
      <>
        {availableAssignments === 0 ? (
          <>
            <Text variant="h5">
              Upgrade to Readlee Ultimate to have unlimited access to the best Readlee features
            </Text>
            <UltimateFeaturesList />
          </>
        ) : (
          <>
            <Text variant="h5">
              Use your free Ultimate Assignments to try out our favorite features before making any
              commitments.
            </Text>
            <UltimateFeaturesList />
          </>
        )}
      </>
    </StandardModal>
  );
};

export const UltimateAssignmentsIndicator = () => {
  const { ultimateAssessmentsAvailable, currentPlan, setField } = useStore(
    (s) => ({
      setField: s.AppData.setField,
      currentPlan: s.AppData.currentPlan,
      ultimateAssessmentsAvailable: s.AppData.currentUser?.ultimateAssessmentsAvailable ?? 0,
    }),
    [],
  );

  if (!currentPlan.isUsageCapsEnabled) return null;

  return (
    <>
      <Button
        displayType="iconOnly"
        color="white"
        onClick={() => setField('ultimateAssessmentsOverviewOpened')(true)}
        testTag="ultimateAssessmentsIndicator"
        paddingOverride={`${paddings[2]} ${paddings[3]}`}
      >
        <Flex align="end">
          <Text
            variant="h3"
            lineHeightOverride="100%"
            color="primaryBlue"
            testTag="ultimate-assessments-available"
          >
            {ultimateAssessmentsAvailable}
          </Text>
          <Spacer horizontal />
          <Icon icon="ultimateAssignment" size="1.5em" />
        </Flex>
      </Button>
      <Spacer horizontal size={4} />
      <LearnMoreModal />
    </>
  );
};
