import { useCallback } from 'react';
import { StandardModal, Button, Spacer, useToaster, Text, Input } from 'src/components';
import { useHistory } from 'src/modules/Router';
import { useStore } from 'src/Store';
import { capitalize } from 'src/modules/String';
import { Assessment } from 'src/models';
import { paddings } from 'src/styles';

type UpdateAssignmentStatusModalProps = {
  onSuccess?: (updatedAssessment: Assessment) => void;
};

export const UpdateAssignmentStatusModal = ({ onSuccess }: UpdateAssignmentStatusModalProps) => {
  const history = useHistory();
  const { createToast } = useToaster();
  const {
    confirmUpdateAssignmentStatus,
    opened,
    action,
    getActionName,
    setField,
    assessment,
    openStatusUpdateModal,
    confirmDeleteText,
  } = useStore(
    ({ UpdateAssignmentStatusModalData: s }) => ({
      confirmUpdateAssignmentStatus: s.confirmUpdateAssignmentStatus,
      opened: s.opened,
      action: s.action,
      getActionName: s.getActionName,
      setField: s.setField,
      assessment: s.assessment,
      openStatusUpdateModal: s.openStatusUpdateModal,
      confirmDeleteText: s.confirmDeleteText,
    }),
    [],
  );
  const actionName = getActionName();
  const close = useCallback(() => setField('opened')(false), [setField]);
  if (!assessment) return null;

  return (
    <StandardModal
      openType="state"
      opened={opened}
      header="Are you sure?"
      close={close}
      testTag="update-status-modal"
      footer={
        <>
          <Button color="primaryBlue" displayType="link" inheritBgColor onClick={close}>
            Cancel
          </Button>
          <Spacer horizontal size={3} />
          <Button
            type="submit"
            onClick={confirmUpdateAssignmentStatus(history, createToast, onSuccess)}
            color={action === 'delete' ? 'danger' : undefined}
            disabled={action === 'delete' && confirmDeleteText.trim() !== assessment.name.trim()}
          >
            {capitalize(actionName)}
          </Button>
        </>
      }
    >
      {action === 'toggleArchive' ? (
        <>
          <Text>Are you sure you want to {actionName} this assignment?</Text>
          {actionName === 'archive' && (
            <>
              <Button
                color="primaryBlue"
                displayType="link"
                inheritBgColor
                onClick={() => openStatusUpdateModal(assessment, 'delete')}
                paddingOverride={paddings.none}
              >
                Delete Assignment Instead?
              </Button>
              <Spacer />
            </>
          )}
        </>
      ) : (
        <>
          <Text>
            This will delete the assignment <em>{assessment.name}</em> from all your classes,
            graphs, submissions, etc.
          </Text>
          <Spacer />
          {assessment.status !== 'archived' && (
            <>
              <Button
                color="primaryBlue"
                displayType="link"
                inheritBgColor
                onClick={() => openStatusUpdateModal(assessment, 'toggleArchive')}
                paddingOverride={paddings.none}
              >
                Archive Assignment Instead?
              </Button>
              <Spacer />
            </>
          )}
          <Input
            name="Confirm Delete Assignment"
            placeholder="Type the assignment name to confirm"
            value={confirmDeleteText}
            onChange={(text) => setField('confirmDeleteText')(text)}
          ></Input>
        </>
      )}
    </StandardModal>
  );
};
