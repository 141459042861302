import { useStore } from 'src/Store';
import { Flex, FormGroup, Text, Spacer, Button, StandardModal } from 'src/components';

export const WebSourceUrlModal = () => {
  const {
    webModalOpen,
    fetchArticle,
    scrapingWebSourceUrl,
    scrapeErrors,
    errors,
    setField,
    editingWebSourceUrl,
  } = useStore(
    ({ ReadingBuilderData: rbd }) => ({
      webModalOpen: rbd.webModalOpen,
      fetchArticle: rbd.fetchArticle,
      scrapingWebSourceUrl: rbd.scrapingWebSourceUrl,
      scrapeErrors: rbd.scrapeErrors,
      errors: rbd.errors,
      setField: rbd.setField,
      editingWebSourceUrl: rbd.editingWebSourceUrl,
    }),
    [],
  );

  return (
    <StandardModal
      openType="state"
      opened={webModalOpen}
      header="Source Web URL"
      footer={
        <Flex justify="end" align="center">
          <Button onClick={() => setField('webModalOpen')(false)} displayType="link">
            Cancel
          </Button>
          <Spacer horizontal size={3} />
          <Button onClick={fetchArticle} loading={scrapingWebSourceUrl} type="submit">
            Save
          </Button>
        </Flex>
      }
      disableFadeClose
      close={() => setField('webModalOpen')(false)}
    >
      <Text maxWidth="600px">
        Readlee will sync the title, thumbnail, and author from the website and provide a url to the
        source material. You will still need to copy and paste the text you would like to assign.
      </Text>
      <Spacer size={4} />
      <FormGroup
        name="webSourceUrl"
        type="text"
        label="Web URL"
        value={editingWebSourceUrl}
        onChange={setField('editingWebSourceUrl')}
        errors={scrapeErrors.merge(errors)}
        disabled={scrapingWebSourceUrl}
      />
    </StandardModal>
  );
};
