import { Button, Spacer, StandardModal, useToaster, Text, FormGroup, Bold } from 'src/components';
import { useStore } from 'src/Store';

export const DeleteSubmissionModal = () => {
  const {
    submitting,
    deleteSubmissionModalOpened,
    assessmentDetail,
    deleteSubmissionStudent,
    confirmDeleteText,
    closeDeleteSubmissionModal,
    deleteSubmission,
    setField,
  } = useStore((s) => s.TeacherAssignmentOverviewData, []);

  const { createToast } = useToaster();

  return (
    <StandardModal
      testTag="delete"
      openType="state"
      opened={deleteSubmissionModalOpened}
      header="Are You Sure?"
      close={closeDeleteSubmissionModal}
      onSubmit={deleteSubmission(createToast)}
      maxWidthOverride="700px"
      footer={
        <>
          <Button onClick={closeDeleteSubmissionModal} displayType="link" color="primaryBlue">
            Cancel
          </Button>
          <Spacer horizontal size={3} />
          <Button
            color="danger"
            loading={submitting}
            type="submit"
            disabled={
              confirmDeleteText.toLowerCase().trim() !==
              deleteSubmissionStudent?.displayName.toLowerCase()
            }
          >
            Delete
          </Button>
        </>
      }
    >
      <Text>
        This will the delete <Bold>{deleteSubmissionStudent?.displayName}&apos;s</Bold> submission
        for <Bold>{assessmentDetail?.assessment.name}</Bold>.
      </Text>
      <Spacer size={2} />
      <Text>
        It will remove all data (recordings, responses to comprehension questions, comments from
        teachers, etc) for this submission.
      </Text>
      <Spacer size={2} />
      <FormGroup
        type="text"
        name="deleteSubmission"
        value={confirmDeleteText}
        placeholder="Type the student's name to confirm"
        onChange={setField('confirmDeleteText')}
        testTag="confirm-delete"
      />
    </StandardModal>
  );
};
