import { ErrorMap, emptyErrorMap } from 'src/modules/Api';
import {
  SignupUser,
  emptySignupUser,
  SetSignupUserField,
  AccountType,
  Course,
  School,
} from 'src/models';
import { History, QueryMap } from 'src/modules/Router';
import { ImmutableSet } from 'src/modules/Immutable';

export type SignUpRegisterState = {
  user: SignupUser;
  submitting: boolean;
  errors: ErrorMap;
  course: Course | null;
  school: School | null;
  loading: boolean;
  fieldsMissing: ImmutableSet<string>;
  sso: string | undefined;
  load: (props: {
    accountType: AccountType;
    courseCode: string | undefined;
    schoolCode: string | undefined;
    query: QueryMap;
    history: History;
  }) => void;
  setUserField: SetSignupUserField;
  submit: (history: History, query: QueryMap) => () => void;
};

export const defaultState = {
  user: emptySignupUser,
  submitting: false,
  errors: emptyErrorMap(),
  course: null,
  loading: true,
  sso: undefined,
  fieldsMissing: ImmutableSet<string>(),
  school: null,
};
