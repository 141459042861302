import { Course, Registration, Subject, User } from 'src/models';
import { ImmutableList, ImmutableMap, ImmutableSet } from 'src/modules/Immutable';
import { CreateToast } from 'src/components';
import { emptyErrorMap, ErrorMap } from 'src/modules/Api';
import { History, QueryMap } from 'src/modules/Router';
import { GradeLevel } from 'src/models/GradeLevel';
import { SetField } from 'src/State';

export type TeacherCourseListState = {
  loading: boolean;
  syncLoadingCourseIds: ImmutableSet<string>;
  courses: ImmutableList<Course>;
  students: ImmutableList<User>;
  registrations: ImmutableMap<string, ImmutableList<Registration>>;
  studentCounts: ImmutableMap<string, number>;
  editSubmitting: boolean;
  editErrors: ErrorMap;
  courseIdsToEdit: ImmutableList<string>;
  editingCourse: Course | null;
  editingCourseSubjects: ImmutableList<Subject>;
  subjects: ImmutableList<Subject>;
  gradeLevels: ImmutableList<GradeLevel>;
  coursesToSync: ImmutableList<Course>;
  assessmentCountMap: ImmutableMap<string, number>;
  load: (opts: {
    history: History;
    pathname: string;
    courseIdsToEdit?: ImmutableSet<string>;
    onSuccess?: () => void;
  }) => void;
  archive: (course: Course, createToast: CreateToast, onSuccess?: () => void) => () => void;
  editCourse: (history: History, query: QueryMap, isCreate?: boolean) => void;
  startSyncCourse: (opts: {
    courses: ImmutableList<Course>;
    pathname: string;
    history: History;
    query: QueryMap;
    isGoogle: boolean;
  }) => () => void;
  syncAllCourses: (
    courses: ImmutableList<Course>,
    history: History,
    pathname: string,
    createToast: CreateToast,
    isGoogle: boolean,
  ) => () => void;
  generateClassCode: () => void;
  setEditCourseField: SetField<Course>;
  unsavedRegistrations: ImmutableSet<string>;
  toggleRegistration: (id: string) => void;
  updateClassStudents: (createToast: CreateToast, closeModal: () => void) => void;
  tempEditingCourseSubjectIds: ImmutableList<string>;
  courseSubjectsMap: ImmutableMap<string, ImmutableList<Subject>>;
  setField: SetField<TeacherCourseListState>;
};

export const defaultState = {
  loading: true,
  syncLoadingCourseIds: ImmutableSet<string>(),
  courses: ImmutableList<Course>(),
  students: ImmutableList<User>(),
  registrations: ImmutableMap<string, ImmutableList<Registration>>(),
  studentCounts: ImmutableMap<string, number>(),
  unsavedRegistrations: ImmutableSet<string>(),
  editSubmitting: false,
  editingCourse: null,
  editingCourseSubjects: ImmutableList<Subject>(),
  subjects: ImmutableList<Subject>(),
  courseIdsToEdit: ImmutableList<string>(),
  gradeLevels: ImmutableList<GradeLevel>(),
  editErrors: emptyErrorMap(),
  coursesToSync: ImmutableList<Course>(),
  tempEditingCourseSubjectIds: ImmutableList<string>(),
  courseSubjectsMap: ImmutableMap<string, ImmutableList<Subject>>(),
  assessmentCountMap: ImmutableMap<string, number>(),
};
