import { Button, StandardModal, FormGroup } from 'src/components';
import { ImmutableList } from 'src/modules/Immutable';

type PlaybackSpeedModalProps = {
  audioFileId: string;
  open: boolean;
  close: () => void;
  onChange: (newRate: number) => void;
  playbackRate: number;
};

export const audioPlaybackSpeedOptions = ImmutableList([
  0.25, 0.5, 0.75, 1, 1.25, 1.5, 1.75, 2,
] as const);

export const audioPlaybackSpeedOptionsMap = {
  0.25: '0.25x',
  0.5: '0.5x',
  0.75: '0.75x',
  1: 'Normal',
  1.25: '1.25x',
  1.5: '1.5x',
  1.75: '1.75x',
  2: '2x',
} as const;

export const PlaybackSpeedModal = ({
  playbackRate,
  open,
  close,
  onChange,
}: PlaybackSpeedModalProps) => {
  return (
    <StandardModal
      openType="state"
      opened={open}
      header="Select Playback Speed"
      close={close}
      disableFadeClose
      footer={<Button onClick={close}>Save</Button>}
    >
      <FormGroup
        name="Speed"
        label=""
        type="select"
        testTag="playback-speed"
        value={playbackRate}
        onChange={onChange}
        options={audioPlaybackSpeedOptions.map((speed) => ({
          value: speed,
          label: audioPlaybackSpeedOptionsMap[speed],
        }))}
      />
    </StandardModal>
  );
};
