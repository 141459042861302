import { colors, CssColor, margins, RequiredDollarPrefix } from 'src/styles';
import styled, { css } from 'styled-components';
import { FlexStyles } from 'src/components/Flex';

const BaseDivider = styled.div<RequiredDollarPrefix<DividerProps>>`
  flex-shrink: 0;
  width: ${({ $vertical }) => ($vertical ? '0' : '100%')};
  height: ${({ $vertical }) => ($vertical ? '100%' : '0')};
  margin: ${({ $noMargin, $vertical }) =>
    $noMargin ? 0 : $vertical ? `${margins.none} ${margins[2]}` : `${margins[2]} ${margins.none}`};
  margin-top: ${({ $endMargin }) => $endMargin};
  margin-bottom: ${({ $endMargin }) => $endMargin};
  border: ${({ $size, $color }) => `${$size}px solid ${$color}`};
  background: ${({ $color }) => $color};

  ${({ $vertical }) => {
    if (!$vertical) return;
    return css`
      ${FlexStyles} > & {
        height: auto;
        align-self: stretch;
      }
    `;
  }}
`;

type DividerProps = {
  noMargin?: boolean;
  size?: number;
  vertical?: boolean;
  color?: CssColor;
  endMargin?: string;
};

export const Divider = ({
  noMargin = false,
  size = 2,
  vertical = false,
  color = colors.gray2.hex,
  endMargin = margins.none,
}: DividerProps) => {
  return (
    <BaseDivider
      $noMargin={noMargin}
      $size={size}
      $vertical={vertical}
      $color={color}
      $endMargin={endMargin}
    />
  );
};
