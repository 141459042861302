import { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { Button, FormGroup, LinkButton, Loading, Spacer, Text } from 'src/components';
import { OnboardingLayout } from 'src/layouts/OnboardingLayout/OnboardingLayout';
import { ImmutableList } from 'src/modules/Immutable';
import { queryMapToString, useHistory, useQuery, useLocation, matchPath } from 'src/modules/Router';
import { useStore } from 'src/Store';
import { AddSchool, ConfirmSchool, SchoolSearch } from '../SchoolSearch';

const SchoolForm = () => {
  const {
    currentUser,
    addedManualSchool,
    tempPositions,
    tempTeachesSpecialEd,
    tempTeachesEll,
    setField,
    submitResponses,
    submitSchool,
    savedSchools,
    schools,
  } = useStore(
    (s) => ({
      currentUser: s.TeacherOnboardingData.currentUser,
      addedManualSchool: s.TeacherOnboardingData.addedManualSchool,
      tempPositions: s.TeacherOnboardingData.tempPositions,
      tempTeachesSpecialEd: s.TeacherOnboardingData.tempTeachesSpecialEd,
      tempTeachesEll: s.TeacherOnboardingData.tempTeachesEll,
      setField: s.TeacherOnboardingData.setField,
      submitResponses: s.TeacherOnboardingData.submitResponses,
      submitSchool: s.TeacherOnboardingData.submitSchool,
      schools: s.SchoolSearchData.schools,
      savedSchools: s.AppData.schools,
    }),
    [],
  );
  const query = useQuery();
  const history = useHistory();
  const redirectPath = query.get('redirectPath');

  if (!currentUser) return null;

  return (
    <Switch>
      <Route path="/t/onboarding/finished" exact>
        <Text variant="h1">Confirm Your School</Text>
        <Spacer size={8} />
        <ConfirmSchool
          onConfirm={submitSchool(history, query, schools, savedSchools)}
          cancelLink={
            addedManualSchool
              ? `/t/onboarding/manual${queryMapToString(query)}`
              : `/t/onboarding/searching${queryMapToString(query)}`
          }
        />
      </Route>
      <Route path="/t/onboarding/searching" exact>
        <Text variant="h1">Select Your School</Text>
        <Spacer size={8} />
        <SchoolSearch
          manualLink={`/t/onboarding/manual${queryMapToString(query)}`}
          onSetSchool={() => {
            setField('addedManualSchool')(false);
            history.replace(`/t/onboarding/finished${queryMapToString(query)}`);
          }}
        />
      </Route>
      <Route path="/t/onboarding/manual" exact>
        <Text variant="h1">Add Your School</Text>
        <Spacer size={8} />
        <AddSchool
          cancelLink={`/t/onboarding/searching${queryMapToString(query)}`}
          onSaveSuccess={() => {
            setField('addedManualSchool')(true);
            history.replace(`/t/onboarding/finished${queryMapToString(query)}`);
          }}
        />
      </Route>
      <Route path="/t/onboarding/questions" exact>
        <Text variant="h1">Tell Us About Yourself!</Text>
        <Spacer size={8} />
        <FormGroup
          type="text"
          name="position"
          value={tempPositions}
          onChange={setField('tempPositions')}
          label={<Text color="black">What is/are your position(s) at your school?</Text>}
        />
        <Spacer size={6} />
        <FormGroup
          name="specialEd"
          type="select"
          value={tempTeachesSpecialEd}
          onChange={setField('tempTeachesSpecialEd')}
          options={ImmutableList([
            { label: '', value: 'unanswered' },
            { label: 'Yes', value: 'yes' },
            { label: 'No', value: 'no' },
          ])}
          label={<Text color="black">Do you teach special education?</Text>}
        />
        <Spacer size={6} />
        <FormGroup
          name="ell"
          type="select"
          value={tempTeachesEll}
          onChange={setField('tempTeachesEll')}
          options={ImmutableList([
            { label: '', value: 'unanswered' },
            { label: 'Yes', value: 'yes' },
            { label: 'No', value: 'no' },
          ])}
          label={<Text color="black">Do you teach English Language Learners (ELL)?</Text>}
        />
        <Spacer size={10} />
        <Button onClick={submitResponses(history, redirectPath)}>Submit</Button>
      </Route>
    </Switch>
  );
};

export const TeacherOnboarding = () => {
  const { currentUser, load, loading, skip, userSchools } = useStore(
    ({ TeacherOnboardingData: tod }) => ({
      currentUser: tod.currentUser,
      load: tod.load,
      loading: tod.loading,
      skip: tod.skip,
      userSchools: tod.userSchools,
    }),
    [],
  );

  const query = useQuery();
  const history = useHistory();
  const pathname = useLocation().pathname;
  const redirectPath = query.get('redirectPath');
  useEffect(() => {
    load();
  }, [load]);

  useEffect(() => {
    if (loading) return;

    if (
      matchPath(pathname, [
        '/t/onboarding/questions',
        '/t/onboarding/finished',
        '/t/onboarding/manual',
      ])
    ) {
      return;
    }

    if (userSchools.size === 0) {
      history.replace(`/t/onboarding/searching${queryMapToString(query)}`);
    } else {
      history.replace(`/t/onboarding/finished${queryMapToString(query)}`);
    }
  }, [history, loading, pathname, query, userSchools.size]);

  if (!currentUser) return null;
  if (loading) return <Loading kind="boat" />;

  return (
    <OnboardingLayout
      secondaryAction={<LinkButton onClick={() => skip(history, redirectPath)}>Skip</LinkButton>}
    >
      <SchoolForm />
      <Spacer size={22} />
    </OnboardingLayout>
  );
};
