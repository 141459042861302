import { CreateToast } from 'src/components';
import { User, Plan, Subscription, emptyPlan } from 'src/models';
import { ImmutableList } from 'src/modules/Immutable';
import { History } from 'src/modules/Router';
import { SetField } from 'src/State';

export type OnSubscriptionStatusProps = {
  createToast: CreateToast;
  action: 'payment_completed';
  payload: { plan: Plan; subscription: Subscription };
};

export type PlanListState = {
  loading: boolean;
  currentUser: User | null;
  currentPlan: Plan | null;
  plans: ImmutableList<Plan>;
  currentSubscription: Subscription | null;
  creatingSession: boolean;
  downgradingPlan: Plan | null;
  load: (createToast: CreateToast, history: History) => void;
  setField: SetField<PlanListState>;
  startPaymentSession: (planId: string, paymentInterval: 'annual' | 'month') => () => void;
  startDowngrade: (plan: Plan) => () => void;
  downgrade: (plan: Plan, createToast: CreateToast) => () => void;
  onSubscriptionStatus: (opts: OnSubscriptionStatusProps) => void;
  onSubscriptionConnected: (createToast: CreateToast, history: History) => void;
};

export const defaultState = {
  loading: true,
  currentUser: null,
  currentPlan: emptyPlan(),
  currentSubscription: null,
  creatingSession: false,
  plans: ImmutableList<Plan>(),
  downgradingPlan: null,
};
