import { subDataCreator } from 'src/State';
import { AvatarProps } from 'src/models';
import { ImmutableList } from 'src/modules/Immutable';
import { defaultState } from './AvatarGalleryState';

type GalleryResponse = {
  avatars: ImmutableList<AvatarProps>;
  page: number;
  total: number;
};

export const createDefaultAvatarGalleryData = subDataCreator(
  'AvatarGalleryData',
  defaultState,
  ({ setField, set, get, fetchJson }) => ({
    setField,
    load: () => {
      set({ ...defaultState });
      get().loadMore();
    },
    loadMore: () => {
      const { page, avatars } = get();
      fetchJson(`/api/users/avatar_gallery`, {
        data: { page },
        onSuccess: ({ page, total, avatars: newAvatars }: GalleryResponse) => {
          set({ loading: false, page: page + 1, total, avatars: avatars.concat(newAvatars) });
        },
      });
    },
  }),
);
