import { ImmutableList } from 'src/modules/Immutable';
import { Assessment } from './Assessment';
import { ExtendedUser, Flags } from './User';
import { Icon, LinkButton, Text, Flex, Spacer, Tooltip } from 'src/components';
import { memo } from 'react';

type PlanStatus = 'visible' | 'hidden' | 'deprecated' | 'disabled' | 'deleted';

export type Plan = {
  id: string;
  name: string;
  tagline: string;
  description: string;
  basePlanId: string | null;
  features: ImmutableList<string>;
  status: PlanStatus;
  comingSoonFeatures: ImmutableList<string>;
  optionalAddOns: ImmutableList<string>;
  stripeProductId: string;
  monthlyStripePriceId: string;
  annualStripePriceId: string;
  monthlyPriceUsdCents: number;
  annualPriceUsdCents: number;
  level: number;
  isBasicPlan: boolean;
  isEnterprise: boolean;
  isUsageCapsEnabled: boolean;
};

export const comparePlans = (planA: Plan, planB: Plan) => {
  return planA.level - planB.level;
};

export const sortPlans = (plans: ImmutableList<Plan>) => {
  return plans.sort(comparePlans);
};

export const emptyPlan = (): Plan => {
  return {
    id: '',
    name: '',
    tagline: '',
    description: '',
    basePlanId: null,
    features: ImmutableList(),
    status: 'hidden',
    comingSoonFeatures: ImmutableList(),
    optionalAddOns: ImmutableList(),
    stripeProductId: '',
    monthlyStripePriceId: '',
    annualStripePriceId: '',
    monthlyPriceUsdCents: 0,
    annualPriceUsdCents: 0,
    level: 0,
    isBasicPlan: true,
    isEnterprise: false,
    isUsageCapsEnabled: false,
  };
};

export const ENTERPRISE_PLAN_LABEL = 'Readlee Ultimate';
export const ENTERPRISE_PLAN_LABEL_SHORT = 'Ultimate';

export const usageCapFeatureEnabled = (
  flag: keyof Flags,
  currentUser: ExtendedUser | null,
  assessment: Assessment | null,
  enabledForStudents: boolean,
) => {
  if (!currentUser) return false;
  if (currentUser?.flags[flag]) return true;
  if (currentUser?.accountType === 'student' && enabledForStudents) return true;
  if (assessment?.isUltimate) return true;

  return false;
};

const valueProps = [
  {
    label: 'Full student transcriptions',
    helpLink: 'https://readleesupport.zendesk.com/hc/en-us/articles/14121208003981',
  },
  {
    label: 'Advanced analytics',
    helpLink: 'https://readleesupport.zendesk.com/hc/en-us/articles/14121211516685',
  },
  {
    label: 'Vocabulary tasks',
    helpLink: 'https://readleesupport.zendesk.com/hc/en-us/articles/14120923896205',
  },
  {
    label: 'Transcriptions of audio questions',
    helpLink: '',
  },
];

const ValueProp = ({ label, helpLink }: { label: string; helpLink: string }) => (
  <li>
    <Flex align="center">
      <Text variant="h6">{label}</Text>
      {helpLink && (
        <>
          <Spacer horizontal size={1} />
          <Tooltip content="Click to learn more">
            <LinkButton newTab href={helpLink}>
              <Icon icon="info" strokeWidth={2} color="primaryBlue" />
            </LinkButton>
          </Tooltip>
        </>
      )}
    </Flex>
  </li>
);

const UnmemoizedUltimateFeaturesList = () => (
  <ul>
    {valueProps.map(({ label, helpLink }, i) => (
      <ValueProp key={i} label={label} helpLink={helpLink} />
    ))}
  </ul>
);

export const UltimateFeaturesList = memo(UnmemoizedUltimateFeaturesList);

export const getFilterLength = (currentPlan: Plan) => {
  if (currentPlan.isBasicPlan) {
    return 'Last 7 Days';
  } else if (currentPlan.isEnterprise) {
    return 'Current Academic Year';
  } else {
    return 'Last 30 Days';
  }
};
