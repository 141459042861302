import './polyfills';
// import { createRoot } from 'react-dom/client';
import { render } from 'react-dom';
import { App } from './App';
import { useFetch } from './modules/Api';
import { createStrictStore } from './modules/Zustand';
import { createInitialState, Provider } from './Store';
import { StrictMode } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { isPaperReading, paperReadingPath } from './config';

const AppContainer = () => {
  const fetchData = useFetch();
  return (
    <Provider createStore={() => createStrictStore(createInitialState(fetchData)).useBaseStore}>
      <App />
    </Provider>
  );
};

const Base = () => {
  return (
    <StrictMode>
      <BrowserRouter basename={isPaperReading ? paperReadingPath : ''}>
        <AppContainer />
      </BrowserRouter>
    </StrictMode>
  );
};

const rootElement = document.getElementById('app');
render(<Base />, rootElement);
// Should swap out for this at some point (broke local last time)
// if (rootElement) {
//   const root = createRoot(rootElement);
//   root.render(<Base />);
// } else {
//   throw new Error('Could not find `app` element.');
// }

if (module.hot) module.hot.accept();
