import { useEffect } from 'react';
import {
  Button,
  Flex,
  Spacer,
  StandardModal,
  Tooltip,
  ClassAvatar,
  AvatarCard,
  LinkButton,
} from 'src/components';
import { setToggle } from 'src/modules/Immutable';
import { useStore } from 'src/Store';
import { useTeacherStudentListHelpers } from '../../TeacherStudentListHelpers';

export const ManageStudentCoursesModal = () => {
  const {
    setField,
    registrationMap,
    courseMap,
    assignedMap,
    updateStudentClasses,
    loadManageStudentCoursesModal,
  } = useStore((s) => s.TeacherStudentListData, []);

  const helpers = useTeacherStudentListHelpers();
  const { selectedStudent, closeModal } = helpers;

  useEffect(() => {
    loadManageStudentCoursesModal(selectedStudent);
  }, [loadManageStudentCoursesModal, selectedStudent]);

  if (!selectedStudent) return null;
  const currClasses = registrationMap.get(selectedStudent.id);

  return (
    <StandardModal
      header={`${selectedStudent.displayName}'s Classes`}
      close={closeModal}
      disableFadeClose
      openType="state"
      opened
    >
      <Flex direction="column" maxHeight="500px" overflowY="auto">
        {courseMap
          .valueSeq()
          .sortBy((course) => course.name.toLowerCase())
          .map((course, key) => {
            const registered = currClasses?.some(
              (registration) =>
                registration.status === 'active' && registration.courseId === course.id,
            );
            return (
              <Flex key={key} justify="space-between" margin="10px 16px">
                <AvatarCard
                  avatar={
                    <ClassAvatar
                      name={course.name}
                      backgroundColor={course.avatarColor}
                      size="large"
                    />
                  }
                  primaryText={course.name}
                  secondaryText={registered && 'Assigned'}
                />
                <Tooltip
                  content="This class roster is managed by your LMS. Visit your LMS to edit this roster."
                  disabled={!course.isLms}
                >
                  <Flex align="center" height="100%">
                    <LinkButton
                      onClick={() => {
                        setField('assignedMap')(setToggle(assignedMap, course.id));
                      }}
                      color="primaryBlue"
                      disabled={course.isLms}
                    >
                      {assignedMap.get(course.id) ? 'Remove' : 'Add'}
                    </LinkButton>
                  </Flex>
                </Tooltip>
              </Flex>
            );
          })}
      </Flex>
      <Spacer size={3} />
      <Button width="100%" onClick={updateStudentClasses(helpers)}>
        Save Changes
      </Button>
    </StandardModal>
  );
};
