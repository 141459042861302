import { useCallback } from 'react';
import { useStore } from 'src/Store';
import { ImmutableList } from 'src/modules/Immutable';
import { today } from 'src/modules/Time';
import { Flex, Icon, SelectInput, Spacer, PremiumTooltip } from 'src/components';
import { ENTERPRISE_PLAN_LABEL } from 'src/models/Plan';

export type TimeLabel =
  | 'Today'
  | 'Last 7 Days'
  | 'Last 30 Days'
  | 'Current Academic Year'
  | 'All Time';

export type TimeRange = { start: Date; end: Date };
export type DatePickerOption = { label: TimeLabel; value: TimeRange; prev: TimeRange | null };

type DatePickerProps = {
  /** ID of the input */
  id?: string;
  /** Value of input */
  value: TimeLabel;
  /** Name for input in form */
  name: string;
  /** Event handler for change event */
  onChange: (newValue: DatePickerOption) => void;
};

const currentAcademicYear = () => {
  return today.getMonth() > 5
    ? new Date(today.getFullYear(), 6, 1)
    : new Date(today.getFullYear() - 1, 6, 1);
};
const prevAcademicYear = () =>
  today.getMonth() > 5
    ? new Date(today.getFullYear() - 1, 6, 1)
    : new Date(today.getFullYear() - 2, 6, 1);

export const dateOptions = {
  Today: {
    label: 'Today',
    value: { start: today, end: today },
    prev: {
      start: new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1),
      end: today,
    },
    isExtended: false,
    isAll: false,
  },
  'Last 7 Days': {
    label: 'Last 7 Days',
    value: {
      start: new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7),
      end: today,
    },
    prev: {
      start: new Date(today.getFullYear(), today.getMonth(), today.getDate() - 14),
      end: new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7),
    },
    isExtended: false,
    isAll: false,
  },
  'Last 30 Days': {
    label: 'Last 30 Days',
    value: {
      start: new Date(today.getFullYear(), today.getMonth(), today.getDate() - 30),
      end: today,
    },
    prev: {
      start: new Date(today.getFullYear(), today.getMonth(), today.getDate() - 60),
      end: new Date(today.getFullYear(), today.getMonth(), today.getDate() - 30),
    },
    isExtended: true,
    isAll: false,
  },
  'Current Academic Year': {
    label: 'Current Academic Year',
    value: { start: currentAcademicYear(), end: today },
    prev: {
      start: prevAcademicYear(),
      end: currentAcademicYear(),
    },
    isExtended: false,
    isAll: true,
  },
  'All Time': {
    label: 'All Time',
    value: { start: new Date(0), end: today },
    prev: null,
    isExtended: false,
    isAll: true,
  },
} as const;

export const DatePicker = ({ id, onChange, value, name, ...basicProps }: DatePickerProps) => {
  const currentUser = useStore((s) => s.AppData.currentUser, []);

  const realOnChange = useCallback(
    (newLabel: TimeLabel) => {
      onChange(dateOptions[newLabel]);
    },
    [onChange],
  );

  if (!currentUser) return null;

  const hasExtended = currentUser.flags.isExtendedDateFilteringEnabled;
  const hasAll = currentUser.flags.isAllDateFilteringEnabled;

  return (
    <Flex align="center">
      {(!hasExtended || !hasAll) && (
        <PremiumTooltip enabled content="Upgrade to view more data" linkLocation="plans">
          <Flex height="100%" testTag="ultimate-tooltip">
            <Icon icon="info" strokeWidth={2} size="24px" color="white" />
          </Flex>
        </PremiumTooltip>
      )}
      <Spacer horizontal />
      <SelectInput
        {...basicProps}
        id={id}
        options={ImmutableList(Object.values(dateOptions)).map((o) => {
          const proText = o.isExtended && !hasExtended ? ' (Pro)' : '';
          const schoolText = o.isAll && !hasAll ? ` (${ENTERPRISE_PLAN_LABEL})` : '';

          return {
            label: `${o.label}${proText}${schoolText}`,
            value: o.label,
            disabled: (o.isExtended && !hasExtended) || (o.isAll && !hasAll),
          };
        })}
        value={value}
        onChange={realOnChange}
        name={name}
        icon="calendar"
        displayType="icon"
      />
    </Flex>
  );
};
