import {
  ComprehensionTaskSubmissionDetails,
  ComprehensionTextDiffItem,
  ENTERPRISE_PLAN_LABEL,
  ModelTaskSubmissionDetails,
  ReadingTaskSubmissionDetails,
  SubmissionExtended,
  TaskDetails,
  TaskSubmissionDetails,
} from 'src/models';
import {
  AudioPlayer,
  Button,
  Flex,
  Icon,
  Metric,
  Spacer,
  Text,
  PremiumTooltip,
} from 'src/components';
import { margins, paddings, standardMeasurements } from 'src/styles';
import { Fragment, useState } from 'react';
import { formatNumber, formatStat, statStyles } from 'src/modules/Stat';
import { formatDate } from 'src/modules/Time';
import { ImmutableList } from 'src/modules/Immutable';
import { VocabTaskSubmissionDetails } from 'src/models/VocabTaskSubmission';

const noAnswerText = (taskDetails: TaskDetails) => {
  switch (taskDetails.type) {
    case 'ModelTask':
      return 'Student did not listen to the model reading';
    case 'ComprehensionTask':
      return 'Student did not answer';
    case 'ReadingTask':
      return 'Student did not submit recording';
    case 'VocabTask':
      return 'Student did not view the vocab task';
    default:
      throw new Error(`Unrecognized task type "${JSON.stringify(taskDetails)}"`);
  }
};

const NoAnswer = ({ taskDetails }: { taskDetails: TaskDetails }) => {
  return <Text variant="h6">{noAnswerText(taskDetails)}</Text>;
};

const ReadingSubmissionViewer = ({
  submission,
  taskSubmissionDetails,
  initialPlayProgress,
  playIndex,
  showStats,
}: {
  submission?: SubmissionExtended;
  taskSubmissionDetails: ReadingTaskSubmissionDetails;
  initialPlayProgress: number;
  playIndex: number;
  showStats: boolean;
}) => {
  if (!submission || !taskSubmissionDetails) return null;
  const metricsTopRow = [
    {
      label: 'Total Time Read',
      value: formatStat(statStyles.time_taken, submission?.timeRead || 0),
    },
    {
      label: taskSubmissionDetails.reading.contentType === 'independent' ? 'WPM' : 'WCPM',
      value: formatStat(
        statStyles.wcpm,
        taskSubmissionDetails.taskSubmission.wordsCorrectPerMinute,
      ),
    },
    {
      label: 'Total Words',
      value: formatStat(statStyles.total_words, submission.totalWords || 0),
    },
    {
      label: 'Unique Words',
      value: formatNumber(taskSubmissionDetails.taskSubmission.uniqueWordSaidCount),
    },
    { label: 'Last Submitted', value: formatDate(new Date(submission?.finishedAt)) },
  ].concat(
    taskSubmissionDetails.reading.contentType !== 'independent'
      ? [
          {
            label: 'Completion',
            value: formatStat(
              statStyles.completion,
              taskSubmissionDetails.taskSubmission.simpleCompletion,
            ),
          },
        ]
      : [],
  );
  return taskSubmissionDetails.audioFile ? (
    <Flex width="100%" direction="column">
      <Flex align="center">
        <AudioPlayer
          initialPlayProgress={initialPlayProgress}
          src={taskSubmissionDetails.audioFile.url}
          audioFileId={taskSubmissionDetails.audioFile.id}
          playIndex={playIndex}
          testTag={`play-submission-${taskSubmissionDetails.taskSubmission.id}`}
        />
      </Flex>
      {showStats && (
        <Flex margin={`${margins[4]} 0 0 0`} wrap="wrap">
          {metricsTopRow.map((metric) => (
            <Metric key={metric.label} {...metric} />
          ))}
        </Flex>
      )}
    </Flex>
  ) : (
    <NoAnswer taskDetails={taskSubmissionDetails} />
  );
};

const ComprehensionSubmissionViewer = ({
  taskSubmissionDetails,
  drawerExpanded,
  textSize,
  comprehensionTextDiff,
  canSeeTranscription = false,
  usageCapsEnabled,
  availableUltimateAssessments,
  toolTipOnClick,
}: {
  taskSubmissionDetails: ComprehensionTaskSubmissionDetails;
  drawerExpanded: boolean;
  canSeeTranscription: boolean;
  textSize?: number;
  comprehensionTextDiff?: ImmutableList<ComprehensionTextDiffItem>;
  usageCapsEnabled: boolean;
  availableUltimateAssessments: number;
  toolTipOnClick: () => void;
}) => {
  const responseType = taskSubmissionDetails.task.responseType;
  const hasAudioResponse =
    ['recorded', 'student_choice'].includes(responseType) &&
    Boolean(taskSubmissionDetails.responseAudioFile?.url);
  const hasTextResponse =
    ['written', 'student_choice'].includes(responseType) &&
    Boolean(taskSubmissionDetails.taskSubmission.responseContent);

  const [showText, setShowText] = useState(true);
  const [showTranscript, setShowTranscript] = useState(false);
  const [time, setTime] = useState(0);

  return (
    <Flex
      direction="column"
      width="100%"
      align="start"
      maxHeight={drawerExpanded ? '100%' : '300px'}
    >
      {hasAudioResponse && (
        <>
          <Flex width="100%">
            <Text variant="h6">Audio Response:</Text>
            <Spacer horizontal size={11} />
            {comprehensionTextDiff && (
              <PremiumTooltip
                enabled={!canSeeTranscription}
                content={`Upgrade to ${ENTERPRISE_PLAN_LABEL} and unlock student response transcripts`}
                linkLocation="contact"
                usageCapsEnabled={usageCapsEnabled}
                availableUltimateAssessments={availableUltimateAssessments}
                onClick={toolTipOnClick}
              >
                <Button
                  displayType="link"
                  disabled={!canSeeTranscription}
                  onClick={() => setShowTranscript(!showTranscript)}
                  paddingOverride={paddings.none}
                >
                  {showTranscript ? 'Hide Transcript' : 'Show Transcript'}
                </Button>
              </PremiumTooltip>
            )}
          </Flex>
          <Flex width="100%" align="center">
            <AudioPlayer
              placeholder="Student Audio"
              src={taskSubmissionDetails.responseAudioFile?.url || ''}
              audioFileId={taskSubmissionDetails.responseAudioFile?.id}
              displayType="desktopSmall"
              testTag={`play-response-${taskSubmissionDetails.taskSubmission.id}`}
              playIndex={time}
              initialPlayProgress={time}
            />
          </Flex>
          <Spacer />
          {showTranscript && comprehensionTextDiff && (
            <Text
              testTag="transcript-container"
              whiteSpace="pre-wrap"
              maxWidth={drawerExpanded ? undefined : '400px'}
              minHeight={showTranscript ? '50px' : '0px'}
              flexGrow={1}
              overflowY="auto"
              sizeOverride={textSize}
              wordBreak="break-all"
            >
              {comprehensionTextDiff.map((word, i) => {
                const nextWord = comprehensionTextDiff.get(i + 1);
                return (
                  <Fragment key={i}>
                    <Button
                      onClick={() => setTime(word.startTime)}
                      display="inline"
                      flexDisplay="inline-flex"
                      displayType="noStyles"
                      sizeOverride={textSize}
                    >
                      {word.content}
                    </Button>
                    {nextWord?.type === 'punctuation' ? '' : ' '}
                  </Fragment>
                );
              })}
            </Text>
          )}
        </>
      )}

      {hasAudioResponse && hasTextResponse && <Spacer size={3} />}
      {hasTextResponse && (
        <>
          <Flex width="100%">
            <Text variant="h6">Written Response:</Text>
            <Spacer horizontal size={8} />
            <Button
              displayType="link"
              onClick={() => setShowText(!showText)}
              paddingOverride={paddings.none}
            >
              {showText ? 'Hide Response' : 'Show Response'}
            </Button>
          </Flex>
          <Spacer />
          {showText && (
            <Text
              whiteSpace="pre-wrap"
              maxWidth="400px"
              minWidth="100%"
              flexGrow={1}
              overflowY="auto"
              sizeOverride={textSize}
              minHeight={showText ? '50px' : '0px'}
              wordBreak="break-all"
            >
              {taskSubmissionDetails.taskSubmission.responseContent}
            </Text>
          )}
        </>
      )}
      {!hasAudioResponse && !hasTextResponse && <NoAnswer taskDetails={taskSubmissionDetails} />}
    </Flex>
  );
};

const ModelTaskStudio = ({
  taskSubmissionDetails,
}: {
  taskSubmissionDetails: ModelTaskSubmissionDetails;
}) => {
  if (taskSubmissionDetails.taskSubmission.completed) {
    return (
      <Flex align="center">
        <Icon icon="check" color="success" size={standardMeasurements[6]} />
        <Spacer horizontal />
        <Text variant="h6">Student listened to the model reading</Text>
      </Flex>
    );
  } else {
    return <NoAnswer taskDetails={taskSubmissionDetails} />;
  }
};
const VocabTaskViewer = ({
  taskSubmissionDetails,
}: {
  taskSubmissionDetails: VocabTaskSubmissionDetails;
}) => {
  if (taskSubmissionDetails.taskSubmission.completed) {
    return (
      <Flex align="center">
        <Icon icon="check" color="success" size={standardMeasurements[6]} />
        <Spacer horizontal />
        <Text variant="h6">Student viewed the vocab task</Text>
      </Flex>
    );
  } else {
    return <NoAnswer taskDetails={taskSubmissionDetails} />;
  }
};

export const TaskSubmissionViewer = ({
  submission,
  taskDetails,
  taskSubmissionDetails,
  initialPlayProgress,
  playIndex,
  comprehensionTextDiff,
  showStats = true,
  drawerExpanded = false,
  canSeeTranscription = false,
  textSize = 16,
  usageCapsEnabled,
  availableUltimateAssessments,
  toolTipOnClick,
}: {
  submission?: SubmissionExtended;
  taskDetails: TaskDetails;
  taskSubmissionDetails: TaskSubmissionDetails | null;
  initialPlayProgress?: number;
  playIndex?: number;
  showStats?: boolean;
  drawerExpanded?: boolean;
  canSeeTranscription: boolean;
  textSize?: number;
  comprehensionTextDiff?: ImmutableList<ComprehensionTextDiffItem>;
  usageCapsEnabled: boolean;
  availableUltimateAssessments: number;
  toolTipOnClick: () => void;
}) => {
  if (!taskSubmissionDetails) return <NoAnswer taskDetails={taskDetails} />;
  if (taskSubmissionDetails.type === 'ReadingTask') {
    return (
      <ReadingSubmissionViewer
        submission={submission}
        taskSubmissionDetails={taskSubmissionDetails}
        initialPlayProgress={initialPlayProgress || 0}
        playIndex={playIndex || 0}
        showStats={showStats}
      />
    );
  } else if (taskSubmissionDetails.type === 'ComprehensionTask') {
    return (
      <ComprehensionSubmissionViewer
        taskSubmissionDetails={taskSubmissionDetails}
        drawerExpanded={drawerExpanded}
        textSize={textSize}
        comprehensionTextDiff={comprehensionTextDiff}
        canSeeTranscription={canSeeTranscription}
        usageCapsEnabled={usageCapsEnabled}
        availableUltimateAssessments={availableUltimateAssessments}
        toolTipOnClick={toolTipOnClick}
      />
    );
  } else if (taskSubmissionDetails.type === 'ModelTask') {
    return <ModelTaskStudio taskSubmissionDetails={taskSubmissionDetails} />;
  } else if (taskSubmissionDetails.type === 'VocabTask') {
    return <VocabTaskViewer taskSubmissionDetails={taskSubmissionDetails} />;
  } else {
    throw new Error('Unrecognized task type');
  }
};
