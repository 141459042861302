import { AudioRecorder } from 'src/components';
import { useCompactLayout } from 'src/layouts/ReadingStudioLayout/ReadingStudioLayoutHelpers';
import { useStore } from 'src/Store';
import styled from 'styled-components';
import { useCurrentTaskSubmissionDetails, useIsPublicPreview } from './ReadingStudioHelpers';

const MobileRecorder = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;
export const ReadingTaskStudio = () => {
  const {
    dirty,
    assessment,
    onRecordingSequenceStatus,
    onAudioRecorderStatus,
    submit,
    emitPaperEvent,
  } = useStore(
    ({ ReadingStudioData: s, AppData: ad }) => ({
      assessment: s.assessment,
      dirty: s.dirty,
      onRecordingSequenceStatus: s.onRecordingSequenceStatus,
      onAudioRecorderStatus: s.onAudioRecorderStatus,
      submit: s.submit,
      emitPaperEvent: ad.emitPaperEvent,
    }),
    [],
  );

  const isPublicPreview = useIsPublicPreview();

  const currentTaskSubmissionDetails = useCurrentTaskSubmissionDetails();
  const compactLayout = useCompactLayout();

  if (!currentTaskSubmissionDetails) return null;
  if (currentTaskSubmissionDetails.type !== 'ReadingTask') return null;
  if (isPublicPreview) return null;

  const recorder = (
    <AudioRecorder
      recordingSequence={currentTaskSubmissionDetails.recordingSequence}
      audioFile={currentTaskSubmissionDetails.audioFile}
      onRecordingSequenceStatus={onRecordingSequenceStatus}
      onAudioRecorderStatus={onAudioRecorderStatus}
      displayType={compactLayout ? 'mobile' : 'desktop'}
      dirty={dirty}
      onSave={() => submit({ clearDirty: true })}
      showCountdown
      onRecordingStart={(recordingId) => {
        if (!assessment) return;

        emitPaperEvent({
          type: 'literacy_recording_started_v3',
          taskId: currentTaskSubmissionDetails.task.id,
          assessmentId: assessment.id,
          recordingSequenceId: currentTaskSubmissionDetails.recordingSequence.id,
          recordingId,
        });
      }}
      onRecordingStop={(recordingId) => {
        if (!assessment) return;

        emitPaperEvent({
          type: 'literacy_recording_ended_v3',
          taskId: currentTaskSubmissionDetails.task.id,
          assessmentId: assessment.id,
          recordingSequenceId: currentTaskSubmissionDetails.recordingSequence.id,
          recordingId,
        });
      }}
    />
  );

  if (compactLayout) {
    return <MobileRecorder>{recorder}</MobileRecorder>;
  }

  return recorder;
};
