import styled, { css } from 'styled-components';
import { colors, paddings, borderRadii } from 'src/styles';
import { Input } from './Input';

const SearchContainer = styled.div<{ $displayType: 'normal' | 'large' }>`
  background: ${colors.white.hex};
  border-radius: ${borderRadii.rounded};
  border: 1px solid ${colors.gray2.hex};
  padding: ${paddings[1]} ${paddings[2]};
  color: ${colors.gray5.hex};
  font-weight: 400;

  ${({ $displayType }) =>
    $displayType === 'large'
      ? css`
          width: 100%;
          padding: ${paddings[2]} ${paddings[5]};
        `
      : ''}
`;

export const SearchInput = ({
  displayType = 'normal',
  ...props
}: {
  value: string;
  onChange: (v: string) => void;
  placeholder?: string;
  displayType?: 'normal' | 'large';
}) => (
  <SearchContainer $displayType={displayType}>
    <Input
      name="Search Bar"
      icon="search"
      iconPosition="left"
      flexGrow={1}
      fontSize={displayType === 'large' ? 16 : 14}
      {...props}
    />
  </SearchContainer>
);
