import { Button, Flex, FormGroup, LinkButton, Spacer, Form } from 'src/components';
import { useStore } from 'src/Store';

type AddSchoolProps = {
  cancelLink: string;
  onSaveSuccess: () => void;
};

export const AddSchool = ({ cancelLink, onSaveSuccess }: AddSchoolProps) => {
  const { manualSchool, saving, manualSchoolErrors, setManualSchoolField, saveManualSchool } =
    useStore((s) => s.SchoolSearchData, []);

  return (
    <Form onSubmit={() => saveManualSchool(onSaveSuccess)}>
      <FormGroup
        type="text"
        label="Name"
        name="name"
        value={manualSchool.name}
        onChange={setManualSchoolField('name')}
        errors={manualSchoolErrors}
      />
      <Spacer size={3} />
      <FormGroup
        type="text"
        label="City"
        name="city"
        value={manualSchool.city}
        onChange={setManualSchoolField('city')}
        errors={manualSchoolErrors}
      />
      <Spacer size={3} />
      <FormGroup
        type="text"
        label="State"
        name="state"
        value={manualSchool.state}
        onChange={setManualSchoolField('state')}
        errors={manualSchoolErrors}
      />
      <Spacer size={3} />
      <FormGroup
        type="text"
        label="Country"
        name="country"
        value={manualSchool.country}
        onChange={setManualSchoolField('country')}
        errors={manualSchoolErrors}
      />
      <Spacer size={3} />
      <FormGroup
        type="number"
        label="Zip Code"
        name="zipCode"
        value={manualSchool.zipCode}
        onChange={setManualSchoolField('zipCode')}
        errors={manualSchoolErrors}
        noSpinner
        integerOnly
      />

      <Spacer size={4} />
      <Flex justify="end">
        <LinkButton to={cancelLink}>Cancel</LinkButton>
        <Spacer horizontal size={2} />
        <Button type="submit" loading={saving}>
          Continue
        </Button>
      </Flex>
    </Form>
  );
};
