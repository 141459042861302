import { AssessmentExtended, Course, SubmissionExtended, TaskSubmissionDetails } from 'src/models';
import { Comment } from 'src/models/Comment';
import { ImmutableList, ImmutableMap, ImmutableSet } from 'src/modules/Immutable';
import { ApiStat } from 'src/modules/Stat';

export type TaskSubmissions = {
  submissionId: string;
  taskSubmissionDetailsMap: ImmutableMap<string, TaskSubmissionDetails>;
};

export type StudentDashboardResponse = {
  stats: ImmutableList<ApiStat>;
  courseMap: ImmutableMap<string, Course>;
  assessmentMap: ImmutableMap<string, AssessmentExtended>;
  submissionMap: ImmutableMap<string, SubmissionExtended>;
  taskSubmissions: ImmutableList<TaskSubmissions>;
  assessmentToCourseMap: ImmutableMap<string, ImmutableList<string>>;
  commentMap: ImmutableMap<string, ImmutableList<Comment>>;
  readingNameMap: ImmutableMap<string, string>;
};

export type StudentDashboardState = {
  submissionMap: ImmutableMap<string, SubmissionExtended>;
  taskSubmissions: ImmutableList<TaskSubmissions>;
  stats: ImmutableList<ApiStat>;
  courseMap: ImmutableMap<string, Course>;
  assessmentMap: ImmutableMap<string, AssessmentExtended>;
  assessmentToCourseMap: ImmutableMap<string, ImmutableList<string>>;
  commentMap: ImmutableMap<string, ImmutableList<Comment>>;
  readingNameMap: ImmutableMap<string, string>;
  loading: boolean;
  openAssignmentCardSet: ImmutableSet<string>;
  load: () => void;
  toggleAssignmentCardOpen: (value: string) => void;
};

export const defaultState = {
  assessmentMap: ImmutableMap<string, AssessmentExtended>(),
  submissionMap: ImmutableMap<string, SubmissionExtended>(),
  taskSubmissions: ImmutableList<TaskSubmissions>(),
  stats: ImmutableList<ApiStat>(),
  courseMap: ImmutableMap<string, Course>(),
  assessmentToCourseMap: ImmutableMap<string, ImmutableList<string>>(),
  loading: true,
  openAssignmentCardSet: ImmutableSet<string>(),
  commentMap: ImmutableMap<string, ImmutableList<Comment>>(),
  readingNameMap: ImmutableMap<string, string>(),
};
