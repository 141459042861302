import { useMemo } from 'react';
import { useStore } from 'src/Store';
import { useBreakpoints } from 'src/styles';

export const compactLayoutBreakpoint = 'tablet';
export const useCompactLayout = () => useBreakpoints({ smallerThan: compactLayoutBreakpoint });
export const useCommonStateValues = () => {
  const { courseAssessmentsAssigned, selectedCourse } = useStore((s) => s.AssignToData, []);
  const hasCourseAssessment = useMemo(
    () =>
      courseAssessmentsAssigned.some(
        (courseAssessmentData) =>
          courseAssessmentData.courseId === selectedCourse?.id && courseAssessmentData.assigned,
      ),
    [courseAssessmentsAssigned, selectedCourse],
  );
  return { hasCourseAssessment };
};
