import { useStore } from 'src/Store';
import { useQuery, useHistory } from 'src/modules/Router';
import { Text, Button, Spacer, StandardModal } from 'src/components';
import { useBackPath, useIncompleteTaskId, useIsPublicPreview } from './ReadingStudioHelpers';

export const UnfinishedTasksModal = () => {
  const history = useHistory();
  const query = useQuery();

  const { submit, confirmingFinished, submitting, switchTask, leaving, closeConfirmationModal } =
    useStore(
      (state) => ({
        submit: state.ReadingStudioData.submit,
        confirmingFinished: state.ReadingStudioData.confirmingFinished,
        submitting: state.ReadingStudioData.submitting,
        switchTask: state.ReadingStudioData.switchTask,
        leaving: state.ReadingStudioData.leaving,
        closeConfirmationModal: state.ReadingStudioData.closeConfirmationModal,
      }),
      [],
    );

  const incompleteTaskId = useIncompleteTaskId();
  const backPath = useBackPath();
  const isPublicPreview = useIsPublicPreview();

  return (
    <StandardModal
      header="You have unfinished tasks"
      openType="state"
      opened={confirmingFinished}
      close={closeConfirmationModal}
      footer={
        <>
          {incompleteTaskId && (
            <Button
              inheritBgColor
              displayType="link"
              color="primaryBlue"
              onClick={switchTask({
                taskId: incompleteTaskId,
                history,
                query,
                isPublicPreview,
              })}
            >
              Keep Working
            </Button>
          )}
          <Spacer horizontal />
          <Button
            loading={submitting}
            onClick={() => {
              submit({
                finished: !leaving,
                clearDirty: true,
                override: true,
                onSuccess: () => {
                  if (leaving) {
                    history.push(backPath);
                  }
                },
              });
            }}
          >
            {leaving ? 'Leave' : 'Submit'} Anyway
          </Button>
        </>
      }
    >
      <Text>
        You have unfinished tasks. Are you sure you would like to {leaving ? 'leave' : 'submit'}?
      </Text>
    </StandardModal>
  );
};
