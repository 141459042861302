import { subDataCreator } from 'src/State';
import { CreateToast } from 'src/components';
import { ImmutableMap } from 'src/modules/Immutable';
import { ErrorMap } from 'src/modules/Api';
import { defaultState } from './ChangePasswordModalState';

type UpdateResponse = {
  success: boolean;
  errors: ErrorMap;
};
export const createDefaultChangePasswordModalData = subDataCreator(
  'ChangePasswordModalData',
  defaultState,
  ({ get, set, setField, fetchJson }) => ({
    openModal: () => set({ opened: true }),
    closeModal: () => set(defaultState),
    changePassword: (createToast: CreateToast) => {
      const { currentPassword, newPassword, passwordConfirmation } = get();
      set({ saving: true });
      fetchJson(`/api/users/update_teacher_password`, {
        method: 'PATCH',
        data: { currentPassword, newPassword, passwordConfirmation },
        onSuccess: (data: UpdateResponse) => {
          if (data.success) {
            set(defaultState);
            createToast({
              children: 'Password updated!',
              color: 'success',
            });
          } else {
            set({ errors: ImmutableMap(data.errors), saving: false });
          }
        },
      });
    },
    setField,
  }),
);
