import { useEffect, useState } from 'react';
import { makeQueryString, useFetch, ErrorMap } from 'src/modules/Api';
import { useHistory, useQuery } from 'src/modules/Router';
import { CleverOauthResponse } from 'src/modules/Clever';
import { useStore } from 'src/Store';
import { SimpleLayout } from 'src/layouts';
import { ImmutableList, ImmutableMap } from 'src/modules/Immutable';

export const CleverOauthCallback = () => {
  const history = useHistory();
  const query = useQuery();
  const { fetchJson } = useFetch();
  const [loading, setLoading] = useState(true);

  const { onLoggedIn, onSignedUp } = useStore(
    (state) => ({
      onLoggedIn: state.AppData.onLoggedIn,
      onSignedUp: state.AppData.onSignedUp,
    }),
    [],
  );
  const code = query.get('code');
  const scope = query.get('scope');
  const state = query.get('state');
  const oauthComplete = query.get('oauthComplete');
  useEffect(() => {
    setLoading(true);
    if (!(code || oauthComplete)) {
      const message = `Clever login failed.`;
      history.replace(
        `/500?message=${encodeURIComponent(message)}&originalPath=${encodeURIComponent('/login')}`,
      );
    }

    fetchJson(`/api/clever_oauths`, {
      method: 'POST',
      data: {
        code,
        scope,
        state,
      },
      onSuccess: (data: CleverOauthResponse) => {
        if (data.status === 'success') {
          if (data.user && data.isNew) {
            onSignedUp({
              redirectPath: data.successUrl,
              history,
              method: 'clever',
              user: data.user,
              schools: ImmutableList(),
              replace: true,
            });
          } else {
            onLoggedIn({
              redirectPath: data.successUrl,
              history,
              method: 'clever',
              replace: true,
            });
          }
        } else if (data.status === 'oauth_required') {
          history.replace(
            `/login${makeQueryString({
              redirectPath: data.successUrl ?? '',
            })}`,
          );
        } else if (data.status === 'errored') {
          const message = `Clever login failed.`;
          history.replace(
            `/500${makeQueryString({
              message,
              originalPath: `/login${makeQueryString({
                redirectPath: data.successUrl ?? '',
              })}`,
            })}`,
          );
        } else if (data.status === 'needs_email_confirmation') {
          history.push(
            `/confirmation-sent${makeQueryString(
              data.user?.email
                ? {
                    email: data.user.email,
                    firstName: data.user.displayName,
                  }
                : {},
            )}`,
          );
        } else if (data.status === 'fields_missing') {
          const errors: ErrorMap = ImmutableMap(data.errors || {});
          if (errors.has('accountType')) {
            history.replace(
              `/sign-up${makeQueryString({
                sso: 'clever',
                ssoName: 'Clever',
                redirectPath: data.successUrl ?? '',
              })}`,
            );
          } else {
            history.replace(
              `/sign-up/${data.user?.accountType ?? 'student'}/register${makeQueryString({
                sso: 'clever',
                ssoName: 'Clever',
                fieldsMissing: Array.from(errors.keys()).join(','),
                errors: JSON.stringify(data.errors || {}),
                email: data.user?.email ? data.user.email : '',
                redirectPath: data.successUrl ?? '',
              })}`,
            );
          }
        }
      },
    });
  }, [code, oauthComplete, history, onLoggedIn, scope, state, onSignedUp, fetchJson]);
  return <SimpleLayout loading={loading}>Clever Login</SimpleLayout>;
};
