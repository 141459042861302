import {
  AssessmentExtended,
  Submission,
  TaskSubmissionDetails,
  AddRecordingResponse,
  ComprehensionTaskSubmissionResponseType,
  ModelTaskSubmissionDetails,
  VocabTaskSubmissionDetails,
  ImageStoredFile,
  InstructionDetails,
} from 'src/models';
import { ErrorMap, ErrorMapResponse } from 'src/modules/Api';
import { History, QueryMap } from 'src/modules/Router';
import { ImmutableMap } from 'src/modules/Immutable';
import { SetField } from 'src/State';
import { AudioRecorderStatus, CreateToast } from 'src/components';

export type TurnInResponse = {
  submission: Submission;
  taskErrors: ImmutableMap<string, ErrorMapResponse>;
  success: boolean;
};

export type ReadingStudioState = {
  loading: boolean;
  assessment: AssessmentExtended | null;
  submission: Submission | null;
  taskSubmissionDetailsMap: ImmutableMap<string, TaskSubmissionDetails>;
  currentTaskId: string | null;
  submitting: boolean;
  taskErrors: ImmutableMap<string, ErrorMap>;
  dirty: boolean;
  confirmingFinished: boolean;
  setField: SetField<ReadingStudioState>;
  showFinishedModal: boolean;
  drawerExpanded: boolean;
  leaving: boolean;
  mobileTaskListOpened: boolean;
  audioRecorderStatus: AudioRecorderStatus;
  rating: number | null;
  contentModalOpened: boolean;
  profileImageFile: ImageStoredFile | null;
  instructionDetails: InstructionDetails | null;
  load: (opts: { assessmentId: string | undefined; isPublicPreview: boolean }) => void;
  onRecordingSequenceStatus: (data: AddRecordingResponse) => unknown;
  onResponseRecordingSequenceStatus: (data: AddRecordingResponse) => void;
  setCurrentTask: (taskId: string | null) => void;
  submit: (opts: {
    finished?: boolean;
    onSuccess?: (data: TurnInResponse) => void;
    override?: boolean;
    history?: History;
    clearDirty?: boolean;
  }) => void;
  setResponseType: (responseType: ComprehensionTaskSubmissionResponseType) => void;
  setResponseContent: (value: string) => void;
  switchTask: (opts: {
    taskId: string | null;
    history: History;
    query: QueryMap;
    isPublicPreview: boolean;
  }) => () => void;
  addToLibrary: (opts: { history: History; createToast: CreateToast }) => () => void;
  onCompleteTask: (
    taskSubmissionDetails: ModelTaskSubmissionDetails | VocabTaskSubmissionDetails,
  ) => void;
  submitRating: (rate: number) => void;
  toggleMobileTaskList: () => void;
  onAudioRecorderStatus: (s: AudioRecorderStatus) => void;
  openContentModal: () => void;
  closeContentModal: () => void;
  closeConfirmationModal: () => void;
  leave: (backPath: string, history: History) => () => void;
};

export const defaultState = {
  loading: true,
  assessment: null,
  submission: null,
  taskSubmissionDetailsMap: ImmutableMap<string, TaskSubmissionDetails>(),
  currentTaskId: null,
  submitting: false,
  showFinishedModal: false,
  taskErrors: ImmutableMap<string, ErrorMap>(),
  dirty: false,
  confirmingFinished: false,
  drawerExpanded: false,
  rating: null,
  mobileTaskListOpened: false,
  audioRecorderStatus: 'not_started',
  contentModalOpened: false,
  leaving: false,
  profileImageFile: null,
  instructionDetails: null,
} as const;
