import { createStrictStoreContext, RestrictedStateCreator } from 'src/modules/Zustand';
import { State } from './State';
import { createDefaultLoginData } from 'src/pages/Login/LoginActions';
import { createDefaultForgotPasswordData } from 'src/pages/ForgotPassword/ForgotPasswordActions';
import { createDefaultAppData } from 'src/AppActions';
import { createDefaultResetPasswordData } from 'src/pages/ResetPassword/ResetPasswordActions';
import { createDefaultReadingStudioData } from 'src/pages/ReadingStudio/ReadingStudioActions';
import { createDefaultSignUpClassCodeData } from 'src/pages/SignUpClassCode/SignUpClassCodeActions';
import { createDefaultSignUpSchoolCodeData } from 'src/pages/SignUpSchoolCode/SignUpSchoolCodeActions';
import { createDefaultSignUpRegisterData } from 'src/pages/SignUpRegister/SignUpRegisterActions';
import { createDefaultSignUpConfirmationSentData } from 'src/pages/SignUpConfirmationSent/SignUpConfirmationSentActions';
import { createDefaultTeacherCourseListData } from 'src/pages/TeacherCourseList/TeacherCourseListActions';
import { createDefaultCreateCourseData } from 'src/pages/CreateCourseModal/CreateCourseActions';
import { createDefaultImportGoogleCourseData } from 'src/pages/ImportGoogleCourseModal/ImportGoogleCourseActions';
import { createDefaultImportCleverCourseData } from 'src/pages/ImportCleverCourseModal/ImportCleverCourseActions';
import { createDefaultTeacherStudentListData } from 'src/pages/TeacherStudentList/TeacherStudentListActions';
import { createDefaultStudentDashboardData } from 'src/pages/StudentDashboard/StudentDashboardActions';
import { createDefaultJoinCourseData } from 'src/components/JoinCourse/JoinCourseActions';
import { createDefaultTeacherAssignmentListData } from 'src/pages/TeacherAssignmentList/TeacherAssignmentListActions';
import { createDefaultTeacherAssignmentOverviewData } from 'src/pages/TeacherAssignmentOverview/TeacherAssignmentOverviewActions';
import { createDefaultSubmissionOverviewData } from 'src/pages/SubmissionOverview/SubmissionOverviewActions';
import { createDefaultAssignToData } from 'src/pages/AssignToModal/AssignToActions';
import { createDefaultAssignmentBuilderData } from 'src/pages/AssignmentBuilder/AssignmentBuilderActions';
import { createDefaultReadingBuilderData } from 'src/pages/ReadingBuilder/ReadingBuilderActions';
import { createDefaultDictionaryData } from 'src/components/Dictionary/DictionaryActions';
import { createDefaultTeacherOnboardingData } from 'src/pages/TeacherOnboarding/TeacherOnboardingActions';
import { createDefaultUserSearchData } from 'src/pages/UserSearch/UserSearchActions';
import { createDefaultProgressMonitoringData } from 'src/pages/ProgressMonitoring/ProgressMonitoringActions';
import { createDefaultStudentProgressMonitoringData } from 'src/pages/StudentProgressMonitoring/StudentProgressMonitoringActions';
import { createDefaultPlanListData } from 'src/pages/PlanList/PlanListActions';
import { createDefaultTaskOverviewData } from 'src/pages/TaskOverview/TaskOverviewActions';
import { createDefaultUpdateAssignmentStatusModalData } from 'src/pages/UpdateAssignmentStatusModal/UpdateAssignmentStatusModalActions';
import { createDefaultShareModalData } from 'src/pages/ShareModal/ShareModalActions';
import { createDefaultDuplicateModalData } from 'src/pages/DuplicateModal/DuplicateModalActions';
import { createDefaultContentLibraryData } from 'src/pages/ContentLibrary/ContentLibraryActions';
import { createDefaultMobileGateData } from 'src/components/MobileGate/MobileGateActions';
import { createDefaultChangePasswordModalData } from 'src/pages/UserProfile/ChangePasswordModal/ChangePasswordModalActions';
import { createDefaultAvatarBuilderData } from 'src/pages/AvatarBuilder/AvatarBuilderActions';
import { createDefaultAvatarGalleryData } from 'src/pages/AvatarGallery/AvatarGalleryActions';
import { createDefaultUpgradeAssignmentModalData } from 'src/components/UpgradeAssignmentModal/UpgradeAssignmentModalActions';
import { createDefaultPlaylistManagerData } from 'src/pages/PlaylistManager/PlaylistManagerActions';
import { createDefaultPaperSyncData } from 'src/components/PaperSync/PaperSyncActions';
import { FetchState } from './modules/Api';
import { createDefaultSchoolSearchData } from './pages/SchoolSearch/SchoolSearchActions';

export const createInitialState = (FetchData: FetchState) => {
  const createState: RestrictedStateCreator<State> = (setFull, getFull): State => {
    return {
      ForgotPasswordData: createDefaultForgotPasswordData({ setFull, getFull }),
      LoginData: createDefaultLoginData({ setFull, getFull }),
      AppData: createDefaultAppData({ setFull, getFull }),
      ResetPasswordData: createDefaultResetPasswordData({ setFull, getFull }),
      ReadingStudioData: createDefaultReadingStudioData({ setFull, getFull }),
      SignUpClassCodeData: createDefaultSignUpClassCodeData({ setFull, getFull }),
      SignUpSchoolCodeData: createDefaultSignUpSchoolCodeData({ setFull, getFull }),
      SignUpRegisterData: createDefaultSignUpRegisterData({ setFull, getFull }),
      SignUpConfirmationSentData: createDefaultSignUpConfirmationSentData({ setFull, getFull }),
      TeacherCourseListData: createDefaultTeacherCourseListData({ setFull, getFull }),
      CreateCourseData: createDefaultCreateCourseData({ setFull, getFull }),
      ImportGoogleCourseData: createDefaultImportGoogleCourseData({ setFull, getFull }),
      ImportCleverCourseData: createDefaultImportCleverCourseData({ setFull, getFull }),
      TeacherStudentListData: createDefaultTeacherStudentListData({ setFull, getFull }),
      JoinCourseData: createDefaultJoinCourseData({ setFull, getFull }),
      StudentDashboardData: createDefaultStudentDashboardData({ setFull, getFull }),
      TeacherAssignmentListData: createDefaultTeacherAssignmentListData({ setFull, getFull }),
      TeacherAssignmentOverviewData: createDefaultTeacherAssignmentOverviewData({
        setFull,
        getFull,
      }),
      SubmissionOverviewData: createDefaultSubmissionOverviewData({
        setFull,
        getFull,
      }),
      AssignToData: createDefaultAssignToData({ setFull, getFull }),
      AssignmentBuilderData: createDefaultAssignmentBuilderData({ setFull, getFull }),
      ReadingBuilderData: createDefaultReadingBuilderData({ setFull, getFull }),
      UserSearchData: createDefaultUserSearchData({ setFull, getFull }),
      DictionaryData: createDefaultDictionaryData({ setFull, getFull }),
      TeacherOnboardingData: createDefaultTeacherOnboardingData({ setFull, getFull }),
      ProgressMonitoringData: createDefaultProgressMonitoringData({ setFull, getFull }),
      StudentProgressMonitoringData: createDefaultStudentProgressMonitoringData({
        setFull,
        getFull,
      }),
      PlanListData: createDefaultPlanListData({ setFull, getFull }),
      TaskOverviewData: createDefaultTaskOverviewData({ setFull, getFull }),
      UpdateAssignmentStatusModalData: createDefaultUpdateAssignmentStatusModalData({
        setFull,
        getFull,
      }),
      ShareModalData: createDefaultShareModalData({ setFull, getFull }),
      DuplicateModalData: createDefaultDuplicateModalData({ setFull, getFull }),
      FetchData,
      ContentLibraryData: createDefaultContentLibraryData({ setFull, getFull }),
      ChangePasswordModalData: createDefaultChangePasswordModalData({ setFull, getFull }),
      MobileGateData: createDefaultMobileGateData({ setFull, getFull }),
      AvatarBuilderData: createDefaultAvatarBuilderData({ setFull, getFull }),
      AvatarGalleryData: createDefaultAvatarGalleryData({ setFull, getFull }),
      UpgradeAssignmentModalData: createDefaultUpgradeAssignmentModalData({ setFull, getFull }),
      SchoolSearchData: createDefaultSchoolSearchData({ setFull, getFull }),
      PlaylistManagerData: createDefaultPlaylistManagerData({ setFull, getFull }),
      PaperSyncData: createDefaultPaperSyncData({ setFull, getFull }),
    };
  };
  return createState;
};

const { useStore, Provider } = createStrictStoreContext<State>();

export { useStore, Provider };
