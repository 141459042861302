import { ImageStoredFile } from './ImageStoredFile';
import { Author } from './Author';
import { Reading } from './Reading';
import { TextStoredFile } from './TextStoredFile';
import { EpubStoredFile, EpubChapter } from './EpubStoredFile';
import { PdfStoredFile } from './PdfStoredFile';
import { TextRange } from '../modules/TextRange';
import { PdfByPageStoredFile } from './PdfByPageStoredFile';

type PageReadingTask = {
  readingPartType: 'pages';
  startPoint: { pageNumber: number };
  endPoint: { pageNumber: number };
};

type StartAndEndIndexReadingTask = {
  readingPartType: 'start_and_end_index';
  newBasicText?: string;
} & TextRange;

// type StartAndEndTextReadingTask = {
//   readingPartType: 'start_and_end_text';
//   startPoint: {
//     text: string;
//   };
//   endPoint: { text: string };
// };

type ChapterReadingTask = {
  readingPartType: 'chapters';
  startPoint: EpubChapter;
  endPoint: EpubChapter;
};

type IndependentReadingTask = {
  readingPartType: 'independent';
};

type WholeTextReadingTask = {
  readingPartType: 'whole_text';
};

export type ReadingTask = {
  id: string;
  name: string;
  type: 'ReadingTask';
  basicTextFileId: string;
  readingId: string;
} & (
  | IndependentReadingTask
  | PageReadingTask
  | StartAndEndIndexReadingTask
  | WholeTextReadingTask
  | ChapterReadingTask
);
export type ReadingPartType = ReadingTask['readingPartType'];

export type ReadingTaskDetails = {
  type: 'ReadingTask';
  task: ReadingTask;
  reading: Reading;
  basicTextFile: TextStoredFile;
  author: Author | null;
  coverImageFile: ImageStoredFile | null;
  contentFile: TextStoredFile | EpubStoredFile | PdfStoredFile | PdfByPageStoredFile | null;
};

export const setInitialReadingPartType = (
  taskDetails: ReadingTaskDetails,
  readingPartType: ReadingPartType,
): ReadingTaskDetails => {
  if (readingPartType === 'chapters') {
    if (taskDetails.contentFile?.type === 'EpubStoredFile') {
      const chapters = taskDetails.contentFile.tableOfContents;
      const firstChapter = chapters.first();
      const lastChapter = chapters.last();
      if (!firstChapter || !lastChapter) {
        throw new Error('This epub does not have chapters');
      }
      return {
        ...taskDetails,
        task: {
          ...taskDetails.task,
          readingPartType,
          startPoint: firstChapter,
          endPoint: lastChapter,
        },
      };
    } else {
      throw new Error('Chapter selection is only valid with epub files.');
    }
  } else if (readingPartType === 'pages') {
    if (
      taskDetails.contentFile?.type === 'PdfStoredFile' ||
      taskDetails.contentFile?.type === 'PdfByPageStoredFile'
    ) {
      return {
        ...taskDetails,
        task: {
          ...taskDetails.task,
          readingPartType,
          startPoint: { pageNumber: 0 },
          endPoint: { pageNumber: taskDetails.contentFile.pageCount - 1 },
        },
      };
    } else {
      throw new Error('Page selection is only valid with PDF files.');
    }
  } else if (readingPartType === 'start_and_end_index') {
    return {
      ...taskDetails,
      task: {
        ...taskDetails.task,
        readingPartType,
        newBasicText: '',
        startPoint: {},
        endPoint: {},
      },
    };
  }
  return { ...taskDetails, task: { ...taskDetails.task, readingPartType } };
};
