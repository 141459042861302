import { useEffect, useState, useMemo, useCallback } from 'react';
import 'chart.js/auto';
import { useStore } from 'src/Store';
import {
  Text,
  Flex,
  Spacer,
  Loading,
  StatsBanner,
  DatePicker,
  TimeLabel,
  LinkButton,
  TabView,
  Icon,
  SelectInput,
  SplitBackgroundFlex,
  dateOptions,
  Tooltip,
  Title,
  Button,
  SearchInput,
  PremiumTooltip,
  StreakStatus,
} from 'src/components';
import { CenteredLayout } from 'src/layouts';
import { colors, paddings, margins } from 'src/styles';
import { ImmutableList, searchFilter } from 'src/modules/Immutable';
import { useQuery, useParams, useHistory, updateQuery, useLocation } from 'src/modules/Router';
import { statStyles, formatStat } from 'src/modules/Stat';
import { formatDateTime } from 'src/modules/Time';
import { courseSelectOptions } from 'src/models/Course';
import { downloadCSV } from 'src/modules/Csv';
import { useFetch } from 'src/modules/Api';
import { StudentProgressReadingChart } from './StudentProgressReadingChart';
import { StudentProgressReadingTooltip } from './StudentProgressReadingTooltip';
import { StudentWcpmGraph } from './StudentWcpmGraph';
import { StudentWpmGraph } from './StudentWpmGraph';
import { StudentWcpmTooltip } from './StudentWcpmTooltip';
import { StudentSubmissionTable } from './StudentSubmissionTable';
import { SubmissionResponse } from './StudentProgressMonitoringState';
import { ChartBrochure } from './ChartBrochure';
import { ENTERPRISE_PLAN_LABEL, getFilterLength } from 'src/models';

const columnNames = [
  'Assignment',
  'Completion',
  'WCPM',
  'WPM',
  'Words Read',
  'Time',
  'Tasks',
  'Turned In',
];

export const StudentProgressMonitoring = () => {
  const {
    load,
    loading,
    stats,
    user,
    courseMap,
    submissionResults,
    assessmentMap,
    assessmentToCourseMap,
    teacherMap,
    search,
    setField,
    currentPlan,
    currentUser,
  } = useStore(
    (s) => ({
      load: s.StudentProgressMonitoringData.load,
      loading: s.StudentProgressMonitoringData.loading,
      stats: s.StudentProgressMonitoringData.stats,
      user: s.StudentProgressMonitoringData.user,
      courseMap: s.StudentProgressMonitoringData.courseMap,
      submissionResults: s.StudentProgressMonitoringData.submissionResults,
      assessmentMap: s.StudentProgressMonitoringData.assessmentMap,
      assessmentToCourseMap: s.StudentProgressMonitoringData.assessmentToCourseMap,
      teacherMap: s.StudentProgressMonitoringData.teacherMap,
      search: s.StudentProgressMonitoringData.search,
      setField: s.StudentProgressMonitoringData.setField,
      currentUser: s.AppData.currentUser,
      currentPlan: s.AppData.currentPlan,
    }),
    [],
  );

  const query = useQuery();
  const pathname = useLocation().pathname;
  const backPath = query.get('backPath');
  const studentId = useParams<{ userId: string }>().userId;
  const history = useHistory();

  const selectedClassParam = query.get('selectedCourseId') || 'all';
  const selectedTimeLabelParam =
    (query.get('selectedTime') as TimeLabel) || getFilterLength(currentPlan);
  const [dateRange, setDateRange] = useState<TimeLabel>(selectedTimeLabelParam);
  const startTime: Date | null = useMemo(
    () => dateOptions[dateRange].value?.start ?? null,
    [dateRange],
  );
  const endTime: Date | null = null;
  const prevStartTime: Date | null = useMemo(
    () => dateOptions[dateRange].prev?.start ?? null,
    [dateRange],
  );
  const prevEndTime: Date | null = useMemo(
    () => dateOptions[dateRange].prev?.end ?? null,
    [dateRange],
  );

  useEffect(() => {
    load(studentId, startTime, endTime, prevStartTime, prevEndTime, dateRange);
  }, [load, studentId, startTime, endTime, prevStartTime, prevEndTime, dateRange]);

  const updateClassParam = useCallback(
    (value: string) =>
      history.push(`${pathname}${updateQuery(query, { selectedCourseId: value })}`),
    [history, pathname, query],
  );

  const updateTimeParam = useCallback(
    (value: TimeLabel) => history.push(`${pathname}${updateQuery(query, { selectedTime: value })}`),
    [history, pathname, query],
  );

  const selectedClassOptions = useMemo(
    () => courseSelectOptions(courseMap.toList(), teacherMap, currentUser),
    [teacherMap, courseMap, currentUser],
  );

  const filteredResults = useMemo(
    () =>
      submissionResults.filter((result) => {
        const assessment = assessmentMap.get(result.assessmentId);

        if (selectedClassParam === 'all') return true;

        if (!assessment) return false;

        if (assessment.isFreeRead) return true;

        const assessmentCourses = assessmentToCourseMap.get(assessment.id, ImmutableList<string>());
        if (assessmentCourses.isEmpty()) return false;

        if (selectedClassParam === 'mine') {
          return (
            assessmentCourses
              .map((ac) => courseMap.get(ac)?.creatorId)
              .filter((creatorId) => Boolean(creatorId) && creatorId === currentUser?.id).size > 0
          );
        }

        return assessmentCourses
          .map((ac) => courseMap.get(ac)?.id)
          .filter(Boolean)
          .includes(selectedClassParam);
      }),
    [
      submissionResults,
      selectedClassParam,
      assessmentMap,
      assessmentToCourseMap,
      courseMap,
      currentUser?.id,
    ],
  );

  const studentData = useMemo(
    () =>
      filteredResults.map((submissionResponse) => {
        return [
          assessmentMap.get(submissionResponse.assessmentId)?.name || '',
          submissionResponse.contentType === 'independent' ||
          submissionResponse.submission.percentRead === 0
            ? ''
            : formatStat(statStyles.completion, submissionResponse.submission.percentRead),
          submissionResponse.submission.wcpm === 0
            ? ''
            : formatStat(statStyles.wcpm, submissionResponse.submission.wcpm),
          submissionResponse.submission.totalWords === 0
            ? ''
            : formatStat(statStyles.total_words, submissionResponse.submission.totalWords),
          submissionResponse.submission.timeRead === 0
            ? ''
            : formatStat(statStyles.time_taken, submissionResponse.submission.timeRead),
          !submissionResponse.completedTasksCount && !submissionResponse.totalTasksCount
            ? ''
            : `${submissionResponse.completedTasksCount ?? 0} / ${
                submissionResponse.totalTasksCount ?? 0
              }`,
          formatDateTime(new Date(submissionResponse.submission.finishedAt)),
        ];
      }),
    [assessmentMap, filteredResults],
  );

  const fileData = studentData.insert(0, columnNames);

  const { fetchJson } = useFetch();

  const hasCsvExport = Boolean(currentUser?.flags.isCsvExportEnabled);
  const TooltipElem = hasCsvExport ? Tooltip : PremiumTooltip;
  const tooltipProps = {
    content: hasCsvExport
      ? 'Export Table as CSV'
      : `Upgrade to ${ENTERPRISE_PLAN_LABEL} to seamlessly export all your data`,
    linkLocation: 'contact' as const,
  };

  const submissionRows = useMemo(
    () =>
      searchFilter(
        search,
        filteredResults,
        (r: SubmissionResponse) => assessmentMap.get(r.assessmentId)?.name || '',
      ),
    [search, filteredResults, assessmentMap],
  );

  return (
    <>
      <Title title={user?.displayName ?? 'Student'} />
      <Flex direction="column" backgroundColor={colors.backgroundLight.hex} minWidth={0}>
        <CenteredLayout
          backgroundColor={colors.backgroundDark.hex}
          minWidth={0}
          padding={`${paddings[4]} 0`}
        >
          <Flex>
            <LinkButton to={backPath || '/t/progress-monitoring'} icon="backArrow" color="gray4">
              Back
            </LinkButton>
          </Flex>
        </CenteredLayout>
        <CenteredLayout backgroundColor={colors.backgroundDark.hex}>
          <Flex
            width="100%"
            justify="space-between"
            align="center"
            wrap="wrap"
            margin={`-${margins[2]} 0`}
          >
            <Flex margin={`${margins[2]} ${margins[8]} ${margins[2]} 0`}>
              <Text variant="h2" color="white" testTag="user-name">
                {loading && studentId !== user?.id ? 'Loading...' : user?.displayName}

                <StreakStatus user={user} isCurrent={false} />
              </Text>
            </Flex>
            <Flex justify="end" margin={`${margins[2]} 0`}>
              <DatePicker
                value={dateRange}
                name="assessmentDatePicker"
                onChange={(v) => {
                  setDateRange(v.label);
                  updateTimeParam(v.label);
                }}
              />
              <Spacer horizontal size={2} />
              <TooltipElem enabled {...tooltipProps} display="flex">
                <Button
                  testTag="export-csv-button"
                  displayType="iconOnly"
                  icon="download"
                  color="white"
                  width="70px"
                  borderRadiusOverride="2"
                  disabled={!hasCsvExport}
                  onClick={downloadCSV({
                    data: fileData,
                    filename: `${user?.displayName}-progress-monitoring`,
                    fetchJson,
                  })}
                />
              </TooltipElem>
            </Flex>
          </Flex>
        </CenteredLayout>
        <SplitBackgroundFlex
          width="100%"
          height="auto"
          direction="column"
          align="center"
          padding={paddings[6]}
        >
          <Flex direction="column" maxWidth="900px" width="100%">
            <StatsBanner stats={stats} />
          </Flex>
        </SplitBackgroundFlex>
        <CenteredLayout minWidth={0} minHeight={0} height="100%">
          <Flex justify="space-between" align="center">
            <TabView
              tabs={ImmutableList([{ id: 'all', name: 'Submissions' }])}
              queryParam="taskId"
            />
            <Flex justify="end" align="center">
              <SearchInput
                value={search}
                onChange={setField('search')}
                placeholder="Search Assignments"
              />
              <Spacer horizontal size={4} />
              <Flex align="center">
                <Icon
                  icon="filter"
                  color="readleeBlue"
                  fill={selectedClassParam !== 'all'}
                  strokeWidth={1.5}
                  strokeLinecap="round"
                />
                <Spacer horizontal />
                {/* Not sure if this needs to be here */}
                <SelectInput
                  id="sortBySelect"
                  displayType="flat"
                  name="Filter By Course"
                  value={selectedClassParam}
                  onChange={updateClassParam}
                  options={selectedClassOptions}
                />
              </Flex>
            </Flex>
          </Flex>
          <Spacer size={2} />
          {loading ? (
            <Loading flex kind="boat" />
          ) : (
            <>
              <Flex direction="column" overflowY="auto" overflowX="auto" grow={1} maxHeight="560px">
                <StudentSubmissionTable rows={submissionRows} />
              </Flex>
              {filteredResults.size > 0 && (
                <>
                  <Spacer size={8} />
                  {currentUser?.flags.isDataVisualizationEnabled ? (
                    <div data-test-tag="data-visualization-content">
                      <StudentProgressReadingChart />
                      <StudentWcpmGraph />
                      <StudentWpmGraph />
                      <StudentWcpmTooltip />
                      <StudentProgressReadingTooltip />
                    </div>
                  ) : (
                    <ChartBrochure />
                  )}
                </>
              )}
            </>
          )}
          <Spacer size={5} />
        </CenteredLayout>
      </Flex>
    </>
  );
};
