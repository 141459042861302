import { User, School, OnboardingQuestionStatus } from 'src/models';
import { ImmutableList } from 'src/modules/Immutable';
import { SetField } from 'src/State';
import { History, QueryMap } from 'src/modules/Router';
import { emptyErrorMap, ErrorMap } from 'src/modules/Api';

export type TeacherOnboardingState = {
  loading: boolean;
  currentUser: User | null;
  addedManualSchool: boolean;
  errors: ErrorMap;
  tempPositions: string;
  tempTeachesSpecialEd: OnboardingQuestionStatus;
  tempTeachesEll: OnboardingQuestionStatus;
  load: () => void;
  userSchools: ImmutableList<School>;
  setField: SetField<TeacherOnboardingState>;
  submitSchool: (
    history: History,
    query: QueryMap,
    schools: ImmutableList<School>,
    savedSchools: ImmutableList<School>,
  ) => () => void;
  submitResponses: (history: History, redirectPath: string | null | undefined) => () => void;
  skip: (history: History, redirectPath: string | null | undefined) => void;
};

export const defaultState = {
  loading: true,
  currentUser: null,
  errors: emptyErrorMap(),
  addedManualSchool: false,
  userSchools: ImmutableList<School>(),
  tempPositions: '',
  tempTeachesSpecialEd: 'unanswered',
  tempTeachesEll: 'unanswered',
} as const;
