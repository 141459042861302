import { useEffect } from 'react';
import { useStore } from 'src/Store';
import { queryMapToString, useHistory, useQuery } from 'src/modules/Router';
import { Flex, Text, Loading, Spacer, Button } from 'src/components';

export const ProcessingStep = () => {
  const history = useHistory();
  const query = useQuery();

  const { pdfFile, uploadingPdf, submit, contentType, accountType } = useStore(
    (state) => ({
      ...state.ReadingBuilderData,
      accountType: state.AppData.currentUser?.accountType || 'student',
    }),
    [],
  );

  useEffect(() => {
    if (contentType === 'pdf_by_page' && (!pdfFile || pdfFile?.processingStatus === 'finished')) {
      history.push(`/t/reading-builder/edit-pdf-text${queryMapToString(query)}`);
      return;
    }

    if (!uploadingPdf && (!pdfFile || pdfFile?.processingStatus === 'finished')) {
      submit({ history, query })();
    }
  }, [pdfFile, uploadingPdf, history, submit, query, contentType, accountType]);

  return pdfFile?.processingStatus === 'errored' ? (
    <Flex align="center" justify="center" direction="column">
      <Text variant="h1">Something went wrong!</Text>
      <Spacer size={2} />
      <Text variant="h3">
        There was an error processing your text, please try a different file.
      </Text>
      <Spacer size={2} />
      <Button to={`/t/reading-builder/content${queryMapToString(query)}`}>Back</Button>
    </Flex>
  ) : (
    <Flex align="center" justify="center" direction="column">
      <Text variant="h1">Hang in there!</Text>
      <Spacer size={2} />
      <Text variant="h3">We&apos;re processing your text, this may take a moment...</Text>
      <Spacer size={2} />
      <Loading />
    </Flex>
  );
};
