import { Button, FormGroup, Spacer, StandardModal } from 'src/components';
import { visibleRoleOptions } from 'src/models';
import { useStore } from 'src/Store';

export const EditPlaylistModal = () => {
  const { editingPlaylist, savePlaylist, setEditingPlaylistField, setField, errors } = useStore(
    ({ PlaylistManagerData }) => PlaylistManagerData,
    [],
  );

  if (!editingPlaylist?.id) return null;
  return (
    <StandardModal
      disableFadeClose
      openType="state"
      opened
      header="Edit Playlist"
      footer={
        <>
          <Button
            displayType="link"
            color="primaryBlue"
            onClick={() => setField('editingPlaylist')(null)}
          >
            Cancel
          </Button>
          <Spacer horizontal size={3} />
          <Button type="submit">Save</Button>
        </>
      }
      close={() => setField('editingPlaylist')(null)}
      onSubmit={savePlaylist}
    >
      <FormGroup
        type="text"
        name="name"
        label="Name"
        value={editingPlaylist.name}
        onChange={setEditingPlaylistField('name')}
        errors={errors}
      />
      <FormGroup
        type="multiselect"
        name="visibleRoles"
        label="Visible Roles"
        value={editingPlaylist.visibleRoles}
        options={visibleRoleOptions}
        onChange={setEditingPlaylistField('visibleRoles')}
        errors={errors}
      />
      <FormGroup
        type="toggle"
        name="enabled"
        label="Visible"
        value={editingPlaylist.enabled}
        onChange={setEditingPlaylistField('enabled')}
        errors={errors}
      />
    </StandardModal>
  );
};
