import { CreateToast } from 'src/components';
import { emptyErrorMap, ErrorMap } from 'src/modules/Api';
import { SetField } from 'src/State';

export type ShareModalState = {
  openShareModal: (assessmentId: string) => void;
  assessmentId: string;
  opened: boolean;
  submitting: boolean;
  sharingEmail: string;
  sharingErrors: ErrorMap;
  submitShare: (createToast: CreateToast) => () => void;
  setField: SetField<ShareModalState>;
};

export const defaultState = {
  assessmentId: '',
  opened: false,
  submitting: false,
  sharingEmail: '',
  sharingErrors: emptyErrorMap(),
};
