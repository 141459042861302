import { useState, useEffect, useCallback, RefCallback } from 'react';
import { colors, borderRadii, paddings, margins, zIndices } from 'src/styles';
import { BodyPortal, Button, ButtonEvent, Flex, Icon, Spacer, Tag, Text } from 'src/components';
import { inputStyles } from './InputHelpers';
import { CheckboxInput } from './CheckboxInput';
import styled, { css } from 'styled-components';
import {
  addEventListenerToAllAncestors,
  removeEventListenerFromAllAncestors,
  useExternalClick,
} from 'src/modules/Dom';
import { ImmutableList } from 'src/modules/Immutable';
import { useUniqueId } from 'src/modules/UniqueId';
import { throttle } from 'lodash';

export type MultiSelectInputOption<T> = {
  value: T;
  label: string;
};

interface MultiSelectInputProps<T> {
  id?: string;
  name: string;
  options: ImmutableList<MultiSelectInputOption<T>>;
  value: ImmutableList<T>;
  placeholder?: string;
  disabled?: boolean;
  maxSelectedShown?: number;
  testTag?: string;
  onChange: (value: ImmutableList<T>) => void;
}

// Position text label for multi-select above dropdown
const MultiSelectInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
`;

// Set style for top button in multi-select
const MultiSelectInputButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 100%;
  color: ${colors.gray5.hex};
`;

const messageActiveStyles = css<{ $direction: 'up' | 'down' }>`
  border: 1px solid ${colors.primaryBlue.hex};
  ${({ $direction }) =>
    $direction === 'down'
      ? `border-bottom: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;`
      : `border-top: 0;
      border-top-left-radius: 0;
      border-top-right-radius: 0;`}
`;
// Style and position of multi-select top message and arrow (inside top button)
const MultiSelectInputMessageContainer = styled.div<{
  $isOpen: boolean;
  disabled?: boolean;
  $direction: 'up' | 'down';
}>`
  ${inputStyles}

  display: flex;
  align-items: center;
  justify-content: space-between;

  ${(props) => (props.$isOpen ? messageActiveStyles : '')}
`;

type OptionsContainerProps = {
  $direction?: 'up' | 'down';
};
// Container for holding all dropdown buttons
const OptionsContainer = styled.div<OptionsContainerProps>`
  position: fixed;
  z-index: ${zIndices.dropdown};
  display: flex;
  flex-direction: column;
  background: ${colors.white.hex};
  max-height: 300px;
  overflow-y: auto;
  border: 1px solid ${colors.primaryBlue.hex};

  ${({ $direction }) =>
    $direction === 'down'
      ? css`
          border-top: 1px solid ${colors.gray2.hex};
          border-bottom-left-radius: ${borderRadii.input};
          border-bottom-right-radius: ${borderRadii.input};
        `
      : css`
          border-bottom: 1px solid ${colors.gray2.hex};
          border-top-left-radius: ${borderRadii.input};
          border-top-right-radius: ${borderRadii.input};
        `}
`;

const lastOptionStyles = css`
  border-bottom-left-radius: ${borderRadii.input};
  border-bottom-right-radius: ${borderRadii.input};
`;

// Style for message displayed in dropdown buttons, colored primaryBlue when selected
const OptionDisplay = styled.div<{ $selected: boolean; $isLast: boolean }>`
  border-radius: ${borderRadii[3]};
  margin: ${margins[1]} ${margins[2]};
  padding: ${paddings[2]} ${paddings[4]};
  width: calc(100% - ${margins[4]});
  background-color: ${colors.white.hex};
  ${(props) => (props.$isLast ? lastOptionStyles : '')}

  &:hover {
    background-color: ${colors.gray1.hex};
  }
`;

const SelectedOptions = <T extends string | number | string[] | undefined>({
  selectedOptions,
  placeholder,
  maxSelectedShown,
}: {
  selectedOptions: ImmutableList<MultiSelectInputOption<T>>;
  placeholder?: string;
  maxSelectedShown: number;
}) => {
  if (selectedOptions.size === 0) {
    return <>{placeholder}</>;
  }

  return (
    <>
      {selectedOptions.slice(0, maxSelectedShown).map(({ label }, i) => (
        <Tag key={i} color="readleeBlue" title={label}>
          <Text ellipsis>{label}</Text>
        </Tag>
      ))}
      {selectedOptions.size > maxSelectedShown && (
        <Tag
          color="black"
          title={selectedOptions
            .slice(maxSelectedShown)
            .map((l) => l.label)
            .join(', ')}
        >
          +{selectedOptions.size - maxSelectedShown}
        </Tag>
      )}
    </>
  );
};

type OptionsCoords = {
  direction: 'up' | 'down';
  left: number;
  width: number;
  top?: number;
  bottom?: number;
};

export const MultiSelectInput = <T extends string | number | string[] | undefined>({
  id: baseId,
  options,
  onChange,
  value,
  placeholder,
  disabled,
  maxSelectedShown = 2,
  testTag,
}: MultiSelectInputProps<T>) => {
  const id = useUniqueId(baseId);
  const [isOpen, setIsOpen] = useState(false);
  const [optionsCoords, setOptionsCoords] = useState<OptionsCoords>({
    left: 0,
    width: 0,
    top: 0,
    direction: 'down',
  });
  const [buttonContainer, setButtonContainer] = useState<HTMLDivElement | null>(null);
  const buttonContainerRef: RefCallback<HTMLDivElement> = (elem) => {
    if (elem && elem !== buttonContainer) {
      setButtonContainer(elem);
    }
  };

  const selectedOptions = options.filter((opt) => value.includes(opt.value));

  useEffect(() => {
    if (!buttonContainer) return;
    const reset = throttle(() => {
      const rect = buttonContainer.getBoundingClientRect();
      const height = 300;
      const left = rect.left;
      const width = rect.width;
      const top = rect.bottom - 3;
      if (top + height > window.innerHeight) {
        const bottom = window.innerHeight - rect.top - 3;
        setOptionsCoords({ left, width, bottom, direction: 'up' });
      } else {
        setOptionsCoords({ left, width, top, direction: 'down' });
      }
    }, 10);
    const close = throttle(() => {
      setIsOpen(false);
    }, 100);

    reset();
    window.addEventListener('resize', reset);
    document.addEventListener('scroll', close);
    addEventListenerToAllAncestors(buttonContainer, 'scroll', close);

    return () => {
      window.removeEventListener('resize', reset);
      document.removeEventListener('scroll', close);
      removeEventListenerFromAllAncestors(buttonContainer, 'scroll', close);
    };
  }, [isOpen, buttonContainer]);

  const close = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);
  useExternalClick(id, close);

  const realOnChange = useCallback(
    (option: MultiSelectInputOption<T>) => (event: ButtonEvent) => {
      event.preventDefault();
      event.stopPropagation();
      const selected = value.includes(option.value);
      onChange(selected ? value.filter((v) => v !== option.value) : value.push(option.value));
      return false;
    },
    [onChange, value],
  );

  const { direction, ...optionsStyles } = optionsCoords;

  return (
    <MultiSelectInputContainer id={id}>
      <MultiSelectInputButtonContainer ref={buttonContainerRef}>
        <Button
          displayType="noStyles"
          display="block"
          onClick={() => setIsOpen(!isOpen)}
          disabled={disabled}
          testTag={testTag}
        >
          <MultiSelectInputMessageContainer
            $isOpen={isOpen}
            disabled={disabled}
            $direction={direction}
          >
            <Flex align="center" minWidth={0}>
              <SelectedOptions
                placeholder={placeholder}
                selectedOptions={selectedOptions}
                maxSelectedShown={maxSelectedShown}
              />
            </Flex>
            <Spacer horizontal size={1} />
            <Icon
              icon={isOpen ? 'selectArrowUp' : 'selectArrowDown'}
              size="1em"
              color={disabled ? 'currentColor' : 'primaryBlue'}
            />
          </MultiSelectInputMessageContainer>
        </Button>
      </MultiSelectInputButtonContainer>
      {isOpen && (
        <BodyPortal>
          <OptionsContainer
            // Styled-components generates a new class every time you change something
            // so we use inline styles here to clean it up
            style={optionsStyles}
            $direction={direction}
          >
            {options.map((option, i) => {
              const selected = value.includes(option.value);
              return (
                <Button
                  key={i}
                  displayType="noStyles"
                  display="block"
                  onClick={realOnChange(option)}
                  testTag={`${testTag || id}-${option.value}`}
                >
                  <OptionDisplay $isLast={i + 1 === options.size} $selected={selected}>
                    <CheckboxInput
                      name={`${id}-option-${i}`}
                      value={selected}
                      label={option.label}
                    />
                  </OptionDisplay>
                </Button>
              );
            })}
          </OptionsContainer>
        </BodyPortal>
      )}
    </MultiSelectInputContainer>
  );
};
