import { useStore } from 'src/Store';
import { ComprehensionHeader, Drawer } from 'src/components';

import { useCurrentTaskSubmissionDetails } from './ReadingStudioHelpers';
import { ComprehensionContent } from './ComprehensionContent';
import { ModelTaskStudio } from './ModelTaskStudio';
import { ReadingTaskStudio } from './ReadingTaskStudio';

export const DrawerContent = () => {
  const { drawerExpanded, setField, currentUser } = useStore(
    (state) => ({
      drawerExpanded: state.ReadingStudioData.drawerExpanded,
      setField: state.ReadingStudioData.setField,
      currentUser: state.AppData.currentUser,
    }),
    [],
  );

  const currentTaskSubmissionDetails = useCurrentTaskSubmissionDetails();

  if (!currentTaskSubmissionDetails) return null;

  if (currentTaskSubmissionDetails.type === 'ReadingTask') {
    return <ReadingTaskStudio />;
  } else if (currentTaskSubmissionDetails.type === 'ComprehensionTask') {
    return (
      <Drawer
        header={
          <ComprehensionHeader
            currentTaskSubmissionDetails={currentTaskSubmissionDetails}
            displayType={drawerExpanded ? 'drawerOpen' : 'drawerClosed'}
            toggleOpen={() => setField('drawerExpanded')(!drawerExpanded)}
            textSize={currentUser?.textSize}
          />
        }
        expanded={drawerExpanded}
      >
        <ComprehensionContent
          currentTaskSubmissionDetails={currentTaskSubmissionDetails}
          displayType={drawerExpanded ? 'drawerOpen' : 'drawerClosed'}
          textSize={currentUser?.textSize}
        />
      </Drawer>
    );
  } else if (currentTaskSubmissionDetails.type === 'ModelTask') {
    return (
      <Drawer>
        <ModelTaskStudio taskSubmissionDetails={currentTaskSubmissionDetails} />
      </Drawer>
    );
  } else if (currentTaskSubmissionDetails.type === 'VocabTask') {
    return null;
  } else {
    throw new Error(`Unrecognized task type ${JSON.stringify(currentTaskSubmissionDetails)}`);
  }
};
