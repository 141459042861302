import { ReactNode, useCallback, useEffect } from 'react';
import { Route } from 'react-router-dom';
import { useHistory } from 'src/modules/Router';
import {
  Button,
  Flex,
  Loading,
  NoStylesButton,
  Spacer,
  StandardModal,
  Subscribe,
  Text,
  useToaster,
} from 'src/components';
import { comparePlans, emptyPlan, Plan, sortPlans, User } from 'src/models';
import { ImmutableList } from 'src/modules/Immutable';
import { commonLayoutStyles } from 'src/modules/Layout';
import { formatDate, formatTime } from 'src/modules/Time';
import { useStore } from 'src/Store';
import {
  basicColors,
  borderRadii,
  colors,
  margins,
  paddings,
  standardMeasurements,
} from 'src/styles';
import styled from 'styled-components';
import { OnSubscriptionStatusProps } from './PlanListState';
import { StartPaymentSession } from './StartPaymentSession';
import { formatPrice } from 'src/modules/String';
import { CenteredLayout } from 'src/layouts';

const PlanListContainer = styled.div`
  ${commonLayoutStyles}

  background: ${colors.backgroundLight.hex};
`;

const PlansContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: ${margins.none} ${margins[4]} ${margins[32]} ${margins[4]};
  justify-content: center;
`;
const PlanBox = styled.div<{ $last: boolean }>`
  flex: 1;
  padding: ${paddings[8]};
  margin-right: ${({ $last }) => ($last ? margins.none : margins[4])};
  margin-bottom: ${({ $last }) => ($last ? margins.none : margins[4])};
  border-radius: ${borderRadii[2]};
  min-width: 300px;
  max-width: 375px;
  border: 1px solid ${colors.gray2.hex};
  background: ${colors.white.hex};
`;

const List = styled.ul`
  margin: 0;
  padding: 0;
  padding-left: ${margins[6]};
`;
const ListItem = styled.li``;

const PricingContainer = styled.div`
  min-height: 220px;
`;

const startPaymentSessionLink = (plan: Plan, paymentInterval: 'annual' | 'month') =>
  `/t/plans/${plan.id}/${paymentInterval}/create_session`;

const PlanButton = ({ plan, currentUser }: { plan: Plan; currentUser: User }) => {
  const { plans, startDowngrade, currentPlan, currentSubscription, creatingSession } = useStore(
    ({ PlanListData }) => PlanListData,
    [],
  );
  const isGrandfathered = plans.every((p) => p.id !== plan.id);
  const comparison = comparePlans(currentPlan || emptyPlan(), plan);

  const currentPlanTag = (
    <Button disabled testTag="current-plan-tag" width="100%">
      Your Current Plan
    </Button>
  );

  if (currentPlan?.isEnterprise) {
    return (
      <>
        {plan.id === currentPlan.id && currentPlanTag}
        <Spacer size={2} />
        <Text>Want to make a change?</Text>
        <Spacer size={2} />
        <Button newTab href="https://www.readlee.com/contact" width="100%">
          Chat With Us
        </Button>
      </>
    );
  }

  if (plan.id === currentPlan?.id || (plan.isBasicPlan && !currentPlan)) {
    if (plan.isBasicPlan) {
      return currentPlanTag;
    } else if (currentSubscription?.status === 'cancelled' && currentSubscription.isActivated) {
      const endsAt = new Date(currentSubscription.currentPeriodEndsAt);
      return (
        <>
          {currentPlanTag}
          <Text>
            Your plan has been cancelled and will end on{' '}
            {formatDate(endsAt, { includeYearIfNotCurrent: true })} at {formatTime(endsAt)}.
          </Text>
          <Button
            loading={creatingSession}
            to={startPaymentSessionLink(plan, 'month')}
            width="100%"
          >
            Re-Activate
          </Button>
        </>
      );
    } else if (isGrandfathered) {
      return currentPlanTag;
    } else {
      const isMonthly = currentSubscription?.paymentInterval === 'month';
      return (
        <>
          {currentPlanTag}
          <Spacer size={2} />
          <Button
            loading={creatingSession}
            to={startPaymentSessionLink(plan, isMonthly ? 'annual' : 'month')}
            width="100%"
          >
            Switch to {isMonthly ? 'Annual' : 'Monthly'} Payments
          </Button>
        </>
      );
    }
  } else if (plan.isEnterprise) {
    return (
      <Button href="https://www.readlee.com/contact" width="100%" newTab>
        Contact Us!
      </Button>
    );
  } else if (plan.isBasicPlan) {
    return (
      <>
        {currentSubscription?.status === 'cancelled' ? (
          <Text>
            Your plan has been cancelled and you will revert to the basic plan on{' '}
            {formatDate(new Date(currentSubscription.currentPeriodEndsAt), {
              includeYearIfNotCurrent: true,
            })}{' '}
            at {formatTime(new Date(currentSubscription.currentPeriodEndsAt))}.
          </Text>
        ) : (
          <Button loading={creatingSession} onClick={startDowngrade(plan)} width="100%">
            Downgrade
          </Button>
        )}
      </>
    );
  } else if (currentUser.trialMonthsAvailable) {
    return (
      <Button loading={creatingSession} to={startPaymentSessionLink(plan, 'month')} width="100%">
        Activate Free Trial
      </Button>
    );
  } else {
    return (
      <Button loading={creatingSession} to={startPaymentSessionLink(plan, 'month')} width="100%">
        {comparison > 0 ? 'Change Plan' : 'Upgrade'}
      </Button>
    );
  }
};

const PlanPriceTag = ({ plan, currentUser }: { plan: Plan; currentUser: User }) => {
  const { currentPlan, currentSubscription } = useStore(({ PlanListData }) => PlanListData, []);
  if (plan.isBasicPlan) {
    return (
      <Text>
        Free until <b>December 2023</b>
      </Text>
    );
  } else if (plan.isEnterprise) {
    return <Text>Custom pricing for schools and districts</Text>;
  } else if (plan.status === 'deprecated') {
    return (
      <Text>
        This plan is no longer available, but your subscription has been grandfathered in and you
        can keep using it.
      </Text>
    );
  } else if (currentUser.trialMonthsAvailable) {
    return null;
  } else if (
    currentSubscription &&
    currentPlan?.id === plan.id &&
    currentSubscription.paymentInterval === 'month' &&
    plan.annualPriceUsdCents / 12 < plan.monthlyPriceUsdCents &&
    currentSubscription.status === 'active'
  ) {
    return (
      <Text>
        Switch to yearly payments and save{' '}
        <b>{formatPrice(plan.monthlyPriceUsdCents - plan.annualPriceUsdCents / 12)}</b> per month!
      </Text>
    );
  } else {
    const cheaperYearlyPrice = Math.min(plan.monthlyPriceUsdCents * 12, plan.annualPriceUsdCents);
    return (
      <Text>
        From <b>{formatPrice(cheaperYearlyPrice)}</b> per year
      </Text>
    );
  }
};

export const PlanSubHeader = ({ children }: { children: ReactNode }) => (
  <Text variant="h6" weightOverride="bold">
    {children}
  </Text>
);

export const FeatureList = ({ features }: { features: ImmutableList<string> }) => (
  <List>
    {features.map((featureText, i) => (
      <ListItem key={i}>
        <Text dangerouslySetInnerHTML={{ __html: featureText }} />
      </ListItem>
    ))}
  </List>
);

export const PlanList = () => {
  const {
    load,
    loading,
    currentUser,
    currentPlan,
    plans,
    onSubscriptionStatus,
    onSubscriptionConnected,
    currentSubscription,
    downgrade,
    downgradingPlan,
    setField,
  } = useStore(({ PlanListData }) => PlanListData, []);
  const { createToast } = useToaster();
  const history = useHistory();

  useEffect(() => {
    load(createToast, history);
  }, [load, createToast, history]);

  const onSub = useCallback(() => {
    onSubscriptionConnected(createToast, history);
  }, [onSubscriptionConnected, createToast, history]);

  const onSubStatus = useCallback(
    (data: Omit<OnSubscriptionStatusProps, 'createToast'>) => {
      onSubscriptionStatus({ createToast, ...data });
    },
    [onSubscriptionStatus, createToast],
  );

  if (loading) return <Loading flex kind="boat" />;
  if (!currentUser) return null;

  const allPlans = sortPlans(
    plans.concat(
      ImmutableList(
        // Handle if they are on a no longer visible plan, e.g. a grandfathered old pro version
        currentPlan && plans.every((p) => p.id !== currentPlan.id) ? [currentPlan] : [],
      ),
    ),
  );

  const closeDowngrade = () => setField('downgradingPlan')(null);

  return (
    <PlanListContainer>
      <CenteredLayout>
        <Spacer size={8} />
        <NoStylesButton
          href="https://drive.google.com/file/d/1rVYHdwrGH8_pVOpUtOK-2zX3lYP9BA8q/view?usp=sharing"
          newTab
        >
          <Flex
            backgroundColor={basicColors.primaryBlue}
            borderRadius={borderRadii[4]}
            justify="center"
            padding={`${paddings[2]} ${paddings[6]}`}
          >
            <Text color="white" variant="h5">
              We are proud to announce that Readlee is joining Paper! Click here for a letter from
              our Founders
            </Text>
          </Flex>
        </NoStylesButton>
      </CenteredLayout>
      <Spacer size={8} />
      <Flex align="center" direction="column">
        <Text variant="h1">Select Your Plan</Text>
        <Spacer size={2} />
        <Text variant="h4" textAlign="center">
          Upgrade and accelerate your students&apos; literacy growth
        </Text>
      </Flex>
      <Spacer size={8} />
      <PlansContainer>
        {allPlans.map((plan, i) => (
          <PlanBox key={plan.id} $last={i === allPlans.size} data-test-tag={`plan-box-${plan.id}`}>
            <PricingContainer>
              <Text variant="h1">{plan.name}</Text>
              <Text
                variant="h4"
                minHeight={standardMeasurements[15]}
                dangerouslySetInnerHTML={{ __html: plan.tagline }}
              />
              <Spacer size={2} />
              <PlanButton plan={plan} currentUser={currentUser} />
              <Spacer size={2} />
              <PlanPriceTag plan={plan} currentUser={currentUser} />
            </PricingContainer>
            <Spacer size={2} />

            <Text
              variant="h6"
              weightOverride="bold"
              minHeight={standardMeasurements[17]}
              dangerouslySetInnerHTML={{ __html: plan.description }}
            />
            <FeatureList features={plan.features} />
            {plan.comingSoonFeatures.size > 0 ? (
              <>
                <Spacer size={3} />
                <PlanSubHeader>Coming Soon:</PlanSubHeader>
                <FeatureList features={plan.comingSoonFeatures} />
              </>
            ) : null}
            {plan.optionalAddOns.size > 0 ? (
              <>
                <Spacer size={3} />
                <PlanSubHeader>Optional Add Ons:</PlanSubHeader>
                <FeatureList features={plan.optionalAddOns} />
              </>
            ) : null}
          </PlanBox>
        ))}
      </PlansContainer>
      <Route path="/t/plans/:id/:paymentInterval/create_session">
        <StartPaymentSession />
      </Route>
      <Subscribe channel="SubscriptionChannel" onMessage={onSubStatus} onConnected={onSub} />
      {downgradingPlan && currentPlan && currentSubscription && (
        <StandardModal
          openType="state"
          opened={Boolean(downgradingPlan)}
          close={closeDowngrade}
          header="Cancel your plan"
          maxWidthOverride="800px"
          footer={
            <>
              <Button
                onClick={closeDowngrade}
                inheritBgColor
                displayType="link"
                color="primaryBlue"
              >
                Cancel
              </Button>
              <Spacer horizontal size={2} />
              <Button onClick={downgrade(downgradingPlan, createToast)} color="danger">
                Cancel my Plan
              </Button>
            </>
          }
        >
          <Text>Are you sure you want to cancel your plan for Readlee {currentPlan.name}?</Text>
          <Spacer />
          <Text>
            Your subscription will be cancelled and you will not be charged next{' '}
            {currentSubscription.paymentInterval}.
          </Text>
          <Spacer />
          <Text>
            You will be able to keep using your {currentPlan.name} features until the current
            payment period ends on{' '}
            {formatDate(new Date(currentSubscription.currentPeriodEndsAt), {
              includeYearIfNotCurrent: true,
            })}{' '}
            at {formatTime(new Date(currentSubscription.currentPeriodEndsAt))}.
          </Text>
        </StandardModal>
      )}
    </PlanListContainer>
  );
};
