import { ReactNode } from 'react';
import { Color, standardMeasurements, zIndices } from 'src/styles';
import { Toast } from './Toast';
import { BodyPortal } from 'src/components';
import styled from 'styled-components';
import { createStrictStore } from 'src/modules/Zustand';
import { ImmutableMap } from 'src/modules/Immutable';
import { getUniqueId } from 'src/modules/UniqueId';
import { setFocusedTimeout } from 'src/modules/FocusedTimeout';

type CreateToastProps = {
  children: ReactNode;
  color?: Color;
  duration?: number;
  forever?: boolean;
  closeAnywhere?: boolean;
};

export type ToastState = CreateToastProps & {
  id: string;
  createdAt: number;
};
export type CreateToast = (toast: CreateToastProps) => string;

const BaseToaster = styled.div`
  position: fixed;
  bottom: ${standardMeasurements[4]};
  left: ${standardMeasurements[4]};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  z-index: ${zIndices.toast};
`;

const DEFAULT_TOAST_DURATION = 5000;

type ToastStoreState = {
  toastMap: ImmutableMap<string, ToastState>;
  createToast: CreateToast;
  closeToast: (id: string) => void;
};

const { useStore: useToasterStore } = createStrictStore<ToastStoreState>((set, get) => {
  return {
    toastMap: ImmutableMap<string, ToastState>(),
    createToast: ({ closeAnywhere = true, ...toast }) => {
      const id = `toast-${getUniqueId()}`;
      set((state) => ({
        toastMap: state.toastMap.set(id, {
          ...toast,
          closeAnywhere,
          id,
          createdAt: new Date().valueOf(),
        }),
      }));
      if (!toast.forever) {
        setFocusedTimeout(() => {
          get().closeToast(id);
        }, toast.duration ?? DEFAULT_TOAST_DURATION);
      }
      return id;
    },
    closeToast: (id: string) => {
      set((state) => ({
        toastMap: state.toastMap.delete(id),
      }));
    },
  };
});

export const useToaster = () => {
  const { toastMap, createToast, closeToast } = useToasterStore((state) => state, []);
  return { toastMap, createToast, closeToast };
};

export const Toaster = () => {
  const { toastMap, closeToast } = useToaster();

  return (
    <BodyPortal>
      <BaseToaster>
        {toastMap
          .toList()
          .sortBy((t) => t.createdAt)
          .map((toast) => {
            return (
              <Toast
                key={toast.id}
                closeToast={() => closeToast(toast.id)}
                color={toast.color ?? 'success'}
                closeAnywhere={toast.closeAnywhere ?? false}
              >
                {toast.children}
              </Toast>
            );
          })}
      </BaseToaster>
    </BodyPortal>
  );
};
