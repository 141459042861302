import { ReactNode, useCallback, useMemo } from 'react';
import { Flex, Loading, Text } from 'src/components';
import { OwnershipType } from 'src/models';
import { ImmutableList, ImmutableMap, ImmutableSet, setToggle } from 'src/modules/Immutable';
import { updateQuery, useHistory, useLocation, useQuery } from 'src/modules/Router';
import { useStore } from 'src/Store';
import { paddings } from 'src/styles';
import { SearchParams } from './ContentLibraryState';

type WordRangeProps = {
  minWords: number;
  maxWords: number | null;
};

export const getWordRangeKey = ({ minWords, maxWords }: WordRangeProps) =>
  `${minWords}-${maxWords || 'max'}`;

export const getWordRangeLabel = ({ minWords, maxWords }: WordRangeProps) => {
  if (typeof maxWords !== 'number') {
    return `${minWords}+`;
  } else if (minWords === 1) {
    return `< ${maxWords}`;
  } else {
    return `${minWords}-${maxWords}`;
  }
};

export const wordRanges = ImmutableList([
  { minWords: 1, maxWords: 100 },
  { minWords: 100, maxWords: 250 },
  { minWords: 250, maxWords: 500 },
  { minWords: 500, maxWords: 1000 },
  { minWords: 1000, maxWords: null },
] as const);

export const wordRangeMap = ImmutableMap(wordRanges.map((v) => [getWordRangeKey(v), v]));

export const useSearchData = (): SearchParams => {
  const query = useQuery();
  const baseSearchText = query.get('searchText');
  const baseGradeBands = query.get('gradeBands');
  const baseOwnershipTypes = query.get('ownershipTypes');
  const basePageNumber = query.get('pageNumber');
  const baseWordCounts = query.get('wordCounts');
  const baseTagIds = query.get('tagIds');
  const baseAdditionalFilters = query.get('additionalFilters');

  const pageNumber = useMemo(() => parseInt(basePageNumber ?? '0'), [basePageNumber]);
  const searchText = useMemo(() => baseSearchText ?? '', [baseSearchText]);
  const tagIds = useMemo(() => ImmutableList(baseTagIds?.split(',') ?? []), [baseTagIds]);
  const gradeBands = useMemo(
    () => ImmutableList(baseGradeBands?.split(',') ?? []),
    [baseGradeBands],
  );
  const ownershipTypes = useMemo(
    () => ImmutableList(baseOwnershipTypes?.split(',') ?? []) as ImmutableList<OwnershipType>,
    [baseOwnershipTypes],
  );
  const wordCounts = useMemo(
    () => ImmutableList(baseWordCounts?.split(',') ?? []),
    [baseWordCounts],
  );
  const additionalFilters = useMemo(
    () => ImmutableList(baseAdditionalFilters?.split(',') ?? []),
    [baseAdditionalFilters],
  );

  const searchData = useMemo(
    () => ({
      searchText,
      gradeBands,
      ownershipTypes,
      pageNumber,
      wordCounts,
      tagIds,
      additionalFilters,
    }),
    [searchText, gradeBands, ownershipTypes, pageNumber, wordCounts, tagIds, additionalFilters],
  );

  return searchData;
};

export const useSetQuery = () => {
  const setField = useStore((s) => s.ContentLibraryData.setField, []);
  const history = useHistory();
  const pathname = useLocation().pathname;
  const query = useQuery();

  const setQuery = useCallback(
    (updates: Record<string, string | null | undefined>) => {
      setField('loading')(true);
      history.push(
        `${pathname}${updateQuery(query, {
          ...updates,
          pageNumber: '0',
          playlistId: null,
        })}`,
      );
    },
    [history, pathname, query, setField],
  );

  return setQuery;
};

export const useQueryValues = () => {
  const setQuery = useSetQuery();
  const searchData = useSearchData();
  const selectedWordCounts = useMemo(
    () => ImmutableSet(searchData.wordCounts),
    [searchData.wordCounts],
  );

  const toggleWordCount = useCallback(
    (s: string) => () => {
      const update = setToggle(selectedWordCounts, s);
      setQuery({
        wordCounts: update.size ? update.join(',') : null,
      });
    },
    [setQuery, selectedWordCounts],
  );

  const selectedBands = useMemo(() => ImmutableSet(searchData.gradeBands), [searchData.gradeBands]);

  const toggleBand = useCallback(
    (value: string) => () => {
      const update = setToggle(selectedBands, value);
      setQuery({
        gradeBands: update.size ? update.join(',') : null,
      });
    },
    [selectedBands, setQuery],
  );

  const selectedAdditionalFilters = useMemo(
    () => ImmutableSet(searchData.additionalFilters),
    [searchData.additionalFilters],
  );

  const toggleAdditionalFilter = useCallback(
    (s: string) => {
      const update = setToggle(selectedAdditionalFilters, s);
      setQuery({
        additionalFilters: update.size ? update.join(',') : null,
      });
    },
    [setQuery, selectedAdditionalFilters],
  );

  const selectedOwnershipTypes = useMemo(
    () => ImmutableSet(searchData.ownershipTypes),
    [searchData.ownershipTypes],
  );

  const toggleOwnershipType = useCallback(
    (s: OwnershipType) => () => {
      const update = setToggle(selectedOwnershipTypes, s);
      setQuery({
        ownershipTypes: update.size ? update.join(',') : null,
      });
    },
    [setQuery, selectedOwnershipTypes],
  );

  return {
    selectedWordCounts,
    toggleWordCount,
    selectedBands,
    toggleBand,
    selectedOwnershipTypes,
    toggleOwnershipType,
    additionalFilters: searchData.additionalFilters,
    toggleAdditionalFilter,
  };
};

export const useCurrentPlaylist = () => {
  const playlists = useStore((s) => s.ContentLibraryData.playlists, []);
  const query = useQuery();

  if (!query.has('playlistId')) return null;

  return playlists.find((playlist) => playlist.id === query.get('playlistId'));
};

export const isSearching = ({
  searchText,
  gradeBands,
  ownershipTypes,
  wordCounts,
  tagIds,
  additionalFilters,
}: SearchParams) => {
  return (
    searchText.length > 0 ||
    gradeBands.size > 0 ||
    ownershipTypes.size > 0 ||
    wordCounts.size > 0 ||
    tagIds.size > 0 ||
    additionalFilters.size > 0
  );
};

export const ListHeader = ({ children }: { children: ReactNode }) => (
  <Text padding={`0 ${paddings[4]}`} variant="h3">
    {children}
  </Text>
);

export const CenteredLoading = () => (
  <Flex align="center" justify="center" width="100%">
    <Loading kind="boat" />
  </Flex>
);
