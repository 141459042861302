import { ImmutableList } from 'src/modules/Immutable';

export type TextDiffAction = 'added' | 'missed' | 'correct' | 'replaced' | 'filler';

type Alternatives = { confidence: number; content: string }[];

export type TextDiffItem =
  | {
      action: 'added';
      transcriptWord: string;
      startTime: number;
      endTime: number;
      alternatives: Alternatives;
    }
  | {
      action: 'missed';
      originalWord: string;
    }
  | {
      action: 'correct';
      originalWord: string;
      transcriptWord: string;
      startTime: number;
      endTime: number;
      alternatives: Alternatives;
    }
  | {
      action: 'replaced';
      originalWord: string;
      transcriptWord: string;
      startTime: number;
      endTime: number;
      alternatives: Alternatives;
    }
  | {
      action: 'filler';
      originalWord: string;
    };

export type TextDiff = {
  id: string;
  prunedItems: ImmutableList<TextDiffItem>;
  isPruned: boolean;
};

export type ComprehensionTextDiffItem = {
  alternatives: Alternatives;
  content: string;
  type: 'pronunciation' | 'punctuation';
  startTime: number;
  endTime: number;
};
