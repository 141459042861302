import { MAX_RATING } from 'src/models';
import { Button, Flex, Icon } from 'src/components';
import { Color } from 'src/styles';
import { useState } from 'react';

export type StarRowProps = {
  rating: number;
  borderColor: Color;
  onClick?: (value: number) => void;
};

export const StarRow = ({ rating, borderColor, onClick }: StarRowProps) => {
  const [starHover, setStarHover] = useState(0);
  const buttonFilled = (val: number) => val < starHover || (val < (rating ?? 0) && starHover === 0);
  const iconFill = (val: number) => (onClick ? buttonFilled(val) : val < rating);
  return (
    <Flex>
      {[...Array(MAX_RATING)].map((_, val) => (
        <Flex
          key={val}
          align="center"
          testTag={`star-${val + 1}-${iconFill(val) ? 'filled' : 'empty'}`}
        >
          {onClick ? (
            <Button
              color="white"
              displayType="noStyles"
              icon="star"
              iconColor={buttonFilled(val) ? 'warning' : borderColor}
              iconFill={buttonFilled(val)}
              onMouseOver={() => setStarHover(val + 1)}
              onMouseOut={() => setStarHover(0)}
              onClick={() => onClick(val)}
            />
          ) : (
            <Icon
              icon="star"
              fill
              fillColor={val < rating ? 'warning' : 'white'}
              color={val < rating ? 'warning' : borderColor}
            />
          )}
        </Flex>
      ))}
    </Flex>
  );
};
