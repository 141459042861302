import styled from 'styled-components';
import { borderRadii, colors, paddings, zIndices } from 'src/styles';
import { Flex } from 'src/components';
import { useCompactLayout } from 'src/layouts/ReadingStudioLayout/ReadingStudioLayoutHelpers';
import { ReactNode } from 'react';
import { useUniqueId } from 'src/modules/UniqueId';
import { useExternalClick } from 'src/modules/Dom';
import { ReadingStudioLayoutTitle } from './ReadingStudioLayoutTitle';

type ReadingStudioLayoutTopBarProps = {
  leftButton?: ReactNode;
  rightButtons?: ReactNode;
  thumbnailUrl?: string;
  readingTitle?: string;
  authorName?: string;
  webSourceUrl?: string | null;
  drawer?: ReactNode;
  closeDrawer?: () => void;
};

const DrawerStyles = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  color: ${colors.white.hex};
  background: ${colors.backgroundDark.hex};
  max-height: calc(100vh - 150px);
  padding: ${paddings[3]};
  border-radius: 0 0 ${borderRadii[3]} ${borderRadii[3]};
  z-index: ${zIndices.raised};
`;
const Drawer = ({ children, closeDrawer }: { children: ReactNode; closeDrawer?: () => void }) => {
  const id = useUniqueId();
  useExternalClick(id, closeDrawer);
  return <DrawerStyles id={id}>{children}</DrawerStyles>;
};

export const ReadingStudioLayoutTopBar = ({
  leftButton,
  rightButtons,
  thumbnailUrl,
  readingTitle,
  authorName,
  webSourceUrl,
  drawer,
  closeDrawer,
}: ReadingStudioLayoutTopBarProps) => {
  const compactLayout = useCompactLayout();

  return (
    <Flex
      direction="column"
      justify="space-between"
      padding={`${paddings[3]} ${paddings[compactLayout ? 3 : 5]}`}
      position="relative"
    >
      <Flex justify="space-between" align="center">
        <Flex grow={1} width="100%">
          {leftButton || <span />}
        </Flex>

        {!compactLayout ? (
          <ReadingStudioLayoutTitle
            thumbnailUrl={thumbnailUrl}
            readingTitle={readingTitle}
            authorName={authorName}
            webSourceUrl={webSourceUrl}
          />
        ) : null}

        <Flex grow={1} width="100%" justify="end">
          {rightButtons || <span />}
        </Flex>
      </Flex>
      {drawer && <Drawer closeDrawer={closeDrawer}>{drawer}</Drawer>}
    </Flex>
  );
};
