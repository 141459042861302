import React, { ReactNode, useCallback } from 'react';
import {
  DEFAULT_TEXT_SIZE,
  ENTERPRISE_PLAN_LABEL,
  ErrorDispute,
  TextDiff,
  TextDiffItem,
} from 'src/models';
import { useStore } from 'src/Store';
import {
  TextViewer,
  Button,
  Text,
  PremiumTooltip,
  Tooltip,
  TextProps,
  Spacer,
  LinkButton,
} from 'src/components';
import { simpleSubstitute } from 'src/modules/String';
import { startZendeskTicket } from 'src/modules/Analytics/Analytics';
import { ImmutableList } from 'src/modules/Immutable';
import { paddings } from 'src/styles';

const fillerVariant = (showAdditions: boolean, prevAction = '', nextAction = '') =>
  (prevAction === 'missed' || (prevAction === 'replaced' && !showAdditions)) &&
  ['missed', 'replaced'].includes(nextAction)
    ? 'del'
    : undefined;

type DiffItemTextProps = {
  blur?: boolean;
  children: ReactNode;
  variant?: 'ins' | 'del';
  color?: TextProps['color'];
  textSizeOverride?: number;
};

export const DiffItemText = ({
  blur = false,
  children,
  variant,
  color,
  textSizeOverride,
}: DiffItemTextProps) => {
  const textSize = useStore((s) => s.AppData.currentUser?.textSize, []) || DEFAULT_TEXT_SIZE;

  return (
    <Text
      display="inline"
      blurrify={blur}
      sizeOverride={textSizeOverride || textSize}
      testTag="diff-item"
      variant={variant || 'normalText'}
      whiteSpace="pre-wrap"
      color={color}
    >
      {children}
    </Text>
  );
};

const tooltipContent = `Upgrade to ${ENTERPRISE_PLAN_LABEL} to view substitutions spoken by your student!`;
const independentReadingTooltipContent = `Upgrade to ${ENTERPRISE_PLAN_LABEL} to view the full transcription of what your student read!`;

type MissingWordProps = {
  tooltipEnabled: boolean;
  children: ReactNode;
  index: number;
  corrected: boolean;
};

const MissingWord = ({ tooltipEnabled = false, children, index, corrected }: MissingWordProps) => {
  const disputeError = useStore((s) => s.SubmissionOverviewData.disputeError, []);
  const onClick = useCallback(() => disputeError(index), [disputeError, index]);

  return (
    <Tooltip
      content={<Button onClick={onClick}>Report as Correct</Button>}
      disabled={!tooltipEnabled || corrected}
    >
      <DiffItemText
        variant={corrected ? undefined : 'del'}
        color={corrected ? 'success' : undefined}
      >
        {children}
      </DiffItemText>
    </Tooltip>
  );
};

const DiffItemViewer = ({
  prevItem,
  diffItem,
  nextItem,
  onTimeSelected,
  showAdditions,
  blurAll,
  blur,
  showReportError,
  index,
  corrected,
  disabled,
  startAssignmentUpgrade,
  usageCapsEnabled,
  availableUltimateAssessments,
}: {
  prevItem: TextDiffItem | null | undefined;
  diffItem: TextDiffItem;
  nextItem: TextDiffItem | null | undefined;
  onTimeSelected: (timestamp: number) => void;
  startAssignmentUpgrade: () => void;
  usageCapsEnabled: boolean;
  availableUltimateAssessments: number;
  showAdditions: boolean;
  blurAll: boolean;
  blur: boolean;
  showReportError: boolean;
  index: number;
  corrected: boolean;
  disabled: boolean;
}) => {
  const onClick = useCallback(() => {
    if (
      diffItem.action === 'added' ||
      diffItem.action === 'correct' ||
      diffItem.action === 'replaced'
    ) {
      onTimeSelected(diffItem.startTime);
    }
  }, [diffItem, onTimeSelected]);

  if (diffItem.action === 'added') {
    return showAdditions ? (
      <>
        <PremiumTooltip
          content={tooltipContent}
          linkLocation="contact"
          enabled={blur}
          usageCapsEnabled={usageCapsEnabled}
          availableUltimateAssessments={availableUltimateAssessments}
          onClick={startAssignmentUpgrade}
        >
          <Button
            testTag="diff-item"
            displayType="noStyles"
            onClick={onClick}
            disabled={disabled || blur}
          >
            <DiffItemText variant="ins" blur={blur}>
              {prevItem?.action !== 'added' && '['}
              {blur ? simpleSubstitute(diffItem.transcriptWord) : diffItem.transcriptWord}
              {nextItem?.action !== 'added' && ']'}
            </DiffItemText>
          </Button>
        </PremiumTooltip>
        {nextItem?.action !== 'filler' && ' '}
      </>
    ) : null;
  } else if (diffItem.action === 'missed') {
    return (
      <MissingWord tooltipEnabled={showReportError} index={index} corrected={corrected}>
        {diffItem.originalWord}
      </MissingWord>
    );
  } else if (diffItem.action === 'correct') {
    return (
      <Button
        testTag="diff-item"
        displayType="noStyles"
        onClick={onClick}
        disabled={disabled || blurAll}
      >
        <DiffItemText blur={blurAll}>
          {blurAll ? simpleSubstitute(diffItem.originalWord) : diffItem.originalWord}
        </DiffItemText>
      </Button>
    );
  } else if (diffItem.action === 'filler') {
    return (
      <DiffItemText
        blur={blurAll}
        variant={fillerVariant(showAdditions, prevItem?.action, nextItem?.action)}
      >
        {diffItem.originalWord}
      </DiffItemText>
    );
  } else if (diffItem.action === 'replaced') {
    return (
      <>
        <MissingWord tooltipEnabled={showReportError} index={index} corrected={corrected}>
          {diffItem.originalWord}
        </MissingWord>
        {showAdditions ? (
          <>
            {' '}
            <PremiumTooltip
              content={tooltipContent}
              enabled={blur}
              linkLocation="contact"
              usageCapsEnabled={usageCapsEnabled}
              availableUltimateAssessments={availableUltimateAssessments}
              onClick={startAssignmentUpgrade}
            >
              <Button
                testTag="diff-item"
                displayType="noStyles"
                onClick={onClick}
                disabled={disabled || blur}
              >
                <DiffItemText variant="ins" blur={blur}>
                  [{blur ? simpleSubstitute(diffItem.transcriptWord) : diffItem.transcriptWord}]
                </DiffItemText>
              </Button>
              {nextItem?.action !== 'filler' && ' '}
            </PremiumTooltip>
          </>
        ) : null}
      </>
    );
  } else {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    throw new Error(`Unregonized diff item action: ${(diffItem as any).action}`);
  }
};

const UnmemoizedDiffViewer = ({
  textDiff,
  errorDisputes,
  onTimeSelected,
  showAdditions = true,
  showReportError = false,
  disabled = false,
  blur,
  blurAll,
  startAssignmentUpgrade,
  usageCapsEnabled,
  availableUltimateAssessments,
}: {
  textDiff: TextDiff;
  errorDisputes: ImmutableList<ErrorDispute>;
  onTimeSelected: (timestamp: number) => void;
  showAdditions?: boolean;
  blur: boolean;
  blurAll: boolean;
  showReportError?: boolean;
  disabled?: boolean;
  startAssignmentUpgrade: () => void;
  usageCapsEnabled: boolean;
  availableUltimateAssessments: number;
}) => {
  const disputeIndices = errorDisputes.map((ed) => ed.index);
  return (
    <PremiumTooltip
      linkLocation="contact"
      content={independentReadingTooltipContent}
      enabled={blurAll}
      usageCapsEnabled={usageCapsEnabled}
      availableUltimateAssessments={availableUltimateAssessments}
      onClick={startAssignmentUpgrade}
    >
      <TextViewer testTag="diff-viewer" drawerOpen>
        {textDiff.prunedItems.map((diffItem, i) => {
          return (
            <DiffItemViewer
              key={i}
              index={i}
              prevItem={i > 0 ? textDiff.prunedItems.get(i - 1) : null}
              diffItem={diffItem}
              nextItem={textDiff.prunedItems.get(i + 1)}
              onTimeSelected={onTimeSelected}
              showAdditions={showAdditions}
              blur={blur}
              blurAll={blurAll}
              showReportError={showReportError}
              corrected={disputeIndices.includes(i)}
              disabled={disabled}
              startAssignmentUpgrade={startAssignmentUpgrade}
              usageCapsEnabled={usageCapsEnabled}
              availableUltimateAssessments={availableUltimateAssessments}
            />
          );
        })}
        {textDiff.isPruned ? (
          <>
            <Spacer size={2} />
            <Text>
              This transcription was cut down due to being too long to show in the browser.{' '}
              <LinkButton
                display="inline"
                flexDisplay="inline-flex"
                paddingOverride={paddings.none}
                onClick={startZendeskTicket}
              >
                Contact support
              </LinkButton>{' '}
              to get the full transcript.
            </Text>
          </>
        ) : null}
      </TextViewer>
    </PremiumTooltip>
  );
};

export const DiffViewer = React.memo(UnmemoizedDiffViewer);
