import { useMemo } from 'react';
import {
  Chart as ChartType,
  ChartEvent,
  TooltipModel,
  TooltipItem,
  FontSpec,
  ScriptableAndScriptableOptions,
  ScriptableTooltipContext,
} from 'chart.js';
import { useStore } from 'src/Store';
import { dateOptions, TimeLabel } from 'src/components';
import { ImmutableList, ImmutableMap, ImmutableCollection, isList } from 'src/modules/Immutable';
import { useQuery } from 'src/modules/Router';
import {
  addDays,
  formatDate,
  MILLISECONDS_IN_SECOND,
  MILLISECONDS_IN_MINUTE,
  MILLISECONDS_IN_DAY,
  isToday,
  isSameDay,
} from 'src/modules/Time';
import {
  BaseTooltipSubmissionData,
  SubmissionResponse,
  TooltipContainerProps,
} from './StudentProgressMonitoringState';
import { ChartTooltipContainer } from './StudentProgressChartComponents';
import { AssessmentExtended } from 'src/models';

export const formatPercentChange = (change: number) => `${Math.abs(change * 100).toFixed(1)}%`;

export const groupByDay = ({ submission: s }: SubmissionResponse) =>
  Math.ceil(Date.parse(s.finishedAt) / MILLISECONDS_IN_DAY) * MILLISECONDS_IN_DAY;

export const useDateFilter = () => {
  const query = useQuery();
  const dateLabel = (query.get('selectedTime') || 'Last 7 Days') as TimeLabel;
  const dateOption = dateOptions[dateLabel];
  return {
    dateLabel,
    dateOption: dateOption.value.start,
    datePrev: dateOption.prev,
  };
};

export const useSubmissionResponses = () => {
  const sr = useStore((s) => s.StudentProgressMonitoringData.submissionResults, []);
  return useMemo(
    () =>
      sr
        .filter(({ submission: s }) => s.finishedAt)
        .groupBy(groupByDay)
        .toMap(),
    [sr],
  );
};

export const aggregateAssignments = <T extends Record<string, number>>(
  group: ImmutableCollection<number, SubmissionResponse>,
  assessmentMap: ImmutableMap<string, AssessmentExtended>,
  getter: (sr: SubmissionResponse) => T,
) =>
  group.toList().map((sr) => ({
    name: assessmentMap.get(sr.assessmentId)?.name,
    submissionId: sr.submission.id,
    finishedAt: sr.submission.finishedAt,
    contentType: sr.contentType,
    ...getter(sr),
  }));

export const getGraphData = <T>(data: ImmutableMap<number, T>, startDate: Date) =>
  data
    .filter(
      (_, key) => new Date(key) > (isToday(startDate) ? addDays(startDate, -1) : startDate ?? 0),
    )
    .sortBy((_v, k) => k)
    .mapKeys((k) => formatDate(new Date(k), { includeYear: true }));

export const labels = (origin: Date) =>
  Array.from(
    new Array(Math.max(Math.ceil((Date.now() - origin.getTime()) / MILLISECONDS_IN_DAY), 2)),
  ).map((_, idx, arr) =>
    formatDate(addDays(new Date(), 1 + idx - arr.length), { includeYear: true }),
  );

export const labelTime = (ms: number) =>
  `${Math.floor(ms / MILLISECONDS_IN_MINUTE)}m ${Math.floor(
    (ms % MILLISECONDS_IN_MINUTE) / MILLISECONDS_IN_SECOND,
  )}s`;

export const getXIntercept = (
  startDate: Date | null,
  submissionResults: ImmutableList<SubmissionResponse>,
) => {
  if (!startDate || startDate.getTime() === 0) {
    return addDays(
      submissionResults
        .filter(({ submission: s }) => s.finishedAt)
        .map(({ submission: s }) => new Date(s.finishedAt))
        .min() ?? new Date(),
      -1,
    );
  } else if (Date.now() - (startDate.getTime() ?? Date.now()) < MILLISECONDS_IN_DAY) {
    return addDays(startDate, -1);
  }
  return startDate;
};

export const getYIntercept = <T>(startDate: Date | null, data: ImmutableMap<number, T>) =>
  data
    .filter((_, key) => {
      const isBeforeStart = new Date(key) < (startDate ?? Infinity);
      return (
        isBeforeStart || (startDate && !isToday(startDate) && isSameDay(startDate, new Date(key)))
      );
    })
    .sortBy((_, key) => key)
    .last(0);

export const getPosition = (ttm: TooltipModel<'line'>) => {
  const rect = ttm.chart.canvas.getBoundingClientRect();
  return {
    left: `${rect.left + window.scrollX + ttm.caretX}px`,
    top: `${rect.top + window.scrollY + ttm.caretY}px`,
  };
};

export const useGraphOrigin = ({ excludeIndependent }: { excludeIndependent: boolean }) => {
  const submissionResults = useStore((s) => s.StudentProgressMonitoringData.submissionResults, []);
  const { dateOption: d } = useDateFilter();
  const sr = useMemo(
    () => submissionResults.filter((s) => !(excludeIndependent && s.contentType === 'independent')),
    [submissionResults, excludeIndependent],
  );

  return useMemo(() => getXIntercept(d, sr), [d, sr]);
};

type UnknownObj = Record<string, unknown>;
export const isObj = (obj: unknown): obj is UnknownObj => typeof obj === 'object' && Boolean(obj);

export const objHasProp = <X extends UnknownObj, Y extends PropertyKey>(
  obj: X,
  prop: Y,
): obj is X & Record<Y, unknown> => Object.hasOwnProperty.call(obj, prop);

export const objHasPropOfType = (
  obj: Record<string, unknown>,
  prop: string,
  type: 'string' | 'number',
) => objHasProp(obj, prop) && typeof obj[prop] === type;

export const isBaseTooltipData = (obj: unknown): obj is BaseTooltipSubmissionData =>
  isObj(obj) &&
  objHasPropOfType(obj, 'name', 'string') &&
  objHasPropOfType(obj, 'submissionId', 'string') &&
  objHasPropOfType(obj, 'finishedAt', 'string');

export const mapToDataArray = (map: ImmutableMap<string, number>) =>
  map
    .map((y, x) => ({ x, y }))
    .valueSeq()
    .toArray();

const timeLabelSpacingMap = {
  Today: 1,
  'Last 7 Days': 2,
  'Last 30 Days': 4,
  'Current Academic Year': 5,
  'All Time': 10,
};
const plugins = {
  legend: {
    display: false,
  },
  tooltip: {
    enabled: false,
    intersect: false,
    mode: 'nearest' as const,
    axis: 'xy' as const,
    callbacks: {
      label: (ctx: TooltipItem<'line'>) =>
        isObj(ctx.raw) && objHasProp(ctx.raw, 'assignments') && isList(ctx.raw.assignments)
          ? ctx.raw?.assignments.map(() => '').toArray()
          : [],
    },
  },
} as const;
export const useOptions = () => {
  const { dateLabel: label } = useDateFilter();

  return useMemo(() => {
    const scales = {
      x: {
        ticks: {
          callback: (_: number | string, idx: number, ticks: unknown[]) =>
            idx % timeLabelSpacingMap[label]
              ? ''
              : formatDate(addDays(new Date(), idx + 1 - ticks.length)),
          minRotation: 45,
          maxRotation: 45,
        },
      },
    };

    return {
      scales,
      parsing: false as const,
      normalized: true,
      plugins,
      maintainAspectRatio: false,
      tension: 0.2,
    };
  }, [label]);
};

const isPartialFontSpec = (
  scriptable: ScriptableAndScriptableOptions<Partial<FontSpec>, ScriptableTooltipContext<'line'>>,
): scriptable is Partial<FontSpec> => {
  return typeof scriptable !== 'function' && typeof scriptable.family !== 'function';
};

const rawDataIsValid = (raw: unknown): raw is { x: string } =>
  isObj(raw) && objHasPropOfType(raw, 'x', 'string');

export const externalTooltipFunc =
  (
    setTooltipProps: (fin: (initial: TooltipContainerProps) => TooltipContainerProps) => void,
    formatTooltipData: (rawData: { x: string }, idx: number) => void,
  ) =>
  (ctx: { chart: ChartType; tooltip: TooltipModel<'line'> }) => {
    const ttm = ctx.tooltip;
    const position = getPosition(ttm);
    if (!isPartialFontSpec(ttm.options.bodyFont)) return;
    if (ttm.opacity === 0) {
      setTooltipProps((p) => ({ ...p, display: 'none' }));
      return;
    }

    const raw = ttm.dataPoints[0]?.raw;
    if (rawDataIsValid(raw)) {
      formatTooltipData(raw, ttm.dataPoints[0]?.datasetIndex ?? 0);
      setTooltipProps(() => ({
        ...position,
        display: 'block',
        xAlign: ttm.xAlign,
        yAlign: ttm.yAlign,
      }));
    }
  };

export const tooltipPlugin = [
  {
    id: 'meme',
    beforeEvent: (_chart: ChartType, args: { event: ChartEvent; cancelable: boolean }) => {
      const nativeEv = args.event.native;

      if (args.cancelable && nativeEv && args.event.type === 'mouseout') {
        if ((nativeEv as MouseEvent).relatedTarget) {
          const relatedTarget = (nativeEv as MouseEvent).relatedTarget as HTMLElement;
          return !(
            relatedTarget.matches(ChartTooltipContainer.toString()) ||
            relatedTarget.closest(ChartTooltipContainer.toString())
          );
        }
      }
      return true;
    },
  },
];
