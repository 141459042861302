import { subDataCreator } from 'src/State';
import { ErrorMapResponse } from 'src/modules/Api';
import { ImmutableMap } from 'src/modules/Immutable';
import { defaultState } from './ResetPasswordState';

interface ResetPasswordResponse {
  success: boolean;
  errors: ErrorMapResponse;
}

export const createDefaultResetPasswordData = subDataCreator(
  'ResetPasswordData',
  defaultState,
  ({ get, set, fetchJson }) => ({
    load: (passwordReset) => {
      set(defaultState);
      fetchJson(`/api/password_resets/${passwordReset.id}`, {
        data: {
          token: passwordReset.token,
        },
        onSuccess: () => {
          set({ passwordReset, loading: false });
        },
      });
    },
    submit: (history) => () => {
      const { passwordReset, user } = get();
      if (!passwordReset) return;
      set({ submitting: true });
      fetchJson(`/api/password_resets/${passwordReset.id}`, {
        method: 'PATCH',
        data: {
          token: passwordReset.token,
          user: user,
        },
        onSuccess: (data: ResetPasswordResponse) => {
          if (data.success) {
            history.replace(`/login?justReset=true`);
          } else {
            set({ errors: ImmutableMap(data.errors), submitting: false });
          }
        },
      });
    },
    setUserField: (key) => (value) => {
      set((state) => ({ user: { ...state.user, [key]: value } }));
    },
  }),
);
