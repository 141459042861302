import { User } from 'src/models';
import { MimeTypeError } from './Media';

let initialized = false;

export const init = async () => {
  const Sentry = await import('@sentry/browser');
  if (!process.env.REACT_SENTRY_DSN) {
    // eslint-disable-next-line no-console
    console.error(`REACT_SENTRY_DSN is missing, can't start up sentry`);
    return null;
  }
  if (!initialized) {
    Sentry.init({
      dsn: process.env.REACT_SENTRY_DSN,
      environment: process.env.REACT_ENV,
      ignoreErrors: [
        // Random plugins/extensions
        'top.GLOBALS',
        // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
        'originalCreateNotification',
        'canvas.contentDocument',
        'MyApp_RemoveAllHighlights',
        'http://tt.epicplay.com',
        "Can't find variable: ZiteReader",
        'jigsaw is not defined',
        'ComboSearch is not defined',
        'http://loading.retry.widdit.com/',
        'atomicFindClose',
        // Facebook borked
        'fb_xd_fragment',
        // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to reduce this. (thanks @acdha)
        // See http://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
        'bmi_SafeAddOnload',
        'EBCallBackMessageReceived',
        // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
        'conduitPage',
        'Object Not Found Matching Id',
        'ResizeObserver loop limit exceeded',
        "NotFoundError: Failed to execute 'removeChild' on 'Node': The node to be removed is not a child of this node.",
        // Avast extension error
        '_avast_submit',

        // Audio/Video Memes
        'The play() request was interrupted by',
        'play() failed because the user',
        "The fetching process for the media resource was aborted by the user agent at the user's request",
        'not allowed by the user agent or the platform in the current context, possibly because the user denied permission',
      ],
      denyUrls: [
        // Google Adsense
        /pagead\/js/i,
        // Facebook flakiness
        /graph\.facebook\.com/i,
        // Facebook blocked
        /connect\.facebook\.net\/en_US\/all\.js/i,
        // Woopra flakiness
        /eatdifferent\.com\.woopra-ns\.com/i,
        /static\.woopra\.com\/js\/woopra\.js/i,
        // Chrome extensions
        /extensions\//i,
        /chrome-extension:\//i,
        /^chrome:\/\//i,
        // Other plugins
        /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
        /webappstoolbarba\.texthelp\.com\//i,
        /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
        /asset\.goguardian/i,
      ],
    });
    initialized = true;
  }
  return Sentry;
};
/* eslint-disable no-console */

export type ReadleeErrorMeta = Record<string, string | number | boolean | null | undefined>;
export class ReadleeError extends Error {
  meta: ReadleeErrorMeta;
  constructor(message: string, meta: ReadleeErrorMeta) {
    super(message);
    this.meta = meta;
  }
}

export const reportError = async (error: unknown, extras?: ReadleeErrorMeta) => {
  console.error(error);
  const Sentry = await init();
  if (!Sentry) return;
  if (error instanceof Error) {
    return Sentry.withScope((scope) => {
      if (window.localStorage.getItem('mobileWebviewOpened') === 'true') {
        scope.setTag('mobileApp', 'true');
      }
      if (extras) scope.setExtras({ ...extras });
      if (error instanceof MimeTypeError) {
        scope.setExtras({ supportedMimeTypes: error.supportedMimeTypes });
        Sentry.captureException(error);
      } else if (error instanceof ReadleeError) {
        scope.setExtras({ ...error.meta });
        Sentry.captureException(error);
      } else {
        Sentry.captureException(error);
      }
    });
  } else {
    console.warn(
      'This error is not an actual error, try to always throw actual errors when possible so you get real stack traces.',
    );
    return Sentry.captureMessage(`${error}`);
  }
};

/* eslint-enable no-console */

export const setUser = async (user: User | null) => {
  const Sentry = await init();
  Sentry?.setUser(
    user && {
      id: user.id,
      name: user.displayName,
      email: user.email ?? undefined,
      username: user.username,
    },
  );
};

export const catchAndReportErrors = <T>(fn: () => T): T | null => {
  try {
    return fn();
  } catch (error) {
    // Don't let this error kill what's currently happening, but still report it
    reportError(error);
    return null;
  }
};
export const catchAndReportAsyncErrors = async <T>(fn: () => Promise<T>): Promise<T | null> => {
  try {
    return fn();
  } catch (error) {
    // Don't let this error kill what's currently happening, but still report it
    reportError(error);
    return null;
  }
};
