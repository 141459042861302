import { ImmutableList, ImmutableMap, ImmutableSet } from 'src/modules/Immutable';

export type GradeLevel = {
  id: string;
  name: string;
  level: number;
};

export const DISABLED_GRADE_LEVELS = ImmutableSet([-2]);
export const filterGradeLevels = (gradeLevels: ImmutableList<GradeLevel>) =>
  gradeLevels.filter((grade) => !DISABLED_GRADE_LEVELS.has(grade.level));

const gradeLevelToBand = (gradeLevel: number | undefined) => {
  if (gradeLevel === undefined) return '';
  return ImmutableMap(gradeBandsToLevels).reduce<string>(
    (acc, val, key) => (val.includes(gradeLevel) ? key : acc),
    '',
  );
};

export const gradeLevelText = (
  gradeLevelId: string | undefined,
  autoGradeLevelId: string | undefined,
  gradeLevels: ImmutableList<GradeLevel>,
): string => {
  const filteredGradeLevels = filterGradeLevels(gradeLevels);
  const gradeLevel = filteredGradeLevels.find((gl) => gl.id === gradeLevelId)?.level;
  const autoGradeLevel = filteredGradeLevels.find((gl) => gl.id === autoGradeLevelId)?.level;

  return gradeLevelToBand(gradeLevel) || gradeLevelToBand(autoGradeLevel) || '--';
};

export const gradeBandsToLevels = {
  'K-1st': [-1, 1],
  '2nd-3rd': [2, 3],
  '4th-6th': [4, 5, 6],
  '7th-8th': [7, 8],
  '9th-10th': [9, 10],
  '11th-12th': [11, 12],
  College: [13],
};

export const levelsToGradeBands = {
  '-1': 'K-1st',
  '1': 'K-1st',
  '2': '2nd-3rd',
  '3': '2nd-3rd',
  '4': '4th-6th',
  '5': '4th-6th',
  '6': '4th-6th',
  '7': '7th-8th',
  '8': '7th-8th',
  '9': '9th-10th',
  '10': '9th-10th',
  '11': '11th-12th',
  '12': '11th-12th',
  '13': 'College',
} as const;

export type GradeBand = keyof typeof levelsToGradeBands;

const immutableGradeBandsToLevels = ImmutableMap(gradeBandsToLevels);
export const bandsToGradeLevelIds = (
  bands: ImmutableList<string>,
  gradeLevels: ImmutableList<GradeLevel>,
) => {
  const levels = bands.reduce(
    (acc, val) => acc.concat(immutableGradeBandsToLevels.get(val, ImmutableList())),
    ImmutableList<number>(),
  );

  return gradeLevels.filter((gl) => levels.includes(gl.level)).map((gl) => gl.id);
};
