import { useEffect } from 'react';
import { Route } from 'react-router-dom';
import { useStore } from 'src/Store';
import { TeacherLayoutProps } from 'src/layouts';
import {
  SuperTable,
  SuperTableColumn,
  Spacer,
  Copyable,
  Flex,
  TabView,
  DropdownMenu,
  useToaster,
  Text,
  ClassAvatar,
  AvatarCard,
  LmsIcon,
  Tooltip,
  EmptyState,
  Loading,
  Button,
  PaperSyncButton,
} from 'src/components';
import { Course, courseCodeLink, editCoursePath, editStudentsPath } from 'src/models';
import { CreateCourseButton } from 'src/pages/CreateCourseModal';
import { ImmutableList, ImmutableSet, partition } from 'src/modules/Immutable';
import { useQuery, useHistory, useLocation } from 'src/modules/Router';
import { formatTimeAgo } from 'src/modules/Time';
import { ellipsify } from 'src/modules/String';
import { EditCourseModal } from './EditCourseModal';
import { ImportGoogleCourseModal } from 'src/pages/ImportGoogleCourseModal';
import { ImportCleverCourseModal } from 'src/pages/ImportCleverCourseModal';
import { ManageCoTeachersModal } from './ManageCoTeachersModal';
import { SyncLms } from './SyncLms';
import { EditStudentsModal } from './EditStudentsModal';
import { margins, paddings } from 'src/styles';
import { ClassCreatedConfirmationModal } from './ClassCreatedConfirmationModal';

type TeacherCourseListProps = {
  ContentElement: React.ComponentType<TeacherLayoutProps>;
};

const courseNameLength = 40;

const EmptyClasses = ({
  isPaperUser,
  isSyncing,
  syncCount,
}: {
  isPaperUser: boolean;
  isSyncing: boolean;
  syncCount: number;
}) => {
  const paperEmptyState = () => {
    if (isSyncing) return 'classesPaperSyncing';
    return syncCount > 0 ? 'noClassesPaper' : 'classesPaper';
  };
  return (
    <Flex height="100%" width="100%" align="center" justify="center">
      <EmptyState kind={isPaperUser ? paperEmptyState() : 'classes'} />
    </Flex>
  );
};

const getCourseSecondaryText = (course: Course, syncLoadingCourseIds: ImmutableSet<string>) => {
  if (course && syncLoadingCourseIds.has(course.id)) {
    return (
      <Flex align="center">
        Syncing
        <Spacer horizontal />
        <Loading size="x-small" />
      </Flex>
    );
  } else if (course.googleSyncedAt) {
    return `Last Synced ${formatTimeAgo(new Date(course.googleSyncedAt))}`;
  } else if (course.cleverSyncedAt) {
    return `Last Synced ${formatTimeAgo(new Date(course.cleverSyncedAt))}`;
  }
  return null;
};

export const TeacherCourseList = ({ ContentElement }: TeacherCourseListProps) => {
  const {
    loading,
    load,
    courses,
    studentCounts,
    archive,
    startSyncCourse,
    syncLoadingCourseIds,
    assessmentCountMap,
    isSyncing,
  } = useStore(
    (state) => ({
      isSyncing: state.PaperSyncData.getIsSyncing(),
      loading: state.TeacherCourseListData.loading,
      load: state.TeacherCourseListData.load,
      courses: state.TeacherCourseListData.courses,
      studentCounts: state.TeacherCourseListData.studentCounts,
      archive: state.TeacherCourseListData.archive,
      startSyncCourse: state.TeacherCourseListData.startSyncCourse,
      syncLoadingCourseIds: state.TeacherCourseListData.syncLoadingCourseIds,
      assessmentCountMap: state.TeacherCourseListData.assessmentCountMap,
    }),
    [],
  );
  const { paperSyncedAt, paperRosterManualSyncCount, paperRosterAutoSyncCount } = useStore(
    ({ AppData: { currentUser } }) => ({
      paperSyncedAt: currentUser?.paperSyncedAt,
      paperRosterManualSyncCount: currentUser?.paperRosterManualSyncCount || 0,
      paperRosterAutoSyncCount: currentUser?.paperRosterAutoSyncCount || 0,
    }),
    [],
  );
  const query = useQuery();
  const pathname = useLocation().pathname;
  const history = useHistory();
  const courseIdsQueryParam = query.get('editIds');

  useEffect(() => {
    const courseIdsToEdit = ImmutableSet((courseIdsQueryParam || '').split(','));
    load({ courseIdsToEdit, history, pathname });
  }, [load, courseIdsQueryParam, history, pathname]);

  const { createToast } = useToaster();

  const [activeCourses, archivedCourses] = partition(courses, (c) => c.status === 'active');

  const lmsCourses = courses.filter((course) => course.isLms);
  const [googleCourses, cleverCourses] = partition(lmsCourses, (c) => c.googleSyncedAt);

  const columns: Array<
    SuperTableColumn<Course> & { isPaperColumn?: boolean; isPaperOnly?: boolean }
  > = [
    {
      id: 'name',
      name: 'Class Name',
      render: (course) => (
        <AvatarCard
          primaryText={ellipsify(course.name, courseNameLength)}
          tooltip={course.name.length > courseNameLength ? course.name : undefined}
          secondaryText={getCourseSecondaryText(course, syncLoadingCourseIds)}
          avatar={
            <ClassAvatar key={course.id} backgroundColor={course.avatarColor} name={course.name} />
          }
        />
      ),
      sortType: 'sortBy',
      sortBy: (course) => course.name,
      isPaperColumn: true,
      isPaperOnly: false,
    },
    {
      id: 'studentCount',
      name: 'Students',
      render: (course) => <Text variant="h6">{studentCounts.get(course.id) ?? 0}</Text>,
      sortType: 'sortBy',
      defaultSortDirection: 'desc',
      sortBy: (course) => studentCounts.get(course.id) ?? 0,
      isPaperColumn: true,
      isPaperOnly: false,
    },
    {
      id: 'classCode',
      name: 'Class Code',
      render: (course) => (
        <Flex align="center">
          {course.isLms ? (
            <Tooltip
              content={
                <>
                  <Text display="inline">Invite students to go to </Text>{' '}
                  <Copyable
                    kind="link"
                    displayType="link"
                    color="primaryBlue"
                    paddingOverride={paddings.none}
                    copyContent="https://app.readlee.com/login"
                    copyIconSize="0.8em"
                    copyDisplayType="inline"
                  >
                    https://app.readlee.com/login
                  </Copyable>
                  <Text display="inline">{`, then login with their school ${
                    course.googleSyncedAt ? 'Google' : 'Clever'
                  } account. They will automatically join your Readlee class.`}</Text>
                </>
              }
            >
              <Flex>
                <LmsIcon lms={course.googleSyncedAt ? 'Google' : 'Clever'} />
                <Text color="gray5">Synced</Text>
              </Flex>
            </Tooltip>
          ) : (
            <Copyable
              kind="link"
              displayType="link"
              color="primaryBlue"
              copyContent={course.courseCode}
            >
              <Text variant="h6" color="black">
                {course.courseCode}
              </Text>
            </Copyable>
          )}
        </Flex>
      ),
      sortType: 'none',
      isPaperColumn: false,
      isPaperOnly: false,
    },
    {
      id: 'classLink',
      name: 'Class Link',
      render: (course) =>
        course.isLms ? (
          <Tooltip
            content={`Students can sign up with their ${
              course.googleSyncedAt ? 'Google' : 'Clever'
            } accounts automatically. No class link necessary!`}
          >
            <Flex padding={`0 ${paddings[10]} 0 0`}>
              <Text variant="h3" color="gray5">
                -
              </Text>
            </Flex>
          </Tooltip>
        ) : (
          <Flex align="center">
            <Copyable
              kind="link"
              displayType="link"
              color="primaryBlue"
              copyContent={courseCodeLink(course.courseCode)}
            >
              Invite Link
            </Copyable>
          </Flex>
        ),
      sortType: 'none',
      isPaperColumn: false,
      isPaperOnly: false,
    },
    {
      id: 'assignmentCount',
      name: 'Assignments Assigned',
      render: (course) => <Text variant="h6">{assessmentCountMap.get(course.id, 0)}</Text>,
      sortType: 'sortBy',
      defaultSortDirection: 'desc',
      sortBy: (course) => assessmentCountMap.get(course.id, 0),
      isPaperColumn: true,
      isPaperOnly: true,
    },
    {
      id: 'settings',
      name: 'Actions',
      textAlign: 'right',
      render: (course) => (
        <Flex align="center" justify="end">
          <DropdownMenu
            rightAligned
            displayType="iconOnly"
            color="white"
            iconColor="gray7"
            icon="threeVerticalDots"
            iconSize="2em"
            testTag="settings"
            inheritBgColor
            noButtonPadding
            options={
              course.status === 'active'
                ? [
                    {
                      children: 'Edit Class',
                      to: editCoursePath('edit', query, ImmutableList([course.id])),
                    },
                    {
                      children: 'Edit Students',
                      to: editStudentsPath(query, course.id),
                      disabled: course.isLms,
                      tooltipProps: {
                        content: 'Manage students directly in your LMS, e.g. GoogleClass',
                        disabled: !course.isLms,
                        containerWidthOverride: '100%',
                      },
                    },
                    ...(course.isLms
                      ? [
                          {
                            children: 'Sync with LMS',
                            onClick: startSyncCourse({
                              history,
                              pathname,
                              query,
                              courses: ImmutableList<Course>([course]),
                              isGoogle: Boolean(course.googleSyncedAt),
                            }),
                          },
                        ]
                      : []),
                    {
                      children: 'Manage Co-Teachers',
                      to: `/t/classes/${course.id}/co-teachers`,
                    },
                    {
                      children: 'Archive',
                      onClick: archive(course, createToast),
                    },
                  ]
                : [
                    {
                      children: 'Unarchive',
                      onClick: archive(course, createToast),
                    },
                  ]
            }
          />
        </Flex>
      ),
      sortType: 'none',
      isPaperColumn: false,
    },
  ];

  const filteredColumns = columns.filter((column) =>
    paperSyncedAt ? column.isPaperColumn : !column.isPaperOnly,
  );

  const syncCount = paperRosterAutoSyncCount + paperRosterManualSyncCount;

  return (
    <>
      <ContentElement
        headerText="Classes"
        headerActionButton={
          !paperSyncedAt && (
            <CreateCourseButton
              displayType="button"
              openedPath="/t/classes/new"
              closedPath="/t/classes"
            />
          )
        }
        loading={loading}
      >
        {!paperSyncedAt && (
          <Flex align="end" direction="column">
            {!googleCourses.isEmpty() && (
              <Button
                onClick={startSyncCourse({
                  history,
                  query,
                  pathname,
                  courses: googleCourses,
                  isGoogle: true,
                })}
                displayType="link"
              >
                Sync Google Courses
              </Button>
            )}
            {!googleCourses.isEmpty() && !cleverCourses.isEmpty() && <Spacer />}
            {!cleverCourses.isEmpty() && (
              <Button
                onClick={startSyncCourse({
                  history,
                  query,
                  pathname,
                  courses: cleverCourses,
                  isGoogle: false,
                })}
                displayType="link"
              >
                Sync Clever Courses
              </Button>
            )}
          </Flex>
        )}
        {paperSyncedAt && (!activeCourses.isEmpty() || !archivedCourses.isEmpty()) && (
          <Flex direction="column" align="end" width="100%" margin={`0 0 -${margins[5]} 0`}>
            <PaperSyncButton showSyncStatus />
          </Flex>
        )}
        {loading ? (
          <Loading flex kind="boat" />
        ) : (
          <TabView
            queryParam="status"
            tabs={ImmutableList([
              {
                id: 'active',
                name: 'Active Classes',
                content: (
                  <SuperTable
                    columns={filteredColumns}
                    rows={isSyncing ? ImmutableList<Course>() : activeCourses}
                    getRowId={(course) => course.id}
                    queryParam="sortBy"
                    initialSortSettings={{
                      columnId: 'name',
                      sortDirection: 'asc',
                    }}
                    rowClickable
                    onRowClick={(course) =>
                      history.push(`/t/students?selectedCourseId=${course.id}`)
                    }
                    emptyStateContent={
                      <EmptyClasses
                        isPaperUser={Boolean(paperSyncedAt)}
                        isSyncing={isSyncing}
                        syncCount={syncCount}
                      />
                    }
                  />
                ),
              },
              {
                id: 'archived',
                name: 'Archived Classes',
                content: (
                  <SuperTable
                    columns={filteredColumns}
                    rows={isSyncing ? ImmutableList<Course>() : archivedCourses}
                    getRowId={(course) => course.id}
                    queryParam="sortBy"
                    initialSortSettings={{
                      columnId: 'name',
                      sortDirection: 'asc',
                    }}
                    rowClickable
                    onRowClick={(course) =>
                      history.push(`/t/students?selectedCourseId=${course.id}`)
                    }
                    emptyStateContent={
                      <EmptyClasses
                        isPaperUser={Boolean(paperSyncedAt)}
                        isSyncing={isSyncing}
                        syncCount={syncCount}
                      />
                    }
                  />
                ),
              },
            ]).filterNot((tab) => archivedCourses.isEmpty() && tab.id === 'archived')}
          />
        )}
        <Spacer size={22} />
      </ContentElement>
      <EditCourseModal />
      <ImportGoogleCourseModal openedPath="/t/classes/google_import" closedPath="/t/classes" />
      <ImportCleverCourseModal openedPath="/t/classes/clever_import" closedPath="/t/classes" />
      <Route path="/t/classes/:courseId/co-teachers">
        <ManageCoTeachersModal />
      </Route>
      <Route path="/t/classes/sync_lms">
        <SyncLms />
      </Route>
      <Route path="/t/classes/:courseId/finished">
        <ClassCreatedConfirmationModal />
      </Route>
      <EditStudentsModal />
    </>
  );
};
