import styled from 'styled-components';

import { Text, FlexGrow } from 'src/components';
import { borderRadii, colors, margins, paddings, rgba } from 'src/styles';
import { ReactNode } from 'react';

const MetricContainer = styled.div`
  background-color: ${rgba(colors.primaryBlue.hex, 0.05)};
  padding: ${paddings[2]};
  border-radius: ${borderRadii[2]};
  margin: ${margins[2]};
`;

export type MetricProps = {
  value: ReactNode;
  label: string;
};

export const Metric = ({ value, label }: MetricProps) => {
  return (
    <FlexGrow grow={0}>
      <MetricContainer data-test-tag="metric">
        <Text variant="h4" color="primaryBlue">
          {value}
        </Text>
        <Text variant="normalText" color="gray5" whiteSpace="nowrap">
          {label}
        </Text>
      </MetricContainer>
    </FlexGrow>
  );
};
